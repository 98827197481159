// @ts-strict-ignore
import Immutable from "immutable";

import {
  WIDGET_BUTTON_LABEL_CHAR_LIMIT,
  WIDGET_LINK_STILL_WAITING_PROMPT_CHAR_LIMIT,
  WIDGET_LINK_TEXT_CHAR_LIMIT,
} from "constants/messages";
import {
  type ActionIntegrationInputItemRecord,
  type ActionIntegrationOutputItemRecord,
} from "reducers/actionIntegrations/types";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "widget";
  appId: string;
  description: string;
  widgetUrl: string;
  widgetId: string;
  widgetName: string;
  inlineInChat: boolean;
  inputsData: unknown;
  inputsDataList: Immutable.List<ActionIntegrationInputItemRecord>;
  variablesData: Immutable.List<ActionIntegrationOutputItemRecord>;
  linkText: string;
  linkSubtext: string;
  stillWaitingPrompt: string;
  hasInvalidFields: boolean;
  hasMissingMandatoryInputs: boolean;
  isSkippable: boolean;
}

export class WidgetMessageRecord extends BaseMessageRecord<Attributes>({
  type: "widget",
  appId: "",
  description: "",
  widgetUrl: "",
  widgetId: "",
  widgetName: "",
  inlineInChat: false,
  inputsData: Immutable.OrderedMap(),
  inputsDataList: Immutable.List(),
  variablesData: Immutable.List(),
  linkText: "",
  linkSubtext: "",
  stillWaitingPrompt: "",
  hasInvalidFields: false,
  hasMissingMandatoryInputs: false,
  isSkippable: false,
}) {
  static getInvalidFields: InvalidFieldsGetter<WidgetMessageRecord> = (
    message,
  ) => {
    const invalidFields = new Set<string>();

    // disable the save button if widgetUrl is empty or it does not begin with 'https://'
    if (
      message.widgetUrl.length <= 0 ||
      !message.widgetUrl.startsWith("https://")
    ) {
      invalidFields.add("widgetUrl");
    }

    if (
      message.linkText.length <= 0 ||
      message.linkText.length > WIDGET_LINK_TEXT_CHAR_LIMIT
    ) {
      invalidFields.add("linkText");
    }

    if (
      message.linkSubtext.length <= 0 ||
      (message.linkSubtext.length > WIDGET_BUTTON_LABEL_CHAR_LIMIT &&
        !message.inlineInChat) // this field is not shown on the UI if inlineInChat is true
    ) {
      invalidFields.add("linkSubtext");
    }

    if (
      message.stillWaitingPrompt.length <= 0 ||
      message.stillWaitingPrompt.length >
        WIDGET_LINK_STILL_WAITING_PROMPT_CHAR_LIMIT
    ) {
      invalidFields.add("stillWaitingPrompt");
    }

    if (message.hasInvalidFields) {
      invalidFields.add("invalidInputFields");
    }

    message.variablesData
      .filter((data) => !data.get("variableId"))
      .forEach((data, i) => invalidFields.add(`invalidVariableId-${i}`));

    return Immutable.List(invalidFields);
  };
}
