import { type List } from "immutable";

import { type State } from "reducers/types";
import { visibleBlocksSelector } from "selectors/blocks";
import { clientSelector } from "selectors/client";
import { getResource, getResources } from "selectors/resources";
import { BROWSERS, CHANNELS, DEVICES } from "services/browser";

import {
  ACTIVE_STATE_FILTER_OPTIONS,
  CSAT_FILTER_OPTIONS,
  type FilterType,
  INTERACTION_TYPES,
  type SelectOption,
} from "./constants";
import { DASHBOARD_PAGE_OPTIONS } from "./dashboardConfigs";

export function constructFilterOptionsFromStore(
  state: State,
  filterType: FilterType,
): SelectOption[] | List<SelectOption> {
  const client = clientSelector(state);
  const languages = getResources(state, "language");
  const { responses } = state;
  const csatSettings = getResource(state, "csatSettings");
  const agents = getResources(state, "agent");
  const users = getResources(state, "user");
  const blocks = visibleBlocksSelector(state);
  const platforms = state.platforms
    .map((platform) => platform.get("record"))
    .toList();

  switch (filterType) {
    case "answerId":
      return responses.toArray().map((response) => ({
        value: response.id || "",
        label: response.handle,
      }));
    case "language":
      return languages && client
        ? languages
            .filter(
              (language) =>
                client.language === language.code ||
                (client.translatedLanguages &&
                  client.translatedLanguages.includes(language.code)),
            )
            .map((language) => ({
              value: language.code,
              label: language.englishName,
            }))
        : [];
    case "user":
      return users
        ? users.map((user) => ({
            label: user.name as string,
            value: user.id,
          }))
        : [];
    case "browser":
      return BROWSERS.map((browser) => ({
        value: browser.id,
        label: browser.name,
      }));
    case "device":
      return DEVICES.map((device) => ({
        value: device.id,
        label: device.name,
      }));
    case "channel":
      return CHANNELS.map((channel) => ({
        value: channel.id,
        label: channel.name,
      }));
    case "platform":
      return client
        ? platforms
            .filter((platform) => platform.isVisibleToClient(client))
            .map((platform) => ({
              value: platform.name,
              label: platform.fullName,
            }))
        : [];
    case "interactionType":
      return INTERACTION_TYPES.map((channel) => ({
        value: channel.id,
        label: channel.name,
      }));
    case "feedback":
      if (!csatSettings) {
        return [];
      }

      return csatSettings.followUpPositiveFeedbackOptions
        .map((feedback) => ({
          value: feedback.id,
          label: feedback.label.get("en") as string,
          groupName: "Positive",
        }))
        .concat(
          csatSettings.followUpNegativeFeedbackOptions.map((feedback) => ({
            value: feedback.id,
            label: feedback.label.get("en") as string,
            groupName: "Negative",
          })),
        );
    case "agentIds":
      if (!agents) {
        return [];
      }

      return agents.map((agent) => ({
        value: agent.id,
        label: agent.name,
      }));
    case "blockType":
      return blocks.map((block) => ({
        value: block.messageType,
        label: block.blockName,
      }));
    case "dashboardPage":
      return DASHBOARD_PAGE_OPTIONS;
    case "csatscore":
      return CSAT_FILTER_OPTIONS;
    case "activeState":
      return ACTIVE_STATE_FILTER_OPTIONS;

    default:
  }

  return [];
}
