import { type AnyAction } from "@reduxjs/toolkit";
import * as Immutable from "immutable";

export class AnalyticsOverviewPageState extends Immutable.Record({
  untrainedExpressionChanged: false,
}) {}

/**
 * @param {Record<string, unknown>} state
 * @param {Object} action
 * @returns {Record<string, unknown>}
 */
export default function analyticsOverviewPage(
  state = new AnalyticsOverviewPageState(),
  action: AnyAction,
): AnalyticsOverviewPageState {
  switch (action.type) {
    case "SET_UNTRAINED_EXPRESSION_CHANGED": {
      return state.set("untrainedExpressionChanged", action.value);
    }

    case "TRAIN_EXPRESSION_SUCCESS":
    case "TRAIN_EXPRESSIONS_SUCCESS":
      return state.set("untrainedExpressionChanged", true);

    case "UNDO_ACTIVITY_SUCCESS":
      if (
        (action.activity.get("activityType") === "update" ||
          action.activity.get("activityType") === "batch_update") &&
        action.activity.get("resourceType") === "expression"
      ) {
        return state.set("untrainedExpressionChanged", true);
      }

      return state;

    default: {
      return state;
    }
  }
}
