import { type BusinessEventsActions } from "actions/events/types";
import { deserializeTriggerConditions } from "reducers/common/helpers";

import {
  type BusinessEvent,
  type BusinessEventDto,
  type BusinessEventsState,
  DEFAULT_BUSINESS_EVENT,
  DEFAULT_BUSINESS_EVENTS_STATE,
} from "./types";

export function deserializeEvent(event: BusinessEventDto): BusinessEvent {
  return {
    ...DEFAULT_BUSINESS_EVENT,
    id: event._id,
    eventKey: event.event_key,
    updatedBy: event.updated_by,
    name: event.name,
    description: event.description,
    triggerConditions: deserializeTriggerConditions(event.trigger_conditions),
    created: event.created,
    updated: event.updated,
    version: event.version,
    value: event.value === 0 ? null : event.value,
    archived: event.archived,
    builtIn: event.built_in,
  };
}

export const businessEventState = (
  state = DEFAULT_BUSINESS_EVENTS_STATE,
  action: BusinessEventsActions,
): BusinessEventsState => {
  switch (action.type) {
    case "FETCH_BUSINESS_EVENTS_REQUEST":
      return { ...state, loading: true };
    case "FETCH_BUSINESS_EVENTS_SUCCESS":
      return {
        ...state,
        loading: false,
        loaded: true,
        events: action.events.map((event: BusinessEventDto) =>
          deserializeEvent(event),
        ),
      };
    case "SET_BUSINESS_EVENTS":
      return {
        ...state,
        loading: false,
        events: action.events,
      };
    case "FETCH_BUSINESS_EVENTS_FAILURE":
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error?.toString() || "",
      };
    case "SAVE_BUSINESS_EVENTS_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error?.toString() || "",
      };
    case "SAVE_BUSINESS_EVENTS_SUCCESS":
      return { ...state, loading: false };
    default:
      return state;
  }
};
