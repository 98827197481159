import React, { type ReactElement } from "react";
import { useDispatch } from "react-redux";

import { Button } from "components/Common/Button";
import * as Modal from "components/Common/ModalElements/styles";
import SvgIcon from "components/Common/SvgIcon";
import { c } from "constants/css";
import { closeModalAction, openModalAction } from "source/actions/modal";

import * as S from "./styles";

interface Props {
  responseId: string;
  versionId: string;
  errors?: Array<{ body: string }>;
  callback?(): void;
}

export default function RestoreTrainingErrorModal({
  errors,
  responseId,
  versionId,
  callback,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(closeModalAction());

  return (
    <div>
      <Modal.Title>Training Conflict</Modal.Title>

      <Modal.ContentFrame>
        <S.ContentMessage>
          <SvgIcon icon="Warning" fillColor={c.colorUiWarn} height={16} />
          <span>
            These questions are trained to other Answers and will not be added:
          </span>
        </S.ContentMessage>

        <S.ErrorList>
          {errors?.map((error) => (
            <li>{error.body}</li>
          ))}
        </S.ErrorList>
      </Modal.ContentFrame>

      <S.RightAlignedButtonArea>
        <Button onClick={closeModal} text="Cancel" light />
        <Button
          onClick={() => {
            if (callback) {
              return callback();
            }

            return dispatch(
              openModalAction("MODAL_RESTORE_TRAINING_WARNING", {
                responseId,
                versionId,
              }),
            );
          }}
          text="Continue"
        />
      </S.RightAlignedButtonArea>
    </div>
  );
}
