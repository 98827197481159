import { type MessageRecord } from "reducers/responses/messageRecords";
import { createMessageRecord } from "reducers/responses/reducer";
import { keyConverter } from "services/key-converter";

export const createMessageRecordsFromApiResponse = (
  rawMessages: Record<string, unknown>[],
): MessageRecord[] =>
  rawMessages.map((rawMessage) =>
    createMessageRecord(keyConverter(rawMessage), true),
  );
