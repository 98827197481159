import hljs from "highlight.js/lib/core";
import groovy from "highlight.js/lib/languages/groovy";
import javascript from "highlight.js/lib/languages/javascript";
import kotlin from "highlight.js/lib/languages/kotlin";
import ruby from "highlight.js/lib/languages/ruby";
import swift from "highlight.js/lib/languages/swift";
import xml from "highlight.js/lib/languages/xml";
import React, { useState } from "react";

import { Button } from "components/Common/Button";
import "highlight.js/styles/github.css";
import { colorTokens } from "constants/css";

import * as S from "./styles";
import { SupportedLanguages, type SupportedLanguagesType } from "./types";
import "./types.d";

/**
 * Register imported languages
 */
hljs.registerLanguage(SupportedLanguages.SWIFT, swift);
hljs.registerLanguage(SupportedLanguages.JAVASCRIPT, javascript);
hljs.registerLanguage(SupportedLanguages.XML, xml);
hljs.registerLanguage(SupportedLanguages.GROOVY, groovy);
hljs.registerLanguage(SupportedLanguages.KOTLIN, kotlin);
hljs.registerLanguage(SupportedLanguages.RUBY, ruby);

export interface CodeBlockProps {
  code: string;
  labelOverride?: string;
  language?: SupportedLanguagesType;
}

/**
 * Normalizes indentation of the passed code block.
 */
export const formattedCode = (code: string, baselineWhitespace: number) => {
  const lines = code.trim().split("\n");

  return lines
    .map((line) => {
      const spacesInFront = line.search(/\S|$/);
      const diff = spacesInFront - baselineWhitespace;

      if (diff > 0) {
        return new Array(diff + 1).join(" ") + line.trim();
      }

      return line.trim();
    })
    .join("\n");
};

/**
 * Adds syntax highlighting based on the passed language.
 */
export const highlightCode = (
  code: string,
  baselineWhitespace: number,
  language?: SupportedLanguages,
) => {
  if (language === undefined) {
    return formattedCode(code, baselineWhitespace);
  }

  if (Object.values(SupportedLanguages).includes(language)) {
    const { value, illegal } = hljs.highlight(
      formattedCode(code, baselineWhitespace),
      {
        language,
        ignoreIllegals: false,
      },
    );

    if (illegal) {
      console.warn(
        `Illegal code passed to ${language} code block. Syntax highlighting will not work.`,
      );
    }

    return value;
  }

  /**
   * No styling
   */
  return formattedCode(code, baselineWhitespace);
};

export const CodeBlock = ({
  code,
  labelOverride,
  language,
}: CodeBlockProps) => {
  const [copied, setCopied] = useState(false);

  /**
   * Finds the amount of indentation used for the first line of code in the
   * string. This assumes that the first line of code should be the baseline
   * from which the rest of the code is indented.
   */
  const findBaselineIdentation = () => {
    const lines = code.split("\n");

    for (let i = 0; i < lines.length; i += 1) {
      // Indicates that a line contains non-space characters
      if (lines[i]?.replace(/\s/g, "").length) {
        return lines[i]?.search(/\S|$/) as number;
      }
    }

    return 0;
  };

  const baselineWhitespace = findBaselineIdentation();

  /**
   * Copy to the clipboard, and handle visual copy indicator state.
   */
  const handleClipboardClick = async () => {
    try {
      await navigator.clipboard.writeText(
        formattedCode(code, baselineWhitespace),
      );
    } catch (e) {
      return;
    }

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <S.Root>
      <S.Header>
        <S.Language>{labelOverride || language}</S.Language>
        <S.ButtonContainer>
          <Button
            clear
            icon={copied ? "CheckmarkCircle" : "Copy"}
            text={copied ? "Copied" : "Copy"}
            iconColor={copied ? colorTokens.statusPositive : ""}
            onClick={() => handleClipboardClick()}
          />
        </S.ButtonContainer>
      </S.Header>
      <S.CodeContainer>
        <S.Code
          dangerouslySetInnerHTML={{
            __html: highlightCode(code, baselineWhitespace, language),
          }}
        />
      </S.CodeContainer>
    </S.Root>
  );
};
