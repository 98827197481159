// @ts-strict-ignore
import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "redirect_block";
  responseId: string;
}

export class RedirectRecord extends BaseMessageRecord<Attributes>({
  type: "redirect_block",
  responseId: "",
}) {
  static getInvalidFields = (
    message: RedirectRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (!message.responseId) {
      invalidFields.add("responseId");
    }

    return Immutable.List(invalidFields);
  };
}
