import styled from "styled-components";

import { c, cssVariables, v } from "constants/css";

export const DropDownPlaceHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${v.smallFontSize};
  color: ${c.colorGrey4};
  height: 28px;
  padding: 0 ${v.gUnitHalf};
`;

export const PlaceholderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const FolderIcon = styled.span<{ isSearchMode: boolean }>`
  padding-right: ${(p) => (p.isSearchMode ? v.gUnit : "0px")};
  padding-left: ${(p) => (p.isSearchMode ? v.gUnitDouble : "0px")};
`;

export const LeafIcon = styled.span<{ isSearchMode: boolean }>`
  padding-left: ${(p) => (p.isSearchMode ? v.gUnitDouble : "40px")};
  padding-right: ${(p) => (p.isSearchMode ? v.gUnit : "0px")};
`;

export const ClearIcon = styled.span`
  background-color: ${c.colorGrey1};
  height: ${v.gUnitDouble};
  width: ${v.gUnitDouble};
  cursor: pointer;
`;

export const IconSpacing = styled.span<{
  isSearchMode: boolean;
  isLeaf: boolean;
}>`
  ${({ isSearchMode, isLeaf }) => `
    display: flex;
    ${
      isLeaf
        ? `
          padding-left: ${isSearchMode ? cssVariables.space5 : "40px"};
          padding-right: ${isSearchMode ? cssVariables.space2 : "0px"};
        `
        : `
          padding-left: ${isSearchMode ? cssVariables.space5 : "0px"};
          padding-right: ${isSearchMode ? cssVariables.space2 : "0px"};
        `
    }
  `}
`;
