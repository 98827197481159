// @ts-strict-ignore
// eslint-disable-next-line max-classes-per-file
import Immutable from "immutable";

import { type MessageRecord } from "reducers/responses/messageRecords";
import { uuid } from "services/generate-uuid";
import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface RecordAttributes extends BaseMessageRecordAttributes {
  type?: "conditionals_block";
  statements: Immutable.List<ConditionalStatement>;
}

export class ConditionalMessageRecord extends BaseMessageRecord<RecordAttributes>(
  {
    type: "conditionals_block",
    statements: Immutable.List(),
  },
) {
  /**
   * Validation
   */

  static getInvalidFields(
    message: ConditionalMessageRecord,
  ): Immutable.List<string> {
    const invalidFields: Set<string> = new Set();

    // Get invalid fields from nested messages
    // For each statement
    message.statements.forEach((statement, statementIndex) => {
      // For each message
      statement.messages?.forEach((m, messageIndex) => {
        // For each invalid field
        m.invalidFields.forEach((fieldName) => {
          invalidFields.add(
            `statement-${statementIndex}-message-${messageIndex}-${fieldName}`,
          );
        });
      });

      if (statementIndex + 1 !== message.statements.size) {
        statement.conditions.forEach(
          (conditionalRecord, conditionalRecordIndex) => {
            if (!conditionalRecord.leftOperand) {
              invalidFields.add(
                `${statementIndex}-${conditionalRecordIndex}-leftOperand`,
              );
            }

            if (!conditionalRecord.comparisonOperator.length) {
              invalidFields.add(
                `${statementIndex}-${conditionalRecordIndex}-comparisonOperator`,
              );
            }
          },
        );
      }
    });

    return Immutable.List(invalidFields);
  }
}

export class ConditionalRecord extends Immutable.Record<{
  id: string | null;
  leftOperand: string | null;
  comparisonOperator: string;
  rightOperand: string;
  fieldType: string;
  matchCase: boolean;
}>({
  id: null,
  leftOperand: null,
  comparisonOperator: "is",
  rightOperand: "",
  fieldType: "string",
  matchCase: false,
}) {}

interface ConditionalStatementAttributes {
  operator: string;
  conditions: Immutable.List<ConditionalRecord>;
  messages?: Immutable.List<MessageRecord>;
}

export class ConditionalStatement extends Immutable.Record<ConditionalStatementAttributes>(
  {
    operator: "AND",
    conditions: Immutable.List([new ConditionalRecord({ id: uuid() })]),
    messages: Immutable.List(),
  },
) {}

/**
 * Creates ConditionalMessageRecord, using `messageCreator` to create nested messages
 */
export function ConditionalMessageRecordCreator(
  attributes: unknown,
  messageCreator: (
    message: unknown,
    shouldMessageTransformFromAPI: boolean,
  ) => MessageRecord,
  shouldMessageTransformFromAPI: boolean,
): ConditionalMessageRecord {
  const attrsMap: TypedMap<{
    statements?: Immutable.List<ConditionalStatement>;
  }> = Immutable.fromJS(attributes);

  return new ConditionalMessageRecord({
    ...attrsMap.toJS(),

    statements: attrsMap.get("statements")
      ? attrsMap.get("statements")?.map(
          (statement) =>
            new ConditionalStatement({
              operator: statement.get("operator"),
              conditions: statement
                .get("conditions")
                .map(
                  (condition) =>
                    new ConditionalRecord(condition.merge({ id: uuid() })),
                ),
              messages: statement
                .get("messages")
                ?.map((m) => messageCreator(m, shouldMessageTransformFromAPI)),
            }),
        )
      : Immutable.List([
          new ConditionalStatement(),
          new ConditionalStatement(),
        ]),
  });
}
