import { Spinner } from "@adasupport/byron";
import React from "react";

import "./style.scss";

interface LoadingProps {
  customClassName?: string;
  display?: boolean;
}

/** @deprecated Use Spinner from @adasupport/byron directly instead */
export function Loading({ customClassName, display = true }: LoadingProps) {
  if (!display) {
    return null;
  }

  return (
    <div
      className={`Loading ${customClassName || ""}`}
      role="progressbar"
      aria-live="polite"
      aria-busy="true"
      aria-label="Loading"
    >
      <Spinner size="lg" />
    </div>
  );
}
