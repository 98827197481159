import {
  type BusinessEvent,
  type BusinessEventDto,
} from "reducers/businessEvents/types";

export const FETCH_BUSINESS_EVENTS_SUCCESS =
  "FETCH_BUSINESS_EVENTS_SUCCESS" as const;
export interface FetchBusinessEventSuccessAction {
  type: typeof FETCH_BUSINESS_EVENTS_SUCCESS;
  events: BusinessEventDto[];
}

export const SET_BUSINESS_EVENTS = "SET_BUSINESS_EVENTS" as const;
export interface SetBusinessEventsAction {
  type: typeof SET_BUSINESS_EVENTS;
  events: BusinessEvent[];
}

export const SAVE_BUSINESS_EVENTS_REQUEST =
  "SAVE_BUSINESS_EVENTS_REQUEST" as const;
export interface SaveBusinessEventsRequestAction {
  type: typeof SAVE_BUSINESS_EVENTS_REQUEST;
}

export const SAVE_BUSINESS_EVENTS_FAILURE =
  "SAVE_BUSINESS_EVENTS_FAILURE" as const;
export interface SaveBusinessEventsFailureAction {
  type: typeof SAVE_BUSINESS_EVENTS_FAILURE;
  error: unknown;
}

export const FETCH_BUSINESS_EVENTS_REQUEST =
  "FETCH_BUSINESS_EVENTS_REQUEST" as const;
interface FetchBusinessEventsRequestAction {
  type: typeof FETCH_BUSINESS_EVENTS_REQUEST;
}

export const FETCH_BUSINESS_EVENTS_FAILURE =
  "FETCH_BUSINESS_EVENTS_FAILURE" as const;
interface FetchBusinessEventsFailureAction {
  type: typeof FETCH_BUSINESS_EVENTS_FAILURE;
  error: unknown;
}

export const SAVE_BUSINESS_EVENTS_SUCCESS =
  "SAVE_BUSINESS_EVENTS_SUCCESS" as const;
export interface SaveBusinessEventsSuccessAction {
  type: typeof SAVE_BUSINESS_EVENTS_SUCCESS;
}

export type BusinessEventsActions =
  | FetchBusinessEventSuccessAction
  | SetBusinessEventsAction
  | SaveBusinessEventsRequestAction
  | SaveBusinessEventsFailureAction
  | FetchBusinessEventsRequestAction
  | FetchBusinessEventsFailureAction
  | SaveBusinessEventsSuccessAction;
