import Immutable from "immutable";

import { type ClientLegacy } from "resourceModels";

class OraclePlatformRecord extends Immutable.Record({
  name: "oracle_liveagent",
  fullName: "Oracle Live Agent",
  auth: Immutable.Map({
    redirectUri: "",
    clientId: "",
    clientSecret: "",
  }),
  siteId: "",
  enabled: true,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isVisibleToClient(client: ClientLegacy): boolean {
    return true;
  },
}) {}

export { OraclePlatformRecord };
