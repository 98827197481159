import { type AnyAction } from "@reduxjs/toolkit";

import { DEFAULT_MODAL_STATE } from "./constants";

export function modal(state = DEFAULT_MODAL_STATE, action: AnyAction) {
  switch (action.type) {
    case "SET_MODAL": {
      return { ...state, ...action.payload };
    }

    case "CLOSE_MODAL": {
      return { ...state, isOpen: false };
    }

    case "CREATE_RESPONSE_SUCCESS": {
      return DEFAULT_MODAL_STATE;
    }

    case "CLEAR_MODAL": {
      return { ...state, view: null, modalProps: {} };
    }

    default:
      return state;
  }
}
