import { type VariableLegacy as Variable } from "services/variables";

import {
  createConversationsTopic,
  createConversationsTopicCsatComment,
  createConversationsTopicUnansweredQuestion,
} from "./factory";
import {
  type ConversationsTopicCsatCommentResponse,
  type ConversationsTopicResponse,
  type ConversationsTopicUnansweredQuestionResponse,
  type FilterResponse,
  PredicateValueType,
} from "./types";

export const mapFilterToVariablePropertyNames = (
  filter: FilterResponse,
): Partial<Variable> | undefined => {
  if (filter.name !== "VARIABLE" || !filter.id) {
    return undefined;
  }

  const [id, scope] = filter.id.split(".");

  return {
    name: filter.type,
    id,
    scope,
  };
};

export const mapConversationsTopicPropertyNames = (
  conversationsTopic: ConversationsTopicResponse,
) =>
  createConversationsTopic({
    id: conversationsTopic._id,
    name: conversationsTopic.title,
    description: conversationsTopic.description,
    keywords: conversationsTopic.keywords,
    excludeKeywords: conversationsTopic.exclude_keywords,
    created: new Date(conversationsTopic.created * 1000),
    lastUpdated: new Date(conversationsTopic.updated * 1000),
    lastUpdatedBy: conversationsTopic.last_changed_by_user,
    logicalConnective: conversationsTopic.filters_if_any_of
      ? PredicateValueType.ANY
      : PredicateValueType.ALL,
    filters: conversationsTopic.filters.map((filter) => ({
      operator: filter.operator,
      value: filter.value,
      type: filter.name,
      variable: mapFilterToVariablePropertyNames(filter),
    })),
  });

export const mapCsatCommentPropertyNames = (
  comment: ConversationsTopicCsatCommentResponse,
) =>
  createConversationsTopicCsatComment({
    chatterId: comment.chatter_id,
    chatterName: comment.chatter_name,
    comment: comment.csat_comment,
    score: comment.csat_score,
    style: comment.csat_style,
    conversationId: comment.conversation_id,
    date: comment.created,
  });

export const mapUnansweredQuestionPropertyNames = (
  question: ConversationsTopicUnansweredQuestionResponse,
) =>
  createConversationsTopicUnansweredQuestion({
    id: question.expression_id,
    question: question.body,
    conversationId: question.conversation_id,
    chatterId: question.chatter_id,
    date: question.updated,
    predictionIds: question.prediction_ids,
  });
