import Immutable from "immutable";

export enum TrainingSuggestionStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  UNASSIGNED = "UNASSIGNED",
}

export interface TrainingSuggestionAttributes {
  _id: string;
  text: string;
  confidence: number;
  timestamp: string;
  status: TrainingSuggestionStatus;
}

export class TrainingSuggestionRecord extends Immutable.Record<TrainingSuggestionAttributes>(
  {
    _id: "123",
    text: "test suggestion",
    confidence: 1,
    timestamp: "",
    status: TrainingSuggestionStatus.UNASSIGNED,
  },
) {}

export const FETCH_TRAINING_SUGGESTIONS_REQUEST =
  "FETCH_TRAINING_SUGGESTIONS_REQUEST";
export interface fetchTrainingSuggestionsRequestAction {
  type: typeof FETCH_TRAINING_SUGGESTIONS_REQUEST;
}

export const FETCH_TRAINING_SUGGESTIONS_SUCCESS =
  "FETCH_TRAINING_SUGGESTIONS_SUCCESS";
export interface fetchTrainingSuggestionsSuccessAction {
  type: typeof FETCH_TRAINING_SUGGESTIONS_SUCCESS;
  data: {
    suggestions: Immutable.List<TrainingSuggestionRecord>;
  };
}

export const REMOVE_TRAINING_SUGGESTIONS = "REMOVE_TRAINING_SUGGESTIONS";
export interface removeTrainingSuggestionsAction {
  type: typeof REMOVE_TRAINING_SUGGESTIONS;
  data: {
    suggestions: Immutable.List<TrainingSuggestionRecord>;
  };
}

export const CLEAR_TRAINING_SUGGESTIONS = "CLEAR_TRAINING_SUGGESTIONS";
export interface clearTrainingSuggestions {
  type: typeof CLEAR_TRAINING_SUGGESTIONS;
}

export const FETCH_TRAINING_SUGGESTIONS_FAILURE =
  "FETCH_TRAINING_SUGGESTIONS_FAILURE";
export interface fetchTrainingSuggestionsFailure {
  type: typeof FETCH_TRAINING_SUGGESTIONS_FAILURE;
}

export const HIDE_TRAINING_SUGGESTIONS = "HIDE_TRAINING_SUGGESTIONS";
export interface hideTrainingSuggestions {
  type: typeof HIDE_TRAINING_SUGGESTIONS;
}

export const SHOW_TRAINING_SUGGESTIONS = "SHOW_TRAINING_SUGGESTIONS";
export interface showTrainingSuggestions {
  type: typeof SHOW_TRAINING_SUGGESTIONS;
}

export type TrainingSuggestionsAction =
  | fetchTrainingSuggestionsRequestAction
  | fetchTrainingSuggestionsSuccessAction
  | removeTrainingSuggestionsAction
  | clearTrainingSuggestions
  | fetchTrainingSuggestionsFailure
  | hideTrainingSuggestions
  | showTrainingSuggestions;
