import { createAlert } from "actions/alerts";
import { type ThunkAction } from "actions/types";
import { adaAPI } from "services/api";

const BASE_URL = "/knowledgebase/";

export const fetchSmartSearchAction =
  (): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    if (state.smartSearchState.loading) {
      return;
    }

    dispatch({ type: "FETCH_SMART_SEARCH_INTEGRATIONS_REQUEST" });

    try {
      const response = await adaAPI.request({
        method: "GET",
        url: `${BASE_URL}`,
      });
      dispatch({
        type: "FETCH_SMART_SEARCH_INTEGRATIONS_SUCCESS",
        smartSearchIntegrations: response.data.settings,
      });
    } catch (error) {
      dispatch({ type: "FETCH_SMART_SEARCH_INTEGRATIONS_FAILURE" });
    }
  };

export const deleteSmartSearchIntegrationAction =
  (index: string | number): ThunkAction =>
  async (dispatch, getState) => {
    const state = getState();

    if (state.smartSearchState.loading) {
      return;
    }

    dispatch({ type: "ADD_DELETE_SMART_SEARCH_INTEGRATIONS_REQUEST" });

    try {
      await adaAPI.request({
        method: "DELETE",
        url: `${BASE_URL}integrations/${index}`,
      });

      dispatch(
        createAlert({
          message: "Knowledge base integration disconnected.",
          alertType: "success",
        }),
      );

      dispatch({ type: "ADD_DELETE_SMART_SEARCH_INTEGRATIONS_SUCCESS" });
    } catch {
      dispatch(
        createAlert({
          message: "Failed to disconnect knowledge base integration.",
          alertType: "error",
        }),
      );

      dispatch({ type: "ADD_DELETE_SMART_SEARCH_INTEGRATIONS_FAILURE" });
    }
  };

export const confirmOAuthSuccess =
  (integration: string): ThunkAction =>
  async (dispatch) => {
    try {
      return await adaAPI.request({
        method: "GET",
        url: `${BASE_URL}${integration}/oauth/confirm`,
      });
    } catch (error) {
      dispatch(
        createAlert({
          message: `Could not verify ${integration} Oauth completion, secrets not found or invalid.`,
          alertType: "error",
        }),
      );
      throw error;
    }
  };
