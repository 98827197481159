import { primitives } from "@adasupport/byron";
import styled from "styled-components";

import { Flex } from "source/components/Common/Flex";

export const RulesetEditorContainer = styled.div`
  padding: ${primitives.spacing.space2} 0;
`;

export const RuleGroupContainer = styled(Flex)<{ hasMultipleRules: boolean }>`
  background-color: ${(p) => p.hasMultipleRules && primitives.palette.slate100};
  border-radius: 1px;
  outline: ${(p) =>
    p.hasMultipleRules &&
    `${primitives.spacing.space3} solid ${primitives.palette.slate100}`};
  margin: ${(p) => (p.hasMultipleRules ? `${primitives.spacing.space4} 0` : 0)};
`;

export const AddRuleGroupButtonContainer = styled.div`
  margin-top: ${primitives.spacing.space3};
`;
