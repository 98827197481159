/**
 * Returns the percentage of current variant weight divided by the
 * summation of all the variant weights.
 * @param {Number} weight
 * @param {List} variantWeights
 * @returns {Integer}
 */
export function getVariantWeightPercentage(weight, variantWeights) {
  return Math.round((weight / variantWeights.reduce((a, b) => a + b, 0)) * 100);
}
