import { primitives } from "@adasupport/byron";
import React from "react";

import * as S from "./styles";

export const ExplainerVideoModal = () => (
  <S.Container>
    <S.Video src="https://ada-.wistia.com/medias/5sdt4ktvxe?embedType=async&seo=true&videoFoam=true&videoWidth=400" />
  </S.Container>
);

ExplainerVideoModal.isLarge = true;
ExplainerVideoModal.isVideo = true;
ExplainerVideoModal.closeColor = primitives.palette.white;
