import styled from "styled-components";

import { c, v } from "constants/css";

export const ModalBottom = styled.div`
  width: 100%;
  border-radius: 0;
  border-top: 1px solid ${c.colorGrey2};
  padding: ${v.gUnitDouble};
  display: flex;
  background-color: ${c.colorWhite};
  justify-content: space-between;
`;
