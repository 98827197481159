import React, {
  type ChangeEvent,
  type ChangeEventHandler,
  type FocusEvent,
  type FocusEventHandler,
  type KeyboardEventHandler,
  useState,
} from "react";

import { Button } from "components/Common/Button";

import "./style.scss";

interface InputSensitiveProps {
  autoComplete: string;
  /** Disable autofill for LastPass password manager */
  datalpignore: string;
  customClassName: string;
  inputId: string;
  invalid: boolean;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  onKeyDown?: KeyboardEventHandler;
  placeholder: string;
  toggleDisabled: boolean;
  value: string;
}

/**
 * A new implementation of the InputPassword component with some tweaks
 * to modernize the behavior of hiding and modifying sensitive strings.
 */
export function InputSensitive(props: InputSensitiveProps) {
  const {
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    customClassName,
    inputId,
    invalid,
    value,
    autoComplete,
    datalpignore,
    toggleDisabled,
    placeholder,
  } = props;

  const [contentVisible, setContentVisible] = useState<boolean>(false);
  const [originalValue] = useState<string>(value);
  const [clearField, setClearField] = useState<boolean>(false);

  const getPlaceholder = () => {
    const shownPasswordPlaceholder = placeholder || "";

    if (contentVisible) {
      return shownPasswordPlaceholder;
    }

    return "•".repeat(shownPasswordPlaceholder.length);
  };

  const toggleContentVisibility = () => {
    setContentVisible(!contentVisible);
  };

  /**
   * When the input field is focused and the content has been changed,
   * clear the field to prevent the user from sending back obfuscated string,
   * otherwise just let the user continue editing.
   */
  const handleFocus = (e: FocusEvent) => {
    if (value === originalValue) {
      setClearField(true);
    } else {
      setClearField(false);
    }

    if (onFocus) {
      onFocus(e);
    }
  };

  /**
   * When the input field is blurred, ensure that the field is not being cleared.
   */
  const handleBlur = (e: FocusEvent) => {
    setClearField(false);

    if (onBlur) {
      onBlur(e);
    }
  };

  /**
   * Mark the content to have been changed, and ensure the field is not being cleared.
   * Trigger the `onChange` callback prop.
   */
  const handleChange = (e: ChangeEvent) => {
    setClearField(false);

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={`InputSensitive ${customClassName}`}>
      <div className="InputSensitive__input-container">
        <input
          id={inputId}
          className={`
              InputSensitive__input-container__input g-input ph-no-capture
              ${invalid ? "g-input--invalid" : ""}
          `}
          value={clearField ? "" : value}
          type={contentVisible ? "text" : "password"}
          placeholder={getPlaceholder()}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) =>
            e.key === "Enter" && onKeyDown ? onKeyDown(e) : null
          }
          onFocus={(e) => handleFocus(e)}
          onBlur={(e) => handleBlur(e)}
          autoComplete={autoComplete}
          name="password"
          data-lpignore={datalpignore}
        />
        {!toggleDisabled && (
          <Button
            tabIndex={-1}
            onClick={toggleContentVisibility}
            title="Toggle Visibility"
            icon={contentVisible ? "Eye" : "EyeSlash"}
            customClassName="InputSensitive__input-container__button"
            clear
          />
        )}
      </div>
    </div>
  );
}
