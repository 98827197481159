/**
 * Returns index of response in list
 * @param {Immutable.List} list - response list
 * @param {String} id - response id to search for
 * @returns {Number}
 */
export function getResponseIndex(list, id) {
  return list.findIndex((listItem) => listItem.id === id);
}

export const getMessagePath = (
  responseIndex,
  language,
  messageIndex,
  nestedPath = [],
) => [responseIndex, "messages", language, messageIndex, ...nestedPath];

export const attachVariableToCapture = ({
  state,
  messagePath,
  variableId,
  variableName,
}) => {
  const dataToMerge = { variableId, captureKey: variableName };

  return state.mergeIn(messagePath, dataToMerge);
};

export const updateVariableIdHTTP = (state, messagePath, oldId, newId) =>
  state.updateIn([...messagePath, "variablesData"], (variablesData) =>
    variablesData.map((data) =>
      data.get("variableId") === oldId ? data.set("variableId", newId) : data,
    ),
  );

export const updateVariableIdsFromServer = (state, action) => {
  /*
    Update all ids in state to match the one the server responded with.
  */
  let newState = state;
  const { variable } = action;
  const savedVariableId = action.response.data.variable._id;
  const responseIds = variable.responseReferenceCounts.keySeq().toList();
  responseIds
    .map((responseId) => getResponseIndex(state, responseId))
    .forEach((responseIndex) => {
      state.getIn([responseIndex, "messages"]).forEach((messages, language) => {
        messages.forEach((message, messageIndex) => {
          const messagePath = getMessagePath(
            responseIndex,
            language,
            messageIndex,
          );
          const messageType = message.type;

          if (
            message.variableId === variable.id ||
            message.variablesData
              .map((v) => v.get("variableId"))
              .includes(variable.id)
          ) {
            if (messageType === "capture") {
              newState = attachVariableToCapture({
                state: newState,
                messagePath,
                variableId: savedVariableId,
              });
            } else if (
              messageType === "http_request_recipe" ||
              messageType === "variable_override" ||
              messageType === "widget"
            ) {
              newState = updateVariableIdHTTP(
                newState,
                messagePath,
                variable.id,
                savedVariableId,
              );
            }
          }
        });
      });
    });

  return newState;
};
