import Immutable from "immutable";
import cloneDeep from "lodash.clonedeep";

/**
 * Special keys will have a "_" prepended to them when sent to Ada APIs.
 */
const SPECIAL_KEYS = Immutable.Set(["type", "id", "with"]);

/**
 * Handoff keys will not be converted due to issues when saving.
 */
const HANDOFF_KEYS = Immutable.Set(["handoff_fields", "custom_fields"]);

// eslint-disable-next-line max-params
function convertKey(
  obj: Record<string, unknown>,
  key: string,
  type: "underscore" | "camel" | "space",
  capitalize: boolean,
  autofixKeys: boolean,
): void {
  let newKey = "";

  if (type === "underscore") {
    newKey = key.replace(/([A-Z])/g, (g) => `_${g.toLowerCase()}`);

    // Remove underscore from starting character
    if (newKey[0] === "_") {
      newKey = newKey.substring(1);
    }

    // Add underscore back if key is special
    if (autofixKeys && SPECIAL_KEYS.has(key.toLowerCase())) {
      newKey = `_${newKey}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (type === "camel" || type === "space") {
    newKey = key.replace(/_([a-z])/g, (g) => {
      if (type === "camel") {
        return g[1]?.toUpperCase() || "";
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (type === "space") {
        return ` ${g[1]?.toUpperCase()}`;
      }

      return "";
    });
  }

  if (capitalize) {
    newKey = newKey.substring(0, 1).toUpperCase() + newKey.substring(1);
  } else {
    newKey = newKey.substring(0, 1).toLowerCase() + newKey.substring(1);
  }

  if (!Object.prototype.hasOwnProperty.call(obj, newKey)) {
    // eslint-disable-next-line no-param-reassign
    obj[newKey] = obj[key];
    // eslint-disable-next-line no-param-reassign
    delete obj[key];
  }
}

function keyIterator(
  clonedObject: Record<string, unknown>,
  type: "underscore" | "camel" | "space",
  capitalize: boolean,
  autofixKeys: boolean,
): Record<string, unknown> {
  // Store a copy of the object before mutation
  const originalObject = { ...clonedObject };

  Object.keys(originalObject).forEach((key) => {
    // Do not convert headers for HTTP Request Blocks
    const isHTTPHeaders =
      [originalObject._type, originalObject.type].includes(
        "http_request_recipe",
      ) && key === "headers";

    if (isHTTPHeaders) {
      return;
    }

    // Do not convert nested input for Widget App blocks
    // Please contact the Chat Enrichment team before you modify this code
    const isWidgetInput =
      [originalObject._type, originalObject.type].includes("widget") &&
      (key === "inputs_data" || key === "inputsData");

    if (isWidgetInput) {
      convertKey(clonedObject, key, type, capitalize, autofixKeys);

      return;
    }

    convertKey(clonedObject, key, type, capitalize, autofixKeys);

    // Perform operation again for nested objects
    // Escape handoff_fields, as converting to camel case causes problems on handoff
    // (duplicate keys on save)
    if (typeof originalObject[key] === "object" && !HANDOFF_KEYS.has(key)) {
      keyIterator(
        originalObject[key] as Record<string, unknown>,
        type,
        capitalize,
        autofixKeys,
      );
    }
  });

  return clonedObject;
}

type KeyConverterReturnType<Input> = Input extends Record<string, unknown>[]
  ? Record<string, unknown>[]
  : Input extends Record<string, unknown>
  ? Record<string, unknown>
  : null;

/** @deprecated */
export function keyConverter<
  Input extends Record<string, unknown> | Record<string, unknown>[] | null,
>(
  obj: Input,
  type: "underscore" | "camel" | "space" = "camel",
  capitalize = false,
  autofixKeys = true,

  // Return type should depend on Input, e.g. if Input is Record<string, unknown>[], return Record<string, unknown>[]
  // If Input is Record<string, unknown>, return Record<string, unknown>
  // If Input is null, return null
): KeyConverterReturnType<Input> {
  if (!obj) {
    return null as KeyConverterReturnType<Input>;
  }

  // Create a clone so that we do not mutate the original object
  let clonedObject;

  if (Array.isArray(obj)) {
    clonedObject = obj.slice(0);
  } else {
    clonedObject = JSON.parse(JSON.stringify(obj));
  }

  keyIterator(clonedObject, type, capitalize, autofixKeys);

  return clonedObject;
}

/**
 * Prefix underscores to special keys (deep replace) an object in-place.
 * {"key":"snake_case","value":"1","type":"string"}
 */
export function addSpecialKeysToObj(
  obj: Record<string, unknown> | null,
): Record<string, unknown> | null {
  if (!obj) {
    return obj;
  }

  Object.entries(obj).forEach(([key, val]) => {
    const withUnderscore = `_${key}`;

    // Checks for user inserted "type" or "id" key and avoid prefixing with underscore.
    if (
      SPECIAL_KEYS.has(key) &&
      !(["type", "id"].includes(key) && typeof val !== "string")
    ) {
      // TODO: Make this better so users can't manually insert special keys to break it
      // eslint-disable-next-line no-param-reassign
      obj[withUnderscore] = val;
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }

    if (typeof val === "object") {
      addSpecialKeysToObj(val as Record<string, unknown> | null);
    }
  });

  return obj;
}

/**
 * Remove prefix on underscores on special keys (deep replace) an object in-place.
 *
 * {"key":"snake_case","value":"1","_type":"string"}
 */
export function removeSpecialKeysFromObj(
  obj: Record<string, unknown> | null,
): Record<string, unknown> | null {
  if (!obj) {
    return obj;
  }

  const objCopy = cloneDeep(obj);
  Object.entries(objCopy).forEach(([key, val]) => {
    const keyWithoutLeadingUnderscore = key.replace(/^_/, "");
    let newVal = val;

    if (typeof val === "object") {
      newVal = removeSpecialKeysFromObj(val as Record<string, unknown> | null);
    }

    if (
      SPECIAL_KEYS.has(keyWithoutLeadingUnderscore) &&
      // allow users to enter keys like `id`
      keyWithoutLeadingUnderscore !== key &&
      !(["_id", "_type"].includes(key) && typeof val !== "string")
    ) {
      delete objCopy[key];
      objCopy[keyWithoutLeadingUnderscore] = newVal;
    } else {
      objCopy[key] = newVal;
    }
  });

  return objCopy;
}
