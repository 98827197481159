import Immutable from "immutable";

import { type ClientLegacy } from "resourceModels";

class SmsPlatformRecord extends Immutable.Record({
  name: "sms",
  fullName: "SMS",
  authId: "",
  authKey: "",
  enabled: true,

  isVisibleToClient(client: ClientLegacy): boolean {
    return client.features.get("twilioDirectSms") || false;
  },
}) {}

export { SmsPlatformRecord };
