import {
  type TriggerCondition,
  type TriggerConditionDto,
} from "reducers/common/types";

export interface BusinessEvent {
  id: string;
  eventKey: string;
  updatedBy: string;
  name: string;
  description: string;
  triggerConditions: TriggerCondition[];
  created: number;
  updated: number;
  version: string;
  value?: string | null | number;
  archived: boolean;
  builtIn: boolean;
}

export interface BusinessEventDto {
  _id: string;
  event_key: string;
  updated_by: string;
  name: string;
  description: string;
  trigger_conditions: TriggerConditionDto[];
  created: number;
  updated: number;
  version: string;
  value: number | null;
  archived: boolean;
  built_in: boolean;
}

export const DEFAULT_BUSINESS_EVENT: BusinessEvent = {
  id: "",
  eventKey: "",
  updatedBy: "",
  name: "",
  description: "",
  triggerConditions: [],
  created: 0.0,
  updated: 0.0,
  version: "",
  value: null,
  archived: false,
  builtIn: false,
};

export interface BusinessEventsState {
  loading: boolean;
  loaded: boolean;
  error: string;
  events: BusinessEvent[];
}

export const DEFAULT_BUSINESS_EVENTS_STATE: BusinessEventsState = {
  loading: false,
  loaded: false,
  error: "",
  events: [],
};
