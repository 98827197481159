import { AxiosError } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { createAlert } from "actions/alerts";
import { keys } from "services/helpers";
import { useVariables } from "services/variables";

import {
  type WebAction,
  useBulkUpdateWebActionsMutation,
  useCreateWebActionMutation,
  useDeleteWebActionMutation,
  useDuplicateWebActionMutation,
  useGetWebActionsQuery,
  useUpdateWebActionMutation,
} from "./api";
import {
  getRedactedTokenValueForVariable,
  getReferencedInferredVariables,
  getReferencedVariables,
  testWebActionApi,
} from "./helpers";
import { getWebActionInvalidFields } from "./validation";

type PartialDeep<T> = T extends object
  ? { [P in keyof T]?: PartialDeep<T[P]> }
  : T;

const isWebAction = (input: unknown): input is WebAction => {
  if (typeof input !== "object" || input === null) {
    return false;
  }

  const webAction = input as PartialDeep<WebAction>;

  if (typeof webAction.name !== "string") {
    return false;
  }

  if (typeof webAction.description !== "string") {
    return false;
  }

  if (typeof webAction.url !== "string") {
    return false;
  }

  if (typeof webAction.request_body !== "string") {
    return false;
  }

  if (typeof webAction.content_type !== "string") {
    return false;
  }

  if (!Array.isArray(webAction.inputs)) {
    return false;
  }

  if (webAction.inputs.some((i) => typeof i?.name !== "string")) {
    return false;
  }

  if (
    webAction.inputs.some(
      (i) => !["text", "number", "boolean"].includes(i?.type as string),
    )
  ) {
    return false;
  }

  if (!Array.isArray(webAction.outputs)) {
    return false;
  }

  if (webAction.outputs.some((o) => typeof o?.key !== "string")) {
    return false;
  }

  return true;
};

export type TestApiResponse = Awaited<ReturnType<typeof testWebActionApi>>;

export type RedactedTokenValues = Awaited<
  ReturnType<typeof getRedactedTokenValueForVariable>
>;

export const useWebActions = ({ skip }: { skip?: boolean } = {}) => {
  const {
    data: webActionListResponse,
    isLoading: webActionsLoading,
    refetch: refetchWebActions,
  } = useGetWebActionsQuery(undefined, { skip });
  const [createWebAction] = useCreateWebActionMutation();
  const [updateWebAction] = useUpdateWebActionMutation();
  const [bulkUpdateWebActions] = useBulkUpdateWebActionsMutation();
  const [deleteWebAction] = useDeleteWebActionMutation();
  const [duplicateWebAction] = useDuplicateWebActionMutation();
  const [testApiResponse, setTestApiResponse] =
    useState<TestApiResponse | null>(null);
  const [testApiPending, setTestApiPending] = useState(false);
  const dispatch = useDispatch();
  const { variables } = useVariables({ skip });

  const [redactedTokenValues, setredactedTokenValues] =
    useState<RedactedTokenValues | null>(null);

  return {
    webActions: webActionListResponse?.webActions,
    refetchWebActions,
    webActionListResponse,
    webActionsLoading,
    createWebAction,
    updateWebAction,
    bulkUpdateWebActions,
    deleteWebAction,
    duplicateWebAction,

    isWebActionStringValid: (input: string) => {
      try {
        const webAction = JSON.parse(input);

        if (!isWebAction(webAction)) {
          return false;
        }

        return keys(getWebActionInvalidFields(webAction)).length === 0;
      } catch (err) {
        return false;
      }
    },

    webActionTestAPI: {
      // Check if the web action API is valid for testing
      canTest: (
        webAction: WebAction | Omit<WebAction, "_id">,
        testValues: {
          inferred_variable?: Record<string, string>;
          variable?: Record<string, string>;
        },
      ) =>
        Boolean(
          // URL must be valid
          getWebActionInvalidFields(webAction).url === undefined &&
            // For every referenced input, check if there's a test value
            getReferencedInferredVariables(webAction).every((input) =>
              Boolean(testValues.inferred_variable?.[input.name]),
            ) &&
            // For every referenced variable, check if there's a test value
            variables &&
            getReferencedVariables(webAction, variables).every(
              (variable) =>
                Boolean(testValues.variable?.[variable._id]) ||
                variable.scope === "client_secret",
            ),
        ),

      // Triggers the API endpoint with given test values
      trigger: async (
        webAction: WebAction | Omit<WebAction, "_id">,
        testValues: {
          inferred_variable?: Record<string, string>;
          variable?: Record<string, string>;
        },
      ) => {
        setTestApiPending(true);

        try {
          const response = await testWebActionApi(webAction, testValues);

          setTestApiResponse(response);
          setTestApiPending(false);
        } catch (err) {
          setTestApiPending(false);

          const errorMessage =
            err instanceof AxiosError
              ? err.response?.data?.message
              : "Something went wrong.";

          dispatch(createAlert({ message: errorMessage, alertType: "error" }));
        }
      },
      isPending: testApiPending,
      response: testApiResponse,
    },

    redactedClientTokenValues: {
      fetch: async (variableIds: string[]) => {
        setTestApiPending(true);
        const response = await getRedactedTokenValueForVariable(variableIds);

        setredactedTokenValues(response);
      },
      tokens: redactedTokenValues,
    },
  };
};
