import Immutable from "immutable";

interface ClientApiKeysAttributes {
  dateCreated: number | null;
  isRevoked: boolean;
  expiresOn: number | null;
  prefix: string | null;
}

export class ClientApiKeysRecord extends Immutable.Record<ClientApiKeysAttributes>(
  {
    dateCreated: null,
    isRevoked: false,
    expiresOn: null,
    prefix: null,
  },
) {}
