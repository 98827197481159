import Immutable from "immutable";

import { EmailPlatformAddressRecord } from "./EmailPlatformAddressRecord";

export class EmailPlatformRecord extends Immutable.Record({
  name: "email",
  fullName: "Email",
  emailAddress: "",
  emailAddressesConfig: [new EmailPlatformAddressRecord()],
  authKey: "",
  enabled: false,

  isVisibleToClient(): boolean {
    return false;
  },
}) {}
