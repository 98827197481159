import { type Dispatch } from "actions/types";
import { createActionIntegration } from "reducers/actionIntegrations/factory";
import { type ActionIntegration } from "reducers/actionIntegrations/types";
import { adaAPI } from "services/api";
import { selectClient } from "services/client";
import { type KeyPath } from "services/responses";
import { type ThunkAction } from "types";

export function fetchActionIntegrationsAction(refetch = false): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { actionIntegrationsState } = state;
    const { loading, loaded } = actionIntegrationsState;
    const client = selectClient(state);

    if (!client || (!refetch && (loading || loaded))) {
      return {};
    }

    if (!client.features.action_integrations) {
      dispatch({ type: "SET_EMPTY_ACTION_INTEGRATIONS" });

      return {};
    }

    dispatch({ type: "FETCH_ACTION_INTEGRATIONS_REQUEST" });

    return adaAPI.request({ method: "GET", url: "/action-integrations" }).then(
      (response) => {
        dispatch({
          type: "FETCH_ACTION_INTEGRATIONS_SUCCESS",
          actionsList: response.data.action_integrations,
        });
      },
      () => dispatch({ type: "FETCH_ACTION_INTEGRATIONS_FAILURE" }),
    );
  };
}

export function getActionIntegrationById(
  actionIntegrationId: string | null,
  actionIntegrations: ActionIntegration[],
) {
  const defaultReturnValue = createActionIntegration({
    display_name: "Integration not found",
    description: "Integration not found",
  });

  return (
    actionIntegrations.find((elem) => elem.id === actionIntegrationId) ||
    defaultReturnValue
  );
}

export function validateManifestAction(
  keyPath: KeyPath,
  responseId: string,
  hasInvalidFields: boolean,
  hasInvalidMandatoryInputs: boolean,
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "VALIDATE_ACTION_INTEGRATION",
      keyPath,
      responseId,
      hasInvalidFields,
      hasInvalidMandatoryInputs,
    });
  };
}
