import {
  DEFAULT_GOAL,
  DEFAULT_GOALS_STATE,
  DEFAULT_GOAL_PREDICATE,
} from "reducers/goals/constants";

function deserializeConditions(conditions) {
  return conditions.map((condition) => {
    if (condition.type === "condition") {
      return {
        type: condition.type,
        eventKey: condition.event_key,
        property: condition.property,
        relation: condition.relation,
        value: condition.value,
      };
    }

    return condition;
  });
}

function deserializeGoal(goal) {
  return {
    id: goal._id,
    version: goal.version,
    name: goal.name,
    created: goal.created,
    updated: goal.updated,
    updatedBy: goal.updated_by,
    description: goal.description,
    successCondition: {
      ...DEFAULT_GOAL_PREDICATE,
      type: goal.success_condition.type,
      value: goal.success_condition.value,
      conditions: deserializeConditions(goal.success_condition.conditions),
    },
    timeframeMinutes: goal.timeframe_minutes,
    value: goal.value,
  };
}

export const goals = (state = DEFAULT_GOALS_STATE, action) => {
  switch (action.type) {
    case "FETCH_GOALS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_GOALS_SUCCESS":
      return {
        ...state,
        loaded: true,
        loading: false,
        goals: action.goals
          ? action.goals.map((goal) => deserializeGoal(goal))
          : state.goals,
      };

    case "FETCH_GOALS_FAILURE":
      return {
        ...state,
        loading: false,
      };

    case "SET_EMPTY_GOALS":
      return {
        ...state,
        loaded: true,
        loading: false,
        goals: [],
      };

    case "ADD_GOAL":
      return {
        ...state,
        draftGoal: {
          ...DEFAULT_GOAL,
          name: action.name,
          description: action.description,
        },
      };

    default:
      return state;
  }
};
