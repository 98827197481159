import styled from "styled-components";

import { colorPrimitives, v } from "constants/css";

export const Container = styled.div`
  display: flex;
  background-color: ${colorPrimitives.plum100};
  border-radius: ${v.gUnit};
  height: 465px;
`;

export const Video = styled.iframe`
  display: flex;
  height: 100%;
  width: 100%;
  border: 0px;
  border-radius: ${v.gUnit};
`;
