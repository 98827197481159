import { createSelector } from "reselect";

import { type State } from "reducers/types";
import { isValidColor } from "services/color";

import { type Client } from "./types";
import {
  isHeaderColorInvalid,
  isHeaderTextColorInvalid,
  isTextOverAccentColorInvalid,
} from "./validation";

export const selectClient = createSelector(
  (state: State) => state.client,
  (client): Client | null =>
    client.resource && {
      ...client.resource,
      ...client.changedAttributes,
    },
);

type InvalidField =
  | keyof Client
  | "botAvatarLetter"
  | "botAvatarImage"
  | "agentAvatarImage"
  | "textOverAccentColor"
  | "headerColor"
  | "headerTextColor";

export const selectClientInvalidFields = createSelector(
  selectClient,
  (client): Set<InvalidField> => {
    if (!client) {
      return new Set<InvalidField>();
    }

    const result = new Set<InvalidField>();
    const avatarImage = client.avatar.image;
    const avatarImageType = avatarImage && avatarImage.split(".").pop();
    const isBotImage = Boolean(
      avatarImageType &&
        ["svg", "jpg", "jpeg", "png", "gif"].includes(avatarImageType),
    );

    if (client.avatar.use_letter && !client.avatar.letter) {
      result.add("botAvatarLetter");
    } else if (!client.avatar.use_letter && !isBotImage) {
      result.add("botAvatarImage");
    }

    if (!client.agentAvatar.default && !client.agentAvatar.image) {
      result.add("agentAvatarImage");
    }

    // Validate tint
    if (!isValidColor(client.tint)) {
      result.add("tint");
    }

    if (isTextOverAccentColorInvalid(client)) {
      result.add("textOverAccentColor");
    }

    if (isHeaderColorInvalid(client)) {
      result.add("headerColor");
    }

    if (isHeaderTextColorInvalid(client)) {
      result.add("headerTextColor");
    }

    return result;
  },
);

export const selectClientNotFound = createSelector(
  (state: State) => state.client,
  (client): boolean => !!client.fetchClientFailed,
);
