import qs from "qs";
import { createSelector } from "reselect";

import {
  ALL_MODALITIES,
  type Modality,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type State } from "types";

export interface ResponsePageParams {
  variantIndex?: number;
  modality?: Modality;
  language?: string;
}

type SelectorResponse = (state: State) => ResponsePageParams;

export const selectResponsePageParams: SelectorResponse = createSelector(
  (state: State) => state.router.location,
  (location) => {
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { variantIndex, modality: paramModality, language } = queryString;
    const modality = paramModality as Modality;

    return {
      variantIndex: variantIndex ? Number(variantIndex) : undefined,
      modality: ALL_MODALITIES.includes(modality) ? modality : undefined,
      language: language ? String(language) : undefined,
    };
  },
);
