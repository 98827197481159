import moment from "moment-timezone";

// This thing breaks tests for some reason
if (process.env.NODE_ENV !== "test") {
  moment.updateLocale("en", {
    relativeTime: {
      mm: "%d mins",
    },
  });
}

interface Options {
  allMatches?: boolean;
}

export const TIME_ZONE = moment.tz.guess();

export const BEGINNING_OF_TIME = moment("1900-01-01")
  .utc()
  .format("YYYY-MM-DD");

export const TOMORROW_LOCAL = moment().add(1, "day").format("YYYY-MM-DD");

export const TODAY = moment().utc().format("YYYY-MM-DD");
export const TODAY_LOCAL = moment().format("YYYY-MM-DD");

export const YESTERDAY = moment().utc().subtract(1, "day").format("YYYY-MM-DD");
export const YESTERDAY_LOCAL = moment().subtract(1, "day").format("YYYY-MM-DD");

export const START_OF_THIS_WEEK_LOCAL = moment()
  .startOf("isoWeek")
  .format("YYYY-MM-DD");

export const FIRST_DAY_OF_LAST_WEEK_LOCAL = moment()
  .subtract(7, "day")
  .startOf("isoWeek")
  .format("YYYY-MM-DD");

export const LAST_DAY_OF_LAST_WEEK_LOCAL = moment()
  .subtract(7, "day")
  .endOf("isoWeek")
  .format("YYYY-MM-DD");

export const START_OF_THIS_MONTH_LOCAL = moment()
  .startOf("month")
  .format("YYYY-MM-DD");

export const FIRST_DAY_OF_LAST_MONTH = moment()
  .utc()
  .subtract(1, "month")
  .startOf("month")
  .format("YYYY-MM-DD");
export const FIRST_DAY_OF_LAST_MONTH_LOCAL = moment()
  .subtract(1, "month")
  .startOf("month")
  .format("YYYY-MM-DD");

export const LAST_DAY_OF_LAST_MONTH_LOCAL = moment()
  .subtract(1, "month")
  .endOf("month")
  .format("YYYY-MM-DD");

export const SEVEN_DAYS_AGO = moment()
  .utc()
  .subtract(7, "days")
  .format("YYYY-MM-DD");
export const SEVEN_DAYS_AGO_LOCAL = moment()
  .subtract(7, "days")
  .format("YYYY-MM-DD");

export const THIRTY_DAYS_AGO = moment()
  .utc()
  .subtract(30, "days")
  .format("YYYY-MM-DD");
export const THIRTY_DAYS_AGO_LOCAL = moment()
  .subtract(30, "days")
  .format("YYYY-MM-DD");

export const NINETY_DAYS_AGO_LOCAL = moment()
  .subtract(90, "days")
  .format("YYYY-MM-DD");

export const ONE_HUNDRED_EIGHTY_DAYS_AGO = moment()
  .utc()
  .subtract(180, "days")
  .format("YYYY-MM-DD");
export const ONE_HUNDRED_EIGHTY_DAYS_AGO_LOCAL = moment()
  .subtract(180, "days")
  .format("YYYY-MM-DD");

export const START_OF_THIS_YEAR_LOCAL = moment()
  .startOf("year")
  .format("YYYY-MM-DD");

export const FIRST_DAY_OF_LAST_YEAR_LOCAL = moment()
  .subtract(1, "year")
  .startOf("year")
  .format("YYYY-MM-DD");

export const LAST_DAY_OF_LAST_YEAR_LOCAL = moment()
  .subtract(1, "year")
  .endOf("year")
  .format("YYYY-MM-DD");

export const TWO_YEARS_AGO = moment()
  .utc()
  .subtract(2, "years")
  .format("YYYY-MM-DD");

export const DEFAULT_START_DATE = SEVEN_DAYS_AGO;
export const DEFAULT_END_DATE = YESTERDAY;

/**
 * Express a date range by a string, or array of strings if there are multiple matches
 */
export function getDateRangeString(
  startDate: string,
  endDate: string,
  options: Options = {},
): string | string[] {
  const matches = [];

  if (startDate === BEGINNING_OF_TIME && endDate === TODAY_LOCAL) {
    matches.push("allTime");
  }

  if (startDate === TODAY_LOCAL && endDate === TODAY_LOCAL) {
    matches.push("today");
  }

  if (startDate === YESTERDAY_LOCAL && endDate === YESTERDAY_LOCAL) {
    matches.push("yesterday");
  }

  if (startDate === SEVEN_DAYS_AGO_LOCAL && endDate === YESTERDAY_LOCAL) {
    matches.push("last7Days");
  }

  if (startDate === THIRTY_DAYS_AGO_LOCAL && endDate === YESTERDAY_LOCAL) {
    matches.push("last30Days");
  }

  if (startDate === NINETY_DAYS_AGO_LOCAL && endDate === YESTERDAY_LOCAL) {
    matches.push("last90Days");
  }

  if (
    startDate === ONE_HUNDRED_EIGHTY_DAYS_AGO_LOCAL &&
    endDate === YESTERDAY_LOCAL
  ) {
    matches.push("last180Days");
  }

  if (startDate === START_OF_THIS_MONTH_LOCAL && endDate === TODAY_LOCAL) {
    matches.push("monthToDate");
  }

  if (
    startDate === FIRST_DAY_OF_LAST_MONTH_LOCAL &&
    endDate === LAST_DAY_OF_LAST_MONTH_LOCAL
  ) {
    matches.push("lastMonth");
  }

  if (
    startDate === FIRST_DAY_OF_LAST_YEAR_LOCAL &&
    endDate === LAST_DAY_OF_LAST_YEAR_LOCAL
  ) {
    matches.push("lastYear");
  }

  if (startDate === START_OF_THIS_WEEK_LOCAL && endDate === TODAY_LOCAL) {
    matches.push("weekToDate");
  }

  if (
    startDate === FIRST_DAY_OF_LAST_WEEK_LOCAL &&
    endDate === LAST_DAY_OF_LAST_WEEK_LOCAL
  ) {
    matches.push("lastWeek");
  }

  if (startDate === START_OF_THIS_YEAR_LOCAL && endDate === TODAY_LOCAL) {
    matches.push("yearToDate");
  }

  if (!matches.length) {
    matches.push("custom");
  }

  return options.allMatches ? matches : (matches[0] as string);
}

export function getAnalyticsDateStringFromTimestamp(timestamp: number): string {
  return moment.unix(timestamp).format("MMM DD, YYYY LT");
}
