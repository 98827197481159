/**
 * normalOperatingDays data is not in the ideal format that we need for rendering UI,
 * so we convert normalOperatingDays -> normalOperatingDaysRows to use in APP
 * when we're about to patch schedule changes,
 * we convert normalOperatingDaysRows -> normalOperatingDays for API
 *
 * same with specialOperatingDays
 */

import {
  type NormalScheduleRuleRow,
  type Schedule,
  type SpecialScheduleRuleRow,
  type Weekday,
} from "components/Shared/Pages/Settings/SettingsSchedules/types";
import {
  type ScheduleDayData,
  type ScheduleDto,
  type ScheduleWeekdayData,
} from "services/client";
import { type DeepReadonly } from "types";

const DEFAULT_START_TIME = "00:00";
const DEFAULT_END_TIME = "23:59";

interface TimeInfo {
  allDay: boolean;
  start: string | null;
  end: string | null;
}

function getTimeInfo(startTime: string, endTime: string): TimeInfo {
  const allDay =
    startTime === DEFAULT_START_TIME && endTime === DEFAULT_END_TIME;
  const start = allDay ? null : startTime;
  const end = allDay ? null : endTime;

  return { allDay, start, end };
}

function getStartEndTimes(row: NormalScheduleRuleRow | SpecialScheduleRuleRow) {
  const start = row.allDay ? DEFAULT_START_TIME : row.start;
  const end = row.allDay ? DEFAULT_END_TIME : row.end;

  return { start, end };
}

/**
 * convert normalOperatingDays to normalOperatingDaysRows
 */
export function getNormalOperatingDaysRows(
  normalOperatingDays: DeepReadonly<ScheduleWeekdayData>,
): NormalScheduleRuleRow[] {
  let normalOperatingDaysRows: NormalScheduleRuleRow[] = [];

  // The key is always a Weekday, Object.entries just doesn't know that
  Object.entries(normalOperatingDays).forEach((entry) => {
    const [day, timeRanges] = entry as [Weekday, ScheduleDayData[] | null];

    if (timeRanges) {
      timeRanges.forEach((timeRange) => {
        const timeInfo = getTimeInfo(timeRange.start, timeRange.end);
        const { allDay, start, end } = timeInfo;

        const existingRowIndex = normalOperatingDaysRows.findIndex(
          (row) => row.start === start && row.end === end,
        );

        // If there's already a row matching the start and end time, add the day to the list of days for the existing row
        if (existingRowIndex > -1) {
          normalOperatingDaysRows = normalOperatingDaysRows.map((row, i) => {
            if (i === existingRowIndex) {
              return {
                ...row,
                days: [...row.days, day],
              };
            }

            return row;
          });
          // Otherwise, make a new row with only this day
        } else {
          normalOperatingDaysRows = [
            ...normalOperatingDaysRows,
            {
              date: null,
              days: [day],
              start,
              end,
              allDay,
              invalidFields: [],
              conflictingDays: {
                days: new Set(),
                errorMessage: "",
              },
            },
          ];
        }
      });
    }
  });

  return normalOperatingDaysRows;
}

/**
 * convert normalOperatingDaysRows to normalOperatingDays
 */
export function getNormalOperatingDays(
  normalOperatingDaysRows: NormalScheduleRuleRow[],
): ScheduleWeekdayData {
  const normalOperatingDays: ScheduleWeekdayData = {
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
    Sunday: null,
  };

  normalOperatingDaysRows.forEach((row) => {
    row.days.forEach((day) => {
      const { start, end } = getStartEndTimes(row) as {
        start: string;
        end: string;
      };
      const dayData = normalOperatingDays[day];

      if (dayData) {
        normalOperatingDays[day] = [...dayData, { start, end }];
      } else {
        normalOperatingDays[day] = [{ start, end }];
      }
    });
  });

  return normalOperatingDays;
}

/**
 * convert specialOperatingDays to specialOperatingDaysRows
 */
export function getSpecialOperatingDaysRows(
  specialOperatingDays: Record<string, ScheduleDayData> | null,
): SpecialScheduleRuleRow[] {
  let specialOperatingDaysRows: SpecialScheduleRuleRow[] = [];

  if (specialOperatingDays) {
    Object.entries(specialOperatingDays).forEach((entry) => {
      const [date, timeRange] = entry;

      const { allDay, start, end } = getTimeInfo(
        timeRange.start,
        timeRange.end,
      );

      specialOperatingDaysRows = [
        ...specialOperatingDaysRows,
        {
          // Dates may have a number appended to them if there are duplicates, e.g. 2024-01-01#2
          date: date.split("#")[0] as string,
          start,
          end,
          allDay,
          invalidFields: [],
        },
      ];
    });
  }

  return specialOperatingDaysRows;
}

/**
 * This is a hack to handle duplicate dates in specialOperatingDays. The original implementation
 * used an object with the dates as the keys, but this means that if there are duplicate dates,
 * the second one will overwrite the first.
 *
 * To handle this, we append a number to the date string if it already exists in the object,
 * using # as a delimiter.
 *
 * A more proper solution would be to use an array
 * instead of an object, but this would involve a complex data migration which we deemed not to
 * be worth it for this edge case in a feature that's used relatively infrequently.
 */
function getDateKey(dateString: string, existingKeys: string[]) {
  if (!existingKeys.includes(dateString)) {
    return dateString;
  }

  let dateKey = dateString;
  let i = 2;

  while (existingKeys.includes(dateKey)) {
    dateKey = `${dateString}#${i}`;
    i += 1;
  }

  return dateKey;
}

/**
 * convert specialOperatingDaysRows to specialOperatingDays
 */
export function getSpecialOperatingDays(
  specialOperatingDaysRows: SpecialScheduleRuleRow[],
): Record<string, ScheduleDayData> {
  let specialOperatingDays: Record<string, ScheduleDayData> = {};

  specialOperatingDaysRows.forEach((row) => {
    const { start, end } = getStartEndTimes(row) as {
      start: string;
      end: string;
    };
    const { date } = row;
    const dateKey = getDateKey(
      date as string,
      Object.keys(specialOperatingDays),
    );

    specialOperatingDays = {
      ...specialOperatingDays,
      [dateKey]: { start, end },
    };
  });

  return specialOperatingDays;
}

export function convertSchedule(schedule: ScheduleDto): Schedule {
  return {
    id: schedule._id,
    name: schedule.name,
    timezone: schedule.timezone,
    normalOperatingDaysRows: getNormalOperatingDaysRows(
      schedule.normal_operating_days,
    ),
    specialOperatingDaysRows: getSpecialOperatingDaysRows(
      schedule.special_operating_days,
    ),
    isValid: false,
    hasErrors: false,
  };
}

/**
 * Convert a single schedule from the settings page to payload format
 */
function convertScheduleToPayload(schedule: Schedule): ScheduleDto {
  return {
    _id: schedule.id,
    name: schedule.name,
    timezone: schedule.timezone,
    normal_operating_days: getNormalOperatingDays(
      schedule.normalOperatingDaysRows,
    ),
    special_operating_days: getSpecialOperatingDays(
      schedule.specialOperatingDaysRows,
    ),
  };
}

/**
 * Convert a list of schedules from the settings page to payload format.
 */
export function convertSchedulesToPayload(schedules: Schedule[]) {
  return schedules.map((schedule) => convertScheduleToPayload(schedule));
}
