import styled from "styled-components";

import { c, v } from "constants/css";

const SettingsPlatformsDataComplianceApiModal = styled.div``;

export const Root = SettingsPlatformsDataComplianceApiModal;

export const Container = styled.div`
  padding: ${v.gUnitDouble};
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: Inter, sans-serif;
  font-weight: ${v.mediumFontWeight};
  font-size: ${v.smallFontSize};
  line-height: ${v.mediumLineHeight};
  color: ${c.colorBlack};
  margin-top: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

export const CopyButtonContainer = styled.div`
  margin-left: ${v.gUnit};
`;

export const Description = styled.div`
  font-family: Inter, sans-serif;
  font-size: ${v.smallFontSize};
  line-height: ${v.smallLineHeight};
  color: ${c.colorGrey4};
`;

export const ExtraInfo = styled.div`
  font-family: Inter, sans-serif;
  font-size: ${v.xSmallFontSize};
  line-height: ${v.smallLineHeight};
  color: ${c.colorGrey4};
`;

export const BoldText = styled.span`
  font-weight: ${v.heavyFontWeight};
`;

export const ButtonContainer = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const WarningMessage = styled.div`
  padding: ${v.gUnit} ${v.gUnitDouble};
  font-family: Inter, sans-serif;
  font-size: ${v.smallFontSize};
  line-height: ${v.mediumLineHeight};
  color: ${c.colorUiBad};
  background-color: rgba(232, 22, 65, 0.08); // colorUiBad with opacity 0.08
  border-radius: ${v.mainBorderRadius};
  display: flex;
`;

export const WarningMessageText = styled.div`
  margin-left: 10px;
`;
