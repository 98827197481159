// @ts-strict-ignore
import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";
import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

export interface VariableMappingAttributes {
  salesforceFieldName: string;
  variableName: string;
  type: string;
}

interface Attributes extends BaseMessageRecordAttributes {
  type: "salesforce_live_agent";
  chatButtonId: null;
  customVariableMapping: Immutable.List<TypedMap<VariableMappingAttributes>>;
  caseCustomVariableMapping: Immutable.List<
    TypedMap<VariableMappingAttributes>
  >;
  enableCase: boolean;
  enableContact: boolean;
  fallbackResponseId: string | null;
  tags: string;
  successBusinessEvent?: FireableBusinessEvent | null;
}

const variableMappingDefault = [
  Immutable.Map({
    salesforceFieldName: "",
    variableName: "",
    type: "_variable_id",
  }),
];

export class SalesforceLiveAgentMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "salesforce_live_agent",
    chatButtonId: null,
    customVariableMapping: Immutable.List(variableMappingDefault),
    caseCustomVariableMapping: Immutable.List(variableMappingDefault),
    enableCase: true,
    enableContact: true,
    fallbackResponseId: null,
    tags: "",
    successBusinessEvent: new FireableBusinessEvent({
      value: "",
      eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
      isVariable: false,
    }),
  },
) {
  static getInvalidFields: InvalidFieldsGetter = () => Immutable.List();
}
