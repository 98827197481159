import { Button, Icon } from "@adasupport/byron";
import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  deletePlatform as deletePlatformAction,
  saveClientPlatform as saveClientPlatformAction,
  updatePlatform as updatePlatformAction,
} from "actions";
import { Button as CommonButton } from "components/Common/Button";
import { InputPassword } from "components/Common/InputPassword";
import { MessengerPlatformRecord } from "reducers/platforms/models";
import "./style.scss";

const COMPONENT_NAME = "SettingsPlatformsMessengerModal";

class SettingsPlatformsMessengerModal extends React.Component {
  static propTypes = {
    updatePlatform: PropTypes.func.isRequired,
    deletePlatform: PropTypes.func.isRequired,
    saveClientPlatform: PropTypes.func.isRequired,
    platform: PropTypes.instanceOf(MessengerPlatformRecord).isRequired,
  };

  updatePlatform(payload) {
    const { updatePlatform } = this.props;
    updatePlatform("messenger", payload);
  }

  render() {
    const { platform, saveClientPlatform, deletePlatform } = this.props;

    const isButtonDisabled =
      (platform.get("isPublished") && !platform.get("isDirty")) ||
      !platform.get("isValid");

    return (
      <div className={`${COMPONENT_NAME} Modal__modal`}>
        <h5 className="Modal__title">Configure Facebook Messenger</h5>
        <div className="Modal__content">
          <section className="Modal__section">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor={`${COMPONENT_NAME}__access-token`}
              >
                Page Access Token
              </label>
              <p className="Modal__section__sub-section__description">
                You can get one of these from developers.facebook.com
              </p>
              <InputPassword
                value={platform.get("record").accessToken}
                placeholder="Access Token"
                inputId={`${COMPONENT_NAME}__access-token`}
                customClassName={`${COMPONENT_NAME}__access-token`}
                onPasswordChange={(e) =>
                  this.updatePlatform({ accessToken: e.target.value })
                }
              />
            </section>
          </section>
        </div>
        <div className={`Modal__bottom ${COMPONENT_NAME}__bottom`}>
          <Button
            onClick={() => saveClientPlatform(platform)}
            text="Save"
            icon={Icon.Cloud}
            isDisabled={isButtonDisabled}
            isLoading={platform.get("isLoading")}
          />
          {!!platform.get("isPublished") && (
            <CommonButton
              onClick={() => deletePlatform(platform)}
              text="Delete"
              title="Delete"
              icon="Trash"
              light
              customClassName={`${COMPONENT_NAME}__bottom__delete`}
            />
          )}
        </div>
      </div>
    );
  }
}

/**
 * @param {*} state
 */
function mapState(state) {
  const { platforms } = state;

  return {
    platforms,
    platform: platforms.get("messenger"),
  };
}

/**
 * @param {*} dispatch
 */
function mapDispatch(dispatch) {
  return {
    saveClientPlatform: bindActionCreators(saveClientPlatformAction, dispatch),
    deletePlatform: bindActionCreators(deletePlatformAction, dispatch),
    updatePlatform: bindActionCreators(updatePlatformAction, dispatch),
  };
}

const Connector = connect(
  mapState,
  mapDispatch,
)(SettingsPlatformsMessengerModal);

export default Connector;
