export const PRESENCE_EVENT_LIVE_AGENT_FILE_REQUEST = "agent_file_request";
export const PRESENCE_EVENT_LIVE_AGENT_FILE_REQUEST_CANCELLED =
  "agent_file_request_cancelled";
export const PRESENCE_EVENT_LIVE_AGENT_FILE_TRANSFER_SUCCESS =
  "agent_file_transfer_success";
export const PRESENCE_EVENT_LIVE_AGENT_FILE_TRANSFER_FAILURE =
  "agent_file_transfer_failure";
export const PRESENCE_EVENT_SIGNED_IN = "signed_in";
export const PRESENCE_EVENT_SIGNED_OUT = "signed_out";
export const PRESENCE_EVENT_CHATTER_RETRY_HANDOFF = "chatter_retry_handoff";
export const PRESENCE_EVENT_CHATTER_SECRET_SENT = "chatter_secret_sent";

export const CHARACTER_LIMIT = 320;
export const VOICE_SMS_CHARACTER_LIMIT = 1600;
export const JAVASCRIPT_EVENT_NAME_CHAR_LIMIT = 128;

export const WIDGET_LINK_TEXT_CHAR_LIMIT = 60;
export const WIDGET_BUTTON_LABEL_CHAR_LIMIT = 20;
export const WIDGET_LINK_STILL_WAITING_PROMPT_CHAR_LIMIT = 320;
