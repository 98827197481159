import Immutable from "immutable";

import { type SortOptions, SortType } from "hooks/SortableTable";
import { keyConverter } from "services/key-converter";
import { VariableRecord } from "services/variables";

interface VariableManagerState {
  list: VariableRecord[];
  isLoading: boolean;
  error: string;
  sortOptions: SortOptions;
}

export const DEFAULT_VARIABLE_MANAGER_STATE: VariableManagerState = {
  list: [] as VariableRecord[],
  isLoading: true,
  sortOptions: {
    key: "name",
    sort: SortType.ASCENDING,
    type: "string",
  },
  error: "",
};

interface GetAllClientVariablesSuccessV2Action {
  type: "GET_ALL_CLIENT_VARIABLES_SUCCESS_V2";
  response: { data: { variables: Record<string, unknown>[] } };
}

interface CleanVariableManagerAction {
  type: "CLEAN_VARIABLE_MANAGER";
}

interface GetALLClientVariablesFailure {
  type: "GET_ALL_CLIENT_VARIABLES_FAILURE";
}

interface DeleteClientVariable {
  type: "DELETE_VARIABLE_REQUEST";
}

interface UpdateSingleVariableAction {
  type: "UPDATE_SINGLE_VARIABLE";
  variable: VariableRecord;
}

interface CreateVariableAction {
  type: "CREATE_VARIABLE";
  variable: VariableRecord;
}

interface SetVariableManagerSortOptions {
  type: "SET_VARIABLE_MANAGER_SORT_OPTIONS";
  sortOptions: SortOptions;
}

type AcceptedAction =
  | GetAllClientVariablesSuccessV2Action
  | CleanVariableManagerAction
  | GetALLClientVariablesFailure
  | DeleteClientVariable
  | UpdateSingleVariableAction
  | CreateVariableAction
  | SetVariableManagerSortOptions;

export const variableManager = (
  state = DEFAULT_VARIABLE_MANAGER_STATE,
  action: AcceptedAction,
) => {
  switch (action.type) {
    case "DELETE_VARIABLE_REQUEST": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "GET_ALL_CLIENT_VARIABLES_SUCCESS_V2": {
      return {
        ...state,
        isLoading: false,
        list: action.response.data.variables.map((variable) => {
          const data = keyConverter(variable);

          return new VariableRecord({
            ...data,
            captureReferences: Immutable.List(
              data.captureReferences as Array<string>,
            ),
            answersReferencing: Immutable.List(
              data.answersReferencing as Array<string>,
            ),
            usageReferences: Immutable.List(
              data.usageReferences as Array<string>,
            ),
          });
        }),
      };
    }

    case "GET_ALL_CLIENT_VARIABLES_FAILURE": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "CLEAN_VARIABLE_MANAGER": {
      return {
        ...state,
        list: [],
        isLoading: true,
      };
    }

    case "UPDATE_SINGLE_VARIABLE": {
      return {
        ...state,
        list: state.list.map((v) => {
          if (v.id === action.variable.id) {
            return action.variable;
          }

          return v;
        }),
      };
    }

    case "CREATE_VARIABLE": {
      return {
        ...state,
        list: [...state.list, action.variable],
      };
    }

    case "SET_VARIABLE_MANAGER_SORT_OPTIONS": {
      return {
        ...state,
        sortOptions: action.sortOptions,
      };
    }

    default:
      return state;
  }
};
