import { createAlert } from "actions/alerts";
import { type ThunkAction } from "actions/types";
import { serializeTest } from "features/ABTesting/reducer";
import { type BuilderABTest } from "features/ABTesting/types";
import { adaApiRequest } from "services/api";

/* These actions are in their own file to avoid a dependency cycle - they need to be imported from
 * actions/responses, so they must be separated from the actions that import from that file
 */

export function updateBuilderABTestsAction(builderABTests: unknown) {
  return {
    type: "FETCH_BUILDER_AB_TESTS_SUCCESS",
    builderABTests,
  };
}

export function saveBuilderABTestAction(
  // Partial because some attributes (e.g. id, updatedBy) are not present when saving a new test
  builderABTest: Partial<BuilderABTest>,
): ThunkAction<Promise<{ upserted_id: string }>> {
  return async (dispatch) => {
    try {
      const response = await dispatch(
        adaApiRequest<{ upserted_id: string; builder_ab_tests: unknown }>({
          method: "POST",
          url: "/builder_ab_tests",
          data: serializeTest(builderABTest),
        }),
      );
      dispatch(updateBuilderABTestsAction(response.data.builder_ab_tests));
      dispatch(
        createAlert({
          message: "Test successfully saved.",
          alertType: "success",
        }),
      );

      return response.data;
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to save test.",
          alertType: "error",
        }),
      );

      throw error;
    }
  };
}
