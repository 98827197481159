// @ts-strict-ignore
import BaseMessageRecord from "../BaseMessageRecord";
import {
  type HttpMessageRecordAttributes,
  getInvalidHttpFields,
  httpMessageRecordDefaultAttributes,
} from "../HTTPMessageRecord";

interface Attributes extends HttpMessageRecordAttributes {
  displayType: "salesforce";
  requestType: string;
}

export class SalesforceMessageRecord extends BaseMessageRecord<Attributes>({
  ...httpMessageRecordDefaultAttributes,
  displayType: "salesforce",
  requestType: "POST",
}) {
  static getInvalidFields = getInvalidHttpFields;
}
