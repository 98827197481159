import Immutable from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { validateJSON } from "services/payload-validators";
import "./style.scss";

export class ModalEditRawJSON extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.state = { json: "" };

    /**
     * These commented lines can be used to allow editing of existing JSON
     * they show how to utilize the commented functions below.
     */

    // const { message } = this.props;
    // const convertedPayloadMap = convertListToMapDeeply(message.requestPayload);
    // const regularJSON = this.payloadToRegularJSON(convertedPayloadMap.toJS());
    //
    // this.state = {
    //   json: JSON.stringify(regularJSON, null, 2)
    // };
  }

  /**
   * These functions deal with converting the payload that we store to JSON - they are the inverse
   * of the uncommented functions below that convert JSON into our payload format.
   *
   * For now we only allow inserting raw JSON (and not editing the existing payload) since our UI
   * doesn't make a very good code editor. This means we only ever need to convert from the editable
   * format to the payload format, and not vice versa. However, these two functions will be useful
   * if we do want to support editing the existing payload, since that will require converting the
   * payload into the editable format.
   */

  // /**
  //  * In the message record, the payload is stored as nested Immutable Records/Maps
  //  * This function converts a single value from an intermediate format to a regular JSON value
  //  * @param {object} val
  //  * @returns {*}
  //  */
  // convertSingleValueToRegularJSON(val) {
  //   if (val.type === "bool" && val.value === "True") {
  //     return true;
  //   }
  //
  //   if (val.type === "bool" && val.value === "False") {
  //     return false;
  //   }
  //
  //   if (val.type === "number") {
  //     let result;
  //
  //     try {
  //       result = JSON.parse(val.value);
  //     } catch (error) {
  //       // This happens if the value is a variable
  //       result = val.value;
  //     }
  //
  //     return result;
  //   }
  //
  //   if (val.type === "null") {
  //     return null;
  //   }
  //
  //   if (val.type === "dict") {
  //     return this.payloadToRegularJSON(val.value.toJS());
  //   }
  //
  //   if (val.type === "list") {
  //     return val.value.toJS().map(elem => this.convertSingleValueToRegularJSON(elem));
  //   }
  //
  //   // val.type === "string"
  //   return val.value;
  // }
  //
  // /**
  //  * In the message record, the payload is stored as nested Immutable Records/Maps
  //  * This function converts an object from an intermediate format to a regular JSON value
  //  * @param {object} payloadJSON
  //  * @returns {object}
  //  */
  // payloadToRegularJSON(payloadJSON) {
  //   const result = payloadJSON;
  //
  //   Object.keys(payloadJSON).forEach((key) => {
  //     const val = payloadJSON[key];
  //     result[key] = this.convertSingleValueToRegularJSON(val);
  //   });
  //
  //   return result;
  // }

  /**
   * In the message record, the payload is stored as nested Immutable Records/Maps
   * This function converts a single key-value pair to that format
   * @param {string|null} key
   * @param {any} val
   * @returns {Immutable.Map}
   */
  convertSingleValueToPayload(key, val) {
    let result;

    if (val === null) {
      result = Immutable.Map({
        value: "",
        type: "null",
      });
    } else if (val === true) {
      result = Immutable.Map({
        value: "True",
        type: "bool",
      });
    } else if (val === false) {
      result = Immutable.Map({
        value: "False",
        type: "bool",
      });
    } else if (typeof val === "number") {
      result = Immutable.Map({
        value: `${val}`,
        type: "number",
      });
    } else if (typeof val === "string") {
      result = Immutable.Map({
        value: val,
        type: "string",
      });
    } else if (Array.isArray(val)) {
      result = Immutable.Map({
        value: Immutable.List(
          val.map((elem) => this.convertSingleValueToPayload(null, elem)),
        ),
        type: "list",
      });
    } else {
      // object
      result = Immutable.Map({
        value: this.regularJsonToPayload(val),
        type: "dict",
      });
    }

    // Normally we want to return something like this:
    //
    // Immutable.Map({
    //   key: "name",
    //   value: "Erik",
    //   type: "string"
    // })
    //
    // However, if this function was called as a result of recursion on a list there is no key -
    // instead, the value corresponds to an index in the list, and we just want:
    //
    // Immutable.Map({
    //   value: "Erik",
    //   type: "string"
    // })

    if (key) {
      return result.merge({ key });
    }

    return result;
  }

  /**
   * In the message record, the payload is stored as nested Immutable Records/Maps
   * This function converts a JSON object to that format
   * @param {Object} json
   * @returns {Immutable.List}
   */
  regularJsonToPayload(json) {
    const result = Object.keys(json).map((key) => {
      const val = json[key];

      return this.convertSingleValueToPayload(key, val);
    });

    return Immutable.List(result);
  }

  /**
   * @returns {ReactNode}
   */
  render() {
    const { closeModal, onChange } = this.props;

    const { json } = this.state;

    return (
      <div className="ModalEditRawJSON__container">
        <h1>Insert raw JSON</h1>
        <DebouncedInput
          customClassName="ModalEditRawJSON__enhanced-text-input"
          placeholder="Paste your JSON"
          onChange={(newJson) => {
            this.setState({ json: newJson });
          }}
          value={json}
          type="text"
          isTextArea
          minHeight={400}
        />
        {json && !validateJSON(json) && (
          <div className="ModalEditRawJSON__invalid-message">Invalid JSON</div>
        )}
        <Button
          customClassName="ModalEditRawJSON__confirm-button"
          text="Confirm"
          title="Confirm"
          onClick={() => {
            onChange({
              requestPayload: this.regularJsonToPayload(JSON.parse(json)),
            });
            closeModal();
          }}
          disabled={!validateJSON(json)}
        />
      </div>
    );
  }
}
