import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";

import { DebouncedInput } from "components/Common/DebouncedInput";
import { MultilingualInputLanguages } from "components/Common/MultilingualInputLanguages";
import { ClientLegacy } from "resourceModels";
import { NO_OP_FUNCTION } from "services/helpers";

import "./style.scss";

const blockName = "MultilingualInput";

export class MultilingualInput extends React.Component {
  /** @param {Object} props */
  constructor(props) {
    super(props);

    const { activeLanguage } = this.props;

    this.state = {
      activeLanguage,
    };

    this.onChange = this.onChange.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onDeleteLanguage = this.onDeleteLanguage.bind(this);
  }

  /**
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    const { activeLanguage } = this.props;

    if (activeLanguage !== prevState.activeLanguage) {
      this.setState({ activeLanguage });
    }
  }

  /**
   * Handlers
   */

  /**
   * @param {String} valueForLanguage
   */
  onChange(valueForLanguage) {
    const { onChange, value } = this.props;
    const newValue = value.set(this.activeLanguageCode, valueForLanguage);

    onChange(newValue);
  }

  /**
   * @param {String} languageCode
   */
  onLanguageChange(languageCode) {
    const { onChange, onLanguageChange, value } = this.props;

    if (!value.has(languageCode)) {
      onChange(value.set(languageCode, ""));
    }

    this.setState({
      activeLanguage: languageCode,
    });

    onLanguageChange(languageCode);
  }

  /**
   * @param {String} languageCode
   */
  onDeleteLanguage(languageCode) {
    const { onChange, value, client } = this.props;
    const newValue = value.delete(languageCode);

    // when a language is deleted, ensure that it is removed
    // from the list and the default language is shown
    this.onLanguageChange(client.language);
    onChange(newValue);
  }

  /**
   * Properties
   */

  /**
   * @returns {String}
   */
  get valueForLanguage() {
    const { value } = this.props;

    return value.get(this.activeLanguageCode);
  }

  /**
   * @returns {String}
   */
  get activeLanguageCode() {
    const { value, activeLanguage } = this.props;
    const [...languageCodes] = value.keys();

    return languageCodes.includes(activeLanguage)
      ? activeLanguage
      : languageCodes[0];
  }

  /**
   * Methods
   */

  /**
   * @returns {ReactElement}
   */
  render() {
    const {
      activeLanguage,
      id,
      label,
      value,
      characterCounter,
      isInvalid,
      placeholder,
      debounceTime,
      isTextArea,
      warningMessage,
      forceValueRefresh,
    } = this.props;

    return (
      <div className={blockName}>
        <div className={`${blockName}__header`}>
          <label className={`${blockName}__label`} htmlFor={id}>
            {label}
          </label>
          <MultilingualInputLanguages
            activeLanguage={this.activeLanguageCode || activeLanguage}
            message={value}
            deleteLanguage={this.onDeleteLanguage}
            setActiveLanguage={this.onLanguageChange}
          />
        </div>
        <DebouncedInput
          id={id}
          type="text"
          value={this.valueForLanguage || ""}
          onChange={this.onChange}
          placeholder={placeholder}
          isTextArea={isTextArea}
          characterCounter={characterCounter}
          smallFont
          debounceTime={debounceTime}
          isInvalid={isInvalid}
          warningMessage={warningMessage}
          forceValueRefresh={forceValueRefresh}
        />
      </div>
    );
  }
}

// eslint-disable-next-line react/static-property-placement
MultilingualInput.propTypes = {
  id: PropTypes.string.isRequired,
  client: PropTypes.instanceOf(ClientLegacy).isRequired,
  activeLanguage: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.instanceOf(Immutable.Map),
  characterCounter: PropTypes.number,
  isInvalid: PropTypes.bool,
  placeholder: PropTypes.string,
  debounceTime: PropTypes.number,
  isTextArea: PropTypes.bool,
  onChange: PropTypes.func,
  onLanguageChange: PropTypes.func,
  warningMessage: PropTypes.string,
  forceValueRefresh: PropTypes.bool,
};

// eslint-disable-next-line react/static-property-placement
MultilingualInput.defaultProps = {
  label: null,
  value: "",
  characterCounter: null,
  isInvalid: false,
  placeholder: "",
  debounceTime: null,
  isTextArea: false,
  onChange: NO_OP_FUNCTION,
  onLanguageChange: NO_OP_FUNCTION,
  forceValueRefresh: false,
};
