import { bindActionCreators } from "@reduxjs/toolkit";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import ResponseSelector from "components/Common/ResponseSelector";
import { ExpressionErrorList } from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorTrainer/ExpressionErrorList";
import { ExpressionRecord } from "reducers/expressions/types";
import { ResponseRecord } from "reducers/responses/types";
import colors from "stylesheets/utilities/colors.scss";
import "./style.scss";

export const BLOCK_NAME = "ModalMoveTraining";

export class ModalMoveTraining extends React.Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.state = {
      targetResponseId: null,
    };
  }

  /**
   * @returns {Immutable.List.<ResponseRecord>}
   */
  get responseIdsToExclude() {
    const { responses, response } = this.props;

    return responses
      .filter((resp) => !resp.hasTraining || resp.id === response.id)
      .map((r) => r.id);
  }

  /**
   * Displays errors that might have occurred while moving training. These aren't user-actionable,
   * so we can only prompt the user to try again.
   *
   * @returns {ReactElement|null}
   */
  renderErrorList() {
    const { errors } = this.props;

    if (errors.size === 0) {
      return null;
    }

    const errorExpressions = errors.map(
      (error) => new ExpressionRecord({ body: error.get("body") }),
    );

    return (
      <ExpressionErrorList
        errorExpressions={errorExpressions}
        errorMessage="The following questions could not be trained - please try again."
      />
    );
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const { closeModal, handleMoveTraining } = this.props;
    const { targetResponseId } = this.state;
    const buttonDisabled = !targetResponseId;

    return (
      <div className={`${BLOCK_NAME}`}>
        <h5 className={`Modal__title ${BLOCK_NAME}__title`}>
          Move Answer Training
        </h5>
        <div className={`Modal__content ${BLOCK_NAME}__description`}>
          {this.renderErrorList()}
          Which Answer would you like to move these questions to?
          <div className={`${BLOCK_NAME}__response-selector`}>
            <ResponseSelector
              responseIdsToExclude={this.responseIdsToExclude}
              onChange={(newResponseId) =>
                this.setState({
                  targetResponseId: newResponseId,
                })
              }
              selectedResponseId={targetResponseId}
            />
          </div>
        </div>
        <div className={`Modal__bottom ${BLOCK_NAME}__bottom`}>
          <Button
            customClassName={`${BLOCK_NAME}__button`}
            text="Move Training"
            onClick={() => {
              handleMoveTraining(targetResponseId);
            }}
            disabled={buttonDisabled}
            tint={colors.colorUIPrimary}
            light
          />
        </div>
        <div className={`Modal__bottom ${BLOCK_NAME}__bottom`}>
          <Button
            customClassName={`${BLOCK_NAME}__button`}
            text="Cancel"
            onClick={closeModal}
            light
          />
        </div>
      </div>
    );
  }
}

ModalMoveTraining.isSmall = true;
ModalMoveTraining.overflowVisible = true;

ModalMoveTraining.propTypes = {
  response: ImmutablePropTypes.record.isRequired,
  responses: ImmutablePropTypes.listOf(PropTypes.instanceOf(ResponseRecord))
    .isRequired,
  closeModal: PropTypes.func.isRequired,
  handleMoveTraining: PropTypes.func.isRequired,
  errors: ImmutablePropTypes.list,
};

ModalMoveTraining.defaultProps = {
  errors: Immutable.List(),
};

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapState(state) {
  const { responses } = state;

  return {
    responses,
  };
}

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      closeModal: closeModalAction,
    },
    dispatch,
  );
}

const Connector = connect(mapState, mapDispatch)(ModalMoveTraining);

export default Connector;
