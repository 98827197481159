import React, { type ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { closeModalAction } from "actions/modal";
import * as Modal from "components/Common/ModalElements/styles";
import {
  replaceTraining,
  restoreTrainingAction,
} from "components/Shared/Pages/Responses/ResponseVersions/actions";
import { c, v } from "constants/css";

interface Props {
  responseId: string;
  versionId: string;
}

export default function RestoreTrainingWarningModal({
  responseId,
  versionId,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const responseVersions = useSelector((state) => state.responseVersions);
  const snapshot = responseVersions.getIn([
    "full",
    responseId,
    versionId,
    "snapshot",
  ]);

  return (
    <>
      <Modal.Title>Restore Answer Training</Modal.Title>
      <Modal.ContentFrame>
        <p>
          This will create a new Answer version with only the older set of{" "}
          {snapshot?.get("totalExpressions")} training questions.
        </p>
      </Modal.ContentFrame>
      <div>
        <Modal.FullWidthButton
          textColor={c.colorUiPrimary}
          fontWeight={v.mediumFontWeight}
          onClick={async () => {
            await dispatch(restoreTrainingAction({ responseId, versionId }));
            dispatch(replaceTraining(snapshot.get("expressions")));
            dispatch(closeModalAction());
            const url = `/answers/${responseId}/versions`;
            history.push(url);
          }}
        >
          Restore Training
        </Modal.FullWidthButton>
        <Modal.FullWidthButton
          onClick={() => {
            dispatch(closeModalAction());
          }}
        >
          Cancel
        </Modal.FullWidthButton>
      </div>
    </>
  );
}

RestoreTrainingWarningModal.isSmall = true;
