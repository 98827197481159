import { Icon, IconButton, primitives } from "@adasupport/byron";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updatePlatform } from "actions";
import { createAlert as createAlertAction } from "actions/alerts";
import SvgIcon from "components/Common/SvgIcon";
import { type ClientInputLaunchControls } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/LaunchControlsTab/launchControls";
import { useAlternativeBotRollout } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/LaunchControlsTab/useAlternativeBotRollout";
import { useLaunchControls } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/LaunchControlsTab/useLaunchControls";
import { useClient } from "services/client";
import { useChatPlatform } from "services/platforms";

import * as S from "./styles";

interface Props {
  setModalError: (hasError: boolean) => void;
}

export function LaunchControlsTab({ setModalError }: Props) {
  const { client } = useClient();

  const {
    isValid: isValidLaunchControls,
    launchControls,
    updateLaunchControls,
  } = useLaunchControls();
  const {
    alternativeBotHandle,
    isValidAlternativeBotHandle,
    setAlternativeBotHandle,
  } = useAlternativeBotRollout();
  const [botHandle, updateBotHandle] = useState(alternativeBotHandle);
  const dispatch = useDispatch();
  const { chatPlatform } = useChatPlatform();
  const { alternativeBotPreviewLink } = chatPlatform;

  const previewURLBotOverride = `/?adaBotHandleOverride=${alternativeBotHandle}`;
  const fullPreviewURL = `${alternativeBotPreviewLink}${previewURLBotOverride}`;

  const launchControlsSum = Object.values(
    launchControls as ClientInputLaunchControls,
  ).reduce((total, next) => Number(total) + Number(next), 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetAlternativeBotHandle = useCallback(
    debounce((handle: string) => {
      setAlternativeBotHandle(handle);
    }, 500),
    [setAlternativeBotHandle],
  );

  useEffect(() => {
    setModalError(
      !isValidLaunchControls || isValidAlternativeBotHandle === false,
    );
  }, [isValidLaunchControls, isValidAlternativeBotHandle, setModalError]);

  useEffect(() => {
    debouncedSetAlternativeBotHandle(botHandle);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [botHandle]);

  // if there's no alternative bot handle, we want to ensure the split is 0 for the alternative bot rollout
  useEffect(() => {
    if (!alternativeBotHandle) {
      updateLaunchControls({
        showAlternativeBot: 0,
      });
    }
  }, [alternativeBotHandle, updateLaunchControls]);

  return (
    <S.LaunchControlsTab>
      <S.LaunchControlsTabHeader>
        <h4>Launch controls</h4>
        <p>
          Configure the percentage of your customers who should see one of up to
          two Ada Web chat instances, or none at all. This can be useful for
          when you&apos;re progressively rolling out a web chat instance but
          want to live test it on a subset of customers.&nbsp;
          <a href="https://docs.ada.cx/bot-rollout">Learn more</a>
        </p>
      </S.LaunchControlsTabHeader>
      <S.LaunchControlInputRows>
        <S.LaunchControlInputRow>
          <S.LaunchControlInputWrapper>
            <S.LaunchControlInput
              type="number"
              name="show-no-bot"
              id="show-no-bot"
              placeholder="0"
              min={0}
              max={100}
              value={launchControls.showNoBot}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = parseInt(e.currentTarget.value, 10);
                updateLaunchControls({
                  showNoBot: Number.isNaN(newValue) ? "" : newValue,
                });
              }}
            />
          </S.LaunchControlInputWrapper>
          <S.LaunchControlInputLabel htmlFor="show-no-bot">
            Ada web chat button is hidden
          </S.LaunchControlInputLabel>
        </S.LaunchControlInputRow>
        <S.LaunchControlInputRow>
          <S.LaunchControlInputWrapper>
            <S.LaunchControlInput
              type="number"
              name="show-primary-bot"
              id="show-primary-bot"
              min={0}
              max={100}
              placeholder="0"
              value={launchControls.showPrimaryBot}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = parseInt(e.currentTarget.value, 10);
                updateLaunchControls({
                  showPrimaryBot: Number.isNaN(newValue) ? "" : newValue,
                });
              }}
            />
          </S.LaunchControlInputWrapper>
          <S.LaunchControlInputLabel htmlFor="show-primary-bot">
            {client.handle}
          </S.LaunchControlInputLabel>
        </S.LaunchControlInputRow>
        <S.LaunchControlInputRow>
          <S.LaunchControlInputWrapper>
            <S.LaunchControlInput
              type="number"
              disabled={!isValidAlternativeBotHandle}
              name="show-alternative-bot"
              id="show-alternative-bot"
              min={0}
              max={100}
              placeholder="0"
              value={launchControls.showAlternativeBot}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = parseInt(e.currentTarget.value, 10);
                updateLaunchControls({
                  showAlternativeBot: Number.isNaN(newValue) ? "" : newValue,
                });
              }}
            />
          </S.LaunchControlInputWrapper>
          <S.AlternativeBotHandleInputWrapper>
            <S.AlternativeBotHandleInput
              isValid={isValidAlternativeBotHandle}
              type="text"
              name="alternative-bot-handle"
              placeholder="New Ada subdomain"
              value={botHandle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateBotHandle(e.currentTarget.value);
              }}
            />
            {isValidAlternativeBotHandle === false ? (
              <S.InvalidAlternativeBotHandleMessage data-testid="AlternativeBotHandleValidationMessage">
                A valid Ada subdomain must be provided.
              </S.InvalidAlternativeBotHandleMessage>
            ) : (
              <S.AlternativeBotHandleInfo>
                Found in the URL of your Ada instance, e.g.
                subdomain.ada.support
              </S.AlternativeBotHandleInfo>
            )}
          </S.AlternativeBotHandleInputWrapper>
          {isValidAlternativeBotHandle && (
            <SvgIcon
              icon="CircleCheckmark"
              height={18}
              fillColor={primitives.palette.green700}
            />
          )}
        </S.LaunchControlInputRow>
      </S.LaunchControlInputRows>
      <S.LaunchControlsValidationMessage isValid={isValidLaunchControls}>
        {isValidLaunchControls ? (
          <Icon.Check
            color={primitives.palette.green700}
            data-testid="LaunchControlsSuccessIcon"
          />
        ) : (
          <Icon.Exclamation
            color={primitives.palette.red600}
            data-testid="LaunchControlsErrorIcon"
          />
        )}
        &nbsp;Total
        <span data-testid="LaunchControlsSum">{launchControlsSum}/100%</span>
      </S.LaunchControlsValidationMessage>
      {isValidAlternativeBotHandle && (
        <S.AlternativeBotPreview data-testid="AlternativeBotPreview">
          <S.LaunchControlsTabHeader>
            <h4>Preview {alternativeBotHandle}</h4>
            <p>
              Enter a URL where <S.BoldenedText>{client.handle}</S.BoldenedText>{" "}
              is currently embedded. We&apos;ll generate a test URL where you
              can always see your new Ada web chat instance,{" "}
              <S.BoldenedText>{alternativeBotHandle}</S.BoldenedText>, instead.
            </p>
          </S.LaunchControlsTabHeader>
          <S.AlternativeBotPreviewURLInputWrapper>
            <S.AlternativeBotPreviewURLInput
              type="text"
              name="alternative-bot-preview-url"
              placeholder="https://mycompany.com"
              value={alternativeBotPreviewLink || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(
                  updatePlatform("chat", {
                    alternativeBotPreviewLink: e.currentTarget.value,
                  }),
                )
              }
            />
            <S.AlternativeBotPreviewURLInputLabel htmlFor="alternative-bot-preview-url">
              {previewURLBotOverride}
            </S.AlternativeBotPreviewURLInputLabel>
            <S.AlternativeBotPreviewAction>
              <IconButton
                icon={Icon.Copy}
                isDisabled={!alternativeBotPreviewLink}
                aria-label="Copy Preview URL"
                variant="tertiary"
                iconColor={primitives.palette.slate500}
                onClick={() => {
                  navigator.clipboard.writeText(fullPreviewURL);
                  dispatch(
                    createAlertAction({
                      message: "Copied to clipboard!",
                      alertType: "success",
                    }),
                  );
                }}
              />
            </S.AlternativeBotPreviewAction>
            <S.AlternativeBotPreviewAction>
              <IconButton
                icon={Icon.Web}
                isDisabled={!alternativeBotPreviewLink}
                aria-label="Open Preview URL"
                variant="tertiary"
                iconColor={primitives.palette.slate500}
                onClick={() => window.open(fullPreviewURL, "_blank")}
              />
            </S.AlternativeBotPreviewAction>
          </S.AlternativeBotPreviewURLInputWrapper>
        </S.AlternativeBotPreview>
      )}
    </S.LaunchControlsTab>
  );
}
