import { type State } from "reducers/types";
import { getResources } from "selectors/resources";
import { selectClient } from "services/client/selectors";

interface TrainingErrorMessageArgs {
  state: State;
  currentResponseId: string | null;
  existingResponseId: string;
  existingLanguageCode?: string;
}

export function getAlreadyTrainedError({
  state,
  currentResponseId,
  existingResponseId,
  existingLanguageCode,
}: TrainingErrorMessageArgs) {
  const client = selectClient(state);
  const existingResponse = state.responses.find(
    (response) => response.id === existingResponseId,
  );

  if (!existingResponse) {
    return "This question is already trained to another Answer.";
  }

  let displayLanguage = "";

  if (client) {
    const languages = getResources(state, "language");
    const existingLanguage = languages?.find(
      (language) => language.code === existingLanguageCode,
    );
    displayLanguage = existingLanguage?.englishName || "an unknown language";
  }

  let message = "This question is already trained to this Answer";

  if (!currentResponseId || currentResponseId !== existingResponseId) {
    message = `This question is already trained to the Answer "${existingResponse.handle}"`;
  }

  if (displayLanguage) {
    return `${message} in ${displayLanguage}.`;
  }

  return `${message}.`;
}
