import { type AnyAction, createAsyncThunk } from "@reduxjs/toolkit";

import { adaApiRequest } from "services/api";
import { deserializeTraining } from "slices/training/deserialization";

interface FetchTrainingParams {
  answerId: string;
  language?: string;
}

export const fetchTraining = createAsyncThunk(
  "training/fetchTraining",
  async ({ answerId, language }: FetchTrainingParams, thunkAPI) => {
    const request = adaApiRequest({
      method: "GET",
      url: `/responses/${answerId}/expressions`,
      params: {
        language,
      },
    }) as unknown as AnyAction;

    const response = await thunkAPI.dispatch(request);

    return {
      languageFilter: language || "all",
      training: deserializeTraining(response.data.expressions),
    };
  },
);

interface FetchMoreTrainingParams {
  answerId: string;
  language?: string;
  lastExpressionId: string;
}

export const fetchMoreTraining = createAsyncThunk(
  "training/fetchMoreTraining",
  async (
    { answerId, language, lastExpressionId }: FetchMoreTrainingParams,
    thunkAPI,
  ) => {
    const request = adaApiRequest({
      method: "GET",
      url: `/responses/${answerId}/expressions`,
      params: {
        language,
        after: lastExpressionId,
      },
    }) as unknown as AnyAction;

    const response = await thunkAPI.dispatch(request);

    return deserializeTraining(response.data.expressions);
  },
);
