import { createAlert } from "actions/alerts";
import { type ThunkAction } from "actions/types";
import { type TestVariableInfo } from "reducers/testVariablesModal/reducer";
import { adaAPI } from "services/api";
import { type Variable } from "slices/variables/types";

export function getValidVariablesInfo(
  variablesInfo: TestVariableInfo[],
  allVariables: Variable[],
) {
  return variablesInfo.filter((v) => {
    if (v.id === null || v.value === null) {
      return false;
    }

    const variable = allVariables.find(
      (currentVar: Variable) => currentVar._id === v.id,
    );

    if (!variable) {
      return false;
    }

    if (variable._type === "long" && Number.isNaN(Number(v.value))) {
      return false;
    }

    return true;
  });
}

export function getValidVariablesCount(
  variablesInfo: TestVariableInfo[],
  allVariables: Variable[],
) {
  return getValidVariablesInfo(variablesInfo, allVariables).length;
}

export function applyTestVariables(allVariables: Variable[]): ThunkAction {
  return async (dispatch, getState) => {
    const { chatterToken, variablesInfo } = getState().testVariablesModalState;

    if (!chatterToken) {
      dispatch(
        createAlert({
          message: "Can't set test variables: no chatter token found.",
          alertType: "error",
        }),
      );
    }

    const filteredVariablesInfo = getValidVariablesInfo(
      variablesInfo,
      allVariables,
    );

    await adaAPI.request({
      method: "PATCH",
      url: `/chatters/${chatterToken}/storage_any_scope`,
      data: {
        updates: filteredVariablesInfo.map((variableInfo: TestVariableInfo) => {
          const variable = allVariables.find(
            (v: Variable) => v._id === variableInfo.id,
          );

          return {
            name: variable?.name,
            scope: variable?.scope,
            value: variableInfo.value,
          };
        }),
      },
    });

    dispatch(
      createAlert({
        message: "Test variables have been set",
        alertType: "success",
      }),
    );
  };
}
