// @ts-strict-ignore
import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "helpdesk_handoff_recipe";
  captureAction: Immutable.Map<string, unknown>;
  confirmationMessage: Immutable.Map<string, unknown>;
  helpdeskAction: Immutable.Map<string, unknown>;
}

export class ZendeskHandoffMessageRecord extends BaseMessageRecord<Attributes>({
  type: "helpdesk_handoff_recipe",
  captureAction: Immutable.Map({
    type: "capture",
    captureLead: "What's your email?",
    captureKey: "email",
    captureLocked: false,
    captureType: "email",
  }),
  confirmationMessage: Immutable.Map({
    body: "I've sent your question to our team. Can I help with anything else?",
    type: "text",
  }),
  helpdeskAction: Immutable.Map({
    type: "zendesk_handoff",
    tags: "ada",
    inlineChatterInfo: false,
    uniqueSubject: false,
    inlineTranscript: false,
    subject: "",
    successBusinessEvent: new FireableBusinessEvent({
      value: "",
      eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
      isVariable: false,
    }),
  }),
}) {
  static getInvalidFields: InvalidFieldsGetter = () => Immutable.List();
}
