import Immutable from "immutable";

import { keyConverter } from "services/key-converter";

export const LAGroupRecord = Immutable.Record({
  id: null,
  name: null,
});

export const zendeskLADepartments = (state = Immutable.List([]), action) => {
  let newState = state;

  switch (action.type) {
    case "GET_LA_GROUP_SUCCESS":
      if (action.response.data.platform === "zendesk_liveagent") {
        newState = Immutable.List(
          action.response.data.groupings.map(
            (dept) => new LAGroupRecord(keyConverter(dept)),
          ),
        );
      }

      return newState;
    default:
      return newState;
  }
};

export const zawLAGroups = (state = Immutable.List([]), action) => {
  let newState = state;

  switch (action.type) {
    case "GET_LA_GROUP_SUCCESS":
      if (action.response.data.platform === "zaw_liveagent") {
        newState = Immutable.List(
          action.response.data.groupings.map(
            (dept) => new LAGroupRecord(keyConverter(dept)),
          ),
        );
      }

      return newState;
    default:
      return newState;
  }
};

export const nuanceLABusinessUnits = (state = Immutable.List([]), action) => {
  let newState = state;

  switch (action.type) {
    case "GET_LA_GROUP_SUCCESS":
      if (
        action.response.data.platform === "nuance_liveagent" &&
        action.response.data.type === "business"
      ) {
        newState = Immutable.List(
          action.response.data.groupings.map(
            (dept) => new LAGroupRecord(keyConverter(dept)),
          ),
        );
      }

      return newState;
    default:
      return newState;
  }
};

export const nuanceLAAgents = (state = Immutable.List([]), action) => {
  let newState = state;

  switch (action.type) {
    case "GET_LA_GROUP_SUCCESS":
      if (
        action.response.data.platform === "nuance_liveagent" &&
        action.response.data.type === "agent"
      ) {
        newState = Immutable.List(
          action.response.data.groupings.map(
            (dept) => new LAGroupRecord(keyConverter(dept)),
          ),
        );
      }

      return newState;
    default:
      return newState;
  }
};
