export interface InitialLaunchControls {
  showNoBot: "" | number;
  showPrimaryBot: "" | number;
  showAlternativeBot: "" | number;
}

export type ValidLaunchControls = {
  [K in keyof InitialLaunchControls]: number;
};

export type ClientInputLaunchControls = {
  [K in keyof InitialLaunchControls]: "" | number | null;
};

export function shouldInitializeLaunchControls(
  launchControls: ClientInputLaunchControls,
) {
  return Object.values(launchControls).every((value) => value === 0);
}

export function validateLaunchControls(
  launchControls: ClientInputLaunchControls,
) {
  const valuesAreNumeric = Object.values(launchControls).every(
    (value) => typeof value === "number",
  );

  if (!valuesAreNumeric) {
    return false;
  }

  const valuesAreFromZeroToAHundred = Object.values(
    launchControls as ValidLaunchControls,
  ).every((value) => value >= 0 && value <= 100);

  if (!valuesAreFromZeroToAHundred) {
    return false;
  }

  const sumEqualsAHundred =
    Object.values(launchControls as ValidLaunchControls).reduce(
      (total, value) => total + value,
      0,
    ) === 100;

  return sumEqualsAHundred;
}
