import Immutable from "immutable";

import { type ClientLegacy } from "resourceModels";

export interface ZAWPlatformAttributes {
  name: string;
  fullName: string;
  enabled: boolean;
  auth: {
    subdomain?: string;
    email?: string;
    apiToken?: string;
  };
  isVisibleToClient: boolean;
}

class ZendeskAgentWorkspacePlatformRecord extends Immutable.Record({
  name: "zaw_liveagent",
  fullName: "Zendesk Social Messaging",
  enabled: true,
  auth: Immutable.Map({
    subdomain: "",
    email: "",
    apiToken: "",
  }),

  isVisibleToClient(client: ClientLegacy): boolean {
    return client.features.get("zendeskAgentWorkspace") || false;
  },
}) {}

export { ZendeskAgentWorkspacePlatformRecord };
