import styled from "styled-components";

import { c, v } from "constants/css";

export const ReferenceListContainer = styled.div`
  margin-bottom: 24px;
`;

export const ReferenceListContent = styled.div`
  max-height: 208px;
  overflow: auto;
`;

export const ReferenceListGradient = styled.div<{ visible: boolean }>`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  pointer-events: none;
  transition: opacity 0.4s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const ReferenceButton = styled.button`
  display: block;
  width: 284px;
  min-height: 6 * ${v.gUnit};
  height: auto;
  border: 1px solid ${c.colorGrey2};
  box-sizing: border-box;
  box-shadow: 0 1px 0 ${c.colorGrey2};
  border-radius: ${v.smallBorderRadius};
  background: none;
  margin: auto;
  margin-bottom: ${v.gUnit};
  padding: 14px 8px 14px 8px;
  cursor: pointer;
  outline: none;
  color: inherit;
`;

export const InfoGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h5`
  font-size: 16px;
  font-weight: ${v.mediumFontWeight};
`;

export const Message = styled.div`
  white-space: pre-wrap;
`;

export const Language = styled.h6`
  margin-left: ${v.gUnit};
  text-transform: uppercase;
  font-weight: ${v.heavyFontWeight};
  font-size: ${v.xSmallFontSize};
  color: ${c.colorGrey4};
`;

export const TagGroup = styled.div`
  margin-top: -6px;
  display: flex;
  align-self: flex-start;
`;

export const Tag = styled.div`
  margin-right: ${v.gUnit};
`;
