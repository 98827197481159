import * as Immutable from "immutable";

import { selectClient } from "services/client";
import { type LanguageCode } from "services/language";
import { type State } from "types";

export const selectClientTranslatedLanguageCodes = (
  state: State,
): Immutable.List<LanguageCode> => {
  const client = selectClient(state);

  if (!client) {
    return Immutable.List<LanguageCode>();
  }

  return Immutable.List(client.translatedLanguages);
};
