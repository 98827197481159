import axios from "axios";
import * as Immutable from "immutable";
import stringify from "json-stable-stringify";

import { createAlert } from "actions/alerts";
import { getModelByResourceType } from "resourceModels";
import { getResource } from "selectors/resources";
import { selectClient } from "services/client";
import { datadogLogs } from "services/datadog";
import { sha224 } from "services/hash";
import { keyConverter } from "services/key-converter";
import { snakeCaseKeys } from "services/object";
import { socket } from "services/sockets/socket";
import { camelCaseToSnakeCase } from "services/strings";

/**
 * We plan to deprecate the resource model abstraction.
 *
 * Here is a proposal for why:
 * https://www.notion.so/adasupport/Proposal-Deprecating-Resource-Models-5d6ab2c830034df5b37e9c4e4b3fbd2a
 *
 * Here is a discussion on Slack about the proposal:
 * https://adasupport.slack.com/archives/CHATBHNTB/p1605484940067900
 *
 * If you want to understand how they work anyways, here is some documentation:
 * https://www.notion.so/adasupport/Client-side-data-management-6fd012c2e20f455596e618e2fdb94d70#5bb9eea8fc5d4237b339e0c2190c12de
 */

/**
 * @typedef ApiRequestOptions
 * @property {String} method
 * @property {String} endpoint
 * @property {String} [responseType]
 * @property {Object} [payload]
 * @property {Object} [params]
 */

/**
 * @param {ApiRequestOptions} options
 * @returns {Function.<Promise>}
 */
function makeApiRequest(options) {
  const { method, endpoint, payload = null, params, responseType } = options;

  return (dispatch) =>
    new Promise((resolve, reject) =>
      // eslint-disable-next-line no-promise-executor-return
      dispatch({
        // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
        CALL_API: {
          method,
          endpoint,
          payload,
          params,
          responseType,
          dispatchCallbacks: [
            {
              request(successResponse) {
                resolve(successResponse.response);
              },
              fireOnStatus: "success",
            },
            {
              request(errorResponse) {
                reject(errorResponse.response);
              },
              fireOnStatus: "error",
            },
          ],
        },
      }),
    );
}

// Time we are going to wait for WS "success" event before showing an error message
const WS_TIMEOUT = 60 * 1000; // milliseconds
const TIMEOUT_ERROR_MESSAGE = `Did not get response within ${
  WS_TIMEOUT / 1000
} seconds.`;

/**
 * @param {ApiRequestOptions} options
 * @returns {String}
 */
function getRequestDetailsAsText(options) {
  return [
    `Method: ${options.method}`,
    `Endpoint: ${options.endpoint}`,
    `Params: ${options.params ? JSON.stringify(options.params) : "none"}`,
  ].join(", ");
}

/**
 * @param {Client} client
 * @param {ApiRequestOptions} apiRequestOptions
 * @returns {String|null}
 */
function getChannelId(client, apiRequestOptions) {
  const apiParams = { ...apiRequestOptions.params };

  if (apiParams) {
    apiParams.reset_cache = undefined;
  }

  const params = axios.defaults.paramsSerializer(apiParams);

  return client
    ? `${client.id}_${sha224(`${apiRequestOptions.endpoint}?${params}`)}`
    : null;
}

/**
 * Creates a WebSocket connection and handles 202 request response
 * then waits for a WS event to make a new request, expecting 200 with data.
 *
 * This is needed to avoid keeping HTTP connection open for too long, which can block API server.
 *
 * @param {ApiRequestOptions} options
 * @returns {AsyncAction.<Promise>}
 */
export function makeAsyncApiRequest(options) {
  return (dispatch, getState) => {
    // Make a regular request if method is "post"
    // We're no able to generate channelId in this case
    if (options.method === "post") {
      return dispatch(makeApiRequest(options));
    }

    /** @type {Client} */
    const client = selectClient(getState());

    // Generate Channel ID from client.id and request URL
    // The same algorithm is used on the backend
    const channelId = getChannelId(client, options);

    // If channel ID can not be generated, make a regular request
    if (!channelId) {
      return dispatch(makeApiRequest(options));
    }

    if (options.endpoint.startsWith("/analytics")) {
      // eslint-disable-next-line no-param-reassign
      options.params = { ...options.params, channel: channelId };
    }

    /**
     * If socket connection state === false, need not bother with the rest of this function, dispatch the api request and move on
     * this saves app from failing when pusher is down
     */
    if (!getState().session.socketConnected) {
      return dispatch(makeApiRequest(options));
    }

    // If socket is connected we will subscribe to the specified channel and proceed normally
    const channel = socket.instance.subscribe(channelId);
    const promise = new Promise((resolve) =>
      // eslint-disable-next-line no-promise-executor-return
      channel.bind("pusher:subscription_succeeded", resolve),
    );

    // Immediately subscribe to "success" and "failure" events. Create promises.
    const onWSEventSuccess = new Promise((r) =>
      // eslint-disable-next-line no-promise-executor-return
      channel.bind("success", () => r()),
    );
    const onWSEventFailure = new Promise((r) =>
      // eslint-disable-next-line no-promise-executor-return
      channel.bind("failure", () => r()),
    );

    return promise
      .then(() => dispatch(makeApiRequest(options)))
      .then(
        (response) =>
          new Promise((resolve, reject) => {
            // If we've got `Accepted` response
            if (response.status === 202) {
              if (!channel) {
                throw new Error("No channel to subscribe to");
              }

              // On timeout
              const timeout = setTimeout(() => {
                dispatch(
                  createAlert({
                    message: TIMEOUT_ERROR_MESSAGE,
                    alertType: "error",
                  }),
                );
                datadogLogs.logger.error(TIMEOUT_ERROR_MESSAGE, options);
                reject(
                  new Error(
                    `${TIMEOUT_ERROR_MESSAGE} ${getRequestDetailsAsText(
                      options,
                    )}`,
                  ),
                );
              }, WS_TIMEOUT);

              // On success
              onWSEventSuccess.then(() => {
                clearTimeout(timeout);
                const optionsWithoutResetCache = { ...options };

                if (optionsWithoutResetCache.params) {
                  optionsWithoutResetCache.params.reset_cache = undefined;
                }

                resolve(dispatch(makeApiRequest(optionsWithoutResetCache)));
              });

              // On failure
              onWSEventFailure.then(() => {
                clearTimeout(timeout);
                reject(new Error('WebSocket sent "failure" event'));
              });

              // Stop here
              return;
            }

            // If it wasn't 202 - just return the response
            resolve(response);
          }),
      )
      .finally(() => {
        // Remove all handlers from channel
        channel.unbind();

        // Unsubscribe for channel
        socket.instance.unsubscribe(channelId);
      });
  };
}

/**
 * Creates a POST request to create a resource
 * @deprecated
 *
 * @param {String} resourceType
 * @param {Object} attributes
 * @returns {ThunkAction<Promise<Record<string, unknown>>>}
 */
export function createOne(resourceType, attributes) {
  const Model = getModelByResourceType(resourceType);
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();

  const requestKey = "create-one";

  return (dispatch) => {
    const request = dispatch(
      makeApiRequest({
        method: "post",
        endpoint: Model.getCreateOneUrl(),
        payload: keyConverter(attributes, "underscore"),
      }),
    ).then(
      (response) => {
        const normalizedPayload = Model.resolveCreateOneResult(response.data);

        // Push resource from "included" array, if present
        if (normalizedPayload.included) {
          dispatch({
            type: "PUSH_RESOURCES",
            payload: normalizedPayload,
          });
        }

        dispatch({
          type: `CREATE_ONE_${RESOURCE_TYPE}_SUCCESS`,
          requestKey,
          response,
        });

        return response;
      },
      (response) => {
        dispatch({
          type: `CREATE_ONE_${RESOURCE_TYPE}_FAILURE`,
          requestKey,
        });

        throw response;
      },
    );

    dispatch({
      type: `CREATE_ONE_${RESOURCE_TYPE}_REQUEST`,
      requestKey,
    });

    return request;
  };
}

/**
 * Creates a GET request to get a single resource.
 * Caches the request's promise in state and clears it after response is resolved
 * If `id` is passed, it is used in the request URL, e.g. GET /languages/123
 * If not - a "default" request is made, e.g. GET /client/
 * The request URL is constructed through `Model.getFetchOneUrl` method, which can be overwritten
 *
 * @param {String} resourceType
 * @param {String} id
 * @returns {AsyncAction}
 */
export function fetchOne(resourceType, id = null) {
  const Model = getModelByResourceType(resourceType);
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();

  const requestKey = id || "default";

  return (dispatch, getState) => {
    // Check if there's a pendingRequest already
    let pendingRequest = getState().resources[resourceType].getIn([
      "requests",
      requestKey,
      "pendingRequest",
    ]);

    // If there's a pending request, just return the promise
    if (pendingRequest) {
      return pendingRequest;
    }

    // Store request in pendingRequest variable
    pendingRequest = dispatch(
      makeAsyncApiRequest({
        method: "get",
        endpoint: Model.getFetchOneUrl(id),
      }),
    ).then(
      (response) => {
        const normalizedPayload = Model.resolveFetchOneResult(response.data);

        // Push resource from "included" array, if present
        if (normalizedPayload.included) {
          dispatch({
            type: "PUSH_RESOURCES",
            payload: normalizedPayload,
          });
        }

        dispatch({
          type: `FETCH_ONE_${RESOURCE_TYPE}_SUCCESS`,
          requestKey,
          response,
        });

        return response;
      },
      (response) => {
        dispatch({
          type: `FETCH_ONE_${RESOURCE_TYPE}_FAILURE`,
          requestKey,
          response,
        });

        throw response;
      },
    );

    dispatch({
      type: `FETCH_ONE_${RESOURCE_TYPE}_REQUEST`,
      requestKey,
      pendingRequest,
    });

    return pendingRequest;
  };
}

/**
 * Creates a GET request to get an array of resources.
 * Caches the request's promise in state and clears it after response is resolved
 * Optionally an `options.filter` parameter can be passed - an Object or Immutable.Record
 * Filter's key/values pairs are be used in GET request as query parameters
 *
 * @param {String} resourceType
 * @param {Object|Immutable.Map} options
 * @param {Object|Immutable.Record} [options.filter]
 * @param {Object|Immutable.Map} [options.page]
 * @param {String|Object} [options.sort]
 * @param {String} [options.method]
 * @returns {AsyncAction}
 */
export function fetchMany(resourceType, options = {}) {
  const Model = getModelByResourceType(resourceType);
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();
  const optionsObject = Immutable.Map.isMap(options)
    ? options.toObject()
    : options;
  let { filter, page } = optionsObject;
  const { sort, resetCache } = optionsObject;
  const { lastSort } = options;

  if (Immutable.Record.isRecord(filter)) {
    filter = filter.toJS();
  }

  if (Immutable.Map.isMap(page)) {
    page = page.toJS();
  }

  const requestKey =
    filter || page || sort || resetCache
      ? stringify({
          filter,
          page,
          sort,
          resetCache,
        })
      : "all";

  return (dispatch, getState) => {
    // Check if there's a pendingRequest already
    let pendingRequest = getState().resources[resourceType].getIn([
      "requests",
      requestKey,
      "pendingRequest",
    ]);

    // If there's a pending request, just return the promise
    if (pendingRequest) {
      return pendingRequest;
    }

    const requestParams = Model.getFetchManyUrlParams({
      filter: snakeCaseKeys(filter),
      page,
      sort,
      reset_cache: resetCache,
      ...(lastSort && { last_sort: lastSort }),
    });

    // Store request in pendingRequest variable
    pendingRequest = dispatch(
      makeAsyncApiRequest({
        method: options.method || "get",
        endpoint: Model.getFetchManyUrl(),
        params: options.method === "post" ? {} : requestParams,
        payload: options.method === "post" ? requestParams : {},
      }),
    ).then(
      (response) => {
        const normalizedPayload = Model.resolveFetchManyResult(response.data);

        // Push resource from "included" array, if present
        if (normalizedPayload.included) {
          dispatch({
            type: "PUSH_RESOURCES",
            payload: normalizedPayload,
          });
        }

        dispatch({
          type: `FETCH_MANY_${RESOURCE_TYPE}_SUCCESS`,
          requestKey,
          response,
        });

        return response;
      },
      (response) => {
        dispatch({
          type: `FETCH_MANY_${RESOURCE_TYPE}_FAILURE`,
          requestKey,
          response,
        });

        throw response;
      },
    );

    dispatch({
      type: `FETCH_MANY_${RESOURCE_TYPE}_REQUEST`,
      requestKey,
      pendingRequest,
    });

    return pendingRequest;
  };
}

/**
 * Creates a PATCH request to update a single resource.
 * Gets request body from resource's changedAttributes.
 * If `id` is passed, it is used in the request URL, e.g. PATCH /languages/123
 * If not - a "default" request is made, e.g. PATCH /client/
 *
 * @param {String} resourceType
 * @param {String} id
 * @returns {AsyncAction}
 */
export function saveOne(resourceType, id = null) {
  const Model = getModelByResourceType(resourceType);
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();

  const requestKey = `save-one-${id || "default"}`;

  return (dispatch, getState) => {
    // Find a resource to save
    const resource = getResource(getState(), resourceType, id);

    if (!resource) {
      throw new Error(
        `Trying to save resource "${resourceType}" with id "${id}", but it's not found`,
      );
    }

    const request = dispatch(
      makeApiRequest({
        method: "patch",
        endpoint: Model.getSaveOneURL(id),
        payload: resource.serialize(), // Get the request payload from resource changed attributes
      }),
    ).then(
      (response) => {
        const normalizedPayload = Model.resolveSaveOneResult(response.data);

        // Push resource from "included" array, if present
        if (normalizedPayload.included) {
          dispatch({
            type: "PUSH_RESOURCES",
            payload: normalizedPayload,
          });
        }

        dispatch({
          type: `SAVE_ONE_${RESOURCE_TYPE}_SUCCESS`,
          requestKey,
          response,
        });

        return response;
      },
      (response) => {
        dispatch({
          type: `SAVE_ONE_${RESOURCE_TYPE}_FAILURE`,
          requestKey,
        });

        throw response;
      },
    );

    dispatch({
      type: `SAVE_ONE_${RESOURCE_TYPE}_REQUEST`,
      requestKey,
    });

    return request;
  };
}

/**
 * Updates resource's changedAttributes without making a request to API.
 * To save changes call saveOne(resourceType, id) after.
 *
 * @param {String} resourceType
 * @param {String} id
 * @param {Object} newAttributes
 * @returns {AsyncAction}
 */
export function updateOne(resourceType, id, newAttributes) {
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();

  return (dispatch, getState) => {
    const resource = getResource(getState(), resourceType, id);

    if (!resource) {
      throw new Error(
        `Trying to update resource "${resourceType}" with id "${id}", but it's not found`,
      );
    }

    dispatch({
      type: `UPDATE_${RESOURCE_TYPE}_ATTRIBUTES`,
      id,
      newResourceRecord: resource.updateAttributes(newAttributes),
    });
  };
}

/**
 * Revert a resource's changes by remove changedAttributes without making
 * a request to API.
 *
 * @param {ResourceType} resourceType
 * @param {String} id
 * @returns {AsyncAction}
 */
export function resetOne(resourceType, id) {
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();

  return (dispatch, getState) => {
    const resource = getResource(getState(), resourceType, id);

    if (!resource) {
      throw new Error(
        `Trying to reset resource "${resourceType}" with id "${id}", but it's not found`,
      );
    }

    dispatch({
      type: `UPDATE_${RESOURCE_TYPE}_ATTRIBUTES`,
      id,
      newResourceRecord: resource.reset(),
    });
  };
}

/**
 * Creates a DELETE request to delete a single resource
 *
 * @param {String} resourceType
 * @param {String} id
 * @returns {AsyncAction}
 */
export function deleteOne(resourceType, id) {
  const Model = getModelByResourceType(resourceType);
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();

  const requestKey = `delete-one-${id}`;

  return (dispatch, getState) => {
    // Find a resource to save
    const resource = getResource(getState(), resourceType, id);

    if (!resource) {
      throw new Error(
        `Trying to delete resource "${resourceType}" with id "${id}", but it's not found`,
      );
    }

    const request = dispatch(
      makeApiRequest({
        method: "delete",
        endpoint: Model.getDeleteOneURL(id),
      }),
    ).then(
      (response) => {
        dispatch({
          type: `DELETE_ONE_${RESOURCE_TYPE}_SUCCESS`,
          requestKey,
          id,
        });

        return response;
      },
      (response) => {
        dispatch({
          type: `DELETE_ONE_${RESOURCE_TYPE}_FAILURE`,
          requestKey,
        });

        throw response;
      },
    );

    dispatch({
      type: `DELETE_ONE_${RESOURCE_TYPE}_REQUEST`,
      requestKey,
    });

    return request;
  };
}

/**
 * Send action request
 *
 * @param {String} resourceType
 * @param {String} id
 * @param {String} actionType
 */
export function postAction(resourceType, id, actionType) {
  const Model = getModelByResourceType(resourceType);
  const RESOURCE_TYPE = camelCaseToSnakeCase(resourceType).toUpperCase();
  const requestKey = `post-action-${id}-${actionType}`;

  return (dispatch) => {
    const request = dispatch(
      makeApiRequest({
        method: "post",
        endpoint: Model.getPostActionURL(id, actionType),
      }),
    ).then(
      (response) => {
        dispatch({
          type: `POST_ACTION_${RESOURCE_TYPE}_SUCCESS`,
          response,
          requestKey,
        });

        return response;
      },
      (response) => {
        dispatch({
          type: `POST_ACTION_${RESOURCE_TYPE}_FAILURE`,
          requestKey,
        });

        throw response;
      },
    );

    dispatch({
      type: `POST_ACTION_${RESOURCE_TYPE}_REQUEST`,
      requestKey,
    });

    return request;
  };
}

/**
 * @param {Object} featureData
 * @returns {Object}
 */
export function patchFeature(featureData) {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "patch",
      endpoint: "/features/",
      payload: featureData,
    },
  };
}
