export interface Metadata {
  createdAt: string;
  expireAt: string;
}

interface ApiKeyState {
  confirmationWarning: boolean;
  loading: boolean;
  loaded: boolean;
  error: boolean;
  apiKey: string | null | undefined;
  metadata: Metadata | null | undefined;
}

export interface ApiKeyAction {
  type: string;
  apiKey?: string | null;
  metadata?: Metadata | null;
}

export const initialState: ApiKeyState = {
  confirmationWarning: false,
  loading: false,
  loaded: false,
  error: false,
  apiKey: null,
  metadata: null,
};

export function reducer(state: ApiKeyState, action: ApiKeyAction): ApiKeyState {
  switch (action.type) {
    case "SHOW_CONFIRMATION_WARNING":
      return { ...state, confirmationWarning: true };
    case "CLOSE_CONFIRMATION_WARNING":
      return { ...state, confirmationWarning: false };
    case "CREATE_KEY_REQUEST":
      return { ...state, loading: true };
    case "CREATE_KEY_SUCCESS":
      return {
        ...state,
        loading: false,
        apiKey: action.apiKey,
        metadata: action.metadata,
      };
    case "CREATE_KEY_FAILURE":
      return { ...state, loading: false, error: true };
    case "GET_KEY_REQUEST":
      return { ...state, loading: true };
    case "GET_KEY_SUCCESS":
      return {
        ...state,
        loading: false,
        loaded: true,
        metadata: action.metadata,
      };
    case "GET_KEY_FAILURE":
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
