import Immutable from "immutable";

/**
 * A constructor for `requests` records to store in the state
 */
export class RequestState extends Immutable.Record<{
  isFailed: boolean;
  isResolved: boolean;
  result: string | string[] | Immutable.List<string> | undefined;
  pendingRequest: unknown;
  meta?: Immutable.Map<string, unknown>;
  errorCode: null;
  errorData: Immutable.Map<string, unknown>;
}>({
  isFailed: false,
  isResolved: false,
  result: undefined,
  pendingRequest: null,
  meta: Immutable.Map(),
  errorCode: null,
  errorData: Immutable.Map(),
}) {
  /**
   * It's pending if it's not resolved nor failed
   */
  get isPending() {
    return !this.isResolved && !this.isFailed;
  }
}
