import Immutable from "immutable";

import { createAlert } from "actions/alerts";
import { closeModal, closeModalAction, openModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { adaApiRequest } from "services/api";
import { keyConverter } from "services/key-converter";

export const AuthenticationOauthRecord = Immutable.Record({
  name: "",
  authType: "",
  tokenUrl: "",
  redirectUri: "",
  authUrl: "",
  clientId: "",
  clientSecret: "",
  scope: Immutable.List([""]),
  certificateName: "",
  enabled: true,
  pkceEnabled: false,
});

/**
 * Validates OAuth payload
 * @param {Immutable.Record} authentication
 * @returns {Immutable.Record}
 */
function validateOauth(authentication) {
  let scopeSet = new Immutable.Set(authentication.get("scope"));
  scopeSet = scopeSet.filter(Boolean);

  return new AuthenticationOauthRecord(
    authentication.set("scope", scopeSet.toList()),
  );
}

/**
 * Validates Authentication payload
 * @param {Immutable.Record} authentication
 * @returns {Immutable.Record}
 */
function validateAuthentication(authentication) {
  return validateOauth(authentication);
}

/**
 * Creates callback blocks in CALL_API
 * @param {Boolean} isModal
 * @param {String} requestMethod
 * @returns {List}
 */
function generateCallbacks(isModal, requestMethod) {
  let message = "Authentication has been successfully updated.";

  if (requestMethod === "delete") {
    message = "Authentication has been successfully deleted.";
  } else if (requestMethod === "post") {
    message = "Authentication has been successfully created.";
  }

  const dispatchCallbacks = [
    {
      request: createAlert,
      fireOnStatus: "success",
      args: {
        message,
        alertType: "success",
      },
    },
  ];

  if (isModal) {
    dispatchCallbacks.push({
      request: closeModal,
      fireOnStatus: "success",
    });
  } else {
    dispatchCallbacks.push({
      request: createAlert,
      fireOnStatus: 400,
    });
  }

  return dispatchCallbacks;
}

export function openVariableReferenceModalAction(variable) {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Variable in Use",
        message:
          "The variable for this authentication is currently in use. Please remove all references to the variable before deleting the authentication.",
        actions: [
          {
            title: "Go to Variable Manager",
            buttonTint: "alert",
            onClick: () => {
              dispatch(closeModalAction());
              dispatch(setPageAction(`/answers/variables?f=${variable.name}`));
            },
          },
          {
            title: "Close",
            onClick: () => {
              dispatch(closeModalAction());
            },
          },
        ],
      }),
    );
  };
}

/**
 * call patch authentication api
 * @param {Immutable.Record} authentication
 * @param {Boolean} isModal
 * @returns {Object}
 */
export function patchAuthentication(authentication, isModal) {
  const payload = keyConverter(
    validateAuthentication(authentication),
    "underscore",
  );
  const dispatchCallbacks = generateCallbacks(isModal, "patch");

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "patch",
      endpoint: `/auths/${authentication.name}/`,
      payload,
      types: [
        "PATCH_AUTHENTICATION_REQUEST",
        "PATCH_AUTHENTICATION_SUCCESS",
        "PATCH_AUTHENTICATION_FAILURE",
        "PATCH_AUTHENTICATION_ABORTED",
      ],
      cancelPrevious: true,
      dispatchCallbacks,
    },
  };
}

/**
 * call post authentication api
 * @param {Immutable.Record} authentication
 * @returns {Object}
 */
export function postAuthentication(authentication) {
  const payload = keyConverter(
    validateAuthentication(authentication),
    "underscore",
  );
  const dispatchCallbacks = generateCallbacks(true, "post");

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      endpoint: "/auths/",
      payload,
      types: [
        "POST_AUTHENTICATION_REQUEST",
        "POST_AUTHENTICATION_SUCCESS",
        "POST_AUTHENTICATION_FAILURE",
        "POST_AUTHENTICATION_ABORTED",
      ],
      cancelPrevious: true,
      dispatchCallbacks,
    },
  };
}

/**
 * call delete authentication api
 * @param {String} authenticationName
 * @returns {Object}
 */
export function deleteAuthentication(authenticationName) {
  return async (dispatch) => {
    try {
      const response = await dispatch(
        adaApiRequest({
          method: "DELETE",
          url: `/auths/${authenticationName}/`,
        }),
      );
      dispatch(
        createAlert({
          message: "Authentication has been successfully deleted.",
          alertType: "success",
        }),
      );
      dispatch({
        type: "DELETE_AUTHENTICATION_SUCCESS",
        response,
      });
    } catch (error) {
      // open modal to direct user to variable manager
      const { variable } = error.response.data;
      dispatch(openVariableReferenceModalAction(variable));
    }
  };
}

/**
 * call post authentication certificate api
 * @param {String} filename
 * @param {String} extension
 * @param {String} base64Content
 * @returns {Object}
 */
export function postCertificate(filename, extension, base64Content) {
  const payload = keyConverter(
    {
      filename,
      extension,
      base64Content,
    },
    "underscore",
  );

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      endpoint: "/manage-oauth/certificate/",
      payload,
      types: [
        "POST_AUTHENTICATION_CERTIFICATE_REQUEST",
        "POST_AUTHENTICATION_CERTIFICATE_SUCCESS",
        "POST_AUTHENTICATION_CERTIFICATE_FAILURE",
        "POST_AUTHENTICATION_CERTIFICATE_ABORTED",
      ],
      cancelPrevious: true,
    },
  };
}

export const handlePkceToggle = (payload) => ({
  type: "TOGGLE_PKCE_STATE",
  payload,
});

/**
 * call get authentication api
 * @returns {Object}
 */
export function handleGetAllAuthentication() {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "get",
      endpoint: "/auths/integrations/",
      types: [
        "GET_ALL_AUTHENTICATION_REQUEST",
        "GET_ALL_AUTHENTICATION_SUCCESS",
        "GET_ALL_AUTHENTICATION_FAILURE",
        "GET_ALL_AUTHENTICATION_ABORTED",
      ],
      cancelPrevious: true,
    },
  };
}

export const updateOrCreateAuthentication = () => (dispatch, getState) => {
  const { authenticationModal, modal } = getState();

  if (authenticationModal.get("invalidFields").size) {
    return {};
  }

  if (modal.modalProps.newAuthentication) {
    return dispatch(postAuthentication(authenticationModal));
  }

  return dispatch(patchAuthentication(authenticationModal, true));
};

/**
 * handle update authentication modal
 * @param {Object} payload
 * @returns {Object}
 */
export const handleUpdateAuthenticationModal = (payload) => ({
  type: "UPDATE_AUTHENTICATION_MODAL",
  payload,
});
/**
 * handle add empty scope to scope list
 * @returns {Object}
 */
export const handleAddAuthenticationScope = () => ({
  type: "ADD_AUTHENTICATION_SCOPE",
});

/**
 * handle update scope in scope list
 * @param {Object} payload
 * @returns {Object}
 */
export const handleUpdateAuthenticationScope = (payload) => ({
  type: "UPDATE_AUTHENTICATION_SCOPE",
  payload,
});

/**
 * handle remove scope in scope list
 * @param {Object} payload
 * @returns {Object}
 */
export const handleRemoveAuthenticationScope = (payload) => ({
  type: "REMOVE_AUTHENTICATION_SCOPE",
  payload,
});

/**
 * handle create new authentication modal
 * @returns {Object}
 */
export const createAuthenticationModal = () => ({
  type: "CREATE_AUTHENTICATION_MODAL",
});

/**
 * handle set fields in authentication modal
 * @param {Object} payload
 * @returns {Object}
 */
export const setAuthenticationModal = (payload) => ({
  type: "SET_AUTHENTICATION_MODAL",
  payload,
});

/**
 * handle remove existing certificate in authentication modal
 * @returns {Object}
 */
export const handleRemoveAuthenticationCertificate = () => ({
  type: "REMOVE_AUTHENTICATION_CERTIFICATE",
});

/**
 * validates the fields in the authentication modal
 * @returns {Object}
 */
export const validateAutenticationModal = () => ({
  type: "VALIDATE_AUTHENTICATION_MODAL",
});
