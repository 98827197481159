import React, { useEffect, useState } from "react";

import { Flex, FlexColumn } from "components/Common";
import { RightOperand } from "components/Common/RulesetEditor/RightOperand";
import {
  RULE_DSL,
  type RuleCondition,
  isRuleConditionUnary,
} from "services/rules";
import { type Variable } from "services/variables";

import { LeftOperandVariableSelector } from "./LeftOperandVariableSelector";
import { RuleOperatorSelector } from "./RuleOperatorSelector";
import {
  BOOLEAN_OPERATOR_OPTIONS,
  DEFAULT_BOOLEAN_ARGUMENT,
  DEFAULT_NUMBER_ARGUMENT,
  DEFAULT_STRING_ARGUMENT,
  LIST_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  STRING_OPERATOR_OPTIONS,
} from "./helpers";

export const RuleConditionComponent = <R extends RuleCondition>({
  rule,
  onChange,
  variables,
  highlightInvalidFields,
  predefinedValues,
  isDisabled,
}: {
  rule: R;
  onChange: (rule: R) => void;
  variables: Variable[];
  highlightInvalidFields?: boolean;
  predefinedValues: {
    [key: string]: { value: string; label: string }[] | undefined;
  };
  isDisabled?: boolean;
}) => {
  // We want to keep rule object in state in case it's not valid
  // In that case we don't want to call onChange
  const [updatedRule, setUpdatedRule] = useState(rule);
  useEffect(() => setUpdatedRule(rule), [rule]);

  const [leftArg, rightArg] = updatedRule.args;

  const onVariableChange = (id: string | null) => {
    const newVariable = variables.find((v) => v._id === id);

    // Update operator if new variable type is incompatible with current operator
    const newRuleName = (() => {
      switch (newVariable?._type) {
        case "bool":
          return BOOLEAN_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS;
        case "long":
          return NUMBER_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS;
        case "list":
          return LIST_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS_SET;
        default:
          return STRING_OPERATOR_OPTIONS.some((o) => o.value === rule.name)
            ? rule.name
            : RULE_DSL.IS;
      }
    })();

    // Update right argument if new variable type is incompatible with current right argument type
    const newRightArg = (() => {
      switch (newVariable?._type) {
        case "long":
          return typeof rightArg === "number"
            ? rightArg
            : DEFAULT_NUMBER_ARGUMENT;
        case "bool":
          return typeof rightArg === "boolean"
            ? rightArg
            : DEFAULT_BOOLEAN_ARGUMENT;
        default:
          return typeof rightArg === "string"
            ? rightArg
            : DEFAULT_STRING_ARGUMENT;
      }
    })();

    const newRule = {
      ...rule,
      name: newRuleName,
    };

    onChange({
      ...newRule,

      // Remove right argument if operator changes to unary (IS_SET, IS_NOT_SET)
      args: isRuleConditionUnary(newRule)
        ? [{ ...leftArg, id }]
        : [{ ...leftArg, id }, newRightArg],
    });
  };

  return (
    <>
      <FlexColumn style={{ flexBasis: "30%" }}>
        <Flex gap justifyContent="flex-end">
          <FlexColumn grow={1} shrink={1}>
            <LeftOperandVariableSelector
              errorMessage="Required"
              isDisabled={isDisabled}
              isInvalid={highlightInvalidFields && !leftArg.id}
              onChange={onVariableChange}
              value={leftArg.id}
              variables={variables}
            />
          </FlexColumn>
        </Flex>
      </FlexColumn>

      <FlexColumn style={{ flexBasis: "30%", maxWidth: 80 }}>
        <RuleOperatorSelector
          rule={rule}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </FlexColumn>
      <FlexColumn grow={1} shrink={1} noBasis>
        <RightOperand
          highlightInvalidFields={highlightInvalidFields}
          isDisabled={isDisabled}
          onChange={onChange}
          predefinedValues={predefinedValues}
          rule={rule}
          setUpdatedRule={setUpdatedRule}
          updatedRule={updatedRule}
          variables={variables}
        />
      </FlexColumn>
    </>
  );
};
