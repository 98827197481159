import { primitives } from "@adasupport/byron";
import styled from "styled-components";

import { cssVariables as c } from "constants/css";

export const LaunchControlsTab = styled.div`
  height: 100%;
  padding: 18px;
  overflow-y: scroll;
`;

export const LaunchControlInputRows = styled.div`
  margin-top: ${c.space6};
`;

export const LaunchControlInputRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${c.space2} 0;
`;

export const LaunchControlsTabHeader = styled.div`
  padding-bottom: ${c.space2};

  & h4 {
    font-weight: ${c.mediumFontWeight};
    font-size: ${c.smallFontSize};
    margin-bottom: ${c.space1};
  }

  & p {
    color: ${primitives.palette.slate500};
    font-size: ${c.smallFontSize};
    line-height: 21px;
  }
`;
export const LaunchControlInput = styled.input`
  width: 42px !important;
  height: 36px;
  border-radius: 6px;
  padding: 6px;
  transition: all 150ms ease;
  user-select: none;
  box-shadow: inset ${primitives.palette.slate400} 0 0 0 1px;
  border: none;
  display: block;
  cursor: text;
  font-size: ${c.smallFontSize};
  text-align: center;

  &:focus {
    box-shadow: inset ${primitives.palette.slate700} 0 0 0 2px;
    outline: 2px solid ${primitives.palette.slate200};
  }
`;

export const LaunchControlInputWrapper = styled.div`
  position: relative;
  padding-right: 36px;

  &:after {
    content: "%";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 24%;
  }
`;

export const LaunchControlsValidationMessage = styled.div<{ isValid: boolean }>`
  color: ${(p) =>
    p.isValid ? primitives.palette.green700 : primitives.palette.red600};
  font-size: ${c.xSmallFontSize};
  display: flex;
  align-items: center;
  padding: 40px 0 0 0;
  & span {
    font-weight: ${c.xHeavyFontWeight};
    margin-left: ${c.space2};
  }
`;
export const LaunchControlInputLabel = styled.label`
  font-size: ${c.smallFontSize};
  font-weight: ${c.mediumFontWeight};
`;

export const AlternativeBotHandleInput = styled.input<{
  isValid: boolean | null;
}>`
  width: 300px !important;
  height: 36px;
  border-radius: 6px;
  padding: 6px;
  transition: all 150ms ease;
  user-select: none;
  box-shadow: inset
    ${(p) =>
      p.isValid === false
        ? primitives.palette.red400
        : primitives.palette.slate400}
    0 0 0 1px;
  border: none;
  display: block;
  cursor: text;
  font-size: ${c.smallFontSize};
  margin-right: 8px;

  &:focus {
    box-shadow: inset ${primitives.palette.slate700} 0 0 0 2px;
    outline: 2px solid ${primitives.palette.slate200};
  }
`;

export const AlternativeBotHandleInputWrapper = styled.div`
  position: relative;
`;

export const InvalidAlternativeBotHandleMessage = styled.p`
  font-size: ${c.xSmallFontSize};
  color: ${primitives.palette.red600};
  position: absolute;
  padding-top: ${c.space1};
`;

export const AlternativeBotPreview = styled.div`
  padding: 40px 0 18px 0;
`;

export const AlternativeBotPreviewURLInputWrapper = styled.div`
  font-size: ${c.smallFontSize};
  display: flex;
  align-items: center;
  margin-top: ${c.space2};
`;

export const AlternativeBotPreviewURLInput = styled.input`
  width: 236px !important;
  height: 36px;
  border-radius: 6px;
  padding: 6px;
  transition: all 150ms ease;
  user-select: none;
  box-shadow: inset ${primitives.palette.slate400} 0 0 0 1px;
  border: none;
  display: block;
  cursor: text;
  font-size: ${c.smallFontSize};

  &:focus {
    box-shadow: inset ${primitives.palette.slate700} 0 0 0 2px;
    outline: 2px solid ${primitives.palette.slate200};
  }
`;

export const AlternativeBotPreviewURLInputLabel = styled.label`
  font-size: ${c.xSmallFontSize};
  padding: 0 ${c.space2};
  color: ${primitives.palette.slate500};
`;

export const AlternativeBotPreviewAction = styled.div``;

export const AlternativeBotHandleInfo = styled.p`
  font-size: ${c.xSmallFontSize};
  color: ${primitives.palette.slate500};
  position: absolute;
  padding-top: ${c.space1};
  white-space: nowrap;
`;

export const BoldenedText = styled.span`
  font-weight: ${c.heavyFontWeight};
  font-size: ${c.smallFontSize};
`;
