import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

import { InputLabel } from "components/Common/InputLabel";
import { colorPrimitives, v } from "constants/css";

export const ModalInput = styled(InputLabel)`
  width: 100%;
`;

export const OptionalLabelContainer = styled.div`
  span {
    margin-left: ${v.space1};
    color: ${colorPrimitives.slate600};
  }
`;

export const SectionTitle = styled.h4`
  font-size: ${primitives.fontSize.default};
  font-weight: ${primitives.fontWeight.semibold};
  line-height: ${primitives.lineHeight.default};
  color: ${tokens.colors.text.main};
  padding-bottom: ${primitives.spacing.space2};
`;
