import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updatePlatform } from "actions";
import { getBotRolloutsFromLaunchControls } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/LaunchControlsTab/alternativeBotRollout";
import {
  type ValidLaunchControls,
  shouldInitializeLaunchControls,
  validateLaunchControls,
} from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/LaunchControlsTab/launchControls";
import { useClient } from "services/client";
import { useChatPlatform } from "services/platforms";

async function validateAlternativeBotClientHandle(
  clientHandle: string,
  dashboardClientHandle: string,
) {
  if (!clientHandle || clientHandle === dashboardClientHandle) {
    return false;
  }

  const clientURL = clientHandle
    .concat(".")
    .concat(window.location.host.split(".").slice(1).join("."));

  try {
    await axios.get(`${window.location.protocol}//${clientURL}/api`);
  } catch (e) {
    return false;
  }

  return true;
}

export function useAlternativeBotRollout() {
  const { chatPlatform } = useChatPlatform();
  const { client } = useClient();
  const { alternativeBot } = chatPlatform;
  const [alternativeBotHandle, setAlternativeBotHandle] = useState(
    alternativeBot?.handle || "",
  );
  const [isValidAlternativeBotHandle, setIsValidAlternativeBotHandle] =
    useState<null | boolean>(null);

  const dispatch = useDispatch();
  const { launchControls } = chatPlatform;

  useEffect(() => {
    // should only update the primary and/or alternative rollout values if the launch control values are valid
    if (
      !shouldInitializeLaunchControls(launchControls) &&
      validateLaunchControls(launchControls)
    ) {
      const { rollout, alternativeBotRollout } =
        getBotRolloutsFromLaunchControls(launchControls as ValidLaunchControls);

      dispatch(
        updatePlatform("chat", {
          alternativeBot: {
            handle: alternativeBotHandle,
            rollout: alternativeBotRollout,
          },
          rollout,
        }),
      );
    }
  }, [launchControls, dispatch, alternativeBotHandle]);

  useEffect(() => {
    validateAlternativeBotClientHandle(
      alternativeBotHandle,
      client.handle,
    ).then((isValid) => {
      if (!alternativeBotHandle) {
        setIsValidAlternativeBotHandle(null);
      } else if (isValid) {
        setIsValidAlternativeBotHandle(true);
      } else {
        setIsValidAlternativeBotHandle(false);
      }
    });
  }, [alternativeBotHandle, client.handle]);

  return {
    alternativeBotHandle,
    setAlternativeBotHandle,
    isValidAlternativeBotHandle,
    alternativeBotRollout: chatPlatform.alternativeBot?.rollout,
  };
}
