// Use this for backwards compatibility with Locker
class BackwardsCompatibleStorage {
  // `any` really is ok here - we take any data and stringify it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));

    return this;
  }

  // eslint-disable-next-line class-methods-use-this
  retrieve(key: string) {
    return JSON.parse(localStorage.getItem(key) as string);
  }

  destroy(key: string) {
    localStorage.removeItem(key);

    return this;
  }
}

/**
 * @deprecated
 * @see services/browserStorage
 */
export const storage = new BackwardsCompatibleStorage();
