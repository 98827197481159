export interface QuestionsPageMapAttributes {
  pageSize: number;
  searchQuery: string;
  checked: Set<string>;
  checkedAll: boolean;
  checkedDeep: boolean;
  elementIndex: number | null;
  originFilter: string;
  viewingTranslations: boolean;
  mouseSelection: string | null; // Selected substring of a question to train
  mouseSelectionOffset: number | null; // index of the first char of the substring
  [key: string]: unknown;
}
export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST";
export interface GetExpressionGroupsRequestAction {
  type: typeof GET_GROUPS_REQUEST;
}

export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const TRAIN_EXPRESSION_GROUP_SUCCESS = "TRAIN_EXPRESSION_GROUP_SUCCESS";
export interface GetExpressionGroupsSuccessAction {
  type: typeof GET_GROUPS_SUCCESS | typeof TRAIN_EXPRESSION_GROUP_SUCCESS;
  // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingGroups: any;
}

export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export interface DeleteExpressionsGroupAction {
  type: typeof DELETE_GROUP_REQUEST;
}

export const GET_GROUPS_FAILURE = "GET_GROUPS_FAILURE";
export interface GetExpressionGroupsFailure {
  type: typeof GET_GROUPS_FAILURE;
}

export type ExpressionGroupsActions =
  | GetExpressionGroupsRequestAction
  | GetExpressionGroupsSuccessAction
  | DeleteExpressionsGroupAction
  | GetExpressionGroupsFailure;
