import Immutable from "immutable";

interface ExpressionGroup {
  id: string | null;
  title: string;
  expressionCount: number | null;
  // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  samples: any;
  keywords: string[];
}
const defaultExpressionGroup: ExpressionGroup = {
  id: null,
  title: "",
  expressionCount: null,
  samples: null,
  keywords: [],
};

export class ExpressionGroupRecord extends Immutable.Record(
  defaultExpressionGroup,
) {}

interface ExpressionGroupState {
  loaded: Immutable.List<ExpressionGroupRecord>;
  /**
   * The number of expressions currently loaded
   */
  loadedCount: number;
  loading: boolean;
  error: boolean;
  hasLoadedGroups: boolean;
  /**
   * The total number of expressions on the response
   */
  queryCount: number;
}

const defaultExpressionGroupState: ExpressionGroupState = {
  loaded: Immutable.List([]),
  loadedCount: 0,
  loading: false,
  error: false,
  hasLoadedGroups: false,
  queryCount: 0,
};

export class ExpressionGroupsState extends Immutable.Record(
  defaultExpressionGroupState,
) {}
