import Immutable from "immutable";
import LRU from "lru-cache";

interface MemoizedFunction {
  immutableArgsMap?: unknown[];
  cache?: LRU<unknown, unknown>;
}

/**
 * Memoizes functions with potentially immutable arguments
 * The arguments to the function should either be primitive values
 * or immutable objects, OR one option object with primitive/immutable values
 * `fn` - the function to memoize
 * `max` - maximum entries in the cache
 * @deprecated - Try not to use this function and Immutable stuff. lodash.memoize is a better alternative
 */
export function memoize<T>(fn: T, max = 250): T & MemoizedFunction {
  const memoizedFn = ((...args: unknown[]) => {
    let newArgs = args;

    // if we're passing in an options object as the only argument
    if (newArgs.length === 1 && typeof newArgs[0] === "object") {
      newArgs = Object.values(newArgs[0] as object);
    }

    // keep track of which arguments are immutable
    if (!memoizedFn.immutableArgsMap) {
      memoizedFn.immutableArgsMap = newArgs.map(Immutable.isImmutable);
    }

    if (!memoizedFn.cache) {
      memoizedFn.cache = new LRU({ max });
    }

    const cacheKeyObj = (
      newArgs as Array<{ hashCode?: () => unknown } | undefined>
    ).map((arg, i) =>
      memoizedFn.immutableArgsMap?.[i] && arg?.hashCode ? arg.hashCode() : arg,
    );
    const cacheKey = JSON.stringify(cacheKeyObj);

    if (memoizedFn.cache.has(cacheKey)) {
      return memoizedFn.cache.get(cacheKey);
    }

    const value = (fn as (...args: unknown[]) => unknown)(...args);
    memoizedFn.cache.set(cacheKey, value);

    return value;
  }) as T & MemoizedFunction;

  return memoizedFn;
}
