import { Spinner } from "@adasupport/byron";
import classNames from "classnames";
import camelCase from "lodash.camelcase";
import React, { type CSSProperties, type ReactNode } from "react";

import SvgIcon from "components/Common/SvgIcon";
import "./style.scss";

import * as S from "./styles";
import { type ButtonSize } from "./types";

const BLOCK_NAME = "Button";

export interface ButtonProps {
  /** The SVG icon of the Button */
  icon?: string;
  /** The height of the Button's icon (needed to overwrite default 24px value) */
  iconHeightOverride?: number;
  /** The secondary SVG icon of the Button */
  secondIcon?: string;
  /** The height of the Button's secondary icon (needed to overwrite default 24px value) */
  secondIconHeightOverride?: number;
  /** Stick secondary SVG icon to the right of a button */
  secondIconGroupRight?: boolean;
  /** The text inside the Button */
  text?: string;
  /** The title attribute of the Button for a11y */
  title?: string;
  /** The bright/light colour scheme */
  light?: boolean;
  /** The bright/light colour scheme, with text in color-ui-primary (blue) */
  lightPrimary?: boolean;
  /** Determined button sizing (null/default = medium size) */
  size?: ButtonSize;
  /** The transparent Button style */
  clear?: boolean;
  /** onClick event handler */
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Disables the Button */
  disabled?: boolean;
  /** Left align the button text */
  left?: boolean;
  /** Make the SVG icon larger */
  iconLarge?: boolean;
  /** When true displays a loading indicator instead of icon */
  isLoading?: boolean;
  /** Will fit container */
  stretched?: boolean;
  /** Specify custom styles to override defaults */
  customClassName?: string;
  /** Allows custom styles using styled component syntax, styled(Button), to override defaults */
  className?: string;
  /** Alternative disabled Button style. Requires disabled to be true */
  disabledStyleFlat?: boolean;
  linkLike?: boolean;
  /** icon fill color */
  fillColor?: string;
  iconColor?: string;
  tint?: string;
  /** Whether will display button with background (cosmetic element only) */
  showActiveBackground?: boolean;
  /** Allows tabIndex customization */
  tabIndex?: number;
  children?: ReactNode;
  type?: "button" | "submit" | "reset";
  leftAlignContent?: boolean;
  form?: string | undefined;
  dataTestId?: string | undefined;
}

/** @deprecated Use Button from @adasupport/byron instead */
export class Button extends React.Component<ButtonProps> {
  static defaultProps: ButtonProps = {
    light: false,
    lightPrimary: false,
    clear: false,
    disabled: false,
    left: false,
    iconLarge: false,
    isLoading: false,
    stretched: false,
    disabledStyleFlat: false,
    linkLike: false,
    showActiveBackground: false,
    tabIndex: 0,
    children: null,
    type: "button",
    size: "medium",
    leftAlignContent: false,
    form: undefined,
    dataTestId: undefined,
  };

  /**
   * Get classNames for Button
   * @returns {String}
   */
  getClassName() {
    const {
      icon,
      light,
      lightPrimary,
      disabled,
      size,
      text,
      clear,
      iconLarge,
      disabledStyleFlat,
      customClassName,
      className,
      isLoading,
      stretched,
      linkLike,
      leftAlignContent,
    } = this.props;

    let sizeClassName = "";

    switch (size) {
      case "x-small":
        sizeClassName = "Button--x-small";
        break;
      case "small":
        sizeClassName = "Button--small";
        break;
      default:
    }

    return classNames(BLOCK_NAME, sizeClassName, {
      "Button--glyph": icon,
      "Button--light": light,
      "Button--light-primary": lightPrimary,
      "Button--disabled": disabled,
      "Button--text": text,
      "Button--clear": clear,
      "Button--large-icon": iconLarge,
      "Button--disabled-flat": disabledStyleFlat,
      "Button--loading": isLoading,
      "Button--stretched": stretched,
      "Button--no-text": !text,
      "Button--left-align": leftAlignContent,
      [`${BLOCK_NAME}--link-like`]: linkLike,
      [customClassName ?? ""]: customClassName,
      [className ?? ""]: className,
    });
  }

  /**
   * Set colour and backgroundColor styles of Button based on props
   */
  getStyle(): CSSProperties {
    const { tint, light } = this.props;
    const styles: CSSProperties = {};

    if (tint) {
      styles[light ? "color" : "backgroundColor"] = tint;
    }

    return styles;
  }

  /**
   * Conditionally render Button icon
   */
  getIcon() {
    const { icon, iconHeightOverride, iconColor, fillColor } = this.props;

    if (!icon) {
      return null;
    }

    return (
      <SvgIcon
        icon={icon}
        height={iconHeightOverride}
        fillColor={iconColor || fillColor}
      />
    );
  }

  /**
   * Conditionally render secondary Button icon
   */
  getSecondIcon() {
    const {
      secondIcon,
      secondIconHeightOverride,
      secondIconGroupRight,
      iconColor,
      fillColor,
    } = this.props;

    if (!secondIcon) {
      return null;
    }

    const icon = (
      <SvgIcon
        icon={secondIcon}
        height={secondIconHeightOverride}
        fillColor={iconColor || fillColor}
        customClassName="Button--second-glyph"
      />
    );

    if (secondIconGroupRight) {
      return <span className="secondIconGroupRight">{icon}</span>;
    }

    return icon;
  }

  /**
   * If showActiveBackground is true, will display button with background (cosmetic element only)
   */
  static getActiveBackground() {
    return <span className="Button__active-background" />;
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const {
      clear,
      size,
      title,
      text,
      icon,
      onClick,
      secondIcon,
      disabled,
      isLoading,
      left,
      showActiveBackground,
      tabIndex,
      children,
      type,
      form,
      dataTestId,
    } = this.props;

    const autoId = text || title || "no-text";

    return (
      <S.Button
        className={this.getClassName()}
        clear={clear}
        onClick={onClick}
        title={title || text || ""}
        tabIndex={tabIndex}
        disabled={disabled}
        data-testid={dataTestId || camelCase(autoId)}
        type={type}
        size={size}
        form={form}
      >
        {icon && !isLoading && this.getIcon()}
        {showActiveBackground && Button.getActiveBackground()}
        {isLoading && (
          <div className="Button--spinner">
            <Spinner color="dark" />
          </div>
        )}
        <S.Text
          className={classNames("Button__text", {
            "Button__text--left": left,
          })}
          style={this.getStyle()}
        >
          {text}
        </S.Text>
        {secondIcon && !isLoading && this.getSecondIcon()}
        {children}
      </S.Button>
    );
  }
}
