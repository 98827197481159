import Immutable from "immutable";

import { type ClientLegacy } from "resourceModels";

class NuanceLAPlatformRecord extends Immutable.Record({
  name: "nuance_liveagent",
  fullName: "Nuance Live Agent",
  auth: Immutable.Map({
    redirectUri: "",
    clientId: "",
    clientSecret: "",
  }),
  siteId: "",
  enabled: true,

  isVisibleToClient(client: ClientLegacy): boolean {
    return (
      client.features.get("nuanceLiveAgent") ||
      client.features.get("adaGlassNuance") ||
      false
    );
  },
}) {}

export { NuanceLAPlatformRecord };
