import { Icon, IconButton, primitives, tokens } from "@adasupport/byron";
import React, { type ReactNode, useEffect } from "react";
import { Transition } from "react-transition-group";
import { type TransitionStatus } from "react-transition-group/Transition";
import styled, { css } from "styled-components";

import { v } from "constants/css";

const S = {
  SimpleModal: styled.div`
    pointer-events: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    z-index: 10000;
  `,

  ModalMask: styled.div<{ state: TransitionStatus }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(10, 15, 20, 0.4);
    transition: opacity 200ms ease-in-out;
    ${(p) =>
      p.state === "exiting" || p.state === "exited"
        ? css`
            opacity: 0;
          `
        : null}
  `,

  ModalWindow: styled.div<{ state: TransitionStatus }>`
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    border-radius: ${v.mainBorderRadius};
    background: ${primitives.palette.white};
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    ${(p) =>
      p.state === "exiting" || p.state === "exited"
        ? css`
            transform: translate3d(0, 100vh, 0);
            opacity: 0;
          `
        : null}
  `,

  Title: styled.div`
    color: ${tokens.colors.text.heading};
    height: 52px;
    padding: 8px 24px;
    border-bottom: 1px solid ${tokens.colors.border.default};
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    flex-shrink: 0;
  `,

  CloseButton: styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${v.space2};
  `,
};

export const ModalContent = styled.div<{ width?: number }>`
  padding: 24px;
  width: ${(p) => p.width || 600}px;
  flex-shrink: 1;
  overflow: auto;
`;

/** @alias ModalContent */
export const SimpleModalContent = ModalContent;

export const ModalFooter = styled.div`
  border-top: 1px solid ${tokens.colors.border.default};
  padding: 16px 24px;
  flex-shrink: 0;
`;

export const SimpleModal = ({
  children,
  title,
  isShown,
  onClose,
}: {
  children: ReactNode;
  title: string | ReactNode;
  isShown: boolean;
  onClose: () => void;
}) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault();
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  return (
    <Transition in={isShown} timeout={200} mountOnEnter unmountOnExit>
      {(state) => (
        <S.SimpleModal>
          <S.ModalMask state={state} onClick={onClose} />
          <S.ModalWindow state={state}>
            {title !== null && (
              <S.Title>
                {title}
                <S.CloseButton>
                  <IconButton
                    icon={Icon.Close}
                    aria-label="Close Modal"
                    onClick={onClose}
                    iconColor={primitives.palette.slate600}
                    variant="tertiary"
                  />
                </S.CloseButton>
              </S.Title>
            )}
            {children}
          </S.ModalWindow>
        </S.SimpleModal>
      )}
    </Transition>
  );
};
