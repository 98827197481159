/* We want to allow direct access to client.product_type in this component */
/* eslint-disable no-restricted-syntax */
import { type ReactNode, useCallback } from "react";

import { useClient } from "services/client";

/**
 * NOTE: Do NOT use this unless you really have to!
 *
 * ProductSplitter is meant to be used sparingly.
 * We do not want app to consist of what are essentially feature flags on the product type everywhere. This couples the code between the product types.
 * When dealing with differences between products, our first effort should be to have different components.
 * However, in cases where the difference between product types is deeply nested within a product tree of otherwise shared code, this can be a reasonable solution.
 */

interface SplitOnProductProps {
  declarative?: unknown;
  generative?: unknown;
  freeTrial?: unknown;
}

export function useSplitOnProduct() {
  const { client } = useClient();

  return useCallback(
    ({
      declarative = null,
      generative = null,
      freeTrial = null,
    }: SplitOnProductProps) => {
      if (client.product_type === "generative") {
        if (client.botStatus === "free_trial") {
          return freeTrial;
        }

        return generative;
      }

      return declarative;
    },
    [client.product_type, client.botStatus],
  );
}

interface ProductSplitterProps {
  declarative?: ReactNode | null;
  generative?: ReactNode | null;
  freeTrial?: ReactNode | null;
}

export function ProductSplitter({
  declarative = null,
  generative = null,
  freeTrial = null,
}: ProductSplitterProps): JSX.Element | null {
  const splitOnProduct = useSplitOnProduct();

  return splitOnProduct({
    declarative,
    generative,
    freeTrial,
  }) as JSX.Element;
}
