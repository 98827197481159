import type Immutable from "immutable";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { sendProactiveSmsCampaignAction } from "actions/campaigns";
import { closeModalAction } from "actions/modal";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { Select } from "components/Common/Select";
import { type Language } from "resourceModels";
import { useClient } from "services/client";
import { type LanguageCode, useLanguage } from "services/language";

import * as S from "./styles";

function formatDataForTriggerSmsRequest(
  csvContent: string,
  campaignKey: string,
  campaignLanguage: string,
): Record<string, unknown> | null {
  const rows = csvContent.split(/\r?\n/) as [string, ...string[]];
  const headers = rows[0].split(",");

  if (headers.indexOf("phone_number") === -1) {
    // phone_number is a required property
    return null;
  }

  const recipients = rows
    .slice(1)
    .map((row) =>
      headers.reduce(
        (obj, header, i) => ({ ...obj, [header]: row.split(",")[i] }),
        {},
      ),
    )
    .map((storage) => ({ meta_fields: storage }));

  return {
    language: campaignLanguage,
    campaign_key: campaignKey,
    recipients,
  };
}

function buildLanguagesOptions(
  translatedLanguages: readonly LanguageCode[],
  defaultLanguage: string,
  allLanguages: Immutable.List<Language>,
) {
  const languageOptions = translatedLanguages.map((language: string) => ({
    label: allLanguages.find((l) => l.code === language)?.name,
    value: language,
  }));

  return [
    // the client's default language isn't in translatedLanguages
    // plus we want it to have a unique label
    {
      label: `Default (${
        allLanguages.find((l) => l.code === defaultLanguage)?.name
      })`,
      value: defaultLanguage,
    },
    ...languageOptions,
  ];
}

const PLACEHOLDER_STRING = `phone_number\n5553214567\n\nor\n\nphone_number,variable_1,variable_2\n5553214567,Jennifer,Diaz`;

interface Props {
  campaignKey: string;
}

export function CampaignUploadCsvModal({ campaignKey }: Props) {
  const { client } = useClient();
  const { allLanguages, fetchAllLanguages } = useLanguage();
  const dispatch = useDispatch();

  const [csvContent, setCsvContent] = useState("");
  const [campaignLanguage, setCampaignLanguage] = useState(client.language);
  const [isCsvInvalid, setIsCsvInvalid] = useState(false);

  let selectedFile: HTMLElement | null;

  useEffect(() => {
    if (!allLanguages) {
      fetchAllLanguages();
    }
  }, [allLanguages, fetchAllLanguages]);

  function parseCsv(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    if (!file) {
      // this happens if the user cancels the file dialog
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const res = reader.result;

      if (typeof res === "string") {
        setCsvContent(res);
      }
    };

    reader.readAsText(file);
  }

  return (
    <div className="Modal__modal Modal__modal--small">
      <div className="Modal__content">
        <S.ModalSection>
          <S.ModalTitle>Send to a list of customers</S.ModalTitle>
          <S.ModalDescription>
            You can manually add one row for each customer that you want to send
            this Campaign to, or populate the list with a CSV. (If you want to
            test the Campaign, enter your own phone number.)
          </S.ModalDescription>
        </S.ModalSection>
        <S.ModalSection>
          <Banner icon="info-circle" iconVerticalPosition="top">
            Read our&nbsp;
            <a
              href="https://docs.ada.cx/sms-proactive-campaigns"
              target="_blank"
              rel="noreferrer noopener"
            >
              <S.BoldUnderlinedText>
                SMS Campaign documentation
              </S.BoldUnderlinedText>
            </a>{" "}
            to learn how to format your CSV and how to include customer metadata
            in your Campaign content.
            <br />
            <br />
            Even manually entered recipients need to follow the CSV formatting –
            including, at minimum, <S.BoldText>phone_number</S.BoldText> in the
            first row.
          </Banner>
        </S.ModalSection>
        <S.ModalSection>
          <S.TextareaHeader>
            <S.TextareaLabel>
              <S.BoldTextLine>Campaign recipients</S.BoldTextLine>
            </S.TextareaLabel>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <div
              onClick={() => selectedFile?.click()}
              role="button"
              tabIndex={0}
            >
              <S.UploadButton>Upload a CSV</S.UploadButton>
              <input
                type="file"
                ref={(file) => {
                  selectedFile = file;
                }}
                style={{ display: "none" }}
                accept="text/csv"
                onChange={parseCsv}
              />
            </div>
          </S.TextareaHeader>
          <DebouncedInput
            value={csvContent}
            onChange={(content: string) => {
              setCsvContent(content);
              setIsCsvInvalid(false);
            }}
            isInvalid={isCsvInvalid}
            placeholder={PLACEHOLDER_STRING}
            isTextArea
          />
        </S.ModalSection>
        {allLanguages && (
          <S.ModalSection>
            <S.LanguageSelectLabel>
              What language should we send this Campaign in?
            </S.LanguageSelectLabel>
            <Select
              options={buildLanguagesOptions(
                client.translatedLanguages,
                client.language,
                allLanguages,
              )}
              value={campaignLanguage}
              onChange={(language) =>
                setCampaignLanguage(language as LanguageCode)
              }
            />
          </S.ModalSection>
        )}
      </div>

      <S.ButtonsContainer className="Modal__bottom">
        <Button
          light
          onClick={() => {
            dispatch(closeModalAction());
          }}
          text="Cancel"
          title="Cancel"
        />
        <Button
          onClick={() => {
            const data = formatDataForTriggerSmsRequest(
              csvContent,
              campaignKey,
              campaignLanguage,
            );

            if (!data) {
              setIsCsvInvalid(true);

              return;
            }

            dispatch(sendProactiveSmsCampaignAction(data));
            dispatch(closeModalAction());
          }}
          text="Send to these customers"
          title="Send to these customers"
          disabled={!csvContent}
        />
      </S.ButtonsContainer>
    </div>
  );
}
