import queryString from "query-string";
import { createSelector } from "reselect";

import { type State } from "types";

export const selectActiveVariant = createSelector(
  (state: State) => state.router.location,
  (location) => {
    const { search } = location;
    const urlSearchParams = queryString.parse(search);

    return urlSearchParams.variantIndex
      ? Number(urlSearchParams.variantIndex)
      : 0;
  },
);
