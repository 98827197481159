import styled from "styled-components";

import { v } from "constants/css";

export const Content = styled.p`
  vertical-align: middle;
  margin-bottom: ${v.gUnit};
  line-height: ${v.gUnitTriple};
  text-align: center;
`;
