import styled from "styled-components";

import { c, v } from "constants/css";

export const IntroModal = styled.div`
  padding: 0 55px;
`;

export const Link = styled.a`
  font-size: ${v.smallFontSize};
  font-weight: ${v.mediumFontWeight};
  margin-left: ${v.gUnit};
`;

export const LinkContainer = styled.div`
  margin: 65px 0 165px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoBed = styled.div`
  display: flex;
  height: 82px;
  width: 82px;
  border-radius: 82px;
  background: ${c.colorWhite};
  border: 1px solid ${c.colorGrey1};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 11px;
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.03);
`;

export const IntroLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`;
