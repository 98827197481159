import Immutable from "immutable";

import {
  type HANDOFF_INTEGRATION_BASIC_CONFIGURATION_TYPE,
  type HANDOFF_INTEGRATION_OAUTH_CONFIGURATION_TYPE,
} from "components/Shared/Pages/Settings/SettingsPlatforms/constants";
import { type FeatureFlag } from "services/clientLegacy";
import { type TypedMap } from "types";

export interface HandoffIntegrationAttributes {
  id: string | null;
  name: string;
  display_name: string;
  description: string;
  icon: string;
  help_docs_url: string | null;
  feature_flag_name: FeatureFlag;
  ada_message_metadata: Immutable.Map<string, unknown>;
  required_metadata: Immutable.Map<string, unknown>;
  handoff_metadata: Immutable.Map<string, unknown>;
  configuration_schema: TypedMap<{
    type:
      | typeof HANDOFF_INTEGRATION_OAUTH_CONFIGURATION_TYPE
      | typeof HANDOFF_INTEGRATION_BASIC_CONFIGURATION_TYPE;
  }>;
  input_schema: Immutable.Map<string, unknown>;
  output_schema: Immutable.Map<string, unknown>;
}

// Describes a whole integration, which may contain multiple input and output fields
export class HandoffIntegrationRecord extends Immutable.Record<HandoffIntegrationAttributes>(
  {
    id: null,
    name: "",
    display_name: "",
    description: "",
    icon: "",
    help_docs_url: null,
    feature_flag_name: "" as FeatureFlag,
    ada_message_metadata: Immutable.Map(),
    required_metadata: Immutable.Map(),
    handoff_metadata: Immutable.Map(),
    configuration_schema: Immutable.Map(),
    input_schema: Immutable.Map(),
    output_schema: Immutable.Map(),
  },
) {}
