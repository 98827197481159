function pluralize(number: number, string: string): string {
  if (number === 1) {
    return string;
  }

  if (string.slice(-1) === "s") {
    return string;
  }

  return `${string}s`;
}

export { pluralize };
