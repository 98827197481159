import { type TreeNodeProps } from "rc-tree";
import React from "react";

import SvgIcon from "components/Common/SvgIcon";
import { colorPrimitives, cssVariables, v } from "constants/css";

import { LeafIcon } from "../../LeafIcon";

import * as S from "./styles";

export const treeStyles: React.CSSProperties = {
  width: "100%",
  zIndex: v.zIndexAlert,
};

export const renderPlaceholder = (isOpen?: boolean) => (
  <S.DropDownPlaceHolder>
    <S.PlaceholderText>
      {isOpen && (
        <SvgIcon
          icon="Search"
          height={cssVariables.mediumSvgIconHeight.numHeight}
        />
      )}
      Search Folders
    </S.PlaceholderText>
    <SvgIcon
      icon="ChevronDown"
      height={cssVariables.smallSvgIconHeight.numHeight}
    />
  </S.DropDownPlaceHolder>
);

export const renderClearIcon = () => (
  <S.ClearIcon>
    <SvgIcon icon="Close" height={cssVariables.smallSvgIconHeight.numHeight} />
  </S.ClearIcon>
);

export const renderIcon = (
  nodeProps: TreeNodeProps,
  isSearchMode: boolean,
  hasVoiceEnabled = false,
) => (
  <S.IconSpacing isSearchMode={isSearchMode} isLeaf={nodeProps.isLeaf ?? true}>
    <LeafIcon nodeProps={nodeProps} hasVoiceEnabled={hasVoiceEnabled} />
  </S.IconSpacing>
);

export const dropdownStyle: React.CSSProperties = {
  zIndex: v.zIndexModal,
  background: colorPrimitives.white,
  boxShadow: "0 0 0 1px rgb(0 0 0 / 5%), 0 0 10px 0 rgb(0 0 0 / 18%)",
  borderRadius: v.gUnit,
  cursor: "pointer",
};
