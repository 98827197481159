// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
import type Immutable from "immutable";
import stringify from "json-stable-stringify";
import memoize from "lodash.memoize";
import { createSelector } from "reselect";

import { type ResourcesState } from "reducers/resources/types";
import { type ResourceModelTypes, type ResourceType } from "resourceModels";
import { type RequestState } from "services/requestState";

interface State {
  resources: ResourcesState;
}

function getResourceState(
  state: State,
  resourceType: ResourceType,
): Immutable.Map<string, unknown> {
  const resourceState = state.resources[resourceType];

  if (!resourceState) {
    throw Error(`Resource \`${resourceType}\` is not registered in state`);
  }

  return resourceState;
}

export function createGetResourceRecordsSelector(
  resourceType: any,
): (state: State) => Record<string, unknown> {
  return (state) => getResourceState(state, resourceType).getIn(["byId"]);
}

export function createGetAllResourcesRequestSelector(
  resourceType: any,
): (state: State) => Record<string, unknown> {
  return (state) =>
    getResourceState(state, resourceType).getIn(["requests", "all"]);
}

export function createGetPatchOneMessageTemplatesRequestSelector(
  resourceType: any,
): (state: State) => Record<string, unknown> {
  return (state) =>
    getResourceState(state, resourceType).getIn(["requests", "patchOne"]);
}

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
export function createGetAllMessageTemplatesSelector(
  resourceType: any,
  getResourceRecordsSelector: any,
  getAllResourcesRequestSelector: any,
): (state: State) => Record<string, unknown> | null {
  return createSelector(
    getResourceRecordsSelector,
    getAllResourcesRequestSelector,
    (records: any, request: any) => {
      if (request && request.get("result")) {
        return (request.get("result") as Immutable.List<string>).map(
          (id: string) => records.get(id),
        );
      }

      return null;
    },
  );
}

export const getResourcesSelector = memoize((resourceType) =>
  createSelector(
    (state: State) => getResourceState(state, resourceType),

    (resourceState) =>
      memoize(
        (requestParams: unknown) => {
          const request = resourceState.getIn([
            "requests",
            requestParams ? stringify(requestParams) : "all",
          ]);

          if (!request || !request.get("result")) {
            return null;
          }

          return request
            .get("result")
            .map((id: string) => resourceState.getIn(["byId", id]));
        },
        (requestParams) => stringify(requestParams),
      ),
  ),
);

export const getResourcesRequestSelector = memoize((resourceType) =>
  createSelector(
    (state: State) => state.resources[resourceType],

    (resourceState) =>
      memoize((requestParams: string | unknown) => {
        let requestKey;

        if (typeof requestParams === "string") {
          requestKey = requestParams;
        } else if (requestParams) {
          requestKey = stringify(requestParams);
        } else {
          requestKey = "all";
        }

        const request = resourceState.getIn(["requests", requestKey]);

        if (!request) {
          return null;
        }

        return request;
      }),
  ),
);

export function getResources<K extends ResourceType>(
  state: State,
  resourceType: K,
  requestParams?: unknown,
): Immutable.List<ResourceModelTypes[K]> | null {
  return getResourcesSelector(resourceType)(state)(requestParams);
}

export function getResourcesRequest(
  state: State,
  resourceType: ResourceType,
  requestParams: unknown | string,
): RequestState | null {
  return getResourcesRequestSelector(resourceType)(state)(requestParams);
}

export function getResource<K extends ResourceType>(
  state: State,
  resourceType: K,
  id: string | null = null,
): ResourceModelTypes[K] | null {
  const resourceId =
    id ||
    getResourceState(state, resourceType).getIn([
      "requests",
      "default",
      "result",
    ]);

  return state.resources[resourceType]?.getIn(["byId", resourceId]) || null;
}
