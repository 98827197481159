// Allow redux to dispatch multiple actions via Array

import { type Middleware } from "@reduxjs/toolkit";

const multi: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) =>
    Array.isArray(action) ? action.filter(Boolean).map(dispatch) : next(action);

export default multi;
