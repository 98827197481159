// @ts-strict-ignore
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { useReadOnly } from "components/Common/ReadOnlyWrapper";
import { Select } from "components/Common/Select";
import {
  completeABTestAction,
  deleteBuilderABTestAction,
} from "features/ABTesting/actions";
import {
  generateVariantsOptions,
  getResponseABTest,
} from "features/ABTesting/services";

import * as S from "./styles";

interface Props {
  modalType: "declare_winner" | "delete_test";
  responseId: string;
  responseHandle: string;
}

export function DeclareWinnerBuilderABTestModal(props: Props) {
  const { modalType, responseId, responseHandle } = props;

  const responseABTest = useSelector((state) =>
    getResponseABTest(responseId, state.builderABTestsState.builderABTests),
  );
  const variantOptions = generateVariantsOptions(
    responseABTest?.variantNames || [],
  );
  const [variantIndex, setVariantIndex] = useState(-1);
  const variantName = responseABTest?.variantNames[variantIndex];

  const { isReadOnly } = useReadOnly();
  const dispatch = useDispatch();

  const choosingWinner = modalType === "declare_winner";
  const title = choosingWinner ? "Declare a winner" : "Delete this A/B Test";
  const responseSelectorLabel = choosingWinner
    ? "Choose this variant as the winner:"
    : "Keep this variant:";
  const responseSelectorPlaceholder = choosingWinner
    ? "Choose a winning variant"
    : "Choose a variant to keep";
  const infoBannerText = choosingWinner
    ? `When you choose a winner, we’ll remove all other variants, and "${variantName}" will become the only content in your Answer "${responseHandle}". We’ll also set the status of this A/B Test to Complete.`
    : "All other variants will be removed when you delete this A/B Test.";
  const confirmButtonText = choosingWinner
    ? "Declare winner"
    : "Delete this A/B test";

  return (
    <div className="Modal__modal Modal__modal--small">
      <S.ModalContent>
        <S.ModalSection>
          <S.ModalTitle>{title}</S.ModalTitle>
          {!choosingWinner && (
            <S.ModalDescription>
              You need to choose which variant to keep as the content of your
              Answer before this A/B Test can be deleted.
            </S.ModalDescription>
          )}
        </S.ModalSection>
        <S.ModalSection>
          <label
            className="g-input__label"
            htmlFor="builderABTestBusinessEventId"
          >
            {responseSelectorLabel}
          </label>
          <Select
            value={variantIndex}
            onChange={setVariantIndex}
            options={variantOptions}
            placeholder={responseSelectorPlaceholder}
            disabled={isReadOnly}
          />
          <S.MarginTop8px>
            {variantName && <Banner icon="InfoFilled">{infoBannerText}</Banner>}
          </S.MarginTop8px>
        </S.ModalSection>
      </S.ModalContent>
      <S.ButtonsContainer className="Modal__bottom">
        <Button
          light
          onClick={() => dispatch(closeModalAction())}
          text="Cancel"
          title="Cancel"
          disabled={false}
        />
        <Button
          text={confirmButtonText}
          title={confirmButtonText}
          disabled={isReadOnly || variantIndex < 0}
          onClick={() => {
            dispatch(completeABTestAction(responseABTest?.id, variantIndex));

            if (!choosingWinner) {
              dispatch(deleteBuilderABTestAction(responseABTest.id));
            }

            dispatch(closeModalAction());
          }}
        />
      </S.ButtonsContainer>
    </div>
  );
}
