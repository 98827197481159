// @ts-strict-ignore
import Immutable from "immutable";

import { CHARACTER_LIMIT } from "constants/messages";
import { removeTemplateVariables } from "services/strings";
import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

export type ShuffleSubMessage = TypedMap<{
  id: string;
  body: string;
}>;
interface Attributes extends BaseMessageRecordAttributes {
  type: "shuffle";
  messages: Immutable.List<ShuffleSubMessage>;
}

export class ShuffleMessageRecord extends BaseMessageRecord<Attributes>({
  type: "shuffle",
  messages: Immutable.List([
    Immutable.Map({
      id: null,
      body: "",
    }),
  ]),
}) {
  static getInvalidFields: InvalidFieldsGetter<ShuffleMessageRecord> = (
    message,
  ) => {
    const invalidFields: Set<string> = new Set();

    message.messages.forEach((shuffleMessage, i) => {
      const body = shuffleMessage.get("body");
      const bodyWithoutTemplateVars = removeTemplateVariables(body);

      if (
        body.trim().length <= 0 ||
        bodyWithoutTemplateVars.trim().length > CHARACTER_LIMIT
      ) {
        invalidFields.add(`${i}`);
      }
    });

    return Immutable.List(invalidFields);
  };
}
