import React, { type ReactElement } from "react";

import * as Modal from "components/Common/ModalElements/styles";
import Variable from "components/Common/Variable";
import { type VariableConflict } from "components/Shared/Pages/Responses/ResponseVersions/actions";
import { c, v } from "constants/css";
import { type VariableRecord } from "services/variables";

import * as S from "./styles";

interface Props {
  conflict: VariableConflict;
  onUseLatest(): void;
  onUseVersioned(): void;
  closeModal(): void;
  currentVariable: VariableRecord;
  versionedVariable: VariableRecord;
}

export function RestoreVariableWarningModal({
  conflict,
  onUseLatest,
  onUseVersioned,
  currentVariable,
  versionedVariable,
  closeModal,
}: Props): ReactElement {
  const { conflictType } = conflict;

  return (
    <div>
      <Modal.Title>
        Variable&nbsp;<S.Capitalize>{conflictType}</S.Capitalize>&nbsp;Conflict
      </Modal.Title>

      <Modal.ContentFrame>
        <S.ContentMessage>
          The
          <Variable
            name={currentVariable.name}
            scope={currentVariable.scope}
            type={currentVariable.type}
          />
          {`variable's ${conflictType} was changed since the older version.
          Which variable ${conflictType} would you like added to the new version?`}
        </S.ContentMessage>
      </Modal.ContentFrame>
      <div>
        <Modal.FullWidthButton
          textColor={c.colorUiPrimary}
          fontWeight={v.mediumFontWeight}
          onClick={onUseLatest}
        >
          Use the latest {conflictType}:&nbsp;
          <S.Capitalize>{currentVariable.get(conflictType)}</S.Capitalize>
        </Modal.FullWidthButton>
        <Modal.FullWidthButton
          textColor={c.colorUiPrimary}
          fontWeight={v.mediumFontWeight}
          onClick={onUseVersioned}
        >
          Use the older variable:{" "}
          <S.Capitalize>{versionedVariable.get(conflictType)}</S.Capitalize>
        </Modal.FullWidthButton>
        <Modal.FullWidthButton onClick={closeModal}>
          Cancel
        </Modal.FullWidthButton>
      </div>
    </div>
  );
}

RestoreVariableWarningModal.isSmall = true;
