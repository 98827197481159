import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";

export function useCloseSmartSearchIntegrationsModal() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(closeModalAction());
    dispatch({ type: "RESET_SMART_SEARCH_INTEGRATIONS_MODAL" });
  }, [dispatch]);
}

export function useCloseWebsiteImportModal() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(closeModalAction());
  }, [dispatch]);
}
