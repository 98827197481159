import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/Common/Button";
import { NO_OP_FUNCTION } from "services/helpers";

import "./style.scss";

export class InputPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordShown: false,
    };
    this.passwordInput = React.createRef();
  }

  getPlaceholder() {
    const { placeholder } = this.props;
    const { isPasswordShown } = this.state;

    const shownPasswordPlaceholder = placeholder;
    let hiddenPasswordPlaceholder = "";

    if (isPasswordShown) {
      return shownPasswordPlaceholder;
    }

    shownPasswordPlaceholder.split("").forEach(() => {
      hiddenPasswordPlaceholder += "•";
    });

    return hiddenPasswordPlaceholder;
  }

  /**
   * toggles visibility of password
   */
  changePasswordToggle = () => {
    const { isPasswordShown } = this.state;

    this.passwordInput.current.focus();

    this.setState({
      isPasswordShown: !isPasswordShown,
    });
  };

  /**
   * @returns {ReactElement}
   */
  render() {
    const {
      autoComplete,
      customClassName,
      inputId,
      invalid,
      onKeyDown,
      onFocus,
      onBlur,
      onPasswordChange,
      toggleDisabled,
      value,
      isDisabled,
    } = this.props;

    const { isPasswordShown } = this.state;

    return (
      <div className={`InputPassword ${customClassName}`}>
        <div className="InputPassword__input-container">
          <input
            id={inputId}
            className={`
                InputPassword__input-container__input g-input ph-no-capture
                ${invalid ? "g-input--invalid" : ""}
            `}
            value={value}
            type={isPasswordShown ? "text" : "password"}
            placeholder={this.getPlaceholder()}
            onChange={(e) => onPasswordChange(e)}
            onKeyDown={(e) => (e.key === "Enter" ? onKeyDown() : null)}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete={autoComplete}
            ref={this.passwordInput}
            name="password"
            disabled={isDisabled}
          />
          {!toggleDisabled && (
            <Button
              tabIndex={-1}
              onClick={this.changePasswordToggle}
              title="Toggle View Password"
              icon={isPasswordShown ? "Eye" : "EyeSlash"}
              customClassName="InputPassword__input-container__button"
              clear
              disabled={isDisabled}
            />
          )}
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line react/static-property-placement
InputPassword.propTypes = {
  autoComplete: PropTypes.string,
  customClassName: PropTypes.string,
  inputId: PropTypes.string,
  invalid: PropTypes.bool,
  onPasswordChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  toggleDisabled: PropTypes.bool,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
};

// eslint-disable-next-line react/static-property-placement
InputPassword.defaultProps = {
  autoComplete: "password",
  customClassName: "",
  inputId: undefined,
  invalid: false,
  onPasswordChange: NO_OP_FUNCTION,
  onBlur: NO_OP_FUNCTION,
  onFocus: NO_OP_FUNCTION,
  onKeyDown: NO_OP_FUNCTION,
  placeholder: "",
  toggleDisabled: false,
  value: "",
  isDisabled: false,
};
