import classnames from "classnames";
import React, { Component } from "react";

import SvgIcon from "components/Common/SvgIcon";
import { v } from "constants/css";

import "./style.scss";

const ICON_HEIGHT = v.smallSvgIconHeight.numHeight;

interface ResponseStatusProps {
  customClassName?: string;
  isOn: boolean;
  isSmall?: boolean;
}

class ResponseStatus extends Component<ResponseStatusProps> {
  renderContent() {
    const { isOn, isSmall } = this.props;

    if (isSmall) {
      return <SvgIcon icon="ToggleFalse" height={ICON_HEIGHT} />;
    }

    return isOn ? "Live" : "Hidden";
  }

  render() {
    const { isOn, isSmall, customClassName = "" } = this.props;

    return (
      <div
        className={classnames("ResponseStatus", {
          "ResponseStatus--on": isOn,
          "ResponseStatus--small": isSmall,
          [customClassName]: Boolean(customClassName),
        })}
      >
        {this.renderContent()}
      </div>
    );
  }
}

export default ResponseStatus;
