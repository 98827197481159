// @ts-strict-ignore
import { Button } from "@adasupport/byron";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { addConversationsTopicAction } from "actions/conversationsTopics";
import { closeModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { Loading } from "components/Common/Loading";
import { useConversationsTopics } from "components/Declarative/Pages/Conversations/ConversationsTopics/hooks/useConversationsTopics";
import { useConversationPageParams } from "components/Shared/Pages/Conversations/ConversationsTopics/hooks/useConversationPageParams";
import { NO_OP_FUNCTION } from "services/helpers";
import SvgImage from "source/components/Common/SvgImage";
import TopicCreationImage from "static/images/create_new.svg";

import * as S from "./styles";

const MAX_TITLE_CHAR_COUNT = 60;
const MAX_DESCRIPTION_CHAR_COUNT = 320;

interface Props {
  isNew: boolean;
  initialName?: string;
  initialDescription?: string;
  onSave?: (name: string, description: string) => void;
}

export function CreateConversationsTopicModal({
  isNew,
  initialName = "",
  initialDescription = "",
  onSave = NO_OP_FUNCTION,
}: Props) {
  const pageParams = useConversationPageParams();
  const { isLoading, topicTitles } = useConversationsTopics({
    pageParams,
    skipFetch: isNew,
  });
  const [name, setName] = useState(initialName || "");
  const [description, setDescription] = useState(initialDescription || "");
  const nameExists = topicTitles.some(
    (topicTitle) => topicTitle !== initialName && topicTitle === name,
  );
  const isNameInvalid =
    !name.trim() || name.length > MAX_TITLE_CHAR_COUNT || nameExists;
  const isDescriptionValid = description.length > MAX_DESCRIPTION_CHAR_COUNT;
  const dispatch = useDispatch();

  const titleWarningMessage = () => {
    if (name.length > MAX_TITLE_CHAR_COUNT) {
      return `Title cannot be longer than ${MAX_TITLE_CHAR_COUNT} characters`;
    }

    if (nameExists) {
      return "Topic name already exists";
    }

    return null;
  };

  return (
    <div className="Modal__modal Modal__modal--small">
      <div className="Modal__content">
        <S.ModalSection>
          <S.ModalImageContainer>
            <SvgImage imageHash={TopicCreationImage} />
          </S.ModalImageContainer>
          <div>
            <S.ModalTitle>
              {isNew ? "Define a new Topic" : "Edit Topic"}
            </S.ModalTitle>
            <S.ModalDescription>
              Give your Topic a name and description to make it clear what
              you&apos;re tracking.
            </S.ModalDescription>
          </div>
        </S.ModalSection>
        {isLoading ? (
          <S.Loader>
            <Loading />
          </S.Loader>
        ) : (
          <>
            <S.ModalSection>
              <DebouncedInput
                id="topicName"
                label="Topic Name"
                value={name}
                isInvalid={isNameInvalid}
                placeholder="e.g. Product Error"
                onChange={setName}
                characterCounter={MAX_TITLE_CHAR_COUNT}
                warningMessage={titleWarningMessage()}
              />
            </S.ModalSection>
            <S.ModalSection>
              <DebouncedInput
                id="topicDescription"
                label={
                  <>
                    <span>Description</span>{" "}
                    <S.ModalOptional>(Optional)</S.ModalOptional>
                  </>
                }
                placeholder="e.g. Track all customer references to a particular product error"
                value={description}
                onChange={setDescription}
                isTextArea
                isInvalid={isDescriptionValid}
                characterCounter={MAX_DESCRIPTION_CHAR_COUNT}
                warningMessage={`Description cannot be longer than ${MAX_DESCRIPTION_CHAR_COUNT} characters`}
              />
            </S.ModalSection>
          </>
        )}
      </div>

      <S.ButtonsContainer className="Modal__bottom">
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModalAction());
          }}
          text="Cancel"
        />
        {isNew ? (
          <Button
            variant="primary"
            onClick={() => {
              dispatch(addConversationsTopicAction(name, description));
              dispatch(setPageAction("/conversations/topics/edit/new"));
              dispatch(closeModalAction());
            }}
            text="Next"
            isDisabled={isNameInvalid || isDescriptionValid}
          />
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              onSave(name, description);
              dispatch(closeModalAction());
            }}
            text="Update"
            isDisabled={isNameInvalid || isDescriptionValid}
          />
        )}
      </S.ButtonsContainer>
    </div>
  );
}
