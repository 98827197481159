import styled from "styled-components";

import { cssColors, cssVariables } from "constants/css";

export const ButtonContainer = styled.div`
  opacity: 0;
  transition: opacity ${cssVariables.transitionTime} ease;
`;

export const Root = styled.div`
  background-color: ${cssColors.colorGrey1};
  border-radius: ${cssVariables.gUnit};
  margin: ${cssVariables.gUnit} 0;
  overflow: hidden;

  &:hover ${ButtonContainer} {
    opacity: 1;
  }
`;

export const Header = styled.header`
  background-color: ${cssColors.colorGrey2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${cssVariables.gUnitHalf} ${cssVariables.gUnitHalf}
    ${cssVariables.gUnitHalf} ${cssVariables.gUnitDouble};
`;

export const Language = styled.h2`
  font-size: ${cssVariables.smallFontSize};
  font-weight: ${cssVariables.mediumFontWeight};
  text-transform: capitalize;
`;

export const CodeContainer = styled.div`
  overflow-x: auto;
  padding: ${cssVariables.gUnitDouble};
`;

export const Code = styled.code`
  white-space: pre;
`;
