import React from "react";

import { RadioButton } from "components/Common/RadioButton";

import "./style.scss";

type RadioValue = string | number;

interface Props<V extends RadioValue> {
  options: ReadonlyArray<{
    value: V;
    title: string;
    description?: string;
    longDescription?: string | React.ReactElement;
  }>;
  checked: V;
  name?: string;
  uniqueIdentifier: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (value: V) => void;
  disabled?: boolean;
}

export function RadioButtons<V extends RadioValue>({
  name,
  onChange = () => undefined,
  onClick,
  options,
  checked,
  uniqueIdentifier,
  disabled,
}: Props<V>) {
  return (
    <div className="RadioButtons">
      {options.map((curr) => (
        <RadioButton
          key={`${curr.value}-${uniqueIdentifier}`}
          id={`${curr.value}-${uniqueIdentifier}`}
          name={name}
          checked={checked === curr.value}
          onChange={onChange}
          onClick={() => {
            if (onClick) {
              onClick(curr.value);
            }
          }}
          label={curr.title}
          description={curr.description}
          longDescription={curr.longDescription}
          disabled={disabled}
        />
      ))}
    </div>
  );
}
