import Immutable from "immutable";
import { createSelector } from "reselect";

import {
  GENERATIVE_VERSIONS_PATHNAME_RE,
  VERSIONS_PATHNAME_RE,
} from "components/Shared/Pages/Responses/ResponseVersions/constants";
import { getResponseABTest } from "features/ABTesting/services";
import { type BuilderABTest } from "features/ABTesting/types";
import { type State } from "reducers/types";
import { type VariableState } from "reducers/variables/types";
import { selectClient } from "services/client";
import { selectActiveResponseId } from "services/responses/selectors/selectActiveResponseId";

const pathnameSelector = (state: State) => state.router.location.pathname;
const responseVersionsSelector = (state: State) => state.responseVersions;
const responsesLoadedSelector = (state: State) => state.responsesLoaded;
const responsesSelector = (state: State) => state.responses;
const variablesSelector = (state: State) => state.variables;
const builderABTestsSelector = (state: State) =>
  state.builderABTestsState.builderABTests;

export const versioningViewSelector = createSelector(
  selectClient,
  pathnameSelector,
  (client, path) => {
    let matches = VERSIONS_PATHNAME_RE.exec(path);

    if (matches) {
      return {
        responseId: matches[1],
        versionId: matches[2] || null,
        restore: matches[3] === "restore",
      };
    }

    matches = GENERATIVE_VERSIONS_PATHNAME_RE.exec(path);

    if (matches) {
      return {
        responseId: matches[1],
        versionId: matches[2] || null,
        restore: matches[3] === "restore",
      };
    }

    // Return null if no match is found
    return null;
  },
);

type VersionedVariablesSelectorResponse = (state: State) => VariableState;
export const versionedVariablesSelector: VersionedVariablesSelectorResponse =
  createSelector(
    versioningViewSelector,
    variablesSelector,
    responseVersionsSelector,
    (versioning, variables, responseVersions) => {
      if (!versioning?.versionId) {
        return variables;
      }

      const { responseId, versionId } = versioning;
      const versionedVariables = responseVersions.getIn(
        ["full", responseId, versionId, "snapshot", "variables"],
        Immutable.Map(),
      );

      return versionedVariables;
    },
  );

// Returns the AB test corresponding to the currently viewed response or response version
export const versionedResponseABTestSelector = createSelector(
  versioningViewSelector,
  builderABTestsSelector,
  selectActiveResponseId,
  responseVersionsSelector,
  (versioning, builderABTests, currentResponseId, responseVersions) => {
    if (!versioning?.versionId) {
      return getResponseABTest(currentResponseId as string, builderABTests);
    }

    const { responseId, versionId } = versioning;

    // Have to do .toJS() because keyConverter converts to a map but normally AB tests are plain JS
    return responseVersions
      .getIn(["full", responseId, versionId, "snapshot", "builderAbTest"])
      ?.toJS() as BuilderABTest | undefined;
  },
);

export const versionedResponsesLoadedSelector = createSelector(
  versioningViewSelector,
  responsesLoadedSelector,
  responseVersionsSelector,
  (versioning, responsesLoaded, responseVersions) => {
    if (!versioning?.versionId) {
      return responsesLoaded;
    }

    const { responseId, versionId } = versioning;
    const versionedResponse = responseVersions.getIn([
      "full",
      responseId,
      versionId,
      "snapshot",
      "response",
    ]);

    return responsesLoaded.set(responseId as string, versionedResponse);
  },
);

export const versionedResponsesSelector = createSelector(
  versioningViewSelector,
  responsesSelector,
  responseVersionsSelector,
  (versioning, responses, responseVersions) => {
    if (!versioning?.versionId) {
      return responses;
    }

    const { responseId, versionId } = versioning;
    const versionedResponses = responseVersions.getIn([
      "full",
      responseId,
      versionId,
      "snapshot",
      "shallowResponses",
    ]);

    return versionedResponses;
  },
);

export const versionedSnapshotSelector = createSelector(
  versioningViewSelector,
  responseVersionsSelector,
  (versioning, responseVersions) => {
    if (!versioning?.versionId) {
      return Immutable.Map();
    }

    const { responseId, versionId } = versioning;
    const responseSnapshot = responseVersions.getIn([
      "full",
      responseId,
      versionId,
      "snapshot",
    ]);

    return responseSnapshot;
  },
);
