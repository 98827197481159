import { HandlerType, StatusType, datadogLogs } from "@datadog/browser-logs";

interface stringMap {
  [key: string]: string;
}

// NOTE: only production env logs are being indexed
const ADA_DOMAIN_DD_ENV: stringMap = {
  "ada-dev.support": "dev",
  "ada-dev2.support": "dev2",
  "ada-stage.support": "stage",
  "ada.support": "production",
  "att.ada.support": "production_att",
  "eu.ada.support": "production_eu",
  "maple.ada.support": "production_maple",
  "ficanex.ada.support": "production_ficanex",
};

// Initialize Datadog
if (window.__env.DATADOG_TOKEN) {
  const env = ADA_DOMAIN_DD_ENV[window.__env.SENTRY_ENVIRONMENT];

  datadogLogs.init({
    clientToken: window.__env.DATADOG_TOKEN,
    sessionSampleRate: 100,
    service: "app",
    env,
    forwardErrorsToLogs: false,
  });

  const { hostname } = window.location;
  const hostParts = hostname.split(".");

  datadogLogs.logger.setLevel(StatusType.info);
  datadogLogs.logger.setHandler(HandlerType.http);
  datadogLogs.logger.setContext({
    env,
    client: hostParts[0],
  });
}

export { datadogLogs };
