// Moment is no longer supported. Slack channel #p-remove-moment for details
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";

import { type ThunkAction } from "actions/types";
import { SESSION_TIMEOUT_TIME } from "constants/session";
import {
  EXPIRE_24_HOURS,
  EXPIRE_5_MIN,
  EXPIRE_5_SECS,
  PASSWORD_EXPIRED_WARNING,
  PASSWORD_EXPIRE_24_HOURS_WARNING,
  PASSWORD_EXPIRE_5_MINS_WARNING,
  PASSWORD_TTL_DAYS,
} from "constants/warnings";
import { getResource } from "selectors/resources";
import { selectClient } from "services/client/selectors";
import { DHDate } from "services/dates";

export function setSessionTimeoutModal() {
  return {
    type: "SET_MODAL_SESSION_TIMEOUT",
  };
}

/**
 * Cancel current timer and set new one to expire 24 hours from now
 */
export function resetSessionTimeout(): ThunkAction {
  return (dispatch, getState) => {
    dispatch({
      type: "RESET_SESSION_TIMEOUT",
      payload: {
        sessionTimeout: setTimeout(() => {
          if (getState().session.isAuthenticated) {
            dispatch(setSessionTimeoutModal());
          }
        }, SESSION_TIMEOUT_TIME),
      },
    });
  };
}

/**
 * Action to check the user's password expiry on every API request
 * Uses moments `diff` function
 */
export function checkUserExpiry(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { warnings } = state;

    const client = selectClient(state);
    const user = getResource(state, "user");

    if (!client || !user) {
      return;
    }

    const passwordExpiryEnabled = client.features.password_expiry;

    if (!passwordExpiryEnabled || !user.passwordCreatedDate) {
      return;
    }

    const passwordCreatedDate = new DHDate(user.passwordCreatedDate).date;

    let showModal = false;
    let name = " ";
    const expirePasswordIn24hr = warnings.get(PASSWORD_EXPIRE_24_HOURS_WARNING);
    const expirePasswordIn5min = warnings.get(PASSWORD_EXPIRE_5_MINS_WARNING);
    const timeLeft = moment
      .utc(passwordCreatedDate)
      .add(PASSWORD_TTL_DAYS, "days");

    if (timeLeft.diff(moment.utc(), "seconds") <= EXPIRE_5_SECS) {
      showModal = true;
      name = PASSWORD_EXPIRED_WARNING;
      dispatch({
        type: "SET_PASSWORD_EXPIRED",
      });
    } else if (
      timeLeft.diff(moment.utc(), "minutes") < EXPIRE_5_MIN &&
      !expirePasswordIn5min
    ) {
      showModal = true;
      name = PASSWORD_EXPIRE_5_MINS_WARNING;
    } else if (
      timeLeft.diff(moment.utc(), "minutes") > EXPIRE_5_MIN &&
      timeLeft.diff(moment.utc(), "hours") < EXPIRE_24_HOURS &&
      !expirePasswordIn24hr
    ) {
      showModal = true;
      name = PASSWORD_EXPIRE_24_HOURS_WARNING;
    } else if (timeLeft.diff(moment.utc(), "hours") >= EXPIRE_24_HOURS) {
      // Reset warnings
      dispatch({
        type: "WARNING_CLEAR_ALL",
      });
    }

    if (showModal) {
      dispatch({
        type: "WARNING_SHOWN",
        name,
      });
      dispatch({
        type: "SET_MODAL",
        payload: {
          isOpen: true,
          view: "MODAL_PASSWORD_EXPIRE",
          modalProps: {},
        },
      });
    }
  };
}
