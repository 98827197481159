import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface VoiceEndCallMessageRecordAttributes
  extends BaseMessageRecordAttributes {
  type: "voice_end_call";
}

export class VoiceEndCallMessageRecord extends BaseMessageRecord<VoiceEndCallMessageRecordAttributes>(
  {
    type: "voice_end_call",
  },
) {
  static getInvalidFields = (): Immutable.List<string> => Immutable.List();
}
