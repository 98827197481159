import { tokens } from "@adasupport/byron";
import classNames from "classnames";
import React, { type FC, useEffect, useRef } from "react";
import styled from "styled-components";

import {
  inputTextStyle,
  inputTextStyleFocused,
} from "components/Common/InputText/styles";
import SvgIcon from "components/Common/SvgIcon";
import { NO_OP_FUNCTION } from "services/helpers";
import colors from "stylesheets/utilities/colors.scss";

import "./style.scss";

const BLOCK_NAME = "InputSearch";

const S = {
  Input: styled.input`
    ${inputTextStyle};
    display: block;
    width: 100%;

    &::placeholder {
      font-style: italic;
    }

    &:hover {
      border-color: ${tokens.colors.border.formInputHover};
    }

    &:focus {
      ${inputTextStyleFocused};
    }
  `,
};

interface InputSearchProps {
  autoFocus?: boolean;
  value?: string;
  onClear?: () => void;
  hideChevron?: boolean;
  customClassName?: string;
  className?: string;
  invalid?: boolean;
  idForLabel?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export const InputSearch: FC<InputSearchProps> = ({
  autoFocus = false,
  value,
  onClear = null,
  hideChevron = true,
  customClassName = "",
  className = "",
  invalid = false,
  idForLabel,
  placeholder = null,
  onChange = NO_OP_FUNCTION,
  onSearch = NO_OP_FUNCTION,
  onFocus = NO_OP_FUNCTION,
  onKeyPress = NO_OP_FUNCTION,
  onKeyDown = NO_OP_FUNCTION,
  onBlur = NO_OP_FUNCTION,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const clearInput = () => {
    if (onClear) {
      onClear();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    onSearch(event.target.value);
  };

  const renderRightSideIcon = () => {
    if (value && onClear) {
      return (
        <>
          <div
            className={classNames(`${BLOCK_NAME}__gradient`, {
              [`${BLOCK_NAME}__gradient--invalid`]: invalid,
            })}
          />
          {/* Not going to have a keyboard handler */}
          <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
            role="button"
            className={`${BLOCK_NAME}__icon-container`}
            onClick={clearInput}
          >
            <SvgIcon
              icon="Clear"
              customClassName={`${BLOCK_NAME}__icon-container__icon`}
              fillColor="currentColor"
            />
          </div>
        </>
      );
    }

    if (!hideChevron) {
      return (
        <div
          className={`${BLOCK_NAME}__icon-container InputSearch__icon-container--type-chevron`}
        >
          <SvgIcon
            icon="ChevronDown"
            customClassName={`${BLOCK_NAME}__icon-container__icon`}
            fillColor="currentColor"
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={classNames(`${BLOCK_NAME}`, {
        [customClassName]: customClassName,
        [className]: className,
      })}
    >
      <S.Input
        data-testid="search-field"
        className={classNames(`${BLOCK_NAME}__input`, {
          "g-input--invalid": invalid,
          [`${BLOCK_NAME}__input--right-icon`]: onClear,
        })}
        id={idForLabel}
        value={value}
        type="text"
        placeholder={placeholder || "Search"}
        onChange={handleChange}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        autoComplete="off"
        spellCheck={false}
        ref={inputRef}
        data-prevent-drag-focus={false}
      />
      <div className={`${BLOCK_NAME}__search-icon-container`}>
        <SvgIcon
          icon="Search"
          customClassName={`${BLOCK_NAME}__search-icon-container__icon`}
          fillColor={colors.colorGrey4}
        />
      </div>
      {value && onClear && <div className={`${BLOCK_NAME}__gradient`} />}
      {renderRightSideIcon()}
    </div>
  );
};
