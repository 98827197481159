import type Immutable from "immutable";
import get from "lodash.get";
import { useState } from "react";

export enum SortType {
  ASCENDING,
  DESCENDING,
  NONE,
}
export interface SortOptions {
  key?: string;
  sort?: SortType;
  type?: string;
}

/**
 * Type-guard predicate for Immutable list.
 */
const isImmutableList = <T>(
  items: Immutable.List<T> | T[],
): items is Immutable.List<T> => "get" in items;

/**
 * Sorts a list based on provided sortOptions.
 *
 * @param items List of items to be sorted
 * @param sortOptions Options regarding field, type and sort order.
 * @returns List of sorted items.
 */
const sortList = <P, T extends Immutable.List<P> | P[]>(
  items: T,
  sortOptions: SortOptions,
): T => {
  const { key, type, sort } = sortOptions;

  if (!key) {
    return items;
  }

  const internalSort = <Type>(
    list: T,
    comparator: (valueA: Type, valueB: Type) => number,
  ) => {
    if (isImmutableList(list)) {
      return list.sort((first, second) =>
        comparator(get(first, key), get(second, key)),
      ) as T;
    }

    return [...list].sort((first, second) =>
      comparator(get(first, key), get(second, key)),
    ) as T;
  };

  if (type === "string") {
    const func =
      sort === SortType.ASCENDING
        ? (first: string, second: string) => second.localeCompare(first)
        : (first: string, second: string) => first.localeCompare(second);

    return internalSort(items, func);
  }

  if (type === "number") {
    const func =
      sort === SortType.ASCENDING
        ? (first: number, second: number) => first - second
        : (first: number, second: number) => second - first;

    return internalSort(items, func);
  }

  if (type === "boolean") {
    const func =
      sort === SortType.ASCENDING
        ? (first: boolean, second: boolean) =>
            second.toString().localeCompare(first.toString())
        : (first: boolean, second: boolean) =>
            first.toString().localeCompare(second.toString());

    return internalSort(items, func);
  }

  return items;
};

/*
  In an effort to create a simple but configurable shared table logic
  Here is a reusable hook to allow for a list to be sorted by a key.

  The setOptions function takes in the key you would like to sort. This
  is a lodash.get key which can include nesting or even indexing in an array

  Example usage:
  items = [{ foo: "bar"}, { foo: "zap"}, { foo: "ask"}]

  Calling setOptions({ key: "foo"}) will sort ascending by string i.e. ask, bar, zap
  If you call setOptions({ key: "foo"}) again it will flip to descending zap, bar, ask

  items = [{ foo: 3}, { foo: 1}, { foo: 10}]
  Calling setOptions({ key: "foo"}) will sort descending by number i.e. 10, 3, 1
  If you call setOptions({ key: "foo"}) again it will flip to ascending 1, 3, 10

  The setOptions function returns the key that is the current sort key, as well as the
  direction that the list is currently sorting in
*/
export const useSortList = <P, T extends Immutable.List<P> | P[]>(items: T) => {
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    key: "",
    sort: SortType.NONE,
  });

  const setOptions = (options: SortOptions) => {
    const { key: newKey, sort: newSort, type } = options;
    const { key: currentKey, sort: currentSort } = sortOptions;

    if (!newKey) {
      // there is no key, remove all sorting
      setSortOptions({ sort: SortType.NONE, key: "" });

      return;
    }

    const item = get(
      isImmutableList(items) ? items.get(0) : get(items, "[0]"),
      newKey,
    );

    let sort = currentSort;

    if (newKey === currentKey) {
      // if you sort by the same key twice, it should toggle between ascending and descending
      sort =
        currentSort === SortType.DESCENDING
          ? SortType.ASCENDING
          : SortType.DESCENDING;
    } else {
      // the keys are not the same, so sort by the new key
      sort = SortType.DESCENDING;
    }

    setSortOptions({
      key: newKey,
      // override the sort you determined above if there is one explicitly given
      sort: newSort || sort,
      type: type || typeof item,
    });
  };

  return [sortList(items, sortOptions), setOptions, sortOptions] as const;
};

/* React hook that filters the items array by the query given.
 * Using the keys array, you can filter any member of the items array by
 * key (this uses lodash.get so it supports nesting)
 *
 * For example, if you have an array of names
 * items = [{ first: "andrei", last: "taylor"}, { first: "rocky", "balboa"}]
 *
 * useFilterList(items, "alb", ["last", "first"]) -> [{ first: "rocky", "balboa"}]
 * because the key "last" on that item matches the query
 */
export const useFilterList = <P, T extends Immutable.List<P> | Array<P>>(
  items: T,
  query: string,
  keys: Array<keyof P>,
): T => {
  if (!query) {
    return items;
  }

  const predicate = (item: P) =>
    keys.some((key) => {
      const str = get(item, key);

      return (
        item &&
        typeof str === "string" &&
        str.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      );
    });

  if (isImmutableList(items)) {
    return items.filter(predicate) as T;
  }

  return items.filter(predicate) as T;
};
