import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";
import ImmutablePropTypes from "react-immutable-proptypes";

import { Button } from "components/Common/Button";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { ClientLegacy, Language } from "resourceModels";
import colors from "stylesheets/utilities/colors.scss";

import { MultilingualInputLanguagesDialog } from "./MultilingualInputLanguagesDialog";
import * as S from "./styles";
import "./style.scss";

/**
 * @param {Boolean} bodyTooLong
 * @param {Boolean} noBody
 * @param {Boolean} invalid
 * @param {Boolean} isDialogOpen
 * @returns {Array.<String>}
 */
function styleIcon({ bodyTooLong, noBody, invalid, isDialogOpen }) {
  let icon = "Globe";
  let iconColor = "";

  if (noBody) {
    icon = "";
  } else if (bodyTooLong) {
    icon = "Warning";
  }

  if (invalid) {
    iconColor = colors.colorUIWarn;
  } else if (isDialogOpen) {
    iconColor = colors.colorUIPrimary;
  }

  return [icon, iconColor];
}

const BLOCK_NAME = "MultilingualInputLanguageButton";

export function MultilingualInputLanguageButton({
  client,
  activeLanguage,
  languages,
  availableLanguages,
  enabledLanguages,
  invalidFields,
  addLanguageAction,
  onClick,
  deleteLanguage,
  invalidLanguageCodes,
}) {
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const dialogRef = useOnClickOutside(() => {
    setIsLanguageDialogOpen(false);
  });

  const hidden =
    availableLanguages.size === 0 &&
    languages.size - 1 === client.translatedLanguages.size;
  const noBody = invalidFields.includes("bodyMissing");
  const bodyTooLong = invalidFields.includes("bodyLength");
  const invalid = noBody || bodyTooLong;
  const [icon, iconColor] = styleIcon({
    bodyTooLong,
    noBody,
    invalid,
    isLanguageDialogOpen,
  });

  const buttonText = enabledLanguages.find(
    (language) => language.code === activeLanguage,
  )?.englishName;

  return (
    <div
      className={classNames(BLOCK_NAME, {
        [`${BLOCK_NAME}--hidden`]: hidden,
      })}
    >
      {noBody && (
        <InfoTooltip
          customClassName={`${BLOCK_NAME}--invalid-tooltip Tooltip--Warning`}
          blurb="One or more translations are missing content."
          iconDefault="Warning"
          fillColor={colors.colorUIWarn}
          absolute
          inModal
          iconHeight={18}
        />
      )}
      <Button
        text={buttonText}
        title="Current Language"
        size="small"
        icon={invalidLanguageCodes.size > 0 ? "WarningYellow" : icon}
        secondIcon="ChevronDown"
        customClassName={classNames(`${BLOCK_NAME}__current-language`, {
          [`${BLOCK_NAME}--active`]: isLanguageDialogOpen,
        })}
        fillColor={isLanguageDialogOpen ? colors.colorUIPrimary : ""}
        iconColor={iconColor}
        light
        onClick={() => setIsLanguageDialogOpen(!isLanguageDialogOpen)}
      />
      {isLanguageDialogOpen && (
        <S.DialogContainer ref={dialogRef}>
          <Button
            icon="Close"
            title="Close Dialog"
            clear
            onClick={() => setIsLanguageDialogOpen(false)}
            size="x-small"
            customClassName="Dialog__close"
            showActiveBackground
          />
          <MultilingualInputLanguagesDialog
            closeDialog={() => setIsLanguageDialogOpen(false)}
            availableLanguages={availableLanguages}
            invalidFields={invalidFields}
            activeLanguage={activeLanguage}
            onLanguageClick={addLanguageAction}
            onClick={onClick}
            isOpen={isLanguageDialogOpen}
            languages={languages}
            client={client}
            enabledLanguages={enabledLanguages}
            deleteLanguage={deleteLanguage}
            customClassName={`${BLOCK_NAME}__dialog`}
            invalidLanguageCodes={invalidLanguageCodes}
          />
        </S.DialogContainer>
      )}
    </div>
  );
}

MultilingualInputLanguageButton.propTypes = {
  activeLanguage: PropTypes.string.isRequired,
  availableLanguages: ImmutablePropTypes.list.isRequired,
  languages: ImmutablePropTypes.listOf(PropTypes.instanceOf(Language))
    .isRequired,
  enabledLanguages: ImmutablePropTypes.listOf(PropTypes.instanceOf(Language))
    .isRequired,
  invalidFields: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  client: PropTypes.instanceOf(ClientLegacy).isRequired,
  addLanguageAction: PropTypes.func.isRequired,
  deleteLanguage: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  invalidLanguageCodes: PropTypes.instanceOf(Set).isRequired,
};
