import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";

import { saveClientAndAlertAction } from "actions";
import { updateClientAction } from "actions/client";
import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";
import { isClientUpdatingSelector } from "selectors/client";
import { useClientLegacy } from "services/clientLegacy";
import colors from "stylesheets/utilities/colors.scss";

import "./style.scss";

export function ModalZendeskTicketingConfig() {
  const { client } = useClientLegacy();
  const isClientUpdating = useSelector(isClientUpdatingSelector);
  const dispatch = useDispatch();

  const [subdomain, setSubdomain] = useState(
    client.handoff.getIn(["integrations", "zendesk", "subdomain"]) || "",
  );
  const [email, setEmail] = useState(
    client.handoff.getIn(["integrations", "zendesk", "email"]) || "",
  );
  const [token, setToken] = useState(
    client.handoff.getIn(["integrations", "zendesk", "token"]) || "",
  );

  const isZendeskConfigured = Boolean(
    client.handoff.getIn(["integrations", "zendesk", "token"]),
  );
  const isValidEmail = isEmail(email);
  const isValidToken = /\w{2,}/.test(token);
  const isValidSubdomain = !isURL(subdomain) && /\w+/.test(subdomain);

  const hasChanges =
    subdomain !==
      client.handoff.getIn(["integrations", "zendesk", "subdomain"]) ||
    email !== client.handoff.getIn(["integrations", "zendesk", "email"]) ||
    token !== client.handoff.getIn(["integrations", "zendesk", "token"]);

  const handleSubmit = useCallback(() => {
    dispatch(
      updateClientAction({
        handoff: client.handoff.mergeIn(["integrations", "zendesk"], {
          name: "zendesk",
          enabled: true,
          email,
          token,
          subdomain,
        }),
      }),
    );

    dispatch(saveClientAndAlertAction());
  }, [client.handoff, dispatch, email, subdomain, token]);

  return (
    <div className="ModalZendeskTicketingConfig Modal__modal">
      <h5 className="Modal__title">Configure Zendesk Ticketing</h5>
      <div className="Modal__content">
        <form
          className="ModalZendeskTicketingConfig__section g-form__block"
          autoComplete
        >
          <div className="ModalZendeskTicketingConfig}__section__input-container">
            <div className="ModalZendeskTicketingConfig__section__input-container__row">
              <div className="ModalZendeskTicketingConfig__section__input-container__input">
                <label
                  className="g-input__label"
                  htmlFor="ModalZendeskTicketingConfig__contact-name"
                >
                  Zendesk Subdomain
                  <SvgIcon
                    link="https://docs.ada.cx/zendesk-subdomain"
                    customClassName="g-input__label"
                    icon="Help"
                    fillColor={colors.colorGrey3}
                    cursor="pointer"
                    height={20}
                  />
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className={`g-input ${
                    isValidSubdomain || "g-input--invalid"
                  }`}
                  placeholder="mycompany"
                  value={subdomain}
                  onChange={(e) => setSubdomain(e.target.value)}
                  id="ModalZendeskTicketingConfig__contact-name"
                />
                <p className="g-form__block__description">
                  Your subdomain is the first part of your helpdesk URL:
                  https://
                  <b>
                    <u className="ModalZendeskTicketingConfig__section__input-container__input__subdomain">
                      this
                    </u>
                  </b>
                  .zendesk.com
                </p>
              </div>
              <div className="ModalZendeskTicketingConfig__section__input-container__input">
                <label
                  className="g-input__label"
                  htmlFor="ModalZendeskTicketingConfig__email"
                >
                  Zendesk Login Email
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  className={`g-input ${isValidEmail || "g-input--invalid"}`}
                  placeholder="you@yourcompany.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="ModalZendeskTicketingConfig__email"
                />
              </div>
            </div>
            <div className="ModalZendeskTicketingConfig__section__input-container__input">
              <label
                className="g-input__label"
                htmlFor="ModalZendeskTicketingConfig__token"
              >
                Zendesk API Token
              </label>
              <input
                autoComplete="off"
                type="text"
                className={`g-input ${isValidToken || "g-input--invalid"}`}
                placeholder="ABcDeFGhijKlMnOPqrSTuVwxyZ"
                value={token}
                onChange={(e) => {
                  setToken(e.target.value);
                }}
                id="ModalZendeskTicketingConfig__token"
              />
            </div>
            <p className="g-form__block__description">
              You can get one of these from Zendesk in Settings &gt; Channels
              &gt; API &gt; Token Access
            </p>
          </div>
        </form>
      </div>
      <div className="Modal__bottom">
        <Button
          isLoading={isClientUpdating}
          disabled={
            !isValidEmail || !isValidSubdomain || !isValidToken || !hasChanges
          }
          title={`${isZendeskConfigured ? "Save" : "Add"} integration`}
          text={`${isZendeskConfigured ? "Save" : "Add"} integration`}
          icon="Zendesk"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
