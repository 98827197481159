/* eslint-disable react-hooks/exhaustive-deps */
import Immutable from "immutable";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchMany } from "actions/resources";
import { deserializeTopics } from "components/Declarative/Pages/Conversations/ConversationsTopics/helpers";
import {
  type ConversationsTopics,
  type ConversationsTopicsProps,
} from "components/Declarative/Pages/Conversations/ConversationsTopics/hooks/useConversationsTopics/types";
import { type AnalyticsListItem } from "resourceModels";
import { type AnalyticsListItemValue } from "resourceModels/AnalyticsListItem/helpers";
import { getResources, getResourcesRequest } from "selectors/resources";

/**
 * Manage Conversations Topics:
 *  - Fetch conversation topics
 *  - Use filters from URL by default
 *  - Topic names
 *  - Total Conversations
 */
export const useConversationsTopics = ({
  pageParams,
  skipFetch = false,
}: ConversationsTopicsProps): ConversationsTopics => {
  const dispatch = useDispatch();
  const requestParams = useMemo(
    () => ({
      filter: {
        ...pageParams.analyticalFilters,
        dataType: "conversations_topics_overview",
        startDate: pageParams.startDate,
        endDate: pageParams.endDate,
        timeZone: pageParams.timeZone,
      },
      resetCache: pageParams.resetCache,
    }),
    [
      pageParams.startDate,
      pageParams.endDate,
      pageParams.timeZone,
      pageParams.analyticalFilters,
    ],
  );

  const conversationsTopicsListItem = useSelector((state) =>
    getResources(state, "analyticsListItem", requestParams),
  );
  const conversationsTopicsRequest = useSelector((state) =>
    getResourcesRequest(state, "analyticsListItem", requestParams),
  );

  const conversationsTopics = useMemo(() => {
    const emptyList = Immutable.List([]) as Immutable.List<AnalyticsListItem>;

    return deserializeTopics(conversationsTopicsListItem ?? emptyList);
  }, [conversationsTopicsListItem]);

  const topicTitles = useMemo(
    () => conversationsTopics.map((topic) => topic.name),
    [conversationsTopics],
  );

  const totalConversations = useMemo(() => {
    const analyticsCounts = conversationsTopicsRequest?.meta?.get("counts") as
      | Immutable.List<AnalyticsListItemValue>
      | undefined;
    const totalConversationsCount = analyticsCounts?.find(
      (count: AnalyticsListItemValue) => count.get("label") === "Volume",
    );

    return Number(totalConversationsCount?.get("value"));
  }, [conversationsTopicsRequest?.meta]);

  const isLoading = conversationsTopicsRequest?.isPending ?? false;

  useEffect(() => {
    if (!skipFetch) {
      dispatch(fetchMany("analyticsListItem", requestParams));
    }
  }, [dispatch, skipFetch, requestParams]);

  return {
    isLoading,
    conversationsTopics,
    totalConversations,
    topicTitles,
  };
};
