import Immutable from "immutable";

import { AdaDateTime } from "services/AdaDateTime";
import { keyConverter } from "services/key-converter";
import RecordClassCreator from "services/record";

export class MessageTemplate extends RecordClassCreator({
  type: "messageTemplate",

  /** @memberof MessageTemplate# */
  templateType: undefined,

  /** @memberof MessageTemplate# */
  scale: 10,

  /** @memberof MessageTemplate# */
  startScaleAtOne: false,

  /** @memberof MessageTemplate# */
  hasQuestionSection: false,

  /** @memberof MessageTemplate# */
  hasCommentSection: false,

  /** @memberof MessageTemplate# */
  likertScaleQuestionLabel: Immutable.Map({ en: "" }),

  /** @memberof MessageTemplate# */
  likertScaleLabelLow: Immutable.Map({ en: "" }),

  /** @memberof MessageTemplate# */
  likertScaleLabelHigh: Immutable.Map({ en: "" }),

  /** @memberof MessageTemplate# */
  yesNoQuestionLabel: Immutable.Map({ en: "" }),

  /** @memberof MessageTemplate# */
  additionalCommentsLabel: Immutable.Map({ en: "" }),

  /** @memberof MessageTemplate# */
  version: undefined,

  /** @memberof MessageTemplate# */
  updated: undefined,

  /**
   * @type {Immutable.Map.<{created: String, version: Number, extra: Object}>}
   * @memberof MessageTemplate#
   */
  previousVersions: undefined,
}) {
  /**
   * Methods
   */

  /**
   * Returns a translated string by `languageCode`
   *
   * @param {String} property
   * @param {String} languageCode
   * @returns {String}
   */
  getLabel(property, languageCode) {
    return this[property].get(languageCode) || "";
  }

  /**
   * @param {Number} version
   * @returns {{startDate: String, endDate: String}}
   */
  getVersionDates(version) {
    if (version === this.version) {
      return {
        startDate: AdaDateTime.format(
          this.updated,
          AdaDateTime.DATE_FORMATS.INTL_STANDARD,
        ),
        endDate: AdaDateTime.format(
          Date.now(),
          AdaDateTime.DATE_FORMATS.INTL_STANDARD,
        ),
      };
    }

    const previousVersion = this.previousVersions.find(
      (item) => item.get("version") === version,
    );
    const nextVersion = this.previousVersions.find(
      (item) => item.get("version") === version + 1,
    );

    if (nextVersion) {
      return {
        startDate: AdaDateTime.format(
          previousVersion.get("created"),
          AdaDateTime.DATE_FORMATS.INTL_STANDARD,
        ),
        endDate: AdaDateTime.format(
          nextVersion.get("created"),
          AdaDateTime.DATE_FORMATS.INTL_STANDARD,
        ),
      };
    }

    return {
      startDate: AdaDateTime.format(
        previousVersion.get("created"),
        AdaDateTime.DATE_FORMATS.INTL_STANDARD,
      ),
      endDate: AdaDateTime.format(
        this.updated,
        AdaDateTime.DATE_FORMATS.INTL_STANDARD,
      ),
    };
  }

  /**
   * Properties
   */

  /**
   * An array of numbers representing the likert scale [0, 1, 2, ...]
   *
   * @returns {Array.<Number>}
   */
  get scaleItems() {
    if (this.startScaleAtOne) {
      return Array(+this.scale)
        .fill(0)
        .map((el, index) => index + 1);
    }

    return Array(+this.scale + 1)
      .fill(0)
      .map((el, index) => index);
  }

  /**
   * Returns a list of invalid fields
   *
   * @returns {Array.<String>}
   */
  get invalidFields() {
    return MessageTemplate.requiredFields.filter((fieldName) => {
      const value = this[fieldName];

      if (value) {
        if (value instanceof Immutable.Map) {
          // If any of translation doesn't have value or value is too long
          return value
            .toArray()
            .some((val) => !val || val.length > MessageTemplate.labelMaxLength);
        }

        return !value || value.length > MessageTemplate.labelMaxLength;
      }

      return true;
    });
  }

  /**
   * @returns {Boolean}
   */
  get isValid() {
    return !this.invalidFields.length;
  }

  /**
   * @returns {ReadonlyArray.<String>}
   */
  static get requiredFields() {
    return Object.freeze([
      "likertScaleQuestionLabel",
      "likertScaleLabelLow",
      "likertScaleLabelHigh",
      "yesNoQuestionLabel",
      "additionalCommentsLabel",
    ]);
  }

  /**
   * @returns {Number}
   */
  static get labelMaxLength() {
    return 120;
  }

  /**
   * @param {Object} payload - stuff returned by API
   * @returns {Object}
   */
  static deserialize(payload) {
    // Make keys camelcase
    const attributes = keyConverter(payload, "camel");

    return {
      ...attributes,
      id: attributes.id,
      templateType: attributes.type,
    };
  }

  /**
   * This is what's gonna be sent through API
   *
   * @returns {Object}
   */
  serialize() {
    const payload = keyConverter(this.changedAttributes, "underscore");

    payload._type = this.templateType;
    payload._id = this.id;

    return payload;
  }
}
