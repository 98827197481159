import styled from "styled-components";

export const ContentMessage = styled.div`
  text-align: center;
  .Variable {
    margin: 0 4px;
  }
`;

export const Capitalize = styled.span`
  text-transform: capitalize;
`;
