import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputClearable } from "components/Common/InputClearable";

import "./styles.scss";

const BLOCK_NAME = "InputRow";

/**
 * Molecule: Combines an Input element with a Tooltip element, plus some styling
 * @param {Object} props
 * @param {unknown} props.containerRef
 * @param {unknown} props.className
 * @param {unknown} props.label
 * @param {unknown} props.inputComponent
 * @param {unknown} props.placeholder
 * @param {Function} props.onChange
 * @param {unknown} props.tooltip
 * @param {unknown} props.value
 * @param {unknown} props.invalid
 */
export const InputRow = ({
  containerRef,
  className,
  label,
  inputComponent: InputComponent,
  placeholder,
  onChange,
  tooltip,
  value,
  invalid,
}) => (
  <div className={classNames(BLOCK_NAME, className)}>
    <div className={`${BLOCK_NAME}__label-container`}>
      <span className={`${BLOCK_NAME}__label`}>{label}</span>
      {tooltip && (
        <InfoTooltip
          blurb={tooltip}
          iconDefault="QuestionCircle"
          iconClicked="QuestionCircleFilled"
          absolute
          container={containerRef}
          tabIndex={-1}
          customClassName={`${BLOCK_NAME}__tooltip`}
        />
      )}
    </div>
    <InputComponent
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      invalid={invalid}
    />
  </div>
);

InputRow.propTypes = {
  className: PropTypes.string,
  containerRef: PropTypes.instanceOf(Element),
  label: PropTypes.string.isRequired,
  inputComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  invalid: PropTypes.bool,
};

InputRow.defaultProps = {
  className: undefined,
  containerRef: undefined,
  inputComponent: InputClearable,
  placeholder: undefined,
  tooltip: undefined,
  invalid: false,
};
