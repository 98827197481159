import type Immutable from "immutable";

import RecordClassCreator from "services/record";

export interface AnalyticsCountAttributes {
  dataType: string | null;
  value:
    | Immutable.List<string | number>
    | Immutable.Map<string, string>
    | number
    | string
    | null;
  valueType: "number" | "array" | "links";
  label: string;
  meta:
    | Immutable.Map<string, string>
    | Immutable.Map<string, Immutable.List<string>>
    | null;
}

export class AnalyticsCount extends RecordClassCreator<AnalyticsCountAttributes>(
  {
    type: "analyticsCount",
    dataType: null,
    value: null,
    valueType: "number",
    label: "",
    meta: null,
  },
) {}
