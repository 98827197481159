import React from "react";

import { InputWithVariableAutocompleteDropdown } from "components/Common/InputWithVariableAutocompleteDropdown";
import { type RuleCondition, type VariableArgument } from "services/rules";

interface Props<R> {
  leftArg: VariableArgument;
  rightArg: number;
  isDisabled?: boolean;
  onChange: (rule: R) => void;
  rule: R;
  setUpdatedRule: (rule: R) => void;
}

export function RightOperandNumericInput<R extends RuleCondition>({
  leftArg,
  rightArg,
  isDisabled,
  setUpdatedRule,
  rule,
  onChange,
}: Props<R>) {
  const getValueValidationMessage = (value: unknown) => {
    // Check if value represents a number
    if (/^[-+]?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?$/g.test(`${value}`)) {
      return undefined;
    }

    return "Please enter a valid number";
  };

  const validationMessage = getValueValidationMessage(rightArg);

  return (
    <InputWithVariableAutocompleteDropdown
      variableId={leftArg.id}
      isDisabled={isDisabled}
      value={`${rightArg}`}
      isInvalid={validationMessage !== undefined}
      onChange={(value) => {
        setUpdatedRule({ ...rule, args: [leftArg, value] });

        if (getValueValidationMessage(value) === undefined) {
          onChange({ ...rule, args: [leftArg, Number(value)] });
        }
      }}
      placeholder="Value"
      errorMessage={validationMessage}
    />
  );
}
