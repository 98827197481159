import styled from "styled-components";

import { colorPrimitives, v } from "constants/css";

export const ErrorContainer = styled.div`
  padding: ${v.space5};
  display: flex;
  flex-direction: column;
`;

export const ErrorTitle = styled.span`
  font-weight: ${v.heavyFontWeight};
`;

export const ErrorMessage = styled.pre`
  padding: ${v.space2};
  background-color: ${colorPrimitives.red100};
  border: 1px solid ${colorPrimitives.red400};
`;
