import Immutable from "immutable";
import camelcase from "lodash.camelcase";

import { type AnalyticsReportFilterAttributes } from "components/Shared/Pages/AnalyticsPage/services";
import RecordClassCreator, {
  type SingleResourcePayload,
} from "services/record";

type filterName = Exclude<
  keyof AnalyticsReportFilterAttributes,
  "startDate" | "endDate"
>;

interface AnalyticsOverviewAttributes {
  allowedFilters: Immutable.List<filterName>;
}

export class AnalyticsOverview extends RecordClassCreator<AnalyticsOverviewAttributes>(
  {
    type: "analyticsOverview",
    allowedFilters: Immutable.List(),
  },
) {
  static getFetchOneUrl(): string {
    return "/analytics_overview";
  }

  static resolveFetchOneResult(payload: {
    data: {
      _id: string;
      _type: string;
      allowed_filters: string[];
    };
  }): SingleResourcePayload {
    const { data } = payload;

    return {
      ...payload,
      data: {
        ...data,
        allowedFilters: data.allowed_filters.map((f) => camelcase(f)),
      },
    };
  }
}
