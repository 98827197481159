import { primitives, tokens } from "@adasupport/byron";
import React from "react";
import styled from "styled-components";

const S = {
  Container: styled.div`
    max-width: 300px;
  `,
  Title: styled.div`
    font-size: ${primitives.fontSize.small};
    font-weight: ${primitives.fontWeight.semibold};
  `,
  Description: styled.div`
    font-size: ${primitives.fontSize.small};
    color: ${tokens.colors.text.muted};
    margin-top: ${primitives.spacing.space1};
    line-height: ${primitives.lineHeight.tight};
  `,
};

interface Props {
  title: string;
  description: string;
}

export function TitleWithSubtitle({ title, description }: Props) {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
    </S.Container>
  );
}
