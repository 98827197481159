import { createAlert } from "actions/alerts";
import { AlertType } from "actions/alerts/types";
import { closeModalAction, openModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { type Dispatch, type ThunkAction } from "actions/types";
import { adaAPI } from "services/api";
import { selectClient } from "services/client";
import { type State } from "types";

/**
 * Used when adding a goal to the Redux store, but not yet saving it to the database.
 * Useful for adding a partially completed goal before details are filled in.
 */
export function addGoalAction(name: string, description: string) {
  return {
    type: "ADD_GOAL",
    name,
    description,
  };
}

export function updateGoalsAction(goals: unknown) {
  return {
    type: "FETCH_GOALS_SUCCESS",
    goals,
  };
}

export function fetchGoalsAction() {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const { loading } = state.goalsState;
    const client = selectClient(state);

    if (loading || !client) {
      return;
    }

    if (!client.features.afm_goals) {
      dispatch({ type: "SET_EMPTY_GOALS" });

      return;
    }

    try {
      dispatch({ type: "FETCH_GOALS_REQUEST" });
      const response = await adaAPI.request({ method: "GET", url: "/goals" });
      dispatch(updateGoalsAction(response.data.goals));
    } catch (error) {
      dispatch({ type: "FETCH_GOALS_FAILURE" });
    }
  };
}

export function saveGoalAction(
  goal: Record<string, unknown>,
  successMessage: string,
): ThunkAction {
  return async (dispatch) => {
    try {
      const response = await adaAPI.request({
        method: "POST",
        url: "/goals/",
        data: goal,
      });
      dispatch(updateGoalsAction([response.data.goal]));
      dispatch(
        createAlert({
          message: successMessage,
          alertType: AlertType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to save Goal.",
          alertType: AlertType.ERROR,
        }),
      );

      throw error;
    }
  };
}

export function deleteGoalAction(goalId: string): ThunkAction {
  return async (dispatch) => {
    try {
      const response = await adaAPI.request({
        method: "DELETE",
        url: `/goals/${goalId}`,
      });
      dispatch(updateGoalsAction(response.data.goals));
      dispatch(
        createAlert({
          message: "Goal deleted.",
          alertType: AlertType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Failed to delete Goal.",
          alertType: AlertType.ERROR,
        }),
      );

      throw error;
    }
  };
}

export function openConfirmDeleteGoalModalAction(goalId: string): ThunkAction {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Delete Goal",
        message:
          "Are you sure you want to delete this Goal? This can’t be undone.",
        shouldCloseOnMaskClick: false,
        actions: [
          {
            title: "Delete Goal",
            buttonTint: "alert",
            onClick: () => {
              dispatch(deleteGoalAction(goalId));
              dispatch(closeModalAction());
              dispatch(setPageAction("/analytics/goals"));
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };
}
