import styled, { css } from "styled-components";

import { colorPrimitives, colorTokens } from "constants/css";

import { type ButtonProps } from ".";

export const Button = styled.button<Pick<ButtonProps, "size" | "clear">>`
  ${(p) => p.size === "x-small" && `padding: 0 12px;`};

  ${(p) =>
    p.clear &&
    css`
      &:hover {
        border-color: ${colorTokens.borderButton};
      }

      &:active {
        border-color: ${colorPrimitives.slate300};
        background-color: ${colorPrimitives.slate300};
      }
    `};
`;

export const Text = styled.span``;
