import { adaAPI } from "services/api";

import { type ApiKeyAction } from "./reducer";
import { type ApiKey } from "./types";

const KNOWLEDGE_API_KEY_PERMISSIONS = [
  "knowledge_api:get",
  "knowledge_api:post",
  "knowledge_api:patch",
  "knowledge_api:delete",
];

export async function fetchApiKeyInfo(
  loading: boolean,
  dispatch: (a: ApiKeyAction) => void,
) {
  if (loading) {
    return;
  }

  dispatch({ type: "GET_KEY_REQUEST" });

  try {
    const response = await adaAPI.request({
      method: "GET",
      url: "/api_keys",
      params: { permissions: KNOWLEDGE_API_KEY_PERMISSIONS },
    });
    const apiKeys = response.data.api_keys as ApiKey[];
    const knowledgeApiKey = apiKeys.find((key) => key.name === "knowledge_api");
    let metadata = null;

    if (knowledgeApiKey) {
      metadata = {
        createdAt: knowledgeApiKey.created_at,
        expireAt: knowledgeApiKey.expire_at,
      };
    }

    dispatch({ type: "GET_KEY_SUCCESS", metadata });
  } catch (e: unknown) {
    dispatch({ type: "GET_KEY_FAILURE" });
  }
}

export async function generateApiKey(dispatch: (a: ApiKeyAction) => void) {
  dispatch({ type: "CREATE_KEY_REQUEST" });

  try {
    const response = await adaAPI.request({
      method: "POST",
      url: "/api_keys/",
      data: {
        name: "knowledge_api",
        permissions: KNOWLEDGE_API_KEY_PERMISSIONS,
        replace_existing: true,
      },
    });
    const apiKey = response.data.token as string;
    const metadata = {
      createdAt: response.data.created_at as string,
      expireAt: response.data.expire_at as string,
    };
    dispatch({ type: "CREATE_KEY_SUCCESS", apiKey, metadata });
  } catch (e: unknown) {
    console.warn(e);
    dispatch({ type: "CREATE_KEY_FAILURE" });
  }
}
