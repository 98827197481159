import type Immutable from "immutable";
import {
  type DataNode,
  type EventDataNode,
  type Key,
} from "rc-tree/lib/interface";

import { DragDropRecord } from "reducers/folders/types/dragDropRecord";
import {
  type CustomNode,
  FolderRecord,
  type FoldersSearchResultItem,
} from "reducers/folders/types/folderRecord";
import { AdaDateTime } from "services/AdaDateTime";

export const ADA_ROOT_FOLDER_NAME = "ada-root";

export enum DropPosition {
  TOP = -1,
  BOTTOM = 1,
  INSIDE = 0,
}
export interface NodeDragEvtParams {
  event: React.DragEvent;
  node: EventDataNode<DataNode> & CustomNode;
}
export interface DragDropStructureProps {
  info: NodeDragEvtParams & DropParams;
  dragNode: FolderRecord;
  treeStructure: Array<CustomNode>;
  rootFolderId: string;
}

export interface DropParams {
  dragNode: EventDataNode<DataNode>;
  dragNodesKeys: Key[];
  dropPosition: DropPosition;
  dropToGap: boolean;
}

export interface DropIndicatorProps {
  dropPosition: DropPosition;
  dropLevelOffset: number;
  indent: number;
}

export const folderFilters = [
  {
    label: "Folders",
    value: "folders",
    selected: "Sort by: Manual",
  },
  {
    label: "Created",
    value: "created",
    subMenu: [
      {
        label: "Newest to Oldest",
        value: "created-last",
        selected: "Created: Newest",
      },
      {
        label: "Oldest to Newest",
        value: "created-first",
        selected: "Created: Oldest",
      },
    ],
  },
  {
    label: "Updated",
    value: "updated",
    subMenu: [
      {
        label: "Newest to Oldest",
        value: "updated-last",
        selected: "Updated: Newest",
      },
      {
        label: "Oldest to Newest",
        value: "updated-first",
        selected: "Updated: Oldest",
      },
    ],
  },
  {
    label: "Title",
    value: "handle",
    subMenu: [
      {
        label: "A to Z",
        value: "handle-first",
        selected: "Title: A to Z",
      },
      {
        label: "Z to A",
        value: "handle-last",
        selected: "Title: Z to A",
      },
    ],
  },
];

interface CreateCollectiveEditsStructureProps {
  checkedItems: Key[];
  newParentFolderId: Key;
  rootFolderId: Key;
  foldersById: Immutable.Map<string, FolderRecord>;
}

interface CreateTreeStructureProps {
  rootFolders: Immutable.List<string>;
  foldersById: Immutable.Map<string, FolderRecord>;
  rootFolderId: string;
  // When isTreeSelect is true, leaf nodes (answers) and checked folders, if any, will be hidden.
  // This view is used when moving the checked items to into another folder -
  // we don't want to allow moving a folder into itself.
  isTreeSelect?: boolean;
  checkedItems?: Key[];
}

export function createTreeStructure(props: CreateTreeStructureProps) {
  const {
    rootFolders: allRootFolders,
    rootFolderId,
    foldersById: foldersMap,
    isTreeSelect,
    checkedItems = [],
  } = props;

  const treeStructure: Array<CustomNode> = [];

  let rootFolders = allRootFolders;
  let foldersById = Object(foldersMap.toJS());

  if (isTreeSelect) {
    foldersById = foldersMap
      .filter(
        (folder) =>
          !checkedItems.includes(folder.get("id")) &&
          !folder.get("isLeaf") &&
          !checkedItems.includes(folder.get("parentId")),
      )
      .toJS();
    rootFolders = allRootFolders.filter(
      (folder) => !checkedItems.includes(folder),
    );
  }

  // rootFolders form the skeleton for the tree
  // it's order should always be preserved and hence the concat
  const foldersWithoutRoot = foldersMap.removeAll(rootFolders);
  rootFolders.concat(foldersWithoutRoot.keySeq().toArray()).forEach((id) => {
    const folder = foldersById[id]; // current folder
    const parent = foldersById[folder?.parentId];

    // if treeSelect is enabled, remove all responses aka leafs from the children
    // and remove all checkedKeys to prevent moving folder into itself.
    if (isTreeSelect && folder?.children) {
      folder.children = folder.children.filter(
        (f: CustomNode) => !checkedItems.includes(f.id) && !f.isLeaf,
      );
    }

    if (folder?.parentId === rootFolderId) {
      treeStructure.push(folder);
    } else if (parent) {
      // if parent exists for this folder, add this folder to the parent's children.
      // if parent does not contain children yet, create an empty children array and push.
      if (!parent.children?.length) {
        parent.children = [];
      }

      const childIndex = parent.children?.findIndex(
        (child: CustomNode) => child.id === folder?.id,
      );

      // if this folder was already a part of the parent's children, update it.
      // else push this folder to parent's children.
      if (childIndex > -1) {
        parent.children[childIndex] = folder;
      } else {
        parent.children.push(folder);
      }
    }
  });

  if (isTreeSelect) {
    const root: CustomNode = new FolderRecord({
      children: [...treeStructure],
      id: rootFolderId,
      value: rootFolderId,
      key: rootFolderId,
      title: "All Answers",
    });

    return [root];
  }

  return treeStructure;
}

export function createDragDropStructure(
  props: DragDropStructureProps,
  dragDropObject: DragDropRecord,
) {
  const { info, dragNode, treeStructure, rootFolderId } = props;

  const childId = dragNode.id;
  const dropKey = info.node.key;
  const dropPos = info.node.pos.split("-");
  const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

  const updatedDragDropObject = dragDropObject;

  // indicates if it is being dropped as the first child/top of a parent's children
  if (dropPosition === DropPosition.INSIDE) {
    // Prevent node being moved into itself
    // we do allow a node to be moved into its own parent, which
    // then just moves it to the top
    if (dropKey === childId) {
      return null;
    }

    updatedDragDropObject.new_parent_folder_id = dropKey;
    updatedDragDropObject.updateChildrenToMove(dragNode.parentId, childId);
    updatedDragDropObject.ordered_children_to_move.add({
      _id: childId,
      type: dragNode.get("isLeaf") ? "response" : "folder",
    });
  } else if (dropPosition === DropPosition.TOP) {
    // indicates if it is being dropped as the first child/top of Root folder

    updatedDragDropObject.new_parent_folder_id = rootFolderId;
    updatedDragDropObject.updateChildrenToMove(dragNode.parentId, childId);
    updatedDragDropObject.ordered_children_to_move.add({
      _id: childId,
      type: dragNode.get("isLeaf") ? "response" : "folder",
    });
  } else {
    if (info.node.parentId === childId) {
      return null;
    }

    // indicates item is being dropped in between 2 children and we need to find new index
    let newIndex = 0;

    const dragPos = info.dragNode.pos.split("-");

    // Positive value indicates drag from Top to Bottom, negative for Bottom to Top drag
    const dragDirection =
      Number(dropPos[dropPos.length - 1]) - Number(dragPos[dragPos.length - 1]);

    const findNode = (
      tree: DataNode[],
      key: Key,
      callback: (node: DataNode, index: number) => void,
    ) => {
      tree.forEach((node, index) => {
        if (node.key === key) {
          callback(node, index);

          return;
        }

        if (node.children) {
          findNode(node.children, key, callback);
        }
      });
    };

    findNode(treeStructure, dropKey, (node, index) => {
      newIndex = dragDirection > 0 ? index : index + 1;
    });

    updatedDragDropObject.new_parent_folder_id = info.node.parentId;
    updatedDragDropObject.new_index = newIndex;
    updatedDragDropObject.updateChildrenToMove(dragNode.parentId, childId);
    updatedDragDropObject.ordered_children_to_move.add({
      _id: childId,
      type: dragNode.get("isLeaf") ? "response" : "folder",
    });
  }

  return updatedDragDropObject;
}

export function createCollectiveEditsStructure(
  props: CreateCollectiveEditsStructureProps,
) {
  const { checkedItems, newParentFolderId, rootFolderId, foldersById } = props;
  const dndRecord = new DragDropRecord({
    new_index: DropPosition.INSIDE,
    new_parent_folder_id: newParentFolderId || rootFolderId,
    children_to_move: {},
    ordered_children_to_move: new Set(),
    hashes: {},
  });
  checkedItems.forEach((checkedItem) => {
    const currentParentId = foldersById.getIn([checkedItem, "parentId"]);

    // If parentId is checked, do not move the child separately
    // as any parentId that is checked will move it along with it's children.
    if (!checkedItems.includes(currentParentId)) {
      dndRecord.updateChildrenToMove(currentParentId, checkedItem);
      dndRecord.ordered_children_to_move.add({
        _id: checkedItem,
        type: foldersById.getIn([checkedItem, "isLeaf"])
          ? "response"
          : "folder",
      });
    }
  });

  return dndRecord;
}

export function deserializeSearchResultNode(treeNode: FoldersSearchResultItem) {
  // eslint-disable-next-line no-underscore-dangle
  const node = treeNode._source;

  const breadcrumbs = treeNode.breadcrumbs
    ? treeNode.breadcrumbs.map(({ title, _id: id }) => ({
        title,
        nodeId: id,
      }))
    : [];

  return new FolderRecord({
    id: node._id,
    key: node._id,
    title: node.title || node.handle,
    description: node.descriptive_string || node.description,
    created: AdaDateTime.format(
      AdaDateTime.secondsToMilliseconds(node.created),
      AdaDateTime.DATE_FORMATS.NAMED_MONTH_DAY_YEAR,
    ),
    updated: AdaDateTime.format(
      AdaDateTime.secondsToMilliseconds(node.updated),
      AdaDateTime.DATE_FORMATS.NAMED_MONTH_DAY_YEAR,
    ),

    isLeaf: treeNode.type !== "folder",
    live: node.live,
    liveVoice: node.live_voice,
    tags: node.tags,
    reserved: node.reserved,
    breadcrumbs,
    parentId: treeNode.parent_id,
    searchMatches: {
      content: treeNode.content,
      tags: treeNode.tags,
      blocks: treeNode.blocks,
      handle: treeNode.handle || treeNode.title,
      description: treeNode.description,
    },
  });
}
