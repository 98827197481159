import styled, { css } from "styled-components";

export const ModalSelfServe = styled.div<{ centered?: boolean }>`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.centered &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;
