import { Button, Icon, IconButton } from "@adasupport/byron";
import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import { setState } from "actions";
import { Canvas } from "components/Common/Canvas";
import { ReadOnlyCheckbox as Checkbox } from "components/Common/Checkbox";
import { InfoTooltip } from "components/Common/InfoTooltip";
import SvgImage from "components/Common/SvgImage";
import {
  createAuthenticationModal,
  deleteAuthentication,
  handleGetAllAuthentication,
  patchAuthentication,
  setAuthenticationModal,
} from "components/Declarative/Pages/Settings/SettingsAuthentication/actions";
import { PageHeader } from "source/components/Common/PageHeader";

import AuthenticationBackground from "./images/AuthenticationBackground.svg";
import "./style.scss";

export const noticeUrl = "https://docs.ada.cx/authentication";

const blurb =
  "Enabling this will show your authentication method when choosing an auth provider";

class SettingsAuthentication extends React.Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.deleteConfirmMessage =
      "Are you sure you want to delete this authentication? " +
      "Clicking delete will remove it from any blocks that are currently using it making them unusable.";
    this.openNewAuthenticationModal =
      this.openNewAuthenticationModal.bind(this);
  }

  /**
   *
   */
  componentWillMount() {
    const { handleGetAllAuthenticationAction } = this.props;
    handleGetAllAuthenticationAction();
  }

  /**
   * open authentication modal with default values
   */
  openNewAuthenticationModal() {
    const { createAuthenticationModalAction, setStateAction } = this.props;
    createAuthenticationModalAction();
    setStateAction("MODAL", {
      isOpen: true,
      view: "MODAL_AUTHENTICATION",
      size: "big",
      modalProps: {
        newAuthentication: true,
        authentication: null,
        notice: true,
      },
    });
  }

  /**
   * check if enable is true
   * @param {String} authenticationKey
   * @returns {Boolean}
   */
  isEnableChecked(authenticationKey) {
    const { authenticationList } = this.props;

    return authenticationList.getIn([authenticationKey, "enabled"]);
  }

  /**
   * handle toggle enable,
   * @param {String} authenticationKey
   */
  handleEnableToggle(authenticationKey) {
    const { authenticationList, patchAuthenticationAction } = this.props;
    const authentication = authenticationList.get(authenticationKey);
    patchAuthenticationAction(
      authentication.set("enabled", !authentication.get("enabled")),
      false,
    );
  }

  /**
   * handle delete authentication
   * @param {String} authenticationKey
   */
  handledeleteAuthentication(authenticationKey) {
    const { authenticationList, setStateAction, deleteAuthenticationAction } =
      this.props;
    const authentication = authenticationList.get(authenticationKey);
    setStateAction("MODAL", {
      isOpen: true,
      view: "MODAL_DELETE",
      size: "small",
      modalProps: {
        title: "Delete Authentication",
        confirmMessage: this.deleteConfirmMessage,
        delete: () => {
          deleteAuthenticationAction(authentication.name);
          setStateAction("MODAL", {
            isOpen: false,
            view: "",
          });
        },
      },
    });
  }

  /**
   * open authentication modal with defined values
   * @param {String} authenticationKey
   */
  openAuthenticationModal(authenticationKey) {
    const { authenticationList, setAuthenticationModalAction, setStateAction } =
      this.props;
    const authentication = authenticationList.get(authenticationKey);
    setAuthenticationModalAction(authentication);
    setStateAction("MODAL", {
      isOpen: true,
      view: "MODAL_AUTHENTICATION",
      size: "big",
      modalProps: {
        newAuthentication: false,
        authentication,
        notice: false,
      },
    });
  }

  /**
   * render default authentication dashboard
   * @returns {ReactElement}
   */
  renderAuthenticationDefault() {
    return (
      <div className="SettingsAuthentication__default">
        <div className="SettingsAuthentication__default__content">
          <div>No Authentication added yet</div>
          <button
            onClick={this.openNewAuthenticationModal}
            type="button"
            className="SettingsAuthentication__default__content__add"
          >
            Add New Authentication
          </button>
        </div>
        <div className="SettingsAuthentication__default__content SettingsAuthentication__default__image">
          <SvgImage
            imageHash={AuthenticationBackground}
            width={600}
            height={230}
          />
        </div>
      </div>
    );
  }

  /**
   * render authentication list in dashboard
   * @returns {ReactElement}
   */
  renderAuthenticationList() {
    const { authenticationList } = this.props;

    return (
      <div>
        {authenticationList.map((authentication, key) => (
          <div
            className="SettingsAuthentication__authentication-list"
            key={authentication.name}
          >
            <div className="SettingsAuthentication__authentication-list__description">
              <div className="SettingsAuthentication__authentication-list__description__name">
                {authentication.name}
              </div>
              <div className="SettingsAuthentication__authentication-list__description__redirectUri">
                {authentication.redirectUri}
              </div>
            </div>
            <div className="SettingsAuthentication__authentication-list__button-wrapper">
              <div className="SettingsAuthentication__authentication-list__transition-on-hover">
                <Button
                  variant="secondary"
                  text="Edit"
                  icon={Icon.Edit}
                  size="small"
                  onClick={() => {
                    this.openAuthenticationModal(key);
                  }}
                />
                <IconButton
                  variant="secondary"
                  icon={Icon.Trash}
                  size="small"
                  onClick={() => this.handledeleteAuthentication(key)}
                />
              </div>
              <div className="SettingsAuthentication__authentication-list__infoToolTip">
                <InfoTooltip
                  blurb={blurb}
                  iconDefault="QuestionCircle"
                  iconClicked="QuestionCircleFilled"
                  absolute
                  inModal
                />
              </div>
              <Checkbox
                checked={this.isEnableChecked(key)}
                className="CheckboxPanel__checkbox g-tile__checkbox"
                handleToggle={() => {
                  this.handleEnableToggle(key);
                }}
                isSwitch
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  getDescription = (
    <>
      {
        "Allows your customers to sign into their account through the Ada platform. This allows a more personalized experience for each customer based on their account-specific information. "
      }
      <a
        href={noticeUrl}
        target="_blank"
        rel="noreferrer noopener"
        className="SettingsAuthentication__description__content__link"
      >
        Learn more
      </a>{" "}
      about getting started in our documentation.
    </>
  );

  getActionItems = (
    <Button
      variant="primary"
      onClick={() => this.openNewAuthenticationModal()}
      text="New Authentication"
      title="New Authentication"
    />
  );

  /**
   * @returns {ReactElement}
   */
  render() {
    const { authenticationList } = this.props;

    return (
      <Canvas className="SettingsAuthentication" isNarrow>
        <PageHeader
          title="Authentication"
          description={this.getDescription}
          actionItems={this.getActionItems}
        />
        {authenticationList.size === 0
          ? this.renderAuthenticationDefault()
          : this.renderAuthenticationList()}
      </Canvas>
    );
  }
}

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapState(state) {
  const { authenticationList } = state;

  return {
    authenticationList,
  };
}

/**
 * @param {Object} dispatch
 * @returns {Object}
 */
function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      setStateAction: setState,
      createAuthenticationModalAction: createAuthenticationModal,
      handleGetAllAuthenticationAction: handleGetAllAuthentication,
      setAuthenticationModalAction: setAuthenticationModal,
      deleteAuthenticationAction: deleteAuthentication,
      patchAuthenticationAction: patchAuthentication,
    },
    dispatch,
  );
}

SettingsAuthentication.propTypes = {
  /** handle get all authentication from api */
  handleGetAllAuthenticationAction: PropTypes.func.isRequired,
  /** create authentication modal with default value */
  createAuthenticationModalAction: PropTypes.func.isRequired,
  /** set state */
  setStateAction: PropTypes.func.isRequired,
  /** authenticationList from Redux */
  authenticationList: ImmutablePropTypes.list.isRequired,
  /** patch authentication and send to api */
  patchAuthenticationAction: PropTypes.func.isRequired,
  /** set authentication modal for given values */
  setAuthenticationModalAction: PropTypes.func.isRequired,
  /** delete authentication and send to api */
  deleteAuthenticationAction: PropTypes.func.isRequired,
};

const Connector = connect(mapState, mapDispatch)(SettingsAuthentication);

export default Connector;
