import Immutable from "immutable";

import { camelCaseToSnakeCase } from "./strings";

export function snakeCaseKeys(object?: Record<string, unknown>) {
  if (!object) {
    return object;
  }

  return Immutable.Map(object).mapKeys(camelCaseToSnakeCase).toObject();
}

/**
 * Creates an object from an array of [key, value] pairs
 * This is built into most modern browsers, but our current
 * typescript target is set to es5
 */
export function fromEntries(iterable: [string, unknown][]) {
  return [...iterable].reduce(
    (obj, [key, val]) => ({ ...obj, [key]: val }),
    {},
  );
}

/**
 * Removes keys with falsy values
 */
export const removeFalsyValues = (obj: Record<string, unknown>) =>
  fromEntries(Object.entries(obj).filter((x) => x[1]));

/**
 * Removes keys that are explicitly null or undefined
 */
export const removeNullOrUndefined = (obj: Record<string, unknown>) =>
  fromEntries(
    Object.entries(obj).filter((x) => x[1] !== undefined && x[1] !== null),
  );
