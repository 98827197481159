// @ts-strict-ignore
import Immutable from "immutable";

import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface VoiceHandoffMessageRecordAttributes
  extends BaseMessageRecordAttributes {
  type: "voice_handoff";
  phoneNum: string;
  dialableAddress: string;
  includeUuiHeader: boolean;
  uuiHeaders: Immutable.List<TypedMap<{ key: string; value: string }>>;
}

export const dialableAddressKey = "dialableAddress";
export const phoneNumKey = "phoneNum";

export class VoiceHandoffMessageRecord extends BaseMessageRecord<VoiceHandoffMessageRecordAttributes>(
  {
    type: "voice_handoff",
    phoneNum: "",
    dialableAddress: "",
    includeUuiHeader: false,
    uuiHeaders: Immutable.List(),
  },
) {
  static getInvalidFields = (
    message: VoiceHandoffMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    const phoneNumOrVarRegEx =
      /^[+]?1?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$|^{([\w]{24})\|(.*?)}$/i;
    const sipAddressRegex = /^.{1,}@.+$/;

    const value = message.dialableAddress.trim();

    if (phoneNumOrVarRegEx.test(value)) {
      return Immutable.List(invalidFields);
    }

    if (sipAddressRegex.test(value)) {
      return Immutable.List(invalidFields);
    }

    // Backwards compatibility for customers who have a phone number saved but no dialable address.
    if (message.phoneNum) {
      return Immutable.List(invalidFields);
    }

    invalidFields.add(dialableAddressKey);

    return Immutable.List(invalidFields);
  };
}
