import { type AnyAction } from "@reduxjs/toolkit";
import Immutable from "immutable";

import { removeNullOrUndefined } from "services/object";

import {
  AppBlockConfiguration,
  AppIntegrationInputFieldRecord,
  type AppIntegrationInputFieldRecordAttributes,
  AppIntegrationOutputFieldRecord,
  AppIntegrationRecord,
  type AppIntegrationsDto,
  type AppWidget,
  AppWidgetRecord,
} from "./types";

export class AppIntegrationsStateRecord extends Immutable.Record({
  loading: false,
  loaded: false,
  appIntegrations: Immutable.List(),
}) {}

export const appIntegrations = (
  state = new AppIntegrationsStateRecord(),
  action: AnyAction,
) => {
  switch (action.type) {
    case "FETCH_APP_INTEGRATIONS_REQUEST":
      return state.merge({ loading: true });
    case "FETCH_APP_INTEGRATIONS_SUCCESS":
      return state.merge({
        loaded: true,
        loading: false,
        // Transform to records of the right type so we get nice typing and attribute autocomplete
        appIntegrations: Immutable.List(
          action.appsList.map((app: AppIntegrationsDto) => {
            const retypeInnerInputs = (
              inputField: AppIntegrationInputFieldRecordAttributes,
            ): AppIntegrationInputFieldRecord => {
              if (inputField.inputs) {
                return new AppIntegrationInputFieldRecord().merge({
                  ...inputField,
                  inputs: inputField.inputs.map((innerInput) =>
                    retypeInnerInputs(innerInput),
                  ),
                });
              }

              return new AppIntegrationInputFieldRecord().merge(inputField);
            };

            const createWidgetRecord = (widget: AppWidget) =>
              new AppWidgetRecord().merge({
                ...widget,
                input_schema: Immutable.List(
                  widget.input_schema.map((inputField) =>
                    retypeInnerInputs(inputField),
                  ),
                ),
                output_schema: Immutable.List(
                  widget.output_schema.map((outputField) =>
                    new AppIntegrationOutputFieldRecord().merge(outputField),
                  ),
                ),
                block_configuration: new AppBlockConfiguration().merge(
                  widget.block_configuration,
                ),
              });

            return new AppIntegrationRecord().merge({
              ...removeNullOrUndefined(app),
              ...(app.widget && { widget: createWidgetRecord(app.widget) }),
              ...(app.widgets && {
                widgets: Immutable.List(
                  app.widgets.map((widget) => createWidgetRecord(widget)),
                ),
              }),
            });
          }),
        ),
      });
    case "FETCH_APP_INTEGRATIONS_FAILURE":
      return state.merge({ loading: false });
    case "SET_EMPTY_APP_INTEGRATIONS":
      return state.merge({
        loaded: true,
        loading: false,
        appIntegrations: Immutable.List(),
      });
    default:
      return state;
  }
};
