import { tokens } from "@adasupport/byron";
import styled from "styled-components";

import { v } from "constants/css";

export const H1 = styled.h1`
  font-size: ${v.bigFontSize};
  font-weight: ${v.heavyFontWeight};
  line-height: 1;
  color: ${tokens.colors.text.main};
`;

export const H2 = styled.h2`
  font-size: ${v.xLargeFontSize};
  font-weight: ${v.heavyFontWeight};
  line-height: 1;
  color: ${tokens.colors.text.main};
`;

export const H3 = styled.h3`
  font-size: ${v.largeFontSize};
  font-weight: ${v.heavyFontWeight};
  line-height: 1;
  color: ${tokens.colors.text.main};
`;

export const H4 = styled.h4`
  font-size: ${v.mainFontSize};
  font-weight: ${v.heavyFontWeight};
  line-height: 1;
  color: ${tokens.colors.text.main};
`;
