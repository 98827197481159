import { type AsyncAction } from "actions/types";

import {
  type CloseModalAction,
  type ModalProps,
  type OpenModalAction,
} from "./types";

function clearModalData(): { type: string } {
  return {
    type: "CLEAR_MODAL",
  };
}

function closeModalInternalAction(): CloseModalAction {
  return {
    type: "CLOSE_MODAL",
  };
}

/** @deprecated - Use useModal hook */
export function openModalAction(
  name: string,
  props: ModalProps,
): OpenModalAction {
  return {
    type: "SET_MODAL",
    payload: {
      view: name,
      isOpen: true,
      modalProps: props,
    },
  };
}

/** @deprecated - Use useModal hook */
export function closeModalAction(): AsyncAction {
  return (dispatch, getState) => {
    // We must close the modal before we clear the data from the modal
    dispatch(closeModalInternalAction());
    setTimeout(() => {
      if (!getState().modal.isOpen) {
        dispatch(clearModalData());
      }
    }, 200);
  };
}

/* For backwards compatibility */
export const closeModal = closeModalAction;
