// Color primitives are context-agnostic names.
// They should only be used when there is not an applicable color token (see below).
/**
 * @deprecated - Use `primitives` from `@adasupport/byron`
 */
export const colorPrimitives = {
  // Brand colors
  gold100: "#FFF9EB",
  gold200: "#FFD781",
  gold300: "#E7BB56",
  gold400: "#CA9827",
  gold500: "#8B7237",
  teal100: "#E9F2F5",
  teal200: "#BAF0F2",
  teal300: "#9DEAEC",
  teal400: "#64ADC5",
  teal500: "#207D9C",
  teal600: "#3196B9",
  teal700: "#207D9C",
  plum100: "#F1ECFA",
  plum200: "#D0C1E8",
  plum300: "#9875D4",
  plum400: "#7B52C2",
  plum500: "#5B2FA6",
  plum600: "#7B52C2",
  plum700: "#6F48B1",

  // UI colors
  slate100: "#F8F9FB",
  slate200: "#EDEFF2",
  slate300: "#E5E7EB",
  slate400: "#D1D5DE",
  slate500: "#B2B6C1",
  slate600: "#898E9D",
  slate700: "#595F70",
  slate800: "#32394C",
  white: "#FFFFFF",
  black: "#171A1E",

  // Status colors
  green100: "#E3FAEE",
  green200: "#C0F5D9",
  green300: "#77D9A5",
  green400: "#0DBC5E",
  green500: "#167E46",
  green600: "#035528",
  green700: "#15803D",
  red100: "#FDE4E8",
  red200: "#F9BDC9",
  red300: "#F16985",
  red400: "#E81641",
  red500: "#99122E",
  red600: "#5D0B1C",
  orange100: "#FFF5E6",
  orange200: "#FEE2B9",
  orange300: "#FCBD5E",
  orange400: "#FB9804",
  orange500: "#A16103",
  orange600: "#462B01",
  orange700: "#B8550A",
  blue100: "#E5F2FD",
  blue200: "#ABD1FB",
  blue300: "#539BF7",
  blue400: "#0C63D6",
  blue500: "#014DB0",
  blue600: "#01316C",
} as const;

// Color tokens refer to a specific context that a color is used in.
// These are derived from color primitives. When there is an applicable
// color token it should be preferred over the equivalent color primitive.
/**
 * @deprecated - Use `tokens` from `@adasupport/byron`
 */
export const colorTokens = {
  textHeadings: colorPrimitives.black,
  textMain: colorPrimitives.black,
  textMuted: colorPrimitives.slate700,
  textInverted: colorPrimitives.white,
  actionPrimary: colorPrimitives.gold300,
  actionPrimaryFocus: colorPrimitives.slate300,
  actionPrimaryText: colorPrimitives.teal500,
  actionSecondary: colorPrimitives.teal500,
  actionHover: colorPrimitives.slate200,
  actionDisabled: colorPrimitives.slate400,
  statusPositive: colorPrimitives.green400,
  statusWarning: colorPrimitives.orange400,
  statusNegative: colorPrimitives.red400,
  statusInformative: colorPrimitives.blue400,
  background: colorPrimitives.white,
  backgroundMuted: colorPrimitives.slate100,
  backgroundDisabled: colorPrimitives.slate200,
  borderPrimary: colorPrimitives.gold300,
  borderDefault: colorPrimitives.slate300,
  borderButton: colorPrimitives.slate400,
  borderFormField: colorPrimitives.slate400,
  aiMagicColor: "#4E53D1",
  analyticsPrimary: colorPrimitives.teal600,
  analyticsAR: colorPrimitives.plum700,
  analyticsChart1: colorPrimitives.teal600,
  analyticsChart2: "#BC2F5C",
  analyticsChart3: colorPrimitives.blue500,
  analyticsChart4: colorPrimitives.orange700,
  analyticsChart5: colorPrimitives.green700,
} as const;

/**
 * @deprecated - use colorPrimitives or colorTokens instead
 */
export const cssColors = {
  colorGrey6: "#2E3A59",
  colorGrey5: "#EFEFF1",
  colorGrey4: "#656568",
  colorGrey4Active: "#535358",
  colorGrey3: "#C4C4CA",
  colorGrey3Active: "#ADADB3",
  colorGrey2: "#E8E8EB",
  colorGrey2Active: "#D9D9DE",
  colorGrey1: "#F3F3F5",
  colorWhite: "#FFFFFF",
  colorTextGrey: "#3C3C3F",
  colorUiGood: "#39C379",
  colorUiBad: "#E81641",
  colorUiBlockAction: "#2AA7D9",
  colorUiBlockMessage: "#44C73B",
  colorUiBlockOperation: "#101820",
  colorUiBlockNote: "#E3B640",
  colorUiHighlight: "#FFE560",
  colorUiHighlightLight: "#FFF1CD",
  colorUiPrimary: "#0975EF",
  colorUiPrimaryActive: "#005DC7",
  colorUiPrimary100: "#EBF3FE",
  colorUiPrimary150: "#D4E6FC",
  colorUiPrimary200: "#BEDAFB",
  colorUiWarn: "#FB9804",
  colorUiWarnBg: "#FFFAF2",
  colorBlack: "#101820",
  adaColor: "#3A8AFA",
  colorBorder: "#E8E8EB",
  colorGreyBg: "#F9F9F9",
  uiBlocksNote: "#E3B640",
  colorMask: "rgba(10, 15, 20, 0.4)",
  brandColorGreen: "#178A65",
  colorBlackNav: "#1C1431",
  colorBlackActive: "#322B45",
  colorTagDefault: "#7379bf",
  // New color palette - TODO check with final values once Byron-design-doc is ready
  colorAdaBrand: "#4E53D1",
  colorAdaBrand100: "#EDEEFA",
  colorAdaBrand200: "#CACBF1",
  colorAdaBrand300: "#6D71CF",
  colorAdaBrand600: "#38339C",
  colorAdaBrand700: "#2F265E",
  colorAdaBrand800: "#1C1431",
  colorAdaBrand900: "#9598E3",
} as const;

/**
 * @deprecated - use colorPrimitives or colorTokens instead
 */
export const c = cssColors;

export const cssVariables = {
  space0: "2px",
  space1: "4px",
  space2: "8px",
  space3: "10px",
  space4: "12px",
  space5: "16px",
  space6: "24px",
  space7: "32px",
  space8: "48px",
  space9: "56px",
  space10: "64px",

  spaceIntermediate: "40px",

  spaceTwentyTwo: "22px",

  gUnitQuadruple: "32px",
  gUnitTriple: "24px",
  gUnitDouble: "16px",
  gUnit: "8px",
  gUnitHalf: "4px",

  xxSmallFontSize: "10px",
  xSmallFontSize: "12px",
  smallFontSize: "14px",
  smallFontSizeAlt: "13px",
  mainFontSize: "16px",
  largeFontSize: "18px",
  xLargeFontSize: "20px",
  bigFontSize: "24px",
  heading1FontSize: "24px",
  heading2FontSize: "20px",
  heading3FontSize: "17px",

  smallLineHeight: "16px",
  mediumLineHeight: "20px",
  largeLineHeight: "24px",
  xLargeLineHeight: "28px",
  xxLargeLineHeight: "32px",

  inputHeight: "36px",
  smallishInputHeight: "28px",
  smallInputHeight: "24px",
  xSmallInputHeight: "20px",
  xxSmallInputHeight: "18px",

  xHeavyFontWeight: 700,
  heavyFontWeight: 600,
  mediumFontWeight: 500,
  lightFontWeight: 400,

  narrowContainerWidth: "531px",
  midContainerWidth: "882px",
  largeContainerWidth: "1152px",

  midContainerWidthOld: "590px",
  largeContainerWidthOld: "790px",
  windowBreakpoint: "1200px",

  smallerBorderRadius: "4px",
  smallBorderRadius: "6px",
  mainBorderRadius: "8px",
  bigBorderRadius: "12px",

  xxlSvgIconHeight: {
    numHeight: 72,
    stringHeight: "72px",
  },

  xlSvgIconHeight: {
    numHeight: 48,
    stringHeight: "48px",
  },
  svgIconHeight: {
    numHeight: 20,
    stringHeight: "20px",
  },
  smallSvgIconHeight: {
    numHeight: 16,
    stringHeight: "16px",
  },
  mediumSvgIconHeight: {
    numHeight: 24,
    stringHeight: "24px",
  },
  xsmallIconHeight: {
    numHeight: 12,
    stringHeight: "12px",
  },
  smallIconWidth: "24px",
  mediumIconWidth: "32px",
  mediumIconHeight: "32px",

  sidebarSize: "220px",

  transitionTime: "180ms",
  xSlowTransitionTime: "400ms",

  modalMaxHeight: "70vh",

  zIndexSearchResults: 9998,
  zIndexSelectResults: 9998,
  zIndexOptions: 9998,
  zIndexListWrapper: 9998,
  zIndexListItemSubmenu: 9998,
  zIndexListItemSubMenuWrapper: 9998,
  zIndexFilterInputResponseSelector: 9998,
  zIndexDropdown: 9998,
  zIndexInfoBubble: 9999,
  zIndexFlyoutButton: 9999,
  zIndexModalMask: 10000,
  zIndexWindowAlertContainer: 10001,
  zIndexModalContainer: 10001,
  zIndexWindowAlert: 10002,
  zIndexHoverTooltip: 10002,
  zIndexResponseSelectorDropdown: 99999,
  zIndexMultilingualInvalidTooltip: 99999,
  zIndexResponsesActionBar: 999999,
  zIndexDropdownLayout: 11,
  /**
   * Version sidebar needs to be above the response action bar
   * and below the "useful links" popup
   */
  zIndexVersionSidebar: 999999,
  zIndexResponsesHeader: 999999,
  zIndexAdaEmbedButton: 999999,
  zIndexDialog: 999999,
  zIndexNavbar: 1000000,
  zIndexModal: 9999999,
  zIndexDevToolbar: 9999999,
  zIndexAlert: 10000000,
  zIndexPendoForm: 999999999,

  boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.18)",

  boxShadow1: "0px 1px 2px rgba(2, 14, 29, 0.08)",
  boxShadow2: "0px 4px 14px rgba(0, 0, 0, 0.06)",
} as const;

/** @deprecated In favour of design system */
export const v = cssVariables;
