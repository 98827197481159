import type Immutable from "immutable";

import { type ExpressionGroupsActions } from "components/Declarative/Pages/Questions/types";
import {
  ExpressionGroupRecord,
  ExpressionGroupsState,
} from "reducers/expressionGroups/types";
import { updateById } from "services/immutable-list-updater";
import { keyConverter } from "services/key-converter";
import { recordMerger } from "services/record-merger";

export const expressionGroups = (
  state = new ExpressionGroupsState(),
  action: ExpressionGroupsActions,
) => {
  switch (action.type) {
    case "GET_GROUPS_REQUEST":
      return state.merge({
        loading: true,
        error: false,
      });

    case "GET_GROUPS_SUCCESS": {
      let loadedGroups = state.get("loaded");

      const returnedResponses = action.trainingGroups.map(
        // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (group: any) =>
          new ExpressionGroupRecord(
            recordMerger(group, new ExpressionGroupRecord()),
          ),
      );

      loadedGroups = loadedGroups.clear();

      const groupsArray = loadedGroups.concat(returnedResponses);

      return state.merge({
        loaded: groupsArray,
        loadedCount: groupsArray.filter((expression) => !expression.responseId)
          .size,
        loading: false,
        error: false,
        hasLoadedGroups: true,
      });
    }

    case "GET_GROUPS_FAILURE":
      return state.merge({
        loading: false,
        error: true,
        hasLoadedGroups: true,
      });

    case "TRAIN_EXPRESSION_GROUP_SUCCESS": {
      const loadedGroups = state.get("loaded");
      const returnedResponses = action.trainingGroups.map(
        (group: Record<string, unknown>) =>
          new ExpressionGroupRecord(keyConverter(group)),
      );

      const groupsArray = updateById(
        loadedGroups,
        returnedResponses,
      ) as Immutable.List<ExpressionGroupRecord>;

      return state.merge({
        loaded: groupsArray,
        loadedCount: groupsArray.size,
        loading: false,
        error: false,
      });
    }

    case "DELETE_GROUP_REQUEST":
      return state.merge({
        loading: true,
        error: false,
        loadedCount: 0,
      });

    default:
      return state;
  }
};
