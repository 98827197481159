import React, { type ReactNode } from "react";
import AnimateHeight from "react-animate-height";

import * as S from "./styles";

export interface BannerProps {
  children?: ReactNode;
  className?: string;
  icon?: string;
  intent?: "default" | "positive" | "negative" | "warning";
  isInline?: boolean;
  isVisible?: boolean;
  onClose?: () => void;
  iconVerticalPosition?: "top" | "middle";
}

export const Banner = ({
  children,
  // Allows custom styles using styled component syntax, styled(Button), to override defaults
  className = "",
  // The icon to display on the left side
  icon = "Banana",
  // The intent of the banner, which changes the background color
  intent = "default",
  isInline = false,
  // Whether or not this entire banner should be visible. Uses `react-animate-height` to do the hiding/showing.
  isVisible = true,
  // The function to fire when the close button is clicked
  onClose,
  iconVerticalPosition = "middle",
}: BannerProps) => {
  const isClosable = typeof onClose === "function";

  return (
    <AnimateHeight height={isVisible ? "auto" : 0}>
      <S.Banner className={className} intent={intent} isInline={isInline}>
        <S.LeftContainer
          childrenVerticalAlignment={
            iconVerticalPosition === "top" ? "flex-start" : "center"
          }
        >
          <S.Icon icon={icon} height={20} />
          <span>{children}</span>
        </S.LeftContainer>

        {isClosable && (
          <S.CloseIcon
            icon="Close"
            height={18}
            onClick={onClose}
            dataTestId="close-button"
          />
        )}
      </S.Banner>
    </AnimateHeight>
  );
};
