import axios from "axios";
import Pusher, { type ChannelAuthorizationOptions } from "pusher-js";

import { storage } from "services/storage";

import { getAPIRoot } from "../api-root";

export async function fetchPusherCredentials() {
  interface ClientConfigResponse {
    data: { PUSHER_CLUSTER: string; PUSHER_KEY: string };
  }

  try {
    const request = axios.get<ClientConfigResponse>(
      `${getAPIRoot()}/client-config/`,
    );

    const {
      data: { data: clientConfig },
    } = await request;

    window.PUSHER_CLUSTER = clientConfig.PUSHER_CLUSTER;
    window.PUSHER_KEY = clientConfig.PUSHER_KEY;
  } catch (e) {
    if (e instanceof Error) {
      throw e;
    }

    throw new Error(
      `Could not fetch PUSHER_CLUSTER and PUSHER_KEY from "/client-config/", Reason: "${e}"`,
    );
  }
}

const getSocket = (): Pusher => {
  const csrf = storage.retrieve("csrf");

  /*
   * If the csrf token is not present, we cannot authorize the user, which means
   * we cannot connect to private channels, but we can connect to public ones.
   *
   * A token is granted on login, and persisted in local storage. If the user
   * deletes this token from storage, they will need to log in again to get a new
   * one. This behaviour is consistent with the rest of the application. Perhaps
   * we should have a more robust solution for this, incase the token is deleted.
   * */
  if (!csrf) {
    console.error("Initializing Pusher without CSRF token");
  }

  return new Pusher(window.PUSHER_KEY, {
    // us2 is the us-east-2 region for pusher, and is the default region
    // if no PUSHER_CLUSTER is defined.
    cluster: window.PUSHER_CLUSTER || "us2",
    channelAuthorization: {
      endpoint: `${getAPIRoot()}/auth/pusher/agent/`,
      headers: {
        "X-CSRF-Token": csrf,
      },
    } as ChannelAuthorizationOptions,
  });
};

interface Socket {
  connection: Pusher | undefined;
  instance: Pusher;
}

export const socket: Socket = {
  connection: undefined,
  get instance() {
    if (this.connection) {
      return this.connection;
    }

    this.connection = getSocket();

    return this.connection;
  },
  set instance(instance: Pusher) {
    this.connection = instance;
  },
};
