// @ts-strict-ignore
import Immutable from "immutable";

export type TranslationMap = Immutable.Map<string, string>;

export interface CSATSettingsFeedbackOptionAttributes {
  id: string;
  label: TranslationMap;
}

export class CSATSettingsFeedbackOption extends Immutable.Record<CSATSettingsFeedbackOptionAttributes>(
  {
    id: null,
    label: null,
  },
) {}
