// @ts-strict-ignore
import Immutable from "immutable";

import { validateResponseMessageURL } from "services/strings";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "web_window";
  isHandoff: boolean;
  windowButtonLabel: string;
  windowHeight: string;
  windowTitle: string;
  windowUrl: string;
  windowWidth: string;
}

export class WebWindowMessageRecord extends BaseMessageRecord<Attributes>({
  type: "web_window",
  isHandoff: false,
  windowButtonLabel: "",
  windowHeight: "",
  windowTitle: "",
  windowUrl: "",
  windowWidth: "",
}) {
  static getInvalidFields: InvalidFieldsGetter<WebWindowMessageRecord> = (
    message,
  ) => {
    const invalidFields = new Set<string>();

    if (!validateResponseMessageURL(message.windowUrl)) {
      invalidFields.add("windowUrl");
    }

    if (message.windowTitle.length <= 0) {
      invalidFields.add("windowTitle");
    }

    if (message.windowButtonLabel.length <= 0) {
      invalidFields.add("windowButtonLabel");
    }

    if (message.windowWidth.length <= 0) {
      invalidFields.add("windowWidth");
    }

    if (message.windowHeight.length <= 0) {
      invalidFields.add("windowHeight");
    }

    return Immutable.List(invalidFields);
  };
}
