import styled from "styled-components";

import { c, v } from "constants/css";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalSection = styled.section`
  margin-top: 25px;
`;

export const ModalTitle = styled.h1`
  text-align: center;
  font-weight: bold;
  font-weight: ${v.xHeavyFontWeight};
  font-size: ${v.xLargeFontSize};
`;

export const ModalDescription = styled.p`
  text-align: center;
  color: ${c.colorGrey4};
`;

export const Alert = styled.div`
  margin-top: 6px;
  font-size: 12px;
  color: ${c.colorUiWarn};
`;

export const Example = styled.div`
  margin-top: 20px;
  font-size: 14px;
  &:first-child {
    margin-top: 5px;
  }
  &:last-child {
    margin-bottom: 5px;
  }
  & span {
    margin-top: 10px;
    display: block;
  }
`;

export const Highlight = styled.code`
  background: ${c.colorGrey1};
  color: ${c.colorBlack};
  font-size: 14px;
  padding: 3px;
`;
