import { Button } from "@adasupport/byron";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Banner } from "components/Common/Banner";
import { InputLabel } from "components/Common/InputLabel";
import { InputText } from "components/Common/InputText";
import { RadioButton } from "components/Common/RadioButton";
import ReadOnlyInput from "components/Common/ReadOnlyInput";
import { Title } from "components/Common/Title";
import { useSmartSearchIntegration } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/useSmartSearchIntegration";
import { useSmartSearchOAuth } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/useSmartSearchOAuth";
import { useClient } from "services/client";
import { type ConfluenceKBIntegration } from "slices/legacyIntegrations/legacyIntegrationsApi";

import {
  BASE_OAUTH_URL,
  CALLBACK_URL,
  CONFLUENCE_INTEGRATION,
  CONFLUENCE_PERMISSIONS,
  SPACES_OPTIONS,
} from "./constants";
import * as S from "./styles";

export function ConnectionGuide() {
  const dispatch = useDispatch();
  const { client } = useClient();
  const initOAuth = useSmartSearchOAuth(CONFLUENCE_INTEGRATION);
  const {
    integrationData,
    integrationPosition,
    integrationError,
    handleSave,
    handleDisconnect,
    isConnecting,
  } = useSmartSearchIntegration("KBIntegrationConfluence");
  const [formInput, setFormInput] = useState({
    clientKey: "",
    clientSecret: "",
    spacesIndex: "all",
    spacesIndexList: "",
  });
  const existingIntegration = integrationPosition > -1;

  const handleConfluenceOAuth = async () => {
    const { clientKey, clientSecret } = formInput;

    const queryParams = queryString.stringify({
      audience: "api.atlassian.com",
      redirect_uri: CALLBACK_URL,
      client_id: clientKey,
      response_type: "code",
      prompt: "consent",
      scope: CONFLUENCE_PERMISSIONS.join(" "),
      state: JSON.stringify({
        client_id: clientKey,
        client_secret: clientSecret,
        redirect_uri: CALLBACK_URL,
      }),
    });

    try {
      await initOAuth(BASE_OAUTH_URL + queryParams);
      const payload = {
        client_id: client.id,
        client_key: formInput.clientKey,
        client_secret: formInput.clientSecret,
        callback_url: CALLBACK_URL,
        spaces_include: [] as string[],
        spaces_exclude: [] as string[],
      };

      const spacesList = formInput.spacesIndexList
        .toUpperCase()
        .split(",")
        .reduce(
          (arr, val) => (val.trim() ? [...arr, val.trim()] : arr),
          [] as string[],
        );

      if (formInput.spacesIndex === "allow") {
        payload.spaces_include = spacesList;
      }

      if (formInput.spacesIndex === "block") {
        payload.spaces_exclude = spacesList;
      }

      handleSave(CONFLUENCE_INTEGRATION, payload);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (key: string) => (value: string) => {
    setFormInput({ ...formInput, [key]: value });
  };

  const handleRadioButton = (value: string) => () => {
    setFormInput({ ...formInput, spacesIndex: value });
  };

  useEffect(() => {
    if (integrationData) {
      const confluenceIntegration = integrationData as ConfluenceKBIntegration;
      let spacesIndex = "all";
      let spacesIndexList = "";

      if (confluenceIntegration.spaces_include) {
        spacesIndex = "allow";
        spacesIndexList = confluenceIntegration.spaces_include.join(",");
      }

      if (confluenceIntegration.spaces_exclude) {
        spacesIndex = "block";
        spacesIndexList = confluenceIntegration.spaces_exclude.join(",");
      }

      setFormInput({
        clientKey: confluenceIntegration.client_key,
        clientSecret: confluenceIntegration.client_secret,
        spacesIndex,
        spacesIndexList,
      });
    }
  }, [integrationData]);

  const isInputValid =
    formInput.clientKey &&
    formInput.clientSecret &&
    (formInput.spacesIndex === "all" || formInput.spacesIndexList);

  return (
    <div>
      <h5 className="Modal__title">Connect Confluence</h5>
      <S.Container>
        <Banner icon="Info">
          Ensure you have Confluence Cloud Administrator permissions.
          <br />
          For setup help, view the&nbsp;
          <a
            href="https://docs.ada.cx/smart-search"
            target="_blank"
            rel="noreferrer noopener"
          >
            help doc.
          </a>
        </Banner>
        <S.TitleWrapper>
          <Title headingLevel="h4">1. Create a new App</Title>
        </S.TitleWrapper>

        <S.Description>
          Create an OAuth 2.0 integration in your&nbsp;
          <a
            href="https://developer.atlassian.com/console/myapps/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Atlassian developer console
          </a>
        </S.Description>

        <S.TitleWrapper>
          <Title headingLevel="h4">2. Ada Callback URL</Title>
        </S.TitleWrapper>
        <S.Description>
          Paste this URL to the Callback URL field during the Atlassian
          app&apos;s authorization setup.
        </S.Description>
        <ReadOnlyInput value={CALLBACK_URL} />

        <S.TitleWrapper>
          <Title headingLevel="h4">3. Authentication details</Title>
        </S.TitleWrapper>
        <S.Description>
          Add your client ID and Secret from your Atlassian app&apos;s settings
          menu.
        </S.Description>
        <S.InputGroupContainer>
          <S.InputContainer>
            <InputLabel label="Client ID">
              <InputText
                value={formInput.clientKey}
                onChange={handleChange("clientKey")}
                invalidMessage="Please enter a valid Client Key"
                disabled={existingIntegration}
              />
            </InputLabel>
          </S.InputContainer>
          <S.InputContainer>
            <InputLabel label="Secret">
              <InputText
                value={formInput.clientSecret}
                onChange={handleChange("clientSecret")}
                invalidMessage="Please enter a valid Client Secret"
                disabled={existingIntegration}
              />
            </InputLabel>
          </S.InputContainer>
        </S.InputGroupContainer>

        <S.Divider />

        <Title headingLevel="h4">Spaces Sync</Title>
        <S.Description>
          Specify the Confluence spaces you want to sync. Private and Personal
          spaces will not be synced.
        </S.Description>
        {SPACES_OPTIONS.map(({ label, value }) => {
          const showSpacesIndexList =
            formInput.spacesIndex !== "all" && formInput.spacesIndex === value;

          return (
            <S.RadioContainer>
              <RadioButton
                checked={formInput.spacesIndex === value}
                id={value}
                label={label}
                onClick={handleRadioButton(value)}
                disabled={existingIntegration}
              />
              {showSpacesIndexList && (
                <S.TitleWrapper>
                  <InputLabel label="Space Key">
                    <InputText
                      value={formInput.spacesIndexList}
                      helperMessage="Add the space key identifiers from your spaces settings, separated by commas."
                      invalidMessage={integrationError}
                      onChange={handleChange("spacesIndexList")}
                      disabled={existingIntegration}
                      invalid={integrationError.includes("Invalid Space Keys")}
                    />
                  </InputLabel>
                </S.TitleWrapper>
              )}
            </S.RadioContainer>
          );
        })}
        <S.Footer>
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => dispatch(closeModalAction())}
          />
          {existingIntegration ? (
            <Button
              text="Disconnect Confluence"
              onClick={handleDisconnect}
              variant="secondary"
            />
          ) : (
            <Button
              variant="primary"
              text="Connect"
              isDisabled={isConnecting || !isInputValid}
              onClick={handleConfluenceOAuth}
            />
          )}
        </S.Footer>
      </S.Container>
    </div>
  );
}
