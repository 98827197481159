import { tokens } from "@adasupport/byron";
import styled from "styled-components";

export const InputHint = styled.div<{
  type?: "error" | "info" | "warning";
}>`
  margin-top: 6px;
  font-size: 12px;
  color: ${({ type }) =>
    ({
      error: tokens.colors.text.negative,
      info: tokens.colors.text.muted,
      warning: tokens.colors.text.warning,
    }[type || "info"])};

  a {
    color: inherit;
    text-decoration: underline;
  }
`;
