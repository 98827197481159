import { type Icon, primitives } from "@adasupport/byron";
import React from "react";
import styled from "styled-components";

const S = {
  Container: styled.div`
    display: flex;
    align-items: center;

    & > svg {
      margin-right: ${primitives.spacing.space2};
    }
  `,
};

interface Props {
  text: string;
  icon: typeof Icon.Cloud;
}

export const IconWithLabel = ({ text, icon: ItemIcon }: Props) => (
  <S.Container>
    <ItemIcon height={24} />
    {text}
  </S.Container>
);
