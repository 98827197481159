import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { trackException } from "services/errorTracker";

// Language files
import NoteBlock from "./locales/en/blocks/NoteBlock.json";
import SelectionBlock from "./locales/en/pages/SelectionBlock.json";
import settings from "./locales/en/pages/settings.json";

export const defaultNS = "en";
export const resources = {
  en: {
    settings,
    SelectionBlock,
    NoteBlock,
  },
};

export function missingKeyHandler(
  languages: readonly string[],
  namespace: string,
  key: string,
): void {
  trackException(
    new Error(
      `Missing translation key: ${key} in ${namespace} for the language(s): ${languages.join(
        " ",
      )}`,
    ),
  );
}

i18n.use(initReactI18next).init({
  resources,
  lng: defaultNS,
  debug: false,
  interpolation: {
    escapeValue: false, // React already safe from xss
  },
  saveMissing: true,
  missingKeyHandler,
});

export { i18n };
