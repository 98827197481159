import classNames from "classnames";
import React from "react";

import { NO_OP_FUNCTION } from "services/helpers";
import "./style.scss";

import * as S from "./styles";

interface Props {
  id?: string;
  name?: string;
  className?: string;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  stretch?: boolean;
}

export function InputRange({
  id = "",
  name = "",
  className = "",
  value,
  min = 0,
  max = 100,
  step = 10,
  onChange,
  disabled = false,
  stretch = false,
}: Props) {
  // Round to prevent JS floating point rounding issues.
  // Eg. 55.00000000001 -> 55
  const roundedVal = Math.round(value);

  return (
    <S.Root
      id={id}
      name={name}
      type="range"
      min={min}
      max={max}
      step={step}
      data-value={roundedVal}
      value={roundedVal}
      disabled={disabled}
      // Use onInput instead of onChange
      // because of Chrome bug with initial event firing
      onInput={onChange}
      // Need to keep onChange and pass this void function
      // to disable React warnings
      onChange={NO_OP_FUNCTION}
      className={classNames("InputRange", {
        [className]: className,
        "InputRange--disabled": disabled,
      })}
      style={{
        backgroundSize: `${((roundedVal - min) / (max - min)) * 100}% 100%`,
      }}
      stretch={stretch}
    />
  );
}
