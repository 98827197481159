import { useDispatch } from "react-redux";

import { createAlert } from "actions/alerts";
import { type AlertArguments } from "actions/alerts/types";

export const useAlert = () => {
  const dispatch = useDispatch();

  return {
    createAlert: (args: AlertArguments) => dispatch(createAlert(args)),
  };
};
