import { Icon, primitives } from "@adasupport/byron";
import React, { useEffect, useRef } from "react";

import { NO_OP_FUNCTION } from "services/helpers";

import { InputHint } from "../InputHint";

import * as S from "./styles";

interface Props {
  value: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  className?: string;
  invalid?: boolean;
  maxLength?: number;
  textAlign?: "left" | "right" | "center";
  multiline?: boolean;
  minRows?: number;
  warningMessage?: string;
  showCounter?: boolean;
  disabled?: boolean;
  onKeyPress?: (event: React.KeyboardEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  invalidMessage?: string;
  helperMessage?: string | React.ReactNode;
  textDirection?: "rtl" | "ltr";
  hideScrollbars?: boolean;
  dataTestId?: string;
  higherRef?: React.RefObject<HTMLTextAreaElement>;
  label?: string;
  autofocus?: boolean;
  prediction?: string;
  search?: boolean;
}

/**
 * Simple text input. Aims to replace DebouncedInput.
 * Feel free to add more props, but not too many!
 */
export function InputText({
  value,
  invalid = false,
  onChange,
  className,
  placeholder,
  maxLength,
  textAlign = "left",
  multiline = false,
  minRows = 1,
  warningMessage,
  showCounter,
  disabled = false,
  onKeyPress = NO_OP_FUNCTION,
  onKeyDown = NO_OP_FUNCTION,
  onFocus = NO_OP_FUNCTION,
  onBlur = NO_OP_FUNCTION,
  invalidMessage,
  helperMessage,
  textDirection = "ltr",
  hideScrollbars = false,
  dataTestId = "",
  higherRef,
  label,
  autofocus = false,
  prediction = "",
  search,
}: Props) {
  const ownRef = useRef<HTMLTextAreaElement>(null);
  const inputRef = higherRef || ownRef;

  useEffect(() => {
    const inputElement = inputRef.current;

    if (!inputElement) {
      return;
    }

    const { lineHeight, paddingTop, paddingBottom } =
      getComputedStyle(inputElement);

    inputElement.rows = minRows;

    inputElement.rows = Math.floor(
      (inputElement.scrollHeight -
        parseInt(paddingTop, 10) -
        parseInt(paddingBottom, 10)) /
        parseInt(lineHeight, 10),
    );
  }, [value, minRows, inputRef]);

  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) {
      return;
    }

    const normalizedValue = multiline
      ? e.currentTarget.value
      : e.currentTarget.value.replace(/(\r\n|\n|\r)/gm, "");

    onChange(normalizedValue);
  };

  return (
    <S.Root>
      {label && <S.Label>{label}</S.Label>}
      <S.TextAreaWrapper>
        {search && (
          <S.SearchIcon>
            <Icon.Search height={24} color={primitives.palette.slate400} />
          </S.SearchIcon>
        )}
        <S.Textarea
          ref={inputRef}
          placeholder={placeholder}
          className={className}
          rows={1}
          value={value}
          onChange={onTextareaChange}
          onFocus={onFocus}
          onBlur={onBlur}
          isInvalid={invalid}
          maxLength={!(multiline || showCounter) ? maxLength : undefined}
          textAlign={textDirection === "rtl" ? "right" : textAlign}
          disabled={disabled}
          multiline={multiline}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          textDirection={textDirection}
          hideScrollbars={hideScrollbars}
          data-testid={dataTestId}
          autoFocus={autofocus}
          search={search}
        />

        {(showCounter || multiline) && maxLength ? (
          <S.CharacterCounter
            placement={textDirection === "rtl" ? "left" : "right"}
          >
            {maxLength - value.length}
          </S.CharacterCounter>
        ) : null}
        <S.PredicitonText>{prediction}</S.PredicitonText>
      </S.TextAreaWrapper>

      {!(invalid && invalidMessage) && helperMessage && (
        <InputHint>{helperMessage}</InputHint>
      )}

      {invalid && invalidMessage && (
        <InputHint type="error">{invalidMessage}</InputHint>
      )}

      {warningMessage && <InputHint type="warning">{warningMessage}</InputHint>}
    </S.Root>
  );
}
