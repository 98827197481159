import Immutable from "immutable";

import { type ThunkAction } from "actions/types";
import { type ClientAttributes } from "resourceModels/Client";
import { updateClientAction as updateClientPlainObjectAction } from "services/client/actions";
import { deserializePartialClient } from "services/client/api";
import { selectClient } from "services/client/selectors";
import { keyConverter } from "services/key-converter";

export { fetchClientAction, saveClientAction } from "services/client/actions";

/** @deprecated */
export function updateClientAction(
  updatedAttributes: Partial<ClientAttributes>,
): ThunkAction {
  return (dispatch, getState) => {
    const client = selectClient(getState());

    if (!client) {
      return;
    }

    // Since updatedAttributes can contain Immutable instances,
    // we need to convert everything to plain JS objects and arrays.
    const updatedAttributesAsPlainObject = Immutable.Map(
      updatedAttributes,
    ).toJS() as Record<string, unknown>;

    // Convert keys to snake_case to represent the API format.
    // Then use deserializePartialClient to convert it
    // to Partial<Client>.
    const formattedAttributes = deserializePartialClient(
      keyConverter(updatedAttributesAsPlainObject, "underscore", false, false),
    );

    dispatch(updateClientPlainObjectAction(formattedAttributes));
  };
}
