// @ts-strict-ignore
import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "handoff_integration";
  iconUrl: string;
  integrationName: string;
  tags: string;
  handoffFields: Immutable.Map<
    string,
    | string
    | number
    | Immutable.Map<string, string>[]
    | Immutable.Map<string, string>
  >;
  fallbackResponseId: string | null;
  standbyMessage: string;
  offHoursAnswerId: string | null;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export class HandoffIntegrationMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "handoff_integration",
    iconUrl: "",
    integrationName: "",
    tags: "",
    handoffFields: Immutable.Map({}),
    fallbackResponseId: "",
    standbyMessage: "",
    offHoursAnswerId: "",
    successBusinessEvent: new FireableBusinessEvent({
      value: "",
      eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
      isVariable: false,
    }),
  },
) {
  static getInvalidFields: InvalidFieldsGetter<HandoffIntegrationMessageRecord> =
    (message) => {
      const invalidFields: Set<string> = new Set();

      if (!message.fallbackResponseId) {
        invalidFields.add("fallbackResponseId");
      }

      return Immutable.List(invalidFields);
    };
}
