import Immutable from "immutable";

interface objectWithAtleastId {
  id: string | null;
}

export function updateById(
  originalList: Immutable.List<objectWithAtleastId>,
  itemsToUpdate: [objectWithAtleastId],
): Immutable.List<objectWithAtleastId> {
  let newList = Immutable.List();
  itemsToUpdate.forEach((item) => {
    newList = originalList.set(
      originalList.findIndex((originalItem) => originalItem.id === item.id),
      item,
    );
  });

  return newList;
}
