import {
  type ScheduleDto,
  type fetchClientSuccessAction,
} from "services/client";
import { convertSchedule } from "services/scheduleFormatConverter";

import { type Schedule } from "./types";

type Action =
  | ReturnType<typeof fetchClientSuccessAction>
  | {
      type: "PATCH_SCHEDULED_BLOCK_SCHEDULES_SUCCESS";
      schedules?: ScheduleDto[];
    };

export const settingsScheduledBlockSchedules = (
  state: Schedule[] = [],
  action: Action,
): Schedule[] => {
  switch (action.type) {
    case "FETCH_CLIENT_SUCCESS": {
      const schedules = action.client.scheduled_block_schedules;

      if (!schedules) {
        return state;
      }

      return schedules.map((schedule) => convertSchedule(schedule));
    }

    case "PATCH_SCHEDULED_BLOCK_SCHEDULES_SUCCESS": {
      if (action.schedules) {
        return action.schedules.map((schedule) => convertSchedule(schedule));
      }

      return state;
    }

    default:
      return state;
  }
};
