// @ts-strict-ignore
import Immutable from "immutable";

import { type TypedMap } from "types";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

export type QuickRepliesMessageRecordQuickReply = TypedMap<{
  target: string;
  label: string;
}>;

interface QuickRepliesMessageRecordAttributes
  extends BaseMessageRecordAttributes {
  type: "quick_replies_block";
  quickReplies: Immutable.List<QuickRepliesMessageRecordQuickReply>;
}

export class QuickRepliesMessageRecord extends BaseMessageRecord<QuickRepliesMessageRecordAttributes>(
  {
    type: "quick_replies_block",
    quickReplies: Immutable.List(),
  },
) {}

/**
 * Validation
 */

QuickRepliesMessageRecord.getInvalidFields = (
  message: QuickRepliesMessageRecord,
): Immutable.List<string> => {
  const invalidFields: Set<string> = new Set();

  if (message.quickReplies.isEmpty()) {
    invalidFields.add("quickReplies");
  }

  return Immutable.List(invalidFields);
};
