import { combineReducers } from "@reduxjs/toolkit";

import analyticsOverviewPage from "components/Declarative/Pages/AnalyticsPage/AnalyticsOverviewPage/reducer";
import { analyticsPage } from "components/Declarative/Pages/AnalyticsPage/reducer";
import analyticsSatisfaction from "components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/AnalyticsSatisfactionPage/reducer";

export default combineReducers({
  analyticsPage,
  analyticsSatisfaction,
  analyticsOverviewPage,
});
