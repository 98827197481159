export const SUNSHINE_CONVERSATIONS_INTEGRATION_GUIDE_URL =
  "https://docs.ada.cx/sunshine-conversations";

export const HANDOFF_INTEGRATION_OAUTH_CONFIGURATION_TYPE = "oauth";
export const HANDOFF_INTEGRATION_BASIC_CONFIGURATION_TYPE = "basic";

export const SETTINGS_PLATFORMS_HANDOFFS_MODAL =
  "SETTINGS_PLATFORMS_HANDOFFS_MODAL";
export const SETTINGS_PLATFORMS_HANDOFFS_OAUTH_MODAL =
  "SETTINGS_PLATFORMS_HANDOFFS_OAUTH_MODAL";

export const HANDOFF_INTEGRATIONS_VIEWS_FOR_CONFIGURATION_TYPES = {
  [HANDOFF_INTEGRATION_OAUTH_CONFIGURATION_TYPE]:
    SETTINGS_PLATFORMS_HANDOFFS_OAUTH_MODAL,
  [HANDOFF_INTEGRATION_BASIC_CONFIGURATION_TYPE]:
    SETTINGS_PLATFORMS_HANDOFFS_MODAL,
};
