import {
  type IntegrationAttributes,
  type SmartSearchAttributes,
} from "./types";

export const DEFAULT_STATE: SmartSearchAttributes = {
  loading: false,
  loaded: false,
  error: false,
  errorMsg: "",
  integrations: [],
  savingIntegration: false,
  success: false,
};

export const DEFAULT_SMART_SEARCH_STATE = DEFAULT_STATE;

export const smartSearch = (
  state: SmartSearchAttributes = DEFAULT_STATE,
  action: {
    type: string;
    smartSearchIntegrations: { integrations: IntegrationAttributes[] };
    message: string;
  },
) => {
  switch (action.type) {
    case "RESET_SMART_SEARCH_INTEGRATIONS_MODAL":
      return {
        ...state,
        success: false,
        error: false,
        errorMsg: "",
      };
    case "FETCH_SMART_SEARCH_INTEGRATIONS_REQUEST":
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case "FETCH_SMART_SEARCH_INTEGRATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        errorMsg: "",
        integrations: action.smartSearchIntegrations.integrations.reduce<
          IntegrationAttributes[]
        >(
          // eslint-disable-next-line no-underscore-dangle
          (arr, val) => [...arr, { ...val, cls: val._cls }],
          [] as IntegrationAttributes[],
        ),
      };
    case "FETCH_SMART_SEARCH_INTEGRATIONS_FAILURE":
      return {
        ...state,
        loading: false,
        error: true,
      };
    case "ADD_DELETE_SMART_SEARCH_INTEGRATIONS_REQUEST":
      return {
        ...state,
        loading: true,
        loaded: false,
        savingIntegration: true,
      };
    case "ADD_DELETE_SMART_SEARCH_INTEGRATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
        errorMsg: "",
        savingIntegration: false,
        success: true,
      };
    case "ADD_DELETE_SMART_SEARCH_INTEGRATIONS_FAILURE":
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
        savingIntegration: false,
      };
    default:
      return state;
  }
};
