import Immutable from "immutable";

export default class HttpPlatformRecord extends Immutable.Record({
  name: "http",
  fullName: "Webhook",
  webhook: "",
  authKey: "",
  enabled: false,

  isVisibleToClient(): boolean {
    return true;
  },
}) {}
