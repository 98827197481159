import { createAlert, dismissAlert } from "actions/alerts";
import { type AlertButtons } from "actions/alerts/types";
import { setPage } from "actions/router";
import { type AsyncAction } from "actions/types";

import { getAlreadyTrainedError } from "./getAlreadyTrainedError";
import { moveExpression } from "./moveExpression";

interface AlreadyTrainedAlertArgs {
  response: {
    data: { response_id: string; expression_id: string; language: string };
  };
  currentResponseId: string;
  body: string;
}

export function alreadyTrainedAlert(
  args: AlreadyTrainedAlertArgs,
): AsyncAction {
  const existingResponseId = args.response.data.response_id;
  const existingExpressionId = args.response.data.expression_id;
  const existingLanguageCode = args.response.data.language;
  const { currentResponseId } = args;

  return (dispatch, getState) => {
    const message = getAlreadyTrainedError({
      state: getState(),
      currentResponseId,
      existingResponseId,
      existingLanguageCode,
    });

    const openAnswerButton = {
      label: "Open Answer",
      action: () => {
        dispatch(dismissAlert());
        dispatch(setPage(`/answers/${existingResponseId}`));
      },
    };
    const moveHereButton = {
      label: "Move here",
      action: () => {
        dispatch(dismissAlert());
        dispatch(
          moveExpression(existingExpressionId, currentResponseId, args.body),
        );
      },
    };

    let buttons = <AlertButtons>[];

    if (!currentResponseId) {
      buttons = [openAnswerButton];
    } else if (currentResponseId === existingResponseId) {
      buttons = [openAnswerButton, moveHereButton];
    }

    const newArgs = { ...args, message, buttons };

    dispatch(createAlert(newArgs));
  };
}
