import Immutable from "immutable";

export type LoginFailureReason = "default" | "rate_limit" | "password_expired";

interface LoginRecordAttributes {
  currentStep: number;
  email: string;
  showError: boolean;
  isPasswordResetShown: boolean;
  password: string;
  tfa_token: string;
  lastLoginFailureReason: LoginFailureReason;
}

const LoginRecordDefault: LoginRecordAttributes = {
  currentStep: 1,
  email: "",
  showError: false,
  isPasswordResetShown: false,
  password: "",
  tfa_token: "",
  lastLoginFailureReason: "default",
};

export class LoginRecord extends Immutable.Record(LoginRecordDefault) {}

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
interface Response {
  data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  status: number;
}

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
export const SET_LOGIN = "SET_LOGIN";
export interface setLoginAction {
  type: typeof SET_LOGIN;
  payload: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export interface setLoginErrorAction {
  type: typeof SET_LOGIN_ERROR;
}

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export interface setCredentialsAction {
  type: typeof SET_CREDENTIALS;
  payload: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const SMS_VERIFY_SUCCESS = "SMS_VERIFY_SUCCESS";
export interface smsVerifySuccessAction {
  type: typeof SMS_VERIFY_SUCCESS;
  response: Response;
}

export const CLEAR_CREDENTIALS = "CLEAR_CREDENTIALS";
export interface clearCredentialsAction {
  type: typeof CLEAR_CREDENTIALS;
}

export const RESET_NOTIFICATION_ERROR = "RESET_NOTIFICATION_ERROR";
export interface resetNotificationErrorAction {
  type: typeof RESET_NOTIFICATION_ERROR;
}

export const SET_PASSWORD_EXPIRED = "SET_PASSWORD_EXPIRED";
export interface setPasswordExpiredAction {
  type: typeof SET_PASSWORD_EXPIRED;
}

export const LOGIN_FAILURE = "LOGIN_FAILURE";
export interface loginFailureAction {
  type: typeof LOGIN_FAILURE;
}

export const LOGIN_RATE_LIMIT = "LOGIN_RATE_LIMIT";
export interface loginRateLimitAction {
  type: typeof LOGIN_RATE_LIMIT;
}

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export interface resetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS;
}

export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export interface resetPasswordFailureAction {
  type: typeof RESET_PASSWORD_FAILURE;
}

export const RESET_EXPIRED_PASSWORD_SUCCESS = "RESET_EXPIRED_PASSWORD_SUCCESS";
export interface resetExpiredPasswordAction {
  type: typeof RESET_EXPIRED_PASSWORD_SUCCESS;
}

export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export interface setCurrentStepAction {
  type: typeof SET_CURRENT_STEP;
}

export const SET_PREVIOUS_STEP = "SET_PREVIOUS_STEP";
export interface setPreviousStepAction {
  type: typeof SET_PREVIOUS_STEP;
}

export const ENFORCE_MFA_ACTIVATE_TFA_FAILURE =
  "ENFORCE_MFA_ACTIVATE_TFA_FAILURE";
export interface enforceMfaActivateTfaFailureAction {
  type: typeof ENFORCE_MFA_ACTIVATE_TFA_FAILURE;
  response: Response;
}

export const ENFORCE_MFA_ACTIVATE_TFA_SUCCESS =
  "ENFORCE_MFA_ACTIVATE_TFA_SUCCESS";
export interface enforceMfaActivateTfaSuccessAction {
  type: typeof ENFORCE_MFA_ACTIVATE_TFA_SUCCESS;
}

export const SMS_VERIFY_FAILURE = "SMS_VERIFY_FAILURE";
export interface smsVerifyFailureAction {
  type: typeof SMS_VERIFY_FAILURE;
}

export const ENFORCE_MFA_SMS_VERIFY_SUCCESS = "ENFORCE_MFA_SMS_VERIFY_SUCCESS";
export interface enforceMfaSmsVerifySuccessAction {
  type: typeof ENFORCE_MFA_SMS_VERIFY_SUCCESS;
  response: Response;
}

export const ENFORCE_MFA_SMS_VERIFY_FAILURE = "ENFORCE_MFA_SMS_VERIFY_FAILURE";
export interface enforceMfaSmsVerifyFailureAction {
  type: typeof ENFORCE_MFA_SMS_VERIFY_FAILURE;
}

export type LoginAction =
  | setLoginAction
  | setLoginErrorAction
  | setCredentialsAction
  | smsVerifySuccessAction
  | clearCredentialsAction
  | resetNotificationErrorAction
  | setPasswordExpiredAction
  | loginFailureAction
  | loginRateLimitAction
  | resetPasswordSuccessAction
  | resetPasswordFailureAction
  | resetExpiredPasswordAction
  | setCurrentStepAction
  | setPreviousStepAction
  | enforceMfaActivateTfaFailureAction
  | enforceMfaActivateTfaSuccessAction
  | smsVerifyFailureAction
  | enforceMfaSmsVerifySuccessAction
  | enforceMfaSmsVerifyFailureAction;
