// @ts-strict-ignore
import moment from "moment-timezone";
import React, { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";

import { createAlert as createAlertAction } from "actions/alerts";
import { Button } from "components/Common/Button";
import { InputText } from "components/Common/InputText";
import { Loading } from "components/Common/Loading";
import ReadOnlyInput from "components/Common/ReadOnlyInput";
import SvgIcon from "components/Common/SvgIcon";
import { c } from "constants/css";
import { getAPIRoot } from "services/api-root";

import { fetchApiKeyInfo, generateApiKey } from "./actions";
import { initialState, reducer } from "./reducer";
import * as S from "./styles";

export function SettingsPlatformsProactiveSmsApiModal() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const globalDispatch = useDispatch();

  useEffect(() => {
    fetchApiKeyInfo(state.loading, dispatch);
  }, [dispatch, state.loaded]);

  function generateApiKeyAndCloseConfirmation() {
    dispatch({ type: "CLOSE_CONFIRMATION_WARNING" });
    generateApiKey(dispatch);
  }

  function handleCopyClicked(textToCopy: string) {
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      globalDispatch(
        createAlertAction({
          message: "Copied to clipboard!",
          alertType: "success",
        }),
      );
    }
  }

  function showGenerateNewApiKeyWarningModal() {
    dispatch({ type: "SHOW_CONFIRMATION_WARNING" });
  }

  function cancelKeyGeneration() {
    dispatch({ type: "CLOSE_CONFIRMATION_WARNING" });
  }

  function renderConfirmationWarning() {
    return (
      <>
        <S.Title>Confirm Token Generation</S.Title>
        <S.Description>
          Generating a new Access Token will{" "}
          <S.BoldText>immediately invalidate</S.BoldText> your previous token.
          Your API integrations will stop working until they’re updated to use
          the new token.
        </S.Description>
        <S.ButtonContainer>
          <Button light text="Cancel" onClick={() => cancelKeyGeneration()} />
          <Button
            light
            text="Generate a new Access Token"
            onClick={() => generateApiKeyAndCloseConfirmation()}
          />
        </S.ButtonContainer>
      </>
    );
  }

  function renderLoadingState() {
    return (
      <S.Centered>
        <Loading />
      </S.Centered>
    );
  }

  function renderErrorState() {
    return (
      <S.WarningMessage>
        <SvgIcon icon="Error" height={20} fillColor={c.colorUiBad} />
        <S.WarningMessageText>Something went wrong.</S.WarningMessageText>
      </S.WarningMessage>
    );
  }

  function renderEmptyState() {
    return (
      <>
        <S.Title>API Access Token</S.Title>
        <S.Description>
          You haven&apos;t set up a Proactive SMS API access token yet.
        </S.Description>
        <S.ButtonContainer>
          <Button
            light
            text="Generate a new Access Token"
            onClick={() => generateApiKey(dispatch)}
          />
        </S.ButtonContainer>
      </>
    );
  }

  function renderWarningMessage() {
    return (
      <S.WarningMessage>
        <SvgIcon icon="Error" height={20} fillColor={c.colorUiBad} />
        <S.WarningMessageText>
          <S.BoldText>This Access Token will be shown only once.</S.BoldText>{" "}
          You need to copy it before you close this modal.
        </S.WarningMessageText>
      </S.WarningMessage>
    );
  }

  const proactiveSmsApiUrl = `${getAPIRoot()}/campaigns/sms/trigger`;

  function renderLatestApiKeyInfo() {
    return (
      <>
        <S.Description>
          View the{" "}
          <a
            href="https://docs.ada.cx/developer-proactive-sms-api"
            target="_blank"
            rel="noopener noreferrer"
          >
            developer documentation
          </a>{" "}
          to learn more about the Proactive SMS API.
        </S.Description>
        <S.Title>Proactive SMS API URL</S.Title>
        <ReadOnlyInput value={proactiveSmsApiUrl} />
        <S.Title>API Access Token</S.Title>
        <S.InputContainer>
          <InputText
            multiline
            value={state.apiKey || "••••••••••••••••••••••••••••••••••••••"}
            className="ph-no-capture"
            disabled
          />
          {state.apiKey && (
            <S.CopyButtonContainer>
              <Button
                light
                text="Copy"
                onClick={() => handleCopyClicked(state.apiKey as string)}
              />
            </S.CopyButtonContainer>
          )}
        </S.InputContainer>

        {state.apiKey ? (
          renderWarningMessage()
        ) : (
          <>
            <S.ExtraInfo>
              This Access Token was generated at{" "}
              <S.BoldText>
                {moment(state.metadata.createdAt).format("LT")}
              </S.BoldText>{" "}
              on{" "}
              <S.BoldText>
                {moment(state.metadata.createdAt).format("MMM DD, YYYY")}
              </S.BoldText>
              .
            </S.ExtraInfo>
            <S.ButtonContainer>
              <Button
                light
                text="Generate a new Access Token"
                onClick={() => showGenerateNewApiKeyWarningModal()}
              />
            </S.ButtonContainer>
          </>
        )}
      </>
    );
  }

  function renderContent() {
    if (state.confirmationWarning) {
      return renderConfirmationWarning();
    }

    if (state.loading) {
      return renderLoadingState();
    }

    if (state.error) {
      return renderErrorState();
    }

    if (!state.apiKey && !state.metadata) {
      return renderEmptyState();
    }

    return renderLatestApiKeyInfo();
  }

  return (
    <div>
      <h5 className="Modal__title">Proactive SMS API</h5>
      <S.Container>{renderContent()}</S.Container>
    </div>
  );
}
