import { useEffect } from "react";

/**
 * Accepts a function that is called when a component initially mounts.
 * The function is not called on subsequent re-renders.
 *
 * This is named after the old classical React lifecyle method, ComponentDidMount,
 * which had the same behaviour.
 *
 * @see https://reactjs.org/docs/react-component.html#componentdidmount
 */
export function useComponentDidMount(func: () => void) {
  useEffect(func, []); // eslint-disable-line react-hooks/exhaustive-deps
}
