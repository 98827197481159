import styled from "styled-components";

import { colorPrimitives, v } from "constants/css";

export const ResolutionModalTitle = styled.div`
  font-weight: ${v.xHeavyFontWeight};
  font-size: ${v.mainFontSize};
  text-align: center;
  padding: ${v.gUnitDouble};
`;

export const ResolutionModalSuccessRate = styled.div`
  background-color: ${colorPrimitives.green100};
  border-radius: ${v.gUnit};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${v.gUnitDouble};
  margin: ${v.gUnitDouble} 0;
`;

export const ResolutionModalSuccessText = styled.div`
  font-size: ${v.bigFontSize};
  color: ${colorPrimitives.green500};
  font-weight: ${v.lightFontWeight};
`;

export const ResolutionModalDetails = styled.div`
  font-size: ${v.smallFontSize};
`;
