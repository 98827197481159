// @ts-strict-ignore
import { Button, Icon } from "@adasupport/byron";
import { type Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import React, { type ReactNode } from "react";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";

import { patchClientAction } from "actions";
import { CheckboxPanel } from "components/Common/CheckboxPanel";
import { type ClientLegacy } from "resourceModels";
import { clientSelector } from "selectors/client";
import { type State } from "types";

interface PropsFromState {
  client: ClientLegacy | null;
}

interface ActionProps {
  patchClient: typeof patchClientAction;
}
type SettingsPlatformsAdaEmailProps = PropsFromState & ActionProps;

interface ComponentState {
  email: string;
  reverseTranscript: boolean | null;
}

class SettingsPlatformsAdaEmailModal extends React.PureComponent<
  SettingsPlatformsAdaEmailProps,
  ComponentState
> {
  state: ComponentState = {
    email: "",
    reverseTranscript: null,
  };

  save = (): void => {
    const { patchClient, client } = this.props;
    const { email, reverseTranscript } = this.state;

    let clientHandoffUpdate = client.handoff;

    if (email !== "") {
      clientHandoffUpdate = clientHandoffUpdate.set("defaultEmail", email);
    }

    if (reverseTranscript !== null) {
      clientHandoffUpdate = clientHandoffUpdate.set(
        "reverseTranscript",
        reverseTranscript,
      );
    }

    patchClient({
      handoff: clientHandoffUpdate,
    });
  };

  render(): ReactNode {
    const { client } = this.props;
    const { email, reverseTranscript } = this.state;

    const clientDefaultEmail = client.handoff.get("defaultEmail") as string;
    const clientReverseTranscript = client.handoff.get(
      "reverseTranscript",
    ) as boolean;

    const emailIsValid = isEmail(email || clientDefaultEmail || "");
    const emailHasChanged = email !== "" && email !== clientDefaultEmail;

    const transcriptOrderHasChanged =
      reverseTranscript !== null &&
      reverseTranscript !== clientReverseTranscript;

    const canSave =
      (emailIsValid && emailHasChanged) || transcriptOrderHasChanged;

    return (
      <div className="SettingsPlatformsAdaEmailModal Modal__modal">
        <h5 className="Modal__title">Ada Email</h5>
        <div className="Modal__content">
          <section className="Modal__section">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformsAdaEmailModal__access-token"
              >
                Default Support Email
              </label>
              <input
                className={`SettingsPlatformsAdaEmailModal__default-email g-input ${
                  !emailIsValid && "g-input--invalid"
                }`}
                value={email || clientDefaultEmail || null}
                onChange={({ target: { value } }) =>
                  this.setState({ email: value })
                }
                name="email"
                placeholder="support@acme.com"
                type="text"
                autoComplete="off"
              />
            </section>
          </section>
          <section>
            <section>
              <CheckboxPanel
                handleToggle={(checked: boolean) =>
                  this.setState({ reverseTranscript: checked })
                }
                checked={
                  reverseTranscript !== null
                    ? reverseTranscript
                    : clientReverseTranscript
                }
                label="Reverse Transcript Order"
              >
                If on, the order of Handoff transcript messages will appear in
                reverse-chronological order with newest messages at the top
              </CheckboxPanel>
            </section>
          </section>
        </div>
        <div className="Modal__bottom SettingsPlatformsAdaEmailModal__bottom">
          <Button
            onClick={this.save}
            text="Save"
            icon={Icon.Cloud}
            isDisabled={!canSave}
          />
        </div>
      </div>
    );
  }
}

function mapState(state: State): PropsFromState {
  return {
    client: clientSelector(state),
  };
}

function mapDispatch(dispatch: Dispatch): ActionProps {
  return {
    patchClient: bindActionCreators(patchClientAction, dispatch),
  };
}

const Connector = connect(
  mapState,
  mapDispatch,
)(SettingsPlatformsAdaEmailModal);

export default Connector;
