import React from "react";

import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";

interface Props {
  goToNextScreen: () => void;
  closeModal: () => void;
  clientHasZendeskMessaging: boolean;
}

export const IntroScreen = ({
  goToNextScreen,
  closeModal,
  clientHasZendeskMessaging,
}: Props) => {
  const guideLink = clientHasZendeskMessaging
    ? "https://docs.ada.cx/ada-glass-zendesk-messaging"
    : "https://docs.ada.cx/zendesk-social-messaging";

  return (
    <>
      <div className="Modal__content ZendeskAgentWorkspace__Intro">
        <h2>Connect Ada to Zendesk Messaging</h2>
        <p>
          Zendesk Messaging uses{" "}
          <a
            href="https://app.smooch.io/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sunshine Conversations
          </a>{" "}
          to connect agents with customers. You&apos;ll need to have your
          Sunshine Conversations dashboard and our setup guide open during this
          process:
        </p>
        <a
          href={guideLink}
          target="_blank"
          rel="noopener noreferrer"
          className="ZendeskAgentWorkspace__Intro__popout-link"
        >
          <SvgIcon icon="PopOut" />
          <div>Open Zendesk Messaging Guide</div>
        </a>
      </div>
      <div className="Modal__bottom ZendeskAgentWorkspace__Intro__bottom">
        <Button
          onClick={closeModal}
          text="Cancel"
          title="Cancel"
          customClassName="ZendeskAgentWorkspaceModal__cancel-button"
          light
        />
        <Button onClick={goToNextScreen} text="Next" title="Next" />
      </div>
    </>
  );
};
