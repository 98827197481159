export function getChatURL(urlParams = {}): string {
  let url = `${window.location.protocol}//${window.location.host}/chat/?test_user=1`;

  if (process.env.NODE_ENV === "development") {
    url = `http://${window.location.hostname}:9001?test_user=1`;
  }

  Object.entries(urlParams).forEach((urlParam) => {
    const key = urlParam[0];
    const val = urlParam[1];
    url += `&${key}=${val}`;
  });

  return url;
}

type URLParts = [string, string, ...string[]]; // e.g. ["client", "ada", "support"]

export function getTestBotHandle(): string {
  const urlParts = window.location.host.split(".") as URLParts;

  return urlParts[0];
}

/**
 * Examines the URL to get the correct domain to specify for Embed
 * e.g. "ada", "ada-dev", "ada-stage"
 * localhost returns the default
 */
export function getTestBotDomain(): string | null {
  const urlParts = window.location.hostname.split(".") as URLParts;

  // If the host ends with "localhost", this is local development environment
  // We should not pass `domain` to Embed
  // in order not to make it try to connect to production API and Chat
  if (urlParts[urlParts.length - 1] === "localhost") {
    return null;
  }

  if (urlParts.length >= 3) {
    // Domain is the second last part, e.g. handle.eu.ada-dev.support -> ada-dev
    return urlParts[urlParts.length - 2] as string;
  }

  return "ada";
}

/**
 * Examines the URL to get the correct cluster to specify for Embed
 * e.g. "ca", "att", "" (if empty, Embed defaults to the US cluster)
 * localhost returns the default
 *
 * @returns {String}
 */
export function getTestBotCluster(): string {
  const urlParts = window.location.host.split(".") as URLParts;

  if (urlParts.length === 4) {
    return urlParts[1];
  }

  return ""; // defaults to US cluster
}
