// @ts-strict-ignore
/* eslint-disable max-classes-per-file */
import stringify from "json-stable-stringify";
import memoize from "lodash.memoize";
import queryString from "query-string";

import {
  Operators,
  type SelectOption,
  supportedFiltersConfig,
} from "components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/AnalyticsReportFilter/constants";
import { deserializeFilters } from "components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/AnalyticsReportFilter/services";
import { type AnalyticsTimeSeriesUnit } from "resourceModels/AnalyticsTimeSeriesRecord/helpers";
import { AdaDateTime } from "services/AdaDateTime";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "services/date-strings";
import { utf8ToBase64 } from "services/strings";
import colors from "stylesheets/utilities/colors.scss";

export const TODAY = AdaDateTime.format(
  new Date(),
  AdaDateTime.DATE_FORMATS.INTL_STANDARD,
);
export const TIME_ZONE = AdaDateTime.getClientTimeZone();

export function isDateRangeValid(
  startDate: string,
  endDate: string,
  minDate?: string,
  maxDate?: string,
): boolean {
  const minDateObject = minDate && new Date(minDate);
  const maxDateObject = maxDate && new Date(maxDate);
  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);

  if (endDateObject < startDateObject) {
    return false;
  }

  if (minDateObject && startDateObject < minDateObject) {
    return false;
  }

  if (maxDateObject && endDateObject > maxDateObject) {
    return false;
  }

  return true;
}

export const REQUEST_DEBOUNCE_TIME = 1000;
export const BUTTON_CLICK_DEBOUNCE_TIME = 500;

/**
 * @param {Array.<String>} allowedUnitValues
 * @returns {Array.<Object>}
 */
export const getUnitSelectOptions = memoize((allowedUnitValues) =>
  [
    {
      label: "Hourly",
      value: "hour",
    },
    {
      label: "Daily",
      value: "day",
    },
    {
      label: "Weekly",
      value: "week",
    },
    {
      label: "Monthly",
      value: "month",
    },
  ].filter((option) => allowedUnitValues.includes(option.value)),
);

/**
 * @param {String} startDate
 * @param {String} endDate
 * @returns {AnalyticsTimeSeriesUnit[]}
 */
export function getAllowedUnitValues(
  startDate: string,
  endDate: string,
): AnalyticsTimeSeriesUnit[] {
  const periodLengthDays = AdaDateTime.diff(endDate, startDate, "DAYS");

  if (periodLengthDays < 2) {
    return ["hour", "day"];
  }

  if (periodLengthDays < 6) {
    return ["day"];
  }

  if (periodLengthDays < 28) {
    return ["day", "week"];
  }

  // Making sure the points in analytics chart are not too close to each other
  if (periodLengthDays > 60) {
    return ["week", "month"];
  }

  return ["day", "week", "month"];
}

const SCALE_COLORS_NUMBER = 11;

/**
 * @param {Number} value - [0..1]
 * @returns {String}
 */
export function getScaleColor(value: number): string {
  const colorIndex = Math.round(value * (SCALE_COLORS_NUMBER - 1));

  return colors[`colorCSAT${colorIndex}`];
}

function removeDuplicates(array: string[]): string[] {
  return array.filter((elem, index, self) => index === self.indexOf(elem));
}

const QUERY_PARAMS_ARRAY_SEPARATOR = ",";

interface FilterConfig {
  queryParam: string;
  isArray?: boolean;
  isBoolean?: boolean;
  values?: unknown[];
}

export interface AnalyticsReportFilterAttributes {
  startDate: string;
  endDate: string;
  timeSeriesUnit?: AnalyticsTimeSeriesUnit;
  answerId?: string[];
  blockType?: string[];
  browser?: string[];
  device?: string[];
  locked?: boolean;
  isTestUser?: boolean;
  includeIntros?: boolean;
  interactionType?: string[];
  language?: string[];
  platform?: string[];
  agentIds?: string[];
  feedback?: string[];
  isAgentReview?: boolean;
  arStatus?: string;

  /** list of object encoded as base64 string. Encoded object structure:
   *   { id: string; operator: string; value: string }
   *  Note that the id is the variable id.
   */
  variables?: string;

  [key: string]: unknown; // index signature required by TypeScript
}

interface ExtraAnalyticsReportFilterAttributes {
  dataType: string; // report name, the same as shown in single report url
  timeZone?: string;
  [key: string]: unknown;
}

export interface AnalyticsRequestParamsAttributes {
  filter: ExtraAnalyticsReportFilterAttributes;
  page?: {
    limit: number;
    number: number;
  };
}

const FILTER_CONFIG: { [key: string]: FilterConfig } = {
  answerId: {
    queryParam: "a",
    isArray: true,
  },
  blockType: {
    queryParam: "bt",
    isArray: true,
  },
  browser: {
    queryParam: "b",
    isArray: true,
  },
  device: {
    queryParam: "d",
    isArray: true,
  },
  locked: {
    queryParam: "ela",
    isBoolean: true,
    values: [undefined, false], // A pair of possible values, first is the default one
  },
  isTestUser: {
    queryParam: "tu",
    isBoolean: true,
    values: [false, undefined],
  },
  includeIntros: {
    queryParam: "ii",
    isBoolean: true,
    values: [false, true],
  },
  interactionType: {
    queryParam: "it",
    isArray: true,
  },
  language: {
    queryParam: "l",
    isArray: true,
  },
  platform: {
    queryParam: "p",
    isArray: true,
  },
  channel: {
    queryParam: "c",
    isArray: true,
  },
  timeSeriesUnit: {
    queryParam: "tsu",
  },
  agentIds: {
    queryParam: "ag",
    isArray: true,
  },
  feedback: {
    queryParam: "fdb",
    isArray: true,
  },
  isAgentReview: {
    queryParam: "ar",
    isBoolean: true,
    values: [false, true],
  },

  startDate: { queryParam: "sd" },

  endDate: { queryParam: "ed" },
};

export interface AnalyticsReportPaginationAttributes {
  page: number;
  pageSize: number;
  isEqual(filter: Partial<AnalyticsReportPaginationAttributes>): boolean;
}

type SortDirection = "desc" | "asc";

export interface AnalyticsReportSortingAttributes {
  sortColumn: string;
  sortDirection: SortDirection;
  isEqual(filter: Partial<AnalyticsReportSortingAttributes>): boolean;
}

const BUILT_IN_FILTERS = ["startDate", "endDate", "timeSeriesUnit"];

export class AnalyticsReportFilterData
  implements AnalyticsReportFilterAttributes
{
  startDate = DEFAULT_START_DATE;
  endDate = DEFAULT_END_DATE;
  [key: string]: unknown;

  constructor(filterData: Partial<AnalyticsReportFilterAttributes>) {
    Object.keys(filterData).forEach((filterName) => {
      this[filterName] = filterData[filterName];
    });
  }

  serialize(): Record<string, unknown> {
    // Possible key value is FILTER_CONFIG[filterName].queryParam
    const urlParams: Record<string, unknown> = {};

    Object.keys(this).forEach((filterName) => {
      if (!FILTER_CONFIG[filterName]) {
        window.console.warn(`Unknown filter name - "${filterName}"`);

        return;
      }

      if (FILTER_CONFIG[filterName].isArray) {
        urlParams[FILTER_CONFIG[filterName].queryParam] = (
          this[filterName] as string[]
        ).length
          ? (this[filterName] as string[]).join(QUERY_PARAMS_ARRAY_SEPARATOR)
          : undefined;
      } else if (FILTER_CONFIG[filterName].isBoolean) {
        if (FILTER_CONFIG[filterName].values) {
          urlParams[FILTER_CONFIG[filterName].queryParam] =
            this[filterName] === FILTER_CONFIG[filterName].values[1]
              ? 1
              : undefined;
        } else {
          urlParams[FILTER_CONFIG[filterName].queryParam] = this[filterName];
        }
      } else {
        urlParams[FILTER_CONFIG[filterName].queryParam] = this[filterName];
      }
    });

    return urlParams;
  }

  isEqual(filter: AnalyticsReportFilterAttributes): boolean {
    return stringify(this) === stringify(filter);
  }

  /**
   * @deprecated
   */
  static deserialize(
    string: string,
    allowedFilters: string[],
    dataToExtendFrom?: Record<string, unknown>,
  ): Partial<AnalyticsReportFilterAttributes> {
    // This method is deprecated in favor of the `deserializeFilters` below.
    const params = queryString.parse(string);
    const filterData: Partial<AnalyticsReportFilterAttributes> = {};

    [...allowedFilters, ...BUILT_IN_FILTERS].forEach((filterName) => {
      /**
       * Remove "Unknown filter name" warning for clients without `analytics_variable_filter`
       * feature flag enabled.
       */
      if (filterName === "variable") {
        return;
      }

      if (!FILTER_CONFIG[filterName]) {
        window.console.warn(`Unknown filter name - "${filterName}"`);

        return;
      }

      if (FILTER_CONFIG[filterName].isArray) {
        filterData[filterName] = params[FILTER_CONFIG[filterName].queryParam]
          ? (params[FILTER_CONFIG[filterName].queryParam] as string).split(
              QUERY_PARAMS_ARRAY_SEPARATOR,
            )
          : [];
      } else if (FILTER_CONFIG[filterName].isBoolean) {
        if (FILTER_CONFIG[filterName].values) {
          filterData[filterName] =
            params[FILTER_CONFIG[filterName].queryParam] === "1"
              ? FILTER_CONFIG[filterName].values[1]
              : FILTER_CONFIG[filterName].values[0];
        } else {
          filterData[filterName] =
            params[FILTER_CONFIG[filterName].queryParam] === "1";
        }
      } else if (
        Object.prototype.hasOwnProperty.call(
          params,
          FILTER_CONFIG[filterName].queryParam,
        )
      ) {
        filterData[filterName] = params[FILTER_CONFIG[filterName].queryParam];
      }
    });

    return new AnalyticsReportFilterData({
      ...dataToExtendFrom,
      ...filterData,
    });
  }

  // eslint-disable-next-line max-params
  static deserializeFilters(
    string: string,
    allowedFilters: string[],
    /** allFilterOptions contains all possible options for any array type of filter */
    allFilterOptions: {
      [key: string]: SelectOption[];
    },
    dataToExtendFrom?: Record<string, unknown>,
    options?: {
      appendScopeToVariableId?: boolean;
    },
  ): AnalyticsReportFilterData {
    const params = queryString.parse(string);
    const filterData: Partial<AnalyticsReportFilterAttributes> = {};
    const variableFiltersData: {
      id: string;
      name: string;
      operator: string;
      value: string;
    }[] = [];

    // Adding builtin filters
    [...BUILT_IN_FILTERS].forEach((filterName) => {
      if (!FILTER_CONFIG[filterName]) {
        window.console.warn(`Unknown filter name - "${filterName}"`);

        return;
      }

      if (
        Object.prototype.hasOwnProperty.call(
          params,
          FILTER_CONFIG[filterName].queryParam,
        )
      ) {
        filterData[filterName] = params[FILTER_CONFIG[filterName].queryParam];
      }
    });

    // Getting filter content from filter string stored in url "f" parameter
    const filtersString = params.f;
    let filters = deserializeFilters(filtersString as string);

    // Sort filters so that filters with IS operators is handled before IS_NOT operations
    filters = filters.sort((item1) =>
      item1.operator === Operators.IS ? -1 : 0,
    );

    // Processes all filters to combine them to AnalyticsReportFilterAttributes instance
    filters.forEach((filterItem) => {
      if (filterItem.filterType === null) {
        return;
      }

      if (!supportedFiltersConfig[filterItem.filterType]) {
        window.console.warn(
          `Unknown filter - "${filterItem.filterType}" - please update supportedFiltersConfig`,
        );

        return;
      }

      if (filterItem.filterType === "variable" && filterItem.variable) {
        // Handles variable filters specifically
        variableFiltersData.push({
          id: options?.appendScopeToVariableId
            ? `${filterItem.variable.scope}.${filterItem.variable.id}`
            : filterItem.variable.id,
          name: filterItem.variable.name,
          operator: Operators[filterItem.operator], // Getting enum name
          value: filterItem.value as string,
        });
      } else if (Array.isArray(filterItem.value)) {
        /**
         * Handle array type of filter (multi-select)
         */
        if (
          filterItem.operator !== Operators.IS &&
          filterItem.operator !== Operators.ISNOT
        ) {
          window.console.warn(
            `Unsupported array filter operator  - "${filterItem.operator}"`,
          );

          return;
        }

        let filterDataField = filterData[filterItem.filterType] as
          | string[]
          | undefined;

        // Filter by user selected values which are stored in filterItem.value
        if (filterItem.operator === Operators.IS) {
          if (!filterDataField) {
            filterDataField = filterItem.value;
          } else {
            filterDataField = filterDataField.concat(filterItem.value);
          }
        }

        // Filter by exception, or remove values from previously selected values
        if (filterItem.operator === Operators.ISNOT) {
          /**
           * If user has already specified a set of value to look for for the filter (IS operation),
           * then selected some other values for `IS_NOT` operation, we just exclude values in
           * IS_NOT operation from IS operation
           */
          if (filterDataField && filterDataField.length > 0) {
            filterItem.value.forEach((item) => {
              filterDataField = filterDataField?.filter(
                (filterDataItem) => filterDataItem !== item,
              );
            });
          } else {
            /**
             * If user hasn't specified any value for the IS operation, it means user wants to
             * select everything except what's specified in here for `IS_NOT` operation
             */
            const possibleOptions = allFilterOptions[filterItem.filterType];
            filterDataField = possibleOptions
              ? possibleOptions
                  .filter(
                    (option) =>
                      (filterItem.value as string[]).indexOf(option.value) < 0,
                  )
                  .map((option) => option.value)
              : [];
          }
        }

        filterData[filterItem.filterType as string] = removeDuplicates(
          filterDataField || [],
        );
      } else {
        /**
         * Handle boolean or string type of filter value
         */
        const { filterType, value } = filterItem;
        const filterConfigs = supportedFiltersConfig[filterType] as {
          possibleValues: unknown[];
        };
        const { possibleValues } = filterConfigs;
        const [valueForOperatorIsNot, valueForOperatorIs] = possibleValues;

        if (filterItem.operator === Operators.IS) {
          // TODO: See if this condition can be removed
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (possibleValues) {
            filterData[filterType as string] = valueForOperatorIs;
          } else {
            filterData[filterType as string] = value;
          }
        }

        if (filterItem.operator === Operators.ISNOT) {
          // TODO: See if this condition can be removed
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (possibleValues) {
            filterData[filterItem.filterType as string] = valueForOperatorIsNot;
          } else {
            filterData[filterItem.filterType as string] = !value;
          }
        }
      }
    });

    if (variableFiltersData.length > 0) {
      filterData.variables = utf8ToBase64(JSON.stringify(variableFiltersData));
    }

    return new AnalyticsReportFilterData({
      // This is required because by default, api response will include test user
      isTestUser: false,
      ...dataToExtendFrom,
      ...filterData,
    });
  }
}

export class AnalyticsReportPagination
  implements AnalyticsReportPaginationAttributes
{
  page = 1;
  pageSize = 10;

  constructor(pagination: Partial<AnalyticsReportPaginationAttributes>) {
    this.page = pagination.page;
    this.pageSize = pagination.pageSize;
  }

  static deserialize(urlQueryString: string): AnalyticsReportPagination {
    const params = queryString.parse(urlQueryString);
    const page = params.page ? parseInt(params.page as string, 10) : 1;
    const pageSize = params.pageSize
      ? parseInt(params.pageSize as string, 10)
      : 100;

    return new AnalyticsReportPagination({
      page,
      pageSize,
    });
  }

  isEqual(filter: Partial<AnalyticsReportPaginationAttributes>): boolean {
    return stringify(this) === stringify(filter);
  }
}

export class AnalyticsReportSorting
  implements AnalyticsReportSortingAttributes
{
  sortColumn: string;
  sortDirection = "desc" as SortDirection;

  constructor(sorting: Partial<AnalyticsReportSortingAttributes>) {
    this.sortColumn = sorting.sortColumn;
    this.sortDirection = sorting.sortDirection;
  }

  static deserialize(urlQueryString: string): AnalyticsReportSorting | null {
    const params = queryString.parse(urlQueryString);

    if (!(params.sortColumn || params.sortDirection)) {
      return null;
    }

    return new AnalyticsReportSorting({
      sortColumn: params.sortColumn as string,
      sortDirection: params.sortDirection as SortDirection,
    });
  }

  static serialize(analyticsReportSorting: Partial<AnalyticsReportSorting>): {
    label?: string;
    direction?: string;
  } {
    return {
      label: analyticsReportSorting.sortColumn,
      direction: analyticsReportSorting.sortDirection,
    };
  }

  isEqual(filter: unknown): boolean {
    return stringify(this) === stringify(filter);
  }
}
