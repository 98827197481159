import { fetchOne } from "actions/resources";

export function removeTFA(userId) {
  return (dispatch) =>
    dispatch({
      // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
      CALL_API: {
        method: "delete",
        endpoint: `/users/${userId}/2fa/`,
        types: [
          "DISABLE_TFA_REQUEST",
          "DISABLE_TFA_SUCCESS",
          "DISABLE_TFA_FAILURE",
        ],
        dispatchCallbacks: [
          {
            request() {
              return dispatch(fetchOne("user"));
            },
            fireOnStatus: "success",
          },
        ],
      },
    });
}

export function setSettingsSecurityState(change) {
  return {
    type: "SET_SETTINGS_SECURITY",
    change,
  };
}
