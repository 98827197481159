// @ts-strict-ignore
import moment from "moment-timezone";

import {
  type AnalyticsReportFilterAttributes,
  type AnalyticsReportPaginationAttributes,
  AnalyticsReportSorting,
  TIME_ZONE,
} from "components/Shared/Pages/AnalyticsPage/services";

export const INTERACTION_TYPES = [
  {
    id: "text",
    name: "Typed Message",
  },
  {
    id: "suggestion",
    name: "Quick Reply",
  },
  {
    id: "predictive_suggestion",
    name: "Suggestion",
  },
  {
    id: "clarification",
    name: "Clarification",
  },
  {
    id: "undefined",
    name: "Undefined",
  },
];

export const QUERY_PARAM_FOR_HIDE_PREVIOUS_TOGGLE = "hide-previous";

interface ExtraAnalyticsReportFilterAttributes {
  dataType: string; // report name, the same as shown in single report url
  timeZone: string;
  [key: string]: unknown;
}

export interface AnalyticsRequestParamsAttributes {
  filter: ExtraAnalyticsReportFilterAttributes;
  page?: {
    limit: number;
    number: number;
  };
}

export function getAnalyticsListItemRequestParams(options: {
  dataType: string;
  filter: Partial<AnalyticsReportFilterAttributes>;
  pagination?: Partial<AnalyticsReportPaginationAttributes> | null;
  sort: Partial<AnalyticsReportSorting> | null;
  timeZone?: string;
}): AnalyticsRequestParamsAttributes {
  return {
    filter: {
      ...options.filter,
      dataType: options.dataType,
      timeZone: options.timeZone || TIME_ZONE,
    },
    ...(options.pagination && {
      page: {
        number: options.pagination.page - 1,
        limit: options.pagination.pageSize,
      },
    }),
    ...(options.sort && {
      sort: AnalyticsReportSorting.serialize(options.sort),
    }),
  };
}

export function formatList(listOfNames: string[]) {
  if (!listOfNames.length) {
    return "";
  }

  if (listOfNames.length === 1) {
    return listOfNames[0];
  }

  if (listOfNames.length === 2) {
    return `${listOfNames[0]} and ${listOfNames[1]}`;
  }

  if (listOfNames.length === 3) {
    return `${listOfNames[0]}, ${listOfNames[1]} and ${listOfNames[2]}`;
  }

  return `${listOfNames[0]}, ${listOfNames[1]} and ${
    listOfNames.length - 2
  } others`;
}

/**
 * Our analytics new Elastic search db only support up to 2 years worth of data, so if user select
 * more than 1 year worth of data, the combined data including previous period will be more than 2
 * years worth in total. So in this case we need to disable displaying the previous period data to
 * prevent incorrect data.
 *
 * @param startDate "YYYY-MM-DD" in utc
 */
export function shouldDisablePreviousPeriod(startDate: string): boolean {
  return moment(startDate).isBefore(moment().utc().subtract(1, "year"));
}
