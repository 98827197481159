import { createSelector } from "reselect";

import { RESPONSE_URL_RE } from "constants/responses";
import { type State } from "types";

export const selectActiveResponseId = createSelector(
  (state: State) => state.router.location,
  (location) => {
    const { pathname } = location;
    const match = RESPONSE_URL_RE.exec(pathname);

    if (match && match.length > 1) {
      const responseId = match[1];

      return responseId;
    }

    return null;
  },
);
