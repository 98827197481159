import Immutable from "immutable";

import { SEVEN_DAYS_AGO, TIME_ZONE, TODAY } from "services/date-strings";

import { AnalyticsRequestParams } from "../legacyServices";

export class AnalyticsSatisfactionRequestParams extends AnalyticsRequestParams {
  static Filter = Immutable.Record({
    startDate: SEVEN_DAYS_AGO,
    endDate: TODAY,
    timeSeriesUnit: "day",
    version: undefined as string | undefined,
    timeZone: TIME_ZONE,
  });
}

const initialState = {
  requestParams: new AnalyticsSatisfactionRequestParams({
    filter: new AnalyticsSatisfactionRequestParams.Filter(),
  }),
} as const;

function updateFilter(
  state: typeof initialState,
  action: {
    type: "UPDATE_ANALYTICS_SATISFACTION_FILTER";
    properties: object;
  },
) {
  const { requestParams } = state;

  // Create new requestParams object using `action.properties`
  let newRequestParams = new AnalyticsSatisfactionRequestParams({
    ...requestParams,
    filter: new AnalyticsSatisfactionRequestParams.Filter({
      ...requestParams.filter.toObject(),
      ...action.properties,
    }),
  });

  // If updated properties include dates, we need to check if unit should be updated accordingly
  if (
    Object.keys(action.properties).some((key) =>
      ["startDate", "endDate"].includes(key),
    ) &&
    !newRequestParams.allowedUnitValues.includes(
      requestParams.filter.timeSeriesUnit,
    )
  ) {
    newRequestParams = new AnalyticsSatisfactionRequestParams({
      ...requestParams,
      filter: new AnalyticsSatisfactionRequestParams.Filter({
        ...requestParams.filter.toObject(),
        ...action.properties,
        timeSeriesUnit: newRequestParams.allowedUnitValues[0],
      }),
    });
  }

  return {
    ...state,
    requestParams: newRequestParams,
  };
}

// eslint-disable-next-line import/no-default-export
export default function analyticsSatisfaction(
  state = initialState,
  action: {
    type: "UPDATE_ANALYTICS_SATISFACTION_FILTER";
    properties: object;
  },
): typeof initialState {
  switch (action.type) {
    case "UPDATE_ANALYTICS_SATISFACTION_FILTER": {
      return updateFilter(state, action);
    }

    default: {
      return state;
    }
  }
}
