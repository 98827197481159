import { type SyncError } from "./types";

export const syncErrors: SyncError[] = [
  {
    match: /api was unauthorized.*zendesk/,
    message:
      "Your knowledge base couldn't be synced because it's set to private. Your bot can still answer questions using previously synced knowledge, but articles won't stay up to date. To resolve this, please update your Zendesk Guide permissions and retry sync.",
    infoLink: "https://docs.ada.cx/zendesk-subdomain",
    showRetry: true,
  },
  {
    match: /api was unauthorized.*Session expired.*salesforce/,
    message:
      "Your knowledge base couldn't be synced because your Salesforce access permissions expired. Your bot can still answer questions using previously synced knowledge, but articles won't stay up to date. To start syncing with your knowledge base again, please disconnect and reconnect your Salesforce integration.",
    infoLink: "https://docs.ada.cx/reconnect-salesforce-kb",
    showRetry: false,
  },
];
