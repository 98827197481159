import { replace } from "connected-react-router";
import Immutable from "immutable";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { versionedResponsesLoadedSelector } from "components/Shared/Pages/Responses/ResponseVersions/selectors";
import { MODALITY_DETAILS } from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { useClient } from "services/client";
import { type LanguageCode } from "services/language";

import { setResponseEditorActiveLanguageAction } from "./actions";
import {
  getResponseEditorActiveLanguage,
  selectActiveModality,
  selectActiveResponse,
  selectResponsePageParams,
} from "./selectors";

/**
 * This is a little atypical - I want it to just be a normal selector, but we don't have the
 * currently edited response id in the Redux state, it comes from the URL. So instead we pass
 * it as a parameter to this hook.
 */
export function useActiveLanguageMessages(responseId: string) {
  const response = useSelector((state) =>
    versionedResponsesLoadedSelector(state).get(responseId),
  );

  const activeModality = useSelector(selectActiveModality);
  const activeLanguage = useSelector(getResponseEditorActiveLanguage);
  const messagePath = [
    MODALITY_DETAILS[activeModality].messageField,
    activeLanguage,
  ];

  return {
    messages: response?.getIn(messagePath) || Immutable.List(),
  };
}

// A helper hook responsible for managing the active language in the response editor
const useResponseEditorActiveLanguage = () => {
  const { client } = useClient();

  const dispatch = useDispatch();
  const { language: urlLanguage } = useSelector(selectResponsePageParams);

  const languageCodes = [client.language, ...client.translatedLanguages];
  let activeLanguageCode = useSelector(
    (state) => state.responsesPage.activeLanguage,
  );

  // Validate language code from URL query params
  if (languageCodes.includes(urlLanguage as LanguageCode)) {
    activeLanguageCode = urlLanguage as LanguageCode;
  }

  const updateURLSearchParams = useCallback(
    (languageCode: LanguageCode) => {
      const newSearchParams = new URLSearchParams(window.location.search);
      newSearchParams.set("language", languageCode);
      dispatch(replace(`?${newSearchParams.toString()}`));
    },
    [dispatch],
  );

  // Get language from URL query params
  useEffect(() => {
    dispatch(setResponseEditorActiveLanguageAction(activeLanguageCode));
  }, [activeLanguageCode, dispatch]);

  // In case there's no language code in the URL query params, set it
  useEffect(() => {
    if (!urlLanguage) {
      updateURLSearchParams(activeLanguageCode);
    }
  }, [activeLanguageCode, updateURLSearchParams, urlLanguage]);

  return {
    activeLanguageCode,
    setResponseEditorActiveLanguage: useCallback(
      (languageCode: LanguageCode) => {
        dispatch(setResponseEditorActiveLanguageAction(languageCode));
        updateURLSearchParams(languageCode);
      },
      [dispatch, updateURLSearchParams],
    ),
  };
};

export const useResponses = () => {
  const { activeLanguageCode, setResponseEditorActiveLanguage } =
    useResponseEditorActiveLanguage();

  return {
    activeLanguageCode,
    activeModality: useSelector(selectActiveModality),
    activeResponse: useSelector(selectActiveResponse),
    setResponseEditorActiveLanguage,
  };
};
