import styled from "styled-components";

import { c, v } from "constants/css";

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-weight: ${v.heavyFontWeight};
  font-size: ${v.xLargeFontSize};
`;

export const Description = styled.p`
  color: ${c.colorGrey4};
  font-size: ${v.smallFontSize};
  margin-top: ${v.gUnit};
  margin-bottom: ${v.gUnitTriple};
`;

export const Note = styled.p`
  color: ${c.colorGrey4};
  font-size: ${v.xSmallFontSize};
  margin-top: ${v.gUnit};
  margin-bottom: ${v.gUnit};
`;

export const ModalImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100px;
  margin: 20px;
`;
