import type Immutable from "immutable";
import { createSelector } from "reselect";

import { type MessageRecord } from "reducers/responses/messageRecords";
import { type LanguageToMessagesMap } from "reducers/responsesLoaded/types";
import { type Language } from "resourceModels/Language";
import { selectClientLanguageCode } from "services/clientLegacy/selectors/selectClientLanguageCode";
import { selectClientTranslatedLanguageCodes } from "services/clientLegacy/selectors/selectClientTranslatedLanguageCodes";
import { selectLanguages } from "services/language/selectors/selectLanguages";
import { selectResponseMessagesLanguages } from "services/responses/selectors/selectResponseMessagesLanguages";

export interface MessageProps {
  message?: MessageRecord;
  messages?: LanguageToMessagesMap;
}

export const selectEnabledLanguagesForActiveMessages = createSelector(
  [
    selectLanguages,
    selectClientLanguageCode,
    selectClientTranslatedLanguageCodes,
    selectResponseMessagesLanguages,
  ],
  (languages, clientLanguage, translatedLanguages, messagesLanguages) =>
    languages
      .filter(
        (language) =>
          // Filter out primary language
          language.code !== clientLanguage &&
          // Make sure the response is translated in this language (from settings)
          translatedLanguages.includes(language.code) &&
          // Check for language key in messages
          messagesLanguages.includes(language.code),
      )
      .sort((langA: Language, langB: Language) =>
        langA.englishName.localeCompare(langB.englishName),
      ),
);

export const selectAvailableLanguagesForActiveMessages = createSelector(
  [
    selectLanguages,
    selectClientLanguageCode,
    selectClientTranslatedLanguageCodes,
    selectEnabledLanguagesForActiveMessages,
  ],
  (languages, clientLanguage, translatedLanguages, enabledLanguages) =>
    languages.filter(
      (language) =>
        language.code !== clientLanguage &&
        !enabledLanguages.includes(language) &&
        translatedLanguages.includes(language.code),
    ),
);

export const getClientTranslatedLanguages = createSelector(
  selectLanguages,
  selectClientTranslatedLanguageCodes,
  (languages, languageCodes) =>
    languageCodes
      .map((code) => languages.find((l) => l.code === code))
      .filter((language) => Boolean(language)) as Immutable.List<Language>,
);
