import React, { type PropsWithChildren, type ReactNode } from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";

import * as S from "./styles";

type Props = PropsWithChildren<{
  label: ReactNode;
  inline?: boolean;
  stretch?: boolean;
  tooltip?: {
    blurb?: string;
    tooltipImgPath?: string;
    iconDefault?: string;
  };
  classNames?: string;
}>;

/**
 * Adds a label to an input
 *
 * @example
 * <InputLabel label="Bot name">
 *   <InputText value={value} />
 * </InputLabel>
 */
export function InputLabel({
  label,
  inline = false,
  stretch = false,
  tooltip = {},
  children,
  classNames,
}: Props) {
  const hasTooltip = Boolean(tooltip.blurb) || Boolean(tooltip.tooltipImgPath);

  return (
    <S.Root inline={inline} stretch={stretch} className={classNames}>
      <S.Label hasTooltip={hasTooltip}>
        {label}
        {hasTooltip && (
          <InfoTooltip
            iconDefault={tooltip.iconDefault || "QuestionCircle"}
            openOnHover
            above
            {...tooltip}
          />
        )}
      </S.Label>
      {children}
    </S.Root>
  );
}
