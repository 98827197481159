import { primitives } from "@adasupport/byron";
import React from "react";

import * as S from "./styles";

export const GuidanceVideoModal = () => (
  <S.Container>
    <S.Video src="https://fast.wistia.net/embed/iframe/k9souf14j6?seo=true&videoFoam=true" />
  </S.Container>
);

export const KnowledgeVideoModal = () => (
  <S.Container>
    <S.Video src="https://fast.wistia.net/embed/iframe/dav2irb2t3?seo=true&videoFoam=true" />
  </S.Container>
);

export const ActionVideoModal = () => (
  <S.Container>
    <S.Video src="https://fast.wistia.net/embed/iframe/rupkr7jcwi?seo=true&videoFoam=true" />
  </S.Container>
);

export const ProcessVideoModal = () => (
  <S.Container>
    <S.Video src="https://fast.wistia.net/embed/iframe/c48cvkjgvb?seo=true&videoFoam=true" />
  </S.Container>
);

GuidanceVideoModal.isLarge = true;
GuidanceVideoModal.isVideo = true;
GuidanceVideoModal.closeColor = primitives.palette.white;
KnowledgeVideoModal.isLarge = true;
KnowledgeVideoModal.isVideo = true;
KnowledgeVideoModal.closeColor = primitives.palette.white;
ActionVideoModal.isLarge = true;
ActionVideoModal.isVideo = true;
ActionVideoModal.closeColor = primitives.palette.white;
ProcessVideoModal.isLarge = true;
ProcessVideoModal.isVideo = true;
ProcessVideoModal.closeColor = primitives.palette.white;
