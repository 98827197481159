import { keyConverter } from "services/key-converter";

import {
  type fetchClientFailureAction,
  type fetchClientSuccessAction,
  type resetClientAction,
  type saveClientRequestAction,
  type saveClientSuccessAction,
  type updateClientAction,
} from "./actions";
import { type Client } from "./types";

type ClientAction =
  | ReturnType<typeof fetchClientSuccessAction>
  | ReturnType<typeof resetClientAction>
  | ReturnType<typeof saveClientRequestAction>
  | ReturnType<typeof saveClientSuccessAction>
  | ReturnType<typeof updateClientAction>
  | ReturnType<typeof fetchClientFailureAction>;

interface ClientState {
  resource: Client | null;
  changedAttributes: Partial<Client>;
  isBeingSaved?: boolean;
  fetchClientFailed?: boolean;
}

export const initialState: ClientState = {
  resource: null,
  changedAttributes: {},
  isBeingSaved: false,
  fetchClientFailed: false,
};

export function clientReducer(
  state = initialState,
  action: ClientAction,
): ClientState {
  switch (action.type) {
    case "FETCH_CLIENT_SUCCESS": {
      return {
        ...state,
        resource: {
          ...action.client,

          // TODO: Remove me when all clients have correct code for Indonesian
          // some clients have an incorrect code for Indonesian. _id instead of id
          // this will convert _id to id and prevent the UI from crashing
          // when the client selects the indonesian language in bot UI settings
          uiSettings: keyConverter(
            action.client.uiSettings,
            "underscore",
            false,
            false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any,
        },
        changedAttributes: {},
        fetchClientFailed: false,
      };
    }

    case "FETCH_CLIENT_FAILURE": {
      return {
        ...state,
        fetchClientFailed: true,
      };
    }

    case "SAVE_CLIENT_REQUEST": {
      return {
        ...state,
        isBeingSaved: true,
      };
    }

    case "UPDATE_CLIENT": {
      return {
        ...state,
        changedAttributes: state.resource
          ? {
              ...state.changedAttributes,
              ...action.attributes,
            }
          : {},
      };
    }

    case "SAVE_CLIENT_SUCCESS": {
      return {
        ...state,
        resource: state.resource && {
          ...state.resource,
          ...state.changedAttributes,
        },
        changedAttributes: {},
        isBeingSaved: false,
        fetchClientFailed: false,
      };
    }

    case "RESET_CLIENT": {
      return { ...state, changedAttributes: {} };
    }

    default:
      /* istanbul ignore next */
      return state;
  }
}
