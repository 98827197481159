import classnames from "classnames";
import React, { type ReactElement } from "react";

import "./style.scss";

const blockName = "SvgImage";

interface SvgImageProps {
  imageHash: { id: string };
  height?: number | null;
  width?: number | null;
  customClassName?: string;
}

export function SvgImage({
  imageHash,
  width = null,
  height = null,
  customClassName = "",
}: SvgImageProps): ReactElement {
  const svgProps: { width?: number; height?: number; viewBox?: string } = {};

  if (width !== null) {
    svgProps.width = width;
  }

  if (height !== null) {
    svgProps.height = height;
  }

  if (width !== null && height !== null) {
    svgProps.viewBox = `0 0 ${width} ${height}`;
  }

  return (
    <svg
      className={classnames(blockName, {
        [customClassName]: customClassName,
      })}
      {...svgProps}
    >
      <use href={`#${imageHash.id}`} />
    </svg>
  );
}

export default SvgImage;
