import classNames from "classnames";
import React from "react";

import SvgIcon from "components/Common/SvgIcon";
import { VALID_PASSWORD_LENGTH } from "constants/passwordGuidelines";
import {
  validatePasswordAlphaNum,
  validatePasswordSpecialChar,
} from "services/validations";
import colors from "stylesheets/utilities/colors.scss";
import "./style.scss";

const renderValidationIcon = (validation: boolean) => {
  const icon = validation ? "CircleCheckmark" : "Circle";
  const fillColor = validation ? colors.colorUIGood : colors.colorGrey2;

  return <SvgIcon height={16} icon={icon} fillColor={fillColor} />;
};

interface PasswordGuidelinesProps {
  password: string;
  minPasswordLength?: number;
  passwordConfirm?: string | undefined;
  oldPassword?: string;
  customClassName?: string;
}

export function PasswordGuidelines({
  oldPassword = "",
  password,
  customClassName = "",
  minPasswordLength = VALID_PASSWORD_LENGTH,
  passwordConfirm,
}: PasswordGuidelinesProps) {
  const validatePasswordAlphaNumeric = validatePasswordAlphaNum(password);
  const validatePasswordSpecialCharacter =
    validatePasswordSpecialChar(password);

  const passwordClassNames = classNames("PasswordGuidelines", {
    [customClassName]: customClassName,
  });

  return (
    <section className={passwordClassNames}>
      <ul className="PasswordGuidelines__list">
        <li className="PasswordGuidelines__item">
          {renderValidationIcon(password.length >= minPasswordLength)}
          Minimum of {minPasswordLength} characters
        </li>
        <li className="PasswordGuidelines__item">
          {renderValidationIcon(validatePasswordAlphaNumeric)}
          One uppercase letter or number
        </li>
        <li className="PasswordGuidelines__item">
          {renderValidationIcon(validatePasswordSpecialCharacter)}
          One symbol
        </li>
        {oldPassword && (
          <li className="PasswordGuidelines__item">
            {renderValidationIcon(password !== oldPassword)}
            Doesn&#39;t match your current password
          </li>
        )}
        {passwordConfirm && (
          <li className="PasswordGuidelines__item">
            {renderValidationIcon(
              passwordConfirm.length > 0 && password === passwordConfirm,
            )}
            Both passwords are matching
          </li>
        )}
      </ul>
    </section>
  );
}
