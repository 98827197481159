import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createAlert as createAlertAction } from "actions/alerts";
import {
  fetchClientApiKeysAction,
  generateNewApiKeyAction,
} from "actions/clientApiKeys";
import { closeModalAction, openModalAction } from "actions/modal";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { InputText } from "components/Common/InputText";
import { Loading } from "components/Common/Loading";
import SvgIcon from "components/Common/SvgIcon";
import { c } from "constants/css";
import { Features } from "resourceModels/Client";
import { AdaDateTime } from "services/AdaDateTime";
import { getAPIRoot } from "services/api-root";
import { useClientLegacy } from "services/clientLegacy";
import { pluralize } from "services/pluralize";

import * as S from "./styles";

export function SettingsPlatformsDataApiModal() {
  const dispatch = useDispatch();
  const { loading, loaded, error, clientApiKeys, apiKeyNew } = useSelector(
    (state) => state.clientApiKeysState,
  );
  const { client } = useClientLegacy();

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchClientApiKeysAction());
    }
  }, [dispatch, loaded]);

  const handleGenerateNewApiKey = () => {
    dispatch(generateNewApiKeyAction());
  };

  const handleCopyClicked = (textToCopy: string) => {
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      dispatch(
        createAlertAction({
          message: "Copied to clipboard!",
          alertType: "success",
        }),
      );
    }
  };

  const showGenerateNewApiKeyWarningModal = () => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Confirm Token Generation",
        message: (
          <>
            Generating a new Access Token will{" "}
            <S.BoldText>immediately invalidate</S.BoldText> your previous token.
            Your API integrations will stop working until they’re updated to use
            the new token.
          </>
        ),
        actions: [
          {
            title: "I understand, generate a new token",
            buttonTint: "primary",
            onClick: () => {
              dispatch(
                openModalAction("SETTINGS_PLATFORMS_DATA_API_MODAL", {}),
              );
              handleGenerateNewApiKey();
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };

  const renderLoadingState = () => (
    <S.Centered>
      <Loading />
    </S.Centered>
  );

  const renderErrorState = () => (
    <S.WarningMessage>
      <SvgIcon icon="Error" height={20} fillColor={c.colorUiBad} />
      <S.WarningMessageText>Something went wrong.</S.WarningMessageText>
    </S.WarningMessage>
  );

  const renderEmptyState = () => (
    <>
      <S.Title>API Access Token</S.Title>
      <S.Description>
        You haven&#39;t setup any data API access token yet.
      </S.Description>
      <S.ButtonContainer>
        <Button
          light
          text="Generate a new Access Token"
          onClick={handleGenerateNewApiKey}
        />
      </S.ButtonContainer>
    </>
  );

  const renderWarningMessage = () => (
    <S.WarningMessage>
      <SvgIcon icon="Error" height={20} fillColor={c.colorUiBad} />
      <S.WarningMessageText>
        <S.BoldText>This Access Token will be shown only once.</S.BoldText> You
        need to copy it before you refresh the page.
      </S.WarningMessageText>
    </S.WarningMessage>
  );

  const renderExpirationMessage = () => {
    const now = new Date();
    const expiryDate = clientApiKeys.get(0)?.expiresOn as number;
    const remainingDays = AdaDateTime.diff(
      AdaDateTime.secondsToMilliseconds(expiryDate),
      now,
      "DAYS",
    );
    const showTrial =
      client.hasFeature(Features.DATA_API_TRIAL) &&
      expiryDate &&
      remainingDays >= 0;

    if (!expiryDate) {
      return null;
    }

    const validTrial = AdaDateTime.isInTheFuture(
      AdaDateTime.secondsToMilliseconds(expiryDate),
    );

    return (
      <Banner icon="Flash">
        {showTrial && (
          <>
            <S.BoldText>
              {remainingDays} {pluralize(remainingDays, "day")}{" "}
            </S.BoldText>
            left in trial.{" "}
          </>
        )}
        Your Data Export API trial token {validTrial ? "expires" : "expired"} at{" "}
        <S.BoldText>
          {AdaDateTime.format(
            AdaDateTime.secondsToMilliseconds(expiryDate),
            AdaDateTime.TIME_FORMATS.HOUR_MINUTE_AMPM,
          )}
        </S.BoldText>{" "}
        on{" "}
        <S.BoldText>
          {AdaDateTime.format(
            AdaDateTime.secondsToMilliseconds(expiryDate),
            AdaDateTime.DATE_FORMATS.NAMED_MONTH_DAY_YEAR,
          )}
        </S.BoldText>
        .{validTrial || " Please reach out to your Ada team for assistance."}
      </Banner>
    );
  };

  const dataApiUrl = `${getAPIRoot().replace("/api", "")}/data_api/v1.3/`;

  const renderLatestApiKeyInfo = () => {
    const validApiKey = clientApiKeys.find(({ isRevoked }) => !isRevoked);

    return (
      <>
        {renderExpirationMessage()}
        <S.Description>
          View the{" "}
          <a
            href="https://developers.ada.cx/reference/overview-data-export"
            target="_blank"
            rel="noopener noreferrer"
          >
            developer documentation
          </a>{" "}
          to learn more about the Data Export API.
        </S.Description>
        <S.Title>Data API Base URL</S.Title>
        <S.InputContainer>
          <InputText multiline value={dataApiUrl} />
          <S.CopyButtonContainer>
            <Button
              light
              text="Copy"
              onClick={() => handleCopyClicked(dataApiUrl)}
            />
          </S.CopyButtonContainer>
        </S.InputContainer>
        <S.Title>API Access Token</S.Title>
        <S.InputContainer>
          <InputText
            multiline
            value={
              apiKeyNew ||
              `${
                validApiKey?.prefix ?? ""
              }••••••••••••••••••••••••••••••••••••••`
            }
            className="ph-no-capture"
            disabled
          />
          {apiKeyNew && (
            <S.CopyButtonContainer>
              <Button
                light
                text="Copy"
                onClick={() => handleCopyClicked(apiKeyNew)}
              />
            </S.CopyButtonContainer>
          )}
        </S.InputContainer>

        {apiKeyNew ? (
          renderWarningMessage()
        ) : (
          <>
            <S.ExtraInfo>
              This Access Token was generated at{" "}
              <S.BoldText>
                {AdaDateTime.format(
                  AdaDateTime.secondsToMilliseconds(
                    clientApiKeys.get(0)!.dateCreated as number,
                  ),
                  AdaDateTime.TIME_FORMATS.HOUR_MINUTE_AMPM,
                )}
              </S.BoldText>{" "}
              on{" "}
              <S.BoldText>
                {AdaDateTime.format(
                  AdaDateTime.secondsToMilliseconds(
                    clientApiKeys.get(0)!.dateCreated as number,
                  ),
                  AdaDateTime.DATE_FORMATS.NAMED_MONTH_DAY_YEAR,
                )}
              </S.BoldText>
              .
            </S.ExtraInfo>
            <S.ButtonContainer>
              <Button
                light
                text="Generate a new Access Token"
                onClick={showGenerateNewApiKeyWarningModal}
              />
            </S.ButtonContainer>
          </>
        )}
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return renderLoadingState();
    }

    if (error) {
      return renderErrorState();
    }

    if (
      !apiKeyNew &&
      (clientApiKeys.size === 0 ||
        clientApiKeys.every(({ isRevoked }) => isRevoked))
    ) {
      return renderEmptyState();
    }

    return renderLatestApiKeyInfo();
  };

  return (
    <S.Root>
      <h5 className="Modal__title">Data Export API</h5>
      <S.Container>{renderContent()}</S.Container>
    </S.Root>
  );
}
