/**
 * @deprecated Use `features` attribute of `Client` instead.
 */
export enum Features {
  ADA_GLASS_NUANCE = "adaGlassNuance",
  ADA_GLASS_SALESFORCE = "adaGlassSalesforce",
  ADA_GLASS_ZENDESK = "adaGlassZendesk",
  AFM_HANDOFF_EVENTS = "afmHandoffEvents",
  AFM_PROACTIVE_MESSAGING = "afmProactiveMessaging",
  CONDITIONALS = "conditionals",
  COUNT_HTTP_REQUEST_AS_HANDOFF = "countHttpRequestAsHandoff",
  CSAT_PRO_POST_CHAT = "csatProPostChat",
  CSAT_V2 = "csatV2",
  DATA_API_TRIAL = "dataApiTrial",
  DND_V2 = "dndV2",
  EXPERIMENT_VOICE = "experimentVoice",
  FORCED_QUICK_REPLIES = "forcedQuickReplies",
  ML_CONVERSATIONS_TOPICS_TEMP = "conversationsTopics",
  RAW_JSON_MODAL = "rawJsonModal",
  SSO_DASHBOARD = "ssoDashboard",
  SUNCO_MULTI_BOT_ROUTING = "suncoMultiBotRouting",
  TWILIO_DIRECT_SMS = "twilioDirectSms",
  VARIABLE_CHANGE_META_MESSAGE_TYPE = "variableChangeMetaMessages",
  WEBHOOK_CHANNEL_API = "webhookChannelApi",
  ZENDESK_CHATTER_AUTH = "adaGlassZendeskChatterAuth",
  ZENDESK_DEPARTMENT_MATCHING = "zendeskDepartmentMatching",
  BU_GENERATIVE_ANSWERS_TEMP = "buGenerativeAnswersTemp",
  CHANNEL_SMOOCH = "channelSmooch",
}
