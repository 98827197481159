import Immutable from "immutable";

import RecordClassCreator from "services/record";
import { type TypedMap } from "types";

interface Attributes {
  type: "responseSideBarItem";
  content: null;
  description: null;
  handle: null;
  responseId: null;
  blocks: Immutable.Map<string, Immutable.List<unknown>> | null;
  reserved: false;
  messages: Immutable.Map<string, unknown>;

  source: TypedMap<{
    buttonLabel: Immutable.Map<string, unknown>;
    created: null;
    description: "";
    descriptiveString: "";
    handle: "";
    hasTraining: false;
    hasQuickReply: false;
    live: false;
    messages: Immutable.Map<string, unknown>;
    tags: Immutable.List<unknown>;
    reserved: false;
    reservedResponseType: null;
    reviewable: false;
    updated: null;
  }>;
}

export class ResponseSideBarItem extends RecordClassCreator<Attributes>({
  type: "responseSideBarItem",
  content: null,
  description: null,
  handle: null,
  responseId: null,
  blocks: null,
  reserved: false,
  messages: Immutable.Map(),

  source: Immutable.Map({
    buttonLabel: Immutable.Map(),
    created: null,
    description: "",
    descriptiveString: "",
    handle: "",
    hasTraining: false,
    hasQuickReply: false,
    live: false,
    messages: Immutable.Map(),
    tags: Immutable.List(),
    reserved: false,
    reservedResponseType: null,
    reviewable: false,
    updated: null,
  }),
}) {
  get isValid() {
    return Boolean(this.handle);
  }

  get languages() {
    return Immutable.List(this.messages.keys());
  }

  static getFetchManyUrl() {
    return "/responses/filter";
  }

  static getFetchManyUrlParams(params: {
    filter: { userinput: string };
    page: number;
    sort: Record<string, unknown>;
  }) {
    const { userinput } = params.filter;

    return {
      userinput,
      page: params.page,
      ...params.sort,
    };
  }

  static getFetchOneUrl(id: string) {
    return `/responses/${id}`;
  }

  static resolveFetchOneResult(payload: { response: { _type: string } }) {
    return {
      ...payload,
      data: {
        ...payload.response,
        reservedResponseType: payload.response._type,
      },
    };
  }

  static resolveFetchManyResult(payload: { responses: { _type: string }[] }) {
    return {
      ...payload,
      data: payload.responses.map((response) => ({
        ...response,
        reservedResponseType: response._type,
      })),
    };
  }
}
