import React from "react";

import { RadioButton } from "components/Common/RadioButton";

import * as S from "./styles";

interface RadioButtonRowProps {
  id: string;
  selected: string;
  option: string;
  setOption: (option: string) => void;
  label: string;
  description: string;
  children: React.ReactElement;
  disabled: boolean;
}

export const RadioButtonRow = ({
  id,
  selected,
  option,
  setOption,
  label,
  description,
  children,
  disabled,
}: RadioButtonRowProps): React.ReactElement => (
  <S.RadioButtonRow>
    <RadioButton
      id={id}
      checked={selected === option}
      onClick={() => setOption(option)}
      label={label}
      disabled={disabled}
    />
    <S.RadioDescription>{description}</S.RadioDescription>
    {selected === option && children}
  </S.RadioButtonRow>
);
