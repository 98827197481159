import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import { closeModalAction } from "actions/modal";
import { ModalActionButton } from "components/Common/ModalActionButton";

import { SimpleReferenceList } from "./SimpleReferenceList";
import * as S from "./styles";
import "./style.scss";

const blockName = "ModalWarning";

export class ModalWarningComponent extends Component {
  constructor(props) {
    super(props);

    this.buttonsTypes = {
      cancel: {
        text: "Cancel",
        intent: undefined,
      },
      delete_training: {
        text: "Delete",
        intent: "alert",
      },
    };
  }

  /**
   * Handle button click action
   * @param {String} type Button type
   */
  handleButtonClick(type) {
    const { closeModal, resolve } = this.props;

    switch (type) {
      case "delete_training":
        {
          const {
            deleteTraining,
            expression,
            expressionPairIndex,
            expressionIndex,
          } = this.props;
          deleteTraining(expression, expressionPairIndex, expressionIndex);
        }

        break;

      default:
        break;
    }

    closeModal();

    if (typeof resolve === "function") {
      resolve(type);
    }
  }

  /**
   *
   * @returns {HTMLElement}
   */
  renderScheduleReferences() {
    const { responseScheduleReferences } = this.props;

    return <SimpleReferenceList references={responseScheduleReferences} />;
  }

  /**
   * Render the WarningModal action buttons
   */
  renderActionButtons() {
    const { buttons, actions } = this.props;

    if (buttons) {
      return buttons.map((button) => (
        <ModalActionButton
          key={button}
          onClick={() => this.handleButtonClick(button)}
          text={this.buttonsTypes[button].text}
          intent={this.buttonsTypes[button].intent}
        />
      ));
    }

    if (actions) {
      return actions.map((action) => (
        <ModalActionButton
          key={action.title}
          onClick={action.onClick}
          text={action.title}
          intent={action.buttonTint}
        />
      ));
    }

    return null;
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const {
      subTitle,
      responseScheduleReferences,
      renderCustomContent,
      title,
      message,
    } = this.props;

    return (
      <div className="Modal__modal Modal__modal--small">
        <label className="Modal__title Modal__title--small">{title}</label>
        <section className="ModalWarning__section">
          {Boolean(subTitle) && (
            <h1 className={`${blockName}__sub-title`}>{subTitle}</h1>
          )}

          <S.Message className="ModalWarning__message">{message}</S.Message>
        </section>
        {responseScheduleReferences && this.renderScheduleReferences()}
        {renderCustomContent && renderCustomContent()}
        {this.renderActionButtons()}
      </div>
    );
  }
}

ModalWarningComponent.isSmall = true;

// eslint-disable-next-line react/static-property-placement
ModalWarningComponent.propTypes = {
  /** Title of the ModalWarning */
  title: PropTypes.string.isRequired,
  /** Subtitle of ModalWarning */
  message: PropTypes.string.isRequired,
  /** Buttons to be rendered at bottom of ModalWarning */
  buttons: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.set]),
  /** Actions will be rendered as buttons at the bottom of the modal */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      buttonTint: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  deleteTraining: PropTypes.func,
  expression: ImmutablePropTypes.map,
  expressionPairIndex: PropTypes.number,
  expressionIndex: PropTypes.number,
  renderCustomContent: PropTypes.func,
  subTitle: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  responseScheduleReferences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      handle: PropTypes.string,
    }),
  ),
  resolve: PropTypes.func,
};

// eslint-disable-next-line react/static-property-placement
ModalWarningComponent.defaultProps = {
  actions: [],
  deleteTraining: null,
  expression: null,
  expressionIndex: null,
  expressionPairIndex: null,
};

function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      closeModal: closeModalAction,
    },
    dispatch,
  );
}

export const ModalWarning = connect(null, mapDispatch)(ModalWarningComponent);
