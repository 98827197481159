import React from "react";

import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";
import { cssColors } from "constants/css";
import { NO_OP_FUNCTION } from "services/helpers";

import * as S from "./styles";

interface Props {
  icon: string;
  platformType: string;
  startSelfSetup?: () => void;
  isAvailable: boolean;
  addPlatform?: () => void; // upgrade button click
}

const ChannelSelectionCard = (props: Props) => {
  const {
    icon,
    platformType,
    isAvailable,
    startSelfSetup = NO_OP_FUNCTION,
    addPlatform = NO_OP_FUNCTION,
  } = props;

  return (
    <S.Root>
      <S.IconWithText>
        <SvgIcon icon={icon} height={48} />
        <span>{platformType}</span>
      </S.IconWithText>
      <S.CardAction>
        {isAvailable && (
          <Button
            clear
            icon="ChevronRight"
            fillColor={cssColors.colorGrey3}
            size="x-small"
            iconHeightOverride={24}
            onClick={startSelfSetup}
          />
        )}
        {!isAvailable && (
          <Button
            light
            icon="Update"
            fillColor={cssColors.colorWhite}
            text="Upgrade"
            customClassName="Button--dark"
            iconHeightOverride={24}
            onClick={addPlatform}
          />
        )}
      </S.CardAction>
    </S.Root>
  );
};

export default ChannelSelectionCard;
