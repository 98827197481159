import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import SvgIcon from "components/Common/SvgIcon";
import "./style.scss";

export class InputClearable extends React.Component {
  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      this.input.focus();
    }
  }

  renderRightSideIcon() {
    const { value, onClear } = this.props;

    if (value && onClear) {
      return (
        // Not going to have a keyboard handler
        <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
          role="button"
          className="InputClearable__icon-container"
          onClick={() => {
            onClear();
            this.input.focus();
          }}
        >
          <SvgIcon
            icon="Clear"
            customClassName="InputClearable__icon-container__icon"
            fillColor="currentColor"
          />
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      customClassName,
      invalid,
      idForLabel,
      value,
      placeholder,
      onChange,
      onFocus,
      onKeyPress,
      onBlur,
    } = this.props;

    return (
      <div className={`InputClearable ${customClassName}`}>
        <input
          className={classNames("InputClearable__input", "g-input", {
            "g-input--invalid": invalid,
          })}
          id={idForLabel}
          value={value || ""}
          type="text"
          placeholder={placeholder}
          onChange={(event) => onChange(event)}
          onFocus={(event) => onFocus && onFocus(event)}
          onKeyPress={(event) => onKeyPress && onKeyPress(event)}
          onBlur={(event) => onBlur && onBlur(event)}
          autoComplete="off"
          spellCheck={false}
          ref={(input) => {
            this.input = input;
          }}
          data-prevent-drag-focus={false}
        />
        {this.renderRightSideIcon()}
      </div>
    );
  }
}

// eslint-disable-next-line react/static-property-placement
InputClearable.propTypes = {
  /** CSS overrided */
  customClassName: PropTypes.string,
  /** Focus input on compondentDidMount */
  autoFocus: PropTypes.func,
  /** Event handler for clear button */
  onClear: PropTypes.func,
  /** Text value for input */
  value: PropTypes.string.isRequired,
  /** Shows invalid state for input if true */
  invalid: PropTypes.bool,
  /** ID for input */
  idForLabel: PropTypes.string,
  /** Placeholder option that cannot be selected */
  placeholder: PropTypes.string,
  /** Handle input onChange event */
  onChange: PropTypes.func.isRequired,
  /** onFocus event handler */
  onFocus: PropTypes.func,
  /** onKeyPress event handler */
  onKeyPress: PropTypes.func,
  /** onBlur event handler */
  onBlur: PropTypes.func,
};
