import Immutable from "immutable";

export class EmailPlatformAddressRecord extends Immutable.Record<{
  emailAddress: string;
  ownership: string;
  dnsRecordLogs: Immutable.List<unknown> | undefined;
  dnsVerified: boolean | undefined;
  hasReceived: boolean | undefined;
}>({
  emailAddress: "",
  ownership: "",
  dnsRecordLogs: Immutable.List(),
  dnsVerified: false,
  hasReceived: false,
}) {}
