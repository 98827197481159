export function isObjectId(subject: string): boolean {
  if (!subject) {
    return false;
  }

  const checkForObjectIdRegExp = /^[0-9a-fA-F]{24}$/;
  const checkForUUIDRegExp =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const matchesObjectId = subject.toLowerCase().match(checkForObjectIdRegExp);
  const matchesUUID = subject.match(checkForUUIDRegExp);

  if (matchesObjectId || matchesUUID) {
    return true;
  }

  return false;
}

export function mongoObjectId(): string {
  // eslint-disable-next-line no-bitwise
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);

  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      // eslint-disable-next-line no-bitwise
      .replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16))
      .toLowerCase()
  );
}
