import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import { GetConversationTopics } from "components/Common/FilterDropdown/hooks";
import { INTERACTION_TYPES } from "components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/services";
import { Features } from "resourceModels/Client";
import { visibleBlocksSelector } from "selectors/blocks";
import { clientSelector } from "selectors/client";
import { getResource, getResources } from "selectors/resources";
import { BROWSERS, CHANNELS, DEVICES } from "services/browser";
import { SelectAllWebActions } from "services/webActions/api";
import { SelectAllCoachings } from "slices/coaching/coachingApi";
import { SelectAllGeneratedTopics } from "slices/generatedTopics/generatedTopicsApi";
import { SelectAllGeneratedTopics as SelectAllGeneratedTopicsV2 } from "slices/generatedTopics/generatedTopicsV2Api";
import { type State } from "types";

import {
  type FilterType,
  OVERVIEW_URL,
  type ReportTypes,
  type SelectOption,
} from "./constants";

export function constructFilterOptionsFromStore(
  state: State,
  filterType: FilterType,
): SelectOption[] {
  const client = clientSelector(state);
  const languages = getResources(state, "language");
  const { responses } = state;
  const csatSettings = getResource(state, "csatSettings");
  const agents = getResources(state, "agent");
  const blocks = visibleBlocksSelector(state);
  const topics = GetConversationTopics();
  const generatedTopics = SelectAllGeneratedTopics(state);
  const generatedTopicsV2 = SelectAllGeneratedTopicsV2(state);
  const coachings = SelectAllCoachings(state);
  const webActions = SelectAllWebActions(state);

  const platforms = state.platforms
    .map((platform) => platform.get("record"))
    .toList();

  const channelsToShow = client?.hasFeature(Features.EXPERIMENT_VOICE)
    ? [
        ...CHANNELS,
        {
          id: "voice",
          name: "Voice",
        },
      ]
    : CHANNELS;

  if (
    platforms
      .toJS()
      .some((platform) => platform.name === "email" && platform.enabled)
  ) {
    if (!channelsToShow.some((channel) => channel.id === "email")) {
      channelsToShow.push({ id: "email", name: "Email" });
    }
  }

  switch (filterType) {
    case "answerId":
      return responses.toArray().map((response) => ({
        value: response.id,
        label: response.handle,
      }));
    case "language":
      return languages && client
        ? languages
            .toJS()
            .filter(
              (language) =>
                client.language === language.code ||
                (client.translatedLanguages &&
                  client.translatedLanguages.includes(language.code)),
            )
            .map((language) => ({
              value: language.code,
              label: language.englishName,
            }))
        : [];
    case "browser":
      return BROWSERS.map((browser) => ({
        value: browser.id,
        label: browser.name,
      }));
    case "device":
      return DEVICES.map((device) => ({
        value: device.id,
        label: device.name,
      }));
    case "channel":
      return channelsToShow.map((channel) => ({
        value: channel.id,
        label: channel.name,
      }));
    case "platform":
      return platforms
        .toJS()
        .filter((platform) => platform.isVisibleToClient(client))
        .map((platform) => ({
          value: platform.name,
          label: platform.fullName,
        }));
    case "interactionType":
      return INTERACTION_TYPES.map((channel) => ({
        value: channel.id,
        label: channel.name,
      }));
    case "feedback":
      if (!csatSettings) {
        return [];
      }

      return csatSettings.followUpPositiveFeedbackOptions
        .toJS()
        .map((feedback) => ({
          value: feedback.id,
          label: feedback.label.en,
          groupName: "Positive",
        }))
        .concat(
          csatSettings.followUpNegativeFeedbackOptions
            .toJS()
            .map((feedback) => ({
              value: feedback.id,
              label: feedback.label.en,
              groupName: "Negative",
            })),
        );
    case "agentIds":
      if (!agents) {
        return [];
      }

      return agents.toJS().map((agent) => ({
        value: agent.id,
        label: agent.name,
      }));
    case "blockType":
      return blocks.map((block) => ({
        value: block.messageType,
        label: block.blockName,
      }));
    case "csatscore":
      return [
        {
          value: "ISPOSITIVE",
          label: "Positive",
        },
        {
          value: "ISNEGATIVE",
          label: "Negative",
        },
        ...[1, 2, 3, 4, 5].map((v) => ({
          value: v.toString(),
          label: `${v}/5`,
        })),
      ];
    case "conversationTopics":
      return topics.map((t) => ({
        value: t.value,
        label: t.label,
      }));

    case "generatedTopics":
      return generatedTopics.map((t) => ({
        value: t._id,
        label: t.topic_label,
      }));
    case "generatedTopicsV2":
      return generatedTopicsV2.map((t) => ({
        value: t._id,
        label: t.title,
      }));
    case "arStatus":
      return [
        { label: "Resolved", value: "Resolved" },
        { label: "Not Resolved", value: "Not Resolved" },
        { label: "Not in Sample", value: "Unclassified" },
      ];
    case "coachingApplied":
      return coachings.map((c) => ({
        value: c.id,
        label: c.label.length > 50 ? `${c.label.slice(0, 47)}...` : c.label,
      }));
    case "actionId":
      return webActions.map((w) => ({
        value: w._id,
        label: w.name.length > 50 ? `${w.name.slice(0, 47)}...` : w.name,
      }));
    case "statusCode":
      return [
        { label: "1xx", value: "100" },
        { label: "2xx", value: "200" },
        { label: "3xx", value: "300" },
        { label: "4xx", value: "400" },
        { label: "5xx", value: "500" },
      ];
    case "articleId":
      return []; // Articles are fetched in source/components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/AnalyticsReportFilter/index.tsx
    // and stored in state due to the large number of articles
    default:
  }

  return [];
}

/**
 * Build a dict contains all possible options for any array filter to handle IS_NOT operator
 *
 * NOTE: Filters that do not support IS_NOT operator will not be included in this function
 */
export function constructAllOptionsDict(state: State): {
  [key: string]: SelectOption[];
} {
  // NOTE: All keys belong to type: FilterType
  return {
    browser: constructFilterOptionsFromStore(state, "browser"),
    channel: constructFilterOptionsFromStore(state, "channel"),
    device: constructFilterOptionsFromStore(state, "device"),
    interactionType: constructFilterOptionsFromStore(state, "interactionType"),
    language: constructFilterOptionsFromStore(state, "language"),
    platform: constructFilterOptionsFromStore(state, "platform"),
    feedback: constructFilterOptionsFromStore(state, "feedback"),
    agentIds: constructFilterOptionsFromStore(state, "agentIds"),
    blockType: constructFilterOptionsFromStore(state, "blockType"),
  };
}

function useFilterOptions(filterType: FilterType) {
  return useSelector((state) =>
    constructFilterOptionsFromStore(state, filterType),
  );
}

export function useFilterOptionsDict(): { [key: string]: SelectOption[] } {
  const flags = useFlags();

  // NOTE: All keys belong to type: FilterType
  return {
    answerId: useFilterOptions("answerId"),
    browser: useFilterOptions("browser"),
    channel: useFilterOptions("channel"),
    device: useFilterOptions("device"),
    interactionType: useFilterOptions("interactionType"),
    language: useFilterOptions("language"),
    platform: useFilterOptions("platform"),
    feedback: useFilterOptions("feedback"),
    agentIds: useFilterOptions("agentIds"),
    blockType: useFilterOptions("blockType"),
    engaged: useFilterOptions("engaged"),
    handoff: useFilterOptions("handoff"),
    csatscore: useFilterOptions("csatscore"),
    conversationTopics: useFilterOptions("conversationTopics"),
    generatedTopics: useFilterOptions(
      flags["bg-gen-topics-v2"] ? "generatedTopicsV2" : "generatedTopics",
    ),
    arStatus: useFilterOptions("arStatus"),
    coachingApplied: useFilterOptions("coachingApplied"),
    actionId: useFilterOptions("actionId"),
    statusCode: useFilterOptions("statusCode"),
    articleId: useFilterOptions("articleId"),
  };
}

export function useAllowedFilters(reportType?: ReportTypes): FilterType[] {
  const match: { params: { id: string }; url: string } = useRouteMatch();

  return useSelector((state) => {
    let resource;

    if (match.url === OVERVIEW_URL) {
      resource = getResource(state, "analyticsOverview");
    } else {
      const params = reportType ?? match.params.id;
      resource = getResource(state, "analyticsReport", params);
    }

    // From api report config, we specify what type of filter we want for this report
    // NOTE that analyticsReport should be always available as it is fetched in parent component,
    // but here we need check type to keep TypeScript happy
    return resource ? resource.allowedFilters.toJS() : [];
  });
}
