// @ts-strict-ignore
import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import {
  type ActionIntegrationInputItemRecord,
  type ActionIntegrationOutputItemRecord,
} from "reducers/actionIntegrations/types";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "action_integration";
  actionIntegrationId: string | null;
  actionId: string | null;
  inputs: Immutable.List<ActionIntegrationInputItemRecord>;
  outputs: Immutable.List<ActionIntegrationOutputItemRecord>;
  errorResponseId: string | null;
  hasInvalidFields: boolean;
  hasInvalidMandatoryInputs: boolean;
  actionManifest: unknown;
  savedManifest: Immutable.Map<string, unknown>;
  isHandoff: boolean;
  shouldPause: boolean;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export class ActionIntegrationMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "action_integration",
    actionIntegrationId: null,
    actionId: null,
    inputs: Immutable.List(),
    outputs: Immutable.List(),
    errorResponseId: null,
    hasInvalidFields: false,
    hasInvalidMandatoryInputs: false,
    actionManifest: Immutable.Map(),
    savedManifest: Immutable.Map(),
    isHandoff: false,
    shouldPause: false,
    successBusinessEvent: new FireableBusinessEvent(),
  },
) {
  static getInvalidFields = (
    message: ActionIntegrationMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (!message.errorResponseId) {
      invalidFields.add("invalidErrorResponseId");
    }

    if (message.hasInvalidFields) {
      invalidFields.add("invalidInputFields");
    }

    return Immutable.List(invalidFields);
  };
}
