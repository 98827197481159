import Immutable from "immutable";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";

import {
  MESSAGING_MODALITY,
  MODALITY_DETAILS,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { FolderRecord } from "reducers/folders/types/folderRecord";

export class FoldersStateRecord extends Immutable.Record({
  loading: false,
  rootFolders: Immutable.List(), // to construct tree skeleton
  foldersById: Immutable.Map(), // all tree nodes data
  rootFolderId: "",
  hasLoadedExpandedFolders: false,
  highlightedNodes: [],
  searchQuery: Immutable.Map({
    userinput: "",
    sortBy: "folders",
    sortDirection: "",
  }),
  numLoadedPages: 1,
  numSearchResults: 0,
  loadedSearchResults: Immutable.OrderedMap(),
  isSearching: false,
  hasMoreSearchResults: false,
  noResults: false,
}) {}

export function serializeFolderNode(treeNode) {
  return {
    folder_id: treeNode.id || null,
    title: treeNode.title,
    parent_id: treeNode.parentId,
  };
}

export function deserializeChildNode(node) {
  return {
    id: node._id,
    key: node._id,
    isLeaf: node.type !== "folder",
  };
}

export function deserializeFolderNode(treeNode) {
  // deserialize each of the treeNode's children
  const children = treeNode.children
    ? new Immutable.List(
        treeNode.children.map((child) => deserializeChildNode(child)),
      )
    : undefined;
  const isLeaf = treeNode.type !== "folder";
  const breadcrumbs = treeNode.breadcrumbs
    ? treeNode.breadcrumbs.map(
        (rawData) =>
          rawData.length > 0 &&
          rawData.map(({ title, _id: id }) => ({
            title,
            nodeId: id,
          })),
      )
    : [];

  return new FolderRecord({
    id: treeNode._id,
    key: treeNode._id, // key is required to render Tree
    value: treeNode._id, // value is required for TreeSelect
    breadcrumbs,
    parentId: treeNode.parent_id,
    updatedBy: treeNode.updated_by,
    createdBy: treeNode.created_by,
    title: treeNode.title || treeNode.handle,
    description: treeNode.description,
    created: moment.utc(treeNode.created * 1000).format("MMM DD, YYYY"),
    updated: moment.utc(treeNode.updated * 1000).format("MMM DD, YYYY"),
    children,
    didLoad: treeNode.didLoad, // indicates if this node was already fetched
    clientId: treeNode.client_id,
    isLeaf,
    live: treeNode.live,
    liveVoice: treeNode.live_voice,
    tags: Immutable.List(treeNode.tags),
    reserved: treeNode.reserved,
    isPendingLoad: treeNode.isPendingLoad,
    searchMatches: treeNode.searchMatches,
  });
}

export const updateResponseParentIdAndFoldersChildren = (
  state,
  responseId,
  newParentId,
) => {
  let nextState = state;
  const oldParentId = nextState.getIn(["foldersById", responseId, "parentId"]);

  // 1. Remove updated response from old parent's children
  if (oldParentId === nextState.rootFolderId) {
    nextState = nextState.update("rootFolders", (nodes) =>
      nodes.filter((nodeId) => nodeId !== responseId),
    );
  } else {
    nextState = nextState.updateIn(
      ["foldersById", oldParentId, "children"],
      (children) => children.filter((child) => child.id !== responseId),
    );
  }

  // 2. Add updated response to new parent's children
  if (newParentId === nextState.rootFolderId) {
    nextState = nextState.update("rootFolders", (folders) =>
      folders.insert(0, responseId),
    );
  } else {
    nextState = nextState.updateIn(
      ["foldersById", newParentId, "children"],
      (children) =>
        children.insert(0, {
          id: responseId,
          key: responseId,
          isLeaf: true,
        }),
    );
  }

  return nextState;
};

const addSearchResultsToTree = (state) => {
  // this helper function adds the search results to foldersById if the data
  // does not exist.
  // this is needed when we're moving a search result from an unloaded folder
  // into a loaded folder.
  // note that "foldersById" is actually responses AND folders by id
  const { loadedSearchResults } = state;

  return state.update("foldersById", (folders) =>
    folders.mergeWith((oldValue) => oldValue, loadedSearchResults),
  );
};

const updateFoldersStructure = (state, folders) => {
  let nextState = state;
  folders.forEach((folder) => {
    if (folder._id === state.rootFolderId) {
      nextState = nextState.set(
        "rootFolders",
        Immutable.List(folder.children.map((treeNode) => treeNode._id)),
      );
      folder.children.forEach((treeNode) => {
        if (nextState.hasIn(["foldersById", treeNode._id, "parentId"])) {
          nextState = nextState.setIn(
            ["foldersById", treeNode._id, "parentId"],
            folder._id,
          );
        }
      });
    } else if (folder.children) {
      const children = [];
      folder.children.forEach((treeNode) => {
        // update parentId on existing child nodes in the store
        if (nextState.hasIn(["foldersById", treeNode._id, "parentId"])) {
          nextState = nextState.setIn(
            ["foldersById", treeNode._id, "parentId"],
            folder._id,
          );
        }

        children.push(deserializeChildNode(treeNode)); // set children as per the new order
      });
      // push the new order for child nodes
      nextState = nextState.setIn(
        ["foldersById", folder._id, "children"],
        Immutable.List(children),
      );
    } else {
      // if folder is returned without children, update store with empty child nodes
      nextState = nextState.setIn(
        ["foldersById", folder._id, "children"],
        Immutable.List([]),
      );
    }
  });

  return nextState;
};

export const foldersState = (state = new FoldersStateRecord(), action) => {
  switch (action.type) {
    case "LOADING_SEARCH_RESULTS":
      return state.set("loading", true);

    case "SET_HAS_MORE_SEARCH_RESULTS": {
      return state.set("hasMoreSearchResults", action.value);
    }

    case "SET_NO_RESULTS": {
      return state.set("noResults", action.value);
    }

    case "LOADED_SEARCH_RESULTS":
      return state.merge({
        loading: false,
        numSearchResults: action.numSearchResults,
      });

    case "FETCH_ROOT_FOLDER_REQUEST":
      return state.merge({
        loading: true,
        hasLoadedExpandedFolders: false,
      });

    case "FETCH_ROOT_FOLDER_SUCCESS": {
      let nextState = state;
      const { root } = action;
      // add rootFolder Ids to state
      const folders = root.children.map((treeNode) => treeNode._id);
      const removedRootFolders = state
        .get("rootFolders")
        .filter((f) => !folders.includes(f));

      // add each root item to foldersById
      root.children.forEach((treeNode) => {
        nextState = nextState.updateIn(["foldersById", treeNode._id], (f) => {
          let node = deserializeFolderNode({
            ...treeNode,
            didLoad: f?.get("didLoad"),
            isPendingLoad: f?.get("isPendingLoad"),
          });

          // need to keep children when we're refreshing
          if (f?.get("children")) {
            node = node.set("children", f.get("children"));
          }

          return node;
        });
      });

      removedRootFolders.forEach((folderId) => {
        nextState = nextState.setIn(
          ["foldersById", folderId, "parentId"],
          null,
        );
      });

      return nextState.merge({
        loading: false,
        rootFolderId: root._id,
        rootFolders: Immutable.List(folders),
      });
    }

    case "FETCH_ROOT_FOLDER_FAILURE":
      return state.merge({ loading: false, loaded: false });

    case "FETCH_EXPANDED_FOLDERS_REQUEST": {
      let nextState = state;
      action.expandedFolders.forEach((id) => {
        nextState = nextState.setIn(["foldersById", id, "isPendingLoad"], true);
      });

      return nextState;
    }

    case "FETCH_EXPANDED_FOLDERS_SUCCESS": {
      let nextState = state;

      action.folders.forEach((folder) => {
        // add all children to foldersById
        folder.children?.forEach((treeNode) => {
          const node = deserializeFolderNode(treeNode);
          nextState = nextState.updateIn(["foldersById", node.id], (f) => {
            let childNode = deserializeFolderNode({
              ...treeNode,
              didLoad: f?.get("didLoad"),
            });

            if (f?.get("children") && f?.get("didLoad")) {
              childNode = childNode.set("children", f?.get("children"));
            }

            return childNode;
          });
        });

        const currentChildren = nextState.getIn([
          "foldersById",
          folder._id,
          "children",
        ]);

        const currentFolderDidLoad = nextState.getIn([
          "foldersById",
          folder._id,
          "didLoad",
        ]);

        let node = deserializeFolderNode({
          ...folder,
          isPendingLoad: false,
          didLoad: true,
        });

        if (currentChildren && currentFolderDidLoad) {
          node = node.set("children", currentChildren);
        }

        nextState = nextState.setIn(["foldersById", folder._id], node);
      });

      return nextState.set("hasLoadedExpandedFolders", true);
    }

    case "SET_EMPTY_EXPANDED_FOLDERS":
      return state.set("hasLoadedExpandedFolders", true);

    case "FETCH_ONE_FOLDER_REQUEST":
      return state.setIn(
        ["foldersById", action.requestKey, "isPendingLoad"],
        true,
      );

    case "FETCH_ONE_FOLDER_SUCCESS": {
      let nextState = state;
      const { folder } = action;

      // A folder that is returned from API can contain children.
      // deserialize all of it's children and add each child to foldersById
      // and if any of the children were already fetched, do not change their didLoad status
      folder.children?.forEach((treeNode) => {
        nextState = nextState.updateIn(["foldersById", treeNode._id], (f) => {
          let childNode = deserializeFolderNode({
            ...treeNode,
            didLoad: f?.get("didLoad"),
          });

          if (f?.get("children") && f?.get("didLoad")) {
            childNode = childNode.set("children", f?.get("children"));
          }

          return childNode;
        });
      });

      const currentChildren = nextState.getIn([
        "foldersById",
        folder._id,
        "children",
      ]);

      const currentFolderDidLoad = nextState.getIn([
        "foldersById",
        folder._id,
        "didLoad",
      ]);

      let node = deserializeFolderNode({
        ...folder,
        isPendingLoad: false,
        didLoad: true,
      });

      if (currentChildren && currentFolderDidLoad) {
        node = node.set("children", currentChildren);
      }

      nextState = nextState.setIn(["foldersById", folder._id], node);

      return nextState;
    }

    case "FETCH_ONE_FOLDER_FAILURE":
      return state.setIn(["foldersById", action.requestKey, "didLoad"], false);

    case "UPDATE_FOLDER_STRUCTURE": {
      let nextState = state;
      const { folders } = action;
      const { dnd, updatedFolder } = folders;

      nextState = nextState.set("highlightedNodes", [updatedFolder._id]);
      nextState = nextState.setIn(
        ["foldersById", updatedFolder._id, "title"],
        updatedFolder.title,
      );
      nextState = addSearchResultsToTree(nextState);
      nextState = updateFoldersStructure(nextState, dnd);

      return nextState;
    }

    case "UPDATE_DND_FOLDER_STRUCTURE": {
      let nextState = state;
      nextState = addSearchResultsToTree(nextState);
      nextState = updateFoldersStructure(nextState, action.folders.dnd);

      return nextState;
    }

    case "INCREMENT_FOLDERS_PAGE_NUMBER":
      return state.updateIn(
        ["numLoadedPages"],
        (currentPage) => currentPage + 1,
      );

    case "RESET_FOLDERS_PAGE_NUMBER":
      return state.set("numLoadedPages", 1);

    case "CLEAR_HIGHLIGHTED_NODES":
      return state.set("highlightedNodes", []);

    case "CREATE_FOLDER_SUCCESS": {
      let nextState = state;
      const folder = deserializeFolderNode(action.folder);

      const noFoldersLoaded = !state.rootFolderId || state.rootFolderId === "";

      if (noFoldersLoaded || folder.parentId === state.rootFolderId) {
        nextState = nextState.update("rootFolders", (folders) =>
          folders.insert(0, folder.id),
        );
      } else {
        nextState = nextState.updateIn(
          ["foldersById", folder.parentId, "children"],
          (children) => children?.insert(0, folder),
        );
      }

      nextState = nextState.setIn(["foldersById", folder.id], folder);
      nextState = nextState.set("highlightedNodes", [folder.id]);

      return nextState;
    }

    // TODO - rename to "SAVE_BOT_CONTENT_SUCCESS" after folders FF is ON for all clients
    // When response is edited on a versioned bot
    case "SAVE_FOLDER_NODE_BOT_CONTENT_SUCCESS": {
      let nextState = state;

      const { folders = {}, responseId, responses } = action;
      // TODO - update API responses in BE to send data in a better format
      const newParentId = Object.keys(folders)[0];
      const oldParentId = nextState.getIn([
        "foldersById",
        responseId,
        "parentId",
      ]);

      // if parentId has not changed, do not update children.
      if (oldParentId && newParentId !== oldParentId) {
        nextState = updateResponseParentIdAndFoldersChildren(
          state,
          responseId,
          newParentId,
        );
      }

      // Update response in foldersState with new data
      nextState = nextState.setIn(
        ["foldersById", responseId],
        deserializeFolderNode({
          ...responses[responseId],
          parent_id: newParentId,
        }),
      );

      return nextState;
    }

    // TODO - rename to "SAVE_RESPONSE_SUCCESS" after folders FF is ON for all clients
    // When response is edited on a bot without versioning
    case "SAVE_FOLDER_RESPONSE_NODE_SUCCESS": {
      let nextState = state;
      const { responseId, response } = action;
      const oldParentId = nextState.getIn([
        "foldersById",
        responseId,
        "parentId",
      ]);

      // If parentId has been changed
      if (oldParentId && response.parent_id !== oldParentId) {
        nextState = updateResponseParentIdAndFoldersChildren(
          state,
          responseId,
          response.parent_id,
        );
      }

      // Update response in foldersState with new data
      nextState = nextState.setIn(
        ["foldersById", responseId],
        deserializeFolderNode(response),
      );

      return nextState;
    }

    // TODO - rename to "DELETE_RESPONSE_SUCCESS" after folders FF is ON for all clients
    case "DELETE_FOLDER_RESPONSE_NODE_SUCCESS": {
      let nextState = state;

      const { responseId } = action;

      // 1. Remove response from parent's children
      const oldParentId = nextState.getIn([
        "foldersById",
        responseId,
        "parentId",
      ]);

      if (oldParentId === state.rootFolderId) {
        nextState = nextState.update("rootFolders", (nodes) =>
          nodes.filter((nodeId) => nodeId !== responseId),
        );
      } else {
        nextState = nextState.updateIn(
          ["foldersById", oldParentId, "children"],
          (children) => children?.filter((child) => child.id !== responseId),
        );
      }

      // 2. Remove response from folders list
      nextState = nextState.update("foldersById", (nodes) =>
        nodes.filter((node) => node.id !== responseId),
      );

      return nextState;
    }

    case "SET_FOLDER_FILTER_REQUEST": {
      let nextState = state;

      if (action.request) {
        nextState = nextState.merge({
          numLoadedPages: 1,
          loading: true,
        });
        action.request.forEach((filter) => {
          nextState = nextState.setIn(filter.query, filter.value);
        });
      }

      return nextState;
    }

    case "CLEAR_FOLDER_FILTERS":
      return state.merge({
        numLoadedPages: 1,
        searchQuery: Immutable.Map({
          userinput: "",
          sortBy: "folders",
          sortDirection: "",
        }),
        loadedSearchResults: Immutable.OrderedMap(),
      });

    case "CLEAR_SEARCH_RESULTS":
      return state.merge({
        loadedSearchResults: Immutable.OrderedMap(),
        numSearchResults: 0,
      });

    case "FOLDERS_SET_IS_SEARCHING": {
      return state.merge({
        isSearching: action.value,
      });
    }

    case "UPDATE_SEARCH_RESULTS":
      return state.update("loadedSearchResults", (prevResults) =>
        state.numLoadedPages > 1
          ? prevResults.concat(action.newResults)
          : action.newResults,
      );

    case "REMOVE_CHILDREN_FROM_PARENT_FOLDER": {
      let nextState = state;
      const deletedItems = [
        ...action.deletedFolders,
        ...action.deletedResponses,
      ];
      deletedItems.forEach((deletedItemId) => {
        const deletedNode = nextState.getIn(["foldersById", deletedItemId]);
        const parentId = deletedNode.get("parentId");

        // 1. Remove deletedId from parent folders's children (if parent folder is not Root)
        if (parentId !== nextState.get("rootFolderId")) {
          nextState = nextState.updateIn(
            ["foldersById", parentId, "children"],
            (children) => {
              const childIndex = children.findIndex(
                (node) => node.id === deletedItemId,
              );

              return childIndex > -1 ? children.delete(childIndex) : children;
            },
          );
        } else {
          // 2. If parent folder is Root, remove the deletedId from rootFolders
          nextState = nextState.update("rootFolders", (folders) =>
            folders.delete(deletedItemId),
          );
        }
      });

      return nextState;
    }

    case "DELETE_FOLDERS_SUCCESS": {
      const deletedItems = [
        ...action.deletedFolders,
        ...action.deletedResponses,
      ];

      return state.set(
        "foldersById",
        state.foldersById.deleteAll(deletedItems),
      );
    }

    case "UPDATE_FOLDER_RESPONSE_STATUS_SUCCESS": {
      const { liveField } =
        MODALITY_DETAILS[action.modality || MESSAGING_MODALITY];

      let newState = state.setIn(
        ["foldersById", action.responseId, liveField],
        action.responseStatus,
      );

      if (newState.hasIn(["loadedSearchResults", action.responseId])) {
        newState = newState.setIn(
          ["loadedSearchResults", action.responseId, liveField],
          action.responseStatus,
        );
      }

      return newState;
    }

    default:
      return state;
  }
};
