import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  border-top: 1px solid ${tokens.colors.border.default};
  padding: ${primitives.spacing.space4};

  button {
    margin-left: ${primitives.spacing.space4};
  }
`;
