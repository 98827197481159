/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Route } from "react-router-dom";

const PageLoader = (PageComponent: any) => () =>
  (
    <React.Suspense fallback="">
      <PageComponent />
    </React.Suspense>
  );

interface LazyRouteProps {
  path: string;
  exact?: boolean;
  component: any;
}

/**
 * @param {Object} props
 * @returns {Route}
 */
export class LazyRoute extends React.PureComponent<LazyRouteProps> {
  static defaultProps = {
    exact: false,
  };

  /**
   * @returns {ReactElement}
   */
  render() {
    const { path, component, exact } = this.props;

    return (
      <Route exact={exact} path={path}>
        {PageLoader(component)}
      </Route>
    );
  }
}
