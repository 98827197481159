// @ts-strict-ignore
import type Immutable from "immutable";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "components/Common/Button";
import { type Language } from "resourceModels";
import { getResources } from "selectors/resources";
import { useClientLegacy } from "services/clientLegacy";

import "./style.scss";

interface Props {
  availableLanguages: Immutable.List<Language>;
  onLanguageClick(languageCode: string): void;
  closeDialog(): void;
}

export function ResponseEditorLanguagesDialog(props: Props) {
  const { onLanguageClick, availableLanguages, closeDialog } = props;

  const { client } = useClientLegacy();
  const languages = useSelector((state) => getResources(state, "language"));

  return (
    <div className="ResponseEditorLanguagesDialog">
      <div className="ResponseEditorLanguagesDialog__languages">
        {availableLanguages.map((language) => (
          <Button
            text={language.englishName}
            title={language.englishName}
            customClassName="ResponseEditorLanguagesDialog__languages__language-button"
            key={language.id}
            onClick={() => {
              onLanguageClick(language.code);
              closeDialog();
            }}
            size="small"
            clear
          />
        ))}
      </div>
      {/*
          We subtract 1 here because languages includes the primary language,
          which does not need to be translated
        */}
      {languages.size - 1 !== client.translatedLanguages.size && (
        <p className="ResponseEditorLanguagesDialog__link-block g-form__block__description">
          Add languages to this list in&nbsp;
          <Link
            to="/settings/languages"
            className="ResponseEditorLanguagesDialog__link-block__link g-form__block__description"
          >
            Settings → Languages
          </Link>
        </p>
      )}
    </div>
  );
}
