// @ts-strict-ignore
import Immutable from "immutable";

// Ada supported sunshine integrations, we use Sunshine to support multiple channels at Ada
export enum SunshineChannelTypes {
  FB_MESSENGER = "messenger",
  WHATSAPP = "whatsapp",
  SUNSHINE_WEB_MESSENGER = "web",
  LINE = "line",
  TWITTER = "twitter",
  TWILIO_SMS = "twilio",
  APPLE_BUSINESS_CHAT = "apple",
}

export interface ChannelIntegrationProps {
  id: string;
  defaultLanguage: string;
  multiBotChannelEnabled: boolean;
  attributes: {
    id: string; // Sunshine integration id
    displayName: string; // User defined sunshine integration name
    type: SunshineChannelTypes;
    // The rest of data varies depends on the channel type above, see https://docs.smooch.io/rest/#operation/listIntegrations
    [key: string]: string;
  };
}

export class ChannelIntegrationRecord extends Immutable.Record<ChannelIntegrationProps>(
  {
    id: null,
    defaultLanguage: "en",
    multiBotChannelEnabled: false,
    attributes: null,
  },
) {}
