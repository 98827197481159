import { combineReducers } from "@reduxjs/toolkit";

import {
  Agent,
  AnalyticsAreaGraphRecord,
  AnalyticsCount,
  AnalyticsListItem,
  AnalyticsOverview,
  AnalyticsReport,
  AnalyticsTimeSeriesRecord,
  CSATSettings,
  Channel,
  ChatterNew,
  Language,
  MessageTemplate,
  ResponseSideBarItem,
  StatsSatisfactionRecord,
  User,
} from "resourceModels";

import createResourceReducer from "./createResourceReducer";

export default combineReducers(
  [
    AnalyticsListItem,
    AnalyticsOverview,
    AnalyticsReport,
    AnalyticsTimeSeriesRecord,
    AnalyticsCount,
    AnalyticsAreaGraphRecord,
    ChatterNew,
    Channel,
    CSATSettings,
    Language,
    MessageTemplate,
    ResponseSideBarItem,
    StatsSatisfactionRecord,
    User,
    Agent,
  ].reduce(
    (result, Model) => ({
      ...result,
      [Model.type]: createResourceReducer(Model),
    }),
    {},
  ),
);
