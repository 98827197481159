import React from "react";
import { connect } from "react-redux";

import { type User } from "resourceModels";
import { type Client, selectClient } from "services/client";
import { type State } from "types";
import "./style.scss";

const BLOCK_NAME = "UserImage";

interface UserImageProps {
  user: User;
  client: Client | null;
  children?: React.ReactNode | null;
  showDefaultPicture?: boolean;
  height: string;
  width: string;
  customClassName?: string;
}

class UserImage extends React.Component<UserImageProps> {
  renderImage() {
    const {
      client,
      user,
      children = null,
      showDefaultPicture = false,
    } = this.props;

    if (user.profilePicture) {
      return (
        <div
          className={`${BLOCK_NAME}__image ${BLOCK_NAME}__image--has-picture`}
          style={{
            backgroundImage: `url(${user.profilePicture})`,
          }}
        >
          {children}
        </div>
      );
    }

    if (showDefaultPicture) {
      return (
        <div
          className="UserImage__image UserImage__image--default-picture"
          style={{
            backgroundImage:
              "url(https://static.ada.support/DefaultAvatar.svg)",
          }}
        >
          {children}
        </div>
      );
    }

    return (
      <div
        className="UserImage__image UserImage__image--initials"
        style={{
          backgroundColor: client?.tint,
        }}
      >
        {user.name && user.name[0]}
      </div>
    );
  }

  /**
   * @returns {JSX.Element}
   */
  render() {
    const { customClassName = "", height, width } = this.props;

    return (
      <div
        role="button"
        className={`
                    UserImage
                    ${customClassName || ""}
                `}
        style={{
          height,
          width,
          fontSize: height,
        }}
      >
        {this.renderImage()}
      </div>
    );
  }
}

function mapState(state: State) {
  return {
    client: selectClient(state),
  };
}

const Connector = connect(mapState)(UserImage);

export default Connector;
