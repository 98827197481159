import type Immutable from "immutable";

import { type ThunkAction } from "actions/types";
import { deleteResponseVariables } from "actions/variables";
import {
  MESSAGING_MODALITY,
  type Modality,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type MessageRecord } from "reducers/responses/messageRecords";
import { selectClient } from "services/client";
import { type LanguageCode } from "services/language";
import { getResponseEditorActiveLanguage } from "services/responses/selectors";

export const addLanguageToResponseMessagesAction = ({
  responseId,
  language,
  messages,
}: {
  responseId: string;
  language: LanguageCode;
  messages: Immutable.List<MessageRecord>;
}) =>
  ({
    type: "ADD_LANGUAGE_TO_RESPONSE_MESSAGES",
    language,
    messages,
    responseId,
  } as const);

export const setResponseEditorActiveLanguageAction = (
  languageCode: LanguageCode,
) =>
  ({
    type: "SET_RESPONSE_EDITOR_ACTIVE_LANGUAGE",
    languageCode,
  } as const);

export const deleteResponseLanguageAction = (
  responseId: string,
  languageCode: LanguageCode,
  modality: Modality,
) =>
  ({
    type: "DELETE_LANGUAGE",
    responseId,
    languageCode,
    modality,
  } as const);

/** Delete variables created for a given language */
export const deleteLanguageScopedVariables =
  (responseId: string, modality: Modality = MESSAGING_MODALITY): ThunkAction =>
  (dispatch, getState) => {
    const activeLanguage = getResponseEditorActiveLanguage(getState());

    return deleteResponseVariables({
      getState,
      dispatch,
      responseId,
      langToDelete: activeLanguage,
      modality,
    });
  };

export const deleteResponseLanguageWithVariablesAction =
  (
    responseId: string,
    languageCode: LanguageCode,
    modality: Modality,
  ): ThunkAction =>
  (dispatch, getState) => {
    const state = getState();

    const client = selectClient(state);

    if (!client) {
      throw new Error("`client` is null");
    }

    if (client.features.personalization) {
      dispatch(deleteLanguageScopedVariables(responseId, modality));
    }

    dispatch(deleteResponseLanguageAction(responseId, languageCode, modality));
  };
