// @ts-strict-ignore
import Immutable from "immutable";

import { VOICE_SMS_CHARACTER_LIMIT } from "constants/messages";
import { removeTemplateVariables } from "services/strings";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface VoiceSMSMessageRecordAttributes extends BaseMessageRecordAttributes {
  type: "voice_sms";
  body: string;
  phoneNum: string | null;
}

export class VoiceSMSMessageRecord extends BaseMessageRecord<VoiceSMSMessageRecordAttributes>(
  {
    type: "voice_sms",
    body: "",
    phoneNum: "",
  },
) {
  static getInvalidFields = (
    message: VoiceSMSMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();
    const bodyWithoutTemplateVars = removeTemplateVariables(message.body);

    if (
      message.body.trim().length <= 0 ||
      bodyWithoutTemplateVars.trim().length > VOICE_SMS_CHARACTER_LIMIT
    ) {
      invalidFields.add("body");
    }

    return Immutable.List(invalidFields);
  };
}
