import { createApi } from "@reduxjs/toolkit/query/react";

import { adaAPI } from "services/api";
import { browserStorage } from "services/browserStorage";

export interface OnboardingQuickQuestion {
  text: string;
}

export interface OnboardingQuickQuestionsResponse {
  questions: OnboardingQuickQuestion[];
}

export const OnboardingQuickQuestionsApi = createApi({
  reducerPath: "onboardingQuickQuestions",
  baseQuery: (props) =>
    adaAPI.request<OnboardingQuickQuestionsResponse>({ ...props }),
  tagTypes: ["OnboardingQuickQuestions"],
  endpoints: (builder) => ({
    getOnboardingQuickQuestions: builder.query<
      OnboardingQuickQuestionsResponse,
      void
    >({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const questions = browserStorage.retrieve("onboarding_quick_questions");

        if (questions && questions.length > 0) {
          return { data: { questions } };
        }

        try {
          const response = await baseQuery({ url: "generate_questions" });

          browserStorage.store(
            "onboarding_quick_questions",
            response.data.questions,
          );

          return response;
        } catch (error) {
          console.error(error);

          // TODO: Should we dispatch an alert here?
          // api.dispatch(
          //   createAlert({
          //     message: "Something went wrong - failed to get new questions.",
          //     alertType: "error",
          //   }),
          // );

          return { error };
        }
      },
      providesTags: ["OnboardingQuickQuestions"],
    }),
  }),
});

export const { useGetOnboardingQuickQuestionsQuery } =
  OnboardingQuickQuestionsApi;
