import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { setPageAction } from "actions/router";

import * as S from "./styles";

export type CampaignReferenceList = {
  name: string;
  campaignKey: string;
}[];

interface Props {
  references: CampaignReferenceList;
}

export function CampaignReferenceList({ references }: Props) {
  const dispatch = useDispatch();

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolledToBottom(
      event.currentTarget.scrollTop >=
        event.currentTarget.scrollHeight - event.currentTarget.offsetHeight,
    );
  };

  return (
    <S.ReferenceListContainer>
      <S.ReferenceListContent onScroll={handleScroll}>
        {references.map((reference) => (
          <S.ReferenceButton
            key={reference.campaignKey}
            type="button"
            onClick={() => {
              dispatch(closeModalAction());
              dispatch(
                setPageAction(
                  `/answers/campaigns/edit/${reference.campaignKey}`,
                ),
              );
            }}
          >
            <S.InfoGroup>
              <S.Title>{reference.name}</S.Title>
            </S.InfoGroup>
          </S.ReferenceButton>
        ))}
      </S.ReferenceListContent>
      <S.ReferenceListGradient
        visible={references.length > 3 && !isScrolledToBottom}
      />
    </S.ReferenceListContainer>
  );
}
