// @ts-strict-ignore
// eslint-disable-next-line max-classes-per-file
import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

export class TrackEventMessageField extends Immutable.Record({
  name: "",
  fieldType: "",
  value: "", // will likely need to be updated to allow for other types
  isVariable: false,
}) {}

interface TrackEventMessageRecordAttributes
  extends BaseMessageRecordAttributes {
  type: "afm_event";
  eventKey: string;
  fields: Immutable.List<TrackEventMessageField>;
}

export class TrackEventMessageRecord extends BaseMessageRecord<TrackEventMessageRecordAttributes>(
  {
    type: "afm_event",
    eventKey: "",
    fields: Immutable.List(),
  },
) {
  static getInvalidFields = (
    message: TrackEventMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (message.eventKey.trim().length <= 0) {
      invalidFields.add("eventKey");
    }

    if (message.fields.size > 0) {
      if (message.fields.first().isVariable && !message.fields.first().value) {
        invalidFields.add("variableId");
      }
    }

    return Immutable.List(invalidFields);
  };
}
