import { type List } from "immutable";

import { type ResponseRecord } from "reducers/responses/types";

export function getResponseIndex(
  list: List<ResponseRecord>,
  id: string | null,
) {
  return list.findIndex((listItem) => listItem.id === id);
}
