import { Icon, tokens } from "@adasupport/byron";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";

import { cssVariables } from "constants/css";
import {
  ALL_SCOPES,
  type AUTO_CAPTURE_VARIABLE_SCOPE,
  type GLOBAL_VARIABLE_SCOPE,
  type SENSITIVE_VARIABLE_SCOPE,
  type VariableScope,
} from "constants/variables";
import {
  VARIABLE_TYPE_LABELS,
  type Variable,
  getVariableType,
  getVariableValueType,
  useVariables,
} from "services/variables";

import { InputText } from "../InputText";
import { Pill } from "../Primitives";
import { HSpace1, VSpace3 } from "../Space";
import { VariablePalette } from "../VariablePalette";
import { VariablePill } from "../VariablePill";

const S = {
  VariablePicker: styled.div<{ isDisabled?: boolean }>`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    ${({ isDisabled }) => isDisabled && `cursor: not-allowed;`}
  `,

  Toggle: styled.div<{
    focused?: boolean;
    invalid?: boolean;
    isDisabled?: boolean;
  }>`
    ${({ isDisabled }) => isDisabled && `pointer-events: none;`}
    display: flex;
    gap: 8px;
    cursor: pointer;
    white-space: nowrap;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    height: 36px;
    border-radius: 6px;
    border: 1px solid ${tokens.colors.border.formInput};
    padding: 4px 6px;
    background-color: ${tokens.colors.background.main};

    ${(p) =>
      p.focused &&
      css`
        border-color: ${tokens.colors.border.formInputFocused};
        box-shadow: 0 0 0 4px ${tokens.colors.border.formInputFocusedShadow};
      `};

    ${(p) =>
      p.invalid &&
      css`
        border-color: ${tokens.colors.border.formInputError};
      `}
  `,

  ToggleContent: styled.span`
    flex: 1 1 0;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  MenuDropdown: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 4px;
    z-index: ${cssVariables.zIndexModal};
    min-width: 240px;
    background-color: ${tokens.colors.background.main};
    box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 0 10px 0 rgb(0 0 0 / 18%);
    border-radius: 8px;
    padding: 16px;
  `,

  VariablesContainer: styled.div`
    max-height: 300px;
    padding: 16px;
    margin: 0 -16px -16px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,

  SectionTitle: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: ${tokens.colors.text.muted};
    margin-bottom: 8px;
  `,

  SectionContent: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  `,

  EmptyText: styled.div`
    font-size: 12px;
    color: ${tokens.colors.text.muted};
    margin-top: 12px;
  `,

  Placeholder: styled.span`
    color: ${tokens.colors.text.muted};
    font-style: italic;
    font-weight: normal;
  `,
};

export const VariablePicker = ({
  value,
  onChange,
  variables,
  isInvalid,
  isDisabled,
  allowedScopes = ALL_SCOPES,
  allowedScopesForCreation = allowedScopes as (
    | typeof AUTO_CAPTURE_VARIABLE_SCOPE
    | typeof GLOBAL_VARIABLE_SCOPE
    | typeof SENSITIVE_VARIABLE_SCOPE
  )[],
  clearable = true,
  placeholder = "Choose a variable",
}: {
  value: string | null | undefined;
  onChange: (value: string | null) => void;
  variables?: Variable[];
  isInvalid?: boolean;
  isDisabled?: boolean;
  allowedScopes?: readonly VariableScope[];
  allowedScopesForCreation?: (
    | typeof AUTO_CAPTURE_VARIABLE_SCOPE
    | typeof GLOBAL_VARIABLE_SCOPE
    | typeof SENSITIVE_VARIABLE_SCOPE
  )[];
  clearable?: boolean;
  placeholder?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [search, setSearch] = useState("");
  const { variables: allVariables } = useVariables();
  let availableVariables = variables || allVariables;
  const rootRef = useOnClickOutside(() => setIsOpen(false));
  const toggleRef = React.createRef<HTMLDivElement>();
  const menuDropdownRef = React.createRef<HTMLDivElement>();
  const searchInputRef = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (!isOpen) {
      setIsCreateMode(false);
    }
  }, [isOpen]);

  availableVariables = availableVariables?.filter((v) =>
    allowedScopes.includes(v.scope),
  );

  const filteredVariables = useMemo(
    () =>
      (availableVariables || [])
        .filter((variable) =>
          variable.name.toLowerCase().includes(search.toLowerCase()),
        )
        .reduce(
          (acc, variable) => {
            const group = getVariableType(variable);

            return {
              ...acc,
              [group]: [...acc[group], variable].sort((a, b) =>
                a.name.localeCompare(b.name),
              ),
            };
          },
          {
            global: [] as Variable[],
            // This component is only used in generative, so we don't actually use local variables in the component,
            // but we need to handle it here still to make typescript happy
            local: [] as Variable[],
            meta: [] as Variable[],
            token: [] as Variable[],
            sensitive: [] as Variable[],
            auto_capture: [] as Variable[],
            unknown: [] as Variable[],
          },
        ),

    [search, availableVariables],
  );

  const selectedVariable = availableVariables?.find((v) => v._id === value);

  const highlightedVariable =
    search !== ""
      ? filteredVariables.auto_capture[0] ||
        filteredVariables.global[0] ||
        filteredVariables.meta[0] ||
        filteredVariables.token[0] ||
        filteredVariables.sensitive[0] ||
        filteredVariables.unknown[0]
      : undefined;

  const renderVariablePill = (variable: Variable) => (
    <VariablePill
      key={variable._id}
      name={variable.name}
      type={getVariableType(variable)}
      valueType={getVariableValueType(variable)}
      onClick={() => {
        onChange(variable._id);
        setIsOpen(false);
      }}
      isSelected={variable === highlightedVariable}
    />
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen) {
        return;
      }

      if (e.key === "Escape") {
        setIsOpen(false);
      }

      if (highlightedVariable && e.key === "Enter") {
        onChange(highlightedVariable._id);
        setIsOpen(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [highlightedVariable, onChange, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setSearch("");
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const updateDropdownPosition = useCallback(() => {
    if (!isOpen) {
      return;
    }

    const adjustPosition = () => {
      const dropdown = menuDropdownRef.current;

      if (dropdown && toggleRef.current) {
        const toggleRect = toggleRef.current.getBoundingClientRect();

        dropdown.style.top = `${toggleRect.bottom}px`;
        dropdown.style.left = `${toggleRect.left}px`;
        dropdown.style.width = `${toggleRect.width}px`;

        const viewportHeight = window.innerHeight;

        if (toggleRect.bottom + dropdown.offsetHeight > viewportHeight) {
          dropdown.style.top = `${toggleRect.top - dropdown.offsetHeight}px`;
          dropdown.style.marginTop = "-4px";
        } else {
          dropdown.style.marginTop = "4px";
        }
      }
    };

    adjustPosition();

    // One more time in case page layout has changed
    setTimeout(adjustPosition, 0);
  }, [isOpen, menuDropdownRef, toggleRef]);

  useEffect(() => {
    updateDropdownPosition();
  }, [updateDropdownPosition]);

  // Also update on scroll
  useEffect(() => {
    window.addEventListener("scroll", updateDropdownPosition, true);

    return () => {
      window.removeEventListener("scroll", updateDropdownPosition, true);
    };
  }, [updateDropdownPosition]);

  return (
    <S.VariablePicker ref={rootRef} isDisabled={isDisabled}>
      <S.Toggle
        focused={isOpen}
        invalid={isInvalid}
        onMouseDown={() => setIsOpen(true)}
        ref={toggleRef}
        isDisabled={isDisabled}
      >
        <S.ToggleContent>
          {selectedVariable ? (
            <VariablePill
              name={selectedVariable.name}
              type={getVariableType(selectedVariable)}
              valueType={getVariableValueType(selectedVariable)}
            />
          ) : (
            <S.Placeholder>{placeholder}</S.Placeholder>
          )}
        </S.ToggleContent>

        {!value || !clearable ? (
          <Icon.ChevronDown />
        ) : (
          <span
            style={{ lineHeight: 0 }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onClick={() => {
              onChange(null);
              setIsOpen(false);
            }}
            title="Clear selection"
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onChange(null);
                setIsOpen(false);
              }
            }}
          >
            <Icon.Close
              color={tokens.colors.text.muted}
              width={16}
              height={16}
            />
          </span>
        )}
      </S.Toggle>

      {isOpen &&
        createPortal(
          <S.MenuDropdown
            ref={menuDropdownRef}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {isCreateMode ? (
              <VariablePalette
                allowedScopes={allowedScopesForCreation}
                onCreate={(id) => {
                  onChange(id);
                  setIsOpen(false);
                }}
                defaultAttributes={{
                  name: search,
                }}
              />
            ) : (
              <>
                <InputText
                  search
                  higherRef={searchInputRef}
                  value={search}
                  onChange={setSearch}
                  placeholder="Search by name"
                />

                {allowedScopesForCreation.length > 0 && (
                  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                  <div onMouseDown={(e) => e.preventDefault()}>
                    <VSpace3 />

                    <Pill
                      outline
                      color="muted"
                      onClick={() => setIsCreateMode(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon.Sparkle width={16} height={16} />
                      <HSpace1 />
                      Make a new variable
                    </Pill>
                  </div>
                )}

                {filteredVariables.global.length !== 0 ||
                filteredVariables.meta.length !== 0 ||
                filteredVariables.token.length !== 0 ||
                filteredVariables.sensitive.length !== 0 ||
                filteredVariables.auto_capture.length !== 0 ||
                filteredVariables.unknown.length !== 0 ? (
                  <S.VariablesContainer>
                    {filteredVariables.auto_capture.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.auto_capture}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.auto_capture.map(
                            renderVariablePill,
                          )}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.global.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.global}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.global.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.meta.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.meta}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.meta.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.token.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.token}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.token.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.sensitive.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.sensitive}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.sensitive.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}

                    {filteredVariables.unknown.length !== 0 && (
                      <div>
                        <S.SectionTitle>
                          {VARIABLE_TYPE_LABELS.unknown}
                        </S.SectionTitle>
                        <S.SectionContent>
                          {filteredVariables.unknown.map(renderVariablePill)}
                        </S.SectionContent>
                      </div>
                    )}
                  </S.VariablesContainer>
                ) : (
                  <S.EmptyText>No matches</S.EmptyText>
                )}
              </>
            )}
          </S.MenuDropdown>,
          document.body,
        )}
    </S.VariablePicker>
  );
};
