import styled from "styled-components";

import { Button } from "components/Common/Button";
import { v } from "constants/css";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PageSizeSelector = styled.div`
  font-size: ${v.smallFontSize};
  font-weight: ${v.mediumFontWeight};
`;

export const Info = styled.span`
  line-height: ${v.gUnitQuadruple};
  font-size: ${v.smallFontSize};
`;

export const PageButtonContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${v.gUnitDouble};
`;

export const PageButton = styled(Button)`
  margin-left: ${v.gUnit};
`;
