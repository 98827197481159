import { AxiosError } from "axios";

import { setState } from "actions";
import { createAlert } from "actions/alerts";
import { closeModalAction, openModalAction } from "actions/modal";
import { type ThunkAction } from "actions/types";
import {
  type Schedule,
  type ScheduleDto,
} from "components/Shared/Pages/Settings/SettingsSchedules/types";
import { adaApiRequest } from "services/api";
import { keyConverter } from "services/key-converter";
import {
  convertSchedulesToPayload,
  getNormalOperatingDays,
  getSpecialOperatingDays,
} from "services/scheduleFormatConverter";

export const createScheduleModalAction = () => ({
  type: "CREATE_SETTINGS_SCHEDULE_MODAL",
});

export const setScheduleModalAction = (payload: Schedule) => ({
  type: "SET_SETTINGS_SCHEDULE_MODAL",
  payload,
});

export const handleUpdateScheduleModalAction = (
  payload: Record<string, unknown>,
) => ({
  type: "UPDATE_SETTINGS_SCHEDULE_MODAL",
  payload,
});

export const handleUpdateScheduleRowAction = (
  payload: Record<string, unknown>,
) => ({
  type: "UPDATE_SETTINGS_SCHEDULE_ROW",
  payload,
});

export const handleAddScheduleRowAction = (
  payload: Record<string, unknown>,
) => ({
  type: "ADD_SETTINGS_SCHEDULE_ROW",
  payload,
});

export const handleDeleteScheduleRowAction = (
  payload: Record<string, unknown>,
) => ({
  type: "DELETE_SETTINGS_SCHEDULE_ROW",
  payload,
});

export function showScheduleModalAction(
  payload: Record<string, unknown>,
): ThunkAction {
  return (dispatch) =>
    dispatch(
      setState("MODAL", {
        isOpen: true,
        view: "SETTINGS_SCHEDULES_MODAL",
        modalProps: payload,
      }),
    );
}

/**
 * patch scheduled block schedules to Client
 */
export function patchScheduledBlockSchedules(
  data: ScheduleDto[],
  action: "create" | "update" | "delete",
): ThunkAction {
  const successMessage =
    action === "create"
      ? "Done! You can now use this new schedule inside a scheduled block."
      : `Schedule was successfully ${action}d.`;

  return async (dispatch) => {
    try {
      const res = await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: "/scheduled-block-schedules/",
          data: { scheduled_block_schedules: data },
        }),
      );

      dispatch({
        type: "PATCH_SCHEDULED_BLOCK_SCHEDULES_SUCCESS",
        schedules: res.data.scheduled_block_schedules,
      });

      dispatch(
        createAlert({
          message: successMessage,
          alertType: "success",
        }),
      );
      dispatch(closeModalAction());
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 400) {
        dispatch(createAlert({ message: error.response.data.message }));
      }
    }
  };
}

export function deleteSchedule(scheduleId: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { settingsScheduledBlockSchedules } = state;
    const schedules = convertSchedulesToPayload(
      settingsScheduledBlockSchedules,
    ).filter((schedule) => schedule._id !== scheduleId);

    dispatch(patchScheduledBlockSchedules(schedules, "delete"));
  };
}

function showScheduleReferencesWarningModalAction(response: {
  data: { response_schedule_references: Record<string, unknown>[] };
}) {
  const responseScheduleReferences = keyConverter(
    response.data.response_schedule_references,
  );

  return openModalAction("MODAL_WARNING", {
    responseScheduleReferences,
    title: "Schedule in Use",
    message: `This schedule is used in the following answer${
      responseScheduleReferences.length > 1 ? "s" : ""
    } and can't be deleted…`,
    buttons: ["cancel"],
    type: "schedule",
    totalReferenceButtons: responseScheduleReferences.length,
  });
}

function showConfirmDeleteScheduleModalAction(scheduleId: string): ThunkAction {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Delete Schedule",
        message: "Are you sure you would like to delete this Schedule?",
        actions: [
          {
            title: "Delete Schedule",
            buttonTint: "alert",
            onClick: () => {
              dispatch(deleteSchedule(scheduleId));
              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick() {
              dispatch(closeModalAction());
            },
          },
        ],
      }),
    );
  };
}

/**
 * Try deleting the schedule. Show a warning modal if it is being used.
 * If it is not being used, show a confirmation modal.
 */
export function tryDeletingScheduleAction(scheduleId: string): ThunkAction {
  return async (dispatch) => {
    try {
      const res = await dispatch(
        adaApiRequest<{
          schedule_id: string;
        }>({
          method: "GET",
          url: `/scheduled_block_schedules/references/${scheduleId}`,
        }),
      );

      dispatch(showConfirmDeleteScheduleModalAction(res.data.schedule_id));
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 400) {
        dispatch(showScheduleReferencesWarningModalAction(error.response));
      }
    }
  };
}

/**
 * update current schedule or add a new schedule
 */
export function handleSaveScheduleModalAction(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { settingsScheduledBlockSchedules, settingsScheduleModal, modal } =
      state;
    let schedules = convertSchedulesToPayload(settingsScheduledBlockSchedules);
    const action = modal.modalProps.newSchedule ? "create" : "update";
    const { scheduleIndex } = modal.modalProps;

    const newSchedule = {
      _id: settingsScheduleModal.id,
      name: settingsScheduleModal.name,
      timezone: settingsScheduleModal.timezone,
      normal_operating_days: getNormalOperatingDays(
        settingsScheduleModal.normalOperatingDaysRows,
      ),
      special_operating_days: getSpecialOperatingDays(
        settingsScheduleModal.specialOperatingDaysRows,
      ),
    };

    if (action === "create") {
      schedules = [...schedules, newSchedule];
    } else {
      schedules = schedules.map((schedule, i) => {
        if (i === scheduleIndex) {
          return newSchedule;
        }

        return schedule;
      });
    }

    dispatch(patchScheduledBlockSchedules(schedules, action));
  };
}
