import classNames from "classnames";
import React from "react";

import { Button } from "components/Common/Button";
import { NO_OP_FUNCTION } from "services/helpers";
import "./style.scss";

// TODO: replace `null` with more sensible default values - intentionally left by TS conversion initiative
interface ButtonGroupProps {
  stopPropagation?: boolean;
  preventDefault?: boolean;
  disabled?: boolean;
  data: {
    label: string;
    value: string;
  }[];
  value: string;
  className?: string | null;
  selectedClassName?: string;
  onChange?(...args: unknown[]): unknown;
  stretch: boolean;
}

export const ButtonGroup = ({
  stopPropagation = false,
  preventDefault = false,
  data,
  disabled = false,
  value,
  className = null,
  selectedClassName = "",
  onChange = NO_OP_FUNCTION,
  stretch,
}: ButtonGroupProps) => {
  const buttons = data.map((dataSet) => (
    <Button
      key={dataSet.value}
      customClassName={classNames("ButtonGroup__button", {
        "ButtonGroup__button--active": value === dataSet.value,
        "ButtonGroup__button--disabled": disabled,
        [selectedClassName]: value === dataSet.value,
      })}
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }

        if (preventDefault) {
          e.preventDefault();
        }

        onChange(dataSet.value);
      }}
      text={dataSet.label}
    />
  ));

  return (
    <div
      className={classNames("ButtonGroup", `${className}`, {
        "ButtonGroup--disabled": disabled,
        "ButtonGroup--stretch": stretch,
      })}
    >
      {buttons}
    </div>
  );
};
