import {
  ConditionPropertyType,
  ConditionRelationType,
  type Goal,
  type GoalCondition,
  type GoalPredicate,
  GoalValueType,
  type GoalsState,
  PredicateValueType,
} from "reducers/goals/types";

export const DEFAULT_GOAL_CONDITION: GoalCondition = {
  type: "condition",
  property: ConditionPropertyType.COUNT,
  relation: ConditionRelationType.GREATER_THAN,
  eventKey: "",
  value: 0.0,
};

export const DEFAULT_GOAL_PREDICATE: GoalPredicate = {
  type: "predicate" as const,
  value: PredicateValueType.ANY,
  conditions: [{ ...DEFAULT_GOAL_CONDITION }],
};

export const DEFAULT_GOAL: Goal = {
  id: "",
  version: "",
  name: "",
  created: 0.0,
  updated: 0.0,
  updatedBy: "",
  description: "",
  successCondition: DEFAULT_GOAL_PREDICATE,
  timeframeMinutes: 30,
  value: {
    type: GoalValueType.DEFAULT,
    value: 0.0,
  },
};

export const DEFAULT_GOALS_STATE: GoalsState = {
  loading: false,
  loaded: false,
  goals: [],
  draftGoal: DEFAULT_GOAL,
};
