// TODO The module is obsolete and should be removed
// eslint-disable-next-line max-classes-per-file
import * as Immutable from "immutable";

import { keyConverter } from "services/key-converter";
import { pluralize } from "services/pluralize";
import { camelCaseToSnakeCase } from "services/strings";

interface PlainObject {
  [key: string]: unknown;
}

export interface SingleResource extends PlainObject {
  _id: string;
  _type: string;
}

export interface SingleResourcePayload {
  data: SingleResource;
  included?: SingleResource[];
}

export interface ManyResourcePayload {
  data: SingleResource[];
  included?: SingleResource[];
}

export type RecordAttributeMapper<A> = Partial<{
  [key in keyof A]: (value: unknown) => unknown;
}>;

interface BaseAttributes {
  id: string;
}

type WithBaseAttributes<A> = BaseAttributes & A;

interface ResourceRecordClass<A> {
  type: string;
  new (
    attributes: WithBaseAttributes<Partial<A>>,
    changedAttributes?: Partial<A>,
  ): ResourceRecord<A> & Readonly<A>;
  attributeMapper: RecordAttributeMapper<A>;
  deserialize(payload: PlainObject): WithBaseAttributes<A>;
}

export class ResourceRecord<A> {
  id: string;
  attributes: Immutable.Map<keyof A, unknown>;
  changedAttributes: Immutable.Map<keyof A, unknown>;
  gettersCache: Record<string, unknown>;
  static attributeMapper?: { [key: string]: (value: unknown) => unknown };
  static type = "unknown";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(attributes: BaseAttributes & Partial<A>, changedAttributes = {}) {
    this.id = attributes.id;
    this.attributes = Immutable.Map();
    this.changedAttributes = Immutable.Map();
    this.gettersCache = {};
  }

  onUpdate(): this {
    return this;
  }

  getAttribute(attributeName: keyof A): unknown {
    const changedAttribute = this.changedAttributes.get(attributeName);

    if (changedAttribute !== undefined) {
      return changedAttribute;
    }

    return this.attributes.get(attributeName);
  }

  /** Creates a deep copy of the record */
  clone(): this {
    // Typescript thinks this.constructor returns generic Function
    return new (this.constructor as typeof ResourceRecord)(
      this.attributes.toJS() as WithBaseAttributes<A>,
      this.changedAttributes.toJS() as Partial<A>,
    ) as this;
  }

  /** Creates or updates an entry in `changedAttributes`. Returns a new record */
  updateAttribute(path: string | string[], value: unknown): this {
    const fullPath = Array.isArray(path) ? path : [path];
    const newRecord = this.clone();
    newRecord.changedAttributes = newRecord.changedAttributes.setIn(
      fullPath,
      value,
    );

    return newRecord;
  }

  static mapAttributes(
    attributes: Record<string, unknown>,
  ): Record<string, unknown> {
    const { attributeMapper } = this;

    if (!attributeMapper) {
      return attributes;
    }

    const newAttributes = attributes;

    Object.keys(attributeMapper).forEach((attributeName) => {
      if (Object.prototype.hasOwnProperty.call(attributes, attributeName)) {
        newAttributes[attributeName] = attributeMapper[attributeName]?.(
          attributes[attributeName],
        );
      }
    });

    return newAttributes;
  }

  /** Creates or updates entries in `changedAttributes`. Returns a new record */
  updateAttributes(newAttributes: PlainObject): this {
    const newRecord = this.clone();
    newRecord.changedAttributes = newRecord.changedAttributes.merge(
      // Typescript thinks this.constructor returns generic Function
      (this.constructor as typeof ResourceRecord).mapAttributes(newAttributes),
    );

    return newRecord;
  }

  /** Updates `attributes`. Returns a new record */
  merge(newAttributes: Partial<WithBaseAttributes<A>>): this {
    // Typescript thinks this.constructor returns generic Function
    const newRecord = new (this.constructor as typeof ResourceRecord)(
      this.attributes.merge(newAttributes).toJS() as WithBaseAttributes<A>,
      this.changedAttributes.toJS() as Partial<A>,
    ) as this;

    // If new record ends up to be the same, just return self
    // This is needed to maintain same reference to the instance
    if (newRecord.attributes.equals(this.attributes)) {
      return this;
    }

    return newRecord;
  }

  /** Returns a new record with cleared `changedAttributes` */
  reset(): this {
    const newRecord = this.clone();
    newRecord.changedAttributes = Immutable.Map();

    return newRecord;
  }

  /** This is what's going to be sent through API */
  serialize(): PlainObject {
    return keyConverter(this.changedAttributes, "underscore", false, false);
  }

  didAttributeChange(attributeName: keyof A): boolean {
    const attributes = this as unknown as { [key: string]: unknown };

    // Need to be able to retrieve value using keys from <A>
    return !Immutable.is(
      this.attributes.get(attributeName),
      attributes[attributeName as string],
    );
  }

  /** Checks if any entry in `changedAttributes` is different from the one in `attributes` */
  get isDirty(): boolean {
    return Object.keys(this.changedAttributes.toObject()).some((key) =>
      this.didAttributeChange(key as keyof A),
    );
  }

  static deserialize<T extends BaseAttributes>(payload: PlainObject): T {
    // Make keys camelcase
    const attributes = keyConverter(payload, "camel");

    return {
      ...attributes,
      id: attributes.id || attributes._id,
    } as unknown as T;
  }

  static resolveCreateOneResult(payload: PlainObject): SingleResourcePayload {
    return this.resolveFetchOneResult(payload);
  }

  static resolveFetchOneResult(payload: PlainObject): SingleResourcePayload {
    return payload as unknown as SingleResourcePayload;
  }

  static resolveFetchManyResult(payload: PlainObject): ManyResourcePayload {
    return payload as unknown as ManyResourcePayload;
  }

  static resolveSaveOneResult(payload: PlainObject): SingleResourcePayload {
    return this.resolveFetchOneResult(payload);
  }

  static resolvePostActionResult(payload: PlainObject): SingleResourcePayload {
    return this.resolveFetchOneResult(payload);
  }

  static getCreateOneUrl(): string {
    return `/${camelCaseToSnakeCase(pluralize(2, this.type))}/`;
  }

  static getFetchOneUrl(id: string | null = null): string {
    return `/${camelCaseToSnakeCase(pluralize(2, this.type))}/${
      id ? `${id}/` : ""
    }`;
  }

  static getFetchManyUrl(): string {
    return `/${camelCaseToSnakeCase(pluralize(2, this.type))}/`;
  }

  static getFetchManyUrlParams(params: PlainObject): PlainObject {
    return params;
  }

  static getSaveOneURL(id: string | null = null): string {
    return this.getFetchOneUrl(id);
  }

  static getDeleteOneURL(id: string | null = null): string {
    return this.getFetchOneUrl(id);
  }

  static getPostActionURL(id: string, actionType: string): string {
    return `/${camelCaseToSnakeCase(
      pluralize(2, this.type),
    )}/${id}/actions/${camelCaseToSnakeCase(actionType)}`;
  }
}

export default function RecordClassCreator<A>(
  defaultAttributes: { type: string } & Required<A> & PlainObject,
): ResourceRecordClass<A> {
  if (!defaultAttributes.type) {
    throw new Error("type property expected when defining a Model");
  }

  class Constructor extends ResourceRecord<A> {
    static type = defaultAttributes.type;

    constructor(
      attributes: BaseAttributes & Partial<A> & PlainObject,
      changedAttributes: Partial<A> = {},
    ) {
      super(attributes, changedAttributes);

      // Typescript thinks this.constructor returns generic Function
      const c = this.constructor as typeof ResourceRecord;

      const ownAttributes: PlainObject = { id: this.id };

      const processedAttributes = c.mapAttributes(attributes);
      const processedChangedAttributes = c.mapAttributes(changedAttributes);

      Object.keys(defaultAttributes).forEach((key) => {
        // "type" is not an "attribute"
        if (key === "type") {
          return;
        }

        // Set attributes from "attributes" or fallback to "defaultAttributes"
        if (key in processedAttributes) {
          ownAttributes[key] = processedAttributes[key];
        } else {
          ownAttributes[key] = defaultAttributes[key];
        }

        // Create a getter for each attribute
        Object.defineProperty(this, key, {
          get() {
            return this.getAttribute(key as keyof A);
          },
        });
      });

      this.attributes = Immutable.fromJS(ownAttributes);
      this.changedAttributes = Immutable.fromJS(processedChangedAttributes);

      this.gettersCache = {};

      this.onUpdate();
    }
  }

  // Need to do type conversion, because TypeScript doesnt understand that the class also has
  // read-only properties from generic <A>
  return Constructor as unknown as ResourceRecordClass<A>;
}
