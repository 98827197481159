import { createAlert } from "actions/alerts";
import {
  ADD_CAMPAIGN,
  FETCH_CAMPAIGNS_FAILURE,
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  SET_CAMPAIGNS_FILTERS,
  SET_CAMPAIGNS_SEARCH_QUERY,
} from "actions/campaigns/types";
import { closeModalAction, openModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { type ThunkAction } from "actions/types";
import { CAMPAIGN_PAGE_AFTER_SAVE_ROUTE } from "components/Declarative/Pages/Campaigns/constants";
import { serializeCampaign } from "reducers/campaigns/reducer";
import { type Campaign, type CampaignDto } from "reducers/campaigns/types";
import { adaAPI } from "services/api";
import {
  fetchClientAction,
  saveClientAction,
  selectClient,
  updateClientAction,
} from "services/client";
import { openConfirmationModalAction } from "services/modal";

export function updateCampaignsAction(newCampaigns: CampaignDto[]) {
  return {
    type: FETCH_CAMPAIGNS_SUCCESS,
    campaigns: newCampaigns,
  };
}

export function fetchCampaignsAction(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const { loading, loaded } = state.campaignsState;
    const client = selectClient(state);

    /* Allowing re-fetching after campaigns are loaded (i.e. dropping the loaded
     * condition here) breaks saving new campaigns.
     */
    if (loading || loaded || !client) {
      return;
    }

    dispatch({ type: FETCH_CAMPAIGNS_REQUEST });

    try {
      const response = await adaAPI.request({
        method: "GET",
        url: "/campaigns/all/",
      });
      dispatch(updateCampaignsAction(response.data.campaigns as CampaignDto[]));
    } catch (error) {
      dispatch({ type: FETCH_CAMPAIGNS_FAILURE });
    }
  };
}

interface AddCampaignActionProps {
  campaignKey: string;
  name: string;
  description: string;
  isEngageClient: boolean;
  channel: string;
}

/**
 * Used when adding a campaign to the Redux store, but not yet saving it to the database.
 * Useful for adding a partially completed campaign before details are filled in.
 */
export function addCampaignAction({
  campaignKey,
  name,
  description,
  isEngageClient,
  channel,
}: AddCampaignActionProps) {
  return {
    type: ADD_CAMPAIGN,
    campaignKey,
    name,
    description,
    isEngageClient,
    channel,
  };
}

export function deleteCampaignAction(campaignId: string): ThunkAction {
  return async (dispatch) => {
    try {
      const response = await adaAPI.request({
        method: "DELETE",
        url: `/campaigns/${campaignId}`,
      });
      dispatch(updateCampaignsAction(response.data.campaigns as CampaignDto[]));
      // Deleting a campaign also removes it from the marketing_campaigns_order on the client.
      // We re-fetch the client so our data is not stale.
      dispatch(fetchClientAction());
      dispatch(
        createAlert({
          message: "Draft deleted.",
          alertType: "success",
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Failed to delete campaign.",
          alertType: "error",
        }),
      );

      throw error;
    }
  };
}

export function saveCampaignAction(
  campaign: Partial<CampaignDto>,
  successMessage: string | null = null,
): ThunkAction {
  return async (dispatch) => {
    try {
      const response = await adaAPI.request({
        method: "POST",
        url: "/campaigns/",
        data: campaign,
      });
      dispatch(updateCampaignsAction(response.data.campaigns as CampaignDto[]));
      // Creating a new campaign also adds it to the marketing_campaigns_order on the client.
      // We re-fetch the client so our data is not stale.
      // Technically this is only necessary when creating a new campaign (not updating an
      // existing one), but we have no way to distinguish between the two cases here.
      dispatch(fetchClientAction());
      dispatch(
        createAlert({
          message: successMessage || "Campaign successfully saved.",
          alertType: "success",
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to save campaign.",
          alertType: "error",
        }),
      );

      throw error;
    }
  };
}

export function softDeleteCampaignAction(campaignId: string): ThunkAction {
  return async (dispatch) => {
    try {
      const response = await adaAPI.request({
        method: "POST",
        url: `/campaigns/soft_delete/${campaignId}`,
      });
      dispatch(updateCampaignsAction(response.data.campaigns as CampaignDto[]));
      // Deleting a campaign also removes it from the marketing_campaigns_order on the client.
      // We re-fetch the client so our data is not stale.
      dispatch(fetchClientAction());
      dispatch(
        createAlert({
          message: "Campaign successfully deleted.",
          alertType: "success",
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to delete campaign.",
          alertType: "error",
        }),
      );

      throw error;
    }
  };
}

export function saveCampaignsOrderAction(
  order: readonly string[],
): ThunkAction {
  return async (dispatch, getState) => {
    const client = selectClient(getState());

    if (!client) {
      throw new Error("`client` is null");
    }

    dispatch(updateClientAction({ marketingCampaignsOrder: order }));

    try {
      await dispatch(saveClientAction());
      dispatch(
        createAlert({
          message: "Campaigns order successfully updated.",
          alertType: "success",
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to update Campaigns order.",
          alertType: "error",
        }),
      );

      throw error;
    }
  };
}

// For compatibility
export { openConfirmationModalAction } from "services/modal";

export function openConfirmDeleteCampaignModalAction(
  campaignId: string,
): ThunkAction {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Delete Draft",
        message:
          "Are you sure you want to delete this Campaign? This can’t be undone.",
        shouldCloseOnMaskClick: false,
        actions: [
          {
            title: "Delete Draft",
            buttonTint: "alert",
            onClick: () => {
              dispatch(deleteCampaignAction(campaignId));
              dispatch(closeModalAction());
              dispatch(
                setPageAction("/answers/campaigns?campaign_unsaved=false"),
              );
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };
}

export function openConfirmPublishCampaignModalAction(
  campaignApiPayload: Record<string, unknown>,
  message: string,
): ThunkAction {
  return (dispatch) => {
    dispatch(
      openConfirmationModalAction({
        title: "Confirm campaign publication",
        message,
        shouldCloseOnMaskClick: false,
        actions: [
          {
            title: "Publish campaign",
            buttonTint: "primary",
            onClick: async () => {
              try {
                await dispatch(
                  saveCampaignAction(
                    { ...campaignApiPayload, status: "active" } as CampaignDto,
                    "Your Campaign is published and live.",
                  ),
                );
                dispatch(setPageAction(CAMPAIGN_PAGE_AFTER_SAVE_ROUTE));
              } catch {
                // we dont care about the error, we just want to close the modal
              }

              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };
}

export function openConfirmDisableCampaignModalAction(
  campaign: Campaign,
): ThunkAction {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Disable Campaign",
        message:
          "Disabling this campaign means data will no longer be collected. You can always republish later.",
        shouldCloseOnMaskClick: false,
        actions: [
          {
            title: "Disable Campaign",
            buttonTint: "alert",
            onClick: () => {
              dispatch(
                saveCampaignAction(
                  serializeCampaign(campaign, { status: "off" }),
                  "Campaign disabled.",
                ),
              );
              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };
}

export function openConfirmSoftDeleteCampaignModalAction(
  campaignId: string,
): ThunkAction {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Delete Campaign",
        message:
          "Deleting this Campaign will remove all references to it, including reporting.",
        shouldCloseOnMaskClick: false,
        actions: [
          {
            title: "Delete Campaign",
            buttonTint: "alert",
            onClick: () => {
              dispatch(softDeleteCampaignAction(campaignId));
              dispatch(closeModalAction());
              dispatch(setPageAction("/answers/campaigns"));
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };
}

// Used by Campaigns list/table to persist user filters
export const setFiltersAction = (filters: Set<string>) => ({
  type: SET_CAMPAIGNS_FILTERS,
  filters,
});

// Used by Campaigns list/table to persist user search query
export const setSearchQueryAction = (searchQuery: string) => ({
  type: SET_CAMPAIGNS_SEARCH_QUERY,
  searchQuery,
});

// TODO: use this type instead of Record<string, unknown> in sendProactiveSmsCampaignAction
// export interface TriggerProactiveSmsProps {
//   language: string;
//   campaign_key: string;
//   recipients: {
//     meta_fields: Record<string, unknown>;
//   }[];
// }

export function sendProactiveSmsCampaignAction(
  data: Record<string, unknown>,
): ThunkAction {
  return async (dispatch) => {
    try {
      await adaAPI.request({
        method: "POST",
        url: "/campaigns/sms/trigger/internal",
        data,
      });
      dispatch(
        createAlert({
          message: "Campaign successfully triggered.",
          alertType: "success",
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to trigger campaign.",
          alertType: "error",
        }),
      );

      throw error;
    }
  };
}
