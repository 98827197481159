import { type AnyAction } from "@reduxjs/toolkit";
import Immutable from "immutable";

export class ResponseEditorTrainerPageState extends Immutable.Record({
  expressionsCount: Immutable.Map({ count: 0 }),
}) {}

function incrementExpressionsCount(
  state: ResponseEditorTrainerPageState,
  expressionLanguage: string,
) {
  const newState = state.updateIn(
    ["expressionsCount", "count"],
    (count) => count + 1,
  );

  return newState.updateIn(
    ["expressionsCount", expressionLanguage || "unknown"],
    (count = 0) => count + 1,
  );
}

function decrementExpressionsCount(
  state: ResponseEditorTrainerPageState,
  expressionLanguage: string,
) {
  const newState = state.updateIn(
    ["expressionsCount", "count"],
    (count) => count - 1,
  );

  return newState.updateIn(
    ["expressionsCount", expressionLanguage || "unknown"],
    (count) => count - 1,
  );
}

export const responseEditorTrainerPage = (
  state = new ResponseEditorTrainerPageState(),
  action: AnyAction,
): ResponseEditorTrainerPageState => {
  switch (action.type) {
    case "SET_RESPONSE_EDITOR_TRAINER_PAGE":
      return state.merge(action.payload);

    case "GET_TRAINED_EXPRESSIONS_COUNT_SUCCESS":
      return state.merge({
        expressionsCount: Immutable.Map(action.response.data),
      });

    case "MOVE_EXPRESSION_SUCCESS":
    // falls through

    case "CREATE_EXPRESSION_SUCCESS": {
      const expressionLanguage = action.response.data.expression.language;

      return incrementExpressionsCount(state, expressionLanguage);
    }

    case "CREATE_EXPRESSIONS_SUCCESS": {
      const createdExpressions = action.response.data.created_expressions;
      let newState = state;

      createdExpressions.forEach(({ language }: { language: string }) => {
        newState = incrementExpressionsCount(newState, language);
      });

      return newState;
    }

    case "MOVE_ALL_EXPRESSIONS_SUCCESS":
    // falls through

    case "MOVE_EXPRESSIONS_SUCCESS": {
      const movedExpressions = action.response.data.updated_expressions;

      let newState = state;

      movedExpressions.forEach(({ language }: { language: string }) => {
        newState = decrementExpressionsCount(newState, language);
      });

      return newState;
    }

    case "DELETE_EXPRESSIONS_SUCCESS": {
      const deletedExpressions = action.response.data.deleted_expressions;

      let newState = state;

      deletedExpressions.forEach(({ language }: { language: string }) => {
        newState = decrementExpressionsCount(newState, language);
      });

      return newState;
    }

    case "PATCH_EXPRESSION_SUCCESS": {
      const { previousLanguage, language } = action.payload;

      if (previousLanguage === language) {
        return state;
      }

      let newState = state;

      newState = decrementExpressionsCount(newState, previousLanguage);
      newState = incrementExpressionsCount(newState, language);

      return newState;
    }

    case "PATCH_EXPRESSION_LANGUAGE_SUCCESS": {
      const { previousLanguage, language } = action.payload;

      let newState = state;

      newState = decrementExpressionsCount(newState, previousLanguage);
      newState = incrementExpressionsCount(newState, language);

      return newState;
    }

    default:
      return state;
  }
};
