import { createAlert } from "actions/alerts";
import { AdaApiSlice } from "slices";
import { KnowledgeApi } from "slices/knowledge/knowledgeApiSlice";

interface BaseKBIntegration {
  INTEGRATION: string;
  subdomain: string;
  _cls: string;
  languages: string[];
  unsupported_languages: string[];
}

export interface ZendeskKbIntegration extends BaseKBIntegration {}

export interface SalesforceKBIntegration extends BaseKBIntegration {
  domain: string;
  support_domain: string;
  consumer_id: string;
  consumer_secret: string;
  channels: string[];
  body_label: string;
}

export interface ConfluenceKBIntegration extends BaseKBIntegration {
  spaces_include?: string[];
  spaces_exclude?: string[];
  client_key: string;
  client_secret: string;
}

export interface PinterestKBIntegration extends BaseKBIntegration {
  categories_variable_id: string;
  base_url: string;
  languages_url: string;
  auth_code: string;
}

interface KBSettingsResponse {
  last_sync_start: string;
  last_sync_finish: string | undefined;
  sync_status: "idle" | "ongoing" | "synced" | "pending";
  languages: string[];
  integrations: Array<
    | ZendeskKbIntegration
    | SalesforceKBIntegration
    | ConfluenceKBIntegration
    | PinterestKBIntegration
  >;
  generative_replies: boolean;
  sync_error_message: string | undefined;
}

const AdaApiSliceWithTags = AdaApiSlice.enhanceEndpoints({
  addTagTypes: ["GetLegacyIntegrations"],
});

export const LegacyIntegrationsApi = AdaApiSliceWithTags.injectEndpoints({
  endpoints: (build) => ({
    getLegacyIntegrations: build.query({
      query: () => ({
        url: "/knowledgebase/",
        method: "GET",
      }),
      providesTags: ["GetLegacyIntegrations"],
      transformResponse: (response: {
        settings: KBSettingsResponse | undefined;
      }) => ({
        lastSyncFinish: response.settings?.last_sync_finish,
        integrations: response.settings?.integrations || [],
      }),
    }),
    syncLegacyIntegrations: build.query({
      query: ({ force }) => ({
        url: "/knowledgebase/sync",
        method: "GET",
        params: force ? { force } : undefined,
      }),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            createAlert({
              message: "Failed to sync knowledge base integration.",
              alertType: "error",
            }),
          );
        }
      },
    }),
    connectLegacyIntegration: build.mutation({
      query: ({ integration, payload }) => ({
        url: `/knowledgebase/integrations/${integration}`,
        method: "POST",
        data: payload,
      }),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            LegacyIntegrationsApi.util.invalidateTags([
              "GetLegacyIntegrations",
            ]),
          );
          dispatch(KnowledgeApi.util.invalidateTags(["GetSources"]));
        } catch (error) {
          dispatch(
            createAlert({
              message:
                "Couldn't connect to your knowledge base. Please try again.",
              alertType: "error",
            }),
          );
        }
      },
    }),
    disconnectLegacyIntegration: build.mutation({
      query: ({ integrationIndex }) => ({
        url: `/knowledgebase/integrations/${integrationIndex}`,
        method: "DELETE",
      }),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            createAlert({
              message: "Successfully disconnected from your knowledge base.",
              alertType: "success",
            }),
          );
          dispatch(
            LegacyIntegrationsApi.util.invalidateTags([
              "GetLegacyIntegrations",
            ]),
          );
        } catch (error) {
          dispatch(
            createAlert({
              message: "Failed to disconnect knowledge base integration.",
              alertType: "error",
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useGetLegacyIntegrationsQuery,
  useLazySyncLegacyIntegrationsQuery,
  useConnectLegacyIntegrationMutation,
  useDisconnectLegacyIntegrationMutation,
} = LegacyIntegrationsApi;
