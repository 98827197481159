import styled, { css } from "styled-components";

const InputRange = styled.input<{ stretch: boolean }>`
  ${(p) =>
    p.stretch &&
    css`
      width: 100%;
    `}
`;
export const Root = InputRange;
