// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */

import Immutable, { type List, type Map, type Record } from "immutable";

import { type Modality } from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type MessagesMap } from "services/responses";

export const ResponseAttributes: ResponseAttributesShape = {
  id: null as unknown as string,
  type: null,
  isProcess: false,
  automaticQuickReplies: false,
  buttonLabel: Immutable.Map({}), // Multilingual clarification
  canBeQuickReply: false,
  clarifications: true,
  clientId: null,
  created: null,
  customizable: true,
  descriptiveString: "",
  handle: "",
  hasForcedQuickReplies: false,
  hasButtonLabel: true,
  description: "",
  messages: Immutable.Map(),
  messagesVoice: Immutable.Map(),
  messagesTranslationPreview: Immutable.Map(),
  messagesVoiceTranslationPreview: Immutable.Map(),
  rating: null,
  reserved: false,
  reservedFor: null,
  tags: Immutable.List([]),
  updated: null,
  saved: true,
  new: false,
  isDirty: false,
  isValid: true,
  live: false,
  liveVoice: false,
  reviewable: false,
  hasTraining: true,
  hasQuickReply: true,
  reservedResponseType: null,
  // `restoringFromVersionId` = if we are restoring from deleted, the version_id we are restoring
  restoringFromVersionId: "",
  // To identify the parent folder this response exists in
  parentId: "",
  rules: "",
  reachableWhen: Immutable.List([]),
};

export class ResponseRecord extends Immutable.Record(ResponseAttributes) {
  [key: string]: any; // necessary because we sometimes access attributes using []
}

interface ReachableWhen {
  variable: string;
  value: string;
}

export interface ResponseAttributesShape {
  id: string;
  type: string;
  isProcess: boolean;
  automaticQuickReplies: boolean;
  buttonLabel: Map<any, any>; // Multilingual clarification
  canBeQuickReply: boolean;
  clarifications: boolean;
  clientId: string | null;
  created: number | string | null;
  customizable: boolean;
  descriptiveString: string;
  handle: string;
  hasForcedQuickReplies: boolean;
  hasButtonLabel: boolean;
  description: string;
  messages: MessagesMap;
  messagesVoice: MessagesMap;
  rating: number | null;
  reserved: boolean;
  reservedFor: Modality | null;
  tags: List<string>;
  updated: number | null;
  saved: boolean;
  isDirty: boolean;
  isValid: boolean;
  live: boolean;
  liveVoice: boolean;
  reviewable: boolean;
  hasTraining: boolean;
  hasQuickReply: boolean;
  reservedResponseType: string | null;
  restoringFromVersionId: string;
  parentId: string;
  rules: string;
  reachableWhen: List<ReachableWhen>;
  [key: string]: any; // TODO remove this eventually, it's a symptom of older code--that accesses these properties like a map--which needs refactored before this can safely removed.
}

export interface MessageDto {
  has_variables?: boolean;
  _type: string;
  locked?: boolean;
  reviewable_message?: boolean;
  body: string;
  quickReplies?: QuickReplyDto[];
  reachable_when?: ReachableWhen[];
}

export interface QuickReplyDto {
  target: string;
  button_type: string;
  label: null;
}

export interface ResponseMessageDto {
  _id: string;
  client_id: string;
  is_process: boolean;
  _type: string;
  handle: string;
  created: number | null;
  updated: number | null;
  button_label: Record<string, unknown>;
  reserved: boolean;
  reserved_for: Modality | null;
  rating: number;
  messages: { [key: string]: MessageDto[] };
  messages_voice: { [key: string]: MessageDto[] };
  tags: string[];
  description: string;
  reviewable: boolean;
  clarifications: boolean;
  live: boolean;
  live_voice: boolean;
  has_forced_quick_replies: boolean;
  has_training: boolean;
  has_quick_reply: boolean;
  has_button_label: boolean;
  can_add_messages: boolean;
  can_be_quick_reply: boolean;
  variable_usage_references: any[];
  parent_id: string;
  customizable: boolean;
}

export interface MessageRecordOptions {
  type: string;
  displayType: string;
  body: string;
  actionIntegrationId: string | null;
  appId: string | null;
  description: string;
  iconUrl: string;
  integrationName: string;
  handoffFields: Record<string, unknown>;
  tags: string;
}
