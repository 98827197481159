import styled from "styled-components";

import { colorPrimitives, v } from "constants/css";

export const AppContentContainer = styled.div`
  background-color: ${colorPrimitives.white};
  width: 100%;
  height: 100vh;
  display: flex;
  border-radius: ${v.space2} 0 0 ${v.space2};

  .no-sidebar & {
    border-radius: 0;
  }
`;
