import * as Immutable from "immutable";

class AnalyticsPageState extends Immutable.Record({
  lastUpdatedTimestamp: "",
}) {}

interface AnalyticsPageAction {
  type: string;
  value: string;
}

const analyticsPage = (
  state = new AnalyticsPageState(),
  action: AnalyticsPageAction,
): AnalyticsPageState => {
  switch (action.type) {
    case "SET_ANALYTICS_LAST_UPDATED_TIMESTAMP": {
      return state.set("lastUpdatedTimestamp", action.value);
    }

    default: {
      return state;
    }
  }
};

export { analyticsPage, AnalyticsPageState };
