import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addGoalAction } from "actions/goals";
import { closeModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { NO_OP_FUNCTION } from "services/helpers";
import SvgImage from "source/components/Common/SvgImage";
import ModalImage from "static/images/create_new.svg";

import * as S from "./styles";

const MAX_GOAL_NAME_LENGTH = 60;

interface Props {
  isNew: boolean;
  initialName?: string;
  initialDescription?: string;
  onSave?: (name: string, description: string) => void;
}

export function CreateGoalModal({
  isNew,
  initialName = "",
  initialDescription = "",
  onSave = NO_OP_FUNCTION,
}: Props) {
  const { goals } = useSelector((state) => state.goalsState);
  const [name, setName] = useState(initialName || "");
  const [description, setDescription] = useState(initialDescription || "");
  const dispatch = useDispatch();

  const isNameAlreadyUsed = goals.some((goal) => goal.name === name);
  const isNameInvalid =
    !name.trim() || isNameAlreadyUsed || name.length > MAX_GOAL_NAME_LENGTH;

  return (
    <div className="Modal__modal Modal__modal--small">
      <div className="Modal__content">
        <S.ModalSection>
          <S.ModalImageContainer>
            <SvgImage imageHash={ModalImage} />
          </S.ModalImageContainer>
          <div>
            {isNew && <S.ModalTitle>Define a new Goal</S.ModalTitle>}
            {!isNew && <S.ModalTitle>Edit Goal</S.ModalTitle>}
            <S.ModalDescription>
              Give your Goal a name and description to make it clear what
              you&apos;re measuring.
            </S.ModalDescription>
          </div>
        </S.ModalSection>
        <S.ModalSection>
          <DebouncedInput
            label="Name"
            id="goalName"
            value={name}
            isInvalid={isNameInvalid}
            placeholder="Overdue Accounts flow"
            characterCounter={MAX_GOAL_NAME_LENGTH}
            onChange={setName}
          />
        </S.ModalSection>
        <S.ModalSection>
          <DebouncedInput
            label={
              <>
                <span>Description</span>{" "}
                <S.ModalOptional>(Optional)</S.ModalOptional>
              </>
            }
            id="goalDescription"
            placeholder="Track how many people are clicking the link to pay off their outstanding bills, or set up payment-arrangements"
            value={description}
            onChange={(newValue: string) => setDescription(newValue)}
            isTextArea
          />
        </S.ModalSection>
      </div>

      <S.ButtonsContainer className="Modal__bottom">
        <Button
          light
          onClick={() => {
            dispatch(closeModalAction());
          }}
          text="Cancel"
          title="Cancel"
          disabled={false}
        />
        {isNew && (
          <Button
            onClick={() => {
              dispatch(addGoalAction(name, description));
              dispatch(setPageAction("/analytics/goals/new"));
              dispatch(closeModalAction());
            }}
            text="Next"
            title="Next"
            disabled={isNameInvalid}
          />
        )}
        {!isNew && (
          <Button
            onClick={() => {
              onSave(name, description);
              dispatch(closeModalAction());
            }}
            text="Update"
            title="Update"
            disabled={isNameInvalid}
          />
        )}
      </S.ButtonsContainer>
    </div>
  );
}
