import type Immutable from "immutable";

import { AppIntegrationRecord } from "reducers/appIntegrations/types";
import { adaAPI } from "services/api";
import { selectClient } from "services/client";
import { type ThunkAction } from "types";

export function fetchAppIntegrationsAction(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { appIntegrationsState } = state;
    const { loading, loaded } = appIntegrationsState;
    const client = selectClient(state);

    if (loading || loaded || !client) {
      return;
    }

    if (!client.features.dynamic_ada_apps) {
      dispatch({ type: "SET_EMPTY_APP_INTEGRATIONS" });

      return;
    }

    dispatch({ type: "FETCH_APP_INTEGRATIONS_REQUEST" });

    adaAPI.request({ method: "GET", url: "/ada-apps" }).then(
      (response) => {
        dispatch({
          type: "FETCH_APP_INTEGRATIONS_SUCCESS",
          appsList: response.data.ada_apps,
        });
      },
      () => dispatch({ type: "FETCH_APP_INTEGRATIONS_FAILURE" }),
    );
  };
}

export function getAppIntegrationById(
  appId: string,
  appIntegrations: Immutable.List<AppIntegrationRecord>,
) {
  const defaultReturnValue = new AppIntegrationRecord({
    display_name: "App integration not found",
    description: "App integration not found",
  });

  return (
    appIntegrations.find((elem) => elem.id === appId) || defaultReturnValue
  );
}
