export const BROWSERS = [
  {
    id: "chrome",
    name: "Chrome",
  },
  {
    id: "firefox",
    name: "Firefox",
  },
  {
    id: "safari",
    name: "Safari",
  },
  {
    id: "msie",
    name: "Internet Explorer",
  },
  {
    id: "opera",
    name: "Opera",
  },
  {
    id: "edge",
    name: "Microsoft Edge",
  },
  {
    id: "webkit",
    name: "WebKit-based",
  },
  {
    id: "unknown",
    name: "Unknown",
  },
];

export const DEVICES = [
  {
    id: "windows",
    name: "Windows",
  },
  {
    id: "android",
    name: "Android",
  },
  {
    id: "macos",
    name: "Mac OS",
  },
  {
    id: "iphone",
    name: "iPhone",
  },
  {
    id: "ipad",
    name: "iPad",
  },
  {
    id: "linux",
    name: "Linux",
  },
  {
    id: "chromeos",
    name: "Chrome OS",
  },
  {
    id: "unknown",
    name: "Unknown",
  },
];

export const CHANNELS = [
  {
    id: "chat",
    name: "Ada Web Chat",
  },
  {
    id: "messenger",
    name: "Facebook Messenger",
  },
  {
    id: "whatsapp",
    name: "WhatsApp",
  },
  {
    id: "sms",
    name: "SMS",
  },
  {
    id: "mobile_sdk",
    name: "Mobile SDK",
  },
];
