/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import React from "react";

import { Button } from "components/Common/Button";

import "./style.scss";

const BLOCK_NAME = "ButtonGroupMultiSelect";

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
interface ButtonGroupMultiSelectProps {
  values: any[];
  invalidFields: string[];
  isInvalid: boolean;
  options: {
    altText?: any;
    label?: any;
    value?: any;
  }[];
  onChange(...args: unknown[]): unknown;
  customClassName: string;
}

export class ButtonGroupMultiSelect extends React.Component<ButtonGroupMultiSelectProps> {
  /**
   * @param {String} selectedValue
   */
  handleButtonSelect(selectedValue: any) {
    const { values, onChange } = this.props;
    let newValues;

    // if selectedValue exist in values already, remove it
    if (values.indexOf(selectedValue) >= 0) {
      newValues = values.filter((value: unknown) => value !== selectedValue);
    } else {
      newValues = [...values, selectedValue];
    }

    onChange(newValues);
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const { values, options, customClassName, invalidFields, isInvalid } =
      this.props;

    const renderButtons = options.map((button) => {
      const selected = values.indexOf(button.value) >= 0;

      return (
        <Button
          key={button.value}
          title={button.altText || button.value}
          customClassName={classNames(`${BLOCK_NAME}__button`, {
            [`${BLOCK_NAME}__button--active`]: selected,
            [`${BLOCK_NAME}__button--invalid`]: invalidFields.includes(
              button.value,
            ),
          })}
          onClick={() => this.handleButtonSelect(button.value)}
          text={button.label}
        />
      );
    });

    return (
      <div
        className={classNames(BLOCK_NAME, customClassName, {
          [`${BLOCK_NAME}--invalid`]: isInvalid,
        })}
      >
        {renderButtons}
      </div>
    );
  }
}
