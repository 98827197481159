import styled from "styled-components";

import { v } from "constants/css";

export const SidebarContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-width: ${v.sidebarSize};

  @media print {
    min-width: 0;
    width: 0;
  }
`;
