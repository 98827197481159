import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { logoutUser } from "actions";
import { closeModal } from "actions/modal";
import { ModalActionButton } from "components/Common/ModalActionButton";
import {
  EXPIRE_5_MIN,
  EXPIRE_5_SECS,
  PASSWORD_TTL_DAYS,
  TIME_TO_AUTO_LOGOUT_MS,
} from "constants/warnings";
import { User } from "resourceModels";
import { getResource } from "selectors/resources";
import { AdaDateTime } from "services/AdaDateTime";
import "./style.scss";

import { modalKeepOpen } from "./actions";

const EXPIRED_TEXT = (
  <div className="ModalExpiredPassword__details ModalExpiredPassword__expired">
    <p>
      Your password has expired. You will be automatically logged out shortly.
    </p>
    <p>Please re-login and follow instructions.</p>
  </div>
);

export class ModalExpiredPasswordComponent extends React.Component {
  showProfileModalBound = this.showProfileModal.bind(this);

  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    modalLogoutUser: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
    makeUnclosable: PropTypes.func.isRequired,
  };

  /**
   * Converts timestamp from backend into human readable string and determines
   * whether expired text should be shown
   * @returns {Object} contains human readable date and expired flag
   */
  getTimeToExpire() {
    const { makeUnclosable, modalLogoutUser, user } = this.props;
    const passwordCreatedAt = new Date(
      AdaDateTime.secondsToMilliseconds(user.passwordCreatedDate),
    );

    const timeleft = new Date(
      passwordCreatedAt.setDate(
        passwordCreatedAt.getDate() + PASSWORD_TTL_DAYS,
      ),
    );

    if (AdaDateTime.diff(timeleft, Date.now(), "SECONDS") <= EXPIRE_5_SECS) {
      makeUnclosable();

      // Set timeout to automatically boot out user
      setTimeout(() => {
        modalLogoutUser();
      }, TIME_TO_AUTO_LOGOUT_MS);

      return { expired: true, timeleft: AdaDateTime.fromNow(timeleft, true) };
    }

    if (AdaDateTime.diff(timeleft, Date.now(), "MINUTES") <= EXPIRE_5_MIN) {
      return { expired: false, timeleft: AdaDateTime.fromNow(timeleft, true) };
    }

    return {
      expired: false,
      timeleft: AdaDateTime.format(
        timeleft,
        AdaDateTime.DATE_FORMATS.NAMED_MONTH_DATE_YEAR_TIME,
      ),
    };
  }

  /**
   * Shows the Profile Modal
   */
  showProfileModal() {
    const { setState } = this.props;

    setState("MODAL", {
      isOpen: true,
      view: "MODAL_PROFILE",
      modalProps: {},
    });
  }

  /**
   * Returns the valid warning text
   * @returns {String} Warning message
   */
  countdownToExpireText() {
    const { timeleft } = this.getTimeToExpire();

    return (
      <div className="ModalExpiredPassword__details ModalExpiredPassword__countdown">
        {timeleft.includes("minute") || timeleft.includes("seconds") ? (
          <p className="ModalExpiredPassword__warning_minutes_left">
            Your password will expire in <b>{timeleft}</b>. You will be
            automatically logged out and any unsaved changes will be lost.
          </p>
        ) : (
          <p className="ModalExpiredPassword__warning_day_left">
            Your password will expire on <b>{timeleft}</b>. Please update your
            password as soon as possible
          </p>
        )}
        <p>Do you want to change it now?</p>
      </div>
    );
  }

  /**
   * renders the component
   * @returns {React.Component} ModalExpiredPassword
   */
  render() {
    const { modalLogoutUser, onCloseModal } = this.props;

    const { expired } = this.getTimeToExpire();

    return (
      <div className="ModalExpiredPassword Modal_modal">
        <h5 className="Modal__title">
          {expired ? "Password Expired" : "Update Password"}
        </h5>
        <div className="Modal__content">
          <section className="Modal__section ModalExpiredPassword__details">
            {expired ? EXPIRED_TEXT : this.countdownToExpireText()}
          </section>
        </div>
        {expired ? (
          <ModalActionButton
            onClick={modalLogoutUser}
            text="Log Out Now"
            intent="primary"
          />
        ) : (
          <div>
            <ModalActionButton
              onClick={this.showProfileModalBound}
              text="Update Password"
              intent="primary"
            />
            <ModalActionButton onClick={onCloseModal} text="Not Now" />
          </div>
        )}
      </div>
    );
  }
}

ModalExpiredPasswordComponent.isSmall = true;

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapState(state) {
  return {
    user: getResource(state, "user"),
  };
}

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatch(dispatch) {
  return {
    onCloseModal: bindActionCreators(closeModal, dispatch),
    modalLogoutUser: bindActionCreators(logoutUser, dispatch),
    makeUnclosable: bindActionCreators(modalKeepOpen, dispatch),
  };
}

export const ModalExpiredPassword = connect(
  mapState,
  mapDispatch,
)(ModalExpiredPasswordComponent);
