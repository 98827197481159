import { Checkbox } from "@adasupport/byron";
import React, { useEffect, useState } from "react";

import { RadioButton } from "components/Common/RadioButton";

import { type ArticleChannel, CHANNELS } from "./constants";
import * as S from "./styles";

interface Props {
  articleChannels: ArticleChannel;
  setArticleChannels: (articleChannel: ArticleChannel) => void;
  existingIntegration: boolean;
}

export const SmartSearchSalesforceChannelSelector = ({
  articleChannels,
  setArticleChannels,
  existingIntegration,
}: Props) => {
  const [showAllArticles, setShowAllArticles] = useState(true);

  const handleChannelChange = (channel: string, value: boolean) => {
    setArticleChannels({
      ...articleChannels,
      [channel]: value,
    });
  };

  const handleChannelReset = () => {
    setShowAllArticles(true);
    setArticleChannels({
      [CHANNELS.PUBLIC_KNOWLEDGEBASE]: true,
      [CHANNELS.CUSTOMER_PORTAL]: true,
      [CHANNELS.PARTNER_PORTAL]: true,
    });
  };

  useEffect(() => {
    if (Object.values(articleChannels).indexOf(false) !== -1) {
      setShowAllArticles(false);
    }
  }, [articleChannels]);

  const channelSelectionDisabled = showAllArticles || existingIntegration;

  return (
    <section>
      <S.Divider />
      <S.SectionTitle>
        <S.OptionalLabelContainer>
          Show only specific articles <span>(Optional)</span>
        </S.OptionalLabelContainer>
      </S.SectionTitle>
      <p className="Modal__section__sub-section__description">
        You can filter which articles from Salesforce will sync to the Knowledge
        page.
      </p>
      <S.RadioButtonsBlock>
        <S.RadioButtonRow>
          <RadioButton
            label="Show all articles"
            id="showAllArticles"
            name="showAllArticles"
            checked={showAllArticles}
            onClick={() => handleChannelReset()}
            disabled={existingIntegration}
          />
        </S.RadioButtonRow>
        <S.RadioButtonRow>
          <RadioButton
            label="Show only articles from a specific channel"
            id="showSpecificArticles"
            name="showSpecificArticles"
            checked={!showAllArticles}
            onClick={() => {
              setShowAllArticles(false);
            }}
            disabled={existingIntegration}
          />
        </S.RadioButtonRow>
      </S.RadioButtonsBlock>
      <S.CheckboxesBlock>
        <S.CheckboxRow>
          <Checkbox
            onChange={(value) =>
              handleChannelChange(CHANNELS.PUBLIC_KNOWLEDGEBASE, value)
            }
            isChecked={articleChannels[CHANNELS.PUBLIC_KNOWLEDGEBASE]}
            isDisabled={channelSelectionDisabled}
            dataTestId="visibleInThePublicKnowledgebasePkb"
          />
          <S.CheckboxLabel isDisabled={channelSelectionDisabled}>
            Visible in the Public Knowledgebase (Pkb)
          </S.CheckboxLabel>
        </S.CheckboxRow>
        <S.CheckboxRow>
          <Checkbox
            onChange={(value) =>
              handleChannelChange(CHANNELS.CUSTOMER_PORTAL, value)
            }
            isChecked={articleChannels[CHANNELS.CUSTOMER_PORTAL]}
            isDisabled={channelSelectionDisabled}
            dataTestId="visibleInTheCustomerPortalCsp"
          />
          <S.CheckboxLabel isDisabled={channelSelectionDisabled}>
            Visible in the Customer Portal (Csp)
          </S.CheckboxLabel>
        </S.CheckboxRow>
        <S.CheckboxRow>
          <Checkbox
            onChange={(value) =>
              handleChannelChange(CHANNELS.PARTNER_PORTAL, value)
            }
            isChecked={articleChannels[CHANNELS.PARTNER_PORTAL]}
            isDisabled={channelSelectionDisabled}
            dataTestId="visibleInThePartnerPortalPrm"
          />
          <S.CheckboxLabel isDisabled={channelSelectionDisabled}>
            Visible in the Partner Portal (Prm)
          </S.CheckboxLabel>
        </S.CheckboxRow>
      </S.CheckboxesBlock>
    </section>
  );
};
