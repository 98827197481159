import Immutable from "immutable";

import {
  PASSWORD_EXPIRED_WARNING,
  PASSWORD_EXPIRE_24_HOURS_WARNING,
  PASSWORD_EXPIRE_5_MINS_WARNING,
} from "constants/warnings";
import { storage } from "services/storage";

const initialState = Immutable.Map({
  [PASSWORD_EXPIRE_24_HOURS_WARNING]: false,
  [PASSWORD_EXPIRE_5_MINS_WARNING]: false,
  [PASSWORD_EXPIRED_WARNING]: false,
});

const storedState = Immutable.fromJS(storage.retrieve("warnings"));
const mergedState = storedState || initialState;

/**
 * @param {Immutable.Map<string, unknown>} state
 * @param {unknown} action
 * @returns {Immutable.Map<string, unknown>}
 */
export const warnings = (state = mergedState, action) => {
  switch (action.type) {
    case "WARNING_NONE":
      return state;
    case "WARNING_CLEAR_ALL":
      return initialState;
    case "WARNING_SHOWN":
      return state.set(action.name, true);
    case "WARNING_CLEAR":
      return state.set(action.name, false);
    default:
      return state;
  }
};
