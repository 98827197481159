/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector } from "reselect";

import {
  createGetAllMessageTemplatesSelector,
  createGetAllResourcesRequestSelector,
  createGetPatchOneMessageTemplatesRequestSelector,
  createGetResourceRecordsSelector,
} from "./resources";

const getMessageTemplatesRecords =
  createGetResourceRecordsSelector("messageTemplate");

export const getAllMessageTemplatesRequest =
  createGetAllResourcesRequestSelector("messageTemplate");
export const getPatchOneMessageTemplatesRequest =
  createGetPatchOneMessageTemplatesRequestSelector("messageTemplate");
export const getAllMessageTemplates = createGetAllMessageTemplatesSelector(
  "messageTemplate",
  getMessageTemplatesRecords,
  getAllMessageTemplatesRequest,
);

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
export const getSurveyMessageTemplate = createSelector(
  getAllMessageTemplates,
  (messageTemplates: any) =>
    messageTemplates
      ? messageTemplates.find((mt: any) => mt.templateType === "survey")
      : null,
);
