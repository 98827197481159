interface AppleConnection {
  signing_key?: string;
  signing_key_id?: string;
  team_id?: string;
  bundle_id?: string;
  launch_schema_url?: string;
}

interface FirebaseConnection {
  api_key?: string;
}

export interface PushConnection {
  apple: AppleConnection;
  firebase: FirebaseConnection;
}

export interface PushNotificationSettings extends PushConnection {
  loaded: boolean;
  loading: boolean;
}

export const pushNotificationSettingsInitialState = {
  loading: false,
  loaded: false,
  firebase: {
    api_key: "",
  },
  apple: {
    signing_key: "",
    signing_key_id: "",
    team_id: "",
    bundle_id: "",
    launch_schema_url: "",
  },
};

interface Action {
  type:
    | "FETCH_PUSH_NOTIFICATION_SETTINGS_SUCCESS"
    | "FETCH_PUSH_NOTIFICATION_SETTINGS_REQUEST";
  pushNotificationSettings: {
    [key: string]: string;
  };
}

export const pushNotificationSettings = (
  state: PushNotificationSettings = pushNotificationSettingsInitialState,
  action: Action,
): PushNotificationSettings => {
  const updatedSettings = action.pushNotificationSettings;

  switch (action.type) {
    case "FETCH_PUSH_NOTIFICATION_SETTINGS_REQUEST":
      return { ...state, ...updatedSettings, loading: true, loaded: false };

    case "FETCH_PUSH_NOTIFICATION_SETTINGS_SUCCESS":
      return { ...state, ...updatedSettings, loading: false, loaded: true };
    default:
      return state;
  }
};
