import Immutable from "immutable";
import moment from "moment-timezone";

import { CSATSatisfactionStyle, type CsatResultAttributes } from "./types";

export * from "./types";

export const CSAT_MESSAGE_SHOWN_TYPE = "csat_shown";
export const CSAT_MESSAGE_TYPE = "csat";

export function getTimeStringFromTimestamp(timestamp: number | string): string {
  if (typeof timestamp === "number") {
    return moment.unix(timestamp).format("LTS");
  }

  return moment(timestamp).format("LTS");
}

export class CsatResult extends Immutable.Record<CsatResultAttributes>({
  style: CSATSatisfactionStyle.NUMERIC_TEN,
  score: 0,
  originalScore: null,
  surveyType: null,
  feedback: [],
  isPositive: false,
  resolved: null,
  comment: "",
}) {}
