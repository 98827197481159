// @ts-strict-ignore
import { type TagType } from "reducers/tags/types";

let lastSavedTags: TagType[];

function updateTagInList(tags: TagType[], updatedTag: Partial<TagType>) {
  return tags.map((tag) =>
    tag.id === updatedTag.id ? { ...tag, ...updatedTag } : tag,
  );
}

interface TagPayload {
  _id: string;
  color: string;
  name: string;
}

function deserializeTag(tag: TagPayload) {
  return {
    id: tag._id,
    color: tag.color,
    name: tag.name,
  };
}

type Action =
  | { type: "SET_TAG"; payload: { id: string } }
  | { type: "UPDATE_TAG_SUCCESS"; response: { data: { tag: TagPayload } } }
  | { type: "UPDATE_TAG_FAILURE"; tagId: string }
  | { type: "GET_TAGS_SUCCESS"; tags: TagPayload[] }
  | { type: "CREATE_TAG_SUCCESS"; response: { data: { tag: TagPayload } } }
  | { type: "DELETE_TAG_SUCCESS"; response: { data: { tag: TagPayload } } };

export const tags = (state: TagType[] = [], action: Action) => {
  switch (action.type) {
    case "SET_TAG":
      return updateTagInList(state, action.payload);

    case "UPDATE_TAG_SUCCESS": {
      const nextState = updateTagInList(
        state,
        deserializeTag(action.response.data.tag),
      );
      lastSavedTags = [...nextState];

      return nextState;
    }

    case "UPDATE_TAG_FAILURE":
      return updateTagInList(
        state,
        lastSavedTags.find((tag) => tag.id === action.tagId),
      );

    case "GET_TAGS_SUCCESS": {
      const nextState = action.tags.map((tag) => deserializeTag(tag));
      lastSavedTags = [...nextState];

      return nextState;
    }

    case "CREATE_TAG_SUCCESS": {
      const nextState = [...state, deserializeTag(action.response.data.tag)];
      lastSavedTags = [...nextState];

      return nextState;
    }

    case "DELETE_TAG_SUCCESS": {
      const nextState = state.filter(
        (tag) => tag.id !== action.response.data.tag._id,
      );
      lastSavedTags = [...nextState];

      return nextState;
    }

    default:
      return state;
  }
};
