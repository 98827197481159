import { type AnyAction, createSlice } from "@reduxjs/toolkit";

import { FilterOptions } from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorTrainer/ExpressionLanguageFilter/FilterOptions";
import {
  fetchMoreTraining,
  fetchTraining,
} from "slices/training/fetchTraining";
import {
  type SerializedTrainedExpression,
  type TrainedExpression,
  type TrainingState,
} from "slices/training/types";

import {
  deserializeTrainedExpression,
  deserializeTraining,
} from "./deserialization";

export const initialState: TrainingState = {
  loading: false,
  loaded: [],
  languageFilter: FilterOptions.ALL.value,
};

const updateTraining = (
  training: TrainedExpression[],
  updatedTrainingData: SerializedTrainedExpression[],
) =>
  training.map((loadedExpression) => {
    const updatedVersion = updatedTrainingData.find(
      (updatedExpressionData) =>
        updatedExpressionData._id === loadedExpression.id,
    );

    if (!updatedVersion) return loadedExpression;

    return deserializeTrainedExpression(updatedVersion);
  });

const isFilteringOnAnotherLanguage = (
  state: TrainingState,
  language: string | null,
) => {
  if (state.languageFilter === FilterOptions.ALL.value) {
    return false;
  }

  if (
    state.languageFilter === FilterOptions.UNKNOWN.value &&
    language === null
  ) {
    return false;
  }

  return language !== state.languageFilter;
};

const trainingSlice = createSlice({
  name: "training",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTraining.pending, (state) => ({
        ...state,
        loading: true,
        loaded: [],
      }))
      .addCase(fetchTraining.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loaded: action.payload.training,
        languageFilter: action.payload.languageFilter,
      }))
      .addCase(fetchMoreTraining.fulfilled, (state, action) => ({
        ...state,
        loaded: [...state.loaded, ...action.payload],
      }))
      .addCase("CREATE_EXPRESSION_SUCCESS", (state, action: AnyAction) => {
        const newExpression: TrainedExpression = deserializeTrainedExpression(
          action.response.data.expression,
        );

        if (isFilteringOnAnotherLanguage(state, newExpression.language)) {
          return state;
        }

        return {
          ...state,
          loaded: [newExpression, ...state.loaded],
        };
      })
      .addCase("CREATE_EXPRESSIONS_SUCCESS", (state, action: AnyAction) => {
        const newExpressions: TrainedExpression[] = deserializeTraining(
          action.response.data.created_expressions,
        );

        const expressionsToLoad = newExpressions.filter(
          ({ language }) => !isFilteringOnAnotherLanguage(state, language),
        );

        return {
          ...state,
          loaded: [...expressionsToLoad, ...state.loaded],
        };
      })
      // moves a trained expression from another Response to the current one
      .addCase("MOVE_EXPRESSION_SUCCESS", (state, action: AnyAction) => {
        const newExpression: TrainedExpression = deserializeTrainedExpression(
          action.response.data.expression,
        );

        if (isFilteringOnAnotherLanguage(state, newExpression.language)) {
          return state;
        }

        return {
          ...state,
          loaded: [newExpression, ...state.loaded],
        };
      })
      // moves trained expressions to a different Response
      .addCase("MOVE_EXPRESSIONS_SUCCESS", (state, action: AnyAction) => {
        const movedTrainingIds = action.response.data.updated_expressions.map(
          (expressionData: SerializedTrainedExpression) => expressionData._id,
        );

        return {
          ...state,
          loaded: state.loaded.filter(
            (trainedExpression) =>
              !movedTrainingIds.includes(trainedExpression.id),
          ),
        };
      })
      // moves all trained expressions to a different Response
      .addCase("MOVE_ALL_EXPRESSIONS_SUCCESS", (state) => ({
        ...state,
        loaded: [],
      }))
      .addCase("DELETE_EXPRESSIONS_SUCCESS", (state, action: AnyAction) => {
        const deletedTrainingIds = action.response.data.deleted_expressions.map(
          (expressionData: SerializedTrainedExpression) => expressionData._id,
        );

        return {
          ...state,
          loaded: state.loaded.filter(
            (trainedExpression) =>
              !deletedTrainingIds.includes(trainedExpression.id),
          ),
        };
      })
      .addCase("PATCH_EXPRESSION_SUCCESS", (state, action: AnyAction) => ({
        ...state,
        loaded: updateTraining(
          state.loaded,
          action.response.data.updatedExpressions,
        ),
      }))
      .addCase(
        "PATCH_EXPRESSION_LANGUAGE_SUCCESS",
        (state, action: AnyAction) => {
          const updatedTraining = updateTraining(
            state.loaded,
            action.response.data.updatedExpressions,
          );

          return {
            ...state,
            loaded: updatedTraining.filter(
              ({ language }) => !isFilteringOnAnotherLanguage(state, language),
            ),
          };
        },
      );
  },
});

export const { reducer: trainingReducer } = trainingSlice;
