// @ts-strict-ignore
import Immutable from "immutable";

import RecordClassCreator, {
  type ManyResourcePayload,
  type SingleResource,
  type SingleResourcePayload,
} from "services/record";

export type UserRole = "owner" | "admin" | "read_only" | "agent";

export interface UserAttributes {
  authyEnabled?: boolean;
  clientId: string;
  email?: string;
  emails?: Immutable.Map<string, unknown>;
  isLoggedIn?: boolean;
  name?: string;
  passwordCreatedDate?: string;
  profilePicture?: string;
  role?: UserRole | null;
  created?: string;
  ssoLogin?: boolean;
  timeZone?: string;
}

export class User extends RecordClassCreator<UserAttributes>({
  type: "user",
  authyEnabled: null,
  clientId: null,
  email: "",
  emails: Immutable.Map(),
  isLoggedIn: false,
  name: "",
  passwordCreatedDate: null,
  profilePicture: "",
  role: null,
  created: null,
  ssoLogin: false,
  timeZone: null,
}) {
  onUpdate(): this {
    return this;
  }

  get isAdmin(): boolean {
    return this.role === "admin";
  }

  get isOwner(): boolean {
    return this.role === "owner";
  }

  get isReadOnly(): boolean {
    return this.role === "read_only";
  }

  get invalidFields(): Immutable.List<string> {
    const result = [];

    if (!this.name) {
      result.push("name");
    }

    return Immutable.List(result);
  }

  static getFetchOneUrl(): string {
    return "/users/me";
  }

  static getSaveOneURL(id: string): string {
    return `/users/${id}/`;
  }

  static getDeleteOneURL(id: string): string {
    return `/users/${id}/`;
  }

  static resolveCreateOneResult(payload: {
    user: SingleResource;
  }): SingleResourcePayload {
    return {
      data: payload.user,
    };
  }

  static resolveFetchOneResult(payload: {
    user: SingleResource;
  }): SingleResourcePayload {
    return {
      ...payload,
      data: {
        ...payload.user,
        is_logged_in: true,
      },
    };
  }

  static resolveFetchManyResult(payload: {
    users: SingleResource[];
  }): ManyResourcePayload {
    return {
      ...payload,
      data: payload.users,
    };
  }

  static resolveSaveOneResult(payload: {
    user: SingleResource;
  }): SingleResourcePayload {
    return {
      ...payload,
      data: payload.user,
    };
  }
}
