import { bindActionCreators } from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";

import { deletePlatform, saveClientPlatform, updatePlatform } from "actions";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputClearable } from "components/Common/InputClearable";

import SettingsPlatformsLAModal from "..";

class SettingsPlatformsNuanceLAModal extends SettingsPlatformsLAModal {
  /**
   * Constructor for Nuance Settings Card
   * @param {Object} props for React
   */
  constructor(props) {
    super(props);
    this.agentName = "Nuance";
  }

  /**
   * In charge of creating additional elements within the Settings card
   * @returns {html} rendered additional view
   */
  additionalView() {
    return (
      <section className="Modal__section SettingsPlatformsLAModal__multi">
        <section className="Modal__section__sub-section">
          <label
            className="Modal__section__sub-section__title g-input__label"
            htmlFor="SettingsPlatformsLAModal__account-key"
          >
            <div className="Modal__section__sub-section__title__header">
              <span>Site ID</span>
              <InfoTooltip
                blurb="Your Site ID maybe found within your Nuance Settings"
                iconDefault="QuestionCircle"
                iconClicked="QuestionCircleFilled"
                absolute
                container={this.modalContentRef.current}
              />
            </div>
            <InputClearable
              value={this.props.platform.getIn(["record", "siteId"])}
              placeholder="1123581321"
              onChange={(e) => this.updateLiveAgent({ siteId: e.target.value })}
              onClear={() =>
                this.updateLiveAgent({
                  siteId: "",
                })
              }
            />
          </label>
        </section>
      </section>
    );
  }

  /**
   * Render method to create the Nuance Live Agent Card
   * @returns {html} rendered view
   */
  render() {
    return this.renderView(this.additionalView());
  }
}

/**
 * Maps the states for redux
 * @param {Object} state current state of redux
 * @returns {Object} updated state
 */
function mapState(state) {
  const { platforms } = state;

  return {
    platforms,
    platform: platforms.get("nuance_liveagent"),
  };
}

/**
 * In charge of dispatching changes to the platform
 * @param {func} dispatch
 * @returns {Object} mapping of dispatch
 */
function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      saveClientPlatform,
      deletePlatform,
      updatePlatform,
    },
    dispatch,
  );
}

const Connector = connect(
  mapState,
  mapDispatch,
)(SettingsPlatformsNuanceLAModal);

export default Connector;
