import React from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { ModalSelfServe } from "components/Common/ModalSelfServe";
import { ModalBottom } from "components/Common/ModalSelfServe/ModalBottom";
import { ModalDescription } from "components/Common/ModalSelfServe/ModalDescription";
import { ModalHeader } from "components/Common/ModalSelfServe/ModalHeader";
import { ModalTitle } from "components/Common/ModalSelfServe/ModalTitle";
import SvgIcon from "components/Common/SvgIcon";
import { c } from "constants/css";

import * as S from "./styles";

interface Props {
  channelName: string;
  channelInstructions: string;
  channelLogo: string;
  link?: string;
}

const ChannelSetupIntroModal = (props: Props) => {
  const { channelName, channelInstructions, link, channelLogo } = props;
  const dispatch = useDispatch();

  return (
    <ModalSelfServe centered>
      <S.IntroModal>
        <S.IntroLogos>
          <S.LogoBed>
            <SvgIcon icon="Ada" height={44} />
          </S.LogoBed>
          <SvgIcon icon="Connect" height={32} />
          <S.LogoBed>
            <SvgIcon icon={channelLogo} height={44} />
          </S.LogoBed>
        </S.IntroLogos>
        <ModalHeader>
          <ModalTitle>Connect Ada to {channelName}</ModalTitle>
          <ModalDescription>{channelInstructions}</ModalDescription>
        </ModalHeader>
        {link && (
          <S.LinkContainer>
            <SvgIcon icon="OpenLink" height={16} fillColor={c.colorUiPrimary} />
            <S.Link href={link} target="_blank" rel="noreferrer">
              Open {channelName} Setup Guide
            </S.Link>
          </S.LinkContainer>
        )}
      </S.IntroModal>
      <ModalBottom>
        <Button
          text="Cancel"
          title="Cancel"
          light
          tint={c.colorBlack}
          lightPrimary
          onClick={() => dispatch(closeModalAction())}
        />
        <Button text="Next" title="Next" />
      </ModalBottom>
    </ModalSelfServe>
  );
};

ChannelSetupIntroModal.defaultProps = {
  link: "",
};

export default ChannelSetupIntroModal;
