import classnames from "classnames";
import camelCase from "lodash.camelcase";
import React, { type ReactElement, type ReactNode } from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";
import { withReadOnlyWrapper } from "components/Common/ReadOnlyWrapper";
import SvgIcon from "components/Common/SvgIcon";

import * as S from "./styles";
import "./style.scss";

const BLOCK_NAME = "Checkbox";

export interface ComponentProps {
  display?: "flex" | "inline-flex";
  checked: boolean;
  className?: string;
  disabled?: boolean;
  handleToggle: (value: boolean) => void;
  isSwitch?: boolean;
  label?: ReactNode;
  labelSide?: "right" | "left";
  infoToolTip?: string;
  size?: "medium" | "small";
  theme?: "light" | "dark";
  backgroundColor?: string;
  stopPropagation?: boolean;
  ignoreReadOnly?: boolean;
}

/** @deprecated Use Checkbox or Toggle from @adasupport/byron instead */
export class Checkbox extends React.PureComponent<ComponentProps> {
  static defaultProps: Partial<ComponentProps> = {
    display: "inline-flex",
    isSwitch: false,
    labelSide: "right",
    infoToolTip: undefined,
    size: "medium",
    theme: "light",
    backgroundColor: "",
    stopPropagation: false,
  };

  constructor(props: ComponentProps) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  /**
   * Handlers
   */

  /**
   * Methods
   */

  /**
   * creates a tooltip element
   */
  static getTooltip(tooltipValue?: string) {
    if (tooltipValue) {
      return (
        <InfoTooltip
          blurb={tooltipValue}
          iconDefault="QuestionCircle"
          iconClicked="QuestionCircleFilled"
        />
      );
    }

    return null;
  }

  /**
   *
   */
  handleToggle(e?: React.MouseEvent): void {
    const { handleToggle, checked, disabled, stopPropagation, ignoreReadOnly } =
      this.props;

    if (e && stopPropagation) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (!ignoreReadOnly && disabled) {
      return;
    }

    handleToggle(!checked);
  }

  handleKeyDown(e: React.KeyboardEvent): void {
    const { disabled } = this.props;

    if (["Enter", " "].includes(e.key) && !disabled) {
      this.handleToggle();
    }
  }

  render(): ReactElement {
    const {
      display,
      labelSide,
      label,
      disabled,
      className,
      checked,
      isSwitch,
      size,
      infoToolTip,
      theme,
      backgroundColor,
      ignoreReadOnly,
    } = this.props;

    const labelSideLeft = labelSide === "left";
    const labelElement = label && (
      <label className="Checkbox__label">{label}</label>
    );
    const type = isSwitch ? "switch" : "box";

    return (
      <S.Checkbox
        display={display}
        role="button"
        tabIndex={0}
        data-testid={camelCase(label?.toString()) || "checkbox"}
        className={classnames(BLOCK_NAME, {
          [`${BLOCK_NAME}--disabled`]: !ignoreReadOnly && disabled,
          [`${BLOCK_NAME}--small`]: size === "small",
          [`${BLOCK_NAME}--label-left`]: labelSideLeft,
          [`${BLOCK_NAME}--theme-${theme}`]: theme,
          [className || ""]: Boolean(className),
        })}
        onKeyDown={this.handleKeyDown}
        onClick={this.handleToggle}
      >
        <div
          data-testid={`${checked ? `${type}Checked` : `${type}UnChecked`}`}
          className={`
            Checkbox__box
            ${
              isSwitch
                ? "Checkbox__box--type-switch"
                : "Checkbox__box--type-box"
            }
            ${checked ? `Checkbox__box--type-${type}--checked` : ""}
          `}
          style={
            backgroundColor && checked ? { background: backgroundColor } : {}
          }
        >
          {checked && !isSwitch && (
            <SvgIcon
              icon={size === "small" ? "Checkmark" : "Checkbox"}
              customClassName="Checkbox__icon"
            />
          )}
        </div>
        {labelElement}
        {Checkbox.getTooltip(infoToolTip)}
      </S.Checkbox>
    );
  }
}

export const ReadOnlyCheckbox = withReadOnlyWrapper(Checkbox);
