import * as Sentry from "@sentry/react";
import type React from "react";

import { keys } from "services/helpers";

export function trackException(error: Error) {
  Sentry.captureException(error);
}

export function trackReactComponentError(
  error: Error,
  errorInfo: React.ErrorInfo,
) {
  Sentry.withScope((scope) => {
    keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
    trackException(error);
  });
}
