import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { logoutUser } from "actions";
import { ModalActionButton } from "components/Common/ModalActionButton";

import { modalKeepOpen } from "./actions";
import "./style.scss";

const BLOCK_NAME = "ModalEnforceMFA";
export class ModalEnforceMFAComponent extends React.Component {
  /** */
  componentDidMount() {
    const { makeUnclosable } = this.props;
    makeUnclosable();
  }

  /**
   * renders the component
   * @returns {ReactElement} ModalEnforceMFA
   */
  render() {
    const { modalLogoutUser } = this.props;

    return (
      <div className={`${BLOCK_NAME} Modal_modal`}>
        <h5 className="Modal__title">Session Expired</h5>
        <div className="Modal__content">
          <section className="Modal__section ModalEnforceMFA__details">
            <p>Sorry, Your session has expired.</p>
            <p>Please login to continue using Ada.</p>
          </section>
        </div>

        <ModalActionButton
          onClick={modalLogoutUser}
          text="Log in"
          intent="primary"
        />
      </div>
    );
  }
}

ModalEnforceMFAComponent.isSmall = true;

// eslint-disable-next-line react/static-property-placement
ModalEnforceMFAComponent.propTypes = {
  modalLogoutUser: PropTypes.func.isRequired,
  makeUnclosable: PropTypes.func.isRequired,
};

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapState(state) {
  const { user } = state;

  return {
    user,
  };
}

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatch(dispatch) {
  return {
    modalLogoutUser: bindActionCreators(logoutUser, dispatch),
    makeUnclosable: bindActionCreators(modalKeepOpen, dispatch),
  };
}

export const ModalEnforceMFA = connect(
  mapState,
  mapDispatch,
)(ModalEnforceMFAComponent);
