// @ts-strict-ignore
import Immutable from "immutable";
import camelcase from "lodash.camelcase";

import { type AnalyticsReportFilterAttributes } from "components/Shared/Pages/AnalyticsPage/services";
import RecordClassCreator, {
  type ManyResourcePayload,
  type SingleResourcePayload,
} from "services/record";

type filterName = Exclude<
  keyof AnalyticsReportFilterAttributes,
  "startDate" | "endDate"
>;

interface AnalyticsReportAttributes {
  reportTitle: string;
  reportDescription: string;
  reportLearnMoreLink: string;
  dataType: string;
  reportType: "list_items" | "time_series" | "area_graph";
  allowedFilters: Immutable.List<filterName>;
  isFavorite: boolean;
  isFeatured: boolean;
  lastViewed: string; // ISO date string e.g. 2020-03-30T20:43:11Z
  dataLabels: Immutable.List<string>; // list of column names
  imageUrl?: string; // URL for featured image, only used if isFeatured = true
  isPaginated?: boolean;
  parentReport?: string | null; // parent report id for back linking
}

export class AnalyticsReport extends RecordClassCreator<AnalyticsReportAttributes>(
  {
    type: "analyticsReport",
    reportTitle: null,
    reportDescription: null,
    reportLearnMoreLink: null,
    dataType: null,
    reportType: null,
    allowedFilters: Immutable.List(),
    isFavorite: null,
    isFeatured: null,
    lastViewed: null,
    dataLabels: Immutable.List(),
    imageUrl: null,
    isPaginated: false,
    parentReport: null,
  },
) {
  static getFetchManyUrl(): string {
    return "/analytics_reports";
  }

  static getFetchOneUrl(id: string): string {
    return `/analytics_reports/${id}`;
  }

  static resolveFetchOneResult(payload: {
    data: {
      _id: string;
      _type: string;
      allowed_filters?: string[];
      [key: string]: unknown; // keys of AnalyticsReportAttributes in snake case
    };
  }): SingleResourcePayload {
    const { data } = payload;

    return {
      ...payload,
      data: {
        ...data,
        dataType: data._id,
        allowedFilters:
          data.allowed_filters && data.allowed_filters.map((f) => camelcase(f)),
      },
    };
  }

  static resolveFetchManyResult(payload: {
    data: {
      _id: string;
      _type: string;
      allowed_filters: string[];
      [key: string]: unknown;
    }[];
  }): ManyResourcePayload {
    return {
      ...payload,
      data: payload.data.map((data) => ({
        ...data,
        dataType: data._id,
        allowedFilters: data.allowed_filters.map((f) => camelcase(f)),
      })),
    };
  }
}
