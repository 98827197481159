import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "weighted_random_integer";
  weights: number[];
  variableId: string;
}

export class WeightedRandomIntegerMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "weighted_random_integer",
    weights: [1],
    variableId: "",
  },
) {
  // There is no frontend for this block, so there's never a need to validate
  static getInvalidFields = () => Immutable.List();
}
