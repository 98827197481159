import { primitives, tokens } from "@adasupport/byron";
import React, { type ReactNode } from "react";
import styled, { css } from "styled-components";

const S = {
  Tabs: styled.div``,
  TabList: styled.div`
    margin-bottom: 16px;
    border-bottom: 1px solid ${tokens.colors.border.default};
  `,
  TabButton: styled.button<{ active: boolean }>`
    background: none;
    border: none;
    padding: 16px 32px 12px;
    margin: 0;
    font-size: 14px;
    border-bottom: 4px solid transparent;
    cursor: pointer;

    ${({ active }) =>
      active &&
      css`
        font-weight: 600;
        border-bottom-color: ${primitives.palette.black};
      `}
  `,
  TabButtonLabel: styled.span`
    position: absolute;
  `,
  TabButtonHiddenLabel: styled.span`
    visibility: hidden;
    font-weight: 600;
  `,
};

export const Tabs = ({
  tabs,
}: {
  tabs: ({ label: ReactNode; content: ReactNode } | null)[];
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  return (
    <S.Tabs>
      <S.TabList>
        {tabs.map(
          (tab, index) =>
            tab && (
              <S.TabButton
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                active={index === activeTabIndex}
                onClick={() => setActiveTabIndex(index)}
              >
                <S.TabButtonLabel>{tab.label}</S.TabButtonLabel>
                {/* This is to prevent tab width from changing when the font-weight changes. */}
                <S.TabButtonHiddenLabel>{tab.label}</S.TabButtonHiddenLabel>
              </S.TabButton>
            ),
        )}
      </S.TabList>
      {tabs[activeTabIndex]?.content}
    </S.Tabs>
  );
};
