import Immutable from "immutable";

class HandoffIntegrationPlatformRecord extends Immutable.Record({
  name: "handoff_integration",
  fullName: "Handoff Integration",
  iconUrl: "",
  handoffFields: {},
  platformData: {},
  isIntegration: true,
  enabled: false,

  isVisibleToClient() {
    return false;
  },
}) {}

export { HandoffIntegrationPlatformRecord };
