export enum SupportedLanguages {
  XML = "xml",
  RUBY = "ruby",
  SWIFT = "swift",
  GROOVY = "groovy",
  KOTLIN = "kotlin",
  JAVASCRIPT = "javascript",
}

export type SupportedLanguagesType =
  | SupportedLanguages.XML
  | SupportedLanguages.RUBY
  | SupportedLanguages.SWIFT
  | SupportedLanguages.GROOVY
  | SupportedLanguages.KOTLIN
  | SupportedLanguages.JAVASCRIPT;
