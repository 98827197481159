import React, { type ReactElement, useState } from "react";
import { useDispatch } from "react-redux";

import { deleteFolderAction } from "actions/folders";
import { closeModalAction } from "actions/modal";
import { ReadOnlyCheckbox as Checkbox } from "components/Common/Checkbox";
import * as Modal from "components/Common/ModalElements/styles";
import { colorTokens, v } from "constants/css";
import { pluralize } from "services/pluralize";

import * as S from "./styles";

interface Props {
  hasReferences: boolean;
  numResponses: number;
  numFolders: number;
  id: string;
  folderTitle: string;
}

interface HasReferencesMessageProps {
  numResponses: number;
  numFolders: number;
  folderTitle: string;
}

const HasReferencesMessage = ({
  numResponses,
  numFolders,
  folderTitle,
}: HasReferencesMessageProps) => (
  <S.HasReferencesMessageStyled>
    Are you sure you want to delete the ‘{folderTitle}’ folder along with{" "}
    {numResponses} {pluralize(numResponses, "Answer")} and {numFolders}{" "}
    {pluralize(numFolders, "subfolder")}?
  </S.HasReferencesMessageStyled>
);

export function DeleteFolderModal({
  hasReferences,
  numResponses,
  numFolders,
  id,
  folderTitle,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);

  let deleteMessage = "Deletion in progress, please wait...";

  if (hasReferences) {
    deleteMessage = "Deleting folder and its content, please wait...";
  }

  return (
    <>
      <Modal.Title>Delete Folder</Modal.Title>
      <Modal.ContentFrame>
        <p>
          {hasReferences ? (
            <HasReferencesMessage
              folderTitle={folderTitle}
              numFolders={numFolders}
              numResponses={numResponses}
            />
          ) : (
            "Are you sure you want to delete this Folder?"
          )}
        </p>
        <S.ConfirmDeletion>
          <Checkbox
            size="small"
            checked={confirmDelete}
            handleToggle={() => setConfirmDelete(!confirmDelete)}
          />
          <span>Confirm deletion</span>
        </S.ConfirmDeletion>
      </Modal.ContentFrame>
      <div>
        <Modal.FullWidthButton
          data-testid="delete"
          disabled={!confirmDelete}
          textColor={colorTokens.statusNegative}
          fontWeight={v.mediumFontWeight}
          onClick={() => {
            if (!confirmDelete) {
              return;
            }

            dispatch(closeModalAction());
            dispatch(deleteFolderAction(id, deleteMessage));
          }}
        >
          {hasReferences ? "Delete Folder and contents" : "Delete Folder"}
        </Modal.FullWidthButton>
        <Modal.FullWidthButton
          last
          onClick={() => {
            dispatch(closeModalAction());
          }}
          data-testid="cancel"
        >
          Cancel
        </Modal.FullWidthButton>
      </div>
    </>
  );
}

DeleteFolderModal.isSmall = true;
