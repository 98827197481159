/* We want to allow direct access to client.product_type in this component */
/* eslint-disable no-restricted-syntax */
import { useClient } from "services/client";

export function useSplitStringOnProduct({ declarative = "", generative = "" }) {
  const { client } = useClient();

  if (client.product_type === "generative") {
    return generative;
  }

  return declarative;
}
