import classNames from "classnames";
import React from "react";

import "./style.scss";
import * as S from "./styles";

interface Props {
  id: string;
  checked: boolean;
  name?: string;
  label?: string;
  description?: string;
  longDescription?: string | React.ReactElement;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

/**
 * Generates an individual radio button. This does not generate a group of linked buttons.
 * Offers more flexibility in styling then the RadioButtons component.
 * First component is the actual button, second is the button users see, third is the label.
 */
export function RadioButton({
  id,
  name,
  checked,
  onChange = () => undefined,
  onClick,
  label,
  description,
  longDescription,
  disabled,
}: Props) {
  return (
    <>
      <div
        className={classNames("RadioButton", {
          "RadioButton--disabled": disabled,
        })}
      >
        <input
          disabled={disabled}
          id={id}
          type="radio"
          name={name}
          checked={checked}
          onChange={onChange}
          onClick={(e) => {
            if (disabled) {
              return;
            }

            if (onClick) {
              onClick(e);
            }
          }}
          className={classNames("RadioButton__input", {
            "RadioButton--disabled": disabled,
          })}
        />
        <div className="RadioButton__button" />
        <label className="RadioButton__label" htmlFor={id}>
          {label}
        </label>

        {/* Only render a description if one has been provided */}
        {description && (
          <div className="RadioButton__description">{description}</div>
        )}
      </div>
      {longDescription && checked && (
        <S.LongDescription>{longDescription}</S.LongDescription>
      )}
    </>
  );
}
