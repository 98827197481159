import { Button, Icon } from "@adasupport/byron";
import { bindActionCreators } from "@reduxjs/toolkit";
import classNames from "classnames";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import { Banner } from "components/Common/Banner";
import { Button as CommonButton } from "components/Common/Button";
import { Checkbox } from "components/Common/Checkbox";
import { DebouncedInput } from "components/Common/DebouncedInput";
import SvgIcon from "components/Common/SvgIcon";
import {
  handleAddAuthenticationScope,
  handlePkceToggle,
  handleRemoveAuthenticationCertificate,
  handleRemoveAuthenticationScope,
  handleUpdateAuthenticationModal,
  handleUpdateAuthenticationScope,
  patchAuthentication,
  postAuthentication,
  postCertificate,
  updateOrCreateAuthentication,
  validateAutenticationModal,
} from "components/Declarative/Pages/Settings/SettingsAuthentication/actions";
import { noticeUrl } from "components/Declarative/Pages/Settings/SettingsAuthentication/index";
import { ClientLegacy } from "resourceModels";
import { clientSelector } from "selectors/client";
import colors from "stylesheets/utilities/colors.scss";
import "./style.scss";

const noticeMessage =
  "Need help? Click here to read our guide on how to set up authentication!";
const notice = {
  url: noticeUrl,
  message: noticeMessage,
};

class AuthenticationModal extends React.Component {
  /**
   * split filename string into filename and extension
   * @param {String} fullFilename
   * @returns {Object}
   */
  static parseFilename(fullFilename) {
    const lastDotIndex = fullFilename.lastIndexOf(".");
    const filename =
      lastDotIndex === -1 ? fullFilename : fullFilename.slice(0, lastDotIndex);
    const extension =
      lastDotIndex === -1 ? "" : fullFilename.slice(lastDotIndex + 1);

    return {
      filename,
      extension,
    };
  }

  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.handleSaveAuthentication = this.handleSaveAuthentication.bind(this);
    this.handleUploadCertificate = this.handleUploadCertificate.bind(this);
    this.handleCancelAuthentication =
      this.handleCancelAuthentication.bind(this);
    this.onUploadCertificateClick = this.onUploadCertificateClick.bind(this);

    this.fileInputRef = React.createRef();

    const clientHandle = props.client.handle;

    this.placeholders = {
      tokenUrl: "https://acme.com/rest/as/token.oauth2",
      redirectUri: (authIntegrationName) =>
        `https://${clientHandle}.ada.support/api/oauth/${authIntegrationName}`,
      authUrl: "https://acme.com/as/authorization.oauth2",
    };
  }

  /** Click on the Upload Certificate button */
  onUploadCertificateClick() {
    this.fileInputRef.current.click();
  }

  /**
   * Returns the correct placeholder depending on the auth type
   * @param {Immutable.Record} authentication
   * @param {String} fieldName
   * @returns {String}
   */
  getPlaceholder(authentication, fieldName) {
    const authIntegrationName = authentication.get("name");
    const { placeholders } = this;
    let placeholder = placeholders[fieldName];

    if (fieldName === "redirectUri") {
      placeholder = placeholders[fieldName](authIntegrationName);
    }

    return placeholder;
  }

  /**
   * check if any input fields are dirty
   * @returns {Boolean}
   */
  isFieldDirty() {
    const { modal, authentication } = this.props;

    if (!modal.modalProps.newAuthentication) {
      return !authentication.equals(modal.modalProps.authentication);
    }

    return true;
  }

  /**
   * check if any input fields are empty
   * @returns {Boolean}
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  hasInvalidFields() {
    const { authentication } = this.props;

    return Boolean(authentication.get("invalidFields").size);
  }

  /**
   * check if save is disabled
   * @returns {Boolean}
   */
  isSaveDisabled() {
    return !this.isFieldDirty();
  }

  /**
   * handle cancel botton
   */
  handleCancelAuthentication() {
    const { setState } = this.props;
    setState("MODAL", {
      isOpen: false,
      view: "",
    });
  }

  /**
   * handle upload certificate
   * @param {Object} e
   */
  handleUploadCertificate(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    const { postCertificateAction } = this.props;
    const parsedFileName = AuthenticationModal.parseFilename(file.name);
    reader.onload = () =>
      postCertificateAction(
        parsedFileName.filename,
        parsedFileName.extension,
        reader.result.split(",", 2)[1],
      );

    reader.readAsDataURL(file);
  }

  /**
   * handle save button
   */
  handleSaveAuthentication() {
    const {
      validateAuthenticationModalAction,
      updateOrCreateAuthenticationAction,
    } = this.props;

    validateAuthenticationModalAction();
    updateOrCreateAuthenticationAction();
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const {
      authentication,
      modal,
      handleUpdateAuthenticationModalAction,
      handleAddAuthenticationScopeAction,
      handleUpdateAuthenticationScopeAction,
      handleRemoveAuthenticationScopeAction,
      handleRemoveAuthenticationCertificateAction,
      handlePkceToggleAction,
    } = this.props;

    const validationErrors = Immutable.Map(
      [
        "name",
        "tokenUrl",
        "redirectUri",
        "authUrl",
        "clientId",
        "clientSecret",
      ].map((field) => [
        field,
        authentication.invalidFields.includes(field) ||
          Boolean(authentication.errorMessages.get(field).size),
      ]),
    );

    return (
      <div className="SettingsAuthenticationModal">
        {modal.modalProps.newAuthentication ? (
          <h5 className="Modal__title">Create New Authentication</h5>
        ) : (
          <h5 className="Modal__title">Edit Authentication</h5>
        )}
        <div className="Modal__content SettingsAuthenticationModal__content">
          <Banner icon="QuestionCircleFilled">
            Need help?&nbsp;
            <a
              href={notice.url}
              target="_blank"
              rel="noreferrer noopener"
              className="SettingsAuthenticationModal__notice__content__link"
            >
              Click here
            </a>
            &nbsp;to read our guide on how to set up authentication!
          </Banner>
          <div
            className={classNames(
              "SettingsAuthenticationModal__input-block g-form__block",
              {
                "SettingsAuthenticationModal__input-block--invalid":
                  validationErrors.get("name"),
              },
            )}
          >
            <div className="SettingsAuthenticationModal__name-type">
              <div className="SettingsAuthenticationModal__name-type__Name">
                <DebouncedInput
                  label="Name"
                  placeholder="Name your authentication"
                  value={authentication.name}
                  onChange={(name) =>
                    handleUpdateAuthenticationModalAction({ name })
                  }
                  disabled={!modal.modalProps.newAuthentication}
                  isInvalid={validationErrors.get("name")}
                />
              </div>
            </div>
            {Boolean(authentication.errorMessages.get("name").size) && (
              <span className="SettingsAuthenticationModal__warning">
                {authentication.errorMessages.get("name").first()}
              </span>
            )}
          </div>
          <div
            className={classNames(
              "SettingsAuthenticationModal__input-block g-form__block",
              {
                "SettingsAuthenticationModal__input-block--invalid":
                  validationErrors.get("tokenUrl"),
              },
            )}
          >
            <DebouncedInput
              label="Token URI"
              placeholder={this.getPlaceholder(authentication, "tokenUrl")}
              value={authentication.tokenUrl}
              onChange={(tokenUrl) =>
                handleUpdateAuthenticationModalAction({ tokenUrl })
              }
              isInvalid={validationErrors.get("tokenUrl")}
            />
            {Boolean(authentication.errorMessages.get("tokenUrl").size) && (
              <span className="SettingsAuthenticationModal__warning">
                {authentication.errorMessages.get("tokenUrl").first()}
              </span>
            )}
          </div>
          <div
            className={classNames(
              "SettingsAuthenticationModal__input-block g-form__block",
              {
                "SettingsAuthenticationModal__input-block--invalid":
                  validationErrors.get("redirectUri"),
              },
            )}
          >
            <DebouncedInput
              label="Redirect URI"
              placeholder={this.getPlaceholder(authentication, "redirectUri")}
              value={authentication.redirectUri}
              onChange={(redirectUri) =>
                handleUpdateAuthenticationModalAction({ redirectUri })
              }
              isInvalid={validationErrors.get("redirectUri")}
            />
            {Boolean(authentication.errorMessages.get("redirectUri").size) && (
              <span className="SettingsAuthenticationModal__warning">
                {authentication.errorMessages.get("redirectUri").first()}
              </span>
            )}
          </div>
          <div
            className={classNames(
              "SettingsAuthenticationModal__input-block g-form__block",
              {
                "SettingsAuthenticationModal__input-block--invalid":
                  validationErrors.get("authUrl"),
              },
            )}
          >
            <DebouncedInput
              label="Auth URI"
              placeholder={this.getPlaceholder(authentication, "authUrl")}
              value={authentication.authUrl}
              onChange={(authUrl) =>
                handleUpdateAuthenticationModalAction({ authUrl })
              }
              isInvalid={validationErrors.get("authUrl")}
            />
            {Boolean(authentication.errorMessages.get("authUrl").size) && (
              <span className="SettingsAuthenticationModal__warning">
                {authentication.errorMessages.get("authUrl").first()}
              </span>
            )}
          </div>
          <div
            className={classNames(
              "SettingsAuthenticationModal__input-block g-form__block",
              {
                "SettingsAuthenticationModal__input-block--invalid":
                  validationErrors.get("clientId"),
              },
            )}
          >
            <DebouncedInput
              label="Client ID"
              placeholder="6779ef20e75817b79602"
              value={authentication.clientId}
              onChange={(clientId) =>
                handleUpdateAuthenticationModalAction({ clientId })
              }
              isInvalid={validationErrors.get("clientId")}
            />
            {Boolean(authentication.errorMessages.get("clientId").size) && (
              <span className="SettingsAuthenticationModal__warning">
                {authentication.errorMessages.get("clientId").first()}
              </span>
            )}
          </div>
          <div
            className={classNames(
              "SettingsAuthenticationModal__input-block g-form__block",
              {
                "SettingsAuthenticationModal__input-block--invalid":
                  validationErrors.get("clientSecret"),
              },
            )}
          >
            <DebouncedInput
              label="Client Secret"
              placeholder="IwkOyQ4ckZBCbq8ecIgPfS3zSSzPgdhMpMiOmx"
              value={authentication.clientSecret}
              onChange={(clientSecret) =>
                handleUpdateAuthenticationModalAction({ clientSecret })
              }
              isInvalid={validationErrors.get("clientSecret")}
            />
            {Boolean(authentication.errorMessages.get("clientSecret").size) && (
              <span className="SettingsAuthenticationModal__warning">
                {authentication.errorMessages.get("clientSecret").first()}
              </span>
            )}
          </div>

          <div className="SettingsAuthenticationModal__input-block g-form__block">
            <div className="SettingsAuthenticationModal__scope-title">
              <div className="SettingsAuthenticationModal__scope-title__wapper">
                <span className="g-input__label">
                  <span>Scopes</span>
                  <span className="SettingsAuthenticationModal__optional-label">
                    {" "}
                    (Optional)
                  </span>
                </span>
              </div>
              <CommonButton
                onClick={handleAddAuthenticationScopeAction}
                title="Add Scope"
                icon="CircleAdd"
                fillColor={colors.colorUIGood}
                customClassName="SettingsAuthenticationModal__button-add-scope"
                clear
              />
            </div>
            <div>
              {Boolean(authentication.scope.size) && (
                <div id="SettingsAuthenticationModal__input-scope">
                  {authentication.scope.map((item, index) => (
                    // It's fine here
                    <div
                      key={index} // eslint-disable-line react/no-array-index-key
                      className="SettingsAuthenticationModal__scope-list"
                    >
                      <div className="SettingsAuthenticationModal__scope-list__scope-row">
                        <div className="SettingsAuthenticationModal__scope-list__scope-row__row-item">
                          <DebouncedInput
                            placeholder="Type a scope"
                            value={item}
                            onChange={(value) => {
                              handleUpdateAuthenticationScopeAction({
                                value,
                                index,
                              });
                            }}
                          />
                        </div>
                        <CommonButton
                          onClick={() => {
                            handleRemoveAuthenticationScopeAction({
                              index,
                            });
                          }}
                          title="Remove Scope"
                          icon="CircleRemove"
                          fillColor={colors.colorUIBad}
                          customClassName={classNames(
                            "SettingsAuthenticationModal__button-remove-scope",
                            {
                              "SettingsAuthenticationModal__button-remove-scope--first":
                                authentication.scope.size === 1,
                            },
                          )}
                          clear
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="SettingsAuthenticationModal__input-block g-form__block">
            <span className="g-input__label">
              <span>Certificate</span>
              <span className="SettingsAuthenticationModal__optional-label">
                {" "}
                (Optional)
              </span>
            </span>
            {authentication.certificateName === "" ? (
              <div className="SettingsAuthenticationModal__certificate-upload">
                <div className="SettingsAuthenticationModal__certificate-upload__wrapper">
                  <CommonButton
                    title="Upload certificate"
                    text="Upload certificate"
                    icon="Upload"
                    onClick={this.onUploadCertificateClick}
                    light
                  />
                  <input
                    type="file"
                    accept=".crt, .cer, .ca-bundle, .p7b, .p7c, .p7s, .pem"
                    ref={this.fileInputRef}
                    onChange={this.handleUploadCertificate}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            ) : (
              <div className="SettingsAuthenticationModal__uploaded-file-block">
                <SvgIcon
                  height={20}
                  icon="Document"
                  fillColor={colors.colorGrey3Active}
                />
                <h3 className="SettingsAuthenticationModal__uploaded-file-block__name">
                  {authentication.certificateName}
                </h3>
                <CommonButton
                  onClick={() => {
                    handleRemoveAuthenticationCertificateAction();
                  }}
                  icon="Clear"
                  fillColor={colors.colorGrey3Active}
                  clear
                />
              </div>
            )}
          </div>

          <div className="SettingsAuthenticationModal__checkbox">
            <span className="g-input__label">
              Proof Key Code Exchange (PKCE){" "}
            </span>
            <Checkbox
              isSwitch
              checked={authentication.pkceEnabled}
              handleToggle={(value) => handlePkceToggleAction({ value })}
            />
          </div>
        </div>

        <div className="Modal__bottom SettingsAuthenticationModal__bottom">
          <Button
            onClick={this.handleCancelAuthentication}
            text="Cancel"
            variant="secondary"
          />
          <Button
            onClick={this.handleSaveAuthentication}
            text="Save"
            icon={Icon.Cloud}
            isDisabled={this.isSaveDisabled()}
            variant="primary"
          />
        </div>
      </div>
    );
  }
}

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapState(state) {
  const { authenticationModal, modal } = state;

  return {
    authentication: authenticationModal,
    modal,
    client: clientSelector(state),
  };
}

/**
 * @param {Object} dispatch
 * @returns {Object}
 */
function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      postAuthenticationAction: postAuthentication,
      patchAuthenticationAction: patchAuthentication,
      handleUpdateAuthenticationModalAction: handleUpdateAuthenticationModal,
      handleAddAuthenticationScopeAction: handleAddAuthenticationScope,
      handleRemoveAuthenticationScopeAction: handleRemoveAuthenticationScope,
      handleRemoveAuthenticationCertificateAction:
        handleRemoveAuthenticationCertificate,
      handleUpdateAuthenticationScopeAction: handleUpdateAuthenticationScope,
      postCertificateAction: postCertificate,
      validateAuthenticationModalAction: validateAutenticationModal,
      updateOrCreateAuthenticationAction: updateOrCreateAuthentication,
      handlePkceToggleAction: handlePkceToggle,
    },
    dispatch,
  );
}

AuthenticationModal.propTypes = {
  client: PropTypes.instanceOf(ClientLegacy).isRequired,
  /** modal from Redux */
  modal: ImmutablePropTypes.record.isRequired,
  /** validate the fields in the modal */
  validateAuthenticationModalAction: PropTypes.func.isRequired,
  /** set state */
  setState: PropTypes.func.isRequired,
  /** authenticationList from Redux */
  authentication: ImmutablePropTypes.record.isRequired,
  /** patch/post authentication and send to api */
  updateOrCreateAuthenticationAction: PropTypes.func.isRequired,
  /** handle update modal in Redux */
  handleUpdateAuthenticationModalAction: PropTypes.func.isRequired,
  /** handle add scope in Redux */
  handleAddAuthenticationScopeAction: PropTypes.func.isRequired,
  /** handle remove scope in Redux */
  handleRemoveAuthenticationScopeAction: PropTypes.func.isRequired,
  /** handle remove certificate in Redux */
  handleRemoveAuthenticationCertificateAction: PropTypes.func.isRequired,
  /** handle update scope in Redux */
  handleUpdateAuthenticationScopeAction: PropTypes.func.isRequired,
  /** handle post certificate and send to api */
  postCertificateAction: PropTypes.func.isRequired,
  handlePkceToggleAction: PropTypes.func.isRequired,
};

const Connector = connect(mapState, mapDispatch)(AuthenticationModal);

export default Connector;
