import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { Button } from "components/Common/Button";
import "./style.scss";

/**
 * Generates the modal sidebar and all the buttons that appear in it.
 * User has to pass an array of objects. Each object contains information on each button.
 * The user must also pass an onClick function that links all the buttons together.
 */
export class ModalSideBar extends Component {
  /**
   * generates all the buttons in the side bar
   * @returns {ReactElement} returns all the generated buttons
   */
  renderButtons() {
    const { onClick, buttons, activeButton } = this.props;

    return buttons.map(
      (button) =>
        // Show the button if as long as it's set to false
        button.visible === false || (
          <Button
            key={`chatSettings_modal_button_${button.name}`}
            id={`chatSettings_modal_button_${button.name}`}
            light
            icon={button.icon}
            text={button.text}
            title={button.name}
            disabled={false}
            customClassName={classnames("Modal__sidebar__button", {
              "Modal__sidebar__button--active": activeButton === button.name,
            })}
            onClick={onClick}
            left
          />
        ),
    );
  }

  /**
   * renders the modal sidebar
   * @returns {ReactNode} side bar
   */
  render() {
    return <div className="Modal__sidebar">{this.renderButtons()}</div>;
  }
}

// eslint-disable-next-line react/static-property-placement
ModalSideBar.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.string,
      text: PropTypes.string.isRequired,
      visible: PropTypes.bool,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  activeButton: PropTypes.string.isRequired,
};
