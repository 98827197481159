import {
  type CaptureOptions,
  type CaptureResult,
  type Properties,
} from "posthog-js";
import { useCallback } from "react";

import { UserAnalyticsService } from "services/userAnalytics/userAnalytics";

export const useUserAnalytics = () => {
  const capture = useCallback(
    (
      eventName: string,
      properties?: Properties | null,
      options?: CaptureOptions,
    ): CaptureResult | void => {
      UserAnalyticsService.capture(eventName, properties, options);
    },
    [],
  );

  return {
    capture,
  };
};
