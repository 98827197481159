import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { InputRange } from "components/Common/InputRange";
import { useReadOnly } from "components/Common/ReadOnlyWrapper";
import { updateWeightsAction } from "features/ABTesting/actions";
import { type BuilderABTest } from "features/ABTesting/types";

import { getVariantWeightPercentage } from "../../helpers";

import * as S from "./styles";

interface Props {
  responseABTest: BuilderABTest;
}

export function EditVariantWeightModal(props: Props) {
  const { responseABTest } = props;

  const [variantWeights, setVariantWeights] = useState(
    responseABTest.variantWeights,
  );
  const { isReadOnly } = useReadOnly();
  const dispatch = useDispatch();

  return (
    <div className="Modal__modal Modal__modal--small">
      <S.ModalContent>
        <S.ModalSection>
          <S.ModalTitle>Change variant weighting</S.ModalTitle>
          <S.ModalDescription>
            Change the weighting of your variants to affect how often
            they&apos;re shown during this Test.
          </S.ModalDescription>
        </S.ModalSection>
        <S.ModalSection>
          {variantWeights.map((weight, index) => (
            <S.InputRangeContainer>
              <S.Title>{responseABTest.variantNames[index]}</S.Title>
              <InputRange
                stretch
                id="rollout"
                min={1}
                max={10}
                step={1}
                data-value={weight}
                value={weight}
                onChange={(e) => {
                  const newWeight = Number(e.currentTarget.value);
                  const newVariantWeights = [...variantWeights];
                  newVariantWeights[index] = newWeight;

                  setVariantWeights(newVariantWeights);
                }}
              />
              <S.Description>
                Weighting: {weight} - Shown{" "}
                {getVariantWeightPercentage(weight, variantWeights)}% of the
                time
              </S.Description>
            </S.InputRangeContainer>
          ))}
        </S.ModalSection>
      </S.ModalContent>
      <S.ButtonsContainer className="Modal__bottom">
        <Button
          light
          onClick={() => dispatch(closeModalAction())}
          text="Cancel"
          title="Cancel"
          disabled={false}
        />
        <Button
          text="Change weighting"
          title="Change weighting"
          disabled={isReadOnly}
          onClick={() => {
            dispatch(
              updateWeightsAction(
                responseABTest.id,
                responseABTest.responseId,
                variantWeights,
              ),
            );
            dispatch(closeModalAction());
          }}
        />
      </S.ButtonsContainer>
    </div>
  );
}
