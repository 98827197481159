import { type ThunkAction } from "actions/types";
import { adaAPI } from "services/api";

function updateClientApiKeysAction(clientApiKeys: unknown) {
  return {
    type: "FETCH_CLIENT_API_KEYS_SUCCESS",
    clientApiKeys,
  };
}

function updateNewClientApiKeysAction(apiKeyNew: string) {
  return {
    type: "GENERATE_CLIENT_API_KEY_SUCCESS",
    apiKeyNew,
  };
}

export function fetchClientApiKeysAction(): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const { loading } = state.clientApiKeysState;

    if (loading) {
      return;
    }

    dispatch({ type: "FETCH_CLIENT_API_KEYS_REQUEST" });

    try {
      const response = await adaAPI.request({
        method: "GET",
        url: "/client_api_keys",
      });
      dispatch(updateClientApiKeysAction(response.data.api_keys));
    } catch (error) {
      console.warn(error);
      dispatch({ type: "FETCH_CLIENT_API_KEYS_FAILURE" });
    }
  };
}

export function generateNewApiKeyAction(): ThunkAction {
  return async (dispatch) => {
    dispatch({ type: "GENERATE_CLIENT_API_KEY_REQUEST" });

    try {
      const response = await adaAPI.request({
        method: "POST",
        url: "/client_api_keys",
      });
      dispatch(updateNewClientApiKeysAction(response.data.api_key as string));
      dispatch(fetchClientApiKeysAction()); // Fetch new key info
    } catch (error) {
      console.warn(error);
      dispatch({ type: "GENERATE_CLIENT_API_KEY_FAILURE" });
    }
  };
}
