import { type AnyAction } from "@reduxjs/toolkit";

import { DEFAULT_MODAL_OPEN_STATE } from "./constants";

export const modalOpenState = (
  state = DEFAULT_MODAL_OPEN_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case "MODAL_KEEP_OPEN":
      return { ...state, isCloseable: false };
    case "MODAL_ALLOW_CLOSE":
      return { ...state, isCloseable: true };
    default:
      return state;
  }
};
