import { createAlert } from "actions/alerts";
import { type ThunkAction } from "actions/types";
import { adaAPI } from "services/api";

import {
  type GetClientResponse,
  createClientFromResponse,
  serializePartialClient,
} from "./api";
import { selectClient } from "./selectors";
import { type Client } from "./types";

export const saveClientSuccessAction = () =>
  ({ type: "SAVE_CLIENT_SUCCESS" } as const);

export const saveClientRequestAction = () =>
  ({ type: "SAVE_CLIENT_REQUEST" } as const);

export const fetchClientSuccessAction = (client: Client) =>
  ({
    type: "FETCH_CLIENT_SUCCESS",
    client,
  } as const);

export const fetchClientFailureAction = () =>
  ({ type: "FETCH_CLIENT_FAILURE" } as const);

export const updateClientAction = (attributes: Partial<Client>) =>
  ({
    type: "UPDATE_CLIENT",
    attributes,
  } as const);

export const resetClientAction = () => ({ type: "RESET_CLIENT" } as const);

export const fetchClientAction = (): ThunkAction => async (dispatch) => {
  try {
    const response = await adaAPI.request<GetClientResponse>({
      method: "GET",
      url: "/",
    });
    dispatch(fetchClientSuccessAction(createClientFromResponse(response.data)));
  } catch (e) {
    dispatch(fetchClientFailureAction());
  }
};

export const saveClientAction =
  (): ThunkAction<Promise<void>> => async (dispatch, getState) => {
    dispatch(saveClientRequestAction());

    await adaAPI.request<GetClientResponse>({
      method: "PATCH",
      url: "/",
      data: serializePartialClient(getState().client.changedAttributes),
    });

    dispatch(saveClientSuccessAction());
  };

export function saveClientAndAlertAction(successMessage?: string): ThunkAction {
  return async (dispatch) => {
    try {
      await dispatch(saveClientAction());
      dispatch(
        createAlert({
          message: successMessage || "Client has been successfully updated.",
          alertType: "success",
        }),
      );
    } catch (e) {
      dispatch(
        createAlert({
          message: "Something went wrong.",
          alertType: "error",
        }),
      );

      throw e;
    }
  };
}

export function toggleRedaction(redactionLabel: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const client = selectClient(state);

    if (!client) {
      throw new Error("`client` is null");
    }

    let redactionsToToggle: string[] = [];

    if (redactionLabel === "pii-redactions") {
      redactionsToToggle = ["phone_number", "sin", "ssn", "email"];
    }

    const newRedactions = client.redactions.map((redaction) => ({
      ...redaction,
      active: redactionsToToggle.includes(redaction.key)
        ? !redaction.active
        : redaction.active,
    }));

    dispatch(updateClientAction({ redactions: newRedactions }));
    dispatch(saveClientAndAlertAction());
  };
}

export function toggleDefaultRedaction(key: string): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const client = selectClient(state);

    if (!client) {
      throw new Error("`client` is null");
    }

    const newRedactions = client.redactions.map((redaction) => ({
      ...redaction,
      active: key === redaction.key ? !redaction.active : redaction.active,
    }));

    dispatch(updateClientAction({ redactions: newRedactions }));
    dispatch(saveClientAndAlertAction());
  };
}
