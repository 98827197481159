import { Button } from "@adasupport/byron";
import queryString from "query-string";
import React, { useEffect, useState } from "react";

import { Banner } from "components/Common/Banner";
import { InputText } from "components/Common/InputText";
import ReadOnlyInput from "components/Common/ReadOnlyInput";
import * as Modal from "components/Shared/Pages/Settings/Modals/styles";
import { useCloseSmartSearchIntegrationsModal } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/closeModalHooks";
import { useSmartSearchIntegration } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/useSmartSearchIntegration";
import { useSmartSearchOAuth } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/useSmartSearchOAuth";
import { HUBSPOT_EVENTS, useHubspotEvents } from "hooks/useHubspotEvents";
import { useClientLegacy } from "services/clientLegacy";
import { keys } from "services/helpers";
import { type SalesforceKBIntegration } from "slices/legacyIntegrations/legacyIntegrationsApi";

import { SmartSearchSalesforceChannelSelector } from "./SmartSearchSalesforceChannelSelector";
import {
  type ArticleChannel,
  CHANNELS,
} from "./SmartSearchSalesforceChannelSelector/constants";
import "./style.scss";
import * as S from "./styles";

export function ConnectionGuide() {
  const initOAuth = useSmartSearchOAuth("salesforce");
  const {
    integrationData,
    integrationPosition,
    handleSave,
    handleDisconnect,
    isConnecting,
  } = useSmartSearchIntegration("KBIntegrationSalesforce");
  const { sendHubspotEvent } = useHubspotEvents();
  const closeModal = useCloseSmartSearchIntegrationsModal();
  const [formInput, setFormInput] = useState({
    instanceUrl: "",
    supportDomain: "",
    consumerKey: "",
    consumerSecret: "",
    bodyLabel: "",
    callbackUrl: `${window.location.protocol}//${window.location.host}/api/knowledgebase/salesforce/oauth/authorize`,
  });
  const [formValidation, setFormValidation] = useState({
    instanceUrl: "pristine",
    consumerKey: "pristine",
    consumerSecret: "pristine",
    supportDomain: "pristine",
  });
  const [articleChannels, setArticleChannels] = useState<ArticleChannel>({
    [CHANNELS.PUBLIC_KNOWLEDGEBASE]: true,
    [CHANNELS.CUSTOMER_PORTAL]: true,
    [CHANNELS.PARTNER_PORTAL]: true,
  });
  const { client } = useClientLegacy();

  const BLOCK_NAME = "SettingsSmartSearchModal";

  const existingIntegration = integrationPosition > -1;

  /**
   * Builds a Salesforce Oauth URL
   *
   * Checkout the 'OAuth 2.0 Web Server Authentication Flow' guide on Salesforce for a list of all
   *    possible things you can pass to the URL.
   * @see https://help.salesforce.com/articleView?id=remoteaccess_oauth_web_server_flow.htm
   *
   * @returns {String}
   */
  const buildSalesforceOauthUrl = () => {
    const trailingSlash = /\/$/;
    const instanceUrl = formInput.instanceUrl.replace(trailingSlash, "");
    const queryParams = queryString.stringify({
      response_type: "code",
      client_id: formInput.consumerKey,
      client_secret: formInput.consumerSecret,
      redirect_uri: formInput.callbackUrl,
      state: JSON.stringify({
        instance_url: `${instanceUrl}`,
        support_domain: formInput.supportDomain,
        consumer_id: formInput.consumerKey,
        consumer_secret: formInput.consumerSecret,
        body_label: formInput.bodyLabel,
        redirect_uri: formInput.callbackUrl,
        verbose: false,
        overwrite: false,
      }),
    });

    return `${instanceUrl}/services/oauth2/authorize?${queryParams}`;
  };

  const handlePopupWindowClose = async () => {
    try {
      await handleSave("salesforce", {
        domain: formInput.instanceUrl,
        support_domain: formInput.supportDomain,
        client_id: client.id,
        consumer_id: formInput.consumerKey,
        consumer_secret: formInput.consumerSecret,
        body_label: formInput.bodyLabel,
        channels: keys(articleChannels).filter(
          (channelKey: keyof ArticleChannel) => articleChannels[channelKey],
        ),
      });

      sendHubspotEvent(HUBSPOT_EVENTS.ADD_KNOWLEDGE);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConnectClick = async () => {
    const instanceURLRegExp = /^https?:\/\/.+\.salesforce\.com$/gi;

    if (!instanceURLRegExp.test(formInput.instanceUrl)) {
      setFormValidation({
        ...formValidation,
        instanceUrl: "invalid",
      });

      return;
    }

    try {
      await initOAuth(buildSalesforceOauthUrl());
      handlePopupWindowClose();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (integrationData) {
      const salesforceIntegration = integrationData as SalesforceKBIntegration;
      setFormInput({
        instanceUrl: salesforceIntegration.domain,
        consumerKey: salesforceIntegration.consumer_id,
        consumerSecret: salesforceIntegration.consumer_secret,
        supportDomain: salesforceIntegration.support_domain,
        bodyLabel: salesforceIntegration.body_label,
        callbackUrl: `${window.location.protocol}//${window.location.host}/api/knowledgebase/salesforce/oauth/authorize`,
      });

      if (salesforceIntegration.channels.length !== 0) {
        setArticleChannels({
          [CHANNELS.PUBLIC_KNOWLEDGEBASE]: false,
          [CHANNELS.CUSTOMER_PORTAL]: false,
          [CHANNELS.PARTNER_PORTAL]: false,
          ...(salesforceIntegration.channels.reduce(
            (accumulator: object, channel: string) => ({
              ...accumulator,
              [channel]: true,
            }),
            {},
          ) as Partial<ArticleChannel>),
        });
      }
    }
  }, [integrationData]);

  const handleSaveButtonClick = async () => {
    if (existingIntegration) {
      handleDisconnect();
    } else {
      try {
        handleConnectClick();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderOptionalLabel = (label: string) => (
    <S.OptionalLabelContainer>
      {label} <span>(Optional)</span>
    </S.OptionalLabelContainer>
  );

  const renderConnectGuide = () => (
    <>
      <h5 className="Modal__title">Connect Salesforce Knowledge</h5>
      <Modal.ModalBody>
        <Banner icon="InfoFilled">
          You must have a Connected App in Salesforce before you integrate this
          knowledge base. For setup help, view the&nbsp;
          <a
            href="https://docs.ada.cx/article-suggestions"
            target="_blank"
            rel="noreferrer noopener"
          >
            help doc.
          </a>
        </Banner>
        <S.SectionTitle>Salesforce Credentials</S.SectionTitle>
        <p className="Modal__section__sub-section__description">
          The following information is found in your Salesforce Admin Account.
        </p>
        <section className={`${BLOCK_NAME}__row`}>
          <S.ModalInput label="Help Center URL">
            <InputText
              value={formInput.supportDomain}
              onChange={(value: string) => {
                setFormValidation({
                  ...formValidation,
                  supportDomain: value.length === 0 ? "invalid" : "valid",
                });
                setFormInput({ ...formInput, supportDomain: value });
              }}
              disabled={existingIntegration}
              invalid={formValidation.supportDomain === "invalid"}
              invalidMessage="Please enter a valid URL"
            />
          </S.ModalInput>
          <S.ModalInput label="Instance URL">
            <InputText
              value={formInput.instanceUrl}
              onChange={(value: string) => {
                setFormValidation({
                  ...formValidation,
                  instanceUrl: value.length === 0 ? "invalid" : "valid",
                });
                setFormInput({ ...formInput, instanceUrl: value });
              }}
              placeholder="e.g https://myinstance.salesforce.com"
              disabled={existingIntegration}
              invalid={formValidation.instanceUrl === "invalid"}
              invalidMessage="Please enter a valid URL"
            />
          </S.ModalInput>
        </section>
        <section className={`${BLOCK_NAME}__row ${BLOCK_NAME}__row--split`}>
          <S.ModalInput label="Consumer Key">
            <InputText
              value={formInput.consumerKey}
              onChange={(value: string) => {
                setFormValidation({
                  ...formValidation,
                  consumerKey: value.length === 0 ? "invalid" : "valid",
                });
                setFormInput({ ...formInput, consumerKey: value });
              }}
              disabled={existingIntegration}
              invalid={formValidation.consumerKey === "invalid"}
              invalidMessage="Please enter a valid consumer key"
            />
          </S.ModalInput>
          <S.ModalInput label="Consumer Secret">
            <InputText
              value={formInput.consumerSecret}
              onChange={(value: string) => {
                setFormValidation({
                  ...formValidation,
                  consumerSecret: value.length === 0 ? "invalid" : "valid",
                });
                setFormInput({ ...formInput, consumerSecret: value });
              }}
              disabled={existingIntegration}
              invalid={formValidation.consumerSecret === "invalid"}
              invalidMessage="Please enter a valid consumer secret"
            />
          </S.ModalInput>
        </section>
        <section className={`${BLOCK_NAME}__row ${BLOCK_NAME}__row--split`}>
          <S.ModalInput
            label={renderOptionalLabel("Custom Content Field Name")}
          >
            <InputText
              value={formInput.bodyLabel}
              onChange={(value: string) =>
                setFormInput({ ...formInput, bodyLabel: value })
              }
              disabled={existingIntegration}
            />
          </S.ModalInput>
          <div />
        </section>
        <SmartSearchSalesforceChannelSelector
          articleChannels={articleChannels}
          setArticleChannels={setArticleChannels}
          existingIntegration={existingIntegration}
        />
        <hr className={`${BLOCK_NAME}__divider`} />
        <S.SectionTitle>Ada Callback URL</S.SectionTitle>
        <p className="Modal__section__sub-section__description">
          Paste the following URL in the connected app&apos;s Callback URL
          field.
        </p>
        <section className={`${BLOCK_NAME}__row`}>
          <ReadOnlyInput value={formInput.callbackUrl} />
        </section>
      </Modal.ModalBody>
    </>
  );

  const isFormValid = () =>
    Object.values(formValidation).every((value) => value === "valid");

  return (
    <Modal.Modal>
      {renderConnectGuide()}
      <Modal.ModalFooter className={`${BLOCK_NAME}__bottom `}>
        <Button
          text={
            existingIntegration
              ? "Disconnect Salesforce Knowledge"
              : "Authenticate"
          }
          onClick={handleSaveButtonClick}
          isDisabled={isConnecting || (!existingIntegration && !isFormValid())}
          variant={existingIntegration ? "secondary" : "primary"}
          dataTestId={`knowledge-salesforce-${
            existingIntegration ? "disconnect" : "connect"
          }`}
        />
        {!existingIntegration && (
          <Button
            text="Cancel"
            variant="secondary"
            onClick={closeModal}
            dataTestId="knowledge-salesforce-connect-cancel"
          />
        )}
      </Modal.ModalFooter>
    </Modal.Modal>
  );
}
