// @ts-strict-ignore
import Immutable from "immutable";

import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

export type SetVariableBlockVariableData = TypedMap<{
  variableId: string;
  content: TypedMap<{
    value: string;
    configuredFieldType: "string" | "variable" | "boolean" | "null";
  }>;
}>;

interface Attributes extends BaseMessageRecordAttributes {
  type: "variable_override";
  variablesData: Immutable.List<SetVariableBlockVariableData>;
  hasInvalidVariables: boolean;
}

export class VariableOverrideMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "variable_override",
    variablesData: Immutable.List(),
    hasInvalidVariables: false,
  },
) {
  static getInvalidFields: InvalidFieldsGetter<VariableOverrideMessageRecord> =
    (message) => {
      const invalidFields = new Set<string>();

      message.variablesData
        .filter(
          (data) =>
            !data.get("variableId") || !data.get("content").get("value"),
        )
        .forEach((data, i) => invalidFields.add(`invalidVariableId-${i}`));

      if (message.hasInvalidVariables === true) {
        invalidFields.add("invalidVariableContent");
      }

      return Immutable.List(invalidFields);
    };
}
