import React from "react";

import { type ExpressionRecord } from "reducers/expressions/types";

import * as S from "./styles";

interface ExpressionErrorListProps {
  errorExpressions: ExpressionRecord[];
  errorMessage: string;
}

export function ExpressionErrorList({
  errorExpressions,
  errorMessage,
}: ExpressionErrorListProps) {
  if (!errorExpressions.length) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.Icon icon="WarningYellow" />
      {errorMessage}
      <S.ErrorList>
        {errorExpressions.map((expression) => (
          <S.Expression key={expression.get("id")}>
            <S.ExpressionText>{expression.get("body")}</S.ExpressionText>
          </S.Expression>
        ))}
      </S.ErrorList>
    </S.Wrapper>
  );
}
