import { createAlert, dismissAlert } from "actions/alerts";
import { setPage } from "actions/router";
import { type ThunkAction } from "actions/types";
import { type ExpressionRecord } from "reducers/expressions/types";
import { selectClient } from "services/client";

import { getLanguageName } from "./getLanguageName";

export function trainingAddedAlert({
  expression,
  responseId,
  showResponseNavigationButton,
}: {
  expression: ExpressionRecord;
  responseId: string;
  showResponseNavigationButton: boolean;
}): ThunkAction {
  return (dispatch, getState) => {
    let successMessage = "Training added.";
    const state = getState();
    const client = selectClient(state);

    if (client) {
      const language = getLanguageName({
        state,
        languageCode: expression.language,
      });

      if (!language) {
        successMessage = "Training added in an unknown language.";
      } else {
        successMessage = `Training added in ${language}.`;
      }
    }

    if (showResponseNavigationButton) {
      dispatch(
        createAlert({
          message: successMessage,
          alertType: "success",
          buttons: [
            {
              label: "Open Answer",
              action: () => {
                dispatch(dismissAlert());
                dispatch(setPage(`/answers/${responseId}`));
              },
            },
          ],
        }),
      );
    } else {
      dispatch(
        createAlert({
          message: successMessage,
          alertType: "success",
        }),
      );
    }
  };
}
