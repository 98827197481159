import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${primitives.spacing.space2};
  padding-left: ${primitives.spacing.space4};
  border-bottom: 1px solid ${tokens.colors.border.formInput};
`;

export const LeftSide = styled.div`
  & > *:not(:first-child) {
    margin-left: ${primitives.spacing.space2};
  }
`;

export const Title = styled.span`
  font-size: ${primitives.fontSize.default};
  font-weight: ${primitives.fontWeight.semibold};
`;
