import { useSelector } from "react-redux";

import { versioningViewSelector } from "components/Shared/Pages/Responses/ResponseVersions/selectors";

export const useAnswerVersioning = () => {
  const versioning = useSelector(versioningViewSelector);

  return {
    versioning,
    isVersionViewMode: Boolean(versioning?.versionId || versioning?.responseId),
  };
};
