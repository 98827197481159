import { type CampaignDto } from "reducers/campaigns/types";

export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS" as const;
export interface UpdateCampaignsAction {
  type: typeof FETCH_CAMPAIGNS_SUCCESS;
  campaigns: CampaignDto[];
}

export const FETCH_CAMPAIGNS_REQUEST = "FETCH_CAMPAIGNS_REQUEST" as const;
export interface FetchCampaignsRequestAction {
  type: typeof FETCH_CAMPAIGNS_REQUEST;
}

export const FETCH_CAMPAIGNS_FAILURE = "FETCH_CAMPAIGNS_FAILURE" as const;
export interface FetchCampaignsFailureAction {
  type: typeof FETCH_CAMPAIGNS_FAILURE;
}
export const ADD_CAMPAIGN = "ADD_CAMPAIGN" as const;
export interface AddCampaignAction {
  type: typeof ADD_CAMPAIGN;
  campaignKey: string;
  name: string;
  description: string;
  isEngageClient: boolean;
  channel: string;
}

export const SET_CAMPAIGNS_FILTERS = "SET_CAMPAIGNS_FILTERS" as const;
export interface SetCampaignsFiltersAction {
  type: typeof SET_CAMPAIGNS_FILTERS;
  filters: Set<string>;
}

export const SET_CAMPAIGNS_SEARCH_QUERY = "SET_CAMPAIGNS_SEARCH_QUERY" as const;
export interface SetCampaignsSearchQuery {
  type: typeof SET_CAMPAIGNS_SEARCH_QUERY;
  searchQuery: string;
}

export const SET_EMPTY_CAMPAIGNS = "SET_EMPTY_CAMPAIGNS" as const;
export interface SetEmptyCampaignsAction {
  type: typeof SET_EMPTY_CAMPAIGNS;
  loaded: boolean;
  loading: boolean;
  campaigns: [];
}

export type CampaignActions =
  | UpdateCampaignsAction
  | FetchCampaignsRequestAction
  | FetchCampaignsFailureAction
  | AddCampaignAction
  | SetCampaignsFiltersAction
  | SetCampaignsSearchQuery
  | SetEmptyCampaignsAction;
