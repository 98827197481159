import {
  type SerializedTrainedExpression,
  type TrainedExpression,
} from "./types";

export const deserializeTrainedExpression = (
  trainedExpressionData: SerializedTrainedExpression,
): TrainedExpression => ({
  id: trainedExpressionData._id,
  active: trainedExpressionData.active,
  body: trainedExpressionData.body,
  bodySimple: trainedExpressionData.body_simple,
  clientId: trainedExpressionData.client_id,
  language: trainedExpressionData.language,
  preTranslationBody: trainedExpressionData.pre_translation_body,
  responseId: trainedExpressionData.response_id,
});

export const deserializeTraining = (
  trainingData: SerializedTrainedExpression[],
) => trainingData.map(deserializeTrainedExpression);
