function getAPIRoot(): string {
  const DOMAIN = window.location.host;

  if (process.env.NODE_ENV === "production" && !window.__env.API_ROOT) {
    return `https://${DOMAIN}/api`;
  }

  if (!window.__env.API_ROOT) {
    return `http://${DOMAIN}/api`;
  }

  return window.__env.API_ROOT;
}

export { getAPIRoot };
