import styled from "styled-components";

import { v } from "constants/css";

export const ModalContent = styled.div`
  padding: ${v.gUnitDouble};
  flex: 1;
`;

export const ModalSection = styled.section`
  padding: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
