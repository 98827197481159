import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction, openModalAction } from "actions/modal";
import { ModalSelfServe } from "components/Common/ModalSelfServe/";
import { ModalDescription } from "components/Common/ModalSelfServe/ModalDescription";
import { ModalHeader } from "components/Common/ModalSelfServe/ModalHeader";
import { ModalTitle } from "components/Common/ModalSelfServe/ModalTitle";

import ChannelSelectionCard from "./ChannelSelectionCard";
import * as S from "./styles";

interface Channel {
  channelName: string;
  channelInstructions: string;
  link?: string;
}

const MockPlatforms = [
  {
    icon: "FacebookMessengerColorNew",
    platformType: "Facebook Messenger",
    isAvailable: true,
  },
  {
    icon: "WhatsappColorNew",
    platformType: "WhatsApp",
    isAvailable: true,
  },
  {
    icon: "TwilioColorNew",
    platformType: "Twilio SMS",
    isAvailable: true,
  },
  {
    icon: "LineColor",
    platformType: "Line",
    isAvailable: false,
  },
];

const ChannelSetupSelectionModal = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // hides close button
    dispatch({
      type: "MODAL_KEEP_OPEN",
    });
  }, []);

  const showChannelIntroModal = (channel: Channel) => {
    dispatch(closeModalAction());
    dispatch(openModalAction("SELF_SERVE_CHANNEL_INTRO", { ...channel }));
  };

  return (
    <ModalSelfServe>
      <ModalHeader>
        <ModalTitle>Say Hello to Channels</ModalTitle>
        <ModalDescription>
          Select a channel to engage with your customers wherever they are.
        </ModalDescription>
      </ModalHeader>
      <S.ChannelsList>
        {MockPlatforms.map(({ platformType, icon, isAvailable }) => {
          // const { platformType, icon, isAvailable } = p;
          const channel = {
            channelName: platformType,
            channelInstructions: `To ensure you are successful, we recommend you have our setup guide and your ${platformType} Developer dashboard open during this process:`,
            link: "http://www.google.com",
            channelLogo: icon,
          };

          return (
            <ChannelSelectionCard
              platformType={platformType}
              icon={icon}
              isAvailable={isAvailable}
              key={platformType}
              startSelfSetup={() =>
                isAvailable && showChannelIntroModal(channel)
              }
            />
          );
        })}
      </S.ChannelsList>
    </ModalSelfServe>
  );
};

export default ChannelSetupSelectionModal;
