import { type AnyAction } from "@reduxjs/toolkit";
// Moment is no longer supported. Slack channel #p-remove-moment for details
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";

import {
  type NormalScheduleRuleRow,
  type Schedule,
  type ScheduleInvalidField,
  type SpecialScheduleRuleRow,
} from "components/Shared/Pages/Settings/SettingsSchedules/types";
import { mongoObjectId } from "services/objectid";

const ERROR_MESSAGE_START_END_TIMES_INVALID =
  "End time must be later than start time";
const ERROR_MESSAGE_START_END_TIMES_ARE_EQUAL =
  "Start and end times cannot be the same";
const ERROR_MESSAGE_START_END_TIMES_MUST_NOT_BE_EMPTY =
  "Start and end times cannot be empty";
const ERROR_MESSAGE_RULE_CONFLICTS =
  "This rule conflicts with an existing rule’s times";
const ERROR_MESSAGE_NO_DAYS_SELECTED = "Rule must have selected days";

const resetConflictingDays = (
  normalOperatingDays: NormalScheduleRuleRow[],
): NormalScheduleRuleRow[] =>
  normalOperatingDays.map((rule) => ({
    ...rule,
    conflictingDays: {
      days: new Set(),
      errorMessage: "",
    },
  }));

/**
 * Returns the conflicting days if and only if there is a conflict
 * i.e., Monday 9am-5pm, and Monday, 3pm-7pm
 */
export const isConflictBetweenRules = (
  rule1: NormalScheduleRuleRow,
  rule2: NormalScheduleRuleRow,
) => {
  const rule2days = new Set(rule2.days);
  const intersectingDays = new Set(
    rule1.days.filter((day) => rule2days.has(day)),
  );

  // early return if there's no common days between rules
  if (!intersectingDays.size) {
    return false;
  }

  // there is always a conflict if one of the days is "allDay"
  if (rule1.allDay || rule2.allDay) {
    return intersectingDays;
  }

  const startDate1 = moment(rule1.start, "HH:mm");
  const endDate1 = moment(rule1.end, "HH:mm");

  const startDate2 = moment(rule2.start, "HH:mm");
  const endDate2 = moment(rule2.end, "HH:mm");

  const checkRange1 = startDate1.diff(endDate2) < 0;
  const checkRange2 = startDate2.diff(endDate1) < 0;

  return checkRange1 && checkRange2 && intersectingDays;
};

export const validateConflictingRules = (
  normalOperatingDays: NormalScheduleRuleRow[],
): NormalScheduleRuleRow[] => {
  let validatedRules = resetConflictingDays(normalOperatingDays);

  if (normalOperatingDays.length <= 1) {
    return validatedRules;
  }

  // loop through each item in `rules`, compare to the "rest" of the items.
  // this is an O(nlogn) operation

  validatedRules.forEach((rule1, i) => {
    let conflictingDays = rule1.conflictingDays.days;

    validatedRules.slice(i + 1).forEach((rule2, j) => {
      let conflictingDaysOverflow = (
        validatedRules[j + i + 1] as NormalScheduleRuleRow
      ).conflictingDays.days;
      const conflictsBetweenRules = isConflictBetweenRules(rule1, rule2);

      if (conflictsBetweenRules) {
        conflictingDays = new Set([
          ...conflictingDays,
          ...conflictsBetweenRules,
        ]);
        conflictingDaysOverflow = new Set([
          ...conflictingDaysOverflow,
          ...conflictsBetweenRules,
        ]);

        validatedRules = validatedRules.map((rule, k) => {
          if (k === i) {
            return {
              ...rule,
              conflictingDays: {
                ...rule.conflictingDays,
                days: conflictingDays,
              },
            };
          }

          return rule;
        });

        validatedRules = validatedRules.map((rule, k) => {
          if (k === j + i + 1) {
            return {
              ...rule,
              conflictingDays: {
                ...rule.conflictingDays,
                days: new Set([...conflictingDaysOverflow, ...conflictingDays]),
                errorMessage: ERROR_MESSAGE_RULE_CONFLICTS,
              },
            };
          }

          return rule;
        });
      }
    });
  });

  return validatedRules;
};

/**
 * This function validates the start/end times of a normal day rule in a schedule.
 * It then updates the invalidFields field on the scheduleRow with error info.
 */
export function validateStartEndTime<
  T extends NormalScheduleRuleRow | SpecialScheduleRuleRow,
>(scheduleRow: T): T {
  let invalidFields: ScheduleInvalidField[] = [];

  const start = moment(scheduleRow.start, "HH:mm");
  const end = moment(scheduleRow.end, "HH:mm");

  // Check the start and end times
  if (start.diff(end) === 0 && !scheduleRow.allDay) {
    // If start and end times are equal
    invalidFields = [
      ...invalidFields,
      {
        field: "startEnd",
        errorMessage: ERROR_MESSAGE_START_END_TIMES_ARE_EQUAL,
      },
    ];
  } else if (start.diff(end) > 0 && !scheduleRow.allDay) {
    // If the start time is later than the end time
    invalidFields = [
      ...invalidFields,
      {
        field: "startEnd",
        errorMessage: ERROR_MESSAGE_START_END_TIMES_INVALID,
      },
    ];
  } else if ((!scheduleRow.start || !scheduleRow.end) && !scheduleRow.allDay) {
    // If either of the start or end times are not set
    invalidFields = [
      ...invalidFields,
      {
        field: "startEnd",
        errorMessage: ERROR_MESSAGE_START_END_TIMES_MUST_NOT_BE_EMPTY,
      },
    ];
  }

  return {
    ...scheduleRow,
    invalidFields,
  };
}

const hasNameAndTimezone = (state: Schedule) =>
  Boolean(state.name && state.timezone);

const validateNormalOperatingHours = (schedule: Schedule) => {
  let validatedNormalHours = schedule.normalOperatingDaysRows;
  let isValid = true;
  let hasErrors = false;

  // Validate all start/end times
  validatedNormalHours = validateConflictingRules(
    validatedNormalHours.map((rule) => validateStartEndTime(rule)),
  );

  // make sure the `days` field is not empty
  validatedNormalHours = validatedNormalHours.map((row) => {
    let validatedRow = row;

    if (validatedRow.days.length === 0) {
      validatedRow = {
        ...validatedRow,
        invalidFields: [
          ...validatedRow.invalidFields,
          {
            field: "days",
            errorMessage: ERROR_MESSAGE_NO_DAYS_SELECTED,
          },
        ],
      };
    }

    return validatedRow;
  });

  const isRulesNonEmpty = Boolean(validatedNormalHours.length);

  const hasRequiredFields = validatedNormalHours.every(
    (rule) => rule.days.length > 0,
  );

  const hasInvalidFields =
    validatedNormalHours.findIndex((row) => row.invalidFields.length) >= 0;

  const hasConflictingDays =
    validatedNormalHours.findIndex((row) => row.conflictingDays.days.size) >= 0;

  if (
    !hasRequiredFields ||
    hasInvalidFields ||
    hasConflictingDays ||
    !isRulesNonEmpty
  ) {
    isValid = false;
    hasErrors = true;
  }

  return { validatedNormalHours, isValid, hasErrors };
};

const validateSpecialOperatingHours = (schedule: Schedule) => {
  let validatedSpecialHours = schedule.specialOperatingDaysRows;
  let isValid = true;
  let hasErrors = false;

  validatedSpecialHours = validatedSpecialHours.map((rule) =>
    validateStartEndTime(rule),
  );

  const rowHasInvalidFields =
    validatedSpecialHours.findIndex((row) => row.invalidFields.length) >= 0;

  if (rowHasInvalidFields) {
    isValid = false;
    hasErrors = true;
  }

  const invalidDates = schedule.specialOperatingDaysRows.filter(
    (row) => row.date === null,
  );

  if (invalidDates.length > 0) {
    isValid = false;
    hasErrors = true;
  }

  return { validatedSpecialHours, isValid, hasErrors };
};

/**
 * Validates the schedule rows for a schedule record
 */
const validateScheduleRecord = (state: Schedule) => {
  let validatedState = state;

  const {
    validatedNormalHours,
    isValid: normalRowsValid,
    hasErrors: normalRowsHaveErrors,
  } = validateNormalOperatingHours(validatedState);

  const {
    validatedSpecialHours,
    isValid: specialRowsValid,
    hasErrors: specialRowsHaveErrors,
  } = validateSpecialOperatingHours(validatedState);

  validatedState = {
    ...validatedState,
    normalOperatingDaysRows: validatedNormalHours,
    specialOperatingDaysRows: validatedSpecialHours,
    isValid: Boolean(
      validatedState.name &&
        validatedState.timezone &&
        normalRowsValid &&
        specialRowsValid,
    ),
    hasErrors: normalRowsHaveErrors || specialRowsHaveErrors,
  };

  return validatedState;
};

export const DEFAULT_SCHEDULE_RULE: NormalScheduleRuleRow = {
  date: null,
  days: [],
  start: "08:00",
  end: "17:00",
  allDay: false,
  invalidFields: [], // list of {field, errorMessage}
  conflictingDays: {
    days: new Set<string>(),
    errorMessage: "",
  },
};

export const DEFAULT_SETTINGS_SCHEDULE_MODAL_STATE: Schedule = {
  id: "",
  name: "",
  timezone: "",
  normalOperatingDaysRows: [DEFAULT_SCHEDULE_RULE],
  specialOperatingDaysRows: [],
  isValid: false,
  hasErrors: false,
};

export const settingsScheduleModal = (
  state = DEFAULT_SETTINGS_SCHEDULE_MODAL_STATE,
  action: AnyAction,
): Schedule => {
  switch (action.type) {
    case "CREATE_SETTINGS_SCHEDULE_MODAL":
      return { ...DEFAULT_SETTINGS_SCHEDULE_MODAL_STATE, id: mongoObjectId() };

    case "SET_SETTINGS_SCHEDULE_MODAL": {
      const { payload } = action;

      return {
        ...DEFAULT_SETTINGS_SCHEDULE_MODAL_STATE,
        ...payload,
      };
    }

    case "UPDATE_SETTINGS_SCHEDULE_MODAL": {
      let nextState = {
        ...state,
        ...action.payload,
      };

      const validatedScheduleIsValid =
        validateScheduleRecord(nextState).isValid;

      if (validatedScheduleIsValid) {
        nextState = {
          ...nextState,
          isValid: hasNameAndTimezone(nextState),
        };
      }

      return nextState;
    }

    case "ADD_SETTINGS_SCHEDULE_ROW": {
      // The current local time
      const now = moment();
      // Get the remainder to round up to 30m
      const minuteReminder = 30 - (now.minute() % 30);
      // add remainder to start and for
      const start = now.add(minuteReminder, "minutes").format("HH:mm");
      const end = now.add(1, "hours").format("HH:mm");

      // Set the start and end time of the row we're adding
      const row = {
        ...DEFAULT_SCHEDULE_RULE,
        start,
        end,
      };

      // update state
      const { scheduleType } = action.payload;

      return {
        ...state,
        [scheduleType]: [
          ...state[
            scheduleType as
              | "normalOperatingDaysRows"
              | "specialOperatingDaysRows"
          ],
          row,
        ],
        isValid: false,
      };
    }

    case "DELETE_SETTINGS_SCHEDULE_ROW": {
      const { scheduleType, index } = action.payload;
      let nextState = state;

      if (scheduleType === "normalOperatingDaysRows") {
        nextState = {
          ...state,
          normalOperatingDaysRows: state.normalOperatingDaysRows.filter(
            (row, i) => i !== index,
          ),
        };
      } else if (scheduleType === "specialOperatingDaysRows") {
        nextState = {
          ...state,
          specialOperatingDaysRows: state.specialOperatingDaysRows.filter(
            (row, i) => i !== index,
          ),
        };
      }

      return validateScheduleRecord(nextState);
    }

    case "UPDATE_SETTINGS_SCHEDULE_ROW": {
      const { scheduleType, index, value } = action.payload;
      let nextState = state;

      if (scheduleType === "normalOperatingDaysRows") {
        nextState = {
          ...state,
          normalOperatingDaysRows: state.normalOperatingDaysRows.map(
            (row, i) => {
              if (i === index) {
                return {
                  ...row,
                  ...value,
                };
              }

              return row;
            },
          ),
        };
      } else if (scheduleType === "specialOperatingDaysRows") {
        nextState = {
          ...state,
          specialOperatingDaysRows: state.specialOperatingDaysRows.map(
            (row, i) => {
              if (i === index) {
                return {
                  ...row,
                  ...value,
                };
              }

              return row;
            },
          ),
        };
      }

      return validateScheduleRecord(nextState);
    }

    default:
      return state;
  }
};
