// @ts-strict-ignore
import { createAlert } from "actions/alerts";
import { AlertType } from "actions/alerts/types";
import { alreadyTrainedAlert } from "actions/expressions/helpers/alreadyTrainedAlert";
import { trainingAddedAlert } from "actions/expressions/helpers/trainingAddedAlert";
import {
  type CallApiAction,
  type Dispatch,
  type ThunkAction,
} from "actions/types";
import { type Filter } from "components/Declarative/Pages/Questions/helper";
import { type ExpressionRecord } from "reducers/expressions/types";
import { type State } from "reducers/types";
import { adaAPI, adaApiRequest } from "services/api";
import { Base64 } from "services/base64";
import { snakeCaseKeys } from "services/object";

import {
  DELETE_GROUP_REQUEST,
  GET_GROUPS_REQUEST,
  GET_GROUPS_SUCCESS,
  TRAIN_EXPRESSION_GROUP_SUCCESS,
} from "./types";

function getGroupExpressions(
  searchQuery: string,
  lastExpressionId: string,
  clear: boolean,
  options: {
    filter: {
      startDate: string;
      endDate: string;
      bestGuess: unknown;
      variables?: Filter[];
    };
    sort?: unknown;
    origin: string;
    groupId: string;
    scrolling?: boolean;
  },
): ThunkAction {
  return (dispatch) => {
    const {
      groupId,
      filter: { variables },
    } = options;
    const group = groupId ? `/${groupId}` : "";
    const search = searchQuery
      ? `/expressions/body/${Base64.encode(searchQuery)}/`
      : "";
    const variablesRequest = variables.length
      ? `?filter[variables]=${JSON.stringify(variables)}`
      : "";
    const url = `/training/groups${group}${search}${variablesRequest}`;
    dispatch({ type: "GET_EXPRESSIONS_REQUEST", scrolling: options.scrolling });

    const params = {
      ...(lastExpressionId && { last_expression_id: lastExpressionId }),
      ...(searchQuery && { search: Base64.encode(searchQuery) }),
    };

    adaAPI.request({ method: "GET", url, params }).then(
      (response) => {
        dispatch({
          type: "GET_EXPRESSIONS_SUCCESS",
          response: {
            data: {
              ...(response.data.training_group_expressions as object),
              count: response.data.count,
            },
          },
          clear,
          scrolling: options.scrolling,
        });
      },
      () => dispatch({ type: "GET_EXPRESSIONS_FAILURE" }),
    );
  };
}

export function getExpressions(
  searchQuery: string,
  lastExpressionId: string,
  clear: boolean,
  options: {
    filter: {
      startDate: string | null;
      endDate: string | null;
      bestGuess: unknown;
      variables?: Filter[];
    };
    sort?: unknown;
    origin: string;
    groupId: string;
    scrolling?: boolean;
    lastSort?: string[];
    size?: number;
  },
): ThunkAction | CallApiAction {
  if (options.groupId) {
    return getGroupExpressions(searchQuery, lastExpressionId, clear, options);
  }

  const url = "/expressions/untrained";
  const {
    filter: { startDate, endDate, bestGuess, variables },
    sort,
    origin,
    scrolling,
    lastSort,
    size,
  } = options;

  const params = {
    filter: snakeCaseKeys({ startDate, endDate, bestGuess }),
    sort,
    origin,
    ...(searchQuery && { search: Base64.encode(searchQuery) }),
    ...(lastSort && { last_sort: JSON.stringify(lastSort) }),
    ...(size && { size }),
  };

  let variablesRequest = "";

  if (variables) {
    variablesRequest = variables.length
      ? `?filter[variables]=${JSON.stringify(variables)}`
      : "";
  }

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "get",
      endpoint: url + variablesRequest,
      args: {
        clear,
        origin,
        scrolling,
      },
      params,
      types: [
        "GET_EXPRESSIONS_REQUEST",
        "GET_EXPRESSIONS_SUCCESS",
        "GET_EXPRESSIONS_FAILURE",
      ],
    },
  };
}

export function trainGroupExpression(trainingResponse: {
  expressionIds: string[];
  groupId?: string;
}): ThunkAction {
  return async (dispatch) => {
    const url = "/training/groups/";

    const response = await dispatch(
      adaApiRequest({
        method: "PATCH",
        url,
        data: {
          expression_ids: trainingResponse.expressionIds,
          group_id: trainingResponse.groupId,
        },
      }),
    );
    dispatch({
      type: TRAIN_EXPRESSION_GROUP_SUCCESS,
      trainingGroups: response.data.training_groups,
    });
  };
}

export function deleteExpression(
  id: string,
  groupId?: string,
): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({ type: "DELETE_EXPRESSION_PENDING", id });

      const response = await dispatch(
        adaApiRequest({
          method: "delete",
          url: `/expressions/${id}`,
        }),
      );
      dispatch({ type: "DELETE_EXPRESSION_SUCCESS", response });
      dispatch(
        trainGroupExpression({
          expressionIds: [id],
          groupId,
        }),
      );
    } catch (error) {
      dispatch({ type: "DELETE_EXPRESSION_FAILURE", id });
      dispatch(
        createAlert({
          message: "Failed to delete question.",
          alertType: "error",
        }),
      );
    }
  };
}

export function deleteExpressions(deleteTargets: {
  ids: string[];
  search: string | null;
  origin: string;
  groupId?: string;
  variables?: Filter[];
}): ThunkAction<Promise<void>> {
  const { ids, search, groupId, origin, variables } = deleteTargets;
  const params = {
    origin,
    ...(search && { search: Base64.encode(search) }),
  };

  let variablesRequest = "";

  if (variables) {
    variablesRequest = variables.length
      ? `?filter[variables]=${JSON.stringify(variables)}`
      : "";
  }

  return async (dispatch) => {
    await dispatch(
      adaApiRequest({
        method: "delete",
        url: `/expressions/untrained${variablesRequest}`,
        data: {
          _ids: ids,
        },
        params,
      }),
    );
    dispatch({ type: "DELETE_UNTRAINED_EXPRESSIONS_SUCCESS", ids });
    dispatch(
      trainGroupExpression({
        expressionIds: ids,
        groupId,
      }),
    );
  };
}

const alreadyTrained = (message: string) =>
  message && message.includes("already trained");

export function trainExpression(
  expression: ExpressionRecord,
  responseId: string,
  selection: string | null,
  groupId?: string,
): ThunkAction {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const viewingTranslations = state.questionsPage.get("viewingTranslations");
    const clientLanguage = state.client.resource.language;

    try {
      dispatch({ type: "TRAIN_EXPRESSION_REQUEST" });

      // if the user highlights a piece of translated text, we use the client language
      const language =
        selection && viewingTranslations
          ? clientLanguage
          : expression.get("language");

      const response = await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: `expressions/${expression.id}`,
          data: {
            response_id: responseId,
            ...(selection && { body: selection }),
            ...(language && { language }),
          },
        }),
      );

      dispatch({
        type: "TRAIN_EXPRESSION_SUCCESS",
        response: { data: { expression: response.data.expression } },
      });
      dispatch(
        trainingAddedAlert({
          expression: expression.merge({
            language: (response.data.expression as ExpressionRecord).language,
          }),
          responseId,
          showResponseNavigationButton: true,
        }),
      );
      dispatch(
        trainGroupExpression({
          expressionIds: [expression.id as string],
          groupId,
        }),
      );
    } catch (error) {
      dispatch({ type: "TRAIN_EXPRESSION_FAILURE" });

      if (alreadyTrained(error.response.data?.message)) {
        dispatch(
          alreadyTrainedAlert({
            response: error.response,
            currentResponseId: null,
            body: expression.get("body") || "",
          }),
        );
      } else {
        dispatch(createAlert(error));
      }
    }
  };
}

export function getGroups(
  searchQuery: string,
  options?: { filter: { variables: Filter[] } },
) {
  return (dispatch: Dispatch) => {
    let variablesRequest = "";

    if (options) {
      const {
        filter: { variables },
      } = options;
      variablesRequest = variables.length
        ? `?filter[variables]=${JSON.stringify(variables)}`
        : "";
    }

    let url = `/training/groups${variablesRequest}`;
    dispatch({ type: GET_GROUPS_REQUEST });

    if (searchQuery) {
      url = `/training/groups/title/${Base64.encode(
        searchQuery,
      )}${variablesRequest}`;
    }

    adaAPI.request({ method: "GET", url }).then((response) => {
      dispatch({
        type: GET_GROUPS_SUCCESS,
        trainingGroups: response.data.training_groups,
      });
    });
  };
}

export function deleteGroup(groupId: string): ThunkAction {
  return async (dispatch) => {
    dispatch({ type: DELETE_GROUP_REQUEST });

    try {
      await dispatch(
        adaApiRequest({
          method: "DELETE",
          url: `/training/groups/${groupId}/`,
        }),
      );
      dispatch(
        createAlert({
          message: "Group successfully deleted.",
          alertType: AlertType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Failed to delete group.",
          alertType: AlertType.ERROR,
        }),
      );
    }

    dispatch(getGroups(""));
  };
}

export interface UntrainedExrpressionsProps {
  searchQuery: string;
  lastExpressionId: string | null;
  clear: boolean;
  options: {
    filter: {
      startDate: string | null;
      endDate: string | null;
      bestGuess: string | null;
      variables: Filter[];
    };
    sort?: unknown;
    origin: string;
    groupId: string;
    scrolling?: boolean;
    lastSort?: string[];
    size?: number;
  };
}

export function getUntrainedExrpressionsResources(
  props: UntrainedExrpressionsProps,
): ThunkAction<unknown> {
  const { searchQuery, lastExpressionId, clear, options } = props;

  return async (dispatch) => {
    await dispatch(
      getExpressions(
        searchQuery,
        lastExpressionId as string,
        clear,
        options,
      ) as CallApiAction,
    );
  };
}
