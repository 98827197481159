import { type TagType } from "reducers/tags/types";

export * from "./hooks";

export const isTopTag = (tagName: string | null) =>
  tagName ? tagName.toLowerCase() === "top" : false;

export const findAndReorderTopTag = (tags: TagType[]) => {
  const topTagIndex = tags.findIndex((tag) => isTopTag(tag.name));

  if (topTagIndex === -1) {
    return tags;
  }

  const topTag = tags[topTagIndex] as TagType;
  const tagsWithoutTopTag = tags.filter((tag) => !isTopTag(tag.name));

  return [topTag, ...tagsWithoutTopTag];
};
