import { createAlert } from "actions/alerts";
import {
  FETCH_BUSINESS_EVENTS_FAILURE,
  FETCH_BUSINESS_EVENTS_REQUEST,
  FETCH_BUSINESS_EVENTS_SUCCESS,
  SAVE_BUSINESS_EVENTS_FAILURE,
  SAVE_BUSINESS_EVENTS_REQUEST,
  SET_BUSINESS_EVENTS,
} from "actions/events/types";
import { closeModalAction, openModalAction } from "actions/modal";
import { type Dispatch, type ThunkAction } from "actions/types";
import { deserializeEvent } from "reducers/businessEvents/reducer";
import {
  type BusinessEvent,
  type BusinessEventDto,
} from "reducers/businessEvents/types";
import { serializeTriggerConditions } from "reducers/common/helpers";
import { adaAPI } from "services/api";
import { selectClient } from "services/client";
import { type State } from "types";

// remove this when we fully support the AFM handoff event. AFM-86
interface TmpEventInterface {
  event_key: string;
}

const EXCLUDED_EVENTS = ["AFM_handoff_support", "ada_handoff_support"];

export function updateEventsAction(
  events: Array<TmpEventInterface>,
): ThunkAction {
  return async (dispatch, getState: () => State) => {
    const client = selectClient(getState());

    dispatch({
      type: FETCH_BUSINESS_EVENTS_SUCCESS,
      events: client?.features.afm_handoff_events
        ? events
        : events.filter((event) => !EXCLUDED_EVENTS.includes(event.event_key)),
    });
  };
}

export function setBusinessEventsAction(events: BusinessEvent[]): ThunkAction {
  return async (dispatch, getState: () => State) => {
    const client = selectClient(getState());

    dispatch({
      type: SET_BUSINESS_EVENTS,
      events: client?.features.afm_handoff_events
        ? events
        : events.filter((event) => !EXCLUDED_EVENTS.includes(event.eventKey)),
    });
  };
}

export function serializeEvent(event: BusinessEvent) {
  return {
    _id: event.id || undefined, // If we have an empty string for the id (happens when creating a new event), we must replace with undefined for the backend to handle correctly
    event_key: event.eventKey,
    name: event.name,
    description: event.description,
    trigger_conditions: serializeTriggerConditions(event.triggerConditions),
    value: event.value !== null ? Number(event.value) : 0.0,
    archived: event.archived,
    built_in: event.builtIn,
  };
}

export function saveEventAction(
  event: BusinessEvent,
  successMessage: string | null = null,
): ThunkAction<Promise<BusinessEvent | null>> {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const client = selectClient(state);

    if (!client?.features.afm_business_events) {
      return null;
    }

    try {
      dispatch({ type: SAVE_BUSINESS_EVENTS_REQUEST });
      const { data } = await adaAPI.request({
        method: "POST",
        url: "/business_events/",
        data: serializeEvent(event),
      });
      dispatch(
        createAlert({
          message: successMessage || "Event successfully saved.",
          alertType: "success",
        }),
      );

      return deserializeEvent(data.business_event as BusinessEventDto);
    } catch (error) {
      dispatch({ type: SAVE_BUSINESS_EVENTS_FAILURE, error });
      dispatch(
        createAlert({
          message: "Something went wrong - failed to save event.",
          alertType: "error",
        }),
      );

      return null;
    }
  };
}

export function fetchEventsAction() {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const client = selectClient(state);

    if (!client?.features.afm_business_events) {
      return;
    }

    if (state.businessEventState.loading) {
      return;
    }

    try {
      dispatch({ type: FETCH_BUSINESS_EVENTS_REQUEST });
      const response = await adaAPI.request({
        method: "GET",
        url: "/business_events/all",
      });
      // remove this when we fully support the AFM handoff event AFM-86
      dispatch(
        updateEventsAction(
          response.data.business_events as Array<TmpEventInterface>,
        ),
      );
    } catch (error) {
      dispatch({ type: FETCH_BUSINESS_EVENTS_FAILURE, error });
    }
  };
}

interface ArchiveEventModalsOptions {
  onSuccess?: () => void;
}

export function openConfirmArchiveEventModalAction(
  event: BusinessEvent,
  { onSuccess }: ArchiveEventModalsOptions,
): ThunkAction {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Archive Event",
        message:
          "Are you sure you want to archive this Event? This can’t be undone. Analytics and reports for this Event will be preserved",
        shouldCloseOnMaskClick: false,
        actions: [
          {
            title: "Archive Event",
            buttonTint: "alert",
            onClick: () => {
              dispatch(
                saveEventAction(
                  { ...event, archived: true },
                  "Event successfully archived.",
                ),
              );

              if (onSuccess) {
                onSuccess();
              }

              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };
}
