import Immutable from "immutable";

import { keyConverter } from "services/key-converter";

export const AUTH_TYPE_OAUTH = "oauth";
const OAUTH_REQUIRED_FIELDS = [
  "name",
  "tokenUrl",
  "redirectUri",
  "authUrl",
  "clientId",
  "clientSecret",
];
const errorMessagesInitial = Immutable.Map({
  name: Immutable.List(),
  tokenUrl: Immutable.List(),
  redirectUri: Immutable.List(),
  authUrl: Immutable.List(),
  clientId: Immutable.List(),
  clientSecret: Immutable.List(),
});
export const AuthenticationRecord = Immutable.Record({
  name: "",
  authType: "",
  tokenUrl: "",
  redirectUri: "",
  authUrl: "",
  clientId: "",
  clientSecret: "",
  scope: Immutable.List([""]),
  certificateName: "",
  enabled: true,
  errorMessages: errorMessagesInitial,
  invalidFields: Immutable.List(),
  pkceEnabled: false,
});

const newAuthenticationModalState = new AuthenticationRecord({
  authType: AUTH_TYPE_OAUTH,
  enabled: true,
});

const newAuthenticationListState = Immutable.List();

/**
 * create a authentication record based on item
 * @param {Object} item
 * @returns {Object}
 */
function createAuthentication(item) {
  return new AuthenticationRecord({
    name: item.name,
    authType: item.authType,
    tokenUrl: item.tokenUrl,
    redirectUri: item.redirectUri,
    authUrl: item.authUrl,
    clientId: item.clientId,
    clientSecret: item.clientSecret,
    scope: Immutable.List(item.scope),
    certificateName: item.certificateName,
    enabled: item.enabled,
    pkceEnabled: item.pkceEnabled,
  });
}

/**
 * update authentication list in redux
 * @param {Object} integrations
 * @returns {Object}
 */
function updateAuthenticationList(integrations) {
  return Immutable.List(
    Object.keys(integrations).map((key) =>
      createAuthentication(keyConverter(integrations[key])),
    ),
  );
}

/**
 * Sets invalid fields on the authentication Record
 * @param {Immutable.Record} authRecord
 * @returns {Immutable.Record}
 */
const setInvalidFields = (authRecord) => {
  let invalidFields = Immutable.List();
  let errorMessages = errorMessagesInitial;
  const errorMessageMap = {
    name: "Enter a name for your authentication",
    tokenUrl: "Enter a Token URL",
    redirectUri: "Enter a Redirect URI",
    authUrl: "Enter an Auth URL",
    clientId: "Enter a Client ID",
    clientSecret: "Enter a Client Secret",
  };

  const requiredFields = OAUTH_REQUIRED_FIELDS;

  requiredFields.forEach((field) => {
    if (!authRecord.get(field)) {
      invalidFields = invalidFields.push(field);
      errorMessages = errorMessages.set(
        field,
        Immutable.List([errorMessageMap[field]]),
      );
    }
  });

  return authRecord.merge({
    invalidFields,
    errorMessages,
  });
};

/**
 * Removes invalid fields on the authentication Record if they become valid
 * @param {Immutable.Record} authRecord
 * @returns {Immutable.Record}
 */
const checkInvalidFields = (authRecord) => {
  let invalidFields = authRecord.get("invalidFields");
  let errorMessages = authRecord.get("errorMessages");

  invalidFields.forEach((field) => {
    if (authRecord.get(field)) {
      invalidFields = invalidFields.filter((f) => f !== field);
      errorMessages = errorMessages.set(field, Immutable.List());
    }
  });

  return authRecord.merge({
    invalidFields,
    errorMessages,
  });
};

export const authenticationModal = (
  state = newAuthenticationModalState,
  action,
) => {
  switch (action.type) {
    case "CREATE_AUTHENTICATION_MODAL":
      return newAuthenticationModalState;
    case "SET_AUTHENTICATION_MODAL":
      return createAuthentication(keyConverter(action.payload));

    case "UPDATE_AUTHENTICATION_MODAL": {
      const newState = state.merge(action.payload);

      return checkInvalidFields(newState);
    }

    case "VALIDATE_AUTHENTICATION_MODAL": {
      return setInvalidFields(state);
    }

    case "ADD_AUTHENTICATION_SCOPE":
      return state.update("scope", (scopes) => scopes.push(""));
    case "REMOVE_AUTHENTICATION_SCOPE":
      if (state.get("scope").size !== 0) {
        return state.update("scope", (scopes) =>
          scopes.delete(action.payload.index),
        );
      }

      return state;
    case "UPDATE_AUTHENTICATION_SCOPE":
      return state.update("scope", (scopes) =>
        scopes.set(action.payload.index, action.payload.value),
      );
    case "REMOVE_AUTHENTICATION_CERTIFICATE":
      return state.set("certificateName", "");

    case "TOGGLE_PKCE_STATE":
      return state.set("pkceEnabled", action.payload.value);

    case "POST_AUTHENTICATION_CERTIFICATE_SUCCESS": {
      const { filename } = action.response.data;

      return state.set("certificateName", filename);
    }

    case "PATCH_AUTHENTICATION_FAILURE":
    // falls through

    case "POST_AUTHENTICATION_FAILURE": {
      const errorMessages = Immutable.fromJS(
        keyConverter(action.response.data.errors),
      );
      const invalidFields = errorMessages.keySeq().toArray();

      return state.merge({
        errorMessages: errorMessagesInitial.merge(errorMessages),
        invalidFields,
      });
    }

    default:
      return state;
  }
};

export const authenticationList = (
  state = newAuthenticationListState,
  action,
) => {
  switch (action.type) {
    case "PATCH_AUTHENTICATION_SUCCESS":
    case "POST_AUTHENTICATION_SUCCESS":
    case "GET_ALL_AUTHENTICATION_SUCCESS":
    case "DELETE_AUTHENTICATION_SUCCESS":
      return updateAuthenticationList(action.response.data.integrations);
    default:
      return state;
  }
};
