import classnames from "classnames";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";
import React, { useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";

import { DatePicker } from "components/Common/DatePicker";
import SvgIcon from "components/Common/SvgIcon";
import { NO_OP_FUNCTION } from "services/helpers";
import "./style.scss";

const BLOCK_NAME = "DatePickerDropdown";

interface DatePickerDropdownProps {
  placeholder?: string;
  selectedDate: string;
  minDate: string;
  maxDate?: string | null;
  onChange(date: string): void;
  displayDateFormat?: string;
  valueDateFormat?: string;
  hasRange?: boolean;
  optionalIconName?: string;
  disabled: boolean;
}

export const DatePickerDropdown = (props: DatePickerDropdownProps) => {
  const {
    placeholder = "Select Date",
    minDate,
    maxDate = null,
    displayDateFormat = "LL",
    valueDateFormat = "YYYY-MM-DD",
    optionalIconName = "Calendar",
    hasRange = false,
    selectedDate,
    onChange = NO_OP_FUNCTION,
    disabled,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useOnClickOutside(() => {
    setIsDropdownOpen(false);
  });

  // selectedDate is a UTC date string
  // remember: moment.utc(selectedDate) is not the same as moment(selectedDate).utc()
  const displayDate =
    selectedDate && moment.utc(selectedDate).format(displayDateFormat);

  /**
   * Show or hide the entire dropdown menu
   */
  const toggleCalendar = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCustomDateChange = (nextDate: moment.MomentInput) => {
    // nextDate is a ISO 8601 date string that has UTC timezone
    // e.g. "2021-03-01T00:00:00.000Z" BUT moment("2023-04-20T00:00:00.000Z") is
    // not the same as moment.utc("2023-04-20T00:00:00.000Z")
    const formattedSelectedDate = moment.utc(nextDate).format(valueDateFormat);
    onChange(formattedSelectedDate);
  };

  return (
    <div
      ref={ref}
      className={classnames(BLOCK_NAME, {
        [`${BLOCK_NAME}--open`]: isDropdownOpen,
      })}
    >
      <button
        type="button"
        className={classnames(`${BLOCK_NAME}__header`, {
          [`${BLOCK_NAME}--disabled`]: disabled,
        })}
        onClick={toggleCalendar}
        disabled={disabled}
      >
        {optionalIconName && (
          <div className={`${BLOCK_NAME}__header-icon`}>
            <SvgIcon icon={optionalIconName} height={24} />
          </div>
        )}
        <div className={`${BLOCK_NAME}__header-title-container`}>
          <div className={`${BLOCK_NAME}__header-title`}>
            {displayDate || placeholder}
          </div>
        </div>
        <div
          className={classnames(`${BLOCK_NAME}__header-arrow`, {
            [`${BLOCK_NAME}--disabled`]: disabled,
          })}
        >
          <SvgIcon icon="ChevronDown" height={24} />
        </div>
      </button>
      {isDropdownOpen && (
        <div className={`${BLOCK_NAME}__dropdown-container`}>
          <div className={`${BLOCK_NAME}__date-picker-container`}>
            <DatePicker
              activeInput="start"
              selectedDay={displayDate}
              minDate={minDate}
              maxDate={maxDate}
              onChange={handleCustomDateChange}
              hasRange={hasRange}
            />
          </div>
        </div>
      )}
    </div>
  );
};
