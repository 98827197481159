import Immutable from "immutable";

import { isValidColor } from "services/color";

import { type ClientAttributes, type ClientLegacy } from ".";

export const MAX_BOT_DESCRIPTION_LENGTH = 80;
export const MAX_FALLBACK_UI_MESSAGE_LENGTH = 250;
export const MAX_FALLBACK_UI_URL_LABEL_LENGTH = 36;
export const MAX_BOT_NAME_LENGTH = 18;

export type ClientInvalidFieldName =
  | keyof ClientAttributes
  | ("botAvatarLetter" | "botAvatarImage" | "agentAvatarImage");

export const getInvalidFields = (
  client: ClientLegacy,
): Immutable.List<ClientInvalidFieldName> => {
  const result = new Set<ClientInvalidFieldName>();
  const avatarImage = client.avatar.get("image");
  const avatarImageType = avatarImage && avatarImage.split(".").pop();
  const isBotImage = Boolean(
    avatarImageType &&
      ["svg", "jpg", "jpeg", "png", "gif"].includes(avatarImageType),
  );

  if (client.avatar.get("useLetter") && !client.avatar.get("letter")) {
    result.add("botAvatarLetter");
  } else if (!client.avatar.get("useLetter") && !isBotImage) {
    result.add("botAvatarImage");
  }

  if (!client.agentAvatar.get("default") && !client.agentAvatar.get("image")) {
    result.add("agentAvatarImage");
  }

  // Validate tint
  if (!isValidColor(client.tint)) {
    result.add("tint");
  }

  return Immutable.List(result);
};
