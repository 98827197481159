import { adaAPI } from "services/api";
import { selectClient } from "services/client";
import { type ThunkAction } from "types";

export function fetchHandoffIntegrationsAction(): ThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { handoffIntegrationsState } = state;
    const { loading, loaded } = handoffIntegrationsState;
    const client = selectClient(state);

    if (loading || loaded || !client) {
      return;
    }

    dispatch({ type: "SET_EMPTY_HANDOFF_INTEGRATIONS" });
    dispatch({ type: "FETCH_HANDOFF_INTEGRATIONS_REQUEST" });

    adaAPI.request({ method: "GET", url: "/integrations" }).then(
      (response) => {
        dispatch({
          type: "FETCH_HANDOFF_INTEGRATIONS_SUCCESS",
          handoffIntegrationsList: response.data.integrations,
        });
      },
      () => dispatch({ type: "FETCH_HANDOFF_INTEGRATIONS_FAILURE" }),
    );
  };
}
