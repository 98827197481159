import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/Common/Button";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputClearable } from "components/Common/InputClearable";
import ReadOnlyInput from "components/Common/ReadOnlyInput";
import "./style.scss";

class SettingsPlatformsLAModal extends React.Component {
  /**
   * Constructor for Live Agent Parent Card
   * @param {Object} props for React
   */
  constructor(props) {
    super(props);
    this.modalContentRef = React.createRef();
  }

  /**
   * Updates the the platform on the API
   * @param {Object} payload that contains platform information
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  updateLiveAgent(payload) {
    const { updatePlatform, platformType } = this.props;
    updatePlatform(platformType, payload);
  }

  /**
   * Updates the auth parameters for a platform on the API
   * @param {Object} payload that contains platform information
   */
  updateAuthLiveAgent(payload) {
    const { updatePlatform, platformType } = this.props;
    const newPayload = {
      auth: payload,
    };
    updatePlatform(platformType, newPayload);
  }

  /**
   * Parent render method to create the basic needs for a Live Agent Card
   * @param {html} additionalView to be rendered inside the
   *                         main section
   * @returns {html} renders the basic view
   */
  // eslint-disable-next-line react/no-unused-class-component-methods
  renderView(additionalView) {
    const { platform, saveClientPlatform } = this.props;
    const isButtonDisabled =
      (platform.get("isPublished") && !platform.get("isDirty")) ||
      !platform.get("isValid");

    return (
      <div className="SettingsPlatformsLAModal Modal__modal">
        <h5 className="Modal__title">{this.agentName} Live Chat Settings</h5>
        <div className="Modal__content" ref={this.modalContentRef}>
          <section className="Modal__section">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformsLAModal__account-sid"
              >
                <div className="Modal__section__sub-section__title__header">
                  <span>Redirect URL</span>
                  <InfoTooltip
                    blurb={`Copy and paste this redirect URL when creating your
                            API Client in ${this.agentName}`}
                    iconDefault="QuestionCircle"
                    iconClicked="QuestionCircleFilled"
                    absolute
                    container={this.modalContentRef.current}
                  />
                </div>
                {!this.restrictUri && (
                  <InputClearable
                    value={platform.getIn(["record", "auth", "redirectUri"])}
                    placeholder="https://auth.touchcommerce.com/oauth-server/oauth/token"
                    onChange={(e) =>
                      this.updateAuthLiveAgent({ redirectUri: e.target.value })
                    }
                    onClear={() =>
                      this.updateAuthLiveAgent({
                        redirectUri: "",
                      })
                    }
                  />
                )}
                {this.restrictUri && (
                  <ReadOnlyInput
                    icon="Copy"
                    value={platform.getIn(["record", "auth", "redirectUri"])}
                    placeholder="Redirect URI"
                  />
                )}
              </label>
            </section>
          </section>
          <section className="Modal__section SettingsPlatformsLAModal__multi">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformsLAModal__account-sid"
              >
                <div className="Modal__section__sub-section__title__header">
                  <span>Client ID</span>
                  <InfoTooltip
                    blurb={`Your Client ID is generated when you create a new API
                            Client in ${this.agentName}.`}
                    iconDefault="QuestionCircle"
                    iconClicked="QuestionCircleFilled"
                    absolute
                    container={this.modalContentRef.current}
                  />
                </div>
                <InputClearable
                  value={platform.getIn(["record", "auth", "clientId"])}
                  placeholder="6779ef20e75817b79602"
                  onChange={(e) =>
                    this.updateAuthLiveAgent({ clientId: e.target.value })
                  }
                  onClear={() =>
                    this.updateAuthLiveAgent({
                      clientId: "",
                    })
                  }
                />
              </label>
            </section>
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformsLAModal__secret-key"
              >
                <div className="Modal__section__sub-section__title__header">
                  <span>Client Secret</span>
                  <InfoTooltip
                    blurb={`Your Client Secret is generated once when you
                            create a new API Client in ${this.agentName}.`}
                    iconDefault="QuestionCircle"
                    iconClicked="QuestionCircleFilled"
                    absolute
                    container={this.modalContentRef.current}
                  />
                </div>
                <InputClearable
                  value={platform.getIn(["record", "auth", "clientSecret"])}
                  placeholder="gGRvsdA1ZzdfMH5Mgedhg"
                  onChange={(e) =>
                    this.updateAuthLiveAgent({ clientSecret: e.target.value })
                  }
                  onClear={() =>
                    this.updateAuthLiveAgent({
                      clientSecret: "",
                    })
                  }
                />
              </label>
            </section>
          </section>
          {additionalView}
        </div>

        <div className="Modal__bottom SettingsPlatformsLAModal__bottom">
          <Button
            onClick={() => saveClientPlatform(platform)}
            text="Save"
            title="Save"
            icon="Cloud"
            disabled={isButtonDisabled}
            isLoading={platform.get("isLoading")}
            customClassName="SettingsPlatformsLAModal__bottom__save"
          />
        </div>
      </div>
    );
  }
}

SettingsPlatformsLAModal.propTypes = {
  updatePlatform: PropTypes.func.isRequired,
  platformType: PropTypes.string.isRequired,
  platform: PropTypes.instanceOf(Immutable.Map).isRequired,
  saveClientPlatform: PropTypes.func.isRequired,
};

export default SettingsPlatformsLAModal;
