import styled from "styled-components";

import { c, v } from "constants/css";

export const Container = styled.div`
  padding: 8px 16px 16px 16px;
  border-bottom: 1px solid ${c.colorGrey2};
  overflow-x: hidden;
`;

/**
 * `white-space: pre-wrap` on Title and Description is so that when we snip up the text for search
 * highlighting, the whitespace at the starts/ends of the snipped up sections isn't trimmed.
 */

export const Title = styled.div`
  color: ${c.colorBlack};
  display: flex;
  align-items: center;
  min-height: 23px;
  margin-right: 5px;
  margin-bottom: 4px;
  white-space: pre-wrap;
`;

export const Description = styled.div`
  font-size: ${v.xSmallFontSize};
  color: ${c.colorGrey4};
  white-space: pre-wrap;
`;

export const IconBar = styled.div`
  margin-left: ${v.gUnit};
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  margin: 8px 8px 4px 0;
`;

export const Highlight = styled.span`
  background-color: ${c.colorUiHighlight};
`;
