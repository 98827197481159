import Immutable from "immutable";

import { recordMerger } from "services/record-merger";

interface LogRecordAttributes {
  id: string | null;
  by: unknown;
  clientId: unknown;
  clientToken: unknown;
  conversationId: unknown;
  created: string | number;
  message: {
    body?: string;
    agentInfo?: {
      name: string;
      default_image: boolean;
      image_url: string;
    };
    type?: string;
    buttonType?: string;
    [key: string]: unknown;
  };
  responseId: unknown;
  review: unknown;
  timeBucket: unknown;
  source: string | null;
  to: unknown;
  agentInfo: unknown;
  type: null;
}

export class LogRecord extends Immutable.Record<LogRecordAttributes>({
  id: null,
  by: null,
  clientId: null,
  clientToken: null,
  conversationId: null,
  created: 0,
  message: {},
  responseId: null,
  review: null,
  timeBucket: null,
  source: null,
  to: null,
  agentInfo: null,
  type: null,
}) {}

let newLog;

type LogState = Immutable.List<LogRecord>;

type LogsAction =
  | { type: "GET_LOGS_SUCCESS"; response: { data: { log: object[] } } }
  | { type: "ADD_LOG_TO_CONVERSATION_SUCCESS"; log: object }
  | { type: "CLEAR_LOGS" };

export const logs = (
  state: LogState = Immutable.List([]),
  action: LogsAction,
) => {
  switch (action.type) {
    case "GET_LOGS_SUCCESS": {
      let nextState = state;

      action.response.data.log.forEach((item, index) => {
        const record = recordMerger(
          item,
          new LogRecord(),
        ) as LogRecordAttributes;

        if (record.message.agentInfo) {
          record.agentInfo = Immutable.fromJS(record.message.agentInfo);
        }

        newLog = new LogRecord(record);

        // not a great way of fixing the duplication bug. Will need to find root cause
        if (nextState.getIn([nextState.size - 1 - index, "id"]) !== newLog.id) {
          nextState = nextState.unshift(newLog);
        }
      });

      return nextState;
    }

    case "ADD_LOG_TO_CONVERSATION_SUCCESS": {
      const record = recordMerger(
        action.log,
        new LogRecord(),
      ) as LogRecordAttributes;

      if (record.message.agentInfo) {
        record.agentInfo = Immutable.fromJS(record.message.agentInfo);
      }

      newLog = new LogRecord(record);

      return state.push(newLog).sortBy((nlog) => nlog.get("created"));
    }

    case "CLEAR_LOGS": {
      return state.clear();
    }

    default:
      return state;
  }
};
