import Immutable from "immutable";

import {
  TrainingSuggestionRecord,
  type TrainingSuggestionsAction,
} from "./types";

interface TrainingSuggestionsStateAttributes {
  loading: boolean;
  loaded: boolean;
  suggestions: Immutable.List<TrainingSuggestionRecord>;
  error: boolean;
  hidden: boolean;
}

export class TrainingSuggestionsStateRecord extends Immutable.Record<TrainingSuggestionsStateAttributes>(
  {
    loading: false,
    loaded: false,
    suggestions: Immutable.List(),
    error: false,
    hidden: false,
  },
) {}

export const trainingSuggestionsState = (
  state = new TrainingSuggestionsStateRecord(),
  action: TrainingSuggestionsAction,
) => {
  switch (action.type) {
    case "FETCH_TRAINING_SUGGESTIONS_REQUEST":
      return state.merge({
        loading: true,
        error: false,
      });

    case "FETCH_TRAINING_SUGGESTIONS_SUCCESS": {
      const suggestionRecords = Immutable.List(action.data.suggestions).map(
        (suggestion) => new TrainingSuggestionRecord(suggestion),
      );

      return state.merge({
        loading: false,
        loaded: true,
        suggestions: suggestionRecords,
        error: suggestionRecords.size === 0,
      });
    }

    case "REMOVE_TRAINING_SUGGESTIONS":
      return state.merge({
        suggestions: state.suggestions.filter(
          (suggestion: TrainingSuggestionRecord) =>
            !action.data.suggestions.find(
              (removeSuggestion: TrainingSuggestionRecord) =>
                suggestion._id === removeSuggestion._id,
            ),
        ),
      });
    case "CLEAR_TRAINING_SUGGESTIONS":
      return state.merge({
        suggestions: Immutable.List(),
        error: false,
      });
    case "FETCH_TRAINING_SUGGESTIONS_FAILURE":
      return state.merge({
        loading: false,
        error: true,
      });
    case "HIDE_TRAINING_SUGGESTIONS":
      return state.merge({
        hidden: true,
      });
    case "SHOW_TRAINING_SUGGESTIONS":
      return state.merge({
        hidden: false,
      });
    default:
      return state;
  }
};
