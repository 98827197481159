import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";

import { removePlatform as removePlatformAction } from "actions";
import { createAlert as createAlertAction } from "actions/alerts";
import { AlertType } from "actions/alerts/types";
import { closeModal as closeModalAction } from "actions/modal";
import {
  createOne as createOneAction,
  deleteOne as deleteOneAction,
  fetchMany,
  resetOne,
  resetOne as resetOneAction,
  saveOne as saveOneAction,
  updateOne as updateOneAction,
} from "actions/resources";
import { getResources } from "selectors/resources";

import IntroScreen from "./IntroScreen";
import SettingsScreen from "./SettingsScreen";
import "./style.scss";

const INTRO_SCREEN = 1;
const SETTINGS_SCREEN = 2;

const ModalScreenMap = {
  [INTRO_SCREEN]: IntroScreen,
  [SETTINGS_SCREEN]: SettingsScreen,
};

const SunshineConversationsModal = ({
  updateOne,
  channel,
  closeModal,
  createAlert,
  createOne,
  saveOne,
  fetchChannels,
  deleteOne,
  removePlatform,
}) => {
  const isPublished = !!channel?.id;

  const newSunshineChannel = {
    name: "sunshine",
    enabled: true,
    apiKey: "",
    apiSecret: "",
    appId: "",
  };

  const initialScreen = isPublished ? SETTINGS_SCREEN : INTRO_SCREEN;
  const [modalScreen, setModalScreen] = useState(initialScreen);
  const [newChannel, setNewChannel] = useState(newSunshineChannel);
  const [loading, setLoading] = useState(false);
  const [errorInfo, setError] = useState(null);

  const modalRef = useRef();

  const newChannelFilledIn = Object.values(newChannel).every((val) => !!val);
  const existingChannelChanged = !channel?.isDirty || !channel?.isValid;
  const isSaveDisabled = isPublished
    ? existingChannelChanged
    : !newChannelFilledIn;

  const updateSunshineAuth = (payload) => {
    setError(null);

    return isPublished
      ? updateOne("channel", channel.id, payload)
      : setNewChannel({ ...newChannel, ...payload });
  };

  const onSaveSuccess = () => {
    setLoading(false);

    createAlert({
      message: "Settings have been successfully updated.",
      alertType: AlertType.SUCCESS,
    });

    closeModal();
  };

  const onSaveError = (error) => {
    setLoading(false);

    setError(error?.data?.message);

    if (isPublished) {
      resetOne("channel", channel.id);
    }
  };

  const saveSunshineChannel = () => {
    setLoading(true);
    updateOne("channel", channel.id, {
      apiKey: channel.apiKey.trim(),
      apiSecret: channel.apiSecret.trim(),
    });

    if (isPublished) {
      saveOne("channel", channel.id).then(onSaveSuccess).catch(onSaveError);
    } else {
      createOne("channel", newChannel).then(onSaveSuccess).catch(onSaveError);
    }
  };

  const checkOauthStatus = async () => {
    setLoading(false);

    const res = await fetchChannels();

    if (res?.data?.channels?.find((c) => c.name === "sunshine")) {
      setModalScreen(SETTINGS_SCREEN);
    }
  };

  const openOauthWindow = () => {
    setLoading(true);

    const oauthUrl = "/api/oauth/sunco/authorize";
    const windowRef = window.open(oauthUrl);

    const timer = setInterval(() => {
      if (windowRef.closed) {
        clearInterval(timer);
        checkOauthStatus();
      }
    }, 500);
  };

  const disconnect = () => {
    setLoading(true);
    deleteOne("channel", channel.id).then(onSaveSuccess).catch(onSaveError);
    removePlatform("zaw_liveagent");
  };

  const CurrentModalScreen = ModalScreenMap[modalScreen];
  const ModalScreenProps = {
    [INTRO_SCREEN]: {
      openOauthWindow,
      closeModal,
      loading,
    },
    [SETTINGS_SCREEN]: {
      channel: channel || newChannel,
      update: updateSunshineAuth,
      save: saveSunshineChannel,
      disabled: isSaveDisabled,
      loading,
      closeModal,
      modalRef,
      errorInfo,
      disconnect,
    },
  };

  return (
    <div
      className="SettingsChannelsSunshineConversationsModal Modal__modal"
      ref={modalRef}
    >
      <CurrentModalScreen {...ModalScreenProps[modalScreen]} />
    </div>
  );
};

SunshineConversationsModal.propTypes = {
  updateOne: PropTypes.func.isRequired,
  channel: PropTypes.objectOf({
    id: PropTypes.string,
    name: PropTypes.string,
    appId: PropTypes.string,
    apiKey: PropTypes.string,
    apiSecret: PropTypes.string,
  }),
  createOne: PropTypes.func.isRequired,
  saveOne: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  createAlert: PropTypes.func.isRequired,
  fetchChannels: PropTypes.func.isRequired,
  deleteOne: PropTypes.func.isRequired,
  removePlatform: PropTypes.func.isRequired,
};

SunshineConversationsModal.defaultProps = {
  channel: null,
};

/**
 * map state to props
 * @param {Object} state
 */
function mapState(state) {
  const channels = getResources(state, "channel");
  const channel = channels.find((c) => c.name === "sunshine");

  return {
    channel,
  };
}

/**
 * Redux map dispatch to props
 * @param {Function} dispatch
 */
function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      updateOne: updateOneAction,
      closeModal: closeModalAction,
      createAlert: createAlertAction,
      saveOne: saveOneAction,
      createOne: createOneAction,
      resetOne: resetOneAction,
      fetchChannels: () => fetchMany("channel"),
      deleteOne: deleteOneAction,
      removePlatform: removePlatformAction,
    },
    dispatch,
  );
}

const Connector = connect(mapState, mapDispatch)(SunshineConversationsModal);

export default Connector;
