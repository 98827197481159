import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";

import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputSearch } from "components/Common/InputSearch";
import SvgIcon from "components/Common/SvgIcon";
import Variable from "components/Common/Variable";
import { getVariableName } from "services/records/variables";
import colors from "stylesheets/utilities/colors.scss";

import "./style.scss";
import { withReadOnlyWrapper } from "../ReadOnlyWrapper";

class VariableSearchSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      resultsVisible: false,
      searchQuery: "",
      rightAligned: false,
      bottomAligned: false,
      elementHeight: null,
      elementWidth: null,
    };

    /**
     * @constant
     * @type {number}
     */
    this.RESULTS_WIDTH = 216;

    /**
     * @constant
     * @type {number}
     */
    this.RESULTS_MAX_HEIGHT = 208;

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSearchQueryChange = this.handleSearchQueryChange.bind(this);

    this.inputElement = React.createRef();
    this.variablesList = React.createRef();
    this.variableSearchSelect = React.createRef();
  }

  componentDidMount() {
    this.setState({
      elementHeight:
        this.variableSearchSelect.current.getBoundingClientRect().height,
      elementWidth:
        this.variableSearchSelect.current.getBoundingClientRect().width,
    });
  }

  componentDidUpdate(_, prevState) {
    this.handleResultsToggle(prevState);
  }

  /**
   * Returns the variable record
   * @param {String} variableId
   *
   * @returns {Immutable.Record}
   */
  getVariable(variableId) {
    const { variables } = this.props;

    return variables.find((variable) => variable.id === variableId);
  }

  /**
   * Returns variables matching search query
   *
   * @returns {List}
   */
  getFilteredVariables() {
    const { variables, excludeTypes } = this.props;
    const { searchQuery } = this.state;

    let variablesToShow = variables.filter(
      (variable) =>
        getVariableName(variable).toLowerCase().indexOf(searchQuery) > -1,
    );

    if (Array.isArray(excludeTypes) && excludeTypes.length > 0) {
      variablesToShow = variablesToShow.filter(
        (variable) => !excludeTypes.includes(variable.type),
      );
    }

    return variablesToShow;
  }

  /**
   * Returns list of variable elements
   *
   * @returns {Array}
   */
  getVariablesList() {
    const { activeIndex } = this.state;
    const { onVariableClick, noVariablesFoundMessage } = this.props;
    const filteredVariables = this.getFilteredVariables();

    if (!filteredVariables.size && noVariablesFoundMessage) {
      return (
        <li className="VariableSearchSelect__results__empty">
          {noVariablesFoundMessage}
        </li>
      );
    }

    return filteredVariables.map((variable, i) => {
      const isSelected = i === activeIndex;

      return (
        <li
          className={classNames(
            "VariableSearchSelect__results__variables__item",
            {
              "VariableSearchSelect__results__variables__item--selected":
                isSelected,
            },
          )}
          key={variable.id}
        >
          <button
            className="VariableSearchSelect__results__variables__item__button"
            onClick={() => {
              onVariableClick(variable.id);
              this.setState({ resultsVisible: false });
            }}
            onMouseDown={this.handleMouseDown.bind(this, i)}
            type="button"
          >
            <div className="VariableSearchSelect__results__variables__item__button__variable-container">
              <Variable
                name={getVariableName(variable)}
                scope={variable.get("scope")}
                type={variable.get("type")}
              />
            </div>
          </button>
        </li>
      );
    });
  }

  /**
   * Returns the element in the list corresponding to the active index
   *
   * @param {Number} activeIndex
   *
   * @returns {Element}
   */
  getActiveElementNode(activeIndex) {
    return this.variablesList.current.childNodes.item(activeIndex);
  }

  /**
   * Sets activeIndex to selected variable index. Needed to shift active state
   *
   * @param {Number} index
   */
  handleMouseDown(index) {
    this.setState({
      activeIndex: index,
    });
  }

  /**
   *
   * @param {Object} event
   */
  handleKeyDown(event) {
    if (event.keyCode === 40 || event.keyCode === 38) {
      this.handleInputClick(event);
    }
  }

  /**
   * Clears the search input value
   */
  handleSearchClear() {
    this.setState({
      searchQuery: "",
    });
  }

  /**
   * Set search query
   *
   * @param {Object} event
   */
  handleSearchQueryChange(event) {
    this.setState({
      searchQuery: event.target.value,
    });
  }

  /**
   * Add event hooks for click and keydown
   */
  addEventListeners() {
    document.addEventListener("keydown", this.handleKeyPress, true);
    document.addEventListener("click", this.handleClickOutside, true);
  }

  /**
   * Strip event hooks
   */
  removeEventListeners() {
    document.removeEventListener("keydown", this.handleKeyPress, true);
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  /**
   * Handle activeIndex state change, as well as binding/unbinding
   * event listeners when dropdown is opened/closed
   *
   * @param {Object} prevState
   */
  handleResultsToggle(prevState) {
    const { resultsVisible } = this.state;
    const { value } = this.props;

    if (prevState.resultsVisible !== resultsVisible) {
      if (resultsVisible) {
        const filteredVariables = this.getFilteredVariables();
        const newActiveIndex = filteredVariables.findIndex(
          (variable) => variable.id === value,
        );

        this.setState(
          {
            activeIndex: newActiveIndex > 0 ? newActiveIndex : 0,
          },
          () => {
            const { activeIndex } = this.state;
            const activeNode = this.getActiveElementNode(activeIndex);

            if (!activeNode) {
              return;
            }

            this.variablesList.current.scrollTop = activeNode.offsetTop;
          },
        );

        this.addEventListeners();
      } else {
        this.removeEventListeners();
      }
    }
  }

  /**
   * Set results to visible, as well as properties needed for positioning the dropdown
   *
   * @param {Object} event
   */
  handleInputClick(event) {
    const { resultsVisible } = this.state;
    const clientRect = event.target.getBoundingClientRect();

    this.setState({
      resultsVisible: !resultsVisible,
      bottomAligned:
        clientRect.top + this.RESULTS_MAX_HEIGHT >= window.innerHeight,
      rightAligned: window.innerWidth - clientRect.right <= this.RESULTS_WIDTH,
    });
  }

  /**
   * Handle keyDown event to leverage arrow keys and enter keypresses
   *
   * @param {Object} event
   */
  handleKeyPress(event) {
    const { disabled } = this.props;

    if (disabled) {
      return;
    }

    const { activeIndex } = this.state;
    const filteredVariables = this.getFilteredVariables();

    if (event.keyCode === 9) {
      this.setState({
        resultsVisible: false,
      });
    } else if (event.keyCode === 38 && activeIndex > 0) {
      // Arrow up
      this.handleArrow(true);
    } else if (
      event.keyCode === 40 &&
      activeIndex < filteredVariables.size - 1
    ) {
      // Arrow down
      this.handleArrow(false);
    } else if (event.keyCode === 13) {
      this.handleClose(filteredVariables.getIn([activeIndex]));
    } else if (event.keyCode === 27) {
      this.setState({
        resultsVisible: false,
      });
    }
  }

  /**
   * Handle setting next active index, as well as scrolling dropdown window if needed
   *
   * @param {Boolean} isUp
   */
  handleArrow(isUp) {
    const direction = isUp ? -1 : 1;

    this.setState(
      (prevState) => ({
        activeIndex: prevState.activeIndex + direction,
      }),
      () => {
        const { activeIndex } = this.state;
        const newActiveElement = this.getActiveElementNode(activeIndex);

        if (!newActiveElement) {
          return;
        }

        if (
          newActiveElement.offsetTop + newActiveElement.offsetHeight >
          this.variablesList.current.scrollTop +
            this.variablesList.current.offsetHeight
        ) {
          this.variablesList.current.scrollTop =
            newActiveElement.offsetTop +
            newActiveElement.offsetHeight -
            this.variablesList.current.offsetHeight;
        } else if (
          this.variablesList.current.scrollTop > newActiveElement.offsetTop
        ) {
          this.variablesList.current.scrollTop = newActiveElement.offsetTop;
        }
      },
    );
  }

  /**
   * Closes results list, and propogates on click event with variableId
   *
   * @param {Record} variable
   */
  handleClose(variable) {
    const { onVariableClick } = this.props;

    this.setState(
      {
        resultsVisible: false,
      },
      () => {
        setTimeout(() => {
          if (variable) {
            onVariableClick(variable.id);
          }

          this.inputElement.current.focus();
        }, 0);
      },
    );
  }

  /**
   * Closes the results list if the user clicks on anything but the VariableSearchSelect component
   *
   * @param {Object} event
   */
  handleClickOutside(event) {
    if (!this.variableSearchSelect.current.contains(event.target)) {
      this.setState({
        resultsVisible: false,
      });
    }
  }

  /**
   * Determine if variable is orphaned
   *
   * @param {String} variableId
   */
  isVariableOrphaned(variableId) {
    // if there's a variableId but no variable, then the variable has been orphaned
    return variableId && !this.getVariable(variableId);
  }

  /**
   * Render the variable for the variable input
   *
   * @param {String} variableId
   *
   * @returns {ReactElement}
   */
  renderVariablePill(variableId) {
    const variable = this.getVariable(variableId);

    if (variable) {
      return (
        <Variable
          name={getVariableName(variable)}
          scope={variable.get("scope")}
          type={variable.get("type")}
        />
      );
    }

    if (variableId) {
      // if there's a variableId but no variable, then the variable has been orphaned
      // TODO: (FND-730) persist the variable name somehow instead of just showing "Ref Error"
      return (
        <span className={classNames("variableEntity", "variableEntity--error")}>
          Ref Error
        </span>
      );
    }

    // TODO: FND-507 will move these styles and rename these classes
    return (
      <span
        className={classNames(
          "HTTPBlock__list__cell__key__inner",
          "HTTPBlock__list__cell__key__inner--color-choose",
          "ResponseEditorEditableMessage__body__variables--clickable",
        )}
      >
        Choose a Variable
      </span>
    );
  }

  /**
   * Render the variable input button
   *
   * @returns {ReactElement}
   */
  renderButton() {
    const { value, isInvalid, noBackground, customWarning, disabled } =
      this.props;

    return (
      <button
        className={classNames(
          "VariableSearchSelect__variable-input",
          "g-input",
          {
            "g-input--no-background": noBackground,
            "VariableSearchSelect__variable-input__disabled": disabled,
          },
        )}
        onClick={this.handleInputClick}
        onKeyDown={this.handleKeyDown}
        tabIndex="0"
        ref={this.inputElement}
        type="button"
        disabled={disabled}
      >
        {this.renderVariablePill(value)}
        <div className="VariableSearchSelect__variable-input__icon-container">
          {(isInvalid || this.isVariableOrphaned(value)) && (
            <InfoTooltip
              blurb={
                this.isVariableOrphaned(value)
                  ? "The insert has been deleted and can't be used"
                  : customWarning
              }
              iconDefault="Warning"
              iconClicked="Warning"
              absolute
              openOnHover
              customClassName={classNames(
                "VariableSearchSelect__variable-input__icon-container__invalid",
              )}
            />
          )}
          <SvgIcon
            icon="ChevronDown"
            customClassName="VariableSearchSelect__variable-input__icon-container__icon"
            fillColor={colors.colorGrey4}
          />
        </div>
      </button>
    );
  }

  /**
   * Render the dropdown
   *
   * @returns {ReactElement|null}
   */
  renderResults() {
    const { disabled } = this.props;
    const {
      searchQuery,
      elementHeight,
      elementWidth,
      resultsVisible,
      bottomAligned,
      rightAligned,
    } = this.state;

    if (!resultsVisible || disabled) {
      return null;
    }

    return (
      <div
        className="VariableSearchSelect__results"
        style={{
          width: this.RESULTS_WIDTH,
          minWidth: elementWidth,
          maxHeight: this.RESULTS_MAX_HEIGHT,
          [rightAligned ? "right" : "left"]: 0,
          [bottomAligned ? "bottom" : "top"]: elementHeight + 8,
        }}
      >
        <InputSearch
          customClassName="VariableSearchSelect__results__search"
          value={searchQuery}
          onChange={this.handleSearchQueryChange}
          onClear={this.handleSearchClear}
          placeholder="Search Variables"
          hideChevron
          autoFocus
        />
        <ul
          className="VariableSearchSelect__results__variables"
          ref={this.variablesList}
        >
          {this.getVariablesList()}
        </ul>
      </div>
    );
  }

  render() {
    const { customClassName } = this.props;

    return (
      <div
        className={classNames("VariableSearchSelect", {
          [customClassName]: customClassName,
        })}
        ref={this.variableSearchSelect}
      >
        {this.renderButton()}
        {this.renderResults()}
      </div>
    );
  }
}

VariableSearchSelect.defaultProps = {
  excludeTypes: [],
  value: null,
  isInvalid: false,
  noBackground: false,
  customClassName: null,
  disabled: false,
  customWarning: "Your condition needs a variable to compare",
  noVariablesFoundMessage: "No variables found",
};

VariableSearchSelect.propTypes = {
  /** The selected variable id */
  value: PropTypes.string,
  /** Specifies if input is invalid */
  isInvalid: PropTypes.bool,
  /** Specifies if disabled */
  disabled: PropTypes.bool,
  /** No background style */
  noBackground: PropTypes.bool,
  /** Specify a custom class name from parent */
  customClassName: PropTypes.string,
  /** List of all variables */
  variables: ImmutablePropTypes.list.isRequired,
  /** On variable click event handler */
  onVariableClick: PropTypes.func.isRequired,
  /** Custom warning message when isInvalid is true */
  customWarning: PropTypes.string,
  /** Types of variables to exclude from showing up in the picker */
  excludeTypes: PropTypes.arrayOf(PropTypes.string),
  /** What to show when no results are found */
  noVariablesFoundMessage: PropTypes.node,
};

export default withReadOnlyWrapper(VariableSearchSelect);
