import styled from "styled-components";

import { c, v } from "constants/css";

export const Container = styled.div`
  padding: ${v.gUnitDouble};
`;

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  font-family: Inter, sans-serif;
  font-weight: ${v.mediumFontWeight};
  font-size: ${v.smallFontSize};
  line-height: ${v.mediumLineHeight};
  color: ${c.colorBlack};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

export const Description = styled.div`
  font-family: Inter, sans-serif;
  font-size: ${v.xSmallFontSize};
  line-height: ${v.smallLineHeight};
  color: ${c.colorGrey4};
`;

export const ButtonContainer = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PopoutLink = styled.a`
  align-items: center;
  display: flex;
  font-weight: ${v.heavyFontWeight};
  justify-content: center;
  text-decoration: none;
`;

export const PopoutLinkText = styled.a`
  display: inline-block;
  margin-left: 8px;
`;

export const PopoutIcon = styled.div`
  width: 20px;
  vertical-align: bottom;
`;

export const ModalBody = styled.section`
  height: 404px;
  padding: ${v.gUnitDouble};
  font-size: ${v.smallFontSize};
  border-bottom: 1px solid ${c.colorGrey1};
`;

export const ColumnCentered = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`;
