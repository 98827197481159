import Immutable from "immutable";

import { ResponseRecord } from "reducers/responses/types";
import { type MessagesMap } from "services/responses";

/**
 * I really wish I could just put this as a default prop. However, since it depends on the client
 * language, this isn't possible.
 */
export function generateNewResponseRecord(language: string) {
  // Just copying over the existing default values we currently use
  return new ResponseRecord().merge({
    buttonLabel: Immutable.Map({
      [language]: null,
    }),
    messages: Immutable.Map({
      [language]: Immutable.List([]),
    }) as MessagesMap,
    options: Immutable.Map({
      [language]: Immutable.List([]),
    }),
    new: true,
    reviewable: true,
  });
}
