// @ts-strict-ignore
import {
  type ConversationSummary,
  type ConversationSummaryResponse,
} from "./types";

export const mapConversationSummaryPropertyNames = (
  summary: ConversationSummaryResponse,
): ConversationSummary => ({
  created: summary.created ?? summary.date,
  id: summary._id,
  conversationId: summary.conversation_id,
  isAccurate: summary.is_accurate,
  summary: summary.summary,
  chatterName: summary.chatter_name || "Anonymous",
  chatterId: summary.chatter_id,
});
