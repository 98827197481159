import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { updatePlatform } from "actions";
import { createAlert } from "actions/alerts";
import { AlertType } from "actions/alerts/types";
import { closeModal } from "actions/modal";
import { type ZAWPlatformAttributes } from "reducers/platforms/models/ZendeskAgentWorkspacePlatformRecord";
import { type Platform } from "reducers/platforms/types";
import { adaAPI } from "services/api";
import { useClient } from "services/client";
import { keyConverter } from "services/key-converter";

import { IntroScreen } from "./IntroScreen";
import { SettingsScreen } from "./SettingsScreen";

import "./style.scss";

const INTRO_SCREEN = 1;
const SETTINGS_SCREEN = 2;

export const ZendeskAgentWorkspaceModal = () => {
  const platformType = "zaw_liveagent";
  const platform = useSelector((state) =>
    state.platforms.get(platformType),
  ) as Platform;
  const { client } = useClient();
  const dispatch = useDispatch();

  const initialScreen = platform.get("isPublished")
    ? SETTINGS_SCREEN
    : INTRO_SCREEN;
  const [modalScreen, setModalScreen] = React.useState(initialScreen);
  const [errorInfo, setErrorInfo] = React.useState(undefined);

  const isSaveDisabled =
    (platform.get("isPublished") && !platform.get("isDirty")) ||
    !platform.get("isValid") ||
    platform.get("isLoading");

  const updateZendeskAgentWorkspacePlatform = (
    payload: Partial<ZAWPlatformAttributes>,
  ) => {
    dispatch(updatePlatform(platformType, payload));
  };

  const onSaveSuccess = (savedPlatform: Platform) => {
    setErrorInfo(undefined);
    dispatch(
      updatePlatform(platformType, savedPlatform.get("record"), {
        isPublished: true,
        isLoading: false,
      }),
    );
    dispatch(
      createAlert({
        message: "Successfully saved platform.",
        alertType: AlertType.SUCCESS,
      }),
    );
    dispatch(closeModal());
  };

  // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSaveError = (error: any) => {
    dispatch(
      updatePlatform(
        platformType,
        {},
        {
          isLoading: false,
        },
      ),
    );
    let apiError;

    try {
      apiError = JSON.parse(error.response.data.message);
    } catch (e) {
      apiError = { type: "generic", message: error.response.data.message };
    }

    setErrorInfo(apiError);
  };

  const saveClientPlatform = async (platformToSave: Platform) => {
    const isPublished = platformToSave.get("isPublished");
    dispatch(
      updatePlatform(
        platformType,
        {},
        {
          isLoading: true,
        },
      ),
    );

    try {
      await adaAPI.request({
        url: isPublished ? `/platforms/${platformType}/` : "/platforms/",
        method: isPublished ? "patch" : "post",
        data: keyConverter(platformToSave.get("record").toJS(), "underscore"),
      });
      onSaveSuccess(platformToSave);
    } catch (error) {
      onSaveError(error);
    }
  };

  return (
    <div className="SettingsPlatformsZendeskAgentWorkspaceModal Modal__modal">
      <h5 className="Modal__title">Configure Zendesk Messaging</h5>
      {modalScreen === INTRO_SCREEN && (
        <IntroScreen
          goToNextScreen={() => setModalScreen(SETTINGS_SCREEN)}
          closeModal={() => dispatch(closeModal)}
          clientHasZendeskMessaging={
            client.features.ada_glass_zendesk_messaging || false
          }
        />
      )}
      {modalScreen === SETTINGS_SCREEN && (
        <SettingsScreen
          saveClientPlatform={saveClientPlatform}
          clientHasZendeskMessaging={
            client.features.ada_glass_zendesk_messaging || false
          }
          updateZendeskAgentWorkspacePlatform={
            updateZendeskAgentWorkspacePlatform
          }
          isSaveDisabled={isSaveDisabled}
          closeModal={closeModal}
          platform={platform}
          errorInfo={errorInfo}
        />
      )}
    </div>
  );
};
