const initialConversationsList = {
  selectedLog: null,
  isTransitioning: false,
  isTraining: false,
};

export const conversationsList = (state = initialConversationsList, action) => {
  switch (action.type) {
    case "SET_CONVERSATIONS_LIST":
      return { ...state, ...action.payload };
    case "CREATE_EXPRESSION_REQUEST":
      return { ...state, isTraining: true };
    case "CREATE_EXPRESSION_SUCCESS":
    case "CREATE_EXPRESSION_FAILURE":
      return {
        ...state,
        selectedLog: null,
        isTransitioning: false,
        isTraining: false,
      };
    default:
      return state;
  }
};
