import { type AnyAction } from "@reduxjs/toolkit";

import {
  createConversationsTopic,
  createConversationsTopicsDefaultState,
} from "./factory";
import {
  mapConversationsTopicPropertyNames,
  mapCsatCommentPropertyNames,
  mapUnansweredQuestionPropertyNames,
} from "./objectMapper";
import { type ConversationsTopicsState } from "./types";

export const conversationsTopics = (
  state = createConversationsTopicsDefaultState(),
  action: AnyAction,
): ConversationsTopicsState => {
  switch (action.type) {
    case "FETCH_CONVERSATIONS_TOPICS_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "FETCH_CONVERSATIONS_TOPICS_SUCCESS":
      return {
        ...state,
        loaded: true,
        loading: false,
        conversationsTopics: action.conversationsTopics
          ? action.conversationsTopics.map(createConversationsTopic)
          : state.conversationsTopics,
      };

    case "ADD_CONVERSATIONS_TOPIC":
      return {
        ...state,
        draftConversationsTopic: createConversationsTopic({
          name: action.name,
          description: action.description,
        }),
      };

    case "FETCH_CONVERSATIONS_TOPIC_REQUEST":
      return { ...state, isLoadingConversationsTopic: true };

    case "FETCH_CONVERSATIONS_TOPIC_SUCCESS":
      return {
        ...state,
        isLoadingConversationsTopic: false,
        activeConversationsTopic: action.conversationsTopic
          ? mapConversationsTopicPropertyNames(action.conversationsTopic)
          : null,
      };

    case "FETCH_CONVERSATIONS_TOPIC_FAILURE":
      return { ...state, isLoadingConversationsTopic: false };

    case "FETCH_CONVERSATIONS_TOPICS_CSAT_COMMENTS_REQUEST": {
      return {
        ...state,
        conversationsTopicCsatComments: {
          ...state.conversationsTopicCsatComments,
          loaded: false,
          loading: true,
        },
      };
    }

    case "FETCH_CONVERSATIONS_TOPICS_CSAT_COMMENTS_SUCCESS": {
      const loadedComments = state.conversationsTopicCsatComments.data;
      const returnedComments = action.csatComments.map(
        mapCsatCommentPropertyNames,
      );

      const data = action.clearShallow
        ? returnedComments
        : [...loadedComments, ...returnedComments];

      return {
        ...state,
        conversationsTopicCsatComments: {
          ...state.conversationsTopicCsatComments,
          data,
          loaded: true,
          loading: false,
          page: action.page,
        },
      };
    }

    case "FETCH_CONVERSATIONS_TOPICS_CSAT_COMMENTS_FAILURE": {
      return {
        ...state,
        conversationsTopicCsatComments: {
          ...state.conversationsTopicCsatComments,
          loaded: false,
          loading: false,
        },
      };
    }

    case "CLEAR_CONVERSATIONS_TOPICS_CSAT_COMMENTS": {
      return {
        ...state,
        conversationsTopicCsatComments: {
          ...state.conversationsTopicCsatComments,
          data: [],
          loaded: true,
          loading: false,
        },
      };
    }

    case "FETCH_CONVERSATIONS_UNTRAINED_EXPRESSIONS_REQUEST": {
      return {
        ...state,
        conversationsTopicUnansweredQuestions: {
          ...state.conversationsTopicUnansweredQuestions,
          loaded: false,
          loading: true,
        },
      };
    }

    case "FETCH_CONVERSATIONS_UNTRAINED_EXPRESSIONS_SUCCESS": {
      const loadedExpressions =
        state.conversationsTopicUnansweredQuestions.data;

      const returnedExpressions = action.unansweredQuestions.map(
        mapUnansweredQuestionPropertyNames,
      );

      const data = action.clearShallow
        ? returnedExpressions
        : [...loadedExpressions, ...returnedExpressions];

      return {
        ...state,
        conversationsTopicUnansweredQuestions: {
          ...state.conversationsTopicUnansweredQuestions,
          data,
          loading: false,
          loaded: true,
          page: action.page,
        },
      };
    }

    case "FETCH_CONVERSATIONS_UNTRAINED_EXPRESSIONS_FAILURE": {
      return {
        ...state,
        conversationsTopicUnansweredQuestions: {
          ...state.conversationsTopicUnansweredQuestions,
          loaded: false,
          loading: false,
        },
      };
    }

    case "CLEAR_CONVERSATIONS_UNTRAINED_EXPRESSIONS": {
      return {
        ...state,
        conversationsTopicUnansweredQuestions: {
          ...state.conversationsTopicUnansweredQuestions,
          data: [],
          loaded: true,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};
