import type Immutable from "immutable";

import { LOCAL_VARIABLE_SCOPE } from "constants/variables";
import { type VariableState } from "reducers/variables/types";
import { memoize } from "services/memoize";
import { type VariableRecord } from "services/variables";

export function getVariableName(variable: VariableRecord) {
  let { name } = variable;

  switch (name) {
    case "chatter_id":
      name = "Customer ID";
      break;
    case "chatter_token":
      name = "Customer Token";
      break;
    default:
      name = variable.displayName || name;
      break;
  }

  return name;
}

export const flattenVariables = (variables: VariableState) =>
  variables.valueSeq().toList().flatten(2) as Immutable.List<VariableRecord>;

interface GetVariablesOptions {
  scopes?: string[] | null;
  responseId?: string | null;
}

/**
 * Returns an Immutable List of variable records based on specified scopes
 */
export const getVariables = memoize(
  (
    variables: VariableState,
    { scopes = null, responseId = null }: GetVariablesOptions,
  ) =>
    flattenVariables(variables).filter(
      (variable) =>
        // Either no scopes specified, or the variable must match one of the scopes
        (!scopes || scopes.includes(variable.scope)) &&
        // Either no responseId specified,
        // or: either the variable is not local, or it is local and must have specified responseId
        (!responseId ||
          variable.scope !== LOCAL_VARIABLE_SCOPE ||
          variable.responseId === responseId),
    ),
);

/** @deprecated */
export const getVariableById = (variableId: string, variables: VariableState) =>
  flattenVariables(variables).find(
    (variable: VariableRecord) => variable.id === variableId,
  );

export function getVariable(
  variableId: string,
  variables: VariableState,
  includeMeta = false,
) {
  let variable = getVariableById(variableId, variables.delete("meta"));

  if (!variable && includeMeta) {
    variable = variables.get("meta").find((v) => v.id === variableId);
  }

  return variable;
}

export const varRegexp = /\{([\w]+)\|([^}]*?)\}/gi;
