import Immutable from "immutable";

import {
  ALL_MODALITIES,
  MODALITY_DETAILS,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type MessageBlock } from "reducers/messageBlocks/types";
import { MESSAGE_RECORDS } from "reducers/responses/reducer";
import { type ResponseRecord } from "reducers/responses/types";
import { type LanguageToMessagesMap } from "reducers/responsesLoaded/types";
import { type Channel } from "resourceModels/Channel";
import { type MessageType } from "selectors/blocks";

export * from "./actions";
export * from "./api";
export * from "./hooks";
export * from "./selectors";
export * from "./translation-preview";
export * from "./types";
export * from "reducers/responses/messageRecords";

export function getMessageTypes(
  obj: Record<string, unknown> | null,
  found: Set<MessageType> = new Set(),
) {
  // searches for message types recursively
  // this handles nested messages
  if (typeof obj !== "object" || obj === null) return found;

  if (Immutable.isImmutable(obj)) {
    obj.toSeq().forEach((v) => {
      if (v?.type in MESSAGE_RECORDS) {
        found.add(v.type);
      }

      getMessageTypes(v, found);
    });
  }

  return found;
}

export function computedMessageTypes(
  response: ResponseRecord,
): Immutable.List<string> {
  let types = new Set<string>();
  ALL_MODALITIES.forEach((modality) => {
    const modalityMessages = response.get(
      MODALITY_DETAILS[modality].messageField,
    ) as LanguageToMessagesMap;
    const found = getMessageTypes(modalityMessages);
    types = new Set([...types, ...found]);
  });

  return Immutable.List(types);
}

export function hasLimitedChannelSupport(
  response: ResponseRecord,
  channels?: Immutable.List<Channel>,
  messageBlocks?: MessageBlock[],
) {
  if (!channels || !messageBlocks) {
    return false;
  }

  const responseMessageBlocks = messageBlocks.filter((mb) =>
    computedMessageTypes(response).includes(mb.messageType),
  );

  const enabledChannelNames = channels
    .filter((c) => c.enabled)
    .map((c) => c.name);

  return responseMessageBlocks.some((mb) =>
    mb.unsupportedChannels.some((channelName) =>
      enabledChannelNames.includes(channelName),
    ),
  );
}

// fill in reservedResponseTypes that should be labelled NEW here, e.g. "negative_csat"
const NEW_RESERVED_RESPONSE_TYPES: string[] = [];

export function hasNewLabel(response: ResponseRecord): boolean {
  return (
    NEW_RESERVED_RESPONSE_TYPES.includes(
      response.reservedResponseType as string,
    ) &&
    new Date(parseInt(response.created as string, 10) * 1000) >=
      new Date("2020-10-13")
  );
}
