import classNames from "classnames";
import React from "react";

import "./style.scss";
import { NO_OP_FUNCTION } from "services/helpers";

import cache from "./iconCache";

interface Props {
  icon: string;
  cursor?: string;
  className?: string;
  customClassName?: string;
  fillColor?: string;
  height?: number;
  link?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
  onMouseUp?: () => void;
  preserveAspectRatio?: string;
  stroke?: string;
  strokeDashArray?: string;
  strokeWidth?: number;
  viewBox?: string;
  disabled?: boolean;
  dataTestId?: string;
}

export function SvgIcon(props: Props) {
  const {
    fillColor,
    stroke,
    strokeDashArray,
    strokeWidth,
    cursor,
    height,
    icon,
    className,
    customClassName,
    link,
    onMouseUp,
    disabled,
    onClick = NO_OP_FUNCTION,
    viewBox,
    preserveAspectRatio,
    dataTestId = "",
  } = props;

  const combinedClassName = classNames(customClassName, className);

  // TODO: find a less hacky way of doing this
  // This is a hack for the Action Integration block. Haven't been able to figure out
  // how to get an svg string to work with jsx.
  if (icon && icon.slice(0, 4) === "<svg") {
    return (
      <img
        src={`data:image/svg+xml;utf8,${encodeURIComponent(icon)}`}
        className={`SvgIcon__fromSvgString ${combinedClassName}`}
        alt="" // Image is supplementary to text title
        data-testid={dataTestId}
      />
    );
  }

  if (icon && icon.substring(icon.length - 4) === ".svg") {
    return (
      <img
        src={icon}
        className={`SvgIcon__fromSvgString ${combinedClassName}`}
        alt="" // Image is supplementary to text title
        data-testid={dataTestId}
      />
    );
  }

  const image = cache.get(`./${icon}.svg`);

  const renderIcon = () => (
    <svg
      viewBox={viewBox || "0 0 64 64"}
      preserveAspectRatio={preserveAspectRatio}
      className={classNames(
        `SvgIcon${link ? "__custom" : ""} ${combinedClassName}`,
        {
          "SvgIcon--disabled": disabled,
        },
      )}
      onClick={(e) => {
        if (disabled) {
          return;
        }

        onClick(e);
      }}
      stroke={stroke}
      strokeDasharray={strokeDashArray}
      strokeWidth={strokeWidth}
      style={{
        cursor,
        height,
        width: height,
        ...(link ? {} : { fill: fillColor }),
      }}
      {...onMouseUp}
      data-testid={dataTestId || `icon-${icon}`}
    >
      {image && <use href={`#${image.default.id}`} />}
    </svg>
  );

  const renderLinkIcon = () => (
    <a
      href={link}
      style={{ height, width: height, color: fillColor }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {renderIcon()}
    </a>
  );

  return link ? renderLinkIcon() : renderIcon();
}

SvgIcon.defaultProps = {
  height: null,
  className: "",
  customClassName: "",
  disabled: false,
};

export default SvgIcon;
