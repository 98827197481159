import {
  type ArticleFilter as Filter,
  Operators,
} from "slices/knowledge/types";

const IN_PREFIX = "in:";
const NOT_IN_PREFIX = "nin:";

function formatEqualityValue(value: boolean | string[]): string {
  if (Array.isArray(value)) {
    return `${IN_PREFIX}${value.join(",")}`;
  }

  return value.toString();
}

function formatInequalityValue(value: boolean | string[]): string {
  if (Array.isArray(value)) {
    return `${NOT_IN_PREFIX}${value.join(",")}`;
  }

  return (!value).toString();
}

function serializeFilterValue(filter: Filter): string {
  switch (filter.operator) {
    case Operators.ISNOT:
      return formatInequalityValue(filter.value);
    default:
      return formatEqualityValue(filter.value);
  }
}

export function serializeFilters(
  filters: Filter[],
): Record<string, string | string[]> {
  return filters.reduce<Record<string, string | string[]>>((result, filter) => {
    const serializedValue = serializeFilterValue(filter);

    const newResult = { ...result };

    if (!newResult[filter.type]) {
      return { ...newResult, [filter.type]: serializedValue };
    }

    // Handle case where the filter type is already present with another value (AND condition)
    if (!Array.isArray(newResult[filter.type])) {
      newResult[filter.type] = [newResult[filter.type] as string];
    }

    return {
      ...newResult,
      [filter.type]: [...(newResult[filter.type] as string[]), serializedValue],
    };
  }, {});
}
