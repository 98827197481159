import { SelectPrimitive } from "@adasupport/byron";
import React from "react";

import {
  LIST_NUMBER_OF_ITEMS_OPERATOR_OPTIONS,
  LIST_OPERATOR_OPTIONS,
} from "components/Common/RulesetEditor/helpers";

const LIST_ALL_OPERATOR_OPTIONS = [
  ...LIST_OPERATOR_OPTIONS,
  ...LIST_NUMBER_OF_ITEMS_OPERATOR_OPTIONS,
];

interface Props {
  value: string | null;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

export function ListVariableOperatorSelector({
  value,
  onChange,
  isDisabled,
}: Props) {
  const currentLabel = LIST_ALL_OPERATOR_OPTIONS.find(
    (option) => option.value === value,
  )?.label;

  return (
    <SelectPrimitive.Root>
      <SelectPrimitive.Trigger
        placeholder="Select an option"
        isDisabled={isDisabled}
      >
        {currentLabel}
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Dropdown>
        <SelectPrimitive.Group>
          {LIST_OPERATOR_OPTIONS.map((option) => (
            <SelectPrimitive.Item
              key={option.value}
              value={option.value}
              isSelected={option.value === value}
              onSelect={onChange}
            >
              {option.label}
            </SelectPrimitive.Item>
          ))}
        </SelectPrimitive.Group>
        <SelectPrimitive.Divider />
        <SelectPrimitive.Group label="Number of items">
          {LIST_NUMBER_OF_ITEMS_OPERATOR_OPTIONS.map((option) => (
            <SelectPrimitive.Item
              key={option.value}
              value={option.value}
              isSelected={option.value === value}
              onSelect={onChange}
            >
              {option.label}
            </SelectPrimitive.Item>
          ))}
        </SelectPrimitive.Group>
      </SelectPrimitive.Dropdown>
    </SelectPrimitive.Root>
  );
}
