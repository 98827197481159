import Immutable from "immutable";

const modalState = Immutable.Map({
  isOpen: false,
});

export const modalSessionTimeout = (state = modalState, action) => {
  switch (action.type) {
    case "SET_MODAL_SESSION_TIMEOUT":
      return state.merge({
        isOpen: true,
      });
    case "CLOSE_MODAL_SESSION_TIMEOUT":
      return state.merge({
        isOpen: false,
      });
    default:
      return state;
  }
};
