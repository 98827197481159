import type * as CSS from "csstype";
import styled from "styled-components";

import SvgIcon from "components/Common/SvgIcon";
import { colorPrimitives, colorTokens, v } from "constants/css";

import { Intent, type StyledBannerProps } from "./types";

const bannerColors = {
  default: colorTokens.statusInformative,
  positive: colorTokens.statusPositive,
  negative: colorTokens.statusNegative,
  warning: colorTokens.statusWarning,
};

const bannerBackgrounds = {
  default: colorPrimitives.blue100,
  positive: colorPrimitives.green100,
  negative: colorPrimitives.red100,
  warning: colorPrimitives.orange100,
};

const getBannerColor = ({ intent = Intent.DEFAULT }: StyledBannerProps) =>
  bannerColors[intent];

const getBannerBackground = ({ intent = Intent.DEFAULT }: StyledBannerProps) =>
  bannerBackgrounds[intent];

export const Banner = styled.div<StyledBannerProps>`
  border-radius: ${v.smallBorderRadius};
  font-size: ${v.smallFontSize};
  line-height: ${v.mediumLineHeight};
  padding: ${v.gUnit} ${v.gUnit} ${v.gUnit} ${v.gUnit};
  position: relative;
  margin-bottom: ${v.gUnit};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ isInline }) =>
    isInline &&
    `
    line-height: ${v.smallLineHeight};
  `}

  color: ${getBannerColor};
  background: ${getBannerBackground};
  svg {
    color: ${getBannerColor};
  }
  a {
    color: ${getBannerColor};
  }
`;

export const LeftContainer = styled.div<{
  childrenVerticalAlignment?: CSS.Property.AlignItems;
}>`
  display: flex;
  align-items: ${(props) => props.childrenVerticalAlignment || "center"};
`;

export const Icon = styled(SvgIcon)`
  pointer-events: none;
  margin-right: ${v.gUnitHalf};
`;

export const CloseIcon = styled(SvgIcon)`
  cursor: pointer;
  transition: 0.2s opacity;
  &:hover {
    opacity: 0.8;
  }
`;
