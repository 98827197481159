// @ts-strict-ignore
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { InputLabel } from "components/Common/InputLabel";
import { InputText } from "components/Common/InputText";
import { useReadOnly } from "components/Common/ReadOnlyWrapper";
import { updateBuilderABTestAction } from "features/ABTesting/actions";
import { type BuilderABTest } from "features/ABTesting/types";

import * as S from "./styles";

const MAX_VARIANT_NAME_LENGTH = 60;

interface Props {
  abTest: BuilderABTest;
  index: number;
}

export function RenameVariantModal(props: Props) {
  const { abTest, index } = props;

  const [variantName, setVariantName] = useState(abTest.variantNames[index]);
  const isVariantNameInvalid =
    !variantName ||
    abTest.variantNames.indexOf(variantName) > -1 ||
    variantName.length > MAX_VARIANT_NAME_LENGTH;

  const { isReadOnly } = useReadOnly();
  const dispatch = useDispatch();

  const handleRenameVariant = () => {
    const newVariantNames = [...abTest.variantNames];
    newVariantNames[index] = variantName;
    dispatch(
      updateBuilderABTestAction(abTest.id, { variantNames: newVariantNames }),
    );
    dispatch(closeModalAction());
  };

  return (
    <div className="Modal__modal Modal__modal--small">
      <S.ModalContent>
        <S.ModalSection>
          <InputLabel label="Variant name">
            <InputText
              value={variantName}
              invalid={isVariantNameInvalid}
              placeholder="Enter a name for your Variant"
              maxLength={MAX_VARIANT_NAME_LENGTH}
              onChange={(newValue: string) => setVariantName(newValue)}
              onKeyPress={(event: React.KeyboardEvent) => {
                if (event.key === "Enter") {
                  handleRenameVariant();
                }
              }}
            />
          </InputLabel>
        </S.ModalSection>
      </S.ModalContent>
      <S.ButtonsContainer className="Modal__bottom">
        <Button
          light
          onClick={() => dispatch(closeModalAction())}
          text="Cancel"
          title="Cancel"
          disabled={false}
        />
        <Button
          text="Rename"
          title="Rename"
          disabled={isReadOnly || isVariantNameInvalid}
          onClick={handleRenameVariant}
        />
      </S.ButtonsContainer>
    </div>
  );
}
