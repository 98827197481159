import Immutable from "immutable";

import { type ClientLegacy } from "resourceModels";
import { getAPIRoot } from "services/api-root";

class ZendeskLAPlatformRecord extends Immutable.Record({
  name: "zendesk_liveagent",
  fullName: "Zendesk Live Agent",
  auth: Immutable.Map({
    redirectUri: `${
      process.env.NODE_ENV === "production"
        ? getAPIRoot()
        : "https://a584bd7d.ngrok.io"
    }/oauth/zendesk_liveagent`,
    clientId: "",
    clientSecret: "",
    subdomain: "",
  }),
  agentEmail: "",
  accessToken: "",
  accountKey: "",
  enabled: false,

  isVisibleToClient(client: ClientLegacy): boolean {
    return client.features.get("adaGlassZendesk") || false;
  },
}) {}

export { ZendeskLAPlatformRecord };
