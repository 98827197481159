import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import {
  activateTFA as activateTFAAction,
  checkTFA as checkTFAAction,
  setState as setStateAction,
} from "actions";
import { fetchOne as fetchOneAction } from "actions/resources";
import { Button } from "components/Common/Button";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { ClientLegacy, User } from "resourceModels";
import { clientSelector } from "selectors/client";
import { getResource } from "selectors/resources";

import {
  removeTFA as removeTFAAction,
  setSettingsSecurityState as setSettingsSecurityStateAction,
} from "./actions";

import "./style.scss";

class ModalProfileSecurityComponent extends React.Component {
  constructor(props) {
    super(props);

    this.removeTFA = this.removeTFA.bind(this);
  }

  activateTFA() {
    const { user, activateTFA, settingsSecurity } = this.props;

    activateTFA(
      settingsSecurity.get("countryCode"),
      settingsSecurity.get("phoneNumber"),
      user.id,
    );
  }

  changeInput(name, e) {
    const { setSettingsSecurityState } = this.props;

    const change = {};
    change[name] = e.target.value;
    setSettingsSecurityState(change);
  }

  checkTFA() {
    const { checkTFA, settingsSecurity, fetchOne } = this.props;

    checkTFA(settingsSecurity.get("TFA"), false).then(() => {
      // re-fetch the user so the updated state (TFA enabled) is reflected on the frontend
      fetchOne("user");
    });
  }

  /**
   * checks if enforced MFA is enabled
   * if MFA is enabled show alert that says cannot be disabled
   * if MFA is not enabled confirm the want to disable and trigger removeTFA redux action
   */
  removeTFA() {
    const { client, user, removeTFA } = this.props;

    if (
      client.features.get("enforceMfa") &&
      client.enforceMfa.get("enforced") &&
      user.authyEnabled
    ) {
      // eslint-disable-next-line no-alert
      window.confirm(
        "All members are required to have multi-factor authentication enabled. Contact your admin if you want to use a different phone number",
      );

      return;
    }

    // eslint-disable-next-line no-alert
    const removeTFAConfirmation = window.confirm(
      "Are you sure you would like to disable Two-Factor Authentication?",
    );

    if (removeTFAConfirmation) {
      removeTFA(user.id);
    }
  }

  handleBack() {
    const { setState } = this.props;

    setState("MODAL_PROFILE_SECURITY", {
      SMSSent: false,
    });
  }

  render() {
    const {
      /** @type User */
      user,
      settingsSecurity,
    } = this.props;

    let view;

    if (user.authyEnabled) {
      view = (
        <div className="ModalProfileSecurity__disable">
          <Button
            onClick={this.removeTFA}
            text="Disable"
            title="Disable"
            customClassName="ModalProfileSecurity__disable__button"
            light
            isLoading={settingsSecurity.get("disableTFALoading")}
          />
        </div>
      );
    } else {
      view = (
        <div className="ModalProfileSecurity__enable">
          {settingsSecurity.get("SMSSent") ? (
            <div className="ModalProfileSecurity__enable__validation">
              <div className="ModalProfileSecurity__enable__validation__container">
                <label
                  htmlFor="ModalProfileSecurity-authentication-code-input"
                  className="ModalProfileSecurity__enable__validation__container__label g-input__label"
                >
                  SMS
                </label>
                <input
                  id="ModalProfileSecurity-authentication-code-input"
                  className="ModalProfileSecurity__enable__validation__container__input g-input"
                  value={settingsSecurity.get("TFA")}
                  type="text"
                  placeholder="Authentication Code"
                  onChange={this.changeInput.bind(this, "TFA")}
                />
              </div>
              <Button
                onClick={() => this.checkTFA()}
                text="Activate"
                title="Activate"
                customClassName="ModalProfileSecurity__enable__validation__button"
                disabled={
                  !settingsSecurity.get("TFA") ||
                  settingsSecurity.get("verifySMSLoading")
                }
                isLoading={settingsSecurity.get("verifySMSLoading")}
              />
              <Button
                onClick={() => this.handleBack()}
                text="Back"
                title="Back"
                customClassName="ModalProfileSecurity__enable__validation__button"
                light
              />
            </div>
          ) : (
            <div>
              <div className="ModalProfileSecurity__enable__inputs">
                <div className="ModalProfileSecurity__enable__inputs__container ModalProfileSecurity__enable__inputs__container--size-small">
                  <label
                    htmlFor="ModalProfileSecurity-country-code-input"
                    className="g-input__label ModalProfile__label"
                  >
                    Country Code
                  </label>
                  <input
                    id="ModalProfileSecurity-country-code-input"
                    className="ModalProfileSecurity__enable__inputs__container__input g-input ph-no-capture"
                    value={settingsSecurity.get("countryCode")}
                    type="text"
                    placeholder="+1"
                    onChange={this.changeInput.bind(this, "countryCode")}
                  />
                </div>

                <div className="ModalProfileSecurity__enable__inputs__container">
                  <label
                    htmlFor="ModalProfileSecurity-phone-number-input"
                    className="g-input__label ModalProfile__label"
                  >
                    Phone Number
                  </label>
                  <input
                    id="ModalProfileSecurity-phone-number-input"
                    className="ModalProfileSecurity__enable__inputs__container__input g-input ph-no-capture"
                    value={settingsSecurity.get("phoneNumber")}
                    type="tel"
                    placeholder="(123) 456-7890"
                    onChange={this.changeInput.bind(this, "phoneNumber")}
                  />
                </div>
                <Button
                  onClick={() => this.activateTFA()}
                  text="Request Activation Code"
                  title="Request Activation Code"
                  icon="Send"
                  customClassName="ModalProfileSecurity__enable__inputs__button"
                  disabled={
                    !settingsSecurity.get("countryCode") ||
                    !settingsSecurity.get("phoneNumber") ||
                    settingsSecurity.get("requestSMSLoading")
                  }
                  isLoading={settingsSecurity.get("requestSMSLoading")}
                />
              </div>
            </div>
          )}
        </div>
      );
    }

    return (
      <section className="ModalProfileSecurity Modal__section__sub-section">
        <h5 className="Modal__section__title ModalProfileSecurity__title">
          Two-Factor Authentication
          <InfoTooltip
            blurb="Adds an extra layer of protection by linking your account with your phone number. Whenever you sign in with your password, we'll send you a code via SMS."
            iconDefault="QuestionCircle"
            absolute
            inModal
          />
        </h5>
        {view}
      </section>
    );
  }
}

// eslint-disable-next-line react/static-property-placement
ModalProfileSecurityComponent.propTypes = {
  client: PropTypes.instanceOf(ClientLegacy).isRequired,
  fetchOne: PropTypes.func.isRequired,
  removeTFA: PropTypes.func.isRequired,
  activateTFA: PropTypes.func.isRequired,
  checkTFA: PropTypes.func.isRequired,
  setSettingsSecurityState: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  settingsSecurity: ImmutablePropTypes.map.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
};

function mapState(state) {
  const { settingsSecurity } = state;

  return {
    client: clientSelector(state),
    settingsSecurity,
    user: getResource(state, "user"),
  };
}

function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      activateTFA: activateTFAAction,
      checkTFA: checkTFAAction,
      fetchOne: fetchOneAction,
      setSettingsSecurityState: setSettingsSecurityStateAction,
      removeTFA: removeTFAAction,
      setState: setStateAction,
    },
    dispatch,
  );
}

export const ModalProfileSecurity = connect(
  mapState,
  mapDispatch,
)(ModalProfileSecurityComponent);
