export enum Intent {
  DEFAULT = "default",
  POSITIVE = "positive",
  NEGATIVE = "negative",
  WARNING = "warning",
}

export interface StyledBannerProps {
  /**
   * The intent of the banner, which changes the background color
   */
  intent?: "default" | "positive" | "negative" | "warning";

  isInline?: boolean;
}
