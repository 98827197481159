import React from "react";

import { ReadOnlyCheckbox as Checkbox } from "components/Common/Checkbox";
import { InfoTooltip } from "components/Common/InfoTooltip";
import "./style.scss";

interface CheckboxPanelProps {
  label: string;
  handleToggle: (value: boolean) => void;
  checked: boolean;
  children?: React.ReactNode | undefined;
  showTooltip?: boolean;
  tooltipMessage?: string;
  customClassName?: string;
  tooltipImgPath?: string;
  tooltipImgAltText?: string;
  maxWidth?: number;
}

export const CheckboxPanel = ({
  label,
  handleToggle,
  checked,
  customClassName = "",
  children = undefined,
  showTooltip = false,
  tooltipMessage = "",
  tooltipImgPath = "",
  tooltipImgAltText = "",
  maxWidth = 184,
}: CheckboxPanelProps) => {
  const iconDefault = "Info";
  const iconClicked = "InfoFilled";

  return (
    <div className={`CheckboxPanel ${customClassName}`}>
      <div className="CheckboxPanel__text">
        {label && (
          <h3 className="g-form__block__label CheckboxPanel__header">
            {label}
          </h3>
        )}
        <div className="CheckboxPanel__description g-form__block__description">
          {children}
        </div>
      </div>
      {showTooltip && (
        <div className="CheckboxPanel__infoTooltip">
          <InfoTooltip
            blurb={tooltipMessage}
            iconDefault={iconDefault}
            iconClicked={iconClicked}
            tooltipImgPath={tooltipImgPath}
            tooltipImgAltText={tooltipImgAltText}
            absolute
            maxWidth={maxWidth}
          />
        </div>
      )}
      <div className="CheckboxPanel__toggle">
        <Checkbox
          checked={checked}
          className="CheckboxPanel__checkbox g-tile__checkbox"
          handleToggle={handleToggle}
          isSwitch
        />
      </div>
    </div>
  );
};
