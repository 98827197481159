import Immutable from "immutable";

const settingsSecurityMap = Immutable.Map({
  countryCode: "",
  phoneNumber: "",
  TFA: "",
  requestSMSLoading: false,
  SMSSent: false,
  verifySMSLoading: false,
  verifySMSSuccess: false,
  verifySMSFailure: false,
  disableTFALoading: false,
});

export const settingsSecurity = (state = settingsSecurityMap, action) => {
  switch (action.type) {
    case "SET_MODAL_PROFILE_SECURITY":
      return state.merge(action.payload);
    case "SET_SETTINGS_SECURITY":
      return state.merge(action.change);
    case "ACTIVATE_TFA_REQUEST":
      return state.merge({
        requestSMSLoading: true,
        SMSSent: false,
      });

    case "ACTIVATE_TFA_FAILURE":
      if (action.response.status === 418) {
        return state.merge({
          requestSMSLoading: false,
          SMSSent: true,
        });
      }

      return state.merge({
        requestSMSLoading: false,
        SMSSent: false,
      });

    case "SMS_VERIFY_REQUEST":
      return state.merge({
        verifySMSLoading: true,
        verifySMSSuccess: false,
        verifySMSFailure: false,
      });
    case "SMS_VERIFY_SUCCESS":
      return state.merge({
        verifySMSLoading: false,
        verifySMSSuccess: true,
        SMSSent: false,
      });
    case "SMS_VERIFY_FAILURE":
      return state.merge({
        verifySMSLoading: false,
        verifySMSFailure: true,
      });
    case "DISABLE_TFA_REQUEST":
      return state.set("disableTFALoading", true);
    case "DISABLE_TFA_SUCCESS":
      return state.merge({
        disableTFALoading: false,
        countryCode: "",
        phoneNumber: "",
        TFA: "",
      });
    case "DISABLE_TFA_FAILURE":
      return state.set("disableTFALoading", false);
    default:
      return state;
  }
};
