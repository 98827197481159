/* Invalidates the cache for RTK response queries tagged with 'Languages' */
/* This is a temporary workaround that only works with the deprecated CALL_API */
import { type AsyncAction } from "actions/types";
import { ResponsesApi } from "slices/responses/responsesApi";

export function invalidateExpressionLanguagesCache(): AsyncAction {
  return (dispatch) => {
    dispatch(ResponsesApi.util.invalidateTags(["Languages"]));
  };
}
