/* eslint-disable no-await-in-loop */
import { createAlert } from "actions/alerts";
import { AlertType } from "actions/alerts/types";
import { type FetchTopicAnswersOptions } from "actions/conversationsTopics/types";
import { closeModalAction, openModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { type Dispatch, type ThunkAction } from "actions/types";
import { type AnalyticsReportFilterAttributes } from "components/Shared/Pages/AnalyticsPage/services";
import { adaApiRequest } from "services/api";
import { selectClient } from "services/client";
import { type State } from "types";

/**
 * Adding a topic without saving it to db
 */
export function addConversationsTopicAction(name: string, description: string) {
  return {
    type: "ADD_CONVERSATIONS_TOPIC",
    name,
    description,
  };
}

export function updateConversationsTopicAction(conversationsTopic: unknown) {
  return {
    type: "FETCH_CONVERSATIONS_TOPIC_SUCCESS",
    conversationsTopic,
  };
}

export function saveConversationsTopicAction(
  conversationsTopic: Record<string, unknown>,
): ThunkAction {
  return async (dispatch) => {
    try {
      await dispatch(
        adaApiRequest({
          method: "POST",
          url: "/conversations/topics/",
          data: conversationsTopic,
        }),
      );
      dispatch(
        createAlert({
          message: "Topic successfully saved.",
          alertType: AlertType.SUCCESS,
        }),
      );
    } catch {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to save topic.",
          alertType: AlertType.ERROR,
        }),
      );
    }
  };
}

export function fetchConversationsTopicAction(topicId: string) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const { isLoadingConversationsTopic } = state.conversationsTopicsState;
    const client = selectClient(state);

    if (isLoadingConversationsTopic || !client) {
      return;
    }

    try {
      dispatch({ type: "FETCH_CONVERSATIONS_TOPIC_REQUEST" });
      const response = await dispatch(
        adaApiRequest({
          method: "GET",
          url: `/conversations/topics/${topicId}`,
        }),
      );
      dispatch(updateConversationsTopicAction(response.data.topic));
    } catch (error) {
      dispatch({ type: "FETCH_CONVERSATIONS_TOPIC_FAILURE" });
    }
  };
}

export function deleteConversationsTopicAction(topicId: string): ThunkAction {
  return async (dispatch) => {
    try {
      await dispatch(
        adaApiRequest({
          method: "DELETE",
          url: `/conversations/topics/${topicId}`,
        }),
      );
      dispatch(
        createAlert({
          message: "Topic deleted.",
          alertType: AlertType.SUCCESS,
        }),
      );
    } catch (error) {
      dispatch(
        createAlert({
          message: "Failed to delete Topic.",
          alertType: AlertType.ERROR,
        }),
      );
    }
  };
}

export function openConfirmDeleteConversationsTopicModalAction(
  topicId: string,
): ThunkAction {
  return (dispatch) => {
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: "Delete Topic",
        message:
          "Are you sure you want to delete this Topic? This can’t be undone.",
        shouldCloseOnMaskClick: false,
        actions: [
          {
            title: "Delete Topic",
            buttonTint: "alert",
            onClick: () => {
              dispatch(deleteConversationsTopicAction(topicId));
              dispatch(closeModalAction());
              dispatch(setPageAction("/conversations/topics?reset_cache=true"));
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };
}

export function fetchConversationsCsatComments(
  topicId: string,
  options: FetchTopicAnswersOptions,
): ThunkAction {
  return async (dispatch) => {
    dispatch({ type: "FETCH_CONVERSATIONS_TOPICS_CSAT_COMMENTS_REQUEST" });

    try {
      const csatComments = [] as unknown[];
      const loops = options.export?.pagesToFetch ?? 1;
      const pageNumber = options.export ? loops : options.page ?? 1;
      const clearShallow = !!options.export || pageNumber === 1;

      for (let i = 1; i <= loops; i += 1) {
        const response = await dispatch(
          adaApiRequest({
            method: "GET",
            url: `/conversations/topics/${topicId}/csat/comments`,
            params: {
              filter: {
                ...(options.filters?.filter as AnalyticsReportFilterAttributes),
                start_date: options.startDate,
                end_date: options.endDate,
                time_zone: options.timezone,
              },
              page_number: options.export ? i : pageNumber,
              order: options.order ?? "desc",
              limit: options.limit ?? 20,
            },
          }),
        );

        const resultCsatComments = response.data
          .conversations_topic_csat_comments as Array<unknown>;
        csatComments.push(...resultCsatComments);

        if (resultCsatComments.length === 0) {
          break;
        }
      }

      dispatch({
        type: "FETCH_CONVERSATIONS_TOPICS_CSAT_COMMENTS_SUCCESS",
        page: pageNumber,
        clearShallow,
        csatComments,
      });
    } catch (error) {
      dispatch({ type: "FETCH_CONVERSATIONS_TOPICS_CSAT_COMMENTS_FAILURE" });
    }
  };
}

export function fetchConversationsTopicUnansweredQuestions(
  topicId: string,
  options: FetchTopicAnswersOptions,
): ThunkAction {
  return async (dispatch) => {
    dispatch({ type: "FETCH_CONVERSATIONS_UNTRAINED_EXPRESSIONS_REQUEST" });

    try {
      const questions = [] as unknown[];
      const loops = options.export?.pagesToFetch ?? 1;
      const pageNumber = options.export ? loops : options.page ?? 1;
      const clearShallow = !!options.export || pageNumber === 1;

      for (let i = 1; i <= loops; i += 1) {
        const response = await dispatch(
          adaApiRequest({
            method: "GET",
            url: `/conversations/topics/${topicId}/untrained/expressions`,
            params: {
              filter: {
                ...(options.filters?.filter as AnalyticsReportFilterAttributes),
                start_date: options.startDate,
                end_date: options.endDate,
                time_zone: options.timezone,
              },
              page_number: options.export ? i : pageNumber,
              order: options.order ?? "desc",
              limit: options.limit ?? 20,
            },
          }),
        );

        const resultQuestions = response.data
          .untrained_expressions as Array<unknown>;
        questions.push(...resultQuestions);

        if (resultQuestions.length === 0) {
          break;
        }
      }

      dispatch({
        type: "FETCH_CONVERSATIONS_UNTRAINED_EXPRESSIONS_SUCCESS",
        unansweredQuestions: questions,
        page: pageNumber,
        clearShallow,
      });
    } catch (error) {
      dispatch({ type: "FETCH_CONVERSATIONS_UNTRAINED_EXPRESSIONS_FAILURE" });
    }
  };
}

export function fetchConversationsTopicUnansweredQuestionsForExpressionsPage(
  topicId: string,
  clientId: string,
  options: FetchTopicAnswersOptions,
): ThunkAction {
  return async (dispatch) => {
    dispatch({ type: "GET_EXPRESSIONS_REQUEST", scrolling: false });

    try {
      const questions = [] as unknown[];
      const loops = options.export?.pagesToFetch ?? 1;
      const pageNumber = options.export ? loops : options.page ?? 1;

      for (let i = 1; i <= loops; i += 1) {
        const response = await dispatch(
          adaApiRequest({
            method: "GET",
            url: `/conversations/topics/${topicId}/untrained/expressions`,
            params: {
              filter: {
                ...(options.filters?.filter as AnalyticsReportFilterAttributes),
                start_date: options.startDate,
                end_date: options.endDate,
                time_zone: options.timezone,
              },
              page_number: options.export ? i : pageNumber,
              order: options.order ?? "desc",
              limit: options.limit ?? 20,
            },
          }),
        );

        const resultQuestions = response.data
          .untrained_expressions as Array<unknown>;
        questions.push(...resultQuestions);

        if (resultQuestions.length === 0) {
          break;
        }
      }

      dispatch({
        type: "GET_CONVERSATIONS_TOPIC_EXPRESSIONS_SUCCESS",
        unansweredQuestions: questions,
        clientId,
      });
    } catch (error) {
      dispatch({ type: "GET_EXPRESSIONS_FAILURE" });
    }
  };
}
