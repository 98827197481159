import { primitives } from "@adasupport/byron";
import styled from "styled-components";

export const Notification = styled.div<{
  notificationType: "info" | "warning" | "error";
}>`
  display: flex;
  border-radius: 6px;
  align-items: flex-start;
  justify-content: space-between;

  background-color: ${({ notificationType }) =>
    ({
      info: primitives.palette.blue100,
      warning: primitives.palette.orange100,
      error: primitives.palette.red100,
    }[notificationType])};

  border: 1px solid
    ${({ notificationType }) =>
      ({
        info: primitives.palette.blue300,
        warning: primitives.palette.gold400,
        error: primitives.palette.red300,
      }[notificationType])};

  a {
    color: inherit;
  }
`;

export const Icon = styled.div`
  margin: 14px 0 0 16px;
`;

export const Title = styled.div<{
  notificationType: "info" | "warning" | "error";
}>`
  font-size: 14px;
  font-weight: 600;

  color: ${({ notificationType }) =>
    ({
      info: primitives.palette.blue700,
      warning: primitives.palette.orange800,
      error: primitives.palette.red800,
    }[notificationType])};
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 16px 12px;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: ${primitives.spacing.space2};
  white-space: normal;
`;

export const CloseButton = styled.div`
  padding: 8px;
  cursor: pointer;
`;
