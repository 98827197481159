// @ts-strict-ignore
import type * as Immutable from "immutable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addCampaignAction } from "actions/campaigns";
import { closeModalAction } from "actions/modal";
import { fetchMany } from "actions/resources";
import { setPageAction } from "actions/router";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { Loading } from "components/Common/Loading";
import Select from "components/Common/Select/ControlledAccessSelect";
import SvgImage from "components/Common/SvgImage";
import { type Channel } from "resourceModels";
import {
  type ChannelIntegrationRecord,
  SunshineChannelTypes,
} from "resourceModels/Channel/helpers";
import { getResources, getResourcesRequest } from "selectors/resources";
import { useClient } from "services/client";
import { NO_OP_FUNCTION } from "services/helpers";
import EmptyImage from "static/images/create_new.svg";

import * as S from "./styles";

const MAX_CAMPAIGN_NAME_LENGTH = 60;

/**
 * returns true if integrationToFind is present and active
 */
function hasSunshineIntegration(
  channels: Immutable.List<Channel> | null,
  integrationToFind: string,
) {
  return channels?.some((channel) => {
    if (channel.name !== "sunshine") {
      return false;
    }

    return Boolean(
      channel.integrations?.some(
        (integration: ChannelIntegrationRecord) =>
          integration.attributes.type === integrationToFind &&
          integration.attributes.status === "active",
      ),
    );
  });
}

interface Props {
  isNew: boolean;
  initialChannel?: string;
  initialName?: string;
  initialDescription?: string;
  onSave?: (name: string, description: string) => void;
}

export function CreateCampaignModal(props: Props) {
  const { isNew, initialChannel, initialName, initialDescription, onSave } =
    props;
  const { client } = useClient();
  const campaigns = useSelector((state) => state.campaignsState.campaigns);
  const channelsRequest = useSelector((state) =>
    getResourcesRequest(state, "channel", "all"),
  );
  const channels = useSelector((state) => getResources(state, "channel"));
  const [name, setName] = useState(initialName || "");
  const [description, setDescription] = useState(initialDescription || "");
  const dispatch = useDispatch();

  const isNameInvalid = !name.trim() || name.length > MAX_CAMPAIGN_NAME_LENGTH;
  const isProactiveSmsEnabled =
    client.features.channel_smooch && client.features.afm_proactive_sms;
  const isProactiveSmsConfigured =
    isProactiveSmsEnabled &&
    hasSunshineIntegration(channels, SunshineChannelTypes.TWILIO_SMS);
  const [channel, setChannel] = useState(initialChannel);

  const channelOptions = [
    {
      label: "Web",
      value: "web",
      enabled: true,
    },
    {
      label: "SMS",
      value: "sms",
      enabled: isProactiveSmsConfigured,
    },
  ];

  const enabledChannelOptions = channelOptions.filter((c) => c.enabled);

  useEffect(() => {
    dispatch(fetchMany("channel"));
  }, [dispatch]);

  useEffect(() => {
    // this is necessary to set the channel options after they've been fetched
    setChannel(initialChannel || (isProactiveSmsConfigured ? "" : "web"));
  }, [initialChannel, isProactiveSmsConfigured]);

  function generateIdFromName() {
    const existingCampaignKeys = campaigns.map((c) => c.campaignKey);

    const baseId = name
      .split("")
      .map((c) => {
        if (c === " ") {
          return "_";
        }

        if (
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890_-".includes(
            c,
          )
        ) {
          return c;
        }

        return "";
      })
      .join("");

    if (!existingCampaignKeys.includes(baseId)) {
      return baseId;
    }

    let count = 2;

    while (existingCampaignKeys.includes(`${baseId}_${count}`)) {
      count += 1;
    }

    return `${baseId}_${count}`;
  }

  if (channelsRequest?.isPending) {
    return <Loading />;
  }

  return (
    <div className="Modal__modal Modal__modal--small">
      <div className="Modal__content">
        <S.ModalSection>
          <S.ModalImageContainer>
            <SvgImage imageHash={EmptyImage} />
          </S.ModalImageContainer>
          <div>
            {isNew && <S.ModalTitle>Create a new Campaign</S.ModalTitle>}
            {!isNew && <S.ModalTitle>Edit Campaign</S.ModalTitle>}
            <S.ModalDescription>
              Give your Campaign a name and description to help you reference
              it.
            </S.ModalDescription>
          </div>
        </S.ModalSection>
        {isProactiveSmsEnabled && (
          <S.ModalSection>
            <label className="g-input__label" htmlFor="campaignChannelId">
              Channel
            </label>
            <S.InfoText>Where will you engage with your customers?</S.InfoText>
            <Select
              placeholder="Choose a Channel"
              options={enabledChannelOptions}
              onChange={(value: string) => setChannel(value)}
              value={channel}
              isInvalid={!channel}
              disabled={!isNew || enabledChannelOptions.length === 1}
            />
            {!isProactiveSmsConfigured && (
              <S.SMSIntegrationBanner icon="InfoFilled" intent="warning">
                You need to{" "}
                <a href="https://docs.ada.cx/sunshine-conversations">
                  configure your Sunshine Conversations integration
                </a>{" "}
                and enable the Twilio SMS channel before you can create an SMS
                Campaign.
              </S.SMSIntegrationBanner>
            )}
          </S.ModalSection>
        )}
        <S.ModalSection>
          <DebouncedInput
            id="campaignName"
            label="Name"
            value={name}
            isInvalid={isNameInvalid}
            placeholder="Enter a name for your Campaign"
            characterCounter={MAX_CAMPAIGN_NAME_LENGTH}
            onChange={setName}
          />
        </S.ModalSection>
        <S.ModalSection>
          <DebouncedInput
            id="campaignDescription"
            label={
              <>
                <span>Description</span>{" "}
                <S.ModalOptional>(Optional)</S.ModalOptional>
              </>
            }
            placeholder="e.g. offer personalized support for new signups on home page"
            value={description}
            onChange={setDescription}
            isTextArea
          />
        </S.ModalSection>
      </div>

      <S.ButtonsContainer className="Modal__bottom">
        <Button
          light
          onClick={() => {
            dispatch(closeModalAction());
          }}
          text="Cancel"
          title="Cancel"
        />
        {isNew && (
          <Button
            onClick={() => {
              const id = generateIdFromName();
              dispatch(
                addCampaignAction({
                  campaignKey: id,
                  name,
                  description,
                  isEngageClient: Boolean(
                    client.features.afm_proactive_messaging,
                  ),
                  channel: channel as string,
                }),
              );
              dispatch(setPageAction(`/answers/campaigns/edit/${id}`));
              dispatch(closeModalAction());
            }}
            text="Next"
            title="Next"
            disabled={isNameInvalid || !channel}
          />
        )}
        {!isNew && (
          <Button
            onClick={() => {
              onSave(name, description);
              dispatch(closeModalAction());
            }}
            text="Update"
            title="Update"
            disabled={isNameInvalid}
          />
        )}
      </S.ButtonsContainer>
    </div>
  );
}

CreateCampaignModal.defaultProps = {
  initialName: "",
  initialDescription: "",
  onSave: NO_OP_FUNCTION,
};
