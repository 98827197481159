import { createSlice } from "@reduxjs/toolkit";

import { type RuleDefinition } from "services/rules";
import { getKBSettings, syncKB } from "slices/knowledge/knowledgeApiSlice";
import { syncErrors } from "slices/knowledge/syncErrors";

import { type Article, type ArticleFilter, type SyncError } from "./types";

export interface KnowledgeState {
  pageSize: number;
  page: number;
  selectedArticles: Article[];
  bulkSelect: boolean;
  bulkSelectIndeterminate: boolean;
  filters: ArticleFilter[];
  search: string;
  isSyncing: boolean;
  isSyncingFirstTime: boolean;
  availabilityModalOpen: boolean;
  knowledgeBaseModalOpen: boolean;
  articleFlyout: {
    isOpen: boolean;
    viewMode: "edit" | "preview" | "create" | null;
    articleId?: string | null;
  };
  syncError?: SyncError | null;
  adaAvailabilityModal: {
    articleId: string | null;
    rules: RuleDefinition | "Everyone" | null;
    isOpen: boolean;
  };
}

export const initialState: KnowledgeState = {
  pageSize: 25,
  page: 1,
  selectedArticles: [],
  bulkSelect: false,
  bulkSelectIndeterminate: false,
  filters: [],
  search: "",
  isSyncing: false,
  isSyncingFirstTime: false,
  availabilityModalOpen: false,
  knowledgeBaseModalOpen: false,
  articleFlyout: {
    isOpen: false,
    viewMode: null,
    articleId: null,
  },
  adaAvailabilityModal: {
    articleId: null,
    rules: null,
    isOpen: false,
  },
};

const articleIsSelected = (articleId: string, selectedArticles: Article[]) =>
  selectedArticles.map((article) => article.id).indexOf(articleId) > -1;

const getError = (syncErrorMessage: string) => {
  const error = syncErrors.find((e) => syncErrorMessage.match(e.match));

  return error || null;
};

const knowledgeSlice = createSlice({
  name: "knowledge",
  initialState,
  reducers: {
    changePageSize(state, action) {
      return { ...state, pageSize: action.payload };
    },
    changePage(state, action) {
      return { ...state, page: action.payload };
    },
    setArticlesSelected(state, action) {
      const {
        payload: { articles, selected, loadedArticleIds },
      } = action;

      let newSelectedArticles: Article[] = [];

      if (selected) {
        newSelectedArticles = [...state.selectedArticles, ...articles];
      } else {
        const articleIds = articles.map((article: Article) => article.id);
        newSelectedArticles = state.selectedArticles.filter(
          (selectedArticle) => !articleIds.includes(selectedArticle.id),
        );
      }

      if (
        loadedArticleIds.every((id: string) =>
          articleIsSelected(id, newSelectedArticles),
        )
      ) {
        return {
          ...state,
          bulkSelect: true,
          bulkSelectIndeterminate: false,
          selectedArticles: newSelectedArticles,
        };
      }

      if (
        loadedArticleIds.some((id: string) =>
          articleIsSelected(id, newSelectedArticles),
        )
      ) {
        return {
          ...state,
          bulkSelect: true,
          bulkSelectIndeterminate: true,
          selectedArticles: newSelectedArticles,
        };
      }

      return {
        ...state,
        bulkSelect: false,
        bulkSelectIndeterminate: false,
        selectedArticles: newSelectedArticles,
      };
    },
    clearSelectedArticles(state) {
      return {
        ...state,
        selectedArticles: [],
        bulkSelect: false,
        bulkSelectIndeterminate: false,
      };
    },
    setFilters(state, action) {
      if (JSON.stringify(action.payload) === JSON.stringify(state.filters)) {
        return state;
      }

      return {
        ...state,
        filters: action.payload,
        selectedArticles: [],
        page: 1,
      };
    },
    setSearch(state, action) {
      if (action.payload === state.search) {
        return state;
      }

      return {
        ...state,
        search: action.payload,
        selectedArticles: [],
        page: 1,
      };
    },
    setAvailabilityModalOpen(state, action) {
      return {
        ...state,
        availabilityModalOpen: action.payload,
      };
    },
    setKnowledgeBaseModalOpen(state, action) {
      return {
        ...state,
        knowledgeBaseModalOpen: action.payload,
      };
    },
    openArticleFlyout(state, action) {
      return {
        ...state,
        articleFlyout: {
          isOpen: true,
          viewMode: action.payload.viewMode,
          articleId: action.payload.articleId || null,
        },
      };
    },
    closeArticleFlyout(state) {
      return {
        ...state,
        articleFlyout: {
          isOpen: false,
          viewMode: null,
          articleId: null,
        },
      };
    },
    setArticleFlyout(state, action) {
      const { isOpen, viewMode, articleId } = action.payload;

      return {
        ...state,
        // set the attributes to the new values if they are provided
        articleFlyout: {
          isOpen: isOpen !== undefined ? isOpen : state.articleFlyout.isOpen,
          viewMode: viewMode || state.articleFlyout.viewMode,
          articleId: articleId || state.articleFlyout.articleId,
        },
      };
    },
    setAdaAvailabilityModal(state, action) {
      return {
        ...state,
        adaAvailabilityModal: action.payload,
      };
    },
    closeAdaAvailabilityModal(state) {
      return {
        ...state,
        adaAvailabilityModal: {
          isOpen: false,
          articleId: null,
          rules: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(getKBSettings.matchFulfilled, (state, action) => {
        const { lastSyncFinish, syncStatus, syncErrorMessage, integrations } =
          action.payload;
        const hasLegacyIntegrations = integrations.length > 0;

        if (syncErrorMessage) {
          return { ...state, syncError: getError(syncErrorMessage) };
        }

        if (
          hasLegacyIntegrations &&
          ["ongoing", "pending"].includes(syncStatus)
        ) {
          if (lastSyncFinish) {
            return { ...state, isSyncing: true, isSyncingFirstTime: false };
          }

          return { ...state, isSyncing: true, isSyncingFirstTime: true };
        }

        return { ...state, isSyncing: false, isSyncingFirstTime: false };
      })
      .addMatcher(getKBSettings.matchPending, (state) => ({
        ...state,
        syncError: null,
      }))
      .addMatcher(syncKB.matchPending, (state) => ({
        ...state,
        isSyncing: true,
        syncError: null,
      }));
  },
});

export const {
  changePage,
  changePageSize,
  setArticlesSelected,
  clearSelectedArticles,
  setFilters,
  setSearch,
  setAvailabilityModalOpen,
  setKnowledgeBaseModalOpen,
  setArticleFlyout,
  setAdaAvailabilityModal,
  openArticleFlyout,
  closeArticleFlyout,
  closeAdaAvailabilityModal,
} = knowledgeSlice.actions;
export const { reducer: knowledgeReducer } = knowledgeSlice;
