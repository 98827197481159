import { useSelector } from "react-redux";

import { type ResponseSearchResultItem } from "components/Common/ResponseSelector/ResponseListItem/ResponseSearchResult";
import { datadogLogs } from "services/datadog";

interface logResponseSearchRelevancyParams {
  result: ResponseSearchResultItem;
  optionNumber: number;
  numberSearchResults: number;
  searchQuery: string;
}

interface logFolderSearchRelevancyParams {
  selectedResultId: string;
  selectedResultTitle: string;
}

const isDevEnvironment = process.env.NODE_ENV === "development";

function log(message: string, context: object | undefined) {
  if (isDevEnvironment) {
    console.log(message, context); // eslint-disable-line no-console
  } else {
    datadogLogs.logger.info(message, context);
  }
}

export function useLogSearchRelevancy() {
  const foldersState = useSelector((state) => state.foldersState);

  function logResponseSearchRelevancy({
    result,
    optionNumber,
    numberSearchResults,
    searchQuery,
  }: logResponseSearchRelevancyParams) {
    log("Answer search relevancy", {
      title: "Answer search relevancy",
      number_search_results: numberSearchResults,
      selected_result: result._source.handle, // eslint-disable-line no-underscore-dangle
      user_query: searchQuery,
      option_number: optionNumber,
      endpoint: "/responses/filter",
    });
  }

  function logFolderSearchRelevancy({
    selectedResultId,
    selectedResultTitle,
  }: logFolderSearchRelevancyParams) {
    const { searchQuery, numLoadedPages, loadedSearchResults } = foldersState;
    const userInput = searchQuery.get("userinput")?.trim() || "";
    const sortBy = searchQuery.get("sortBy");

    const sortingByRelevancy = sortBy === "folders";

    if (!sortingByRelevancy) {
      return;
    }

    const searchResults = loadedSearchResults.toArray().map((result) => ({
      id: result.id,
      title: result.title,
    }));

    if (!searchResults.length) {
      return;
    }

    log("Answer search relevancy", {
      title: "Answer search relevancy",
      number_search_results: searchResults.length,
      selected_result: selectedResultTitle,
      user_query: userInput,
      option_number:
        searchResults.findIndex((result) => result.id === selectedResultId) + 1,
      number_loaded_pages: numLoadedPages - 1, // Count of 'numLoadedPages' is too high - BUIL-1781 should address this
      endpoint: "/folders/filter",
    });
  }

  return {
    logResponseSearchRelevancy,
    logFolderSearchRelevancy,
  };
}
