import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

export const Text = styled.span<{
  block?: boolean;
  color?: keyof typeof tokens.colors.text | keyof typeof primitives.palette;
  size?: keyof typeof primitives.fontSize;
  weight?: keyof typeof primitives.fontWeight;
  fontFamily?: keyof typeof primitives.fontFamily;
}>`
  display: ${(p) => (p.block ? "block" : "inline")};
  color: ${(p) =>
    // eslint-disable-next-line no-nested-ternary
    p.color
      ? p.color in tokens.colors.text
        ? tokens.colors.text[p.color as keyof typeof tokens.colors.text]
        : primitives.palette[p.color as keyof typeof primitives.palette]
      : primitives.palette.black};
  font-size: ${(p) =>
    p.size ? primitives.fontSize[p.size] : primitives.fontSize.default};
  font-weight: ${(p) =>
    p.weight ? primitives.fontWeight[p.weight] : primitives.fontWeight.normal};
  font-family: ${(p) =>
    p.fontFamily
      ? primitives.fontFamily[p.fontFamily]
      : primitives.fontFamily.sans};
`;
