import { primitives } from "@adasupport/byron";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: ${primitives.palette.plum100};
  border-radius: ${primitives.borderRadius.small};
  height: 450px;
`;

export const Video = styled.iframe`
  display: flex;
  height: 100%;
  width: 100%;
  border: 0px;
  border-radius: ${primitives.borderRadius.small};
`;
