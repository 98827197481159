const isJmesIdentifier = (identifier: string) =>
  !(/^[0-9]/.test(identifier) || !/^[a-zA-Z0-9_]+$/.test(identifier));

export const concatJmesPath = (
  parentJmesPath: string | undefined,
  propertyName: string | number | undefined,
) => {
  const jmesPathSegment = (() => {
    if (propertyName === undefined) {
      return "";
    }

    if (typeof propertyName === "number") {
      return `[${propertyName}]`;
    }

    if (!isJmesIdentifier(propertyName)) {
      return parentJmesPath
        ? `.${JSON.stringify(propertyName)}`
        : JSON.stringify(propertyName);
    }

    return parentJmesPath ? `.${propertyName}` : propertyName;
  })();

  return `${parentJmesPath ?? ""}${jmesPathSegment}`;
};
