import React from "react";

import { CodeBlock } from "components/Common/CodeBlock";
import { SupportedLanguages } from "components/Common/CodeBlock/types";
import { getTestBotCluster, getTestBotHandle } from "services/test-bot";

export function EmbedSnippetHtml() {
  const cluster = getTestBotCluster();
  const handle = getTestBotHandle();

  return (
    <CodeBlock
      language={SupportedLanguages.XML}
      labelOverride="HTML"
      code={
        cluster
          ? `
        <!-- <head> -->
        <script>
          window.adaSettings = {
            handle: "${handle}",
            cluster: "${cluster}"
          };
        </script>

        <script
          src="https://static.ada.support/embed2.js"
        ></script>
      `
          : `
        <!-- <head> -->
        <script
          id="__ada"
          data-handle="${handle}"
          src="https://static.ada.support/embed2.js"
        ></script>
      `
      }
    />
  );
}
