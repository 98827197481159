import { primitives } from "@adasupport/byron";
import styled from "styled-components";

import { Notification as CommonNotification } from "components/Common";

export const Notification = styled(CommonNotification)`
  margin-bottom: ${primitives.spacing.space4};
`;

export const RulesetEditorContainer = styled.div`
  margin-left: ${primitives.spacing.space7};
`;
