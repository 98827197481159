import React from "react";

import * as S from "./styles";

interface ModalActionButtonProps {
  intent?: "primary" | "alert";
  onClick: () => void;
  text: string;
}

export function ModalActionButton({
  intent,
  onClick,
  text,
}: ModalActionButtonProps) {
  return (
    <S.ActionButton intent={intent} onClick={onClick}>
      {text}
    </S.ActionButton>
  );
}
