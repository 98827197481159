import { type Client } from "services/client";
import { isValidColor } from "services/color";

export const isHeaderColorInvalid = (client: Client) =>
  client.uiSettings.chat.header_color_enabled &&
  !isValidColor(client.uiSettings.chat.header_color);

export const isTextOverAccentColorInvalid = (client: Client) =>
  client.uiSettings.chat.advanced_colors_enabled &&
  !isValidColor(client.uiSettings.chat.text_over_accent_color);

export const isHeaderTextColorInvalid = (client: Client) =>
  client.uiSettings.chat.advanced_colors_enabled &&
  client.uiSettings.chat.header_color_enabled &&
  !isValidColor(client.uiSettings.chat.header_text_color);
