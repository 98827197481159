export const NO_OP_FUNCTION = () => {
  // Do nothing
};

export function isPrefixArray(
  arr: unknown[] | undefined,
  startsWithArr: unknown[] | undefined,
) {
  if (!arr || !startsWithArr || !startsWithArr.length) {
    return false;
  }

  if (startsWithArr.length > arr.length) {
    return false;
  }

  for (let i = 0; i < startsWithArr.length; i += 1) {
    if (arr[i] !== startsWithArr[i]) {
      return false;
    }
  }

  return true;
}

export function isSameLevel(path1: unknown[], path2: unknown[]) {
  if (path1.length === path2.length) {
    return isPrefixArray(path1, path2.slice(0, -1));
  }

  return false;
}

// Just a typed version of Object.keys
// eslint-disable-next-line prefer-destructuring
export const keys: <T extends object>(o: T) => (keyof T)[] = Object.keys;

export type JSONPrimitive = string | number | boolean | null;
export type JSONData =
  | JSONPrimitive
  | JSONPrimitive[]
  | {
      [key: string]: JSONPrimitive | JSONData | JSONData[];
    }
  | JSONData[];
