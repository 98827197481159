/* eslint-disable max-classes-per-file */
import Immutable from "immutable";

export interface ActionIntegrationInputItemRecordAttributes {
  key: string;
  value:
    | string
    | Immutable.List<ActionIntegrationInputItemRecordAttributes>
    | Immutable.List<Immutable.List<ActionIntegrationInputItemRecordAttributes>>
    | null;
  type?: string;
}

// Corresponds to the actual configured input value
export class ActionIntegrationInputItemRecord extends Immutable.Record<ActionIntegrationInputItemRecordAttributes>(
  {
    key: "",
    value: "",
    type: "",
  },
) {}

// Corresponds to the schema specifying an input field
export interface ActionIntegrationInputField {
  id: string | null;
  display_name: string;
  description: string;
  description_link: string;
  description_link_text: string;
  placeholder: string;
  optional: boolean;
  validation: Record<string, unknown>;
  type: string;
  inputs: ActionIntegrationInputField[];
  repeatable: boolean;
}

// Corresponds to the actual configured output value
export class ActionIntegrationOutputItemRecord extends Immutable.Record({
  key: "",
  variableId: "" as string | null,
}) {}

// Corresponds to the schema specifying an output field
export interface ActionIntegrationOutputField {
  id: string | null;
  display_name: string;
  description: string;
  type: "string";
}

export interface ActionIntegrationBlockConfiguration {
  allow_count_as_handoff: boolean;
  count_as_handoff_description: string;
  show_pause_checkbox: boolean;
  pause_description: string;
}

// Describes an individual action of an integration
export interface ActionIntegrationAction {
  id: string | null;
  display_name: string;
  description: string;
  input_schema: ActionIntegrationInputField[];
  output_schema: ActionIntegrationOutputField[];
  visible: boolean;
  block_configuration: ActionIntegrationBlockConfiguration;
}

// Describes a whole integration, which may contain multiple actions
export interface ActionIntegration {
  id: string | null;
  display_name: string;
  description: string;
  icon: string;
  help_docs_url: string | null;
  not_configured_warning_text: string | null;
  configuration_schema: Record<string, unknown>;
  actions: ActionIntegrationAction[];
  is_configured: boolean;
  published: boolean;
}

export interface ActionIntegrationsState {
  loading: boolean;
  loaded: boolean;
  actionIntegrations: ActionIntegration[];
}
