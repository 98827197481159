import { bindActionCreators } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";

import { fetchActionIntegrationsAction } from "actions/actionIntegrations";
import { createAlert as createAlertAction } from "actions/alerts";
import { closeModalAction } from "actions/modal";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputSensitive } from "components/Common/InputSensitive";
import { Loading } from "components/Common/Loading";
import { adaAPI } from "services/api";

import "./style.scss";

const BLOCK_NAME = "SettingsPlatformsActionsModal";

class SettingsPlatformsActionsModal extends React.Component {
  static propTypes = {
    actionIntegrationId: PropTypes.string.isRequired,
    actionName: PropTypes.string.isRequired,
    configurationSchema: PropTypes.shape({
      fields: PropTypes.oneOfType([PropTypes.object]),
    }).isRequired,
    helpDocsUrl: PropTypes.string,

    // props from dispatch
    closeModal: PropTypes.func.isRequired,
    createAlert: PropTypes.func.isRequired,
    fetchActionIntegrations: PropTypes.func.isRequired,
  };

  static defaultProps = {
    helpDocsUrl: "",
  };

  state = {
    credentialsLoaded: false,
    oldCredentials: {},
    savedCredentials: {},
    credentials: {},
    errorMessage: "",
    isLoading: false,
  };

  constructor(props) {
    super(props);

    this.saveCredentials = this.saveCredentials.bind(this);

    this.modalContentRef = React.createRef();
  }

  componentDidMount() {
    this.fetchCurrentCredentials();
  }

  /**
   * Fetch the current credential values from the integrations machine via API
   */
  fetchCurrentCredentials() {
    const { actionIntegrationId } = this.props;

    adaAPI
      .request({
        method: "GET",
        url: `/action-integrations/${actionIntegrationId}/credentials`,
      })
      .then((response) => {
        this.setState({
          credentialsLoaded: true,
          oldCredentials: response.data.actions_credentials,
          savedCredentials: response.data.actions_credentials,
          credentials: response.data.actions_credentials,
        });
      })
      .catch(() => {
        // If an error occurs while loading existing credentials, we'll just show blank credentials
        this.setState({
          credentialsLoaded: true,
          oldCredentials: {},
          credentials: {},
        });
      });
  }

  /**
   * Update component state with new field values when input value changes
   * @param {string} fieldId
   * @param  {string} newValue
   */
  updateCredentialsField(fieldId, newValue) {
    const { savedCredentials, errorMessage, credentials } = this.state;

    const updatedCredentials = {
      ...credentials,
      [fieldId]: newValue,
    };
    const updatedErrorMessage = isEqual(updatedCredentials, savedCredentials)
      ? ""
      : errorMessage;

    this.setState({
      credentials: updatedCredentials,
      errorMessage: updatedErrorMessage,
    });
  }

  /**
   * Build a shallow diff between credentials and oldCredentials,
   * and send the diff as a partial update.
   */
  saveCredentials() {
    const {
      actionIntegrationId,
      closeModal,
      createAlert,
      fetchActionIntegrations,
    } = this.props;
    const { savedCredentials, credentials } = this.state;

    this.setState({
      isLoading: true,
    });

    const updates = {};
    // TODO: once we support truly nested objects, we need to use a deep diff here
    Object.entries(credentials).forEach(([key, value]) => {
      if (savedCredentials[key] !== value) {
        updates[key] = value;
      }
    });

    adaAPI
      .request({
        method: "PATCH",
        url: `/action-integrations/${actionIntegrationId}/credentials`,
        data: updates,
      })
      .then(async () => {
        createAlert({
          message: "Credentials saved successfully",
          alertType: "success",
        });

        await fetchActionIntegrations(true);
        closeModal();
      })
      .catch((e) => {
        const errorMessage = e.response.data.message
          .split('{"message":"')[1]
          .replace('"}', "");

        this.setState({
          errorMessage:
            errorMessage ||
            "An authentication error has occured. Please check your credentials and try again. ",
          oldCredentials: credentials,
          isLoading: false,
        });

        createAlert({
          message: "Failed to save credentials",
          alertType: "error",
        });
      });
  }

  /**
   * Returns false if the field is valid
   * Returns a string explaining why the field failed validation if the field is invalid
   * @param {object} field
   */
  invalidReason(field) {
    const { id, validation, optional } = field;
    const { credentials } = this.state;
    const value = credentials[id] || "";

    if (!optional && value.length === 0) {
      return "This field is required";
    }

    if (!validation) {
      return false;
    }

    const { minLength, maxLength, format } = validation;

    if (minLength && value.length < minLength) {
      return `Must be at least ${minLength} characters`;
    }

    if (maxLength && value.length > maxLength) {
      return `Must be at most ${maxLength} characters`;
    }

    if (format === "email" && !isEmail(value)) {
      return "Must be a valid email";
    }

    if (format === "url" && !isURL(value)) {
      return "Must be a valid URL";
    }

    return false;
  }

  /**
   * Returns true if any fields is invalid, and false if all fields are valid
   */
  hasAnyInvalidField() {
    const { configurationSchema } = this.props;

    return configurationSchema.fields.some((field) =>
      this.invalidReason(field),
    );
  }

  /**
   * Render a single field based on the specification in the schema
   * @param {object} field
   * @param {number} index
   * @returns {*}
   */
  renderField(field, index) {
    const { credentials } = this.state;
    const invalidReason = this.invalidReason(field);
    const isInvalid = Boolean(invalidReason);

    return (
      <section className="input-row" key={`${BLOCK_NAME}__${index}`}>
        <div className="input-row__title__header">
          <label
            className="input-row__title g-input__label"
            htmlFor={`${BLOCK_NAME}__${index}`}
          >
            <span>{field.display_name}</span>
          </label>
          {field.description && (
            <InfoTooltip
              blurb={field.description}
              iconDefault="QuestionCircle"
              iconClicked="QuestionCircleFilled"
              absolute
              container={this.modalContentRef.current}
            />
          )}
        </div>
        {field.hidden && (
          <InputSensitive
            inputId={`${BLOCK_NAME}__${index}`}
            value={credentials[field.id] || ""}
            placeholder={field.placeholder}
            onChange={(e) =>
              this.updateCredentialsField(field.id, e.target.value)
            }
            invalid={isInvalid}
            autoComplete="new-password"
            datalpignore="true"
          />
        )}
        {!field.hidden && (
          <DebouncedInput
            id={`${BLOCK_NAME}__${index}`}
            value={credentials[field.id] || ""}
            placeholder={field.placeholder}
            onChange={(newValue) =>
              this.updateCredentialsField(field.id, newValue)
            }
            customClassName="ph-no-capture"
            isInvalid={isInvalid}
            autoComplete="new-password"
            datalpignore="true"
          />
        )}
        {field.hidden && (
          <div className={`${BLOCK_NAME}__sensitive-message`}>
            For data security, the content in this field will remain hidden
            after you press save.
          </div>
        )}
        {isInvalid && (
          <div className={`${BLOCK_NAME}__invalid-message`}>
            {invalidReason}
          </div>
        )}
      </section>
    );
  }

  renderBanner() {
    const { helpDocsUrl } = this.props;
    const { errorMessage } = this.state;

    return (
      <Banner
        icon={errorMessage ? "InfoFilled" : "QuestionCircleFilled"}
        intent={errorMessage ? "negative" : "default"}
      >
        {`${errorMessage} `}
        {helpDocsUrl && (
          <>
            To learn more about configuring this App, please reference the{" "}
            <a href={helpDocsUrl} target="_blank" rel="noopener noreferrer">
              Configuration Guide
            </a>
            .
          </>
        )}
      </Banner>
    );
  }

  renderModalContent() {
    const { configurationSchema, helpDocsUrl } = this.props;
    const { credentialsLoaded, errorMessage, isLoading } = this.state;

    if (isLoading || !credentialsLoaded) {
      return <Loading />;
    }

    return (
      <>
        {(helpDocsUrl || errorMessage) && this.renderBanner()}
        {configurationSchema.fields.map((field, index) =>
          this.renderField(field, index),
        )}
      </>
    );
  }

  render() {
    const { actionName } = this.props;
    const { credentials, oldCredentials, savedCredentials, isLoading } =
      this.state;

    return (
      <div className={`${BLOCK_NAME} Modal__modal`}>
        <h5 className="Modal__title">{actionName}</h5>
        <div className="Modal__content">{this.renderModalContent()}</div>
        <div className={`Modal__bottom ${BLOCK_NAME}__bottom`}>
          <Button
            onClick={this.saveCredentials}
            text="Save"
            title="Save"
            icon="Cloud"
            disabled={
              isEqual(credentials, oldCredentials) ||
              isEqual(credentials, savedCredentials) ||
              this.hasAnyInvalidField() ||
              isLoading
            }
            customClassName={`${BLOCK_NAME}__bottom__save`}
          />
        </div>
      </div>
    );
  }
}

function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      closeModal: closeModalAction,
      createAlert: createAlertAction,
      fetchActionIntegrations: fetchActionIntegrationsAction,
    },
    dispatch,
  );
}

export default connect(null, mapDispatch)(SettingsPlatformsActionsModal);
