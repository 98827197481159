const msConversionFactor = {
  mins: 60000,
  hours: 3600000,
  days: 86400000,
  weeks: 604800000,
};

/**
 * Converts the given unit and amount into milliseconds
 */
export function convertToMS(
  unitOfTime: keyof typeof msConversionFactor,
  amount: number,
): number {
  return msConversionFactor[unitOfTime] * amount;
}

/**
 * Converts milliseconds into the highest order of magnitude for time
 */
export function convertToUnitTime(milliseconds: number) {
  if (milliseconds < 0) {
    return null;
  }

  if (milliseconds === 0) {
    return ["mins", 0];
  }

  let result;
  Object.keys(msConversionFactor).forEach((unitOfTime) => {
    if (
      milliseconds %
        msConversionFactor[unitOfTime as keyof typeof msConversionFactor] ===
      0
    ) {
      result = [
        unitOfTime,
        milliseconds /
          msConversionFactor[unitOfTime as keyof typeof msConversionFactor],
      ];
    }
  });

  return result;
}
