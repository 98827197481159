// @ts-strict-ignore
import Immutable from "immutable";
// Moment is no longer supported. Slack channel #p-remove-moment for details
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";

import RecordClassCreator, { ResourceRecord } from "services/record";

import {
  AnalyticsTimeSeriesRecordValue,
  type AnalyticsTimeSeriesUnit,
  type AnalyticsTimeSeriesValueType,
} from "./helpers";

interface AnalyticsTimeSeriesRecordAttributes {
  dataType: string;
  isoDate: string;
  timeSeriesUnit: AnalyticsTimeSeriesUnit | null;
  values: Immutable.List<AnalyticsTimeSeriesRecordValue>;
  valueType: AnalyticsTimeSeriesValueType;
}

export class AnalyticsTimeSeriesRecord extends RecordClassCreator<AnalyticsTimeSeriesRecordAttributes>(
  {
    type: "analyticsTimeSeriesRecord",
    dataType: null,
    isoDate: null,
    timeSeriesUnit: null,
    values: Immutable.List(),
    valueType: "number",
  },
) {
  static deserialize(
    payload: Record<string, unknown>,
  ): Record<string, unknown> {
    const attributes = ResourceRecord.deserialize<{
      id: string;
      values: Record<string, unknown>[];
    }>(payload);

    return {
      ...attributes,
      values: attributes.values.map(
        (attrs: Record<string, unknown>) =>
          new AnalyticsTimeSeriesRecordValue(attrs),
      ),
    };
  }

  get chartAxisLabel(): string {
    switch (this.timeSeriesUnit) {
      case "month":
        return moment(this.isoDate).format("MMM 'YY");
      case "week":
        return moment(this.isoDate).format("MMM D");
      case "hour":
        return moment(this.isoDate).format("MMM D, ha");
      default:
        return moment(this.isoDate).format("MMM D");
    }
  }

  get tooltipLabel(): string {
    const date = moment(this.isoDate);

    switch (this.timeSeriesUnit) {
      case "month":
        return date.format("MMMM, YYYY");
      case "week":
        return `Week of ${date.format("MMMM D, YYYY")}`;
      case "hour":
        return date.format("dddd, MMMM D, hh:mma");
      default:
        return date.format("dddd, MMMM D, YYYY");
    }
  }
}
