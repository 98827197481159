import { type AnyAction } from "@reduxjs/toolkit";

export interface Session {
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isMfaVerificationRequired: boolean;
  hasAuthenticationError: boolean;
  errorMessage: string;
  isPasswordExpired: boolean;
  isMfaSetupRequired: boolean;
  sessionTimeout: number | null;
  socketConnected: boolean;
  isAuthenticatingPage: boolean;
}

const initialState: Session = {
  isAuthenticated: false,
  isAuthenticating: false,
  isMfaSetupRequired: false,
  hasAuthenticationError: false,
  errorMessage: "",
  isPasswordExpired: false,
  sessionTimeout: null,
  socketConnected: false,
  isMfaVerificationRequired: false,
  isAuthenticatingPage: false,
};

export const session = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case "SMS_VERIFY_REQUEST":
    case "LOGIN_REQUEST":
      return {
        ...state,
        isAuthenticating: true,
      };
    case "SET_IS_MFA_SETUP_REQUIRED":
      return {
        ...state,
        isAuthenticating: false,
        isMfaSetupRequired: true,
        isAuthenticated: false,
        isMfaVerificationRequired: false,
      };
    case "UPDATE_SESSION_AFTER_LOGIN":
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        isMfaVerificationRequired: false,
      };
    case "SMS_REQUEST":
      return {
        ...state,
        isMfaVerificationRequired: true,
        isAuthenticating: true,
      };
    case "SMS_SUCCESS":
    case "SMS_FAILURE":
      return {
        ...state,
        isAuthenticating: false,
      };
    case "LOGIN_FAILURE":
      if (action.response.status === 303) {
        return {
          ...state,
          isPasswordExpired: true,
          isAuthenticating: false,
        };
      }

      if (action.response.status === 418) {
        return {
          ...state,
          isMfaVerificationRequired: true,
          isAuthenticating: false,
        };
      }

      return {
        ...state,
        isAuthenticating: false,
      };
    case "RESET_EXPIRED_PASSWORD_SUCCESS":
      return {
        ...state,
        isPasswordExpired: false,
        isAuthenticating: false,
        isMfaVerificationRequired: false,
      };
    case "SMS_VERIFY_SUCCESS":
      return {
        ...state,
        isAuthenticating: false,
      };
    case "RESET_NOTIFICATION_ERROR":
      return {
        ...state,
        hasAuthenticationError: false,
        errorMessage: "",
      };

    case "RESET_SESSION_TIMEOUT": {
      if (state.sessionTimeout) {
        clearTimeout(state.sessionTimeout);
      }

      const { sessionTimeout } = action.payload;

      return {
        ...state,
        sessionTimeout,
      };
    }

    case "ENFORCE_MFA_ACTIVATE_TFA_REQUEST":
      return {
        ...state,
        isAuthenticating: true,
      };
    case "ENFORCE_MFA_ACTIVATE_TFA_FAILURE":
      if (action.response.status === 418) {
        return {
          ...state,
          isAuthenticating: false,
          errorMessage: "",
          hasAuthenticationError: false,
        };
      }

      return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.response.data.message,
        hasAuthenticationError: true,
        isAuthenticating: false,
      };
    case "SMS_VERIFY_FAILURE":
      if (action.response.status === 303) {
        return {
          ...state,
          isPasswordExpired: true,
          isAuthenticating: false,
        };
      }

      return {
        ...state,
        isAuthenticating: false,
        hasAuthenticationError: true,
        errorMessage: action.response.data.message,
      };
    case "RESET_MFA_REQUIREMENTS":
      return {
        ...state,
        isMfaVerificationRequired: false,
      };
    case "ENFORCE_MFA_SMS_VERIFY_REQUEST":
      return {
        ...state,
        isAuthenticating: true,
      };
    case "ENFORCE_MFA_SMS_VERIFY_SUCCESS":
      return {
        ...state,
        isAuthenticatingPage: false,
        isAuthenticating: false,
        isAuthenticated: false,
      };
    case "ENFORCE_MFA_SMS_VERIFY_FAILURE":
      return {
        ...state,
        isAuthenticating: false,
        hasAuthenticationError: true,
        errorMessage: action.response.data.message,
      };

    case "SET_SOCKET_CONNECTION":
      return {
        ...state,
        socketConnected: action.connected,
      };

    case "UNAUTHENTICATE":
      return {
        ...state,
        isAuthenticated: false,
      };

    default:
      return state;
  }
};
