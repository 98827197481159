import { CounterBadge, Icon, IconButton } from "@adasupport/byron";
import React from "react";
import { useDispatch } from "react-redux";

import * as S from "./styles";

interface Props {
  variableCount: number;
}

export function Header({ variableCount }: Props) {
  const dispatch = useDispatch();

  return (
    <S.Header>
      <S.LeftSide>
        <S.Title>Set test variables</S.Title>
        <CounterBadge number={variableCount} />
      </S.LeftSide>
      <IconButton
        icon={Icon.Close}
        aria-label="Close"
        variant="tertiary"
        onClick={() => {
          dispatch({
            type: "SET_TEST_VARIABLES_MODAL_STATE",
            payload: {
              isVariablePanelCollapsed: true,
            },
          });
        }}
      />
    </S.Header>
  );
}
