import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";
import SvgIcon from "components/Common/SvgIcon";

import "./style.scss";

class Variable extends React.Component {
  /**
   * @param {Event} e
   */
  static handleMouseDown(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    scope: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    orphaned: PropTypes.bool,
    dialog: PropTypes.element,
    customClassName: PropTypes.string,
    icon: PropTypes.string,
    tabIndex: PropTypes.string,
    dropdownArrow: PropTypes.bool,
  };

  static defaultProps = {
    scope: null,
    onClick: null,
    type: null,
    orphaned: false,
    dialog: null,
    customClassName: null,
    icon: null,
    tabIndex: "-1",
    dropdownArrow: false,
  };

  state = {
    dialogOpen: false,
  };

  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.typeIcons = {
      string: "Variables/Icons/Text",
      long: "Variables/Icons/Number",
      bool: "Variables/Icons/Boolean",
      list: "Variables/Icons/List",
    };
    this.scopeIcons = {
      meta: "Asterisk",
      sensitive_meta: "Asterisk",
      headers: "Asterisk",
      oauth: "UserMan",
    };

    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  /**
   */
  toggleDialog() {
    this.setState(({ dialogOpen }) => ({ dialogOpen: !dialogOpen }));
  }

  /**
   * @param {Event} e
   */
  handleMouseUp(e) {
    const { onClick } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (onClick) {
      onClick(e);
    }
  }

  /**
   * Handle click event
   */
  handleClick() {
    const { dialog } = this.props;
    const hasDialog = Boolean(dialog);

    if (hasDialog) {
      this.toggleDialog();
    }
  }

  /**
   * @param {Event} e
   */
  handleKeyPress(e) {
    const { dialog } = this.props;
    const hasDialog = Boolean(dialog);

    if (hasDialog && e.key === "Enter") {
      this.toggleDialog();
    }
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const {
      onClick,
      name,
      scope,
      type,
      orphaned,
      dialog,
      customClassName,
      icon,
      tabIndex,
      dropdownArrow,
    } = this.props;
    const { dialogOpen } = this.state;
    let variableIcon = icon;

    if (!icon) {
      variableIcon = this.scopeIcons[scope];

      if (!variableIcon && type) {
        variableIcon = this.typeIcons[type];
      }
    }

    const hasDialog = Boolean(dialog);
    const role = onClick || hasDialog ? "button" : "presentation";
    const hasArrowIcon = hasDialog || dropdownArrow;

    return (
      // the element has a role, but static linting doesn't seem to detect it
      // since its dynamic, thus disabled the rule for now
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <span
        className={classnames("Variable", {
          [customClassName]: customClassName,
          "Variable--orphaned": orphaned,
          "Variable--left-icon": variableIcon,
          "Variable--right-icon": hasArrowIcon,
        })}
        role={role}
        onMouseDown={Variable.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        tabIndex={tabIndex}
      >
        {/* the element has a role, but static linting doesnt seem to detect it
        since its dynamic, thus disabled the rule for now */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          className={classnames("Variable__inner", {
            [`Variable__inner--${scope}`]: scope,
          })}
          role={role}
          onClick={this.handleClick}
          onKeyPress={this.handleKeyPress}
        >
          {variableIcon && (
            <SvgIcon
              icon={variableIcon}
              customClassName="Variable__left-icon"
              height={16}
            />
          )}
          {name ? (
            <span className="Variable__name">{name}</span>
          ) : (
            <span className="Variable__no-name-text">Untitled</span>
          )}
          {hasArrowIcon && (
            <SvgIcon
              icon="Variables/Icons/ArrowDown"
              customClassName="Variable__arrow-down"
              height={8}
              width={8}
            />
          )}
        </span>
        {!name && (
          <InfoTooltip
            customClassName="Variable__tooltip-required"
            blurb="Please give your variable name"
            iconDefault="Warning"
            absolute
          />
        )}
        {dialog &&
          React.cloneElement(dialog, {
            isOpen: dialogOpen,
            closeDialog: () => this.setState({ dialogOpen: false }),
          })}
      </span>
    );
  }
}

export default Variable;
