// @ts-strict-ignore
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { saveEventAction, setBusinessEventsAction } from "actions/events";
import { closeModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import SvgImage from "components/Common/SvgImage";
import {
  type BusinessEvent,
  DEFAULT_BUSINESS_EVENT,
} from "source/reducers/businessEvents/types";
import ModalImage from "static/images/create_new.svg";

import * as S from "./styles";

const MAX_BUSINESS_EVENT_NAME_LENGTH = 60;

interface Props {
  editableEvent?: BusinessEvent;
  // This is used to redirect the modal on success.
  // You only need to provide the beginning of the url not the end
  // i.e. /events will redirect to /events/<created event id>
  editRedirect?: string;
}

function ModalCreateBusinessEvent(props: Props) {
  const { editableEvent, editRedirect } = props;
  const isEditing = Boolean(editableEvent);
  const { events } = useSelector((state) => state.businessEventState);
  const [event, setEvent] = useState(editableEvent || DEFAULT_BUSINESS_EVENT);

  const dispatch = useDispatch();

  const eventKeyHasInvalidCharacters = event.eventKey.match(/[^\w-]/g);
  const isEventKeyValid = event.eventKey && !eventKeyHasInvalidCharacters;
  const isNameValid =
    event.name && event.name.length <= MAX_BUSINESS_EVENT_NAME_LENGTH;

  const eventIsValid = Boolean(
    isNameValid &&
      isEventKeyValid &&
      (isEditing || !events.find((e) => e.eventKey === event.eventKey)),
  );

  const eventKeyIsArchived = Boolean(
    events.find((e) => e.eventKey === event.eventKey && e.archived),
  );

  const getEventKeyWarningMessage = () => {
    if (eventKeyIsArchived) {
      return "An Archived Event already exists with the Event key";
    }

    if (!event.eventKey || eventKeyHasInvalidCharacters) {
      return "Please use alpha-numeric symbols, dashes, and underscores for the event key";
    }

    return "Cannot create an Event with a duplicate key";
  };

  return (
    <div className="Modal__modal Modal__modal--small Modal__modal--fixed">
      <div className="Modal__content">
        <S.Header>
          <S.ModalImageContainer>
            <SvgImage imageHash={ModalImage} />
          </S.ModalImageContainer>
          <S.Title>
            {editableEvent ? "Edit Event" : "Create a new Event"}
          </S.Title>
          <S.Description>
            Give your Event a name and description to make it clear what you’re
            tracking.
          </S.Description>
        </S.Header>

        <DebouncedInput
          label="Display Name"
          id="displayName"
          isInvalid={!isNameValid}
          value={event.name}
          placeholder="Enter a name for your Event"
          characterCounter={MAX_BUSINESS_EVENT_NAME_LENGTH}
          onChange={(newValue: string) =>
            setEvent({
              ...event,
              name: newValue,
              eventKey: !isEditing
                ? newValue.replace(/(\s)/g, "_").replace(/(\W)/g, "")
                : event.eventKey,
            })
          }
        />
        <DebouncedInput
          label="Event Key"
          id="eventKey"
          value={event.eventKey}
          isInvalid={!isEventKeyValid}
          disabled={isEditing}
          placeholder="Enter a key for your Event"
          onChange={(newValue: string) =>
            !isEditing && setEvent({ ...event, eventKey: newValue })
          }
          warningMessage={getEventKeyWarningMessage()}
        />
        {!editableEvent && (
          <S.Note>
            The Event Key cannot be changed once the Event has been created.
          </S.Note>
        )}
        <DebouncedInput
          label="Description (Optional)"
          isTextArea
          id="description"
          value={event.description}
          placeholder="e.g. Give logged-in customers an offer to upgrade ahead of their renewal cycle"
          onChange={(newValue: string) =>
            setEvent({ ...event, description: newValue })
          }
        />
      </div>
      <div className="Modal__bottom Modal__bottom__spaced">
        <Button
          text="Cancel"
          title="Cancel"
          size="medium"
          onClick={() => dispatch(closeModalAction())}
          light
        />
        <Button
          onClick={async () => {
            if (!eventIsValid) {
              return;
            }

            const savedEvent = (await dispatch(
              saveEventAction(event),
              // typescript is not playing nice with the return value of the thunk message
            )) as unknown as BusinessEvent | null;

            if (savedEvent) {
              if (isEditing) {
                // remove the old event and add the new
                dispatch(
                  setBusinessEventsAction([
                    ...events.filter((value) => value.id !== editableEvent.id),
                    savedEvent,
                  ]),
                );
              } else {
                // if you are not editing add the saved event to the list of events
                // then redirect if a redirection is given
                dispatch(setBusinessEventsAction([...events, savedEvent]));

                if (editRedirect) {
                  dispatch(setPageAction(`${editRedirect}/${savedEvent.id}`));
                }
              }
            }

            dispatch(closeModalAction());
          }}
          text={isEditing ? "Save Event" : "Create Event"}
          title={isEditing ? "Save Event" : "Create Event"}
          disabled={!eventIsValid}
        />
      </div>
    </div>
  );
}

export default ModalCreateBusinessEvent;
