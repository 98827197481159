import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

export const RadioButtonRow = styled.div`
  padding: ${primitives.spacing.space2} 0;
`;

export const RadioDescription = styled.p`
  font-size: ${primitives.fontSize.xSmall};
  color: ${tokens.colors.text.muted};
  max-width: 640px;
  padding: 0 0 ${primitives.spacing.space2} 27px;
`;
