import styled from "styled-components";

import { c, colorPrimitives, colorTokens, v } from "constants/css";

export const Trigger = styled.div<{
  isOpen: boolean;
  isInvalid: boolean;
  disabled: boolean;
}>`
  width: 100%;
  border-radius: 8px;
  justify-content: center;
  transition: background-color 0.25s, box-shadow 0.25s;
  cursor: pointer;
  display: flex;
  align-items: center;

  border: 1px solid ${colorTokens.borderButton};

  &:hover {
    border: 1px solid ${colorPrimitives.black};
  }

  &:active {
    background-color: ${colorTokens.actionPrimaryFocus};
    border: 1px solid ${colorTokens.borderButton};
  }

  ${({ isInvalid }) =>
    isInvalid &&
    `
    border: 1px solid ${colorTokens.statusNegative};
  `};

  ${({ isOpen }) =>
    isOpen &&
    `
    border: 1px solid ${colorPrimitives.black};
    outline: 3px solid ${colorTokens.actionPrimaryFocus}
  `};

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `};
`;

export const ResponseTitleText = styled.span`
  font-size: ${v.smallFontSize};
  padding: ${v.gUnit} 0;
`;

export const DeleteButtonContainer = styled.div`
  transition: opacity ${v.transitionTime} ease;
  opacity: 0;
`;

export const TriggerContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 4px;

  &:hover ${DeleteButtonContainer} {
    opacity: 1;
  }
`;

export const TriggerButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownIconContainer = styled.div`
  padding: 2px;
`;

export const ListContainer = styled.div<{
  triggerHeight: number;
  shouldOpenUp: boolean;
  listHeight: number;
}>`
  list-style: none;
  margin: 0;
  padding: ${v.gUnit} 0 0 0;
  min-width: 332px;
  display: block;
  z-index: 9999998;
  position: absolute;
  background-color: ${c.colorWhite};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  width: 100%;
  left: 0;
  overflow: hidden;

  // Subtract 16px to account for spacing from trigger, and to allow spacing from edge of container
  height: ${({ listHeight }) => listHeight - 16}px;
  ${({ triggerHeight, shouldOpenUp }) =>
    shouldOpenUp
      ? `bottom: ${triggerHeight + 8}px`
      : `top: ${triggerHeight + 8}px`}
`;

export const SearchContainer = styled.div`
  padding: 0 8px 8px 8px;
  border-bottom: 1px solid ${c.colorGrey2};
  display: flex;
`;

export const SearchInputContainer = styled.div`
  width: 100%;
`;

export const NewResponseButtonContainer = styled.div`
  margin-left: ${v.gUnit};
`;

export const ResponseListContainer = styled.div`
  overflow-y: scroll;
  // We need this to fill the remainder of the ListContainer
  // Subtract 32px for height of search bar, 8px for padding
  height: calc(100% - 40px);
`;

export const MaxAnswersMessage = styled.div`
  padding: ${v.gUnit} ${v.gUnitDouble};
  font-size: ${v.xSmallFontSize};
  font-weight: ${v.heavyFontWeight};
  color: ${c.colorGrey4};
  cursor: default;
`;

export const Heading = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${c.colorGrey2};
  font-size: 14px;
  font-weight: ${v.mediumFontWeight};
  color: ${c.colorBlack};
  display: flex;
`;

export const HeadingIconContainer = styled.div`
  background-color: ${c.colorUiPrimary100};
  border-radius: ${v.gUnitDouble};
  padding: 4px;
  height: 24px;
  width: 24px;
`;

export const HeadingText = styled.div`
  margin-left: 12px;
  line-height: 24px;
`;

export const QuickReplyBlockButtonContainer = styled.div<{
  disabled: boolean;
  active: boolean;
}>`
  display: flex;
  width: 200px;
  border: 1px solid ${c.colorGrey2};
  border-radius: 6px;
  padding: 4px 8px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.25s;

  ${({ active }) =>
    active &&
    `
    background-color: ${c.colorGrey2};
  `};

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `};
`;

export const QuickReplyBlockButtonText = styled.span`
  margin: 0 8px;
`;

export const ExactlyCentered = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
