import React, { type ReactNode } from "react";
import { useSelector } from "react-redux";

import { getResource } from "selectors/resources";
import { useAnswerVersioning } from "services/answer-versioning";

interface ReadOnlyWrapperProps {
  // the hide option specifies that we want to HIDE content instead of SHOW for read-only users
  hide?: boolean;
  children: ReactNode;
}

export const READ_ONLY_ROLE = "read_only";

export const useReadOnly = () => {
  const user = useSelector((state) => getResource(state, "user"));
  const { versioning } = useAnswerVersioning();
  const isUserReadOnly = user?.role === READ_ONLY_ROLE;
  const isReadOnly = isUserReadOnly || Boolean(versioning?.versionId);

  return { isReadOnly, isUserReadOnly };
};

export const ReadOnlyWrapper = (props: ReadOnlyWrapperProps) => {
  const { children, hide } = props;
  const { isReadOnly } = useReadOnly();

  // We only want to show content for the following configurations
  // 1. if the user *is* read-only and they have not specified other configurations
  // 2. they have specified the HIDE option and the user is NOT read-only
  if ((isReadOnly && !hide) || (!isReadOnly && hide)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};

ReadOnlyWrapper.defaultProps = {
  hide: false,
};

export function withReadOnlyWrapper<T extends { disabled?: boolean }>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<T>>,
) {
  return function ReadOnly(props: T) {
    const { isReadOnly } = useReadOnly();
    const { disabled } = props;

    return <WrappedComponent {...props} disabled={isReadOnly || disabled} />;
  };
}
