// @ts-strict-ignore
import Immutable from "immutable";

import { type TypedMap } from "types";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

export type CaptureType =
  | "email"
  | "phone_number"
  | "date"
  | "time"
  | "name"
  | "number"
  | "boolean"
  | "numeric_string"
  | "alphanumeric_string"
  | "list";

export const captureTypeMap = {
  email: "string",
  phone_number: "string",
  list: "string",
  date: "string",
  time: "string",
  name: "string",
  number: "long",
  boolean: "bool",
  numeric_string: "string",
  alphanumeric_string: "string",
};

export type VariableData = Immutable.Map<string, string | null>;

export type ListDataItem = Immutable.Map<string, string | null>;

export type ListOptions = TypedMap<{
  data?: Immutable.List<ListDataItem>;
  dataVariableId?: string;
  labelKeypath: string;
}>;

interface Attributes extends BaseMessageRecordAttributes {
  type: "smart_capture";
  alwaysAsk: boolean;
  chatterResponseOptional: boolean;
  instructions: string;
  captureFormat: string;
  captureType?: CaptureType;
  variablesData: Immutable.List<VariableData>;
  listOfOptions?: ListOptions;
}

export class SmartCaptureMessageRecord extends BaseMessageRecord<Attributes>({
  type: "smart_capture",
  alwaysAsk: false,
  chatterResponseOptional: false,
  instructions: "",
  captureFormat: "",
  captureType: undefined,
  listOfOptions: Immutable.Map({
    data: Immutable.List(),
    labelKeypath: "{{label}}",
  }),
  variablesData: Immutable.List(),
}) {
  static getInvalidFields = (
    message: SmartCaptureMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (!message.instructions) {
      invalidFields.add("instructions");
    }

    if (!message.captureType) {
      invalidFields.add("captureType");
    }

    if (!message.variablesData.size) {
      invalidFields.add("variablesData");
    }

    return Immutable.List(invalidFields);
  };
}
