// @ts-strict-ignore
import Immutable from "immutable";
import isEmail from "validator/lib/isEmail";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "handoff_recipe";
  emailMessagesRecipe: Immutable.List<Immutable.Map<string, unknown>>;
}

export class HandoffRecipeMessageRecord extends BaseMessageRecord<Attributes>({
  type: "handoff_recipe",
  emailMessagesRecipe: Immutable.List([
    Immutable.Map({
      type: "capture",
      locked: false,
      captureLead: "First, what's your email?",
      captureKey: "email",
      captureLocked: false,
      captureType: "email",
    }),
    Immutable.Map({
      type: "handoff",
      locked: false,
      handoffEmail: "",
      emailSubject: "",
      successBusinessEvent: new FireableBusinessEvent({
        value: "",
        eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
        isVariable: false,
      }),
    }),
    Immutable.Map({
      body: "We'll be in touch by email shortly!",
      type: "text",
      locked: false,
    }),
  ]),
}) {
  static getInvalidFields = (
    message: HandoffRecipeMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (!message.emailMessagesRecipe.getIn([0, "captureLead"])) {
      invalidFields.add("captureLead");
    }

    if (!isEmail(message.emailMessagesRecipe.getIn([1, "handoffEmail"]))) {
      invalidFields.add("handoffEmail");
    }

    if (!message.emailMessagesRecipe.getIn([2, "body"])) {
      invalidFields.add("body");
    }

    return Immutable.List(invalidFields);
  };
}
