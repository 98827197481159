import classNames from "classnames";
import React, { useRef, useState } from "react";
import tinycolor from "tinycolor2";

import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";
import { cssColors } from "constants/css";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { type TagType } from "reducers/tags/types";
import colors from "stylesheets/utilities/colors.scss";

import { InfoTooltip } from "../InfoTooltip";

import "./style.scss";

const BLOCK_NAME = "Tag";
const DEFAULT_COLOR = cssColors.colorTagDefault;

interface TagProps {
  tag: TagType;
  isSmall?: boolean;
  showAsLine?: boolean;
  hasIcon?: boolean;
  customClassName?: string;
  onClick?: (name: string) => void;
  removeTag?: (id: string) => void;
}

export const Tag = ({
  tag,
  customClassName = "",
  isSmall = false,
  showAsLine = false,
  onClick,
  removeTag,
  hasIcon = false,
}: TagProps) => {
  const tagText = useRef<HTMLDivElement>(null);
  const [isLong, setIsLong] = useState(false);

  const handleClick = () => {
    if (tag.name) {
      onClick?.(tag.name);
    }
  };

  const handleRemove = () => {
    removeTag?.(tag.id);
  };

  useComponentDidMount(() => {
    const tagTextTotalWidth = tagText.current?.scrollWidth ?? 0;
    const tagTextShownWidth = tagText.current?.clientWidth ?? 0;

    if (tagTextTotalWidth > tagTextShownWidth) {
      setIsLong(true);
    }
  });

  const isLight = tinycolor(tag.color ?? DEFAULT_COLOR).isLight();

  return (
    <div
      className={classNames(BLOCK_NAME, {
        [customClassName]: customClassName,
        [`${BLOCK_NAME}--is-light`]: isLight,
        [`${BLOCK_NAME}--line`]: showAsLine,
        [`${BLOCK_NAME}--small`]: isSmall,
        [`${BLOCK_NAME}--icon`]: hasIcon,
      })}
      role="presentation"
      style={{ backgroundColor: tag.color ?? DEFAULT_COLOR }}
      onClick={handleClick}
    >
      <div
        className={`${BLOCK_NAME}__text`}
        ref={tagText}
        data-testid="tag-text"
      >
        {hasIcon && (
          <SvgIcon
            icon="Star"
            customClassName="ResponseListItem__icons__icon"
            fillColor="currentColor"
            height={13}
          />
        )}
        {!showAsLine && tag.name}
      </div>
      {isLong && (
        <InfoTooltip blurb={tag.name} iconDefault="none" openOnHover absolute />
      )}
      {removeTag && (
        <Button
          customClassName={`${BLOCK_NAME}__button`}
          onClick={handleRemove}
          title="Remove Tag"
          fillColor={isLight ? colors.colorBlack : colors.colorWhite}
          icon="NoEntry"
          clear
        />
      )}
    </div>
  );
};
