import React, { Component } from "react";

import { Button } from "components/Common/Button";
import { Notification } from "components/Common/Notification";
import SvgIcon from "components/Common/SvgIcon";
import { NO_OP_FUNCTION } from "services/helpers";
import "./style.scss";

interface LoginSplashProps {
  button?: {
    label?: string;
    action?(): void;
    icon?: string;
    isLoading?: boolean;
    disabled?: boolean;
  };
  errorMessage?: string;
  children?: React.ReactNode | undefined;
  description: React.ReactNode | string;
  fillColor?: string;
  icon?: string;
  resetNotificationError?(): void;
  stroke?: string;
  showError?: boolean;
  strokeDashArray?: string;
  title: string;
}

export class LoginSplash extends Component<LoginSplashProps> {
  getIcon() {
    const {
      icon = "",
      fillColor = "currentColor",
      strokeDashArray = "",
      stroke = "",
    } = this.props;

    return (
      <SvgIcon
        icon={icon}
        customClassName="Splash__icon"
        fillColor={fillColor}
        strokeDashArray={strokeDashArray}
        stroke={stroke}
      />
    );
  }

  getButton() {
    const { button = {} } = this.props;

    return (
      <Button
        icon={button.icon}
        text={button.label}
        onClick={button.action}
        isLoading={button.isLoading}
        disabled={button.disabled}
      />
    );
  }

  render() {
    const {
      title,
      icon,
      button = {},
      children,
      description,
      showError = false,
      resetNotificationError = NO_OP_FUNCTION,
      errorMessage = "",
    } = this.props;

    return (
      <div className="LoginSplash">
        {icon && this.getIcon()}
        <h1 className="LoginSplash__header">{title}</h1>

        {showError && (
          <Notification
            description={errorMessage}
            notificationType="error"
            className="Login__notification"
            onClose={resetNotificationError}
          />
        )}
        <p className="LoginSplash__description">{description}</p>
        {children}
        {button.label && this.getButton()}
      </div>
    );
  }
}
