import { AxiosError } from "axios";

import { createAlert } from "actions/alerts";
import { type ThunkAction } from "actions/types";
import { adaApiRequest } from "services/api";

export function updatePassword(
  oldPassword: string,
  password: string,
  userId: string,
): ThunkAction {
  return async (dispatch) => {
    try {
      await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: `/users/${userId}/password/`,
          data: {
            old_password: oldPassword,
            new_password: password,
          },
        }),
      );

      dispatch(
        createAlert({
          alertType: "success",
          message: "Your password has been updated.",
        }),
      );
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 403) {
        dispatch(
          createAlert({ message: "The password you entered was incorrect." }),
        );
      } else {
        dispatch(createAlert({ message: "Failed to updated password." }));
      }

      throw error;
    }
  };
}
