import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updatePlatform } from "actions";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { useChatPlatform } from "services/platforms";

import {
  type ClientInputLaunchControls,
  shouldInitializeLaunchControls,
  validateLaunchControls,
} from "./launchControls";

export function useLaunchControls() {
  const { chatPlatform } = useChatPlatform();
  const dispatch = useDispatch();
  const [isValid, setValidity] = useState(true);
  const { launchControls } = chatPlatform;

  useComponentDidMount(() => {
    if (
      shouldInitializeLaunchControls(launchControls) &&
      typeof chatPlatform.rollout === "number"
    ) {
      dispatch(
        updatePlatform("chat", {
          launchControls: {
            showPrimaryBot: parseFloat((chatPlatform.rollout * 100).toFixed(2)),
            showAlternativeBot: 0,
            showNoBot: parseFloat(
              (100 - chatPlatform.rollout * 100).toFixed(2),
            ),
          },
        }),
      );
    }
  });

  useEffect(() => {
    if (!validateLaunchControls(launchControls as ClientInputLaunchControls)) {
      setValidity(false);
    } else {
      setValidity(true);
    }
  }, [launchControls, isValid, setValidity]);

  return {
    isValid,
    launchControls,
    updateLaunchControls(updates: Partial<ClientInputLaunchControls>) {
      dispatch(
        updatePlatform("chat", {
          launchControls: {
            ...updates,
          },
        }),
      );
    },
  };
}
