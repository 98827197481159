import axios from "axios";

import { adaAPI } from "services/api";

import { type ApiKeyAction } from "./reducer";

export async function fetchApiKeyInfo(
  loading: boolean,
  dispatch: (a: ApiKeyAction) => void,
) {
  if (loading) {
    return;
  }

  dispatch({ type: "GET_KEY_REQUEST" });

  try {
    const response = await adaAPI.request({
      method: "GET",
      url: "/campaigns/proactive_sms_token",
    });
    const metadata = {
      createdAt: response.data.createdAt as string,
      expireAt: response.data.expireAt as string,
    };
    dispatch({ type: "GET_KEY_SUCCESS", metadata });
  } catch (e: unknown) {
    // handle no existing token
    if (axios.isAxiosError(e) && e.response?.status === 404) {
      dispatch({ type: "GET_KEY_SUCCESS", metadata: null });
    } else {
      dispatch({ type: "GET_KEY_FAILURE" });
    }
  }
}

export async function generateApiKey(dispatch: (a: ApiKeyAction) => void) {
  dispatch({ type: "CREATE_KEY_REQUEST" });

  try {
    const response = await adaAPI.request({
      method: "POST",
      url: "/campaigns/proactive_sms_token",
    });
    const apiKey = response.data.token as string;
    const metadata = {
      createdAt: response.data.createdAt as string,
      expireAt: response.data.expireAt as string,
    };
    dispatch({ type: "CREATE_KEY_SUCCESS", apiKey, metadata });
  } catch (e: unknown) {
    console.warn(e);
    dispatch({ type: "CREATE_KEY_FAILURE" });
  }
}
