/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { Button } from "components/Common/Button";
import "./style.scss";

interface ModalDeleteProps {
  title: string;
  confirmMessage: string;
  delete(...args: unknown[]): unknown;
  setState(...args: unknown[]): unknown;
}

export class ModalDelete extends React.Component<ModalDeleteProps> {
  static isSmall: boolean;
  constructor(props: any) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    const { setState } = this.props;

    // Can't use delete as a variable name since it's a keyword
    // eslint-disable-next-line react/destructuring-assignment
    this.props.delete();
    setState("MODAL", {
      isOpen: false,
      view: "",
    });
  }

  render() {
    const { title, confirmMessage, setState } = this.props;

    return (
      <div className="DeleteModal">
        <h5 className="Modal__title ModalDelete__title">{title}</h5>
        <div className="Modal__content ModalDelete__description">
          {confirmMessage}
        </div>
        <div className="ModalDelete__bottom Modal__bottom">
          <Button
            customClassName="ModalDelete__button__delete ModalDelete__button"
            text="Delete"
            onClick={this.handleDelete}
            light
          />
        </div>
        <div className="ModalDelete__bottom Modal__bottom">
          <Button
            customClassName="ModalDelete__button__cancel ModalDelete__button"
            text="Cancel"
            onClick={() =>
              setState("MODAL", {
                isOpen: false,
                view: "",
              })
            }
            light
          />
        </div>
      </div>
    );
  }
}

ModalDelete.isSmall = true;
