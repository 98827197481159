// TODO Would be nice to see this go away
export class DHDate {
  date: Date;

  MILLISECONDS_PER_SEC = 1000;
  MILLISECONDS_PER_MIN = this.MILLISECONDS_PER_SEC * 60;

  // IMPORTANT: This constructor assumes that the incoming timestamp is UTC-based, as all
  // timestamps coming from api are in UTC. The date will be converted to the browser's timezone.
  constructor(timestamp: string | number) {
    // Get date object in UTC time, but with browser's timezone
    const dateWithBrowserTimeZone =
      typeof timestamp === "string"
        ? new Date(timestamp)
        : new Date(timestamp * 1000);

    // Calculate correct time for browser timezone
    this.date = new Date(
      dateWithBrowserTimeZone.getTime() -
        new Date().getTimezoneOffset() * this.MILLISECONDS_PER_MIN,
    );
  }

  humanReadableTimeAgo(): string {
    const delta = Math.round(
      (new Date().getTime() - this.date.getTime()) / 1000,
    );
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 31;
    const year = day * 365;

    let timeAgoString = "";

    if (delta < 30) {
      timeAgoString = "just now";
    } else if (delta < minute) {
      timeAgoString = `${delta} seconds ago`;
    } else if (delta < 2 * minute) {
      timeAgoString = "a minute ago";
    } else if (delta < hour) {
      timeAgoString = `${Math.floor(delta / minute)} minutes ago`;
    } else if (Math.floor(delta / hour) === 1) {
      timeAgoString = "an hour ago";
    } else if (delta < day) {
      timeAgoString = `${Math.floor(delta / hour)} hours ago`;
    } else if (delta < day * 2) {
      timeAgoString = "yesterday";
    } else if (delta < week) {
      timeAgoString = `${Math.floor(delta / day)} days ago`;
    } else if (Math.floor(delta / week) === 1) {
      timeAgoString = "a week ago";
    } else if (delta < month) {
      timeAgoString = `${Math.floor(delta / week)} weeks ago`;
    } else if (Math.floor(delta / month) === 1) {
      timeAgoString = "a month ago";
    } else if (delta < year) {
      timeAgoString = `${Math.floor(delta / month)} months ago`;
    } else if (Math.floor(delta / year) === 1) {
      timeAgoString = "a year ago";
    } else {
      timeAgoString = `${Math.floor(delta / year)} years ago`;
    }

    return timeAgoString;
  }
}
