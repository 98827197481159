/**
 * This service is an abstraction layer on top of Ada's date time needs to allow us to
 * centralize external dependencies and provide a consistent interface for our app regardless if a third
 * party module (like moment) so that if they need to be changed or removed, we only need to do that here.
 */
import * as dateFns from "date-fns";
// Moment is no longer supported. Slack channel #p-remove-moment for details
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";

type DateLike = Date | number | string;

// These are here to make it easy to keep our date styles consistent through the app and future proof us against changes
// ex: AdaDateTime.format(someDate, AdaDateTime.INTL_STANDARD)
export const FORMATS = {
  DATE_FORMATS: {
    NAMED_MONTH_DAY_YEAR: "MMM DD, YYYY", // Sep 08, 2022
    NAMED_DAY_MONTH_YEAR: "DD MMMM, YYYY", // 08 September, 2022
    NAMED_MONTH_DAY: "MMM D", // Sep 8
    NAMED_DAY_MONTH_DATE_YEAR_TIME: "LLLL", // Friday, September 8, 2022 1:06 PM
    NAMED_MONTH_DATE_YEAR_TIME: "LLL", // September 8, 2022 10:51 AM
    NAMED_MONTH_DAY_NAMED_YEAR: "MMMM D, YYYY", // September 8, 2022
    DAY_NAMED_MONTH_DATE_YEAR: "dddd, MMMM D, YYYY", // Friday, September 8, 2022
    NAMED_MONTH_YEAR: "MMM 'YY", // Sep '22
    NAMED_MONTH_FULL_YEAR: "MMMM, YYYY", // September, 2022
    INTL_STANDARD: "YYYY-MM-DD ", // 2022-09-08
  },
  TIME_FORMATS: {
    HOUR_MINUTE_AMPM: "LT", // 1:07 PM
    TWELVE_HOUR_MINUTE_AMPM: "h:mm A", // 1:07 PM
  },
  COMBINED_FORMATS: {
    NAMED_MONTH_HOUR_MINUTE_AMPM: "MMM DD, hh:mmA", // Sep 08, 01:07PM
  },
};

export type DateDiffPeriods =
  | "BUSINESS_DAYS"
  | "DAYS"
  | "HOURS"
  | "MILLISECONDS"
  | "MINUTES"
  | "MONTHS"
  | "SECONDS"
  | "WEEKS";

// Used to make sure we can easily take in multiple types and still be working with a date object
function toDate(dateStringNumber: DateLike): Date {
  return moment(dateStringNumber).toDate();
}

function isInThePast(compareDate: DateLike): boolean {
  return toDate(compareDate).valueOf() < Date.now();
}

function isInTheFuture(compareDate: DateLike): boolean {
  return toDate(compareDate).valueOf() > Date.now();
}

// Note, using moment here because the Data Fn tokens are different, so they will need to be manually re-mapped
function format(
  date: DateLike,
  tokenString: string,
  timezone?: string,
  isUnix?: boolean,
): string {
  if (timezone) {
    return moment(date).tz(timezone).format(tokenString);
  }

  if (isUnix) {
    return moment.unix(date as number).format(tokenString);
  }

  return moment(date).format(tokenString);
}

// Use this if you are replacing moment.unix()
function secondsToMilliseconds(seconds: number): number {
  return seconds * 1000;
}

function fromNow(time: DateLike, excludeSuffix?: boolean): string {
  if (excludeSuffix) {
    return moment(time).fromNow(true);
  }

  return moment(time).fromNow();
}

function utcToLocal(time: DateLike): Date {
  return moment.utc(time).local().toDate();
}

function calendar(date: DateLike): string {
  return moment(date).calendar();
}

function toUTC(date: DateLike, tokenString?: string): Date {
  if (tokenString) {
    return moment.utc(date, tokenString).toDate();
  }

  return moment.utc(date).toDate();
}

function diff(
  dateLeft: DateLike,
  dateRight: DateLike,
  period: DateDiffPeriods,
) {
  switch (period) {
    case "BUSINESS_DAYS":
      return dateFns.differenceInBusinessDays(
        toDate(dateLeft),
        toDate(dateRight),
      );
    case "DAYS":
      return dateFns.differenceInDays(toDate(dateLeft), toDate(dateRight));
    case "HOURS":
      return dateFns.differenceInHours(toDate(dateLeft), toDate(dateRight));
    case "MILLISECONDS":
      return dateFns.differenceInMilliseconds(
        toDate(dateLeft),
        toDate(dateRight),
      );
    case "MINUTES":
      return dateFns.differenceInMinutes(toDate(dateLeft), toDate(dateRight));
    case "MONTHS":
      return dateFns.differenceInMonths(toDate(dateLeft), toDate(dateRight));
    case "SECONDS":
      return dateFns.differenceInSeconds(toDate(dateLeft), toDate(dateRight));
    case "WEEKS":
      return dateFns.differenceInWeeks(toDate(dateLeft), toDate(dateRight));
    default:
      throw new Error(`Unsupported time diff period: ${String(period)}`);
  }
}

function parseISO(dateString: string): Date {
  return dateFns.parseISO(dateString);
}

function getClientTimeZone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    return undefined;
  }
}

export const AdaDateTime = {
  calendar,
  diff,
  getClientTimeZone,
  isInThePast,
  isInTheFuture,
  fromNow,
  format,
  parseISO,
  secondsToMilliseconds,
  toDate,
  toUTC,
  utcToLocal,
  ...FORMATS,
};
