import axios, {
  type AxiosError,
  type AxiosPromise,
  type AxiosResponse,
  type Method,
  type ResponseType,
} from "axios";
import qs from "qs";

import {
  checkUserExpiry,
  resetSessionTimeout,
  setSessionTimeoutModal,
} from "actions/authentication";
import { setErrorPage } from "actions/error";
import { goToPage } from "actions/router";
import { type ThunkAction } from "actions/types";
import { getAPIRoot } from "services/api-root";
import { socket } from "services/sockets/socket";
import { storage } from "services/storage";

const defaultParamsSerializer = (params: unknown) =>
  qs.stringify(params, { arrayFormat: "brackets" });

axios.defaults.paramsSerializer = defaultParamsSerializer;

interface RequestOptions {
  method: Method;
  url: string;
  params?: Record<string, unknown>;
  data?: Record<string, unknown>;
  requestType?: string;
  responseType?: ResponseType;
  abortSignal?: AbortSignal;
  paramSerializer?: (params: unknown) => string;
}

class AdaAPI {
  requestTokens: { [key: string]: () => void };

  constructor() {
    this.requestTokens = {};
  }

  cancelLastRequest(requestType: string): void {
    const previousCancelToken = this.requestTokens[requestType];

    if (previousCancelToken) {
      previousCancelToken();
    }
  }

  request<R = Record<string, unknown>>({
    method,
    url,
    params = {},
    data = {},
    requestType = undefined,
    responseType = "json",
    abortSignal,
    paramSerializer = defaultParamsSerializer,
  }: RequestOptions): AxiosPromise<R> {
    const { CancelToken } = axios;
    const csrf = storage.retrieve("csrf");

    return axios({
      method,
      url,
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Ada-Request-Origin": "app",
        ...(csrf && { "X-CSRF-Token": csrf }),
      },
      ...(abortSignal && { signal: abortSignal }),
      params,
      data,
      responseType,
      baseURL: getAPIRoot(),
      cancelToken: new CancelToken((cancel) => {
        if (requestType) {
          this.requestTokens[requestType] = cancel;
        }
      }),
      paramsSerializer: paramSerializer,
    });
  }
}

// Create new AdaAPI instance
export const adaAPI = new AdaAPI();

export const vimeoApi = axios.create({
  baseURL: "https://vimeo.com/api/oembed.json",
  headers: {
    "Content-Type": "application/json",
  },
});

interface AdaApiErrorData {
  type?: string;
  source?: string;
  reason?: string;
}

/**
 * The following is a thunk action which adds a layer of error handling (via dispatching various
 * action) to adaAPI requests.
 */
export function handleUnauthorized(errorData: AdaApiErrorData): ThunkAction {
  return (dispatch) => {
    if (window.location.pathname === "/") {
      return;
    }

    if (errorData.reason === "mfa_not_setup") {
      dispatch({
        type: "SET_MODAL",
        payload: {
          isOpen: true,
          view: "MODAL_ENFORCE_MFA",
          modalProps: {},
        },
      });
    } else {
      // Auto logout user if unauthorized status code
      dispatch({
        type: "CLEAR_CREDENTIALS",
      });

      dispatch({
        type: "UNAUTHENTICATE",
      });

      dispatch(goToPage("/"));
    }
  };
}

// 403 indicates invalid token
export function handleForbidden(): ThunkAction {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_CREDENTIALS",
    });

    dispatch({
      type: "UNAUTHENTICATE",
    });
  };
}

// 419 indicates session timeout
export function handleSessionTimeout(): ThunkAction {
  return (dispatch, getState) => {
    // No need to show modal if already logged out
    if (getState().session.isAuthenticated) {
      dispatch(setSessionTimeoutModal());
    }
  };
}

export function handleServiceUnavailable(): ThunkAction {
  return (dispatch) => {
    dispatch(setErrorPage(true, 503));
  };
}

interface AdaApiRequestArgs {
  method: Method;
  url: string;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  abortSignal?: AbortSignal;
}

/**
 * The essence of an ADA_API_REQUEST action is the adaAPI.request call.
 * This middleware adds some error handling logic around the request.
 *
 * Error handling logic aside, this:
 *     dispatch(adaApiRequest({ ...params }))
 * is equivalent to this:
 *     adaAPI.request({ ...params })
 */
export function adaApiRequest<R extends Record<string, unknown>>(
  requestOptions: AdaApiRequestArgs,
): ThunkAction<Promise<AxiosResponse<R>>> {
  return (dispatch) => {
    // Check password expiry status on each call
    dispatch(checkUserExpiry());

    return adaAPI.request<R>(requestOptions).then(
      (response) => {
        dispatch(resetSessionTimeout());

        return response;
      },
      (error: AxiosError<AdaApiErrorData | undefined>) => {
        if (axios.isCancel(error)) {
          console.warn("Request aborted");
        }

        // Handling the case when there's no internet connection (no response)
        if (!error.response) {
          throw error;
        }

        // Error codes from other API requests (such as the one we use for testing HTTP blocks)
        // could return a code that would trigger a user to become unauthenticated. To combat
        // against this, we check for specific fields on the request to ensure we only perform
        // error authentication actions when they are legitimate.
        if (
          error.response.data &&
          error.response.data.type === "ada_not_authorized" &&
          error.response.data.source === "ada"
        ) {
          if (error.response.status === 401) {
            dispatch(handleUnauthorized(error.response.data));
          } else if (error.response.status === 403) {
            dispatch(handleForbidden());
          } else if (error.response.status === 419) {
            dispatch(handleSessionTimeout());
          } else if (error.response.status === 503) {
            dispatch(handleServiceUnavailable());
          }
        }

        throw error;
      },
    );
  };
}

/**
 * The WS request includes getting the WS channel id from the endpoint, listening on that channel id, and handling success/failure WS events
 * to achieve this we:
 * 1 make the initial call to the endpoint to get the channel_name
 * 2 subscribe to the channel_name websocket
 * 3 make the follow-up call to the endpoint with the channel_name to notify that we are subscribed
 * 4 handle the ws events <successEvent> and <failEvent>
 *
 * A common use case for this function is when waiting for long-running data to load
 */

interface WebsocketAdaApiRequestArgs extends AdaApiRequestArgs {
  successEvent?: string;
  failEvent?: string;
  fallbackTime?: number; // milliseconds
}

interface WebsocketFallbackRequestArgs extends WebsocketAdaApiRequestArgs {
  channelRequest: AxiosResponse;
  fail: string;
  success: string;
}

function websocketFallbackRequest({
  method,
  url,
  channelRequest,
  fallbackTime,
  fail,
  success,
}: WebsocketFallbackRequestArgs): ThunkAction<Promise<unknown>> {
  return async (dispatch) =>
    new Promise((resolve) => {
      const dataWithChannel = {
        subscribed_channel_name: channelRequest.data.channel_name,
      };

      dispatch(adaApiRequest({ method, url, params: dataWithChannel })).then(
        () => {
          // wait <fallbackTime> replaces waiting for the websocket to tell us the data is ready
          setTimeout(() => {
            resolve(success);
          }, fallbackTime);
        },
        () => {
          resolve(fail);
        },
      );
    });
}

export function websocketAdaApiRequest({
  method,
  url,
  data,
  successEvent,
  failEvent,
  fallbackTime,
}: WebsocketAdaApiRequestArgs): ThunkAction<Promise<unknown>> {
  return async (dispatch) => {
    const success = successEvent ?? "success";
    const fail = failEvent ?? "failure";
    // 1st call the WS endpoint
    const channelRequest: AxiosResponse = await dispatch(
      adaApiRequest({ method, url, data }),
    );

    if (socket.instance.connection.state !== "connected" && fallbackTime) {
      return dispatch(
        websocketFallbackRequest({
          method,
          url,
          channelRequest,
          fallbackTime,
          fail,
          success,
        }),
      );
    }

    if (channelRequest.data.channel_name) {
      // 2nd subscribe to the returned channel
      const channel = socket.instance.subscribe(
        channelRequest.data.channel_name,
      );

      // Immediately subscribe to events
      const onWSEventSubscribed = new Promise((r) =>
        // eslint-disable-next-line no-promise-executor-return
        channel.bind("pusher:subscription_succeeded", () => r(true)),
      );
      const onWSEvent = new Promise((resolve) => {
        channel.bind(success, () => resolve(success));
        channel.bind(fail, () => resolve(fail));
      });

      // 3rd once we're set up on the WS
      // call the WS endpoint again with the channel, to let API know you're subscribed
      await onWSEventSubscribed;
      const dataWithChannel = {
        subscribed_channel_name: channelRequest.data.channel_name,
      };

      try {
        await dispatch(adaApiRequest({ method, url, params: dataWithChannel }));
      } catch (error) {
        channel.unbind();
        socket.instance.unsubscribe(channelRequest.data.channel_name);

        return fail;
      }

      // 4th wait for the WS events on the channel, action them when they arrive
      const wsEvent = await onWSEvent;
      // remove all handlers from channel
      channel.unbind();

      // unsubscribe from channel
      socket.instance.unsubscribe(channelRequest.data.channel_name);

      return wsEvent;
    }

    return fail;
  };
}
