import {
  CLIENT_SECRET_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  LOCAL_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
} from "constants/variables";
import { type Variable } from "services/variables";

export type VariableType =
  | "auto_capture"
  | "global"
  | "local"
  | "meta"
  | "token"
  | "sensitive"
  | "unknown";

export const VARIABLE_TYPE_LABELS: Record<VariableType, string> = {
  auto_capture: "Auto-capture",
  global: "Variables",
  local: "Local",
  meta: "Metadata",
  token: "Token",
  sensitive: "Sensitive",
  unknown: "Other",
};

export const getVariableType = (variable: Variable): VariableType => {
  // TODO: Remove this once these variables are marked as meta on the backend
  if (
    variable.name === "channel" ||
    variable.name === "chatter_id" ||
    variable.name === "end_user_id"
  ) {
    return "meta";
  }

  if (
    [SENSITIVE_VARIABLE_SCOPE, SENSITIVE_META_VARIABLE_SCOPE].includes(
      variable.scope,
    )
  ) {
    return "sensitive";
  }

  if (
    [OAUTH_VARIABLE_SCOPE, CLIENT_SECRET_VARIABLE_SCOPE].includes(
      variable.scope,
    )
  ) {
    return "token";
  }

  if (variable.scope === GLOBAL_VARIABLE_SCOPE) {
    return "global";
  }

  if (variable.scope === LOCAL_VARIABLE_SCOPE) {
    return "local";
  }

  if (variable.scope === "meta") {
    return "meta";
  }

  if (variable.scope === "auto_capture") {
    return "auto_capture";
  }

  return "unknown";
};

export const getVariableValueType = (
  variable: Variable,
): "text" | "number" | "boolean" | "list" | "unknown" => {
  if (variable._type === "long") {
    return "number";
  }

  if (variable._type === "bool") {
    return "boolean";
  }

  if (variable._type === "string") {
    return "text";
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (variable._type === "list") {
    return "list";
  }

  return "unknown";
};
