import { createSlice } from "@reduxjs/toolkit";

export interface ConversationsState {
  articleFlyout: {
    isOpen: boolean;
    viewMode: "edit" | "preview" | null;
    articleId: string | null;
  };
}

export const initialState: ConversationsState = {
  articleFlyout: {
    isOpen: false,
    viewMode: null,
    articleId: null,
  },
};

const conversationsSlice = createSlice({
  name: "conversationsSlice",
  initialState,
  reducers: {
    setArticleFlyout(state, action) {
      return {
        ...state,
        articleFlyout: action.payload,
      };
    },
  },
});

export const { setArticleFlyout } = conversationsSlice.actions;
export const { reducer: conversationsReducer } = conversationsSlice;
