import { keyConverter } from "services/key-converter";

/**
 * This is used to safely mass merge objects to Immutable Records.
 * Immutable Records will trigger an error if a key not found in the Record is merged.
 *
 * @deprecated
 * @param {Object} object
 * @param {Object} state
 * @param {String} [type]
 * @param {Boolean} [capitalize]
 * @returns {Object}
 */
function recordMerger(object, state, type, capitalize) {
  const convertedObject = keyConverter(object, type, capitalize);

  Object.keys(convertedObject).forEach((key) => {
    if (state.get(key) === undefined || convertedObject[key] === null) {
      delete convertedObject[key];
    }
  });

  return convertedObject;
}

export { recordMerger };
