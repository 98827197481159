import { type TreeNodeProps } from "rc-tree";
import React from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";
import SvgIcon from "components/Common/SvgIcon";
import { getAnswerIconInfoByLiveStatus } from "components/Shared/Pages/Folders/helpers/iconRender";
import { cssVariables } from "constants/css";
import { useHoverDelay } from "hooks/useHoverDelay";
import { type FolderRecordProps } from "reducers/folders/types/folderRecord";

import * as S from "./styles";

interface LeafIconProps {
  size?: number;
  nodeProps: TreeNodeProps;
  hasVoiceEnabled: boolean;
}

export const LeafIcon = ({
  size = cssVariables.mediumSvgIconHeight.numHeight,
  nodeProps,
  hasVoiceEnabled,
}: LeafIconProps) => {
  const { isOpen, handleMouseEnter, handleMouseLeave } = useHoverDelay();
  const { expanded, isLeaf, data } = nodeProps;

  if (isLeaf) {
    const { live, liveVoice } = data as FolderRecordProps;
    const leafIconInfo = getAnswerIconInfoByLiveStatus(live, liveVoice);

    return (
      <S.Container
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <InfoTooltip
          blurb={leafIconInfo.iconTooltip}
          opened={hasVoiceEnabled && isOpen}
          absolute
          above
        >
          <SvgIcon
            icon={hasVoiceEnabled ? leafIconInfo.iconName : "AnswerBubble"}
            height={size}
          />
        </InfoTooltip>
      </S.Container>
    );
  }

  const icon = expanded ? "FolderOpen" : "FolderClosed";

  return (
    <S.Container>
      <SvgIcon icon={icon} height={size} />
    </S.Container>
  );
};
