// @ts-strict-ignore
import Immutable from "immutable";

import { JAVASCRIPT_EVENT_NAME_CHAR_LIMIT } from "constants/messages";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "custom_javascript_event";
  eventName: string;
}

export class JavascriptEventMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "custom_javascript_event",
    eventName: "",
  },
) {
  static getInvalidFields = (
    message: JavascriptEventMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();
    const { eventName } = message;
    const eventNameCleaned = message.eventName.replace(/ /g, "");

    if (
      eventNameCleaned !== eventName ||
      eventNameCleaned.length <= 0 ||
      eventNameCleaned.length > JAVASCRIPT_EVENT_NAME_CHAR_LIMIT
    ) {
      invalidFields.add("eventName");
    }

    return Immutable.List(invalidFields);
  };
}
