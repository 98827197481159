import { Button } from "@adasupport/byron";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SimpleModal } from "components/Common";
import {
  AVAILABILITY_OPTIONS,
  ArticleAvailabilityModalContent,
} from "components/Generative/Pages/Knowledge/ArticleAvailabilityModalContent/ArticleAvailabilityModalContent";
import {
  RULE_DSL,
  type RuleDefinition,
  parseRuleQuery,
  ruleHasInvalidFields,
  serializeRuleset,
} from "services/rules";
import {
  useCreateRulesetMutation,
  useGetRulesetsQuery,
} from "slices/knowledge/knowledgeApiSlice";
import {
  clearSelectedArticles,
  setAvailabilityModalOpen,
} from "slices/knowledge/knowledgeSlice";
import { type Article, type ArticleRulesMap } from "slices/knowledge/types";

import * as S from "./styles";

export const DEFAULT_RULES: RuleDefinition = {
  name: "all",
  args: [
    {
      name: "any",
      args: [{ name: RULE_DSL.IS, args: [{ type: "variable", id: "" }, ""] }],
    },
  ],
};

const getSharedRuleForArticles = ({
  articles,
  rulesets,
}: {
  articles: Article[];
  rulesets?: ArticleRulesMap;
}): string | null => {
  if (!articles.length || !articles[0] || !rulesets) {
    return null;
  }

  const firstArticleRule = rulesets[articles[0].id];

  if (!firstArticleRule) return null;

  if (!articles.every((article) => rulesets[article.id] === firstArticleRule)) {
    return null;
  }

  return firstArticleRule;
};

export function ArticleAvailabilityModal() {
  const dispatch = useDispatch();
  const [availabilityOption, setAvailabilityOption] = useState(
    AVAILABILITY_OPTIONS.EVERYONE,
  );
  const { availabilityModalOpen, selectedArticles } = useSelector(
    (state) => state.knowledge,
  );

  const [rules, setRules] = useState(DEFAULT_RULES);
  const [isValid, setIsValid] = useState(true);
  const { data: rulesets } = useGetRulesetsQuery({});
  const [createRuleset] = useCreateRulesetMutation();

  useEffect(() => {
    const sharedRule = getSharedRuleForArticles({
      articles: selectedArticles,
      rulesets,
    });

    if (sharedRule) {
      setRules(parseRuleQuery(sharedRule) as RuleDefinition);
      setAvailabilityOption(AVAILABILITY_OPTIONS.LIMITED);
    } else {
      setRules(DEFAULT_RULES);
      setAvailabilityOption(AVAILABILITY_OPTIONS.EVERYONE);
    }
  }, [rulesets, selectedArticles, availabilityModalOpen]);

  const handleCloseModal = () => {
    dispatch(setAvailabilityModalOpen(false));
    setRules(DEFAULT_RULES);
    setAvailabilityOption(AVAILABILITY_OPTIONS.EVERYONE);
    setIsValid(true);
  };

  const handleSaveRuleset = () => {
    const availabilityLimited =
      availabilityOption === AVAILABILITY_OPTIONS.LIMITED;

    if (availabilityLimited && ruleHasInvalidFields(rules)) {
      setIsValid(false);

      return;
    }

    const serializedRules = availabilityLimited ? serializeRuleset(rules) : "";

    createRuleset({
      rules: serializedRules,
      articleIds: selectedArticles.map((article) => article.id),
    });
    setRules(DEFAULT_RULES);
    dispatch(clearSelectedArticles());
    handleCloseModal();
  };

  return (
    <SimpleModal
      title="Set availability"
      isShown={availabilityModalOpen}
      onClose={handleCloseModal}
    >
      <ArticleAvailabilityModalContent
        rules={rules}
        availabilityOption={availabilityOption}
        isValid={isValid}
        setRules={setRules}
        setAvailabilityOption={setAvailabilityOption}
        setIsValid={setIsValid}
      />
      <S.Footer>
        <Button onClick={handleCloseModal} text="Cancel" variant="tertiary" />
        <Button onClick={handleSaveRuleset} text="Save" isDisabled={!isValid} />
      </S.Footer>
    </SimpleModal>
  );
}
