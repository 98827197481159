import { getResources } from "selectors/resources";
import { type State } from "types";

export function getLanguageName({
  state,
  languageCode,
}: {
  state: State;
  languageCode: string | null;
}) {
  if (!languageCode) {
    return null;
  }

  const languages = getResources(state, "language");
  const languageAttrs = languages?.find(
    (language) => language.code === languageCode,
  );

  if (!languageAttrs) {
    return null;
  }

  return languageAttrs.englishName;
}
