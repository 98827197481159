import { primitives, tokens } from "@adasupport/byron";
import styled, { css } from "styled-components";

export const MatchCaseButtonContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  white-space: nowrap;
`;

export const MatchCaseButton = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  color: ${tokens.colors.text.muted};
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  height: 24px;
  width: 24px;
  padding: 0;

  ${(p) =>
    p.active &&
    css`
      color: ${tokens.colors.text.mainInverted};
      font-weight: 600;
      background-color: ${primitives.palette.slate500};
    `}
`;
