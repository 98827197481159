import { type ThunkAction } from "actions/types";
import { type Client, selectClient } from "services/client";

export const dismissWindowAlertAction = () =>
  ({
    type: "DISMISS_WINDOW_ALERT",
  } as const);

export interface WindowAlertEventData {
  type: string;
  title: string;
  content: string;
  image: string;
  clients?: string[];
  action_required: boolean;
}

export const generateWindowAlertAction = (
  eventData: WindowAlertEventData,
  client: Client | null,
) =>
  ({
    type: "GENERATE_WINDOW_ALERT",
    title: eventData.title || null,
    content: eventData.content || null,
    image: eventData.image || null,
    clients: eventData.clients || [],
    actionRequired: eventData.action_required,
    currentClient: client,
    windowAlertType: eventData.type || null,
  } as const);

function generateWindowAlert(eventData: WindowAlertEventData): ThunkAction {
  return (dispatch, getState) => {
    dispatch(generateWindowAlertAction(eventData, selectClient(getState())));
  };
}

export function createWindowAlert(
  eventData: WindowAlertEventData,
): ThunkAction {
  return (dispatch, getState) => {
    // Ignore alert if another alert is already open
    if (!getState().windowAlert.get("isVisible")) {
      dispatch(generateWindowAlert(eventData));
    }
  };
}
