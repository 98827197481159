import { createSelector } from "reselect";

import { versionedResponsesLoadedSelector } from "components/Shared/Pages/Responses/ResponseVersions/selectors";
import { type ResponseRecord } from "reducers/responses/types";
import { type State } from "types";

type SelectorResponse = (
  state: State,
  responseId?: string | null,
) => ResponseRecord | undefined;

export const selectResponseById: SelectorResponse = createSelector(
  versionedResponsesLoadedSelector,
  (state: State, responseId?: string) => responseId,
  (responsesLoaded, responseId) =>
    responseId ? responsesLoaded.get(responseId) : undefined,
);

/**
 * @deprecated Use selectResponseById instead.
 * @alias selectResponseById
 */
export const selectActiveResponseById = selectResponseById;
