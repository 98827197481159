import { Button } from "@adasupport/byron";
import React from "react";
import { useLocation } from "react-router-dom";

import { LoginSplash } from "components/Common/LoginSplash";
import * as Modal from "components/Shared/Pages/Settings/Modals/styles";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { useGetKBSettingsQuery } from "slices/knowledge/knowledgeApiSlice";
import colors from "stylesheets/utilities/colors.scss";

import { useGoToKnowledgeHub } from "./hooks";

export function IntegrationSuccessMessage() {
  const goToKnowledgeHub = useGoToKnowledgeHub();
  const location = useLocation();
  const { refetch: settingsRefetch } = useGetKBSettingsQuery();

  const isOnKnowledgeHub = location.pathname === "/content/knowledge";

  const BLOCK_NAME = "SettingsSmartSearchModal";

  useComponentDidMount(() => {
    // if on knowledge hub, refetch kb settings to update the state of knowledge hub or else the state will be outdated and only refetched if the user refreshes the page
    if (isOnKnowledgeHub) settingsRefetch();
  });

  return (
    <Modal.Modal>
      <Modal.ModalBody>
        <LoginSplash
          title="Successfully Connected"
          description="Your bot can now answer customer inquiries using your knowledge base content. Articles may take a few minutes to sync."
          icon="CircleCheckmark"
          fillColor={colors.colorUIGood}
        />
      </Modal.ModalBody>
      <Modal.ModalFooter className={`${BLOCK_NAME}__bottom `}>
        <Button
          variant="primary"
          text={isOnKnowledgeHub ? "Done" : "Go to Knowledge page"}
          onClick={goToKnowledgeHub}
        />
      </Modal.ModalFooter>
    </Modal.Modal>
  );
}
