import { tokens } from "@adasupport/byron";
import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";

import { cssVariables } from "constants/css";

export const Content = styled(Popover.Content)`
  z-index: ${cssVariables.zIndexModal};
  min-width: 240px;
  max-width: 550px;
  background-color: ${tokens.colors.background.main};
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 0 10px 0 rgb(0 0 0 / 18%);
  border-radius: 8px;
  padding: 12px 0;
`;

export const Option = styled.div`
  gap: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: 6px 16px;
  font-size: 14px;

  &:hover {
    background: ${tokens.colors.action.hover};
  }
`;
