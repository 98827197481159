import omit from "lodash.omit";
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { setPageAction } from "actions/router";
import { type ExcludeOptions } from "components/Common/DoubleDatepickerDropdown/constants";
import { useFilterOptionsDict } from "components/Shared/Pages/AnalyticsPage/AnalyticsSingleReportPage/AnalyticsReportFilter/hooks";
import {
  type AnalyticsReportFilterAttributes,
  AnalyticsReportFilterData,
  getAllowedUnitValues,
} from "components/Shared/Pages/AnalyticsPage/services";
import { type AnalyticsTimeSeriesUnit } from "resourceModels/AnalyticsTimeSeriesRecord/helpers";
import { getResource } from "selectors/resources";
import {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE,
  FIRST_DAY_OF_LAST_YEAR_LOCAL,
  LAST_DAY_OF_LAST_YEAR_LOCAL,
  TWO_YEARS_AGO,
} from "services/date-strings";

import {
  type ConversationPageParams,
  type ConversationQueryStringParams,
  type ParamsType,
} from "./types";

/**
 * Manages common page parameters used across Conversations module, like:
 *  - Analytical filters,
 *  - Start date
 *  - End date,
 *  - Use time zone,
 *  - Topic Id
 */
export const useConversationPageParams = (): ConversationPageParams => {
  const MIN_ALLOWED_DATE = TWO_YEARS_AGO;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const allowedOptionsDict = useFilterOptionsDict();

  const { id: topicId } = useParams<ParamsType>();
  const user = useSelector((state) => getResource(state, "user"));
  const timeZone = user?.timeZone || "UTC";

  const queryString = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search],
  ) as ConversationQueryStringParams;

  /**
   * Utility to obtain a AnalyticsReportFilterData based on what is on the URL.
   * This function returns only filters allowed by the Conversation Topics reports.
   *
   * Note: When these filters are used by the fetchMany function,
   * all keys are snakeCased by function itself.
   * If you need to used it like that in others places,
   * you need call snakeCaseKeys function by your own in that place.
   */
  const analyticalFilters = useMemo(() => {
    const filter = AnalyticsReportFilterData.deserializeFilters(
      location.search,
      [],
      allowedOptionsDict,
      {},
      { appendScopeToVariableId: false },
    );

    // Remove not allowed filters
    type FilterKey = keyof AnalyticsReportFilterData;
    const isTestUser: FilterKey = "isTestUser";
    const paths = [isTestUser];

    return omit(filter, paths) as AnalyticsReportFilterAttributes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  /**
   * Validate if the specified date is greater than the minimum allowed date.
   * Also ensures start date is not after than end date
   * @param defaultDate Default date to use in case the one specified is invalid.
   * @param params All dates contained in the query string.
   * @param key The query string param to check (sd or ed)
   * @returns The specified date if valid, the defaulDate otherwise.
   */
  const checkAllowedDate = (
    defaultDate: string,
    params: ConversationQueryStringParams,
    key: keyof ConversationQueryStringParams,
  ) => {
    const date = params[key];

    if (date) {
      const start = new Date(params.sd ?? "");
      const end = new Date(params.ed ?? "");
      const checkDate = new Date(date);
      const minDate = new Date(MIN_ALLOWED_DATE);

      const isValidOrder =
        end > start || end.toISOString() === start.toISOString();
      const isValidDateRange =
        key === "ed" || (key === "sd" && checkDate >= minDate);

      if (isValidOrder && isValidDateRange) {
        return date;
      }
    }

    return defaultDate;
  };

  const [startDate, setStartDate] = useState(
    checkAllowedDate(DEFAULT_START_DATE, queryString, "sd"),
  );
  const [endDate, setEndDate] = useState(
    checkAllowedDate(DEFAULT_END_DATE, queryString, "ed"),
  );
  const [timeSeriesUnit, setTimeSeriesUnit] = useState(
    queryString.tsu ?? "day",
  );
  const resetCache = useRef(!!queryString.reset_cache);

  /**
   * We're excluding lastYear option while MIN_ALLOWED_DATE
   * falls between last year's start and end date.
   */
  const excludeDatePickerOptions = useMemo(() => {
    const minDate = new Date(MIN_ALLOWED_DATE);
    const startDateState = new Date(FIRST_DAY_OF_LAST_YEAR_LOCAL);
    const endDateState = new Date(LAST_DAY_OF_LAST_YEAR_LOCAL);

    if (startDateState >= minDate && endDateState >= minDate) {
      return [];
    }

    return ["lastYear"];
  }, []) as ExcludeOptions[];

  /**
   * Utility to update start_date (sd) and end_date (ed) filters.
   */
  const setDateRange = useCallback(
    (start: string, end: string) => {
      const params = qs.stringify({
        ...queryString,
        sd: start,
        ed: end,
      });

      history.replace(`${location.pathname}?${params}`);
    },
    [history, location.pathname, queryString],
  );

  /**
   * Update state if value differs from url
   */
  useEffect(() => {
    if (queryString.sd !== startDate || queryString.ed !== endDate) {
      setStartDate(queryString.sd ?? DEFAULT_START_DATE);
      setEndDate(queryString.ed ?? DEFAULT_END_DATE);
    }

    if (queryString.tsu !== timeSeriesUnit) {
      const allowedUnitValues = getAllowedUnitValues(startDate, endDate);

      if (
        !allowedUnitValues.includes(queryString.tsu as AnalyticsTimeSeriesUnit)
      ) {
        setTimeSeriesUnit(allowedUnitValues[0] as string);
      } else {
        setTimeSeriesUnit(queryString.tsu ?? "day");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  /**
   * Check if we have a invalid date in the URL.
   * If so, update URL with the valid date within out internal state.
   */
  const updateInvalidRangeDate = useCallback(() => {
    if (queryString.sd !== startDate || queryString.ed !== endDate) {
      const params = qs.stringify({
        ...queryString,
        sd: startDate,
        ed: endDate,
      });
      history.replace(`${location.pathname}?${params}`);
    }
  }, [startDate, endDate, history, location.pathname, queryString]);

  /*
   * Utility to update Analytical filters.
   */
  const setAnalyticalFilters = useCallback(
    (filter?: string | null) => {
      const params = qs.stringify({
        ...queryString,
        f: filter,
      });
      history.replace(`${location.pathname}?${params}`);
    },
    [history, location.pathname, queryString],
  );

  /**
   * Remove reset_cache from the URL after the first render.
   */
  const invalidateResetCache = useCallback(() => {
    const { reset_cache: resetParam, ...otherParams } = queryString;

    if (resetParam) {
      resetCache.current = false;
      history.replace({
        pathname: location.pathname,
        search: qs.stringify(otherParams),
      });
    }
  }, [history, location.pathname, queryString]);

  /**
   * Utility to get a url with the current params in the URL.
   */
  const getURLWithPersistedParams = useCallback(
    (path: string, extraParams?: { [key: string]: unknown }) => {
      const query = qs.stringify({ ...queryString, ...extraParams });

      return `${path}?${query}`;
    },
    [queryString],
  );

  /**
   * Utility to redirect to a page keeping the existent query params.
   */
  const redirectWithPersistedParams = useCallback(
    (path: string, extraParams?: { [key: string]: unknown }) => {
      dispatch(setPageAction(getURLWithPersistedParams(path, extraParams)));
    },
    [dispatch, getURLWithPersistedParams],
  );

  useEffect(() => {
    updateInvalidRangeDate();
    invalidateResetCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    startDate,
    endDate,
    timeSeriesUnit,
    timeZone,
    topicId,
    resetCache: resetCache.current,
    analyticalFilters,
    datePickerOptions: {
      excludeDatePickerOptions,
      minDate: MIN_ALLOWED_DATE,
      tooltip: `The timeline range for conversations are limited to dates after ${MIN_ALLOWED_DATE}`,
    },
    setDateRange,
    setAnalyticalFilters,
    setTimeSeriesUnit,
    redirectWithPersistedParams,
    getURLWithPersistedParams,
  };
};
