// @ts-strict-ignore
import Immutable from "immutable";

import { type MessageRecord } from "reducers/responses/messageRecords";
import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "scheduled_block";
  scheduleId: string;
  affirmativeMessages: Immutable.List<MessageRecord>;
  negativeMessages: Immutable.List<MessageRecord>;
}

export class ScheduledBlockRecord extends BaseMessageRecord<Attributes>({
  type: "scheduled_block",
  scheduleId: "",
  affirmativeMessages: Immutable.List(),
  negativeMessages: Immutable.List(),
}) {
  static getInvalidFields: InvalidFieldsGetter<ScheduledBlockRecord> = (
    message,
  ) => {
    const invalidFields: Set<string> = new Set();

    if (!message.scheduleId) {
      invalidFields.add("scheduleId");
    }

    // Check that affirmative and negative messages are non-empty
    if (message.affirmativeMessages.size === 0) {
      invalidFields.add("affirmativeMessages");
    }

    if (message.negativeMessages.size === 0) {
      invalidFields.add("negativeMessages");
    }

    // Make sure there are no scheduled blocks in the scheduled block
    if (
      message.affirmativeMessages
        .concat(message.negativeMessages)
        .find((m) => m.type === "scheduled_block")
    ) {
      invalidFields.add("nestedScheduledBlock");
    }

    // Get invalid fields from nested messages (affirmativeMessages)
    message.affirmativeMessages.forEach((m, index) => {
      m.invalidFields.forEach((field) =>
        invalidFields.add(`affirmativeMessages-${index}-${field}`),
      );
    });

    // Get invalid fields from nested messages (negativeMessages)
    message.negativeMessages.forEach((m, index) => {
      m.invalidFields.forEach((field) =>
        invalidFields.add(`negativeMessages-${index}-${field}`),
      );
    });

    return Immutable.List(invalidFields);
  };
}

/**
 * Creates ScheduledBlockRecord, using `messageCreator` to create nested messages
 */
export function ScheduledBlockRecordCreator(
  attributes: Record<string, unknown>,
  messageCreator: (
    message: unknown,
    shouldMessageTransformFromAPI: boolean,
  ) => MessageRecord,
  shouldMessageTransformFromAPI: boolean,
): ScheduledBlockRecord {
  const attrsMap: TypedMap<{
    affirmativeMessages: unknown[];
    negativeMessages: unknown[];
  }> = Immutable.Map(attributes);

  return new ScheduledBlockRecord({
    ...(attrsMap.toJS() as Attributes),
    affirmativeMessages: Immutable.List(
      attrsMap.get("affirmativeMessages"),
    ).map((m) => messageCreator(m, shouldMessageTransformFromAPI)),
    negativeMessages: Immutable.List(attrsMap.get("negativeMessages")).map(
      (m) => messageCreator(m, shouldMessageTransformFromAPI),
    ),
  });
}
