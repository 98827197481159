// @ts-strict-ignore
import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface NoteMessageRecordAttributes extends BaseMessageRecordAttributes {
  type: "note";
  body: string;
  updated: Date;
  updatedBy: string;
  isNew: boolean;
}

export class NoteMessageRecord extends BaseMessageRecord<NoteMessageRecordAttributes>(
  {
    type: "note",
    body: "",
    updated: new Date(),
    updatedBy: "",
    isNew: false,
  },
) {
  static getInvalidFields = (
    message: NoteMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (message.body.trim().length <= 0) {
      invalidFields.add("body");
    }

    return Immutable.List(invalidFields);
  };
}
