import Immutable from "immutable";

import { type Variable } from "services/variables";

import { type ReferenceCounts } from "./ReferenceCounts";

/**
 * @deprecated - Use `Variable` from `services/variables/api.ts` instead
 */
export interface VariableLegacy {
  id: string;
  name: string;
  key: string;
  defaultValue: string;
  scope: string;
  responseId: string;
  originId: string;
  displayName: string;
  responseReferences: string[];
  active: boolean;
  type: string;
  totalAnswersReferencing: number;
  answersReferencing: string[];
  totalUsageReferences: number;
  usageReferences: string[];
  totalCaptureReferences: number;
  captureReferences: string[];
  responseReferenceCounts: Record<string, ReferenceCounts>;
  modified: boolean;
  isValid: boolean;
  isReadOnly: boolean;
}

export interface Attributes {
  id: string;
  name: string;
  key: string;
  defaultValue: string;
  scope: string;
  responseId: string | null;
  originId: string | null;
  displayName: string;
  responseReferences: Immutable.List<string>;
  active: boolean;
  type: "string" | "long" | "bool" | "list";
  totalAnswersReferencing: number;
  answersReferencing: Immutable.List<string>;
  totalUsageReferences: number;
  usageReferences: Immutable.List<string>;
  totalCaptureReferences: number;
  captureReferences: Immutable.List<string>;
  responseReferenceCounts: Immutable.Map<string, ReferenceCounts>;
  modified: boolean;
  isValid: boolean;
  isReadOnly: boolean;
}

/** @deprecated - Use `Variable` from `services/variables` */
export class VariableRecord extends Immutable.Record<Attributes>({
  id: "",
  name: "",
  key: "",
  defaultValue: "",
  scope: "",
  responseId: null, // should be null for globals
  originId: null, // for locals originId == responseId, for globals this should be set
  displayName: "",
  responseReferences: Immutable.List(),
  active: true, // Deprecated. Will be deleted in later task
  type: "string",
  totalAnswersReferencing: 0,
  answersReferencing: Immutable.List(),
  totalUsageReferences: 0,
  usageReferences: Immutable.List(),
  totalCaptureReferences: 0,
  captureReferences: Immutable.List(),
  // client-side only attributes
  responseReferenceCounts: Immutable.Map(),
  modified: false,
  isValid: true,
  isReadOnly: false,
}) {}

export const toVariable = (variable: VariableRecord): Variable => ({
  _id: variable.id,
  name: variable.name,
  display_name: variable.displayName,
  description: undefined,
  scope: variable.scope as Variable["scope"],
  _type: variable.type as Variable["_type"],
  capture_modes: [],
  answers_referencing: variable.answersReferencing.toArray(),
  response_id: variable.responseId || undefined,
});
