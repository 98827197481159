import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "custom_handoff_event";
}

export class CustomHandoffEventMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "custom_handoff_event",
  },
) {
  static getInvalidFields = (): Immutable.List<string> => Immutable.List();
}
