export enum PredicateValueType {
  ALL = "all",
  ANY = "any",
}

export enum ConditionPropertyType {
  COUNT = "count",
}

export enum ConditionRelationType {
  EQUAL = "equal",
  GREATER_THAN = "greater-than",
  LESS_THAN = "less-than",
}

export enum GoalValueType {
  CONSTANT = "constant",
  AGGREGATE = "aggregate",
  DEFAULT = "",
}

export interface GoalCondition {
  type: string;
  eventKey: string;
  property: ConditionPropertyType;
  relation: ConditionRelationType;
  value: number;
}

export interface GoalPredicate {
  type: "predicate";
  value: PredicateValueType;
  conditions: GoalCondition[]; // upgrading to (GoalCondition | Predicate)[] would allow nested logic
}

interface GoalValue {
  type: GoalValueType;
  value?: number;
}

export interface Goal {
  id: string;
  version: string;
  name: string;
  created: number;
  updated: number;
  updatedBy: string;
  description: string;
  successCondition: GoalPredicate;
  timeframeMinutes: number;
  value?: GoalValue;
}

export interface GoalsState {
  loading: boolean;
  loaded: boolean;
  goals: Goal[];
  draftGoal: Goal;
}
