import styled from "styled-components";

import { c, v } from "constants/css";

export const Modal = styled.div`
  background: ${c.colorWhite};
  border-radius: ${v.gUnit};
`;

export const ModalHeader = styled.section`
  padding: ${v.gUnitDouble};
`;

export const ModalTitle = styled.h1`
  padding: ${v.gUnit};
  text-align: center;
  font-weight: ${v.heavyFontWeight};
  font-size: ${v.xLargeFontSize};
`;

export const ModalDescription = styled.p`
  text-align: center;
  font-size: ${v.smallFontSize};
  color: ${c.colorGrey4};
`;

export const ModalBody = styled.section`
  padding: ${v.gUnitDouble};
  font-size: ${v.smallFontSize};
  border-bottom: 1px solid ${c.colorGrey1};
  overflow-y: auto;
  max-height: 70vh;
`;

export const FormInput = styled.div`
  padding: ${v.gUnit};
`;

export const OptionalLabel = styled.span`
  color: ${c.colorGrey4};
`;

export const ModalFooter = styled.section`
  display: flex;
  justify-content: space-between;
  padding: ${v.gUnitDouble};
`;
