// @ts-strict-ignore
import Immutable from "immutable";
import React, { type ReactElement, useState } from "react";
import { useDispatch } from "react-redux";

import { updateExpressionLanguageAction } from "actions/expressions";
import { closeModalAction } from "actions/modal";
import * as Modal from "components/Common/ModalElements/styles";
import { Select } from "components/Common/Select";
import { useGetTrainingText } from "components/Declarative/Pages/Responses/hooks/useGetTrainingText";
import { colorTokens, v } from "constants/css";
import { useLanguage } from "services/language/hooks";
import { type TrainedExpression } from "slices/training/types";

import * as S from "./styles";

interface Props {
  expression: TrainedExpression;
  isAnimating: boolean;
}

export function SetExpressionLanguageModal({
  expression,
  isAnimating,
}: Props): ReactElement {
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(
    expression.language,
  );
  const dispatch = useDispatch();
  const { getAvailableLanguages, clientLanguage } = useLanguage();
  const expressionText = useGetTrainingText(expression);

  let languages = getAvailableLanguages([]) || Immutable.List();

  if (clientLanguage) {
    languages = languages.unshift(clientLanguage);
  }

  const options = languages
    .sort((a, b) => a.englishName.localeCompare(b.englishName))
    .map((language) => ({ label: language.englishName, value: language.code }))
    .toArray();

  const handleSelect = () => {
    if (selectedLanguageCode) {
      dispatch(
        updateExpressionLanguageAction(expression, selectedLanguageCode),
      );

      dispatch(closeModalAction());
    }
  };

  return (
    <>
      <Modal.Title>Select language</Modal.Title>
      <Modal.ContentFrame>
        <S.Content>{`Select the language of the trained question "${expressionText}".`}</S.Content>
        {/** `Select` does width/height calcs that will be inaccurate when animating */}
        {!isAnimating && (
          <Select
            placeholder="Choose Language"
            options={options}
            isInvalid={false}
            value={selectedLanguageCode}
            onChange={setSelectedLanguageCode}
          />
        )}
      </Modal.ContentFrame>
      <div>
        <Modal.FullWidthButton
          textColor={colorTokens.statusInformative}
          fontWeight={v.mediumFontWeight}
          onClick={handleSelect}
        >
          Update
        </Modal.FullWidthButton>
        <Modal.FullWidthButton
          last
          onClick={() => {
            dispatch(closeModalAction());
          }}
        >
          Cancel
        </Modal.FullWidthButton>
      </div>
    </>
  );
}

SetExpressionLanguageModal.isSmall = true;
