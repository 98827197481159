interface AnswerIconInfo {
  iconName: string;
  iconTooltip?: string;
}

const ICON_ANSWER_DEFAULT: Readonly<AnswerIconInfo> = {
  iconName: "AnswerBubble",
};

const ICON_ANSWER_MESSAGING_LIVE: Readonly<AnswerIconInfo> = {
  iconName: "AnswerMessagingLive",
  iconTooltip: "Messaging is live",
};

const ICON_ANSWER_VOICE_LIVE: Readonly<AnswerIconInfo> = {
  iconName: "AnswerVoiceLive",
  iconTooltip: "Phone is live",
};

const ICON_ANSWER_MULTI_MODALITY_LIVE: Readonly<AnswerIconInfo> = {
  iconName: "AnswerMultiModalityLive",
  iconTooltip: "Messaging and phone are live",
};

const ICON_ANSWER_INACTIVE: Readonly<AnswerIconInfo> = {
  iconName: "AnswerInactive",
  iconTooltip: "This Answer is inactive",
};

export const getAnswerIconInfoByLiveStatus = (
  live: boolean,
  liveVoice: boolean,
): Readonly<AnswerIconInfo> => {
  const isAnswerInactive = !live && !liveVoice;
  const isAnswerMultiModalityLive = live && liveVoice;
  const isAnswerMessagingLive = live && !liveVoice;
  const isAnswerVoiceLive = liveVoice && !live;

  if (isAnswerMessagingLive) return ICON_ANSWER_MESSAGING_LIVE;
  if (isAnswerInactive) return ICON_ANSWER_INACTIVE;
  if (isAnswerVoiceLive) return ICON_ANSWER_VOICE_LIVE;
  if (isAnswerMultiModalityLive) return ICON_ANSWER_MULTI_MODALITY_LIVE;

  return ICON_ANSWER_DEFAULT;
};
