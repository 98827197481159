// @ts-strict-ignore
import * as Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface DeauthMessageRecordAttributes extends BaseMessageRecordAttributes {
  type: "sign_out";
  authProvider: string;
}

export class DeauthMessageRecord extends BaseMessageRecord<DeauthMessageRecordAttributes>(
  {
    type: "sign_out",
    authProvider: "",
  },
) {
  static getInvalidFields = (
    message: DeauthMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (message.authProvider.length <= 0) {
      invalidFields.add("authProvider");
    }

    return Immutable.List(invalidFields);
  };
}
