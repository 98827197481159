import { type AnyAction } from "@reduxjs/toolkit";

import {
  createActionIntegration,
  createActionIntegrationAction,
  createActionIntegrationBlockConfiguration,
  createActionIntegrationInputField,
  createActionIntegrationOutputField,
  createActionIntegrationsState,
} from "reducers/actionIntegrations/factory";

import {
  type ActionIntegration,
  type ActionIntegrationAction,
  type ActionIntegrationsState,
} from "./types";

export const actionIntegrations = (
  state = createActionIntegrationsState(),
  action: AnyAction,
): ActionIntegrationsState => {
  switch (action.type) {
    case "FETCH_ACTION_INTEGRATIONS_REQUEST":
      return { ...state, loading: true };
    case "FETCH_ACTION_INTEGRATIONS_SUCCESS":
      return {
        ...state,
        loaded: true,
        loading: false,
        actionIntegrations: action.actionsList.map(
          (actionIntegration: ActionIntegration) =>
            createActionIntegration({
              ...actionIntegration,
              actions: actionIntegration.actions.map(
                (actionIntegrationAction: ActionIntegrationAction) =>
                  createActionIntegrationAction({
                    ...actionIntegrationAction,
                    input_schema: actionIntegrationAction.input_schema.map(
                      (inputField) =>
                        createActionIntegrationInputField(inputField),
                    ),
                    output_schema: actionIntegrationAction.output_schema.map(
                      (outputField) =>
                        createActionIntegrationOutputField(outputField),
                    ),
                    block_configuration:
                      createActionIntegrationBlockConfiguration(
                        actionIntegrationAction.block_configuration,
                      ),
                  }),
              ),
            }),
        ),
      };
    case "FETCH_ACTION_INTEGRATIONS_FAILURE":
      return { ...state, loading: false };
    case "SET_EMPTY_ACTION_INTEGRATIONS":
      return {
        ...state,
        loaded: true,
        loading: false,
        actionIntegrations: [],
      };
    default:
      return state;
  }
};
