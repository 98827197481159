import { type AnyAction } from "@reduxjs/toolkit";
import Immutable from "immutable";
import isEqual from "lodash.isequal";

import { fromAPI } from "adapters/message";
import {
  MESSAGING_MODALITY,
  MODALITY_DETAILS,
  type Modality,
  VOICE_MODALITY,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { uuid } from "services/generate-uuid";
import { recordMerger } from "services/record-merger";
import { type TypedMap } from "types";

import {
  ActionIntegrationMessageRecord,
  AuthMessageRecord,
  CaptureMessageRecord,
  type ConditionalMessageRecord,
  ConditionalMessageRecordCreator,
  CsatMessageRecord,
  CustomHandoffEventMessageRecord,
  DeauthMessageRecord,
  GenerativeHandoffMessageRecord,
  HTTPMessageRecord,
  HandoffIntegrationMessageRecord,
  HandoffMessageRecord,
  HandoffRecipeMessageRecord,
  JavascriptEventMessageRecord,
  LinkMessageRecord,
  ListSelectionRecord,
  type MessageRecord,
  NoteMessageRecord,
  PictureMessageRecord,
  QuickRepliesMessageRecord,
  RedirectRecord,
  SalesforceLiveAgentMessageRecord,
  SalesforceMessageRecord,
  SatisfactionSurveyMessageRecord,
  type ScheduledBlockRecord,
  ScheduledBlockRecordCreator,
  SecureNuanceLiveAgentMessageRecord,
  ShuffleMessageRecord,
  SmartCaptureMessageRecord,
  TextMessageRecord,
  TrackEventMessageRecord,
  UnsupportedMessageRecord,
  VariableOverrideMessageRecord,
  VideoMessageRecord,
  VoiceEndCallMessageRecord,
  VoiceHandoffMessageRecord,
  VoiceSMSMessageRecord,
  WebWindowMessageRecord,
  WeightedRandomIntegerMessageRecord,
  WidgetMessageRecord,
  ZendeskAgentWorkspaceHandoffMessageRecord,
  ZendeskHandoffMessageRecord,
  ZendeskLiveAgentMessageRecord,
  createProcessInstructionMessageRecord,
  createWebActionMessageRecord,
} from "./messageRecords";
import {
  type BaseImmutableMessageRecord,
  type BaseMessageRecordInstance,
} from "./messageRecords/BaseMessageRecord";
import {
  type MessageRecordOptions,
  type ResponseMessageDto,
  ResponseRecord,
} from "./types";
import { getResponseIndex } from "./variables-helpers";

export const MESSAGE_RECORDS = {
  text: TextMessageRecord,
  shuffle: ShuffleMessageRecord,
  web_window: WebWindowMessageRecord,
  picture: PictureMessageRecord,
  video: VideoMessageRecord,
  link: LinkMessageRecord,
  custom_handoff_event: CustomHandoffEventMessageRecord,
  custom_javascript_event: JavascriptEventMessageRecord,
  capture: CaptureMessageRecord,
  handoff: HandoffMessageRecord,
  handoff_recipe: HandoffRecipeMessageRecord,
  helpdesk_handoff_recipe: ZendeskHandoffMessageRecord,
  http_request_recipe: HTTPMessageRecord,
  zendesk_agent_workspace_handoff: ZendeskAgentWorkspaceHandoffMessageRecord,
  base: HTTPMessageRecord, // base is a display_type override http_request_recipe
  sign_in: AuthMessageRecord,
  sign_out: DeauthMessageRecord,
  scheduled_block: ScheduledBlockRecordCreator as unknown as new (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ) => ScheduledBlockRecord,
  conditionals_block: ConditionalMessageRecordCreator as unknown as new (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ) => ConditionalMessageRecord,
  survey: SatisfactionSurveyMessageRecord,
  nuance_secure_live_agent: SecureNuanceLiveAgentMessageRecord,
  zendesk_live_agent: ZendeskLiveAgentMessageRecord,
  salesforce_live_agent: SalesforceLiveAgentMessageRecord,
  redirect_block: RedirectRecord,
  list_selection_template: ListSelectionRecord,
  variable_override: VariableOverrideMessageRecord,
  widget: WidgetMessageRecord,
  salesforce: SalesforceMessageRecord,
  quick_replies_block: QuickRepliesMessageRecord,
  csat_survey: CsatMessageRecord,
  action_integration: ActionIntegrationMessageRecord,
  unsupported: UnsupportedMessageRecord,
  note: NoteMessageRecord,
  afm_event: TrackEventMessageRecord,
  handoff_integration: HandoffIntegrationMessageRecord,
  voice_end_call: VoiceEndCallMessageRecord,
  voice_handoff: VoiceHandoffMessageRecord,
  voice_sms: VoiceSMSMessageRecord,
  weighted_random_integer: WeightedRandomIntegerMessageRecord,
  smart_capture: SmartCaptureMessageRecord,
  generative_handoff: GenerativeHandoffMessageRecord,
} as const;

// Message record creator functions
// These return plain objects instead of Immutable Records
export const MESSAGE_RECORD_FACTORIES = {
  web_action_block: createWebActionMessageRecord,
  process_instruction_block: createProcessInstructionMessageRecord,
};

let newResponse: ResponseRecord;
let prevState: Immutable.List<ResponseRecord> | undefined;

export function createMessageRecord(
  message:
    | TypedMap<MessageRecordOptions>
    | MessageRecord
    | Record<string, unknown>,
  shouldTransformFromAPI = true,
): MessageRecord {
  const immutableMessage = Immutable.fromJS(message) as TypedMap<
    Partial<MessageRecordOptions>
  >;
  const messageType = immutableMessage.get("type");
  const messageDisplayType = immutableMessage.get("displayType");

  // TODO: Validate message type before using it as a key for `MESSAGE_RECORDS`
  const MessageRecordType =
    MESSAGE_RECORDS[
      (messageDisplayType || messageType) as keyof typeof MESSAGE_RECORDS
    ];
  let messageRecord;

  if (messageType === "text") {
    messageRecord = new ShuffleMessageRecord({
      id: uuid(),
      type: "shuffle",
      messages: Immutable.List([
        Immutable.Map({
          id: uuid(),
          body: immutableMessage.get("body"),
        }),
      ]),
    });
  } else if (messageType && messageType in MESSAGE_RECORD_FACTORIES) {
    return MESSAGE_RECORD_FACTORIES[
      messageType as keyof typeof MESSAGE_RECORD_FACTORIES
    ]({
      ...immutableMessage.toJS(),
      id: uuid(),
    });
  } else if (MessageRecordType as typeof MessageRecordType | undefined) {
    messageRecord = (
      new MessageRecordType(
        immutableMessage,
        createMessageRecord,
        shouldTransformFromAPI,
      ) as BaseImmutableMessageRecord
    ).merge({ id: uuid() }) as MessageRecord;
  } else {
    messageRecord = new UnsupportedMessageRecord({
      id: uuid(),
      originalBlockType: messageType,
      originalMessage: immutableMessage,
    });
  }

  if (shouldTransformFromAPI) {
    messageRecord = fromAPI(messageRecord);
  }

  return messageRecord;
}

export function createEmptyMessageFromMessageType(
  messageType:
    | keyof typeof MESSAGE_RECORDS
    | keyof typeof MESSAGE_RECORD_FACTORIES,
) {
  let mt;

  if (messageType in MESSAGE_RECORD_FACTORIES) {
    mt = messageType as keyof typeof MESSAGE_RECORD_FACTORIES;

    return MESSAGE_RECORD_FACTORIES[mt]({ id: uuid() });
  }

  mt = messageType as keyof typeof MESSAGE_RECORDS;
  const MessageRecordType = MESSAGE_RECORDS[mt];
  let messageRecord = (
    new MessageRecordType({}) as BaseImmutableMessageRecord
  ).merge({ id: uuid(), isNew: true }) as MessageRecord;
  messageRecord = fromAPI(messageRecord);

  return messageRecord;
}

function createMessageRecords(
  response: ResponseRecord,
  prevResponse: ResponseRecord | undefined | null,
  pathToMessages: unknown[],
) {
  return response.updateIn(pathToMessages, (messages) =>
    messages.map((m: MessageRecord, index: number) => {
      let message = createMessageRecord(m);

      if (prevResponse) {
        // Merge in the previous message id to keep the id's stable across saves
        const prevMessage = prevResponse.getIn([...pathToMessages, index]);

        if (prevMessage) {
          message = (message as BaseImmutableMessageRecord).merge({
            id: prevMessage.id,
          }) as MessageRecord;
        }
      }

      return message;
    }),
  );
}

export function createRecordFromResponse(
  responseObject: ResponseMessageDto,
  prevResponse?: ResponseRecord | null,
  metaFields: object = {},
) {
  newResponse = new ResponseRecord(
    Immutable.fromJS({
      ...recordMerger(responseObject, new ResponseRecord()),
      reservedResponseType: responseObject._type,
      parentId: responseObject.parent_id,
    }),
  );
  newResponse = newResponse.merge(metaFields);

  [MESSAGING_MODALITY, VOICE_MODALITY].forEach((modality: Modality) => {
    const { messageField } = MODALITY_DETAILS[modality];
    newResponse
      .get(messageField)
      .keySeq()
      .toArray()
      .forEach((key) => {
        newResponse = createMessageRecords(newResponse, prevResponse, [
          messageField,
          key,
        ]);
      });
  });

  return newResponse;
}

export function validateResponse(
  responseIndex: number,
  state: Immutable.List<ResponseRecord>,
) {
  const response = state.getIn([responseIndex]);
  const languagesArray = response.messages.keySeq().toArray();

  /** Return invalid if no languages */
  if (!languagesArray.length) {
    return false;
  }

  if (!response.handle) {
    return false;
  }

  return languagesArray.every((key: number) => {
    const messages = response.getIn(["messages", key]);

    return messages.every(
      (m: BaseMessageRecordInstance) => !m.invalidFields.size,
    );
  });
}

const takeDirtyFields = (
  newResponses: Immutable.List<ResponseRecord>,
  lastSavedResponses: Immutable.List<ResponseRecord>,
  fieldNames = [
    "handle",
    "description",
    "reviewable",
    "clarifications",
    "buttonLabel",
    "tags",
    "messages",
  ],
) =>
  newResponses.map((response) => {
    const resp = response.get("isDirty")
      ? response
      : lastSavedResponses.find((r) => r.id === response.id);
    const dirtyFields = {} as ResponseRecord;
    fieldNames.forEach((fieldName) => {
      dirtyFields[fieldName] = resp
        ? resp[fieldName]
        : Immutable.List<ResponseRecord>();
    });

    return dirtyFields;
  });

let lastSavedResponses = Immutable.List();
let lastSavedResponseIndex: number;

export function checkIfDirty(
  responseIndex: number,
  currentResponsesState: Immutable.List<ResponseRecord>,
) {
  const currentResponseState = currentResponsesState
    .getIn([responseIndex])
    .toJS();

  const lastResponseState = lastSavedResponses
    .getIn([lastSavedResponseIndex])
    .toJS();

  const fieldsToIgnore = [
    "isDirty",
    "isValid",
    "live",
    "quickReplyReference",
    "fallbackReference",
  ];

  // Delete fields we don't want to compare. This is kinda hacky though, eventually
  // Response metadata should be kept separate from Response record
  fieldsToIgnore.forEach((field) => {
    delete currentResponseState[field];
    delete lastResponseState[field];
  });

  return !isEqual(currentResponseState, lastResponseState);
}

export function stateWithMetaData(
  responseIndex: number,
  state: Immutable.List<ResponseRecord>,
) {
  return state.mergeIn([responseIndex], {
    isDirty: checkIfDirty(responseIndex, state),
    isValid: validateResponse(responseIndex, state),
  });
}

/**
 * Note: In each of the cases, we return the original state if we can't find the response
 * index. This reducer will be overhauled, so in the meantime, we need to confirm.
 */

export const responses = (
  state = Immutable.List<ResponseRecord>(),
  action: AnyAction,
) => {
  let responseIndex;
  let nextState = state;

  switch (action.type) {
    case "GET_RESPONSES_SHALLOW_SUCCESS":

    // eslint-disable-next-line no-fallthrough
    case "GET_RESPONSES_SUCCESS": {
      prevState = state;
      const returnedResponses: ResponseMessageDto[] =
        action.response.data.responses;
      nextState = nextState.filter((response) => response.new);
      returnedResponses.forEach((response, index) => {
        newResponse = createRecordFromResponse(
          response,
          prevState?.get(index),
          {
            isDirty:
              !prevState?.isEmpty() && prevState?.getIn([index, "isDirty"]),
            isValid: prevState?.getIn([index, "isValid"]),
            live: response.live,
          },
        );
        nextState = nextState.push(newResponse);
      });

      lastSavedResponses = nextState;

      // merge in unsaved responses
      if (nextState.size === prevState.size) {
        nextState = nextState.mergeWith(
          (response, dirtyFields) => response.merge(dirtyFields),
          takeDirtyFields(prevState, lastSavedResponses, [
            "handle",
            "description",
            "reviewable",
            "clarifications",
            "buttonLabel",
            "tags",
            "messages",
          ]),
        );
      }

      return nextState;
    }

    case "DELETE_RESPONSE":
      return nextState.filter((response) => response.id !== action.responseId);

    case "CREATE_RESPONSE_SUCCESS":
      newResponse = createRecordFromResponse(action.response);
      responseIndex = getResponseIndex(nextState, newResponse.id);

      if (!responseIndex) {
        return nextState;
      }

      lastSavedResponseIndex = getResponseIndex(
        lastSavedResponses,
        newResponse.id,
      );

      nextState = nextState.unshift(newResponse);
      lastSavedResponses = lastSavedResponses.unshift(newResponse);

      nextState = nextState.filter((response) => response.id !== action.oldId);
      lastSavedResponses = lastSavedResponses.filter(
        (response) => response.id !== action.oldId,
      );

      return stateWithMetaData(responseIndex, nextState);

    case "SAVE_RESPONSE_SUCCESS":
      newResponse = createRecordFromResponse(
        action.response,
        nextState.find((response) => response.id === action.responseId),
      );
      responseIndex = getResponseIndex(nextState, newResponse.id);

      if (!responseIndex) {
        return nextState;
      }

      lastSavedResponseIndex = getResponseIndex(
        lastSavedResponses,
        newResponse.id,
      );

      nextState = nextState.mergeIn([responseIndex], newResponse);
      lastSavedResponses = lastSavedResponses.mergeIn(
        [lastSavedResponseIndex],
        newResponse,
      );
      prevState = undefined;

      return stateWithMetaData(responseIndex, nextState);

    case "SAVE_BOT_CONTENT_SUCCESS": {
      const newResponses = Object.keys(action.responses).map((respId) =>
        createRecordFromResponse(
          action.responses[respId],
          nextState.find((response) => response.id === respId),
        ),
      );

      newResponses.forEach((resp) => {
        responseIndex = getResponseIndex(nextState, resp.id);
        lastSavedResponseIndex = getResponseIndex(lastSavedResponses, resp.id);

        nextState = nextState.mergeIn([responseIndex], resp);
        lastSavedResponses = lastSavedResponses.mergeIn(
          [lastSavedResponseIndex],
          resp,
        );
      });

      prevState = undefined;

      return nextState;
    }

    case "UPDATE_RESPONSE_STATUS_SUCCESS": {
      const { liveField } =
        MODALITY_DETAILS[
          (action.modality as Modality | undefined) || MESSAGING_MODALITY
        ];
      responseIndex = getResponseIndex(nextState, action.responseId);

      if (!responseIndex) {
        return nextState;
      }

      lastSavedResponseIndex = getResponseIndex(
        lastSavedResponses,
        action.responseId,
      );
      nextState = nextState.mergeIn([responseIndex], {
        [liveField]: action.responseStatus,
      });

      return stateWithMetaData(responseIndex, nextState);
    }

    case "UPDATE_RESPONSE":
      responseIndex = getResponseIndex(nextState, action.responseId);

      if (responseIndex === -1) {
        return nextState;
      }

      lastSavedResponseIndex = getResponseIndex(
        lastSavedResponses,
        action.responseId,
      );

      nextState = nextState.mergeIn([responseIndex], action.payload);

      return stateWithMetaData(responseIndex, nextState);

    case "DELETE_RESPONSE_SUCCESS":
      return nextState.filter(
        (response) => response.id !== action.response.data.response._id,
      );

    case "DELETE_MULTIPLE_RESPONSES_SUCCESS":
      return nextState.filter(
        (response) => !action.deletedResponses.includes(response.id),
      );

    case "SET_RESPONSE_BUTTON": {
      const { responseId, buttonLabel } = action.payload;
      responseIndex = getResponseIndex(nextState, responseId);

      lastSavedResponseIndex = getResponseIndex(lastSavedResponses, responseId);

      /*
        Default clarification language to english. May want to change this to
        reflect a default language
      */
      const buttonLanguageToSet = action.payload.languageCode;

      nextState = nextState.setIn(
        [responseIndex, "buttonLabel", buttonLanguageToSet],
        buttonLabel,
      );

      return stateWithMetaData(responseIndex, nextState);
    }

    case "CREATE_TAG_SUCCESS":
      if (!action.responseRecord.get("new")) {
        responseIndex = getResponseIndex(
          nextState,
          action.responseRecord.get("id"),
        );

        lastSavedResponseIndex = getResponseIndex(
          lastSavedResponses,
          action.responseRecord.get("id"),
        );

        nextState = nextState.updateIn([responseIndex, "tags"], (arr) =>
          arr.push(action.response.data.tag._id),
        );

        return stateWithMetaData(responseIndex, nextState);
      }

      return state;

    case "UNDO_RESPONSE": {
      responseIndex = getResponseIndex(nextState, action.responseId);

      if (responseIndex === -1) {
        return nextState;
      }

      lastSavedResponseIndex = getResponseIndex(
        lastSavedResponses,
        action.responseId,
      );

      const revertedResponse = lastSavedResponses.getIn([
        lastSavedResponseIndex,
      ]);

      nextState = nextState.setIn([responseIndex], revertedResponse);

      return stateWithMetaData(responseIndex, nextState);
    }

    case "ADD_LANGUAGE_TO_RESPONSE_MESSAGES":
      responseIndex = getResponseIndex(nextState, action.responseId);

      if (!responseIndex) {
        return nextState;
      }

      lastSavedResponseIndex = getResponseIndex(
        lastSavedResponses,
        action.responseId,
      );

      nextState = nextState
        .setIn([responseIndex, "messages", action.language], action.messages)
        .setIn([responseIndex, "options", action.language], Immutable.List([]));

      return stateWithMetaData(responseIndex, nextState);

    case "DELETE_LANGUAGE": {
      const { responseId, languageCode, modality } = action;

      responseIndex = getResponseIndex(nextState, responseId);

      lastSavedResponseIndex = getResponseIndex(lastSavedResponses, responseId);

      nextState = nextState
        .deleteIn([
          responseIndex,
          modality === VOICE_MODALITY ? "messagesVoice" : "messages",
          languageCode,
        ])
        .deleteIn([responseIndex, "options", languageCode]);

      return stateWithMetaData(responseIndex, nextState);
    }

    default:
      return nextState;
  }
};
