import {
  type ActionIntegration,
  type ActionIntegrationAction,
  type ActionIntegrationBlockConfiguration,
  type ActionIntegrationInputField,
  type ActionIntegrationOutputField,
  type ActionIntegrationsState,
} from "reducers/actionIntegrations/types";

export function createActionIntegrationInputField(
  values: Partial<ActionIntegrationInputField> = {},
): ActionIntegrationInputField {
  return {
    id: null,
    display_name: "",
    description: "",
    description_link: "",
    description_link_text: "",
    placeholder: "",
    optional: true,
    validation: {},
    type: "text",
    inputs: [],
    repeatable: false,
    ...values,
  };
}

export function createActionIntegrationOutputField(
  values: Partial<ActionIntegrationOutputField> = {},
): ActionIntegrationOutputField {
  return {
    id: null,
    display_name: "",
    description: "",
    type: "string",
    ...values,
  };
}

export function createActionIntegrationBlockConfiguration(
  values: Partial<ActionIntegrationBlockConfiguration> = {},
): ActionIntegrationBlockConfiguration {
  return {
    allow_count_as_handoff: false,
    count_as_handoff_description: "",
    show_pause_checkbox: false,
    pause_description: "",
    ...values,
  };
}

export function createActionIntegrationAction(
  values: Partial<ActionIntegrationAction> = {},
): ActionIntegrationAction {
  return {
    id: null,
    display_name: "",
    description: "",
    input_schema: [],
    output_schema: [],
    visible: true,
    block_configuration: createActionIntegrationBlockConfiguration(),
    ...values,
  };
}

export function createActionIntegration(
  values: Partial<ActionIntegration> = {},
): ActionIntegration {
  return {
    id: null,
    display_name: "",
    description: "",
    icon: "",
    help_docs_url: null,
    not_configured_warning_text: null,
    configuration_schema: {},
    actions: [],
    is_configured: true,
    published: true,
    ...values,
  };
}

export function createActionIntegrationsState(
  values: Partial<ActionIntegrationsState> = {},
): ActionIntegrationsState {
  return {
    loading: false,
    loaded: false,
    actionIntegrations: [],
    ...values,
  };
}
