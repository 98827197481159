import { tokens } from "@adasupport/byron";
import styled from "styled-components";

import { colorPrimitives } from "constants/css";

export const AppContainer = styled.div<{
  productType?: "declarative" | "generative";
}>`
  width: 100vw;
  height: 100vh;
  min-width: 930px;
  overflow: visible;
  display: flex;
  flex-direction: row;
  background-color: ${({ productType }) =>
    productType === "generative"
      ? tokens.colors.background.mainInverted
      : colorPrimitives.black};

  &.no-sidebar {
    min-width: 0;
  }

  @media print {
    overflow: auto;
    height: auto;
  }
`;
