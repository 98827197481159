import Immutable from "immutable";
import { createSelector } from "reselect";

import { type State } from "reducers/types";
import { ClientLegacy } from "resourceModels";
import { keyConverter } from "services/key-converter";

/** @deprecated Use selectClient */
export const clientSelector = createSelector(
  (state: State) => state.client,
  (client) => {
    if (!client.resource) {
      return null;
    }

    const attributes = Immutable.fromJS(
      keyConverter(client.resource, "camel"),
    ).toObject();

    const changedAttributes = Immutable.fromJS(
      keyConverter(client.changedAttributes, "camel"),
    ).toObject();

    return new ClientLegacy(attributes, changedAttributes);
  },
);

export function isClientUpdatingSelector(state: State) {
  return state.client.isBeingSaved;
}
