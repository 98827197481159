import * as Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface GenerativeHandoffMessageAttributes
  extends BaseMessageRecordAttributes {
  type: "generative_handoff";
  authProvider: string;
}

export class GenerativeHandoffMessageRecord extends BaseMessageRecord<GenerativeHandoffMessageAttributes>(
  {
    type: "generative_handoff",
    authProvider: "",
  },
) {
  static getInvalidFields() {
    return Immutable.List();
  }
}
