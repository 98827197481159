import styled from "styled-components";

import { c, colorPrimitives, colorTokens, v } from "constants/css";

export const FolderSelect = styled.div`
  font-weight: ${v.mediumFontWeight};
  padding: ${v.gUnit} 0;
  .folders-select-selector {
    border-radius: 6px;
    padding: 2px;
    height: ${v.inputHeight};
    position: relative;
    border: 1px solid ${colorTokens.borderButton};
    cursor: pointer;
  }
  .folders-select-selection-search,
  .folders-select-selection-item {
    position: absolute;
    display: flex;
    align-items: center;
    right: ${v.gUnitTriple};
    top: 0;
    left: 0;
    bottom: 0;
  }
  .folders-select-arrow,
  .folders-select-clear {
    position: absolute;
    top: 25%;
    right: ${v.gUnit};
  }
  .folders-select-selection-item-content {
    margin-right: ${v.gUnit};
  }
  .folders-select-selection-search-input {
    position: relative;
    width: 100%;
    padding: 0 6px;
    line-height: ${v.gUnitTriple};
    border: none;
    background-color: transparent;
    height: ${v.inputHeight};
  }
  .folders-select-selection-placeholder {
    position: absolute;
    width: calc(100% - ${v.gUnitHalf});
  }
  .folders-select-selection-item {
    padding: 0 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: ${v.smallFontSize};
  }
  .folders-select-selection-search-mirror {
    display: none;
  }
  .folders-select-open {
    .folders-select-selector {
      padding: 0;
      border: 1px solid ${colorPrimitives.black};
      outline: 3px solid ${colorTokens.actionPrimaryFocus};
    }
  }
  .folders-select-tree-title {
    width: 100%;
  }
`;

export const DropDownContainer = styled.div<{ isOpen: boolean }>`
  padding: ${v.gUnit} 0;
  ${({ isOpen }) => !isOpen && "display: none"};
  .folders-select-tree-indent {
    display: inline-flex;
  }
  .folders-select-tree-indent-unit {
    width: ${v.gUnitDouble};
  }
  .folders-select-tree-title {
    font-size: ${v.smallFontSize};
  }
  .folders-select-tree-treenode {
    height: ${v.inputHeight};
    display: flex;
    gap: ${v.gUnit};
    align-items: center;
    opacity: 0.8;
    margin-bottom: ${v.gUnit};
  }
  .folders-select-tree-treenode-selected {
    background-color: ${c.colorAdaBrand100};
    opacity: 1;
  }
  .folders-select-tree-node-content-wrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    width: ${v.narrowContainerWidth};
    display: flex;
    align-items: center;
  }
`;

export const FolderSearchResultLabelStyle = styled.div`
  display: flex;
  div {
    flex: 1;
  }
`;
