/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import colors from "stylesheets/utilities/colors.scss";
import "./style.scss";

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
interface QuickReplyLabelInputProps {
  handleUpdateButtonLabel(label: string, languageCode: string): any;
  handleDeleteButtonLabel(languageCode: string): any;
  value?: string;
  languageCode: string;
  responseHandle: string;
  shouldShowMultipleLanguages: boolean;
  placeholder?: string;
  clientLanguageCode: string;
}

export const QuickReplyLabelInput = (props: QuickReplyLabelInputProps) => {
  const {
    value = "",
    languageCode,
    clientLanguageCode,
    handleDeleteButtonLabel,
    handleUpdateButtonLabel,
    responseHandle,
    shouldShowMultipleLanguages,
    placeholder = "",
  } = props;

  let quickReplyAction;

  // the user can only delete language labels other than their current language
  if (languageCode !== clientLanguageCode) {
    quickReplyAction = (
      <Button
        customClassName="g-button m-glyph m-clear QuickReplyLabelInput__action__button"
        onClick={() => handleDeleteButtonLabel(languageCode)}
        title="Remove an Expression"
        icon="CircleRemove"
        fillColor={colors.colorUIBad}
        clear
      />
    );
  }

  return shouldShowMultipleLanguages ? (
    <div className="QuickReplyLabelInput">
      <div className="QuickReplyLabelInput__language-code">
        <p
          className="QuickReplyLabelInput__language-code__label"
          style={languageCode.length > 2 ? { fontSize: 8 } : {}}
        >
          {languageCode}
        </p>
      </div>
      <DebouncedInput
        id="button-label"
        placeholder={responseHandle || placeholder}
        value={value}
        onChange={(label: string) =>
          handleUpdateButtonLabel(label, languageCode)
        }
        customClassName="QuickReplyLabelInput__debounced-input"
      />
      <div className="QuickReplyLabelInput__action-container">
        {quickReplyAction}
      </div>
    </div>
  ) : (
    <DebouncedInput
      id="button-label"
      placeholder={responseHandle || placeholder}
      value={value}
      onChange={(label: string) => handleUpdateButtonLabel(label, languageCode)}
    />
  );
};
