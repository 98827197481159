// @ts-strict-ignore
import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";
import { CHARACTER_LIMIT } from "constants/messages";
import { removeTemplateVariables } from "services/strings";
import { TAG_REGEX } from "source/constants/tags";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

function validTags(string: string): boolean {
  const variableRegex = /{([\w]+)\|([^}]*?)}/g;
  const emptyString = "";

  return (
    string
      .replace(variableRegex, emptyString)
      .replace(TAG_REGEX, emptyString)
      .replace(/ /g, emptyString) !== emptyString
  );
}

interface Attributes extends BaseMessageRecordAttributes {
  type: "zendesk_agent_workspace_handoff";
  isLiveAgentHandoff: true;
  fallbackResponseId: string | null;
  acknowledgementBody: string;
  customFields: Immutable.List<Immutable.Map<string, string>>;
  departmentId: string | null;
  departmentName: string | null;
  tags: string;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export class ZendeskAgentWorkspaceHandoffMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "zendesk_agent_workspace_handoff",
    isLiveAgentHandoff: true,
    fallbackResponseId: null,
    acknowledgementBody:
      "An agent will be with you as soon as they can. You’ll be notified here when the agent responds.",
    departmentId: null,
    departmentName: null,
    customFields: Immutable.List(),
    tags: "",
    successBusinessEvent: new FireableBusinessEvent({
      value: "",
      eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
      isVariable: false,
    }),
  },
) {
  static getInvalidFields: InvalidFieldsGetter<ZendeskAgentWorkspaceHandoffMessageRecord> =
    (message) => {
      const invalidFields = new Set<string>();

      const bodyWithoutTemplateVars = removeTemplateVariables(
        message.acknowledgementBody,
      );

      if (
        message.acknowledgementBody.trim().length <= 0 ||
        bodyWithoutTemplateVars.trim().length > CHARACTER_LIMIT
      ) {
        invalidFields.add("acknowledgementBody");
      }

      if (!message.acknowledgementBody) {
        invalidFields.add("acknowledgementBody");
      }

      if (!message.fallbackResponseId) {
        invalidFields.add("fallbackResponseId");
      }

      if (!message.departmentId) {
        invalidFields.add("departmentId");
      }

      if (message.tags && validTags(message.tags)) {
        invalidFields.add("tags");
      }

      return Immutable.List(invalidFields);
    };
}
