import {
  type DateTimeTriggerCondition,
  type TriggerCondition,
  type TriggerConditionDto,
  type UrlMatchTrigger,
} from "reducers/common/types";

export function deserializeTriggerConditions(
  triggers: TriggerConditionDto[],
): TriggerCondition[] {
  return triggers.map((trigger) => {
    switch (trigger.type) {
      case "url-match":
        return {
          type: trigger.type,
          conditionsAny: trigger.conditions_any,
        } as UrlMatchTrigger;
      case "datetime":
        return {
          type: trigger.type,
          timezone: trigger.timezone,
          condition: trigger.condition,
        } as DateTimeTriggerCondition;
      default:
        return trigger as TriggerCondition;
    }
  });
}

export function serializeTriggerConditions(
  triggers: TriggerCondition[],
): TriggerConditionDto[] {
  return triggers.map((trigger) => {
    if (trigger.type === "url-match") {
      return {
        type: trigger.type,
        conditions_any: trigger.conditionsAny,
      };
    }

    return trigger;
  });
}
