import Immutable from "immutable";

export default (state = Immutable.Map({}), action) => {
  switch (action.type) {
    case "GET_AUTHS_REQUEST":
      return state;

    case "GET_AUTHS_SUCCESS":
      return Immutable.Map(action.response.data.auths);

    default:
      return state;
  }
};
