import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "handoff";
  handoffEmail: string;
}

export class HandoffMessageRecord extends BaseMessageRecord<Attributes>({
  type: "handoff",
  handoffEmail: "",
}) {
  static getInvalidFields = (): Immutable.List<string> => Immutable.List();
}
