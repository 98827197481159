import * as Immutable from "immutable";

import RecordClassCreator from "services/record";

export class ChatterNew extends RecordClassCreator<{
  type: "chatterNew";
}>({
  type: "chatterNew",
  agents: Immutable.List(),
  created: null,
  csatScore: Immutable.Map(),
  email: null,
  lastInteraction: null,
  online: null,
  responses: Immutable.List(),
  state: null,
  storage: Immutable.Map(),
  token: null,
  updated: null,
  lastConversationLastUpdated: null,
  latestCsat: null,
  conversationId: null,
}) {
  static getFetchOneUrl(id: string) {
    return `/chatters/${id}/`;
  }

  static getFetchManyUrl() {
    return "/chatters/search";
  }

  static getFetchManyUrlParams(params: {
    filter: {
      online: boolean;
      state: string;
      last_sort: string;
    };
    page: {
      page: number;
      size: number;
    };
    sort: string;
    last_sort: string;
  }) {
    const { last_sort: lastSort } = params;

    return {
      ...params.filter,
      page: params.page.page,
      size: params.page.size,
      sort: params.sort,
      ...(lastSort && { last_sort: lastSort }),
    };
  }

  static resolveFetchOneResult(payload: { chatter: unknown }) {
    return {
      data: payload.chatter,
    };
  }

  static resolveFetchManyResult(payload: {
    results: {
      chatters: unknown;
    };
  }) {
    return {
      data: payload.results.chatters,
      payload,
    };
  }
}
