import { Button } from "@adasupport/byron";
import React, { useEffect, useState } from "react";

import { InputText } from "components/Common";
import * as Modal from "components/Shared/Pages/Settings/Modals/styles";
import { useCloseSmartSearchIntegrationsModal } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/closeModalHooks";
import { useSmartSearchIntegration } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/useSmartSearchIntegration";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { HUBSPOT_EVENTS, useHubspotEvents } from "hooks/useHubspotEvents";
import { useClient } from "services/client";
import { useGetKBSettingsQuery } from "slices/knowledge/knowledgeApiSlice";

import { ZendeskAuthError } from "./ZendeskAuthError";
import * as S from "./styles";

export function ConnectionGuide() {
  const { client } = useClient();
  const { data: kbSettings, refetch: settingsRefetch } =
    useGetKBSettingsQuery();
  const {
    integrationData,
    integrationPosition,
    handleSave,
    handleDisconnect,
    isConnecting,
  } = useSmartSearchIntegration("KBIntegrationZendesk");
  const closeModal = useCloseSmartSearchIntegrationsModal();
  const { sendHubspotEvent } = useHubspotEvents();
  const [subdomain, setSubdomain] = useState<string>("");
  const [isInputInvalid, setIsInputInvalid] = useState(false);

  const BLOCK_NAME = "SettingsSmartSearchModal";

  const zendeskAuthError = kbSettings?.syncErrorMessage?.match(
    /api was unauthorized.*zendesk/,
  );
  const connectSubdomainDisabled = integrationPosition > -1;

  useEffect(() => {
    if (integrationData) {
      setSubdomain(integrationData.subdomain);
    }
  }, [integrationData]);

  useComponentDidMount(() => {
    settingsRefetch();
  });

  const handleSaveButtonClick = async () => {
    if (connectSubdomainDisabled) {
      handleDisconnect();
    } else {
      await handleSave("zendesk", {
        client_id: client.id,
        subdomain,
      });
      sendHubspotEvent(HUBSPOT_EVENTS.ADD_KNOWLEDGE);
    }
  };

  const renderConnectGuide = () => (
    <>
      <h5 className="Modal__title">Connect Zendesk Guide</h5>
      {zendeskAuthError && <ZendeskAuthError />}
      <Modal.ModalBody className={`${BLOCK_NAME}-row`}>
        <S.ModalInput label="Zendesk Subdomain">
          <InputText
            value={subdomain}
            onChange={(value: string) => {
              setIsInputInvalid(value.length === 0);
              setSubdomain(value);
            }}
            placeholder="abccompany"
            disabled={connectSubdomainDisabled}
            invalid={isInputInvalid}
            invalidMessage='Please enter a valid subdomain. The subdomain is the "abccompany" in abccompany.zendesk.com'
            helperMessage='The subdomain is the "abccompany" in abccompany.zendesk.com'
          />
        </S.ModalInput>
      </Modal.ModalBody>
    </>
  );

  function renderConnectionButton() {
    return (
      <Button
        text={connectSubdomainDisabled ? "Disconnect Zendesk Guide" : "Connect"}
        onClick={handleSaveButtonClick}
        isDisabled={
          (!connectSubdomainDisabled && isInputInvalid) || isConnecting
        }
        variant={connectSubdomainDisabled ? "secondary" : "primary"}
        dataTestId={`knowledge-zendesk-${
          connectSubdomainDisabled ? "disconnect" : "connect"
        }`}
      />
    );
  }

  return (
    <Modal.Modal>
      {renderConnectGuide()}
      <Modal.ModalFooter className={`${BLOCK_NAME}__bottom `}>
        {renderConnectionButton()}
        {!connectSubdomainDisabled && (
          <Button
            text="Cancel"
            variant="secondary"
            onClick={closeModal}
            dataTestId="knowledge-zendesk-connect-cancel"
          />
        )}
      </Modal.ModalFooter>
    </Modal.Modal>
  );
}
