import styled from "styled-components";

import { v } from "constants/css";

export const ConfirmDeletion = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${v.gUnit};

  span {
    margin-left: ${v.gUnit};
    font-size: ${v.gUnitDouble};
  }
`;
export const HasReferencesMessageStyled = styled.p`
  text-align: center;
`;
