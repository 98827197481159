import { adaAPI } from "services/api";

import { type ApiKeyAction } from "./reducer";
import { type ApiKey } from "./types";

const END_USERS_API_KEY_PERMISSIONS = ["end_users_api"];

export async function fetchApiKeyInfo(dispatch: (a: ApiKeyAction) => void) {
  dispatch({ type: "GET_KEY_REQUEST" });

  try {
    const response = await adaAPI.request({
      method: "GET",
      url: "/api_keys",
      params: { permissions: END_USERS_API_KEY_PERMISSIONS },
    });
    const apiKeys = response.data.api_keys as ApiKey[];
    const endUserApiKey = apiKeys.find((key) => key.name === "end_user_api");
    let metadata = null;

    if (endUserApiKey) {
      metadata = {
        createdAt: endUserApiKey.created_at,
        expireAt: endUserApiKey.expire_at,
      };
    }

    dispatch({ type: "GET_KEY_SUCCESS", metadata });
  } catch (e: unknown) {
    dispatch({ type: "GET_KEY_FAILURE" });
  }
}

export async function generateApiKey(dispatch: (a: ApiKeyAction) => void) {
  dispatch({ type: "CREATE_KEY_REQUEST" });

  try {
    const response = await adaAPI.request({
      method: "POST",
      url: "/api_keys/",
      data: {
        name: "end_user_api",
        permissions: END_USERS_API_KEY_PERMISSIONS,
        replace_existing: true,
      },
    });
    const apiKey = response.data.token as string;
    const metadata = {
      createdAt: response.data.created_at as string,
      expireAt: response.data.expire_at as string,
    };
    dispatch({ type: "CREATE_KEY_SUCCESS", apiKey, metadata });
  } catch (e: unknown) {
    console.warn(e);
    dispatch({ type: "CREATE_KEY_FAILURE" });
  }
}
