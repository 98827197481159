import { CounterBadge, Icon } from "@adasupport/byron";
import React from "react";
import { useDispatch } from "react-redux";

import * as S from "./styles";

interface Props {
  variableCount: number;
}

export const Collapsed = ({ variableCount }: Props) => {
  const dispatch = useDispatch();

  return (
    <S.Bubble
      onClick={() => {
        dispatch({
          type: "SET_TEST_VARIABLES_MODAL_STATE",
          payload: {
            isVariablePanelCollapsed: false,
          },
        });
      }}
    >
      <Icon.User />
      <S.CounterContainer>
        <CounterBadge number={variableCount} />
      </S.CounterContainer>
    </S.Bubble>
  );
};
