import { useClient } from "services/client";
import { type TrainedExpression } from "slices/training/types";

export function useGetTrainingText(
  trainedExpression: TrainedExpression,
): string {
  const { client } = useClient();

  if (trainedExpression.language === client.language) {
    return trainedExpression.body;
  }

  return trainedExpression.preTranslationBody || trainedExpression.body;
}
