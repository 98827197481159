export const LOCAL_VARIABLE_SCOPE = "local";
export const GLOBAL_VARIABLE_SCOPE = "global";
export const META_VARIABLE_SCOPE = "meta";
export const OAUTH_VARIABLE_SCOPE = "oauth";
export const HEADERS_VARIABLE_SCOPE = "headers";
export const SENSITIVE_VARIABLE_SCOPE = "sensitive";
export const SENSITIVE_META_VARIABLE_SCOPE = "sensitive_meta";
export const BUILDER_AB_TESTS_VARIABLE_SCOPE = "builder_ab_tests";
export const CLIENT_SECRET_VARIABLE_SCOPE = "client_secret";
export const AUTO_CAPTURE_VARIABLE_SCOPE = "auto_capture";

export type VariableScope =
  | typeof LOCAL_VARIABLE_SCOPE
  | typeof GLOBAL_VARIABLE_SCOPE
  | typeof META_VARIABLE_SCOPE
  | typeof OAUTH_VARIABLE_SCOPE
  | typeof HEADERS_VARIABLE_SCOPE
  | typeof SENSITIVE_VARIABLE_SCOPE
  | typeof SENSITIVE_META_VARIABLE_SCOPE
  | typeof BUILDER_AB_TESTS_VARIABLE_SCOPE
  | typeof CLIENT_SECRET_VARIABLE_SCOPE
  | typeof AUTO_CAPTURE_VARIABLE_SCOPE;

export type VariableType = "string" | "long" | "bool" | "list";

export const ALL_SCOPES = [
  LOCAL_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  META_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
  HEADERS_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
  BUILDER_AB_TESTS_VARIABLE_SCOPE,
  CLIENT_SECRET_VARIABLE_SCOPE,
  AUTO_CAPTURE_VARIABLE_SCOPE,
] as const;

export const SCOPES_AVAILABLE_IN_ALL_ANSWERS = [
  GLOBAL_VARIABLE_SCOPE,
  META_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
  HEADERS_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
  BUILDER_AB_TESTS_VARIABLE_SCOPE,
  CLIENT_SECRET_VARIABLE_SCOPE,
  AUTO_CAPTURE_VARIABLE_SCOPE,
];

export const NON_STANDARD_SCOPES = [
  META_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
  HEADERS_VARIABLE_SCOPE,
  CLIENT_SECRET_VARIABLE_SCOPE,
  AUTO_CAPTURE_VARIABLE_SCOPE,
] as const;

export const DELETABLE_VARIABLE_SCOPES = [
  LOCAL_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
  // You can create/attach oauth/chatter variables in V1
  OAUTH_VARIABLE_SCOPE,
  BUILDER_AB_TESTS_VARIABLE_SCOPE,
  AUTO_CAPTURE_VARIABLE_SCOPE,
];

// The scopes that should show in capture/request variable dropdowns
export const VARIABLE_SCOPES_IN_DROPDOWN = [
  LOCAL_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
];

// The scopes that should show in set variable block target variable dropdowns
export const VARIABLE_SCOPES_IN_DROPDOWN_SHOW_AUTH = [
  LOCAL_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
];

// The scopes that should show in conditionals/segmentation variable dropdowns
export const VARIABLE_SCOPES_IN_CONDITIONALS_DROPDOWN = [
  LOCAL_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  HEADERS_VARIABLE_SCOPE,
  META_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
];

// The scopes that should show in email selector variable dropdowns

// The scopes that should show in content variable selector dropdowns for the set variable block
export const VARIABLE_SCOPES_IN_CONTENT_SELECTOR_DROPDOWN = [
  LOCAL_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  META_VARIABLE_SCOPE,
];

// The scopes that should have the "always ask" option
export const VARIABLE_SCOPES_ALWAYS_ASK_OPTION = [
  GLOBAL_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
];

// The scopes that should have the dropdown arrow to open the edit menu
export const VARIABLE_SCOPES_EDITABLE = [
  LOCAL_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  SENSITIVE_VARIABLE_SCOPE,
  SENSITIVE_META_VARIABLE_SCOPE,
];
