import { CSATSatisfactionStyle } from "services/csat";

import {
  type ConversationsTopic,
  type ConversationsTopicCsatComment,
  type ConversationsTopicUnansweredQuestion,
  type ConversationsTopicsState,
  type LoadableDataAttribute,
  PredicateValueType,
} from "./types";

const DEFAULT_CONVERSATIONS_TOPIC: ConversationsTopic = {
  id: "",
  name: "",
  description: "",
  logicalConnective: PredicateValueType.ANY,
  filters: [],
  keywords: [],
  excludeKeywords: [],
  lastUpdatedBy: "",
  lastUpdated: new Date(),
  created: new Date(),
};

const DEFAULT_CONVERSATIONS_TOPIC_CSAT_COMMENT: ConversationsTopicCsatComment =
  {
    chatterId: "",
    chatterName: "",
    comment: "",
    score: 0,
    style: CSATSatisfactionStyle.EMOJI,
    conversationId: "",
    date: 0,
  };

const DEFAULT_CONVERSATIONS_TOPIC_UNANSWERED_QUESTION: ConversationsTopicUnansweredQuestion =
  {
    id: "",
    question: "",
    conversationId: "",
    chatterId: "",
    date: 0,
    predictionIds: [],
  };

const DEFAULT_LOADABLE: LoadableDataAttribute<never> = {
  loading: false,
  loaded: false,
  page: 1,
  data: [],
};

export const createConversationsTopic = (
  attributes?: Partial<ConversationsTopic>,
) => ({
  ...DEFAULT_CONVERSATIONS_TOPIC,
  ...attributes,
});

export const createConversationsTopicCsatComment = (
  attributes?: Partial<ConversationsTopicCsatComment>,
) => ({
  ...DEFAULT_CONVERSATIONS_TOPIC_CSAT_COMMENT,
  ...attributes,
});

export const createConversationsTopicUnansweredQuestion = (
  attributes?: Partial<ConversationsTopicUnansweredQuestion>,
) => ({
  ...DEFAULT_CONVERSATIONS_TOPIC_UNANSWERED_QUESTION,
  ...attributes,
});

export const createLoadable = <T>(
  attributes?: Partial<LoadableDataAttribute<T>>,
) => ({
  ...DEFAULT_LOADABLE,
  ...attributes,
});

const DEFAULT_CONVERSATIONS_TOPIC_STATE: ConversationsTopicsState = {
  loading: false,
  loaded: false,
  conversationsTopics: [],
  draftConversationsTopic: createConversationsTopic(),
  isLoadingConversationsTopic: false,
  activeConversationsTopic: createConversationsTopic(),
  conversationsTopicCsatComments:
    createLoadable<ConversationsTopicCsatComment>(),
  conversationsTopicUnansweredQuestions:
    createLoadable<ConversationsTopicUnansweredQuestion>(),
};

export const createConversationsTopicsDefaultState = (
  attributes?: Partial<ConversationsTopicsState>,
) =>
  ({
    ...DEFAULT_CONVERSATIONS_TOPIC_STATE,
    ...attributes,
  } as ConversationsTopicsState);
