import { tokens } from "@adasupport/byron";
import styled, { css } from "styled-components";

import { v } from "constants/css";

export const Label = styled.span<{ hasTooltip: boolean }>`
  color: ${tokens.colors.text.main};
  font-size: ${v.smallFontSize};
  font-weight: 600;
  padding: 0 0 6px 0;
  display: block;
  ${(p) =>
    p.hasTooltip &&
    css`
      display: inline-flex;
      align-items: center;
      padding: 0 0 2px 0;
    `}
`;

const InputLabel = styled.label<{ inline: boolean; stretch: boolean }>`
  ${(p) =>
    p.inline &&
    css`
      display: flex;

      ${Label} {
        padding-right: 12px;
      }

      ${p.stretch &&
      css`
        justify-content: space-between;
      `}
    `}
`;
export const Root = InputLabel;
