import Immutable from "immutable";

import {
  type dismissWindowAlertAction,
  type generateWindowAlertAction,
} from "actions/windowAlert";

const defaultState = Immutable.Map({
  isVisible: false,
  title: "",
  content: "",
  image: "",
  type: null,
  clients: [],
  actionRequired: false,
});

type WindowAlertState = typeof defaultState;

type WindowAlertAction =
  | ReturnType<typeof generateWindowAlertAction>
  | ReturnType<typeof dismissWindowAlertAction>;

export const windowAlert = (
  state = defaultState,
  action: WindowAlertAction,
): WindowAlertState => {
  switch (action.type) {
    case "GENERATE_WINDOW_ALERT": {
      /*
            Check if the clients list on the event is non-empty
            which indicates we need to only show this alert to the
            included clients
            */
      const allowed = action.clients;
      const client = action.currentClient;

      if (allowed.length > 0 && client) {
        if (!allowed.includes(client.handle) && !allowed.includes(client.id)) {
          return state;
        }
      }

      return state.merge({
        isVisible: true,
        title: action.title,
        content: action.content,
        image: action.image,
        actionRequired: action.actionRequired,
        type: action.windowAlertType || "basic",
      });
    }

    case "DISMISS_WINDOW_ALERT":
      return state.merge({ isVisible: false });
    default:
      return state;
  }
};
