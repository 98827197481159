// @ts-strict-ignore
import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "nuance_secure_live_agent";
  fallbackResponseId: string | null;
  businessUnitId: string | null;
  agentGroupId: string | null;
  secureEnabled: boolean;
  securePrompt: string;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export class SecureNuanceLiveAgentMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "nuance_secure_live_agent",
    fallbackResponseId: null,
    businessUnitId: null,
    agentGroupId: null,
    secureEnabled: false,
    securePrompt: "",
    successBusinessEvent: new FireableBusinessEvent({
      value: "",
      eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
      isVariable: false,
    }),
  },
) {
  static getInvalidFields: InvalidFieldsGetter = () => Immutable.List();
}
