import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "unsupported";
  originalBlockType: string;
  originalMessage: Immutable.Map<string, unknown>;
}

export class UnsupportedMessageRecord extends BaseMessageRecord<Attributes>({
  type: "unsupported",
  originalBlockType: "unknown",
  originalMessage: Immutable.Map(),
}) {
  static getInvalidFields = (): Immutable.List<string> => Immutable.List();

  /**
   * Custom JSON serialization so that we get the original message
   * when we're copying / pasting
   */
  toJSON(): Attributes {
    return this.originalMessage.toJSON() as unknown as Attributes;
  }
}
