import { bindActionCreators } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";

import { refreshClient } from "actions";
import { createAlert as createAlertAction } from "actions/alerts";
import { closeModalAction } from "actions/modal";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { InputPassword } from "components/Common/InputPassword";
import { Loading } from "components/Common/Loading";
import { adaAPI } from "services/api";

import "./style.scss";

const BLOCK_NAME = "SettingsPlatformsHandoffsModal";

const SettingsPlatformsHandoffsModal = ({
  integrationName,
  displayName,
  configurationSchema,
  helpDocsUrl = "",
  closeModal,
  createAlert,
}) => {
  const [credentialsLoaded, setCredentialsLoaded] = useState(false);
  const [oldCredentials, setOldCredentials] = useState({});
  const [credentials, setCredentials] = useState({});

  const fetchCurrentCredentials = () => {
    adaAPI
      .request({
        method: "GET",
        url: `/integrations/${integrationName}/credentials`,
      })
      .then((response) => {
        setCredentialsLoaded(true);
        setOldCredentials(response.data.credentials);
        setCredentials(response.data.credentials);
      })
      .catch(() => {
        // If an error occurs while loading existing credentials, we'll just show blank credentials
        setCredentialsLoaded(true);
      });
  };

  useEffect(() => {
    fetchCurrentCredentials();
  }, []);

  /**
   * Update component state with new field values when input value changes
   * @param {string} fieldId
   * @param  {string} newValue
   */
  const updateCredentialsField = (fieldId, newValue) => {
    setCredentials({
      ...credentials,
      [fieldId]: newValue,
    });
  };

  /**
   * Validate credentials to the integrations machine via API
   */
  const validateCredentials = async () => {
    const res = await adaAPI
      .request({
        method: "POST",
        url: `/integrations/${integrationName}/credentials/validate`,
        data: { ...credentials },
      })
      .catch(() => {
        createAlert({
          message: "Failed to validate credentials",
          alertType: "error",
        });
      });

    return res?.status === 200;
  };

  /**
   * Save credentials to the integrations machine via API
   */
  const saveCredentials = async () => {
    const hasVerificationUrl = !!configurationSchema?.verification_url;
    const isValid =
      configurationSchema?.verification_url && (await validateCredentials());

    if (isValid || !hasVerificationUrl) {
      adaAPI
        .request({
          method: "PUT",
          url: `/integrations/${integrationName}/credentials`,
          data: { ...credentials },
        })
        .then(() => {
          createAlert({
            message: "Credentials saved successfully",
            alertType: "success",
          });
          closeModal();
          window.location.reload(true);
        })
        .catch(() => {
          createAlert({
            message: "Failed to save credentials",
            alertType: "error",
          });
        });
    }
  };

  /**
   * Returns false if the field is valid
   * Returns a string explaining why the field failed validation if the field is invalid
   * @param {object} field
   */
  const isInvalidReason = (field) => {
    const { id, validation, optional } = field;
    const value = credentials[id] || "";

    if (!optional && value.length === 0) {
      return "This field is required";
    }

    if (!validation) {
      return false;
    }

    const { minLength, maxLength, format } = validation;

    if (minLength && value.length < minLength) {
      return `Must be at least ${minLength} characters`;
    }

    if (maxLength && value.length > maxLength) {
      return `Must be at most ${maxLength} characters`;
    }

    if (format === "email" && !isEmail(value)) {
      return "Must be a valid email";
    }

    if (format === "url" && !isURL(value)) {
      return "Must be a valid URL";
    }

    return false;
  };

  /**
   * Returns true if any fields is invalid, and false if all fields are valid
   */
  const hasAnyInvalidField = () =>
    configurationSchema.fields.some((field) => isInvalidReason(field));

  /**
   * Render a single field based on the specification in the schema
   * @param {object} field
   * @param {number} index
   * @returns {*}
   */
  const renderField = (field, index) => {
    const invalidReason = isInvalidReason(field);
    const isInvalid = Boolean(invalidReason);

    return (
      <section className="input-row" key={`${BLOCK_NAME}__${index}`}>
        <div className="input-row__title__header">
          <section className="Modal__section__sub-section">
            <label
              className="Modal__section__sub-section__title g-input__label"
              htmlFor={`${BLOCK_NAME}__${index}`}
            >
              <span>{field?.display_name}</span>
            </label>
            <br />
            {field?.description && (
              <p className="Modal__section__sub-section__description">
                {field?.description}
              </p>
            )}
          </section>
        </div>
        {field?.is_visible ? (
          <DebouncedInput
            id={`${BLOCK_NAME}__${index}`}
            value={credentials[field?.id] || ""}
            placeholder={field.placeholder}
            onChange={(newValue) => updateCredentialsField(field?.id, newValue)}
            isInvalid={isInvalid}
          />
        ) : (
          <InputPassword
            inputId={`${BLOCK_NAME}__${index}`}
            value={credentials[field?.id] || ""}
            placeholder={field?.placeholder}
            onPasswordChange={(e) =>
              updateCredentialsField(field?.id, e.target.value)
            }
            invalid={isInvalid}
            toggleDisabled
          />
        )}

        {isInvalid && (
          <div className={`${BLOCK_NAME}__invalid-message`}>
            {invalidReason}
          </div>
        )}
      </section>
    );
  };

  return (
    <div className={`${BLOCK_NAME} Modal__modal`}>
      <h5 className="Modal__title">{displayName}</h5>
      <div className="Modal__content">
        {helpDocsUrl && (
          <Banner icon="QuestionCircleFilled">
            Need help? Try the following{" "}
            <a href={helpDocsUrl} target="_blank" rel="noopener noreferrer">
              Configuration Guide
            </a>
          </Banner>
        )}
        {!credentialsLoaded && <Loading />}
        {credentialsLoaded &&
          configurationSchema?.fields.map((field, index) =>
            renderField(field, index),
          )}
      </div>
      <div className={`Modal__bottom ${BLOCK_NAME}__bottom`}>
        <Button
          onClick={saveCredentials}
          text="Save"
          title="Save"
          icon="Cloud"
          disabled={
            isEqual(credentials, oldCredentials) || hasAnyInvalidField()
          }
          customClassName={`${BLOCK_NAME}__bottom__save`}
        />
      </div>
    </div>
  );
};

SettingsPlatformsHandoffsModal.propTypes = {
  integrationName: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  configurationSchema: PropTypes.shape({
    verification_url: PropTypes.string.isRequired,
    fields: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
  helpDocsUrl: PropTypes.string,

  // props from dispatch
  closeModal: PropTypes.func.isRequired,
  createAlert: PropTypes.func.isRequired,
};

function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      closeModal: closeModalAction,
      createAlert: createAlertAction,
      refreshClient,
    },
    dispatch,
  );
}

export default connect(null, mapDispatch)(SettingsPlatformsHandoffsModal);
