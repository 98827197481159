// @ts-strict-ignore
import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface AuthMessageRecordAttributes extends BaseMessageRecordAttributes {
  type: "sign_in";
  messageLead: string;
  authButtonLabel: string;
  authProvider: string;
  errorResponseId: string;
}

export class AuthMessageRecord extends BaseMessageRecord<AuthMessageRecordAttributes>(
  {
    type: "sign_in",
    messageLead: "To do this, I'll need you to sign in with the button below",
    authButtonLabel: "Sign In",
    authProvider: "",
    errorResponseId: null,
  },
) {}

/**
 * Validation
 */

AuthMessageRecord.getInvalidFields = (
  message: AuthMessageRecord,
): Immutable.List<string> => {
  const invalidFields: Set<string> = new Set();

  if (!message.messageLead) {
    invalidFields.add("messageLead");
  }

  if (!message.authButtonLabel) {
    invalidFields.add("authButtonLabel");
  }

  if (!message.authProvider) {
    invalidFields.add("authProvider");
  }

  if (!message.errorResponseId) {
    invalidFields.add("errorResponseId");
  }

  return Immutable.List(invalidFields);
};
