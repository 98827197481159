// @ts-strict-ignore
import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

interface Attributes extends BaseMessageRecordAttributes {
  type: "survey";
  messageTemplateId: string;
}

export class SatisfactionSurveyMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "survey",
    messageTemplateId: null,
  },
) {
  static getInvalidFields: InvalidFieldsGetter = () => Immutable.List();
}
