import { bindActionCreators } from "@reduxjs/toolkit";
import Immutable from "immutable";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  saveClientPlatform as saveClientPlatformAction,
  updatePlatform as updatePlatformAction,
} from "actions";
import { Button } from "components/Common/Button";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputClearable } from "components/Common/InputClearable";

import "./style.scss";

class SettingsPlatformsOracleModal extends React.Component {
  /**
   * Constructor for Live Agent Parent Card
   * @param {Object} props for React
   */
  constructor(props) {
    super(props);
    this.modalContentRef = React.createRef();
    this.agentName = "Oracle";
  }

  /**
   * Updates the the platform on the API
   * @param {Object} payload that contains platform information
   */
  updatePlatformLiveAgent(payload) {
    const { updatePlatform, platformType } = this.props;

    const newPayload = {
      platformData: payload,
    };

    updatePlatform(platformType, newPayload);
  }

  /**
   * @returns {html} renders the basic view
   */
  renderView() {
    const { platform, saveClientPlatform } = this.props;

    return (
      <div className="SettingsPlatformOracleModal Modal__modal">
        <h5 className="Modal__title">{this.agentName} Live Chat Settings</h5>
        <div className="Modal__content" ref={this.modalContentRef}>
          <section className="Modal__section">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformOracleModal__account-sid"
              >
                <div className="Modal__section__sub-section__title__header">
                  <span>Oracle Instance URL</span>
                  <InfoTooltip
                    blurb={`The fully qualified URL of your ${this.agentName} chat instance.`}
                    iconDefault="QuestionCircle"
                    iconClicked="QuestionCircleFilled"
                    absolute
                    container={this.modalContentRef.current}
                  />
                </div>
                {!this.restrictUri && (
                  <InputClearable
                    value={platform.getIn([
                      "record",
                      "platformData",
                      "oracleChatInstanceUrl",
                    ])}
                    placeholder="https://your-oracle-subdomain.oracle.com"
                    onChange={(e) =>
                      this.updatePlatformLiveAgent({
                        oracleChatInstanceUrl: e.target.value,
                      })
                    }
                    onClear={() =>
                      this.updatePlatformLiveAgent({
                        oracleChatInstanceUrl: "",
                      })
                    }
                  />
                )}
                {this.restrictUri && (
                  <InputClearable
                    icon="Copy"
                    value={platform.getIn([
                      "record",
                      "platformData",
                      "oracleChatInstanceUrl",
                    ])}
                    placeholder="Redirect URI"
                  />
                )}
              </label>
            </section>
          </section>
          <section className="Modal__section SettingsPlatformOracleModal__multi">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformOracleModal__account-sid"
              >
                <div className="Modal__section__sub-section__title__header">
                  <span>Oracle Username</span>
                  <InfoTooltip
                    blurb="The username of an administrator account on this instance."
                    iconDefault="QuestionCircle"
                    iconClicked="QuestionCircleFilled"
                    absolute
                    container={this.modalContentRef.current}
                  />
                </div>
                <InputClearable
                  value={platform.getIn([
                    "record",
                    "platformData",
                    "oracleUsername",
                  ])}
                  placeholder="Admin"
                  onChange={(e) =>
                    this.updatePlatformLiveAgent({
                      oracleUsername: e.target.value,
                    })
                  }
                  onClear={() =>
                    this.updatePlatformLiveAgent({
                      oracleUsername: "",
                    })
                  }
                />
              </label>
            </section>
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformOracleModal__secret-key"
              >
                <div className="Modal__section__sub-section__title__header">
                  <span>Oracle Password</span>
                  <InfoTooltip
                    blurb="The corresponding password associated with the administrator user."
                    iconDefault="QuestionCircle"
                    iconClicked="QuestionCircleFilled"
                    absolute
                    container={this.modalContentRef.current}
                  />
                </div>
                <InputClearable
                  value={platform.getIn([
                    "record",
                    "platformData",
                    "oraclePassword",
                  ])}
                  placeholder="gGRvsdA1ZzdfMH5Mgedhg"
                  onChange={(e) =>
                    this.updatePlatformLiveAgent({
                      oraclePassword: e.target.value,
                    })
                  }
                  onClear={() =>
                    this.updatePlatformLiveAgent({
                      oraclePassword: "",
                    })
                  }
                />
              </label>
            </section>
          </section>
        </div>

        <div className="Modal__bottom SettingsPlatformOracleModal__bottom">
          <Button
            onClick={() => saveClientPlatform(platform)}
            text="Save"
            title="Save"
            icon="Cloud"
            disabled={false}
            isLoading={platform.get("isLoading")}
            customClassName="SettingsPlatformOracleModal__bottom__save"
          />
        </div>
      </div>
    );
  }

  /**
   * Render method to create the Oracle Live Agent modal
   * @returns {html} rendered view
   */
  render() {
    return this.renderView();
  }
}

/**
 * Maps the states for redux
 * @param {Object} state current state of redux
 * @param {Object} ownProps the props
 * @returns {Object} updated state
 */
function mapState(state, ownProps) {
  const { platforms } = state;

  const platform = platforms.get(ownProps.platformType);
  const record = platform.getIn(["record"]);

  return {
    platforms,
    platform,
    record,
  };
}

/**
 * In charge of dispatching changes to the platform
 * @param {func} dispatch
 * @returns {Object} mapping of dispatch
 */
function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      saveClientPlatform: saveClientPlatformAction,
      updatePlatform: updatePlatformAction,
    },
    dispatch,
  );
}

SettingsPlatformsOracleModal.propTypes = {
  updatePlatform: PropTypes.func.isRequired,
  platformType: PropTypes.string.isRequired,
  platform: PropTypes.instanceOf(Immutable.Map).isRequired,
  saveClientPlatform: PropTypes.func.isRequired,
};

const Connector = connect(mapState, mapDispatch)(SettingsPlatformsOracleModal);

export default Connector;
