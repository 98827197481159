import Immutable from "immutable";

import RecordClassCreator from "services/record";

import { type AnalyticsAreaGraphRecordValue } from "./helpers";

export interface AnalyticsAreaGraphAttributes {
  dataType: string | null;
  valueType: string;
  value?: unknown;
  meta?: Immutable.Map<string, unknown>;
  values: Immutable.List<AnalyticsAreaGraphRecordValue>;
  binKey: string | null;
  binSize: number | null;
  tooltipLabel: string | null;
  xAxisLabel: string;
  yAxisLabel: string;
  yAxisLabelTooltip?: string;
}

export class AnalyticsAreaGraphRecord extends RecordClassCreator<AnalyticsAreaGraphAttributes>(
  {
    type: "analyticsAreaGraphRecord",
    dataType: null,
    values: Immutable.List(),
    value: null,
    meta: Immutable.Map(),
    valueType: "number",
    binKey: null,
    binSize: null,
    tooltipLabel: null,
    xAxisLabel: "",
    yAxisLabel: "",
    yAxisLabelTooltip: "",
  },
) {}
