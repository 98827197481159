import { createSlice } from "@reduxjs/toolkit";

import { type PlatformIntegrationState } from "./types";

const initialState: PlatformIntegrationState = {
  toastNotification: null,
};

const platformIntegrations = createSlice({
  name: "platformIntegrations",
  initialState,
  reducers: {
    setToastNotification(state, action) {
      return { ...state, toastNotification: action.payload };
    },
    clearToastNotification(state) {
      return { ...state, toastNotification: null };
    },
  },
});

export const { setToastNotification, clearToastNotification } =
  platformIntegrations.actions;
export const { reducer: platformIntegrationsReducer } = platformIntegrations;
