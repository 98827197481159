import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

import { cssVariables } from "constants/css";

export const Bubble = styled.div`
  position: fixed;
  right: 410px;
  bottom: calc(90vh - 32px);
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${primitives.borderRadius.full};
  z-index: ${cssVariables.zIndexModal};
  background: ${tokens.colors.background.main};

  @media (min-height: 858px) {
    bottom: 738px;
  }
`;

export const CounterContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
