/* eslint-disable @typescript-eslint/no-explicit-any */
import Immutable, { type List, type Map } from "immutable";

import { ActionIntegrationInputItemRecord } from "reducers/actionIntegrations/types";
import {
  type ActionIntegrationMessageRecord,
  type HTTPMessageRecord,
  type MessageRecord,
  type ShuffleMessageRecord,
  type WidgetMessageRecord,
} from "reducers/responses/messageRecords";
import { uuid } from "services/generate-uuid";
import {
  addSpecialKeysToObj,
  keyConverter,
  removeSpecialKeysFromObj,
} from "services/key-converter";
import { type TypedMap } from "types";

export function getHeadersList(message: HTTPMessageRecord) {
  const headers = message.get("headers") as any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative

  return headers
    .entrySeq()
    .map(([key, value]: any[]) => Immutable.Map({ key, value })) // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
    .toList();
}

/**
 * Transforms a key-value Widget inputs data from the database into a
 * list of InputItemRecords.
 */
export function getWidgetInputsDataList(message: WidgetMessageRecord) {
  const deserializeValue = (value: any): List<any> => {
    // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
    // An Immutable List represents group input
    if (Immutable.List.isList(value)) {
      return value.map((innerValue) => deserializeValue(innerValue));
    }

    // A single k-v pair in an Immutable Map represents a InputItemRecord.
    // We explode the Map to a list of Maps representing an InputItemRecord each.
    if (Immutable.Map.isMap(value)) {
      return value
        .entrySeq()
        .map(([key, val]) =>
          Immutable.Map({
            key,
            value: deserializeValue(val),
          }),
        )
        .toList();
    }

    return value;
  };

  return deserializeValue(message.get("inputsData"));
}

/**
 * Add a unique id to the shuffle sub-messages so we can use it as a React key
 *
 * @param {Object} message - shuffle message whose submessages an id will be added
 */
export function shuffleFromAPI(message: ShuffleMessageRecord) {
  return message.update("messages", (messages) =>
    messages.map((innerMessage) => innerMessage.merge({ id: uuid() })),
  );
}

export function widgetFromAPI(message: WidgetMessageRecord) {
  const inputsDataList = getWidgetInputsDataList(message);

  return message.merge({ inputsDataList });
}

// Due to the implicit snake case to camel case conversion app does
// when retrieving and saving blocks, we need to convert the action manifest
// saved on a block into snake case to be saved in the message record
export function actionIntegrationFromAPI(
  message: ActionIntegrationMessageRecord,
) {
  const savedManifest = keyConverter(
    message.get("actionManifest") as any, // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
    "underscore",
    false,
    false,
  ) as any; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative

  return message.merge({
    actionManifest: savedManifest,
    savedManifest,
  });
}

/**
 * @param {Immutable.List<unknown>} collection
 * @param {string} type
 * @returns {Immutable.List<unknown>}
 */
export function convertListToMapDeeply(collection: any, type = "dict") {
  // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  let value;

  let newCollection = collection;

  if (Immutable.isIndexed(newCollection)) {
    const pairs = newCollection.map((item) => {
      value = { type: item.get("type"), value: item.get("value") };

      if (item.get("type") === "dict" || item.get("type") === "list") {
        value = {
          type: item.get("type"),
          value: convertListToMapDeeply(item.get("value"), item.get("type")),
        };
      }

      return type === "dict" ? [item.get("key"), value] : value;
    });
    newCollection =
      type === "dict" ? Immutable.Map(pairs as any) : Immutable.List(pairs); // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  }

  newCollection =
    type === "dict"
      ? newCollection.filter((v: any, key: any) => key.length)
      : newCollection.filter(Boolean);

  return newCollection;
}

/**
 * @param {Immutable.List<unknown>} collection
 * @param {string} parent
 * @returns {Immutable.List<unknown>}
 */
export function convertMapToListDeep(collection: any, parent = "dict") {
  // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  let type;

  return collection
    .entrySeq()
    .map(([key, value]: any[]) => {
      // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
      let newValue = value;
      type = value.get("type");

      if (["list", "dict"].includes(type)) {
        newValue = newValue.set(
          "value",
          convertMapToListDeep(newValue.get("value"), type),
        );
      }

      return parent === "dict"
        ? Immutable.Map({ key, value: newValue.get("value"), type })
        : Immutable.Map({ value: newValue.get("value"), type });
    })
    .toList();
}

export function HTTPMessageFromAPI(message: HTTPMessageRecord) {
  let requestPayload = message.get("requestPayload");
  const headersList = getHeadersList(message);
  const isXML = message.get("requestPayloadType") === "data";

  if (!isXML) {
    requestPayload = (requestPayload as string).length
      ? Immutable.fromJS(
          removeSpecialKeysFromObj(JSON.parse(requestPayload as string)),
        )
      : Immutable.List();

    requestPayload = convertMapToListDeep(requestPayload);
  }

  return message.merge({
    requestPayload,
    headersList,
  });
}

export function fromAPI(message: MessageRecord) {
  let transformedMessage;

  // Note that conditional and scheduled blocks have additional transformations applied in their
  // message creators `ConditionalMessageRecordCreator` and `ScheduledBlockRecordCreator`
  switch (message.type) {
    case "http_request_recipe":
      transformedMessage = HTTPMessageFromAPI(
        message as unknown as HTTPMessageRecord,
      );
      break;
    case "shuffle":
      transformedMessage = shuffleFromAPI(
        message as unknown as ShuffleMessageRecord,
      );
      break;
    case "widget":
      transformedMessage = widgetFromAPI(
        message as unknown as WidgetMessageRecord,
      );
      break;
    case "action_integration":
      transformedMessage = actionIntegrationFromAPI(
        message as unknown as ActionIntegrationMessageRecord,
      );
      break;
    default:
      transformedMessage = message;
  }

  return transformedMessage;
}

export function transformHTTP(message: HTTPMessageRecord) {
  const isXML = message.get("requestPayloadType") === "data";
  const headerKeys = message
    .get("headersList")
    .map((item: TypedMap<{ key: string; value: string }>) => item.get("key"))
    .toList();
  const headerValues = message
    .get("headersList")
    .map((item: TypedMap<{ key: string; value: string }>) => item.get("value"))
    .toList();
  const isGetRequest = message.get("requestType") === "GET";
  const transformed = {
    requestPayload: "",
    headers: Immutable.Map(headerKeys.zip(headerValues as any)).filter(
      // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
      (value, key) => (key as string).length,
    ),
  };

  if (!isGetRequest) {
    const payload = message.get("requestPayload");
    let requestPayload;

    if (isXML) {
      requestPayload = payload;
    } else {
      const convertedPayloadMap = convertListToMapDeeply(payload);
      const withSpecialKeys = addSpecialKeysToObj(convertedPayloadMap.toJS());
      const convertedPayloadStr = JSON.stringify(withSpecialKeys);
      requestPayload = convertedPayloadStr;
    }

    Object.assign(transformed, { requestPayload });
  }

  return transformed;
}

/**
 * Transforms a widget message to a key-value object for saving to the database.
 * @param {} message
 * @returns
 */
export function transformWidget(message: WidgetMessageRecord) {
  const serializeValue = (value: any): any => {
    // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
    // TODO: During refactoring and cleanup at some point, we should
    // make sure that the inputsDataList at all levels is an Immutable type.
    // This specific conversion is done here because some values are actually
    // arrays and not Immutable.List when passed to the inputsDataList
    if (Array.isArray(value)) {
      return serializeValue(Immutable.List(value));
    }

    // An Immutable List represents a group value
    if (Immutable.List.isList(value)) {
      // If the group is repeating, then there is a 2-level List, so we want to
      // flatten the second level into a map by reducing.
      if (Immutable.List.isList(value.first())) {
        // For repeatable groups, null input values will not be filtered and gets saved
        // into the database. Non-repeating groups and non-group inputs will have
        // null inputs filtered out.
        return value.map((innerValue) => serializeValue(innerValue)).toArray();
      }

      return value
        .reduce(
          (accum, nextValue) => accum.merge(serializeValue(nextValue)),
          Immutable.Map(),
        )
        .toJS();
    }

    // Transform an InputItemRecord or a Map to its proper shape
    if (
      value instanceof ActionIntegrationInputItemRecord ||
      Immutable.Map.isMap(value)
    ) {
      if (
        (value as any).get("value") === "" || // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
        (value as any).get("value") === null ||
        typeof (value as any).get("value") === "undefined"
      ) {
        return null;
      }

      return Immutable.Map({
        [(value as any).get("key")]: serializeValue(
          (value as any).get("value"),
        ),
      });
    }

    // Everything else, like primitives, are returned directly.
    return value;
  };

  return {
    inputsData: serializeValue(message.get("inputsDataList")),
  };
}

/**
 * Transform an individual language message map before sending it to our API
 */
function messagesToAPI(
  messages: List<MessageRecord>,
): List<Map<string, unknown> | MessageRecord> {
  return messages.map((message) => {
    switch (message.type) {
      case "http_request_recipe":
        return message.merge(transformHTTP(message));
      case "widget":
        return message.merge(transformWidget(message));

      case "scheduled_block": {
        let newMessage = message;
        newMessage = newMessage.updateIn(["affirmativeMessages"], (m) =>
          messagesToAPI(m),
        );
        newMessage = newMessage.updateIn(["negativeMessages"], (m) =>
          messagesToAPI(m),
        );

        return newMessage;
      }

      case "conditionals_block": {
        let newMessage = message;

        message.get("statements").forEach((statementBlock, statementIndex) => {
          newMessage = newMessage.updateIn(
            ["statements", statementIndex, "messages"],
            (m) => messagesToAPI(m),
          );
        });

        return newMessage;
      }

      case "salesforce_live_agent": {
        let newMessage = message;
        // remove any "empty" variables from customVariableMapping before saving
        const trimmedTranscriptVariables = message
          .get("customVariableMapping")
          .filter(
            (customVariable) =>
              customVariable.get("salesforceFieldName") !== "",
          );

        newMessage = newMessage.set(
          "customVariableMapping",
          trimmedTranscriptVariables,
        );

        if (newMessage.get("enableCase")) {
          // remove any "empty" variables from caseCustomVariableMapping
          const trimmedCaseVariables = newMessage
            .get("caseCustomVariableMapping")
            .filter(
              (customVariable) =>
                customVariable.get("salesforceFieldName") !== "",
            );

          newMessage = newMessage.set(
            "caseCustomVariableMapping",
            trimmedCaseVariables,
          );
        } else {
          // If enableCase is false, set caseCustomVariableMapping to an empty array
          // (can't set fields on a case if we're not creating one)
          newMessage = newMessage.set("caseCustomVariableMapping", [] as any);
        }

        return newMessage;
      }

      case "unsupported":
        return message.originalMessage;

      default:
        return message;
    }
  });
}

/**
 * Transform multi-language messages map before sending it to our API
 *
 * @param {Immutable.Map} messages - {"en": Immutable.Map}
 * @returns {Immutable.Map} transformed messages
 */
export function toAPI(messages: Map<string, List<MessageRecord>>) {
  return messages.map(messagesToAPI);
}
