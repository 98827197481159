import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { saveClientPlatform, updatePlatform } from "actions";
import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";

const COMPONENT_NAME = "SettingsPlatformsHttpModal";

export function SettingsPlatformsHttpModal() {
  const platformType = "http";
  const platforms = useSelector((s) => s.platforms);
  const platform = platforms.get(platformType);
  const dispatch = useDispatch();

  if (!platform) {
    return null;
  }

  const handleInputChange = (name: string, value: string) => {
    dispatch(updatePlatform(platformType, { [name]: value }));
  };

  const handleSave = () => {
    dispatch(saveClientPlatform(platform));
  };

  const isSaveDisabled =
    (platform.get("isPublished") && !platform.get("isDirty")) ||
    !platform.get("isValid");

  return (
    <div className={`${COMPONENT_NAME} Modal__modal Modal__modal--small`}>
      <h5 className="Modal__title">Configure Webhook</h5>
      <div className="Modal__content">
        <section className="Modal__section">
          <section className="Modal__section__sub-section">
            <DebouncedInput
              label="Webhook URL"
              id={`${COMPONENT_NAME}__webhook`}
              value={platform.getIn(["record", "webhook"])}
              onChange={(v: string) => handleInputChange("webhook", v)}
            />
          </section>
        </section>
        <section className="Modal__section">
          <section className="Modal__section__sub-section">
            <DebouncedInput
              label="Authorization Key"
              id={`${COMPONENT_NAME}__auth_key`}
              value={platform.getIn(["record", "authKey"])}
              onChange={(v: string) => handleInputChange("authKey", v)}
            />
          </section>
        </section>
      </div>
      <div className="Modal__bottom">
        <Button
          onClick={handleSave}
          text="Save"
          title="Save"
          icon="Cloud"
          disabled={isSaveDisabled}
          isLoading={platform.get("isLoading")}
        />
      </div>
    </div>
  );
}
