import { createSelector } from "reselect";

import { type Modality } from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type State } from "types";

import { selectActiveResponse } from "./selectActiveResponse";

type SelectorResponse = (state: State) => Modality | null | undefined;

export const selectModality: SelectorResponse = createSelector(
  selectActiveResponse,
  (state) => state.responsesPage.responseEditorActiveModalities,
  (response, activeModalityMap) => {
    if (response && response.id) {
      const { id, reservedFor } = response;

      return reservedFor || activeModalityMap[id];
    }

    return null;
  },
);
