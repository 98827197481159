import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteTagAction, fetchTagsAction, updateTagAction } from "actions";

export function useTags() {
  const dispatch = useDispatch();

  return {
    allTags: useSelector((state) => state.tags),
    fetchTags: useCallback(() => dispatch(fetchTagsAction()), [dispatch]),
    updateTag: useCallback(
      (...args: Parameters<typeof updateTagAction>) => {
        dispatch(updateTagAction(...args));
      },
      [dispatch],
    ),
    deleteTag: useCallback(
      (...args: Parameters<typeof deleteTagAction>) => {
        dispatch(deleteTagAction(...args));
      },
      [dispatch],
    ),
  };
}
