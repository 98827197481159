import React from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";
import * as Modal from "components/Shared/Pages/Settings/Modals/styles";
import { colorPrimitives } from "constants/css";

import {
  ResolutionModalDetails,
  ResolutionModalSuccessRate,
  ResolutionModalSuccessText,
  ResolutionModalTitle,
} from "./styles";

export const ConversationsResolutionsSuccessModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal.Modal>
      <Modal.ModalHeader>
        <ResolutionModalTitle>
          You annotated an entire set of interactions
        </ResolutionModalTitle>
        <ResolutionModalSuccessRate>
          <SvgIcon
            icon="LightBulb"
            height={40}
            fillColor={colorPrimitives.green500}
          />
          <ResolutionModalSuccessText>
            Your resolution rate is 45%
          </ResolutionModalSuccessText>
        </ResolutionModalSuccessRate>
        <ResolutionModalDetails>
          Annotating one set per day is a great way to keep training your bot to
          understand what Answers will help the most customers. Of course, you
          can always do more!
        </ResolutionModalDetails>
      </Modal.ModalHeader>
      <Modal.ModalFooter>
        <Button
          onClick={() => dispatch(closeModalAction())}
          text="I'm done"
          title="I'm done"
          light
        />
        <Button
          onClick={() => dispatch(closeModalAction())}
          text="Annotate another set"
          title="Annotate another set"
          icon="MessageVerify"
        />
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};
