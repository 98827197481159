import styled from "styled-components";

import { c, v } from "constants/css";

export const DialogContainer = styled.div`
  padding: ${v.gUnit} 0 0 0;
  min-width: 216px;
  z-index: 9999998;
  position: absolute;
  background-color: ${c.colorWhite};
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.18);
  border-radius: 8px;
  right: 0;
  top: 30px;
`;
