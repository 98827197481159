import { setState } from "actions";

/**
 * @param {Object} modalProps
 * @returns {*}
 */
export function openConfirmationModalAction(modalProps) {
  return (dispatch) =>
    dispatch(
      setState("MODAL", {
        isOpen: true,
        view: "MODAL_WARNING",
        modalProps,
      }),
    );
}

/**
 * @returns {*}
 */
export function showSettingsModalAction() {
  return (dispatch) =>
    dispatch(
      setState("MODAL", {
        isOpen: true,
        view: "SETTINGS_SATISFACTION_MODAL",
        modalProps: {},
      }),
    );
}
