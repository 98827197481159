import { createSelector } from "@reduxjs/toolkit";

import { createAlert } from "actions/alerts";
import { type State } from "reducers";
import { pluralize } from "services/pluralize";
import { AdaApiSlice } from "slices";
import { mapRulesToArticleIds } from "slices/knowledge/mapRulesToArticleIds";
import { serializeFilters } from "slices/knowledge/serializeFilters";

import {
  deserializeArticleMetadata,
  deserializeArticles,
  deserializeFullArticle,
  deserializeKBSettings,
} from "./deserialization";
import {
  type ArticleFilter,
  type ArticleResponse,
  type ArticleTagsResponse,
  type KBSettings,
  type KBSyncResponse,
  type RulesetsResponse,
  type SerializedArticleResponse,
  type SerializedGetArticleResponse,
  type SerializedKBSettings,
  type SourcesResponse,
} from "./types";

const AdaApiSliceWithTags = AdaApiSlice.enhanceEndpoints({
  addTagTypes: ["GetArticles", "GetRulesets", "GetSources", "GetKBSettings"],
});

export const KnowledgeApi = AdaApiSliceWithTags.injectEndpoints({
  endpoints: (build) => ({
    getArticles: build.query<
      ArticleResponse,
      {
        pageNumber: number;
        pageSize: number;
        filters?: ArticleFilter[];
        search?: string;
      }
    >({
      query: ({ pageNumber, pageSize, filters = [], search = "" }) => ({
        method: "GET",
        url: "/articles",
        params: {
          page_number: pageNumber,
          limit: pageSize,
          search,
          ...serializeFilters(filters),
        },
      }),
      providesTags: ["GetArticles"],
      transformResponse: (response: SerializedArticleResponse) => {
        const transformedResponse: ArticleResponse = {
          articles: deserializeArticles(response.articles),
          meta: deserializeArticleMetadata(response.meta),
        };

        return transformedResponse;
      },
    }),
    getArticle: build.query({
      query: (id: string) => ({
        method: "GET",
        url: `/articles/${id}`,
      }),
      transformResponse: (response: SerializedGetArticleResponse) =>
        deserializeFullArticle(response.article),
    }),
    updateArticlesEnabled: build.mutation<
      void,
      { articleIds: string[]; externalIds?: string[]; enabled: boolean }
    >({
      query: ({ articleIds, enabled }) => ({
        method: "PATCH",
        url: "/articles",
        data: {
          article_ids: articleIds,
          enabled,
        },
      }),
      async onQueryStarted(
        { articleIds, enabled },
        { dispatch, getState, queryFulfilled },
      ) {
        const knowledgeState = (getState() as State).knowledge;
        // optimistically update the articles in the store
        dispatch(
          KnowledgeApi.util.updateQueryData(
            "getArticles",
            {
              pageNumber: knowledgeState.page,
              pageSize: knowledgeState.pageSize,
              search: knowledgeState.search,
              filters: knowledgeState.filters,
            },
            (draft) => {
              draft.articles.forEach((article) => {
                if (articleIds.includes(article.id)) {
                  Object.assign(article, { enabled });
                }
              });
            },
          ),
        );

        dispatch(
          KnowledgeApi.util.updateQueryData(
            "getArticle",
            articleIds[0] as string,
            (draft) => {
              Object.assign(draft, { enabled });
            },
          ),
        );

        try {
          await queryFulfilled;
          const message = `${articleIds.length} ${pluralize(
            articleIds.length,
            "article",
          )} ${enabled ? "set as active" : "set as inactive"}.`;

          dispatch(createAlert({ message, alertType: "success" }));
          // invalidate the cache so that other stale cache entries are not used
          dispatch(KnowledgeApi.util.invalidateTags(["GetArticles"]));
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
          dispatch(KnowledgeApi.util.invalidateTags(["GetArticles"]));
        }
      },
    }),
    getRulesets: build.query({
      query: () => ({
        method: "GET",
        url: "/articles/rulesets",
      }),
      providesTags: ["GetRulesets"],
      transformResponse: (response: RulesetsResponse) =>
        mapRulesToArticleIds(response.rulesets),
    }),
    createRuleset: build.mutation<
      void,
      { rules: string; articleIds: string[] }
    >({
      query: ({ rules, articleIds }) => ({
        method: "POST",
        url: "/articles/rulesets",
        data: {
          article_ids: articleIds,
          rules,
        },
      }),
      async onQueryStarted(
        { rules, articleIds },
        { dispatch, queryFulfilled },
      ) {
        dispatch(
          KnowledgeApi.util.updateQueryData("getRulesets", {}, (draft) => {
            const newDraft = { ...draft };
            articleIds.forEach((articleId) => {
              newDraft[articleId] = rules;
            });

            return newDraft;
          }),
        );

        try {
          await queryFulfilled;
          dispatch(
            createAlert({
              message: "Availability updated.",
              alertType: "success",
            }),
          );
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
          dispatch(KnowledgeApi.util.invalidateTags(["GetRulesets"]));
        }
      },
    }),
    createAdaArticle: build.mutation<
      void,
      {
        id: string;
        name: string;
        content: string;
        enabled: boolean;
        rules: string;
        language?: string;
        hideNotification?: boolean;
      }
    >({
      query: ({ id, name, content, enabled, rules, language }) => ({
        method: "POST",
        url: "/articles",
        data: {
          id,
          name,
          content,
          enabled,
          rules,
          language,
          external_updated: new Date().toISOString(),
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          if (!args.hideNotification) {
            dispatch(
              createAlert({
                message: "Article created.",
                alertType: "success",
              }),
            );
          }

          dispatch(
            KnowledgeApi.util.invalidateTags(["GetArticles", "GetRulesets"]),
          );
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
        }
      },
    }),
    updateAdaArticle: build.mutation<
      void,
      {
        id: string;
        name: string;
        content: string;
        enabled: boolean;
        rules: string;
        language: string;
      }
    >({
      query: ({ id, name, content, enabled, rules, language }) => ({
        method: "PATCH",
        url: `/articles/${id}`,
        data: {
          name,
          content,
          enabled,
          rules,
          language,
          external_updated: new Date().toISOString(),
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        // optimistically update the rules state
        dispatch(
          KnowledgeApi.util.updateQueryData("getRulesets", {}, (draft) => ({
            ...draft,
            [args.id]: args.rules,
          })),
        );

        // optimistically update the article in the store
        dispatch(
          KnowledgeApi.util.updateQueryData("getArticle", args.id, (draft) => {
            Object.assign(draft, {
              title: args.name,
              content: args.content,
              enabled: args.enabled,
              rules: args.rules,
              language: args.language,
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(
            createAlert({
              message: "Article updated.",
              alertType: "success",
            }),
          );
          dispatch(KnowledgeApi.util.invalidateTags(["GetArticles"]));
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
        }
      },
    }),
    deleteAdaArticle: build.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/articles/${id}`,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            createAlert({
              message: "Article deleted.",
              alertType: "success",
            }),
          );
          dispatch(KnowledgeApi.util.invalidateTags(["GetArticles"]));
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
        }
      },
    }),
    getKBSettings: build.query<KBSettings, void>({
      query: () => ({
        method: "GET",
        url: "/knowledgebase",
      }),
      providesTags: ["GetKBSettings"],
      transformResponse: (response: { settings: SerializedKBSettings }) =>
        deserializeKBSettings(response.settings),
    }),
    syncKB: build.mutation<KBSyncResponse, boolean | void>({
      query: (force) => ({
        method: "GET",
        url: `knowledgebase/sync${force ? "/?force" : ""}`,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            KnowledgeApi.util.invalidateTags(["GetArticles", "GetKBSettings"]),
          );
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
        }
      },
    }),
    createIntegration: build.mutation<
      void,
      {
        integration: "zendesk" | "salesforce" | "confluence" | "pinterest";
        payload: Record<string, unknown>;
      }
    >({
      query: ({ integration, payload }) => ({
        method: "POST",
        url: `/knowledgebase/integrations/${integration}/`,
        data: payload,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            KnowledgeApi.util.invalidateTags(["GetKBSettings", "GetArticles"]),
          );
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
        }
      },
    }),
    deleteIntegration: build.mutation<void, number>({
      query: (position: number) => ({
        method: "DELETE",
        url: `/knowledgebase/integrations/${position}`,
      }),
      async onQueryStarted(position, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            createAlert({
              message: "Integration removed.",
              alertType: "success",
            }),
          );
          dispatch(
            KnowledgeApi.util.invalidateTags(["GetKBSettings", "GetArticles"]),
          );
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong. Please try again.",
              alertType: "warning",
            }),
          );
        }
      },
    }),
    getSources: build.query<SourcesResponse, void>({
      query: () => ({
        method: "GET",
        url: `knowledge/sources`,
      }),
      providesTags: ["GetSources"],
    }),
    getArticleTags: build.query<ArticleTagsResponse, void>({
      query: () => ({
        method: "GET",
        url: "/article_tags",
      }),
    }),
  }),
});

export const SelectAllArticles = createSelector(
  [
    (state: State) => state,
    (
      state: State,
      params: {
        pageNumber: number;
        pageSize: number;
        filters?: ArticleFilter[];
        search?: string;
      },
    ) => params,
  ],
  (state, { pageNumber, pageSize, filters, search }) =>
    KnowledgeApi.endpoints.getArticles.select({
      pageNumber,
      pageSize,
      search,
      filters,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    })(state).data?.articles ?? [],
);

export const {
  useGetArticlesQuery,
  useGetArticleQuery,
  useGetArticleTagsQuery,
  useUpdateArticlesEnabledMutation,
  useGetKBSettingsQuery,
  useSyncKBMutation,
  useGetSourcesQuery,
  useGetRulesetsQuery,
  useCreateRulesetMutation,
  useCreateAdaArticleMutation,
  useUpdateAdaArticleMutation,
  useDeleteAdaArticleMutation,
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
} = KnowledgeApi;

export const { getKBSettings, syncKB } = KnowledgeApi.endpoints;
