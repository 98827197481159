import {
  type AnyAction,
  type Dispatch,
  bindActionCreators,
} from "@reduxjs/toolkit";
import React from "react";
import { connect } from "react-redux";

import { dismissWindowAlertAction } from "actions/windowAlert";
import { Button } from "components/Common/Button";
import { type State } from "types";
import "./style.scss";

interface WindowAlertProps {
  windowAlert: State["windowAlert"];
  dismissWindowAlert(...args: unknown[]): unknown;
}

class WindowAlert extends React.Component<WindowAlertProps> {
  renderImage() {
    const { windowAlert } = this.props;
    const imageUrl = windowAlert.get("image");

    if (imageUrl) {
      return (
        <img
          className="WindowAlert__inner-container__image"
          src={imageUrl as string}
          alt=""
        />
      );
    }

    return null;
  }

  renderActions() {
    const { windowAlert } = this.props;

    switch (windowAlert.get("type")) {
      case "reload":
        return (
          <div className="WindowAlert__inner-container__actions">
            <Button
              onClick={() => window.location.reload()}
              title="Update and reload your app"
              text="Update"
              icon="Sync"
              fillColor="white"
            />
          </div>
        );
      default:
        return undefined;
    }
  }

  render() {
    const { dismissWindowAlert, windowAlert } = this.props;

    return (
      <div
        className={`WindowAlert ${
          windowAlert.get("isVisible") ? "WindowAlert--active" : ""
        }`}
      >
        {/* No need for keyboard handler */}
        <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
          className="WindowAlert__mask"
          role="button"
          aria-label="Dismiss"
          onClick={
            !windowAlert.get("actionRequired") ? dismissWindowAlert : undefined
          }
        />
        <div className="WindowAlert__container">
          <div className="WindowAlert__inner-container">
            <div className="WindowAlert__inner-container__title">
              {windowAlert.get("title")}
            </div>
            <div className="WindowAlert__inner-container__content">
              {this.renderImage()}
              {windowAlert.get("content")}
            </div>
            {this.renderActions()}
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state: State) {
  const { windowAlert } = state;

  return { windowAlert };
}

function mapDispatch(dispatch: Dispatch<AnyAction>) {
  return {
    dismissWindowAlert: bindActionCreators(dismissWindowAlertAction, dispatch),
  };
}

const Connector = connect(mapState, mapDispatch)(WindowAlert);

// eslint-disable-next-line import/no-default-export
export default Connector;
