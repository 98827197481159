export const CHANNELS = {
  PUBLIC_KNOWLEDGEBASE: "Pkb" as const,
  CUSTOMER_PORTAL: "Csp" as const,
  PARTNER_PORTAL: "Prm" as const,
};

export interface ArticleChannel {
  [CHANNELS.PUBLIC_KNOWLEDGEBASE]: boolean;
  [CHANNELS.CUSTOMER_PORTAL]: boolean;
  [CHANNELS.PARTNER_PORTAL]: boolean;
}
