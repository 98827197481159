import Immutable from "immutable";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createAlert as createAlertAction } from "actions/alerts";
import { saveClientAction, updateClientAction } from "actions/client";
import {
  type ClientAttributes,
  type FallbackUiLink,
} from "resourceModels/Client";
import { clientSelector } from "selectors/client";
import { resetOne } from "services/resources";

/** @deprecated Use `useClient` */
export function useClientLegacy() {
  const dispatch = useDispatch();
  const client = useSelector(clientSelector);

  if (!client) {
    throw new Error(
      "`client` is undefined. Make sure client is fetched before using useClientLegacy() hook.",
    );
  }

  const { id } = client;
  const defaultLanguageCode = client.language;

  return {
    client,
    updateClient: (attributes: Partial<ClientAttributes>) =>
      dispatch(updateClientAction(attributes)),
    resetClient: useCallback(() => {
      dispatch(resetOne("client", id));
    }, [dispatch, id]),
    saveClient: useCallback(
      (message?: string) => {
        try {
          dispatch(saveClientAction());
          dispatch(
            createAlertAction({
              message: message || "Your bot customization has been updated.",
              alertType: "success",
            }),
          );
        } catch (e) {
          dispatch(
            createAlertAction({
              message: "Something went wrong",
              alertType: "error",
            }),
          );
        }
      },
      [dispatch],
    ),
    saveClientAllowList: useCallback(() => {
      try {
        dispatch(saveClientAction());
        dispatch(
          createAlertAction({
            message: "Your bot customization has been updated.",
            alertType: "success",
          }),
        );
      } catch (e) {
        dispatch(
          createAlertAction({
            message: "Something went wrong",
            alertType: "error",
          }),
        );
      }
    }, [dispatch]),

    defaultLanguageCode,

    getBotNameValue: useCallback(
      (languageCode: string) => {
        const botNameMap = client.uiSettings.get("chat").get("botName");

        return botNameMap.get(languageCode) || "";
      },
      [client],
    ),

    getBotDescription: useCallback(
      (languageCode: string) => {
        const botDescriptions = client.uiSettings
          .get("chat")
          .get("botDescription");

        return botDescriptions.get(languageCode) || "";
      },
      [client],
    ),

    getFallbackMessage: useCallback(
      (languageCode: string) => {
        const fallbackMessage = client.uiSettings
          .get("chat")
          .get("fallbackUiSettings")
          .get("message");

        return fallbackMessage.get(languageCode) || "";
      },
      [client],
    ),

    getFallbackLinks: useCallback(
      (languageCode: string): Immutable.List<FallbackUiLink> => {
        const fallbackLinks = client.uiSettings
          .get("chat")
          .get("fallbackUiSettings")
          .get("links")
          .get(languageCode);

        return fallbackLinks || Immutable.List<FallbackUiLink>();
      },
      [client],
    ),
  };
}
