export function getBotRolloutsFromLaunchControls(launchControls: {
  showNoBot: number;
  showPrimaryBot: number;
  showAlternativeBot: number;
}) {
  const { showNoBot, showAlternativeBot } = launchControls;

  // this is equivalent to setting the value of the previous rollout slider
  const newBotRollout = 100 - showNoBot;

  // handle division by zero case by setting the alternative bot rollout to 0 when the bot rollout is 0
  const alternativeBotRollout =
    newBotRollout === 0
      ? 0
      : parseFloat((showAlternativeBot / newBotRollout).toFixed(2));

  return {
    rollout: parseFloat((newBotRollout / 100).toFixed(2)),
    alternativeBotRollout,
  };
}
