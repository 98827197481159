import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

import { cssVariables } from "constants/css";

export const Root = styled.div`
  position: fixed;
  height: 773px;
  width: 375px;
  right: 410px;
  bottom: 25px;

  display: flex;
  flex-direction: column;
  z-index: ${cssVariables.zIndexModal};
  border-radius: ${primitives.borderRadius.large};
  background: ${tokens.colors.background.main};

  @media screen and (max-height: 858px) {
    height: 90vh;
  }
`;

export const VariablesConfig = styled.div`
  width: 100%;
  padding: 0 ${primitives.spacing.space4};
  overflow-y: auto;
`;

export const VariableConfigRow = styled.div`
  display: flex;
  padding: ${primitives.spacing.space4} 0;
  border-bottom: 1px solid ${tokens.colors.border.formInput};
  height: fit-content;
  width: 100%;
`;

export const AddButtonContainer = styled.div`
  margin-top: ${primitives.spacing.space2};
`;

export const Footer = styled.div`
  margin-top: auto;
  border-top: 1px solid ${tokens.colors.border.default};
  padding: 16px 24px;
  flex-shrink: 0;
`;
