/**
 * This is a reducer for keeping track of all the loaded responses and their edited data.
 *
 * The plan is to only load the response data that we need. All other response data will
 * only live on the API.
 *
 * The reducer state will be a map of response IDs to response records:
 *
 * Map {
 *   [response_id]: [ResponseRecord]
 *   ...
 * }
 *
 * This should have much greater performance benefits, rather than loading ALL
 * responses with ALL the nested data inside them.
 *
 */

import * as Immutable from "immutable";

import { type updateResponse } from "actions";
import {
  type blockDragEndAction,
  type blockSortingEndAction,
  type dragFromToolboxAction,
} from "actions/dragAndDrop";
import { type replaceAnswerSettings } from "components/Shared/Pages/Responses/ResponseVersions/actions";
import {
  MODALITY_DETAILS,
  type Modality,
  VOICE_MODALITY,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type BaseImmutableMessageRecord } from "reducers/responses/messageRecords/BaseMessageRecord";
import { createRecordFromResponse } from "reducers/responses/reducer";
import {
  type ResponseMessageDto,
  type ResponseRecord,
} from "reducers/responses/types";
import { uuid } from "services/generate-uuid";
import { isPrefixArray, isSameLevel } from "services/helpers";
import {
  type KeyPath,
  type addLanguageToResponseMessagesAction,
  type addPreviewLanguageToResponseMessagesAction,
  type applyTranslationPreviewAction,
  type deleteResponseLanguageAction,
} from "services/responses";

import { stateWithMetaData } from "./reducerHelpers";
import { updateVariableIdsFromServer } from "./variablesHelpers";

let lastSavedResponses = Immutable.Map<string, ResponseRecord>();

export function getSavedResponse(responseId: string) {
  return lastSavedResponses.find((resp) => resp.id === responseId);
}

type Action =
  | ReturnType<typeof deleteResponseLanguageAction>
  | ReturnType<typeof dragFromToolboxAction>
  | ReturnType<typeof blockDragEndAction>
  | ReturnType<typeof blockSortingEndAction>
  | ReturnType<typeof replaceAnswerSettings>
  | ReturnType<typeof updateResponse>
  | ReturnType<typeof addLanguageToResponseMessagesAction>
  | ReturnType<typeof addPreviewLanguageToResponseMessagesAction>
  | ReturnType<typeof applyTranslationPreviewAction>
  | {
      type: "SAVE_BOT_CONTENT_SUCCESS";
      responses: Record<string, ResponseMessageDto>;
    }
  | {
      type: "COPY_MESSAGES_FROM_MODALITY";
      fromPath: KeyPath;
      toPath: KeyPath;
      responseId: string;
    }
  | {
      type: "VALIDATE_ACTION_INTEGRATION";
      keyPath: KeyPath;
      responseId: string;
      [key: string]: unknown;
    }
  | {
      type: "GET_RESPONSES_BY_ID_SUCCESS";
      response: { data: { response: ResponseMessageDto } };
    }
  | {
      type: "UPDATE_DND_FOLDER_STRUCTURE";
      folders: {
        dnd: { _id: string; children: { type: unknown; _id: string }[] }[];
      };
    }
  | {
      type: "SAVE_FOLDER_NODE_BOT_CONTENT_SUCCESS";
      folders: object;
      responseId: string;
    }
  | {
      type: "SAVE_RESPONSE_SUCCESS";
      response: ResponseMessageDto;
      responseId: string;
    }
  | { type: "CREATE_RESPONSE_SUCCESS"; response: ResponseMessageDto }
  | {
      type: "UPDATE_RESPONSE_DEEP";
      responseId: string;
      keyPath: KeyPath;
      payload: object;
    }
  | {
      type: "UPDATE_RESPONSE_STATUS_SUCCESS";
      modality: Modality;
      responseId: string;
      responseStatus: unknown;
    }
  | { type: "UNDO_RESPONSE"; responseId: string }
  | {
      type: "SET_RESPONSE_BUTTON";
      payload: { [key: string]: unknown };
      responseId: string;
    } // TODO Check if the action is actually being used
  | {
      type: "CREATE_VARIABLE_SUCCESS";
      response: {
        data: { variable: { [key: string]: unknown }; [key: string]: unknown };
        [key: string]: unknown;
      };
      responseId: string;
      variable: { id: string; [key: string]: unknown };
      [key: string]: unknown;
    }
  | { type: "REPLACE_CONTENT_BLOCKS"; response: ResponseRecord }
  | { type: "REPLACE_DECLARATIVE_STEP_CONTENT"; response: ResponseRecord }
  | { type: "DELETE_RESPONSE"; responseId: string };

export const responsesLoaded = (
  state: Immutable.Map<string, ResponseRecord> = Immutable.Map(),
  action: Action,
): Immutable.Map<string, ResponseRecord> => {
  switch (action.type) {
    case "DRAG_FROM_TOOLBOX": {
      let newState = state;
      const { over, responseId, message } = action;

      // TODO: Check if `over?.data` can be undefined
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const overKeyPath = over?.data?.current?.keyPath;

      if (!overKeyPath) {
        return newState;
      }

      // the handoff_integration and action_integration blocks
      // both have properties on the original toolbox message that we want to carry over
      const draggingItem = (message as BaseImmutableMessageRecord).merge({
        id: uuid(),
      });

      const overParentKeyPath = overKeyPath.slice(0, -1);
      const overIndex = overKeyPath.slice(-1)[0];

      if (newState.getIn(overParentKeyPath)) {
        newState = newState.updateIn(overParentKeyPath, (overParent) =>
          overParent.splice(overIndex, 0, draggingItem),
        );
      } else {
        newState = newState.setIn(
          overParentKeyPath,
          Immutable.List([draggingItem]),
        );
      }

      return stateWithMetaData(responseId, lastSavedResponses, newState);
    }

    case "BLOCK_DRAG_END": {
      const { active, over, responseId } = action;
      const draggingKeyPath = active.data.current?.keyPath;
      // TODO: Check if `over?.data` can be undefined
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const overKeyPath = over?.data?.current?.keyPath;

      if (!draggingKeyPath || !overKeyPath) {
        return state;
      }

      const draggingParentKeyPath = draggingKeyPath.slice(0, -1);
      const draggingIndex = draggingKeyPath.slice(-1)[0];

      if (isPrefixArray(overKeyPath, draggingKeyPath)) {
        // dragging into self.
        // this happens when dragging conditional block up or down,
        // ends up intersecting with its own droppable areas.
        // might be a way to fix this
        return state;
      }

      const sameLevel = isSameLevel(draggingKeyPath, overKeyPath);

      if (sameLevel) {
        const draggingItem = state.getIn(draggingKeyPath);
        let newState = state;
        const overIndex = overKeyPath.slice(-1)[0];
        const above = overIndex - 1;
        const below = overIndex;
        const inserting = draggingIndex < overIndex ? above : below;

        if (![above, below].includes(draggingIndex)) {
          newState = newState.updateIn(
            draggingParentKeyPath,
            (draggingParent) => {
              const p = draggingParent.delete(draggingIndex);

              return p.splice(inserting, 0, draggingItem);
            },
          );
        }

        return stateWithMetaData(responseId, lastSavedResponses, newState);
      }

      // if the overPath will be effected when we delete the dragging item
      // that is, the draggingParentPath is a prefix
      if (isPrefixArray(overKeyPath, draggingParentKeyPath)) {
        // if we're dragging down
        if (draggingIndex < overKeyPath[draggingParentKeyPath.length]) {
          overKeyPath[draggingParentKeyPath.length] -= 1;
        }
      }

      const overParentKeyPath = overKeyPath.slice(0, -1);
      const overIndex = overKeyPath.slice(-1)[0];
      const draggingItem = state.getIn(draggingKeyPath);
      let newState = state;

      newState = newState.updateIn(draggingParentKeyPath, (draggingParent) =>
        draggingParent.delete(draggingIndex),
      );

      if (newState.getIn(overParentKeyPath)) {
        newState = newState.updateIn(overParentKeyPath, (overParent) =>
          overParent.splice(overIndex, 0, draggingItem),
        );
      } else {
        newState = newState.setIn(
          overParentKeyPath,
          Immutable.List([draggingItem]),
        );
      }

      return stateWithMetaData(responseId, lastSavedResponses, newState);
    }

    case "BLOCK_SORTING_END": {
      const { active, over, responseId } = action;
      const draggingKeyPath = active.data.current?.keyPath;
      // TODO: Check if `over?.data` can be undefined
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const overKeyPath = over?.data?.current?.keyPath;

      const draggingParentKeyPath = draggingKeyPath.slice(0, -1);
      const draggingIndex = draggingKeyPath.slice(-1)[0];

      const draggingItem = state.getIn(draggingKeyPath);
      const overIndex = overKeyPath.slice(-1)[0];

      const newState = state.updateIn(
        draggingParentKeyPath,
        (draggingParent) => {
          const p = draggingParent.delete(draggingIndex);

          return p.splice(overIndex, 0, draggingItem);
        },
      );

      return stateWithMetaData(responseId, lastSavedResponses, newState);
    }

    case "COPY_MESSAGES_FROM_MODALITY": {
      const { fromPath, toPath, responseId } = action;
      const messages = state.getIn(fromPath);
      const nextState = state.setIn(toPath, messages);

      return stateWithMetaData(responseId, lastSavedResponses, nextState);
    }

    case "VALIDATE_ACTION_INTEGRATION": {
      const {
        hasInvalidFields,
        hasInvalidMandatoryInputs,
        keyPath,
        responseId,
      } = action;

      const nextState = state.mergeIn(keyPath, {
        hasInvalidFields,
        hasInvalidMandatoryInputs,
      });

      return stateWithMetaData(responseId, lastSavedResponses, nextState);
    }

    // After we've fetched a response from API,
    // insert it into the Map.
    case "GET_RESPONSES_BY_ID_SUCCESS": {
      const newResponse = createRecordFromResponse(
        action.response.data.response,
        undefined,
      );

      // Update lastSavedResponses Map
      lastSavedResponses = lastSavedResponses.set(newResponse.id, newResponse);

      return state.set(newResponse.id, newResponse);
    }

    case "UPDATE_DND_FOLDER_STRUCTURE": {
      // on DnD operation, a response's parent may have changed,
      // so we update them here.
      let nextState = state;

      action.folders.dnd.forEach((folder) => {
        // filter only the child responses for this folder
        folder.children
          .filter((child) => child.type === "response")
          .forEach((childResponse) => {
            if (nextState.has(childResponse._id)) {
              // only set for responses that have been loaded.
              nextState = nextState.setIn(
                [childResponse._id, "parentId"],
                folder._id,
              );
            }
          });
      });

      return nextState;
    }

    case "SAVE_FOLDER_NODE_BOT_CONTENT_SUCCESS": {
      // updates the response's parentId when it's been moved to another folder
      // via the settings modal
      const { folders = {}, responseId } = action;
      const newParentId = Object.keys(folders)[0];

      if (state.has(responseId)) {
        return state.setIn([responseId, "parentId"], newParentId);
      }

      return state;
    }

    case "SAVE_RESPONSE_SUCCESS": {
      const newResponse = createRecordFromResponse(
        action.response,
        state.get(action.responseId),
      );

      const nextState = state.set(action.responseId, newResponse);

      // Update lastSavedResponses Map
      lastSavedResponses = lastSavedResponses.set(newResponse.id, newResponse);

      return stateWithMetaData(
        action.responseId,
        lastSavedResponses,
        nextState,
      );
    }

    /** ************** */
    /** CREATE ACTIONS */
    /** ************** */

    case "CREATE_RESPONSE_SUCCESS": {
      if (!action.response._id) {
        return state;
      }

      const newResponse = createRecordFromResponse(
        action.response,
        state.get(action.response._id),
      );

      lastSavedResponses = lastSavedResponses.set(newResponse.id, newResponse);

      const nextState = state.set(action.response._id, newResponse);

      return stateWithMetaData(
        action.response._id,
        lastSavedResponses,
        nextState,
      );
    }

    /** *********************** */
    /** UPDATE RESPONSE ACTIONS */
    /** *********************** */

    case "UPDATE_RESPONSE": {
      const nextState = state.mergeIn([action.responseId], action.payload);

      return stateWithMetaData(
        action.responseId,
        lastSavedResponses,
        nextState,
      );
    }

    case "UPDATE_RESPONSE_DEEP": {
      const nextState = state.setIn(action.keyPath, action.payload);

      return stateWithMetaData(
        action.responseId,
        lastSavedResponses,
        nextState,
      );
    }

    case "UPDATE_RESPONSE_STATUS_SUCCESS": {
      const { liveField } = MODALITY_DETAILS[action.modality];
      const nextState = state.mergeIn([action.responseId], {
        [liveField]: action.responseStatus,
      });

      /**
       * return `nextState` instead of `stateWithMetaData` because we don't
       * want to set `isDirty` to true, since `response.live` has already been saved.
       * The liveness toggle on a response saves to api immediately.
       */
      return nextState;
    }

    case "UNDO_RESPONSE": {
      const revertedResponse = lastSavedResponses.get(action.responseId);

      const nextState = state.setIn([action.responseId], revertedResponse);

      return stateWithMetaData(
        action.responseId,
        lastSavedResponses,
        nextState,
      );
    }

    /** ************** */
    /** DELETE ACTIONS */
    /** ************** */

    case "DELETE_RESPONSE": {
      return state.delete(action.responseId);
    }

    case "SET_RESPONSE_BUTTON": {
      const { buttonLabel } = action.payload;

      /*
        Default clarification language to english. May want to change this to
        reflect a default language
      */
      const buttonLanguageToSet = action.payload.languageCode;

      const nextState = state.setIn(
        [action.responseId, "buttonLabel", buttonLanguageToSet],
        buttonLabel,
      );

      return stateWithMetaData(
        action.responseId,
        lastSavedResponses,
        nextState,
      );
    }

    /** **************** */
    /** LANGUAGE ACTIONS */
    /** **************** */

    case "ADD_LANGUAGE_TO_RESPONSE_MESSAGES": {
      const nextState = state
        .setIn(
          [action.responseId, "messages", action.language],
          action.messages,
        )
        .setIn(
          [action.responseId, "options", action.language],
          Immutable.List([]),
        );

      return stateWithMetaData(
        action.responseId,
        lastSavedResponses,
        nextState,
      );
    }

    case "ADD_PREVIEW_LANGUAGE_TO_RESPONSE_MESSAGES": {
      const nextState = state.setIn(
        [
          action.responseId,
          action.modality === VOICE_MODALITY
            ? "messagesVoiceTranslationPreview"
            : "messagesTranslationPreview",
          action.language,
        ],
        action.messages,
      );

      return nextState;
    }

    case "APPLY_TRANSLATION_PREVIEW": {
      const response = state.get(action.responseId);

      if (!response) {
        throw new Error(`Response with id ${action.responseId} not found`);
      }

      const keyPath = [
        action.responseId,
        action.modality === VOICE_MODALITY ? "messagesVoice" : "messages",
        action.languageCode,
      ];
      const responseKey = [
        action.modality === VOICE_MODALITY
          ? "messagesVoiceTranslationPreview"
          : "messagesTranslationPreview",
        action.languageCode,
      ];
      const nextState = state.setIn(keyPath, response.getIn(responseKey));

      return stateWithMetaData(
        action.responseId,
        lastSavedResponses,
        nextState,
      );
    }

    case "DELETE_LANGUAGE": {
      const { responseId, languageCode, modality } = action;

      const nextState = state
        .deleteIn([responseId, "messages", languageCode])
        .deleteIn([
          responseId,
          modality === VOICE_MODALITY ? "messagesVoice" : "messages",
          languageCode,
        ])
        .deleteIn([responseId, "options", languageCode]);

      return stateWithMetaData(responseId, lastSavedResponses, nextState);
    }

    case "CREATE_VARIABLE_SUCCESS": {
      if (action.variable.id !== action.response.data.variable._id) {
        const nextState = updateVariableIdsFromServer(state, action);

        return stateWithMetaData(
          action.responseId,
          lastSavedResponses,
          nextState,
        );
      }

      return state;
    }

    case "REPLACE_CONTENT_BLOCKS": {
      const { response } = action;
      const responseId = response.id;
      const dataToRestore = {
        messages: response.messages,
        messagesVoice: response.messagesVoice,
        options: response.options,
        restoringFromVersionId: response.restoringFromVersionId,
      };
      let nextState;

      if (state.has(responseId)) {
        nextState = state.mergeIn([responseId], dataToRestore);
      } else {
        nextState = state.set(responseId, response);
      }

      return stateWithMetaData(responseId, lastSavedResponses, nextState);
    }

    case "REPLACE_DECLARATIVE_STEP_CONTENT": {
      const { response } = action;
      const responseId = response.id;
      const dataToRestore = {
        messages: response.messages,
        messagesVoice: response.messagesVoice,
        options: response.options,
        restoringFromVersionId: response.restoringFromVersionId,
        rules: response.rules,
        handle: response.handle,
        description: response.description,
      };

      let nextState;

      if (state.has(responseId)) {
        nextState = state.mergeIn([responseId], dataToRestore);
      } else {
        nextState = state.set(responseId, response);
      }

      return stateWithMetaData(responseId, lastSavedResponses, nextState);
    }

    case "REPLACE_ANSWER_SETTINGS": {
      const { response } = action;
      const responseId = response.id;

      const data = {
        handle: response.handle,
        description: response.description,
        descriptiveString: response.descriptiveString,
        buttonLabel: response.buttonLabel,
        tags: response.tags,
        reviewable: response.reviewable,
        clarifications: response.clarifications,
        live: response.live,
      };
      const nextState = state.mergeIn([responseId], data);

      return stateWithMetaData(responseId, lastSavedResponses, nextState);
    }

    case "SAVE_BOT_CONTENT_SUCCESS": {
      const newResponses = Object.keys(action.responses).map((respId) =>
        createRecordFromResponse(
          action.responses[respId] as ResponseMessageDto,
          state.find((response) => response.id === respId),
        ),
      );
      let nextState = state;

      newResponses.forEach((resp) => {
        nextState = nextState.set(resp.id, resp);

        // Update lastSavedResponses Map
        lastSavedResponses = lastSavedResponses.set(resp.id, resp);
        nextState = stateWithMetaData(resp.id, lastSavedResponses, nextState);
      });

      return nextState;
    }

    default:
      return state;
  }
};
