import React from "react";

import { SimpleModalContent } from "components/Common";
import { RadioButtons } from "components/Common/RadioButtons";
import { RulesetEditor } from "components/Common/RulesetEditor";
import { type RuleDefinition } from "services/rules";

import * as S from "./styles";

export const enum AVAILABILITY_OPTIONS {
  EVERYONE,
  LIMITED,
}

interface Props {
  rules: RuleDefinition;
  availabilityOption: AVAILABILITY_OPTIONS;
  isValid: boolean;
  setRules: (rules: RuleDefinition) => void;
  setAvailabilityOption: (availabilityOption: AVAILABILITY_OPTIONS) => void;
  setIsValid: (isValid: boolean) => void;
}

export const ArticleAvailabilityModalContent = ({
  rules,
  availabilityOption,
  isValid,
  setRules,
  setAvailabilityOption,
  setIsValid,
}: Props) => {
  const showRulesetEditor = availabilityOption === AVAILABILITY_OPTIONS.LIMITED;

  const handleChangeAvailabilityOption = (value: AVAILABILITY_OPTIONS) => {
    setAvailabilityOption(value);
    setIsValid(true);
  };

  const handleChangeRules = (newRules: RuleDefinition) => {
    setIsValid(true);
    setRules(newRules);
  };

  return (
    <SimpleModalContent width={800}>
      {!isValid && (
        <S.Notification notificationType="error" closeable={false}>
          Couldn&apos;t set availability. Complete the required fields and try
          again.
        </S.Notification>
      )}
      <RadioButtons
        options={[
          {
            value: AVAILABILITY_OPTIONS.EVERYONE,
            title: "Everyone",
          },
          {
            value: AVAILABILITY_OPTIONS.LIMITED,
            title: "Based on the following rules...",
          },
        ]}
        checked={availabilityOption}
        onClick={handleChangeAvailabilityOption}
        uniqueIdentifier="articleAvailabilityOption"
      />
      {showRulesetEditor && (
        <S.RulesetEditorContainer>
          <RulesetEditor
            ruleset={rules}
            onChange={handleChangeRules}
            highlightInvalidFields={!isValid}
          />
        </S.RulesetEditorContainer>
      )}
    </SimpleModalContent>
  );
};
