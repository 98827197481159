import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "components/Common/Button";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { MessageTemplate } from "resourceModels/MessageTemplate";

import "./style.scss";

const blockName = "SettingsSatisfactionModalPreview";
const defaultSteps = ["main", "question", "comment"];

/**
 * @param {MessageTemplate} messageTemplate
 * @returns {Array.<String>}
 */
function getActiveSteps(messageTemplate) {
  return defaultSteps.filter((step) => {
    if (step === "question" && !messageTemplate.hasQuestionSection) {
      return false;
    }

    return !(step === "comment" && !messageTemplate.hasCommentSection);
  });
}

class SettingsSatisfactionModalPreview extends React.Component {
  handleBackClickBound = this.handleBackClick.bind(this);
  handleForwardClickBound = this.handleForwardClick.bind(this);

  state = {
    stepIndex: 0,
  };

  /**
   * Hooks
   */

  /**
   * @param {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    const {
      /** @type MessageTemplate */
      messageTemplate,
      activeLanguageCode,
    } = this.props;
    const {
      /** @type MessageTemplate */
      messageTemplate: newMessageTemplate,
    } = nextProps;
    const newActiveSteps = getActiveSteps(newMessageTemplate);

    // Automatically switch view when things change
    if (
      newMessageTemplate.hasQuestionSection !==
        messageTemplate.hasQuestionSection ||
      newMessageTemplate.hasCommentSection !== messageTemplate.hasCommentSection
    ) {
      this.setState({
        stepIndex: newActiveSteps.indexOf("main"),
      });
    } else if (
      newMessageTemplate.getLabel(
        "additionalCommentsLabel",
        activeLanguageCode,
      ) !==
      messageTemplate.getLabel("additionalCommentsLabel", activeLanguageCode)
    ) {
      this.setState({
        stepIndex: newActiveSteps.indexOf("comment"),
      });
    } else if (
      newMessageTemplate.getLabel("yesNoQuestionLabel", activeLanguageCode) !==
      messageTemplate.getLabel("yesNoQuestionLabel", activeLanguageCode)
    ) {
      this.setState({
        stepIndex: newActiveSteps.indexOf("question"),
      });
    } else if (
      newMessageTemplate.getLabel(
        "likertScaleQuestionLabel",
        activeLanguageCode,
      ) !==
        messageTemplate.getLabel(
          "likertScaleQuestionLabel",
          activeLanguageCode,
        ) ||
      newMessageTemplate.getLabel("likertScaleLabelLow", activeLanguageCode) !==
        messageTemplate.getLabel("likertScaleLabelLow", activeLanguageCode) ||
      newMessageTemplate.getLabel(
        "likertScaleLabelHigh",
        activeLanguageCode,
      ) !==
        messageTemplate.getLabel("likertScaleLabelHigh", activeLanguageCode) ||
      newMessageTemplate.scale !== messageTemplate.scale ||
      newMessageTemplate.startScaleAtOne !== messageTemplate.startScaleAtOne
    ) {
      this.setState({
        stepIndex: newActiveSteps.indexOf("main"),
      });
    }
  }

  /**
   * Properties
   */

  /**
   * @returns {Array.<String>}
   */
  get activeSteps() {
    const { messageTemplate } = this.props;

    return getActiveSteps(messageTemplate);
  }

  /**
   * Handlers
   */

  /**
   */
  handleBackClick() {
    const { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex - 1,
    });
  }

  /**
   */
  handleForwardClick() {
    const { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
    });
  }

  /**
   * Don't display label text on binary mode
   */
  showBinary() {
    const {
      /** @type MessageTemplate */
      messageTemplate,
    } = this.props;

    return messageTemplate.scaleItems.length <= 2;
  }

  /**
   * Methods
   */

  /**
   * @returns {ReactElement}
   */
  render() {
    const { messageTemplate, activeLanguageCode } = this.props;
    const { stepIndex } = this.state;
    const showBinary = this.showBinary();
    const scaleItems = messageTemplate.scaleItems.map((item) => {
      let label;

      if (showBinary) {
        const labelName =
          item === messageTemplate.scale
            ? "likertScaleLabelHigh"
            : "likertScaleLabelLow";
        label = messageTemplate.getLabel(labelName, activeLanguageCode);
      } else {
        label = item;
      }

      return {
        value: item,
        label,
      };
    });

    return (
      <div className={blockName}>
        <div className={`${blockName}__navigation`}>
          <Button
            onClick={this.handleBackClickBound}
            title="Back"
            icon="ArrowLeft"
            disabled={stepIndex === 0}
            light
          />
          <div className={`${blockName}__navigation-label`}>Preview</div>
          <Button
            onClick={this.handleForwardClickBound}
            title="Forward"
            icon="ArrowRight"
            disabled={stepIndex === this.activeSteps.length - 1}
            light
          />
        </div>

        <div className={`${blockName}__messages`}>
          {
            {
              main: (
                <div className={`${blockName}__survey-block`}>
                  <div className={`${blockName}__survey-block-message`}>
                    {messageTemplate.getLabel(
                      "likertScaleQuestionLabel",
                      activeLanguageCode,
                    )}
                  </div>
                  <div className={`${blockName}__survey-block-scale`}>
                    <div className={`${blockName}__survey-block-scale-grade`}>
                      {scaleItems.map((item) => (
                        <span
                          className={classnames(
                            `${blockName}__survey-block-scale-item`,
                            `${blockName}__survey-block-button`,
                          )}
                          key={item.value}
                        >
                          {item.label}
                        </span>
                      ))}
                    </div>
                    {!this.showBinary() && (
                      <div
                        className={`${blockName}__survey-block-scale-labels`}
                      >
                        <div
                          className={`${blockName}__survey-block-scale-label`}
                        >
                          {messageTemplate.getLabel(
                            "likertScaleLabelLow",
                            activeLanguageCode,
                          )}
                        </div>
                        <div
                          className={`${blockName}__survey-block-scale-label`}
                        >
                          {messageTemplate.getLabel(
                            "likertScaleLabelHigh",
                            activeLanguageCode,
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ),
              question: (
                <div className={`${blockName}__survey-block`}>
                  <div className={`${blockName}__survey-block-message`}>
                    {messageTemplate.getLabel(
                      "yesNoQuestionLabel",
                      activeLanguageCode,
                    )}
                  </div>
                  <div
                    className={classnames(
                      `${blockName}__survey-block-buttons`,
                      `${blockName}__survey-block-buttons--yes-no`,
                    )}
                  >
                    <div className={`${blockName}__survey-block-button`}>
                      Yes
                    </div>
                    <div className={`${blockName}__survey-block-button`}>
                      No
                    </div>
                  </div>
                </div>
              ),
              comment: (
                <div className={`${blockName}__survey-block`}>
                  <div className={`${blockName}__survey-block-message`}>
                    {messageTemplate.getLabel(
                      "additionalCommentsLabel",
                      activeLanguageCode,
                    )}
                  </div>

                  <div className={`${blockName}__survey-block-comment-input`}>
                    <DebouncedInput
                      type="text"
                      value=""
                      placeholder="Enter text"
                      isTextArea
                      characterCounter={320}
                      minHeight={56}
                      smallFont
                    />
                  </div>

                  <div className={`${blockName}__survey-block-submit-button`}>
                    Submit
                  </div>
                </div>
              ),
            }[this.activeSteps[stepIndex]]
          }
        </div>
      </div>
    );
  }
}

SettingsSatisfactionModalPreview.propTypes = {
  messageTemplate: PropTypes.instanceOf(MessageTemplate).isRequired,
  activeLanguageCode: PropTypes.string.isRequired,
};

export default SettingsSatisfactionModalPreview;
