import styled from "styled-components";

import { v } from "constants/css";

export const Title = styled.h1`
  font-size: ${v.largeFontSize};
  font-weight: ${v.heavyFontWeight};
  margin-bottom: ${v.gUnitDouble};
`;

export const InputContainer = styled.div`
  width: 200px;
  margin-bottom: ${v.gUnitDouble};
`;

export const CheckboxContainer = styled.div`
  display: inline-flex;
  margin-right: calc(3 * ${v.gUnit});
  margin-bottom: ${v.gUnitDouble};
`;

export const Root = styled.section``;
