import {
  type AlertAction,
  type AlertButtons,
  type AlertTypeOption,
  DISMISS_ALERT,
  GENERATE_ALERT,
} from "actions/alerts/types";

export interface AlertType {
  isVisible: boolean;
  message: string | null;
  buttons: AlertButtons;
  type: AlertTypeOption | null;
}

export const DEFAULT_ALERT: AlertType = {
  isVisible: false,
  message: null,
  buttons: [],
  type: null,
};

export function alert(state = DEFAULT_ALERT, action: AlertAction) {
  switch (action.type) {
    case GENERATE_ALERT: {
      return {
        ...state,
        isVisible: true,
        message: action.message,
        buttons: action.buttons || [],
        type: action.alertType || "warning",
      };
    }

    case DISMISS_ALERT:
      return DEFAULT_ALERT;
    default:
      return state;
  }
}
