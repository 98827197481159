import { type AsyncAction } from "actions/types";
import { type ResponseRecord } from "reducers/responses/types";
import { selectClient } from "services/client";
import { deleteLanguageScopedVariables } from "services/responses";

export function introsDeleteLanguage(
  response: ResponseRecord,
  selectedLanguage: unknown,
  primaryLanguage: unknown,
  switchLanguage = false,
): AsyncAction {
  return (dispatch, getState) => {
    const client = selectClient(getState());

    if (client?.features.personalization) {
      dispatch(deleteLanguageScopedVariables(response.id));
    }

    dispatch({
      type: "DELETE_INTROS_LANGUAGE",
      response,
      selectedLanguage,
      primaryLanguage,
      switchLanguage,
    });
  };
}
