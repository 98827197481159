import React from "react";

import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { type ZAWPlatformAttributes } from "reducers/platforms/models/ZendeskAgentWorkspacePlatformRecord";
import { type Platform } from "reducers/platforms/types";

import { InputRow } from "../LiveAgents/SalesforceLAModal/InputRow";

export const INVALID_CREDENTIALS_ERROR_MESSAGE =
  "We couldn’t connect to your account. Verify the highlighted fields and try again.";
export const SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK =
  "Your Zendesk instance is not linked to a Sunshine Conversations App. Please contact your Zendesk representative to resolve issue.";
export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again.";

const errorTypes = {
  GENERIC: "generic",
  INVALID_CREDENTIALS_ERROR: "validation_exception",
  SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK: "integration_validation_exception",
};

interface Props {
  platform: Platform;
  saveClientPlatform: (updatedPlatform: Platform) => void;
  updateZendeskAgentWorkspacePlatform: (
    updatedAttributes: Partial<ZAWPlatformAttributes>,
  ) => void;
  isSaveDisabled: boolean;
  closeModal: () => void;
  errorInfo?: {
    type: string;
    platform?: string;
    message?: string;
  };
  clientHasZendeskMessaging: boolean;
}

export const SettingsScreen = ({
  platform,
  saveClientPlatform,
  updateZendeskAgentWorkspacePlatform,
  isSaveDisabled,
  closeModal,
  errorInfo = undefined,
  clientHasZendeskMessaging,
}: Props) => {
  let errorBanner;
  let invalidZendeskCredentials;

  if (errorInfo) {
    let message;

    if (errorInfo.type === errorTypes.INVALID_CREDENTIALS_ERROR) {
      invalidZendeskCredentials = errorInfo.platform === "zendesk";
      message = INVALID_CREDENTIALS_ERROR_MESSAGE;
    } else if (
      errorInfo.type === errorTypes.SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK
    ) {
      message = SUNSHINE_APP_NOT_CONNECTED_TO_ZENDESK;
    } else {
      message = GENERIC_ERROR_MESSAGE;
    }

    errorBanner = (
      <Banner icon="Error" intent="negative">
        {message}
      </Banner>
    );
  }

  const guideLink = clientHasZendeskMessaging
    ? "https://docs.ada.cx/ada-glass-zendesk-messaging"
    : "https://docs.ada.cx/zendesk-social-messaging";

  return (
    <>
      <div className="Modal__content ZendeskAgentWorkspace__Settings">
        <Banner icon="QuestionCircleFilled">
          Need help? Check out our{" "}
          <a href={guideLink} target="_blank" rel="noopener noreferrer">
            Zendesk Messaging Guide.
          </a>
        </Banner>
        {errorBanner}
        <section className="Modal__section">
          <div className="ZendeskAgentWorkspace__Settings__zd-social__row">
            <InputRow
              // N.B.: "split" rows get an extra class name:
              className="ZendeskAgentWorkspace__Settings__cell ph-no-capture"
              label="Zendesk Subdomain"
              tooltip={`Your subdomain is the first part of your helpdesk URL: 
              https://[yoursubdomain].zendesk.com`}
              value={platform.getIn(["record", "auth", "subdomain"])}
              placeholder="e.g Mycompany"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateZendeskAgentWorkspacePlatform({
                  auth: { subdomain: e.target.value },
                })
              }
              invalid={invalidZendeskCredentials}
            />
            <InputRow
              // N.B.: "split" rows get an extra class name:
              className="ZendeskAgentWorkspace__Settings__cell ph-no-capture"
              label="Zendesk Login Email"
              tooltip="The email you use to login to Zendesk"
              value={platform.getIn(["record", "auth", "email"])}
              placeholder="e.g you@yourcompany.com"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateZendeskAgentWorkspacePlatform({
                  auth: { email: e.target.value },
                })
              }
              invalid={invalidZendeskCredentials}
            />
          </div>
          <InputRow
            // N.B.: "split" rows get an extra class name:
            className="ZendeskAgentWorkspace__Settings__cell ph-no-capture"
            label="Zendesk API Token"
            tooltip={`You can get one of these from Zendesk in
          Settings > Channels > API > Token Access`}
            value={platform.getIn(["record", "auth", "apiToken"])}
            placeholder="e.g 1Jc9wXd9nyqAi187LWdJfnJjDY5NMr4ht4Y3nRTn"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateZendeskAgentWorkspacePlatform({
                auth: { apiToken: e.target.value },
              })
            }
            invalid={invalidZendeskCredentials}
          />
        </section>
      </div>
      <div className="Modal__bottom ZendeskAgentWorkspace__Settings__bottom">
        <Button
          onClick={closeModal}
          text="Cancel"
          title="Cancel"
          customClassName="ZendeskAgentWorkspaceModal__cancel-button"
          light
        />
        <Button
          onClick={() => {
            const enabledPlatform = platform.setIn(["record", "enabled"], true);
            saveClientPlatform(enabledPlatform);
          }}
          text="Finish"
          title="Finish"
          disabled={isSaveDisabled}
          isLoading={platform.get("isLoading")}
          customClassName="ZendeskAgentWorkspaceModal__bottom__save"
        />
      </div>
    </>
  );
};
