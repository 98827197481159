import styled from "styled-components";

import { cssColors, cssVariables } from "constants/css";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalSection = styled.section`
  padding: 10px;
`;

export const ModalImageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100px;
  margin: 20px;
`;

export const ModalTitle = styled.h1`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  font-weight: ${cssVariables.xHeavyFontWeight};
  font-size: ${cssVariables.xLargeFontSize};
`;

export const ModalDescription = styled.p`
  text-align: center;
  color: ${cssColors.colorGrey4};
`;

export const ModalOptional = styled.span`
  color: ${cssColors.colorGrey3Active};
`;
