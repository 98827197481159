/* eslint-disable max-classes-per-file */
import Immutable from "immutable";

import { type AddIndex } from "types";

export interface AppIntegrationInputFieldRecordAttributes {
  id: string | null;
  display_name: string;
  description: string;
  description_link: string;
  description_link_text: string;
  placeholder: string;
  optional: boolean;
  validation: Immutable.Map<string, unknown>;
  type: string;
  inputs?: Immutable.List<AppIntegrationInputFieldRecordAttributes>;
  repeatable?: boolean;
}

// Corresponds to the schema specifying an input field
export class AppIntegrationInputFieldRecord extends Immutable.Record<AppIntegrationInputFieldRecordAttributes>(
  {
    id: null,
    display_name: "",
    description: "",
    description_link: "",
    description_link_text: "",
    placeholder: "",
    optional: true,
    validation: Immutable.Map(),
    type: "text",
    inputs: Immutable.List(),
    repeatable: false,
  },
) {}

export interface AppIntegrationOutputFieldRecordAttributes {
  id: string | null;
  display_name: string;
  description: string;
  type: "string";
}

// Corresponds to the schema specifying an output field
export class AppIntegrationOutputFieldRecord extends Immutable.Record<AppIntegrationOutputFieldRecordAttributes>(
  {
    id: null,
    display_name: "",
    description: "",
    type: "string",
  },
) {}

export class AppBlockConfiguration extends Immutable.Record<{
  input_section_label: string | null;
  output_section_label: string | null;
  allow_optional: boolean;
  inline_in_chat: boolean;
}>({
  input_section_label: null,
  output_section_label: null,
  allow_optional: false,
  inline_in_chat: false,
}) {}

export type AppIntegrationsDto = AddIndex<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configuration_schema: Record<any, any>; // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
  description: string;
  display_name: string;
  icon: string;
  widget: AppWidget | null;
  widgets?: AppWidget[];
}>;

export interface AppWidget {
  id: string | null;
  display_name: string;
  description: string;
  input_schema: Immutable.List<AppIntegrationInputFieldRecord>;
  output_schema: Immutable.List<AppIntegrationOutputFieldRecord>;
  widget_url: string;
  message_text: string;
  message_text_info: string;
  button_label: string;
  button_label_info: string;
  retry_prompt: string;
  block_configuration: AppBlockConfiguration;
}

export class AppWidgetRecord extends Immutable.Record<AppWidget>({
  id: null,
  display_name: "",
  description: "",
  input_schema: Immutable.List(),
  output_schema: Immutable.List(),
  widget_url: "",
  message_text: "",
  message_text_info: "",
  button_label: "",
  button_label_info: "",
  retry_prompt: "",
  block_configuration: new AppBlockConfiguration(),
}) {}

// Describes an app manifest
export class AppIntegrationRecord extends Immutable.Record<{
  id: string | null;
  display_name: string;
  description: string;
  icon: string;
  help_docs_url: string | null;
  configuration_schema: Immutable.Map<string, unknown>;
  widget: AppWidgetRecord;
  widgets: Immutable.List<AppWidgetRecord>;
}>({
  id: null,
  display_name: "",
  description: "",
  icon: "",
  help_docs_url: null,
  configuration_schema: Immutable.Map(),
  widget: new AppWidgetRecord(),
  widgets: Immutable.List(),
}) {}
