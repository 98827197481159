import React from "react";
import { useSelector } from "react-redux";

import SvgIcon from "components/Common/SvgIcon";
import { Tag } from "components/Common/Tag";
import { type ResponseRecord } from "reducers/responses/types";
import { replaceVariableIDJSX } from "services/strings";
import { isTopTag } from "services/tags";

import * as S from "./styles";

interface Props {
  response: ResponseRecord;
  onClick: (responseId: string) => void;
}

const ICON_HEIGHT = 20;

export function ResponseListItem({ response, onClick }: Props) {
  const variables = useSelector((state) => state.variables);
  const tags = useSelector((state) => state.tags);
  const filteredTags = tags.filter((tag) => response.tags.includes(tag.id));

  const { isDirty, isValid, rating, reserved } = response;

  return (
    <S.Container
      onClick={(e) => {
        e.stopPropagation(); // otherwise we also register a click on the opening trigger
        onClick(response.id);
      }}
      data-testid="response-list-item"
    >
      <S.Title>
        {response.handle}
        <S.IconBar>
          {isDirty && !isValid && (
            <SvgIcon icon="CloudAlert" height={ICON_HEIGHT} />
          )}
          {isDirty && isValid && <SvgIcon icon="Cloud" height={ICON_HEIGHT} />}
          {!isDirty && !isValid && (
            <SvgIcon icon="WarningYellow" height={ICON_HEIGHT} />
          )}
          {rating === 1 && <SvgIcon icon="ThumbsUp" height={ICON_HEIGHT} />}
          {rating === -1 && <SvgIcon icon="ThumbsDown" height={ICON_HEIGHT} />}
          {reserved && <SvgIcon icon="Lock" height={ICON_HEIGHT} />}
        </S.IconBar>
      </S.Title>
      <S.Description>
        {replaceVariableIDJSX(response.descriptiveString, variables)}
      </S.Description>
      <S.Tags>
        {filteredTags.map((tag) => (
          <S.Tag key={tag.id}>
            <Tag tag={tag} isSmall hasIcon={isTopTag(tag.name)} />
          </S.Tag>
        ))}
      </S.Tags>
    </S.Container>
  );
}
