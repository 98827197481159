// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import moment from "moment";

import RecordClassCreator from "services/record";

export class StatsSatisfactionRecord extends RecordClassCreator({
  type: "statsSatisfactionRecord",

  /** @memberof StatsSatisfactionRecord# */
  date: undefined,
  /** @memberof StatsSatisfactionRecord# */
  timeSeriesUnit: undefined,
  /** @memberof StatsSatisfactionRecord# */
  yes: undefined,
  /** @memberof StatsSatisfactionRecord# */
  no: undefined,
  /**
   * @memberof StatsSatisfactionRecord#
   * @type {Immutable.List<unknown> | undefined}
   */
  comments: undefined,
  /**
   * @memberof StatsSatisfactionRecord#
   * @type {Immutable.List<unknown> | undefined}
   */
  likertScale: undefined,
}) {
  /**
   * @returns {String}
   */
  get chartAxisLabel() {
    switch (this.timeSeriesUnit) {
      case "month":
        return moment(this.date).format("MMM 'YY");
      case "week":
        return moment(this.date).format("MMM D");
      default:
        return moment(this.date).format("MMM D");
    }
  }

  /**
   * @returns {String}
   */
  get tooltipLabel() {
    const date = moment(this.date);

    switch (this.timeSeriesUnit) {
      case "month":
        return date.format("MMMM, YYYY");
      case "week":
        return `Week of ${date.format("MMMM D, YYYY")}`;
      default:
        return date.format("dddd, MMMM D, YYYY");
    }
  }

  /**
   * @param {Object} payload
   * @returns {ManyResourcePayload}
   */
  static resolveFetchManyResult(payload) {
    return {
      ...payload,
      data: payload.data.map((item) => ({
        ...item,
        _id: item.id,
      })),
    };
  }
}
