import { createApi } from "@reduxjs/toolkit/query/react";
import { format, utcToZonedTime } from "date-fns-tz";

import { type DataType } from "components/Common/charts/TimeSeriesChart/types";
import { AdaDateTime } from "services/AdaDateTime";
import { adaAPI } from "services/api";

export interface TimeSeriesResponse {
  reportId: string;
  data: {
    metric: string;
    current: TimeSeriesType;
    previous: TimeSeriesType;
  }[];
}

interface TimeSeriesType {
  aggregate: {
    name: string;
    value: number;
  }[];
  timeSeries: {
    data: number[][];
    dateTime: string[];
  };
}

export interface TimeSeriesData {
  datasets: number[][][];
  datasetsNames: string[];
  prevDatasets: number[][][];
  prevDatasetsNames: string[];
  labels: string[];
  tooltipLabels: { title: string; conversationsViewLink?: string }[];
  dataType: DataType;
  aggregate: {
    [key: string]: {
      previous: {
        [key: string]: string | number | boolean | null;
      };
      current: {
        [key: string]: string | number | boolean | null;
      };
    };
  };
}

interface TabularData {
  reportId: string;
  columnNames: string[];
  data: string[][];
  meta: {
    [k: string]: string | number | undefined;
    page: number;
    totalPages: number;
    totalCount: number;
    maxVal?: number;
  };
}

export interface TabularResponse {
  reportId: string;
  columnNames: string[];
  data: string[][];
  meta?: {
    name: "page" | "totalPages" | "totalCount" | string;
    value: number | string;
  }[];
}

interface QueryArgs {
  reportId: string;
  params: {
    startDate: string;
    endDate: string;
    timeZone: string;
    timeSeriesUnit?: string;
    filters?: string;
    page?: number;
    rowsPerPage?: number;
  };
}

const METRIC_NAMES: { [key: string]: string } = {
  automatedResolutions: "Automated Resolution Rate",
  containment: "Containment Rate",
  sample: "Sample",
};

const formatDataset = (metric: string, data: number[][]): number[][] => {
  if (metric === "sample") {
    return data.map((d) => [-1, d[0] as number]);
  }

  return data;
};

const toPercentage = (value: string): string => {
  let float = parseFloat(value);
  float = Math.round(float * 10) / 10;
  float = float < 10 ? float : Math.round(float);

  return `${float}%`;
};

const reportTransformers = {
  timeSeries: (response: TimeSeriesResponse) => {
    const formattedResponse: TimeSeriesData = {
      datasets: [],
      datasetsNames: [],
      prevDatasets: [],
      prevDatasetsNames: [],
      labels: [],
      tooltipLabels: [],
      dataType: "number",
      aggregate: {},
    };

    response.data.forEach((d) => {
      formattedResponse.datasets.push(
        formatDataset(d.metric, d.current.timeSeries.data),
      );
      formattedResponse.datasetsNames.push(METRIC_NAMES[d.metric] as string);
      formattedResponse.prevDatasets.push(
        formatDataset(d.metric, d.previous.timeSeries.data),
      );
      formattedResponse.prevDatasetsNames.push("Previous Period");
      formattedResponse.labels = d.current.timeSeries.dateTime.map((dt) =>
        new Date(dt).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          timeZone: "UTC",
        }),
      );
      formattedResponse.tooltipLabels = d.current.timeSeries.dateTime.map(
        (dt) => ({
          title: new Date(dt).toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
            year: "numeric",
          }),
        }),
      );
      formattedResponse.aggregate[METRIC_NAMES[d.metric] as string] = {
        previous: {
          ...d.previous.aggregate.reduce(
            (obj, val) => ({ ...obj, [val.name]: val.value }),
            {},
          ),
        },
        current: {
          ...d.current.aggregate.reduce(
            (obj, val) => ({ ...obj, [val.name]: val.value }),
            {},
          ),
        },
      };
    });

    if (response.reportId === "automatedResolutions") {
      formattedResponse.dataType = "percentage";
    }

    return formattedResponse;
  },
  tabular: (
    response: TabularResponse,
    meta: unknown,
    args: QueryArgs,
  ): TabularData => {
    const formattedResponse: TabularData = {
      ...response,
      meta: {
        ...response.meta?.reduce(
          (obj, { name, value }) => ({ ...obj, [name]: value }),
          {},
        ),
        page: parseInt(
          (response.meta?.find((m) => m.name === "page")?.value ??
            1) as unknown as string,
          10,
        ),

        totalPages: parseInt(
          (response.meta?.find((m) => m.name === "totalPages")?.value ??
            1) as unknown as string,
          10,
        ),

        totalCount: parseInt(
          (response.meta?.find((m) => m.name === "totalCount")?.value ??
            1) as unknown as string,
          10,
        ),
      },
    };

    if (response.reportId === "automatedResolutions") {
      formattedResponse.data = response.data.map(
        ([id, dateCreated, inquiry, status, reason]) => [
          id as string,
          format(
            utcToZonedTime(
              AdaDateTime.utcToLocal(dateCreated as string),
              args.params.timeZone,
            ),
            "MMM dd, yyyy h:mma",
            { timeZone: args.params.timeZone },
          ),
          inquiry as string,
          status === "Resolved" ? "Resolved" : "Not Resolved",
          reason as string,
        ],
      );

      const columnNameMapper: { [k: string]: string } = {
        inquiry: "Inquiry Summary",
        status: "Classification",
        reason: "Reason for Classification",
      };
      formattedResponse.columnNames = response.columnNames.map(
        (name) => columnNameMapper[name.toLocaleLowerCase()] ?? name,
      );
    }

    if (response.reportId === "generatedTopics") {
      let maxVal = -Infinity;

      formattedResponse.data = response.data.map(
        ([
          id,
          topic,
          summary,
          arOp,
          volume,
          volumeRate,
          arRate,
          containmentRate,
          csat,
          isNew,
          isHidden,
        ]) => {
          maxVal = Math.max(parseInt(arOp as string, 10), maxVal);

          return [
            `${id}`,
            `${topic}`,
            `${summary}`,
            topic === "Unassigned Conversations"
              ? "-"
              : toPercentage(arOp as string),
            parseInt(volume as string, 10).toLocaleString(),
            toPercentage(volumeRate as string),
            toPercentage(arRate as string),
            toPercentage(containmentRate as string),
            toPercentage(csat as string),
            isNew as string,
            isHidden as string,
          ];
        },
      );

      formattedResponse.meta = {
        ...formattedResponse.meta,
        maxVal,
      };

      const columnNameMapper: { [k: string]: string } = {
        "topic label": "Topic",
        volume: "Conversations",
        "volume %": "% of Sample",
      };
      formattedResponse.columnNames = response.columnNames.map(
        (name) => columnNameMapper[name.toLocaleLowerCase()] ?? name,
      );
    }

    return formattedResponse;
  },
};

export const AresReportApi = createApi({
  reducerPath: "aresReport",
  baseQuery: (props) => adaAPI.request({ ...props }),
  endpoints: (builder) => ({
    getTimeseriesReport: builder.query({
      query: ({ reportId, params }: QueryArgs) => ({
        url: `ares/timeSeries/reports/${reportId}`,
        params,
      }),
      transformResponse: (response: TimeSeriesResponse) => response.data,
    }),
    getTimeseriesReportFormatted: builder.query({
      query: ({ reportId, params }: QueryArgs) => ({
        url: `ares/timeSeries/reports/${reportId}`,
        params,
      }),
      transformResponse: reportTransformers.timeSeries,
    }),
    getTabularReport: builder.query({
      query: ({ reportId, params }: QueryArgs) => ({
        url: `ares/tabular/reports/${reportId}`,
        params,
      }),
      transformResponse: reportTransformers.tabular,
    }),
  }),
});

export const {
  useGetTabularReportQuery,
  useGetTimeseriesReportQuery,
  useGetTimeseriesReportFormattedQuery,
} = AresReportApi;
