import axios from "axios";
import { type Key } from "react";

import { createAlert } from "actions/alerts";
import { AlertType } from "actions/alerts/types";
import { closeModalAction, openModalAction } from "actions/modal";
import { type Dispatch, type ThunkAction } from "actions/types";
import { serializeFolderNode } from "reducers/folders/reducer";
import { type DragDropRecord } from "reducers/folders/types/dragDropRecord";
import { type State } from "reducers/types";
import { adaApiRequest } from "services/api";
import { pluralize } from "services/pluralize";
import { storage } from "services/storage";

export function updateFolderStructureAction(folders: unknown) {
  return { type: "UPDATE_FOLDER_STRUCTURE", folders };
}

export function clearHighlightedFoldersAction() {
  return { type: "CLEAR_HIGHLIGHTED_NODES" };
}

export function fetchRootFoldersAction(): ThunkAction {
  return async (dispatch) => {
    dispatch({ type: "FETCH_ROOT_FOLDER_REQUEST" });

    try {
      const response = await dispatch(
        adaApiRequest({
          method: "GET",
          url: "/folders/root",
        }),
      );

      if (response.data.root) {
        await dispatch({
          type: "FETCH_ROOT_FOLDER_SUCCESS",
          root: response.data.root,
        });
      }
    } catch (error) {
      dispatch({ type: "FETCH_ROOT_FOLDER_FAILURE", response: error });
    }
  };
}

export function fetchExpandedFoldersAction(): ThunkAction {
  return async (dispatch) => {
    const expandedFolders = storage.retrieve("expandedFolders");

    if (!expandedFolders) {
      dispatch({ type: "SET_EMPTY_EXPANDED_FOLDERS" });

      return;
    }

    await dispatch({ type: "FETCH_EXPANDED_FOLDERS_REQUEST", expandedFolders });

    try {
      const response = await dispatch(
        adaApiRequest({
          method: "PUT",
          url: "/folders",
          data: { folder_ids: expandedFolders },
        }),
      );

      if (response.data.folders) {
        await dispatch({
          type: "FETCH_EXPANDED_FOLDERS_SUCCESS",
          folders: response.data.folders,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchOneFolderAction(id: Key): ThunkAction {
  return async (dispatch, getState) => {
    const state = getState();
    const { foldersById, rootFolderId } = state.foldersState;
    const folder = foldersById.get(id);

    if (id === rootFolderId) {
      return;
    }

    if (folder?.get("didLoad") || folder?.get("isPendingLoad")) {
      return;
    }

    try {
      dispatch({ type: "FETCH_ONE_FOLDER_REQUEST", requestKey: id });
      const response = await dispatch(
        adaApiRequest({
          method: "GET",
          url: `/folders/${id}`,
        }),
      );

      if (response.data.folder) {
        dispatch({
          type: "FETCH_ONE_FOLDER_SUCCESS",
          requestKey: id,
          folder: response.data.folder,
        });
      }
    } catch (error) {
      dispatch({
        type: "FETCH_ONE_FOLDER_FAILURE",
        requestKey: id,
        response: error,
      });
    }
  };
}

export function alertFailedDragDropAction() {
  return (dispatch: Dispatch) => {
    dispatch(
      createAlert({
        message: "Unable to move selection, please verify operation",
        alertType: AlertType.WARNING,
      }),
    );
  };
}

export function updateFoldersDndAction(
  dragDropObject: DragDropRecord,
): ThunkAction {
  return async (dispatch, getState: () => State) => {
    dispatch(clearHighlightedFoldersAction());

    const state = getState();

    try {
      const response = await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: "/folders/",
          data: { dnd: dragDropObject.generatePayloadObj(state.foldersState) },
        }),
      );

      if (response.data.folders) {
        dispatch({
          type: "UPDATE_DND_FOLDER_STRUCTURE",
          folders: response.data.folders,
        });
        dispatch(
          createAlert({
            message: "Folder updated successfully.",
            alertType: AlertType.SUCCESS,
          }),
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          dispatch(
            openModalAction("MODAL_WARNING", {
              title: "Couldn't Make Changes",
              message: error.response.data.error,
              shouldCloseOnMaskClick: true,
              actions: [
                {
                  title: "Ok",
                  onClick: () => dispatch(closeModalAction()),
                },
              ],
            }),
          );
          throw error;
        }
      }

      dispatch(
        createAlert({
          message: "Something went wrong - failed to update folder.",
          alertType: AlertType.ERROR,
        }),
      );
      throw error;
    }
  };
}

export function saveFolderAction(folder: Record<string, unknown>): ThunkAction {
  return async (dispatch) => {
    try {
      const response = await dispatch(
        adaApiRequest({
          method: "POST",
          url: "/folders/",
          data: serializeFolderNode(folder),
        }),
      );

      if (response.data.folder) {
        dispatch({
          type: "CREATE_FOLDER_SUCCESS",
          folder: response.data.folder,
        });
        dispatch(
          createAlert({
            message: "Folder saved successfully.",
            alertType: AlertType.SUCCESS,
          }),
        );
      }
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to save folder.",
          alertType: AlertType.ERROR,
        }),
      );

      throw error;
    }
  };
}

export function updateFolderAction(
  folder: Record<string, unknown>,
): ThunkAction {
  return async (dispatch) => {
    dispatch(clearHighlightedFoldersAction());

    try {
      const response = await dispatch(
        adaApiRequest({
          method: "PATCH",
          url: "/folders/",
          data: folder,
        }),
      );

      if (response.data.folders) {
        dispatch(updateFolderStructureAction(response.data.folders));
        dispatch(
          createAlert({
            message: "Folder updated successfully.",
            alertType: AlertType.SUCCESS,
          }),
        );
      }
    } catch (error) {
      // TODO - handle error gracefully
      dispatch(
        createAlert({
          message: "Something went wrong - failed to update folder.",
          alertType: AlertType.ERROR,
        }),
      );
    }
  };
}

export function deleteFolderAction(
  folderId: Key,
  deleteMessage: string,
): ThunkAction {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(
        openModalAction("MODAL_WARNING", {
          title: "Folder deletion",
          message: deleteMessage,
          actions: [
            {
              title: "Close",
              onClick: () => dispatch(closeModalAction()),
            },
          ],
        }),
      );
      const res = await dispatch(
        adaApiRequest<{
          deleted_resources?: {
            deleted_folders: unknown[];
            deleted_responses?: unknown[];
            updated_folder?: Record<string, unknown>;
            reserved_responses: unknown[];
          };
        }>({
          method: "DELETE",
          url: `/folders/${folderId}`,
        }),
      );
      dispatch(closeModalAction());

      if (res.data.deleted_resources) {
        const {
          deleted_folders: deletedFolders,
          deleted_responses: deletedResponses,
          updated_folder: updatedFolder,
          reserved_responses: reservedResponses,
        } = res.data.deleted_resources;
        dispatch({
          type: "REMOVE_CHILDREN_FROM_PARENT_FOLDER",
          deletedFolders,
          deletedResponses,
        });
        dispatch({
          type: "DELETE_FOLDERS_SUCCESS",
          deletedFolders,
          deletedResponses,
        });

        if (updatedFolder) {
          dispatch({
            type: "UPDATE_DND_FOLDER_STRUCTURE",
            folders: { dnd: [updatedFolder] },
          });
        }

        if (deletedResponses) {
          dispatch({
            type: "DELETE_MULTIPLE_RESPONSES_SUCCESS",
            deletedResponses,
          });
        }

        if (reservedResponses.length) {
          dispatch(
            openModalAction("MODAL_WARNING", {
              title: "Folder update",
              message: `${reservedResponses.length} ${pluralize(
                reservedResponses.length,
                "Answer",
              )}
                      could not be deleted because they're locked or are being used as a fallback elsewhere.
                      They will be moved into the parent folder.`,
              actions: [
                {
                  title: "Close",
                  onClick: () => dispatch(closeModalAction()),
                },
              ],
            }),
          );
        } else {
          dispatch(
            createAlert({
              message: "Folder deleted succesfully.",
              alertType: "success",
            }),
          );
        }
      }
    } catch (error) {
      dispatch(
        createAlert({
          message: "Something went wrong - failed to delete folder.",
          alertType: "error",
        }),
      );
    }
  };
}

export function refreshFoldersAction(folderIds: Key[]) {
  return async (dispatch: Dispatch, getState: () => State) => {
    const {
      foldersState: { rootFolderId },
    } = getState();

    if (folderIds.includes(rootFolderId)) {
      await dispatch(fetchRootFoldersAction());
    }

    const nonRootFolderIds = folderIds.filter((fId) => fId !== rootFolderId);

    if (nonRootFolderIds.length) {
      try {
        const response = await dispatch(
          adaApiRequest({
            method: "PUT",
            url: "/folders",
            data: { folder_ids: nonRootFolderIds },
          }),
        );

        if (response.data.folders) {
          dispatch({
            type: "FETCH_EXPANDED_FOLDERS_SUCCESS",
            folders: response.data.folders,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
}

export const getFolderContentSummary =
  (folderId: string) => async (dispatch: Dispatch) => {
    // get the ids of subfolders and responses
    // contained within the given folder
    const res = await dispatch(
      adaApiRequest<{
        references: {
          folders: unknown[];
          responses: unknown[];
        };
      }>({
        method: "GET",
        url: `/folders/${folderId}/folder_references`,
      }),
    );

    return res.data.references;
  };
