import styled from "styled-components";

import { ButtonArea } from "components/Common/ModalElements/styles";
import { c, v } from "constants/css";

export const ContentMessage = styled.div`
  span {
    font-weight: bold;
    font-size: 14px;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: ${v.gUnit};
  }
`;

export const ErrorList = styled.ul`
  border: 1px solid ${c.colorGrey2};
  border-radius: ${v.gUnit};
  padding: 0;
  list-style: none;
  li {
    padding: ${v.gUnit};
    border-bottom: 1px solid ${c.colorGrey2};
    overflow: hidden;
    max-width: 100%;
  }
  li:last-child {
    border: none;
  }
`;

export const RightAlignedButtonArea = styled(ButtonArea)`
  text-align: right;
  button:first-child {
    margin-right: ${v.gUnit};
  }
`;
