import { type AnyAction } from "@reduxjs/toolkit";
import Immutable from "immutable";

import { HandoffIntegrationRecord } from "./types";

interface HandoffIntegrationsStateAttributes {
  loading: boolean;
  loaded: boolean;
  handoffIntegrationsList: Immutable.List<HandoffIntegrationRecord>;
}

export class HandoffIntegrationsStateRecord extends Immutable.Record<HandoffIntegrationsStateAttributes>(
  {
    loading: false,
    loaded: false,
    handoffIntegrationsList: Immutable.List(),
  },
) {}

export const handoffIntegrations = (
  state = new HandoffIntegrationsStateRecord(),
  action: AnyAction,
) => {
  switch (action.type) {
    case "FETCH_HANDOFF_INTEGRATIONS_REQUEST":
      return state.merge({ loading: true });
    case "FETCH_HANDOFF_INTEGRATIONS_SUCCESS":
      return state.merge({
        loaded: true,
        // Transform to records of the right type so we get nice typing and attribute autocomplete
        handoffIntegrationsList: Immutable.List(
          // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          action.handoffIntegrationsList.map((handoffIntegration: any) =>
            new HandoffIntegrationRecord().merge({
              ...handoffIntegration,
            }),
          ),
        ),
      });
    case "FETCH_HANDOFF_INTEGRATIONS_FAILURE":
      return state.merge({ loading: false });
    case "SET_EMPTY_HANDOFF_INTEGRATIONS":
      return state.merge({
        loaded: true,
        handoffIntegrationsList: Immutable.List(),
      });
    default:
      return state;
  }
};
