export const EXPIRE_5_MIN = 5;
export const EXPIRE_5_SECS = 5;
export const TIME_TO_AUTO_LOGOUT_MS = 25000;
export const EXPIRE_24_HOURS = 24;

// TTL == Time To Live
export const PASSWORD_TTL_DAYS = 90;

export const PASSWORD_EXPIRE_24_HOURS_WARNING = "expirePasswordIn24hr";
export const PASSWORD_EXPIRE_5_MINS_WARNING = "expirePasswordIn5min";
export const PASSWORD_EXPIRED_WARNING = "expirePasswordTimeout";
