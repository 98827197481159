import { bindActionCreators } from "@reduxjs/toolkit";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { ExpressionErrorList } from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorTrainer/ExpressionErrorList";
import { ExpressionRecord } from "reducers/expressions/types";
import { ResponseRecord } from "reducers/responses/types";
import { pluralize } from "services/pluralize";

import { handleAddBulkTrainingAction } from "./actions";
import "./style.scss";

const BULK_TRAINING_LIMIT = 20;

export const BLOCK_NAME = "ModalBulkAddTraining";

export class ModalBulkAddTraining extends React.Component {
  static stripEmptyLines(textareaContent) {
    const trainingData = textareaContent.split("\n");

    return trainingData.filter((expr) => expr !== "");
  }

  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    this.onBulkTrainButtonClick = this.onBulkTrainButtonClick.bind(this);
    this.onChangeTextarea = this.onChangeTextarea.bind(this);

    this.bulkUploadRef = React.createRef();

    this.state = {
      canTrain: false,
    };
  }

  /** */
  componentDidMount() {
    if (this.bulkUploadRef.current) {
      this.bulkUploadRef.current.focus();
    }
  }

  /**
   * onclick handler for bulk training button
   */
  onBulkTrainButtonClick() {
    const { response, handleAddBulkTraining } = this.props;

    const trainingData = ModalBulkAddTraining.stripEmptyLines(
      this.bulkUploadRef.current.value,
    );

    handleAddBulkTraining(response, trainingData);
  }

  /**
   * validates the input from the textarea
   */
  onChangeTextarea() {
    const trainingData = ModalBulkAddTraining.stripEmptyLines(
      this.bulkUploadRef.current.value,
    );
    const isOverLimit = trainingData.length > BULK_TRAINING_LIMIT;

    this.setState({
      canTrain: trainingData.length > 0 && !isOverLimit,
    });
  }

  /**
   * Displays errors that might have occurred during bulk training.
   * Here, errors can either be actionable by the user (if one or more questions are already trained
   * on other answers), or not actionable (if some other, internal api error occurred)
   *
   * @returns {ReactElement|null}
   */
  renderErrorListContainer() {
    const { errors } = this.props;

    if (errors.length === 0) {
      return null;
    }

    const alreadyTrainedExpressions = errors
      .filter((error) => error.type === "already_trained")
      .map((error) => new ExpressionRecord({ body: error.body }));

    const otherExpressions = errors
      .filter((error) => error.type !== "already_trained")
      .map((error) => new ExpressionRecord({ body: error.body }));

    const alreadyTrainedErrorMessage =
      "The following questions already exist on other answers. Please remove from the list below and try again.";
    const otherErrorMessage =
      "The following questions could not be trained - please try again.";

    return (
      <>
        <ExpressionErrorList
          errorExpressions={alreadyTrainedExpressions}
          errorMessage={alreadyTrainedErrorMessage}
        />
        <ExpressionErrorList
          errorExpressions={otherExpressions}
          errorMessage={otherErrorMessage}
        />
      </>
    );
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const { closeModal } = this.props;
    const { canTrain } = this.state;
    let trainingData = [];

    if (this.bulkUploadRef.current) {
      trainingData = ModalBulkAddTraining.stripEmptyLines(
        this.bulkUploadRef.current.value,
      );
    }

    const isOverLimit = trainingData.length > BULK_TRAINING_LIMIT;

    const tooltipBlurb = `You may only train up to ${BULK_TRAINING_LIMIT} ${pluralize(
      BULK_TRAINING_LIMIT,
      "question",
    )} at a time.`;
    const rowsRemaining = BULK_TRAINING_LIMIT - trainingData.length;

    return (
      <div className={`${BLOCK_NAME} Modal__modal`}>
        <h5 className="Modal__title">Bulk Add Training</h5>
        <div className="Modal__content">
          <div className={`${BLOCK_NAME}__bulk-upload`}>
            {this.renderErrorListContainer()}
            <span className={`${BLOCK_NAME}__input-label g-input__label`}>
              Add Training Questions
            </span>
            {isOverLimit && (
              <InfoTooltip
                customClassName={`${BLOCK_NAME}__overlimit-warning-icon`}
                blurb={tooltipBlurb}
                iconDefault="WarningYellow"
              />
            )}
            <textarea
              className={classNames(
                `${BLOCK_NAME}__bulk-upload-input g-input`,
                {
                  "g-input--invalid": isOverLimit,
                },
              )}
              placeholder="Paste a list of training questions or multiple rows of a spreadsheet"
              ref={this.bulkUploadRef}
              onChange={this.onChangeTextarea}
            />
            <div
              className={classNames(`${BLOCK_NAME}__character-counter`, {
                [`${BLOCK_NAME}__character-counter--invalid`]: isOverLimit,
              })}
            >
              {`${rowsRemaining} ${pluralize(rowsRemaining, "row")}`}
            </div>
          </div>
          <div className={`${BLOCK_NAME}__hint`}>
            Paste in up to {BULK_TRAINING_LIMIT}
            &nbsp;{pluralize(BULK_TRAINING_LIMIT, "row")} from a spreadsheet or
            separate training with line breaks.
          </div>
        </div>
        <div className="Modal__bottom Modal__bottom--right">
          <Button
            onClick={closeModal}
            customClassName={`${BLOCK_NAME}__cancel-button`}
            title="Cancel"
            text="Cancel"
            tabIndex={0}
            light
          />
          <Button
            onClick={this.onBulkTrainButtonClick}
            className={`${BLOCK_NAME}__bulk-train-button`}
            title="Train"
            text="Train"
            icon="CircleAdd"
            disabled={!canTrain}
            tabIndex={canTrain ? 0 : -1}
            iconLarge
          />
        </div>
      </div>
    );
  }
}

ModalBulkAddTraining.propTypes = {
  response: PropTypes.instanceOf(ResponseRecord).isRequired,
  handleAddBulkTraining: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array,
};

ModalBulkAddTraining.defaultProps = {
  errors: [],
};

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapStateToProps(state) {
  const { handleAddBulkTraining } = state;

  return {
    handleAddBulkTraining,
  };
}

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeModal: closeModalAction,
      handleAddBulkTraining: handleAddBulkTrainingAction,
    },
    dispatch,
  );
}

const Connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalBulkAddTraining);

export default Connector;
