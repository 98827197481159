import Immutable from "immutable";

interface AlternativeBot {
  handle: string;
  rollout: number;
}

interface LaunchControls {
  showPrimaryBot: number;
  showAlternativeBot: number;
  showNoBot: number;
}

class ChatPlatformRecord extends Immutable.Record<{
  name: "chat";
  fullName: "Chat";
  rollout: number | null;
  alternativeBot: null | Immutable.Map<
    keyof AlternativeBot,
    AlternativeBot[keyof AlternativeBot]
  >;
  alternativeBotPreviewLink: string | null;
  launchControls: Immutable.Map<keyof LaunchControls, number>;
  timeToExpiry: number;
  enabled: boolean;
  showLogo: boolean;
  privacy: boolean;
  privacyLink: string;
  persistence: string;
  expiryTime: string;
  expiryUnitOfTime: string;
  buttonCarousel: string;
  buttonLayoutMobile: string;
  buttonLayoutDesktop: string;
  isVisibleToClient: () => boolean;
}>({
  name: "chat",
  fullName: "Chat",
  rollout: 1.0,
  alternativeBot: null,
  alternativeBotPreviewLink: null,
  launchControls: Immutable.Map({
    showPrimaryBot: 0,
    showAlternativeBot: 0,
    showNoBot: 0,
  }) as Immutable.Map<keyof LaunchControls, number>,
  enabled: true,
  showLogo: false,
  privacy: false,
  privacyLink: "",
  persistence: "normal",
  timeToExpiry: 0,
  expiryTime: "",
  expiryUnitOfTime: "mins",
  buttonCarousel: "none",
  buttonLayoutMobile: "carousel",
  buttonLayoutDesktop: "stacked",

  isVisibleToClient(): boolean {
    return true;
  },
}) {}

export { ChatPlatformRecord };
