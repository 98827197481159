import { Button } from "@adasupport/byron";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import isURL from "validator/lib/isURL";

import { closeModalAction } from "actions/modal";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { useClient } from "services/client";

import * as S from "./styles";

interface Props {
  isNew: boolean;
  path: string;
}

export function ModalAddEditDomain(props: Props) {
  const { isNew, path } = props;

  const dispatch = useDispatch();
  const { client, updateClient, saveClientAndAlert } = useClient();

  const [newPath, setNewPath] = useState<string>(path);

  const iframeAllowList = client.securitySettings.chat.iframe_allow_list;

  const pathAlreadyInUse = (updatedPath: string | undefined): boolean =>
    iframeAllowList.some((listPath) => listPath === updatedPath);

  const isValidPath = (updatedPath: string | undefined): boolean => {
    if (!updatedPath) {
      return false;
    }

    // We want to allow wildcard characters only as a subdomain
    const escapedPath = updatedPath.replace(
      /^(http|https):\/\/(\*)/,
      "$1://valid",
    );
    const isValidURL = isURL(escapedPath, {
      require_tld: false,
      require_protocol: true,
      require_valid_protocol: true,
      protocols: ["http", "https", "HTTP", "HTTPS"],
    });

    if (updatedPath.indexOf(" ") !== -1 || !isValidURL) {
      return false;
    }

    // If its a valid url - we want to make sure it doesn't contain a path
    if (updatedPath !== "") {
      const url = new URL(updatedPath);

      if (url.pathname !== "/" || url.search !== "") {
        return false;
      }
    }

    return true;
  };

  const updateNewPath = (updatedPath: string) => {
    setNewPath(updatedPath);
  };

  const savePath = () => {
    if (!newPath) {
      return;
    }

    let allowList;

    if (isNew) {
      // If this is a new path, just append it to the current list,
      allowList = [...iframeAllowList, newPath];
      setNewPath("");
    } else {
      // else find where the path exists in the current list and update it
      allowList = iframeAllowList.map((allowedPath) => {
        if (allowedPath === path) {
          return newPath;
        }

        return allowedPath;
      });
    }

    updateClient({
      securitySettings: {
        ...client.securitySettings,
        chat: {
          ...client.securitySettings.chat,
          iframe_allow_list: allowList,
        },
      },
    });

    saveClientAndAlert("Your domain list has been updated.");
  };

  const isPathInUse = pathAlreadyInUse(newPath);
  const isInvalidPath = !isValidPath(newPath);

  /*
    If the user has entered a path (or a different path if they are editing)
    and the path is either invalid or already in use we want to show an error. Otherwise
    the modal may have just been opened and they haven't added a path or edited the
    existing path and we don't want to show an error yet.
  */
  const shouldShowAlert =
    (isInvalidPath || isPathInUse) && newPath && newPath !== path;

  const getAlertText = () => {
    if (isPathInUse) {
      return "You have already authorized this domain.";
    }

    return "This URL's formatting is invalid.";
  };

  const disableSave = isInvalidPath || isPathInUse || !newPath;

  return (
    <div className="Modal__modal Modal__modal--small">
      <div className="Modal__content">
        <S.ModalSection>
          <div>
            <S.ModalTitle>
              {isNew ? "Add" : "Edit"} An Authorized Domain
            </S.ModalTitle>
          </div>
        </S.ModalSection>

        <S.ModalSection>
          <S.Example>
            Add domains with just the top level domain, without a page path.
            <span>
              Eg: <S.Highlight>http://www.domain.com</S.Highlight>
            </span>
          </S.Example>

          <S.Example>
            If you have many subdomains, add a single entry using an asterisk in
            place of the subdomain.
            <span>
              Eg: <S.Highlight>http://*.domain.com</S.Highlight>{" "}
            </span>
          </S.Example>
        </S.ModalSection>

        <S.ModalSection>
          <div>
            <label className="g-input__label" htmlFor="AllowList-URL-input">
              <span>Domain URL</span>
            </label>
          </div>
          <DebouncedInput
            id="AllowList-URL-input"
            autoComplete="off"
            autoCorrect="off"
            customClassName="AllowList__content__input-container__input"
            placeholder="e.g., https://*.domain.com"
            value={newPath}
            debounceTime={50}
            onChange={updateNewPath}
            isInvalid={isInvalidPath || isPathInUse}
            forceValueRefresh
          />
          {shouldShowAlert && <S.Alert>{getAlertText()}</S.Alert>}
        </S.ModalSection>
      </div>

      <S.ButtonsContainer className="Modal__bottom">
        <Button
          variant="secondary"
          onClick={() => {
            dispatch({ type: "MODAL_ALLOW_CLOSE" });
            dispatch(closeModalAction());
          }}
          text="Cancel"
        />

        <Button
          variant="primary"
          onClick={() => {
            savePath();
            dispatch({ type: "MODAL_ALLOW_CLOSE" });
            dispatch(closeModalAction());
          }}
          text={isNew ? "Add" : "Save"}
          isDisabled={disableSave}
        />
      </S.ButtonsContainer>
    </div>
  );
}
