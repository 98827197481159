import Immutable from "immutable";

import { type ClientLegacy } from "resourceModels";

export class VoicePlatformRecord extends Immutable.Record({
  name: "voice",
  fullName: "Voice",
  phoneNumber: "",
  smsPhoneNumber: "",
  useDifferentNumberForSms: false,
  fallbackPhoneNumber: "",
  maxRepetitions: 2, // cant change this
  enabled: false,
  longSilenceWaitSeconds: 10,
  voiceName: "en-US-Wavenet-H",
  twilioApiKeySid: "",
  twilioApiKeySecret: "",
  twilioAccountSid: "",
  phoneNumberOrSip: "phone_number",
  twilioSipDomain: "",
  fallbackSipAddress: "",
  ttsProvider: "google",

  isVisibleToClient(client: ClientLegacy): boolean {
    return client.features.get("experimentVoice") || false;
  },
}) {}
