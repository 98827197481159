import Immutable from "immutable";

class MessengerPlatformRecord extends Immutable.Record({
  name: "messenger",
  fullName: "Messenger",
  accessToken: "",
  enabled: true,

  isVisibleToClient(): boolean {
    return true;
  },
}) {}

export { MessengerPlatformRecord };
