/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-classes-per-file */

import Immutable from "immutable";

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
export interface ExpressionShape {
  id: string | null;
  active: boolean;
  bestGuess: string | null;
  body: string | null;
  bodySimple: string | null;
  clientId: string | null;
  confidence: any;
  chatterId: any;
  conversationId: any;
  created: any;
  origin: any;
  responseId: string | null;
  updated: any;
  predictions: any;
  language: string | null;
  preTranslationBody: string | null;
}

export const ExpressionRecordDefault: ExpressionShape = {
  id: null,
  active: true,
  bestGuess: null,
  body: null,
  bodySimple: null,
  clientId: null,
  confidence: null,
  chatterId: null,
  conversationId: null,
  created: null,
  origin: null,
  responseId: null,
  updated: null,
  predictions: null,
  language: null,
  preTranslationBody: null,
};
export class ExpressionRecord extends Immutable.Record(
  ExpressionRecordDefault,
) {}

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
export interface ExpressionState {
  loaded: Immutable.List<ExpressionRecord>;
  lastExpressionId: string | null;
  loadedCount: number;
  loading: boolean;
  error: boolean;
  queryCount: number;
  scrolling: boolean;
  groupTitle: string;
  keywords: string[];
  moreAvailable: boolean;
  lastSort: any[];
}

export const defaultExpressionState: ExpressionState = {
  loaded: Immutable.List([] as ExpressionRecord[]),
  lastExpressionId: null,
  loadedCount: 0,
  loading: false,
  error: false,
  queryCount: 0,
  scrolling: false,
  groupTitle: "",
  keywords: [],
  moreAvailable: false,
  lastSort: [],
};

export class ExpressionsState extends Immutable.Record(
  defaultExpressionState,
) {}

// TODO: replace `any` with proper types - intentionally left by TS conversion initiative
export interface UnansweredQuestionsDto {
  expression_id: string;
  body: string;
  chatter_id: string;
  conversation_id: string;
  updated: string;
  prediction_ids: any;
}
