import { Select } from "@adasupport/byron";
import React from "react";

import { ListVariableOperatorSelector } from "components/Common/RulesetEditor/ListVariableOperatorSelector";
import {
  RULE_DSL,
  type RuleCondition,
  type RuleDefinition,
} from "services/rules";
import { useVariables } from "services/variables";

import {
  BOOLEAN_OPERATOR_OPTIONS,
  LIST_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  STRING_OPERATOR_OPTIONS,
  getDefaultRightArgumentValue,
} from "./helpers";

export const RuleOperatorSelector = <R extends RuleCondition>({
  rule,
  onChange,
  isDisabled,
}: {
  rule: R;
  onChange: (rule: R) => void;
  isDisabled?: boolean;
}) => {
  const { variables } = useVariables();

  const [leftArg, rightArg, matchCase] = rule.args;
  const selectedVariable = variables?.find((v) => v._id === leftArg.id);

  const operatorOptions = (() => {
    switch (selectedVariable?._type) {
      case "bool":
        return BOOLEAN_OPERATOR_OPTIONS;
      case "long":
        return NUMBER_OPERATOR_OPTIONS;
      case "list":
        return LIST_OPERATOR_OPTIONS;
      default:
        return STRING_OPERATOR_OPTIONS;
    }
  })();

  const updateValue = (value: string) => {
    if (value === RULE_DSL.IS_SET || value === RULE_DSL.IS_NOT_SET) {
      onChange({
        ...rule,
        name: value,
        // Remove right argument if operator changes to unary (IS_SET, IS_NOT_SET)
        args: [leftArg],
      });

      return;
    }

    // Update right argument depending on new selected variable type
    const newRightArg = getDefaultRightArgumentValue(
      selectedVariable?._type,
      value as RuleDefinition["name"],
      rightArg,
    );

    onChange({
      ...rule,
      name: value,
      args:
        matchCase !== undefined
          ? [leftArg, newRightArg, matchCase]
          : [leftArg, newRightArg],
    });
  };

  if (selectedVariable?._type === "list") {
    return (
      <ListVariableOperatorSelector
        value={rule.name}
        onChange={updateValue}
        isDisabled={isDisabled}
      />
    );
  }

  return (
    <Select
      dataTestId="operator"
      isDisabled={isDisabled}
      value={rule.name}
      onChange={updateValue}
      options={operatorOptions}
    />
  );
};
