import styled from "styled-components";

import { colorPrimitives, colorTokens, v } from "constants/css";

export const ActionButton = styled.button<{ intent?: "primary" | "alert" }>`
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 46px;
  box-shadow: none;
  font-size: ${v.mainFontSize};
  font-weight: ${v.mediumFontWeight};
  border-top: 1px solid ${colorTokens.borderButton};
  background-color: ${colorPrimitives.white};
  cursor: pointer;
  transition: all ${v.transitionTime} ease;

  color: ${(p) => {
    if (p.intent === "primary") {
      return colorTokens.actionPrimaryText;
    }

    if (p.intent === "alert") {
      return colorTokens.statusNegative;
    }

    return colorTokens.textMain;
  }};

  &:active {
    background-color: ${colorPrimitives.slate200};
  }

  &:last-child {
    border-radius: 0 0 ${v.mainBorderRadius} ${v.mainBorderRadius};
  }
`;
