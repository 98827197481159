export const NESTABLE_MESSAGE_TYPES = ["conditionals_block", "scheduled_block"];

export const MESSAGING_MODALITY = "messaging" as const;
export const VOICE_MODALITY = "voice" as const;

export type Modality = typeof MESSAGING_MODALITY | typeof VOICE_MODALITY;
export const ALL_MODALITIES: Modality[] = [MESSAGING_MODALITY, VOICE_MODALITY];

export interface ModalityDetails {
  displayName: string;
  liveField: "live" | "liveVoice";
  messageField: "messages" | "messagesVoice";
  liveFieldToggledOnMessage: string;
  liveFieldToggledOffMessage: string;
  apiLiveField: "live" | "live_voice";
}

export const MODALITY_DETAILS: Record<Modality, ModalityDetails> = {
  [MESSAGING_MODALITY]: {
    displayName: "Messaging",
    liveField: "live",
    apiLiveField: "live",
    messageField: "messages",
    liveFieldToggledOnMessage: "Edit to Answer visibility: live",
    liveFieldToggledOffMessage: "Edit to Answer visibility: hidden",
  },
  [VOICE_MODALITY]: {
    displayName: "Voice",
    liveField: "liveVoice",
    apiLiveField: "live_voice",
    messageField: "messagesVoice",
    liveFieldToggledOnMessage: "Edit to Voice Answer visibility: live",
    liveFieldToggledOffMessage: "Edit to Voice Answer visibility: hidden",
  },
};

export const BLOCK_TYPES = [
  "action",
  "action_integration",
  "message",
  "operation",
  "note",
  "event",
  "handoff_integration",
  "generative_handoffs",
] as const;
