// @ts-strict-ignore
import Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { BuiltInBusinessEvents } from "components/Declarative/Pages/BusinessEvents/constants";
import { TAG_REGEX } from "constants/tags";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
  type InvalidFieldsGetter,
} from "../BaseMessageRecord";

function validTags(string: string): boolean {
  const variableRegex = /{([\w]+)\|([^}]*?)}/g;
  const emptyString = "";

  return (
    string
      .replace(variableRegex, emptyString)
      .replace(TAG_REGEX, emptyString)
      .replace(/ /g, emptyString) !== emptyString
  );
}

interface Attributes extends BaseMessageRecordAttributes {
  type: "zendesk_live_agent";
  isLiveAgentHandoff: true;
  fallbackResponseId: string | null;
  useZdAuth: boolean;
  authErrorResponseId: string | null;
  departmentId: string | null;
  departmentName: string | null;
  tags: string;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export class ZendeskLiveAgentMessageRecord extends BaseMessageRecord<Attributes>(
  {
    type: "zendesk_live_agent",
    isLiveAgentHandoff: true,
    fallbackResponseId: null,
    authErrorResponseId: null,
    departmentId: null,
    departmentName: null,
    useZdAuth: false,
    tags: "",
    successBusinessEvent: new FireableBusinessEvent({
      value: "",
      eventKey: BuiltInBusinessEvents.DEFAULT_HANDOFF_SUPPORT,
      isVariable: false,
    }),
  },
) {
  static getInvalidFields: InvalidFieldsGetter<ZendeskLiveAgentMessageRecord> =
    (message) => {
      const invalidFields: Set<string> = new Set();

      if (!message.departmentId) {
        invalidFields.add("departmentId");
      }

      if (!message.fallbackResponseId) {
        invalidFields.add("fallbackResponseId");
      }

      if (message.useZdAuth && !message.authErrorResponseId) {
        invalidFields.add("authErrorResponseId");
      }

      if (message.tags && validTags(message.tags)) {
        invalidFields.add("tags");
      }

      return Immutable.List(invalidFields);
    };
}
