import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  deletePlatform as deletePlatformAction,
  saveClientPlatform as saveClientPlatformAction,
  updatePlatform as updatePlatformAction,
} from "actions";
import { Button } from "components/Common/Button";
import { InputPassword } from "components/Common/InputPassword";
import { SmsPlatformRecord } from "reducers/platforms/models";
import "./style.scss";

class SettingsPlatformsSmsModal extends React.Component {
  static propTypes = {
    updatePlatform: PropTypes.func.isRequired,
    deletePlatform: PropTypes.func.isRequired,
    saveClientPlatform: PropTypes.func.isRequired,
    platform: PropTypes.instanceOf(SmsPlatformRecord).isRequired,
  };

  updatePlatform(payload) {
    const { updatePlatform } = this.props;

    updatePlatform("sms", payload);
  }

  render() {
    const { deletePlatform, platform, saveClientPlatform } = this.props;
    const isButtonDisabled =
      (platform.get("isPublished") && !platform.get("isDirty")) ||
      !platform.get("isValid");

    return (
      <div className="SettingsPlatformsSmsModal Modal__modal">
        <h5 className="Modal__title">Configure Twilio SMS</h5>
        <div className="Modal__content">
          <section className="Modal__section">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformsSmsModal__account-sid"
              >
                Account SID
              </label>
              <p className="Modal__section__sub-section__description">
                This comes from your Twilio account.
              </p>
              <input
                className="SettingsPlatformsSmsModal__account-sid g-input"
                type="text"
                value={platform.get("record").authId}
                placeholder="Account SID"
                id="SettingsPlatformsSmsModal__account-sid"
                onChange={(e) =>
                  this.updatePlatform({ authId: e.target.value })
                }
              />
            </section>
          </section>
          <section className="Modal__section">
            <section className="Modal__section__sub-section">
              <label
                className="Modal__section__sub-section__title g-input__label"
                htmlFor="SettingsPlatformsSmsModal__secret-key"
              >
                Account Secret Key
              </label>
              <p className="Modal__section__sub-section__description">
                The secret key for your Twilio account.
              </p>
              <InputPassword
                value={platform.get("record").authKey}
                placeholder="Account Secret Key"
                inputId="SettingsPlatformsSmsModal__secret-key"
                customClassName="SettingsPlatformsSmsModal__account-key"
                onPasswordChange={(e) =>
                  this.updatePlatform({ authKey: e.target.value })
                }
              />
            </section>
          </section>
        </div>
        <div className="Modal__bottom SettingsPlatformsSmsModal__bottom">
          <Button
            onClick={() => saveClientPlatform(platform)}
            text="Save"
            title="Save"
            icon="Cloud"
            disabled={isButtonDisabled}
            isLoading={platform.get("isLoading")}
            customClassName="SettingsPlatformsSmsModal__bottom__save"
          />
          {!!platform.get("isPublished") && (
            <Button
              onClick={() => deletePlatform(platform)}
              text="Delete"
              title="Delete"
              icon="Trash"
              light
              customClassName="SettingsPlatformsSmsModal__bottom__delete"
            />
          )}
        </div>
      </div>
    );
  }
}

/**
 * @param {State} state
 */
function mapState(state) {
  const { platforms } = state;

  return {
    platforms,
    platform: platforms.get("sms"),
  };
}

/**
 * @param {Dispatch} dispatch
 */
function mapDispatch(dispatch) {
  return {
    saveClientPlatform: bindActionCreators(saveClientPlatformAction, dispatch),
    deletePlatform: bindActionCreators(deletePlatformAction, dispatch),
    updatePlatform: bindActionCreators(updatePlatformAction, dispatch),
  };
}

const Connector = connect(mapState, mapDispatch)(SettingsPlatformsSmsModal);

export default Connector;
