import axios from "axios";
import { push } from "connected-react-router";
import type Immutable from "immutable";
import React from "react";

import { createAlert } from "actions/alerts";
import { closeModal, closeModalAction, openModalAction } from "actions/modal";
import { fetchMany } from "actions/resources";
import {
  type IdAndHandle,
  type ResponseReferencesInfo,
} from "actions/responses/types";
import { type ThunkAction } from "actions/types";
import {
  deleteMessageVariables,
  deleteResponseVariables,
  getGroomedVariables,
} from "actions/variables";
import { toAPI } from "adapters/message";
import { CampaignReferenceList } from "components/Common/ModalWarning/CampaignReferenceList";
import {
  ReferenceList,
  type ResponseReferencesList,
} from "components/Common/ModalWarning/ReferenceList";
import {
  CREATED_ANSWER_DESCRIPTION,
  DELETED_ANSWER_DESCRIPTION,
  EDIT_TO_ANSWER_CONTENT,
  EDIT_TO_ANSWER_SETTINGS,
  RESTORED_ANSWER_DESCRIPTION,
} from "components/Shared/Pages/Responses/ResponseVersions/constants";
import { type Modality } from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { saveBuilderABTestAction } from "features/ABTesting/actionsUsedInResponseActions";
import {
  getResponseABTest,
  trackABTestInvalidState,
} from "features/ABTesting/services";
import { createMessageRecord } from "reducers/responses/reducer";
import {
  type MessageRecordOptions,
  type ResponseAttributesShape,
  type ResponseRecord,
} from "reducers/responses/types";
import { getSavedResponse } from "reducers/responsesLoaded/reducer";
import {
  BaseQuickReplyInBlock,
  getMessageIndex,
} from "reducers/responsesLoaded/reducerHelpers";
import { adaApiRequest } from "services/api";
import { selectClient } from "services/client";
import { NO_OP_FUNCTION } from "services/helpers";
import { keyConverter } from "services/key-converter";
import { type KeyPath } from "services/responses";
import {
  getActiveMessages,
  getBaseKeyPath,
} from "services/responses/selectors";
import { selectActiveResponseById } from "services/responses/selectors/selectActiveResponseById";
import { type Variable } from "slices/variables/types";

export interface BotContentRequest {
  resource: string;
  method: string;
  data: Variable | ResponseRecord | Record<string, unknown>;
}

export function updateActiveMessages(
  responseId: string,
  newMessages: Immutable.List<unknown>,
): ThunkAction {
  return (dispatch, getState) => {
    dispatch({
      type: "UPDATE_RESPONSE_DEEP",
      keyPath: getBaseKeyPath(getState()),
      payload: newMessages,
      responseId,
    });
  };
}

// TODO: I believe this can be simplified by just calling keyConverter on `messages` instead of iterating through the keys
/**
 * Transform frontend representation of messages to API-compatible messages.
 */
export function serializeResponseMessages(
  messages: Record<string, Record<string, unknown>>,
) {
  // Deep clone messages to avoid mutation
  const newMessages = JSON.parse(JSON.stringify(messages));

  Object.keys(messages).forEach((messageLanguage) => {
    newMessages[messageLanguage] = keyConverter(
      newMessages[messageLanguage],
      "underscore",
    );
  });

  return newMessages;
}

interface AddQuickReplyToBlockActionParams {
  responseId: string;
  responseIdToAdd: string;
  messageId: string;
}

export function addQuickReplyToBlockAction({
  responseId,
  responseIdToAdd,
  messageId,
}: AddQuickReplyToBlockActionParams): ThunkAction {
  return (dispatch, getState) => {
    const messages = getActiveMessages(getState());
    const optionToPush = new BaseQuickReplyInBlock({ target: responseIdToAdd });

    const messageIndex = getMessageIndex(messages, messageId);

    const newMessages = messages.updateIn(
      [messageIndex, "quickReplies"],
      (arr: Immutable.List<unknown>) => arr.push(optionToPush),
    );

    dispatch(updateActiveMessages(responseId, newMessages));
  };
}

interface CheckTrainingErrorsParams {
  responseId: string;
  versionId: string;
  stagedTraining?: boolean;
  callback?: () => unknown;
}

export function checkTrainingErrors({
  responseId,
  versionId,
  stagedTraining = false,
  callback = NO_OP_FUNCTION,
}: CheckTrainingErrorsParams): ThunkAction {
  return async (dispatch) => {
    try {
      await dispatch(
        adaApiRequest({
          method: "GET",
          url: `/responses/${responseId}/versions/${versionId}/training_conflicts`,
        }),
      );

      if (!stagedTraining) {
        return dispatch(
          openModalAction("MODAL_RESTORE_TRAINING_WARNING", {
            responseId,
            versionId,
          }),
        ) as never;
      }

      return callback();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 409) {
          return dispatch(
            openModalAction("MODAL_RESTORE_TRAINING_ERROR", {
              errors: error.response.data.errors,
              responseId,
              versionId,
              callback,
            }),
          ) as never;
        }
      }

      return error as never;
    }
  };
}

function responseContentEqual(currentResponse: ResponseRecord) {
  const savedResponse = getSavedResponse(currentResponse.id);

  if (!savedResponse) {
    return false;
  }

  return currentResponse.messages.equals(savedResponse.messages);
}

function getVersionDescription(response: ResponseRecord) {
  if (response.restoringFromVersionId) {
    return RESTORED_ANSWER_DESCRIPTION;
  }

  if (responseContentEqual(response)) {
    return EDIT_TO_ANSWER_SETTINGS;
  }

  return EDIT_TO_ANSWER_CONTENT;
}

export function saveBatchBotContent(
  response: ResponseRecord,
  shouldCheckTrainingErrors = true,
): ThunkAction<Promise<SaveResponseResult>> {
  return async (dispatch, getState) => {
    const updatedResponse = getState().responsesLoaded.get(response.id);
    const groomedVariables = getGroomedVariables(response.id, getState());

    if (!updatedResponse) {
      throw new Error("`updatedResponse` is undefined");
    }

    const versionDescription = getVersionDescription(updatedResponse);

    if (response.restoringFromVersionId && shouldCheckTrainingErrors) {
      return dispatch(
        checkTrainingErrors({
          responseId: response.id,
          versionId: response.restoringFromVersionId,
          stagedTraining: true,
          callback: () => dispatch(saveBatchBotContent(response, false)),
        }) as ThunkAction<SaveResponseResult>,
      );
    }

    dispatch({
      type: "SAVE_BOT_CONTENT_REQUEST",
      responseId: updatedResponse.id,
    });

    const updates = [
      {
        resource: "responses",
        method: response.restoringFromVersionId ? "POST" : "PATCH",
        data: {
          _id: updatedResponse.id,
          button_label: updatedResponse.buttonLabel,
          clarifications: updatedResponse.clarifications,
          created: updatedResponse.created,
          handle: updatedResponse.handle,
          has_forced_quick_replies: updatedResponse.hasForcedQuickReplies,
          description: updatedResponse.description,
          messages: serializeResponseMessages(
            toAPI(updatedResponse.messages).toJS() as Record<
              string,
              Record<string, unknown>
            >,
          ),
          messages_voice: serializeResponseMessages(
            toAPI(updatedResponse.messagesVoice).toJS() as Record<
              string,
              Record<string, unknown>
            >,
          ),
          rating: updatedResponse.rating,
          reserved: updatedResponse.reserved,
          tags: updatedResponse.tags,
          reviewable: updatedResponse.reviewable,
          updated: updatedResponse.updated,
          live: updatedResponse.live,
          live_voice: updatedResponse.liveVoice,
        },
      },
      ...groomedVariables,
    ];

    if (response.parentId) {
      updates.push({
        method: "PATCH",
        resource: "folders",
        data: {
          _id: response.parentId,
          response_id: response.id,
        },
      });
    }

    if (response.restoringFromVersionId) {
      updates.push({
        method: "RESTORE_TRAINING",
        data: {
          response_id: updatedResponse.id,
          version_id: response.restoringFromVersionId,
        },
        resource: "expressions",
      });
    }

    try {
      const res = await dispatch(
        adaApiRequest<{
          data: {
            response: { _id: string };
            responses: unknown;
            variables: unknown;
            folders: unknown;
          };
        }>({
          method: "post",
          url: "/bot-content/batch",
          data: {
            updates,
            ada__versioning__generated_description: versionDescription,
          },
        }),
      );
      dispatch({
        type: "SAVE_BOT_CONTENT_SUCCESS",
        responseId: updatedResponse.id,
        responses: res.data.data.responses,
        variables: res.data.data.variables,
      });
      dispatch(
        createAlert({
          message: `${updatedResponse.handle} was successfully updated.`,
          alertType: "success",
        }),
      );
      dispatch(closeModal());
      dispatch(
        fetchMany("responseSideBarItem", getState().responsesPage.query),
      );

      dispatch({
        type: "SAVE_FOLDER_NODE_BOT_CONTENT_SUCCESS",
        responseId: updatedResponse.id,
        responses: res.data.data.responses,
        folders: res.data.data.folders,
      });

      // Must also save AB test if it exists - number of variants may have changed
      const abTest = getResponseABTest(
        response.id,
        getState().builderABTestsState.builderABTests,
      );

      if (abTest) {
        dispatch(saveBuilderABTestAction(abTest));
      }

      return res as unknown as SaveResponseResult;
    } catch (error) {
      try {
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.data
        ) {
          /**
           * Show useful error message by selecting first error.
           * There may be multiple since the batch endpoint is potentially
           * modifying multiple resources.
           */
          const { data } = error.response.data;
          const resourceType = Object.keys(data)[0] || "";
          const resourceId = Object.keys(data[resourceType])[0] || "";
          const message = data[resourceType][resourceId];
          dispatch({ type: "SAVE_BOT_CONTENT_FAILURE" });
          dispatch(createAlert({ message }));
        }
      } catch (e) {
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.data
        ) {
          // fallback to regular error message if we don't get the format we're expecting
          dispatch(createAlert({ message: error.response.data.message }));
        }
      }

      trackABTestInvalidState(
        response.id,
        getState().builderABTestsState.builderABTests,
        response.messages,
      );

      // TODO: throw instead of return here once all calls are rewritten to handle request failures
      return error as never;
    }
  };
}

export interface SaveResponseOptions {
  noNavigateOnCreation?: boolean;
}

export interface SaveResponseResult {
  data: {
    response: { _id: string };
    responses?: unknown;
    folders?: unknown;
    variables?: unknown;
  };
}

export function saveResponse(
  response: ResponseRecord,
  options: SaveResponseOptions = {},
): ThunkAction<Promise<SaveResponseResult>> {
  return async (dispatch, getState) => {
    // *** NEW ANSWER CASE ***
    if (response.new) {
      dispatch({
        type: "CREATE_RESPONSE_REQUEST",
        oldId: response.id,
      });

      try {
        const res = await dispatch(
          adaApiRequest<{
            response: {
              _id: string;
            };
          }>({
            method: "POST",
            url: "/responses/",
            data: {
              _id: response.id,
              parent_id: response.parentId,
              button_label: response.buttonLabel,
              clarifications: response.clarifications,
              created: response.created,
              handle: response.handle,
              description: response.description,
              messages: serializeResponseMessages(
                toAPI(response.messages).toJS() as Record<
                  string,
                  Record<string, unknown>
                >,
              ),
              messages_voice: serializeResponseMessages(
                toAPI(response.messages).toJS() as Record<
                  string,
                  Record<string, unknown>
                >,
              ),
              new: response.new,
              rating: response.rating,
              reserved: response.reserved,
              tags: response.tags,
              updated: response.updated,
              reviewable: response.reviewable,
              live: response.live,
              ada__versioning__generated_description:
                CREATED_ANSWER_DESCRIPTION,
            },
          }),
        );

        dispatch({
          type: "CREATE_RESPONSE_SUCCESS",
          response: res.data.response,
          oldId: response.id,
        });

        dispatch({
          type: "CREATE_FOLDER_SUCCESS",
          folder: res.data.response,
        });

        if (res.status === 201) {
          // Ideally, this should not be passed as an option - instead, navigating to the new page
          // should be done as a callback at the place where saveResponse is called.
          if (!options.noNavigateOnCreation) {
            dispatch(push(`/answers/${res.data.response._id}`));
          }
        }

        // Ideally closeModal should be done as a callback when necessary at the place where
        // saveResponse is called.
        dispatch(closeModal());
        dispatch(
          createAlert({
            message: `${response.handle} was successfully created.`,
            alertType: "success",
          }),
        );
        dispatch(
          fetchMany("responseSideBarItem", getState().responsesPage.query),
        );

        return res;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 400) {
            dispatch({ type: "CREATE_RESPONSE_FAILURE" });
            dispatch(createAlert({ message: error.response.data.message }));
          }
        }

        throw error;
      }
    }

    return dispatch(saveBatchBotContent(response));
  };
}

/**
 * Save a draft response by its id.
 * The response should be loaded within responsesLoaded state.
 */
export function saveDraftResponseById(
  responseId: string,
  options: SaveResponseOptions = {},
): ThunkAction {
  return async (dispatch, getState) => {
    const draftResponse = selectActiveResponseById(getState(), responseId);

    if (!draftResponse) {
      return;
    }

    dispatch(saveResponse(draftResponse, options));
  };
}

export function updateResponse(
  responseId: string,
  payload: Record<string, unknown>,
) {
  return {
    type: "UPDATE_RESPONSE",
    responseId,
    payload,
  } as const;
}

interface CreateMessageArgs {
  responseId: string;
  message: Partial<MessageRecordOptions>;
  index: number;
}

/**
 * Create a new `message` at `keyPath`
 * @param {Object} p
 * @param {String} p.responseId
 * @param {unknown} p.message
 * @param {Number} p.index
 * @returns {Object}
 */
export function createMessage({
  responseId,
  message,
  index,
}: CreateMessageArgs): ThunkAction {
  return (dispatch, getState) => {
    const messages = getActiveMessages(getState());
    const newMessage = createMessageRecord(message);
    const newMessages = messages
      .slice(0, index)
      .push(newMessage)
      .concat(messages.slice(index));

    dispatch(updateActiveMessages(responseId, newMessages));
  };
}

export function deleteMessage(
  keyPath: KeyPath,
  responseId: string,
): ThunkAction {
  return (dispatch, getState) => {
    const client = selectClient(getState());

    if (client?.features.personalization) {
      dispatch(deleteMessageVariables({ responseId, keyPath }));
    }

    const pathToMessages = keyPath.slice(0, -1);
    const messageIndex = keyPath[keyPath.length - 1];
    const messages = getState().responsesLoaded.getIn(pathToMessages);
    const newMessages = messages.delete(messageIndex);

    dispatch({
      type: "UPDATE_RESPONSE_DEEP",
      keyPath: pathToMessages,
      payload: newMessages,
      responseId,
    });
  };
}

interface GetResponseReferencesActionResult {
  hasReferences: boolean;
  references: ResponseReferencesInfo;
}

/**
 * This endpoint is really weird - if there are ONLY quick reply references, it returns them with
 * status 200. If there are fallback or redirect references, it returns them with status 400.
 */
export function getResponseReferencesAction(
  response: ResponseRecord | IdAndHandle,
  modality: Modality | null,
): ThunkAction<Promise<GetResponseReferencesActionResult>> {
  return async (dispatch) => {
    try {
      const res = await dispatch(
        adaApiRequest({
          method: "GET",
          url: `/responses/references/${response.id}`,
          params: {
            modality,
          },
        }),
      );

      const responseData = keyConverter(res.data);
      const { responseQuickReplyReferences } = responseData;

      if ((responseQuickReplyReferences as unknown[] | null)?.length) {
        return {
          hasReferences: true,
          references: { responseQuickReplyReferences },
        };
      }

      return {
        hasReferences: false,
        references: {},
      };
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 400) {
          const responseData = keyConverter(
            error.response.data as Record<string, unknown>,
          );

          const {
            responseFallbackReferences,
            responseQuickReplyReferences,
            responseAnswersRedirectReferences,
            responseCampaignReferences,
          } = responseData;

          return {
            hasReferences: true,
            references: {
              responseFallbackReferences,
              responseQuickReplyReferences,
              responseAnswersRedirectReferences,
              responseCampaignReferences,
            },
          };
        }
      }

      // Handle errors that don't match expected signature
      return {
        hasReferences: false,
        references: {},
      };
    }
  };
}

interface OpenResponseReferenceModalActionArgs {
  references: ResponseReferencesInfo;
  presentTenseAction: string;
  pastTenseAction: string;
  confirmButtonText: string;
  confirmButtonTint: "primary" | "alert";
  confirmButtonAction: () => void;
}

export function openResponseReferenceModalAction({
  references,
  presentTenseAction,
  pastTenseAction,
  confirmButtonText,
  confirmButtonTint,
  confirmButtonAction,
}: OpenResponseReferenceModalActionArgs): ThunkAction {
  return (dispatch) => {
    const {
      responseFallbackReferences,
      responseQuickReplyReferences,
      responseAnswersRedirectReferences,
      responseCampaignReferences,
    } = references;

    if (responseFallbackReferences) {
      dispatch(
        openModalAction("MODAL_WARNING", {
          title: "Answer Used Elsewhere",
          message:
            "This Answer is used as a redirect in the following Answers " +
            `and can’t be ${pastTenseAction} until this is changed.`,
          buttons: ["cancel"],
          renderCustomContent: () => (
            <ReferenceList
              references={responseFallbackReferences as ResponseReferencesList}
            />
          ),
        }),
      );
    } else if (responseAnswersRedirectReferences) {
      dispatch(
        openModalAction("MODAL_WARNING", {
          title: "Answer Used Elsewhere",
          message:
            "This Answer is used as a redirect in the following Answers and " +
            `can’t be ${pastTenseAction} until this is changed.`,
          buttons: ["cancel"],
          renderCustomContent: () => (
            <ReferenceList
              references={
                responseAnswersRedirectReferences as ResponseReferencesList
              }
            />
          ),
        }),
      );
    } else if (responseCampaignReferences) {
      dispatch(
        openModalAction("MODAL_WARNING", {
          title: "Answer Used Elsewhere",
          message:
            "This Answer is used in the following Campaigns and " +
            `can’t be ${pastTenseAction} until this is changed.`,
          buttons: ["cancel"],
          renderCustomContent: () => (
            <CampaignReferenceList
              references={responseCampaignReferences as CampaignReferenceList}
            />
          ),
        }),
      );
    } else if (responseQuickReplyReferences) {
      dispatch(
        openModalAction("MODAL_WARNING", {
          title: "Answer Used Elsewhere",
          message:
            "This Answer is used as a quick reply in other Answers. " +
            `${presentTenseAction} it anyway?`,
          actions: [
            {
              title: confirmButtonText,
              buttonTint: confirmButtonTint,
              onClick: confirmButtonAction,
            },
            {
              title: "Cancel",
              onClick() {
                dispatch(closeModalAction());
              },
            },
          ],
          renderCustomContent: () => (
            <ReferenceList
              references={
                responseQuickReplyReferences as ResponseReferencesList
              }
            />
          ),
        }),
      );
    }
  };
}

/**
 * Load a single response from API
 */
export function getResponseById(args: { responseId: string }): ThunkAction {
  return (dispatch, getState) => {
    const client = selectClient(getState());
    const { responsesLoaded } = getState();
    const { responseId } = args;

    // If we've already loaded the response, don't load it again.
    if (responsesLoaded.get(responseId)) {
      return;
    }

    dispatch({
      // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
      CALL_API: {
        method: "get",
        endpoint: `/responses/${responseId}`,
        args: {
          primaryLanguage: client?.language,
        },
        types: [
          "GET_RESPONSES_BY_ID_REQUEST",
          "GET_RESPONSES_BY_ID_SUCCESS",
          "GET_RESPONSES_BY_ID_FAILURE",
        ],
        dispatchCallbacks: [
          {
            request(payload) {
              dispatch({
                type: "FETCH_RESPONSE_SUCCESS",
                requestKey: responseId,
                response: (payload as { response: unknown }).response,
              });
            },
            fireOnStatus: "success",
          },
        ],
      },
    });
  };
}

export function deleteResponse(
  response: ResponseRecord | IdAndHandle,
): ThunkAction {
  return async (dispatch, getState) => {
    const responseId = response.id;
    await deleteResponseVariables({ getState, dispatch, responseId });

    dispatch({
      type: "DELETE_ONE_RESPONSE_SIDE_BAR_ITEM_REQUEST",
      requestKey: `delete-one-${responseId}`,
    });
    dispatch({ type: "DELETE_RESPONSE_REQUEST", responseId });

    try {
      const result = await dispatch(
        adaApiRequest({
          method: "DELETE",
          url: `/responses/${responseId}`,
          data: {
            ada__versioning__generated_description: DELETED_ANSWER_DESCRIPTION,
          },
        }),
      );
      dispatch({ type: "DELETE_RESPONSE_SUCCESS", response: result });
      dispatch({
        type: "DELETE_ONE_RESPONSE_SIDE_BAR_ITEM_SUCCESS",
        requestKey: `delete-one-${responseId}`,
        id: responseId,
      });

      dispatch({ type: "DELETE_FOLDER_RESPONSE_NODE_SUCCESS", responseId });

      dispatch(
        createAlert({
          message: `${response.handle} was successfully deleted.`,
          alertType: "success",
        }),
      );
      dispatch(push(`/answers/folders`));
    } catch (error) {
      dispatch({ type: "DELETE_RESPONSE_FAILURE", responseId });

      if (axios.isAxiosError(error) && error.response) {
        dispatch(
          createAlert({
            message: error.response.data.message,
            alertType: "error",
          }),
        );
      }
    }
  };
}

export function fetchAllResponsesShallow(): ThunkAction {
  return (dispatch, getState) => {
    if (getState().responsesPage.isLoading) {
      return;
    }

    const client = selectClient(getState());

    dispatch({
      // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
      CALL_API: {
        method: "get",
        endpoint: "/responses/shallow",
        args: {
          primaryLanguage: client?.language,
        },
        types: [
          "GET_RESPONSES_SHALLOW_REQUEST",
          "GET_RESPONSES_SHALLOW_SUCCESS",
          "GET_RESPONSES_SHALLOW_FAILURE",
        ],
        dispatchCallbacks: [],
      },
    });
  };
}

export function checkResponseReferencesAndDeleteAction(
  id: string,
  handle: string,
): ThunkAction {
  return async (dispatch) => {
    try {
      const { hasReferences, references } = await dispatch(
        getResponseReferencesAction({ id, handle }, null),
      );

      if (hasReferences) {
        dispatch(
          openResponseReferenceModalAction({
            references,
            presentTenseAction: "Delete",
            pastTenseAction: "deleted",
            confirmButtonText: "Delete",
            confirmButtonTint: "alert",
            confirmButtonAction: () => {
              dispatch(deleteResponse({ id, handle }));
              dispatch(closeModalAction());
            },
          }),
        );
      } else {
        dispatch(
          openModalAction("MODAL_WARNING", {
            title: "Delete Answer",
            message: "Are you sure you would like to delete this Answer?",
            actions: [
              {
                title: "Delete",
                buttonTint: "alert",
                onClick: () => {
                  dispatch(deleteResponse({ id, handle }));
                  dispatch(closeModalAction());
                },
              },
              {
                title: "Cancel",
                onClick() {
                  dispatch(closeModalAction());
                },
              },
            ],
          }),
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createAlert({ message: error.message }));
      }
    }
  };
}

interface DuplicateResponseDto {
  [key: string]: ResponseAttributesShape;
}

export function duplicateResponse(id: string, handle: string): ThunkAction {
  return async (dispatch, getState) => {
    try {
      const res = await dispatch(
        adaApiRequest<{
          response: DuplicateResponseDto;
          response_id: string;
          variables: [];
        }>({
          method: "POST",
          url: `/responses/duplicate/${id}`,
          data: {
            ada__versioning__generated_description:
              "Created a duplicate Answer",
          },
        }),
      );

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { response, response_id, variables } = res.data;

      await dispatch({
        type: "CREATE_RESPONSE_SUCCESS",
        response,
      });

      dispatch({
        type: "CREATE_FOLDER_SUCCESS",
        folder: response[response_id],
      });

      dispatch({
        type: "SAVE_BOT_CONTENT_SUCCESS",
        responseId: response_id,
        responses: response,
        variables,
      });

      if (res.status === 201) {
        dispatch(push(`/answers/${response_id}`));
      }

      dispatch(
        createAlert({
          message: `${handle} was successfully duplicated.`,
          alertType: "success",
        }),
      );

      dispatch(
        fetchMany("responseSideBarItem", getState().responsesPage.query),
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        dispatch(createAlert({ message: error.response.data.message }));
      }
    }
  };
}
