import { Button, Icon } from "@adasupport/byron";
import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  saveClientAndAlertAction,
  saveClientPlatform as saveClientPlatformAction,
  setState as setStateAction,
  updatePlatform as updatePlatformAction,
} from "actions";
import { ModalSideBar } from "components/Common/ModalSideBar";
import { LaunchControlsTab } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/LaunchControlsTab";
import { PersistenceTab } from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/PersistenceTab";
import InstallationTab from "components/Shared/Pages/Settings/SettingsPlatforms/SettingsPlatformsChatModal/SettingsPlatformChatModalInstallation";
import { useClientLegacy } from "services/clientLegacy";

import "./style.scss";

export function SettingsPlatformsChatModal() {
  const platformType = "chat";
  const { client } = useClientLegacy();
  const [active, setActive] = useState("installation");
  const platforms = useSelector((s) => s.platforms);
  const platform = platforms.get(platformType);
  const dispatch = useDispatch();
  const { saveClientPlatform, saveClient } = bindActionCreators(
    {
      setState: setStateAction,
      saveClientPlatform: saveClientPlatformAction,
      updatePlatform: updatePlatformAction,
      saveClient: saveClientAndAlertAction,
    },
    dispatch,
  );
  const [hasModalError, setModalError] = useState(false);

  if (!platform) {
    return null;
  }

  const BUTTONS = [
    {
      name: "installation",
      text: "Installation",
    },
    {
      name: "launch controls",
      text: "Launch controls",
    },
    {
      name: "persistence",
      text: "Persistence",
    },
  ];

  const onTabSelect = (e: MouseEvent) => {
    setActive((e.target as HTMLElement).title);
  };

  const updateClientPlatform = async () => {
    if (client.isDirty) {
      await saveClient();
      saveClientPlatform(platform);
    } else {
      saveClientPlatform(platform);
    }
  };

  const renderTab = () => {
    switch (active) {
      case "installation":
        return <InstallationTab />;
      case "launch controls":
        return <LaunchControlsTab setModalError={setModalError} />;
      case "persistence":
        return <PersistenceTab />;
      default:
        return null;
    }
  };

  return (
    <div className="Modal__modal">
      <h5 className="Modal__title">Configure Web Chat</h5>
      <div className="Modal__body">
        <ModalSideBar
          buttons={BUTTONS}
          onClick={onTabSelect}
          activeButton={active}
        />
        <div className="Modal__body__container">
          {renderTab()}
          <div className="Modal__bottom Modal__bottom--right">
            <Button
              onClick={updateClientPlatform}
              text="Save"
              icon={Icon.Cloud}
              isLoading={false}
              isDisabled={
                hasModalError || (!platform.get("isDirty") && !client.isDirty)
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

SettingsPlatformsChatModal.isLarge = true;
