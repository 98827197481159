import * as Immutable from "immutable";

import { type AnalyticsListItem } from "resourceModels";

export interface ConversationTopic {
  name: string;
  totalConversations: number;
  totalHandoffs: number;
  lastUpdatedBy: string;
  id: string;
}

export function deserializeTopics(
  topics: Immutable.List<AnalyticsListItem> | null,
): Immutable.List<ConversationTopic> {
  if (!topics || !topics.size) {
    return Immutable.List<ConversationTopic>();
  }

  // right now our topic id is coming back as:
  // "conversations_topics_breakdown-61058ca4b52634952172bfde"
  // we only want the actual id string from this, so we need to slice at this index
  const indexOfTopicIdInResourceId = 31;

  const formattedTopics = topics.map((topic: AnalyticsListItem) => {
    const newTopic: ConversationTopic = {
      name: String(
        topic.values
          .find((column) => column.get("label") === "Topic")
          ?.get("value"),
      ),
      totalConversations: Number(
        topic.values
          .find((column) => column.get("label") === "Volume")
          ?.get("value"),
      ),
      totalHandoffs: Number(
        topic.values
          .find((column) => column.get("label") === "Handoffs")
          ?.get("value"),
      ),
      lastUpdatedBy: String(
        topic.values
          .find((column) => column.get("label") === "UpdatedBy")
          ?.get("value"),
      ),
      id: String(
        topic.values
          .find((column) => column.get("label") === "Topic")
          ?.getIn(["meta", "resourceId"]),
      ).slice(indexOfTopicIdInResourceId),
    };

    return newTopic;
  });

  return formattedTopics;
}
