import React from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";
import { type ResponseRecord } from "reducers/responses/types";
import { useClient } from "services/client";

import { QuickReplyLabelInput } from "../QuickReplyLabelInput";

import "./style.scss";

export const MultiLangAnswerLabels = (props: {
  sortedButtonLabels: readonly (readonly [string, string])[];
  response: ResponseRecord;
  languageButton: JSX.Element;
  handleUpdateButtonLabel(buttonLabel: string, languageCode: string): void;
  handleDeleteButtonLabel(languageCode: string): void;
}) => {
  const { client } = useClient();

  const {
    sortedButtonLabels,
    response,
    languageButton,
    handleUpdateButtonLabel,
    handleDeleteButtonLabel,
  } = props;

  const shouldShowMultipleLanguages = !(
    client.translatedLanguages.length === 0 && sortedButtonLabels.length < 2
  );

  const actions = { handleDeleteButtonLabel, handleUpdateButtonLabel };

  const getQuickReplyLabelInputList = () =>
    sortedButtonLabels.map((languageTuple, i) => {
      const key = `QuickReplyLabelInput-${i}`;

      return (
        <QuickReplyLabelInput
          key={key}
          clientLanguageCode={client.language}
          handleUpdateButtonLabel={handleUpdateButtonLabel}
          handleDeleteButtonLabel={(languageCode) =>
            actions.handleDeleteButtonLabel(languageCode)
          }
          value={languageTuple[1]}
          languageCode={languageTuple[0]}
          shouldShowMultipleLanguages={shouldShowMultipleLanguages}
          responseHandle={response.handle}
          placeholder="Enter your default button text here"
        />
      );
    });

  return (
    <div className="MultiLangAnswerLabels">
      <div className="MultiLangAnswerLabels__header">
        <div className="MultiLangAnswerLabels__title">
          <h3 className="g-form__block__label MultiLangAnswerLabels__headline">
            Answer Label
          </h3>
          <div className="MultiLangAnswerLabels__tooltip">
            <InfoTooltip
              blurb="Customers click this text to trigger an Answer during chat. It is used in Quick Replies, Clarifications and Suggestions."
              iconDefault="QuestionCircle"
              iconClicked="QuestionCircleFilled"
              maxWidth={218}
            />
          </div>
        </div>
        {shouldShowMultipleLanguages && languageButton}
      </div>
      <div>
        <p className="MultiLangAnswerLabels__description">
          Add a short label to help customers understand what this Answer is
          about.
        </p>
      </div>
      <div className="MultiLangAnswerLabels__language-buttons">
        {getQuickReplyLabelInputList()}
      </div>
    </div>
  );
};
