import { type Action } from "@reduxjs/toolkit";

import { createAlert } from "actions/alerts";
import { TRAINING_MOVED_DESCRIPTION } from "components/Shared/Pages/Responses/ResponseVersions/constants";

import { invalidateExpressionLanguagesCache } from "./invalidateExpressionLanguagesCache";

export function moveExpression(
  expressionId: string,
  responseId: string,
  body: string,
): Action {
  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "patch",
      endpoint: `/expressions/${expressionId}`,
      payload: {
        response_id: responseId,
        body,
        ada__versioning__generated_description: TRAINING_MOVED_DESCRIPTION,
      },
      types: [
        "MOVE_EXPRESSION_REQUEST",
        "MOVE_EXPRESSION_SUCCESS",
        "MOVE_EXPRESSION_FAILURE",
      ],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: 400,
        },
        {
          request: createAlert,
          fireOnStatus: "success",
          args: {
            message: `Your message, "${body}", was successfully moved!`,
            alertType: "success",
          },
        },
        {
          request: invalidateExpressionLanguagesCache,
          fireOnStatus: 200,
        },
      ],
    },
  } as unknown as Action;
}
