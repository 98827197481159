/* eslint-disable @typescript-eslint/naming-convention */
import axios from "axios";

import { createAlert } from "actions/alerts";
import {
  type ScraperCollection,
  type TransformedScraperCollection,
} from "components/Generative/Pages/Knowledge/SourcesItem/types";
import { browserStorage } from "services/browserStorage";
import { AdaApiSlice } from "slices";
import { KnowledgeApi } from "slices/knowledge/knowledgeApiSlice";
import { OnboardingQuickQuestionsApi } from "slices/onboardingQuickQuestions/onboardingQuickQuestionsApi";

const AdaApiSliceWithTags = AdaApiSlice.enhanceEndpoints({
  addTagTypes: ["GetScraperCollections"],
});

export const WebScraperApi = AdaApiSliceWithTags.injectEndpoints({
  endpoints: (build) => ({
    getWebScraperData: build.query<TransformedScraperCollection[], void>({
      query: () => ({
        method: "GET",
        url: "/web_scraper",
      }),
      providesTags: ["GetScraperCollections"],
      transformResponse: (response: {
        data: ScraperCollection[];
      }): TransformedScraperCollection[] => {
        const { data } = response;
        const transformResponse = data
          .map(
            ({
              run_status,
              start_urls,
              run_id,
              dataset_id,
              knowledge_source_id,
              ...item
            }) => ({
              runStatus: run_status,
              startUrls: start_urls,
              runId: run_id,
              datasetId: dataset_id,
              knowledgeSourceId: knowledge_source_id,
              ...item,
            }),
          )
          .sort((a, b) => Date.parse(b.updated) - Date.parse(a.updated));

        return transformResponse;
      },
    }),
    createWebScraper: build.mutation<
      void,
      {
        sourceName: string;
        startUrls: Array<{ url: string; crawl: boolean }>;
        knowledgeSourceId?: string;
        timeoutSecs?: number;
      }
    >({
      query: ({ sourceName, startUrls, knowledgeSourceId, timeoutSecs }) => ({
        method: "POST",
        url: "/web_scraper",
        data: {
          name: sourceName,
          start_urls: startUrls,
          knowledge_source_id: knowledgeSourceId,
          timeout_secs: timeoutSecs,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            WebScraperApi.util.invalidateTags(["GetScraperCollections"]),
          );
          dispatch(
            KnowledgeApi.util.invalidateTags(["GetSources", "GetArticles"]),
          );
        } catch (error) {
          let errorMessage = "Failed to scrape site.";

          if (
            axios.isAxiosError(error) &&
            error.response &&
            (error.response.status === 409 || error.response.status === 429) &&
            error.response.data instanceof Object &&
            "message" in error.response.data
          ) {
            errorMessage = error.response.data.message as string;
          }

          dispatch(
            createAlert({
              message: `Something went wrong - ${errorMessage}`,
              alertType: "error",
            }),
          );
          throw error;
        }
      },
    }),
    deleteWebScrape: build.mutation<
      void,
      { knowledgeSourceId: string; isOnboardingScrapeCollection?: boolean }
    >({
      query: ({ knowledgeSourceId }) => ({
        method: "DELETE",
        url: `/web_scraper/${knowledgeSourceId}`,
      }),
      async onQueryStarted(
        { isOnboardingScrapeCollection },
        { dispatch, queryFulfilled },
      ) {
        try {
          await queryFulfilled;
          dispatch(
            WebScraperApi.util.invalidateTags(["GetScraperCollections"]),
          );
          dispatch(
            KnowledgeApi.util.invalidateTags(["GetSources", "GetArticles"]),
          );

          if (isOnboardingScrapeCollection) {
            dispatch(
              OnboardingQuickQuestionsApi.util.invalidateTags([
                "OnboardingQuickQuestions",
              ]),
            );
            browserStorage.remove("onboarding_quick_questions");
          }
        } catch (error) {
          dispatch(
            createAlert({
              message: "Something went wrong - Failed to delete source.",
              alertType: "error",
            }),
          );
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetWebScraperDataQuery,
  useCreateWebScraperMutation,
  useDeleteWebScrapeMutation,
} = WebScraperApi;
