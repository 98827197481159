import isMatch from "lodash.ismatch";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBetween } from "use-between";

import { saveClientPlatform } from "actions";
import { type ChatPlatformRecord } from "reducers/platforms/models";

interface ChatPlatformAttributes {
  privacy: boolean;
  privacyLink: string;
  showLogo: boolean;
  buttonLayoutDesktop: "carousel" | "stacked";
  buttonLayoutMobile: "carousel" | "stacked";
  launchControls: {
    showPrimaryBot: number;
    showAlternativeBot: number;
    showNoBot: number;
  };
  alternativeBot: null | { handle: string; rollout: number };
  alternativeBotPreviewLink: string | null;
  rollout: ChatPlatformRecord["rollout"];
}

interface ChatPlatform extends ChatPlatformAttributes {
  isDirty: boolean;
  invalidFields: Set<keyof ChatPlatformAttributes>;
}

function useChangedAttributes() {
  const [changedAttributes, setChangedAttributes] = useState<
    Partial<ChatPlatformAttributes>
  >({});

  return {
    changedAttributes,
    setChangedAttributes,
  };
}

export function useChatPlatform() {
  const dispatch = useDispatch();
  const chatPlatformState = useSelector((state) => state.platforms.get("chat"));

  const platformAttributes = useMemo((): ChatPlatformAttributes => {
    if (!chatPlatformState) {
      throw new Error('`state.platforms.get("chat")` not found');
    }

    const chatPlatformRecord = chatPlatformState.get(
      "record",
    ) as ChatPlatformRecord;

    return {
      privacy: chatPlatformRecord.privacy,
      privacyLink: chatPlatformRecord.privacyLink,
      showLogo: chatPlatformRecord.showLogo,
      buttonLayoutDesktop:
        chatPlatformRecord.buttonLayoutDesktop as ChatPlatformAttributes["buttonLayoutDesktop"],
      buttonLayoutMobile:
        chatPlatformRecord.buttonLayoutMobile as ChatPlatformAttributes["buttonLayoutMobile"],
      launchControls:
        chatPlatformRecord.launchControls.toJS() as ChatPlatformAttributes["launchControls"],
      alternativeBot:
        chatPlatformRecord.alternativeBot?.toJS() as ChatPlatformAttributes["alternativeBot"],
      alternativeBotPreviewLink: chatPlatformRecord.alternativeBotPreviewLink,
      rollout: chatPlatformRecord.rollout,
    };
  }, [chatPlatformState]);

  const { changedAttributes, setChangedAttributes } =
    useBetween(useChangedAttributes);

  const attrs: ChatPlatformAttributes = {
    ...platformAttributes,
    ...changedAttributes,
  };

  const chatPlatform: ChatPlatform = {
    ...attrs,
    isDirty: !isMatch(platformAttributes, changedAttributes),
    invalidFields: (() => {
      const r = new Set<keyof ChatPlatformAttributes>();

      if (attrs.privacy && !attrs.privacyLink) {
        r.add("privacyLink");
      }

      return r;
    })(),
  };

  return {
    chatPlatform,

    updateChatPlatform: useCallback(
      (attributes: Partial<ChatPlatformAttributes>) =>
        setChangedAttributes({ ...changedAttributes, ...attributes }),
      [changedAttributes, setChangedAttributes],
    ),

    resetChatPlatform: useCallback(() => {
      setChangedAttributes({});
    }, [setChangedAttributes]),

    saveChatPlatform: useCallback(() => {
      if (chatPlatformState) {
        dispatch(
          saveClientPlatform(
            chatPlatformState.mergeIn(["record"], changedAttributes),
          ),
        );
      }
    }, [changedAttributes, chatPlatformState, dispatch]),
  };
}
