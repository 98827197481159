// @ts-strict-ignore
import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

export type CaptureType =
  | "string"
  | "phone_number"
  | "email"
  | "pnr"
  | "number"
  | "boolean";

interface Attributes extends BaseMessageRecordAttributes {
  type: "capture";
  alwaysAsk: boolean;
  chatterResponseOptional: boolean;
  captureKey: string;
  capturePlaceholder: string;
  captureLead: string;
  captureType: CaptureType;
  scopeChanged: boolean;
  guessFirst: boolean;
  entity: string;
  variableId: string;
}

export class CaptureMessageRecord extends BaseMessageRecord<Attributes>({
  type: "capture",
  alwaysAsk: false,
  chatterResponseOptional: false,
  captureKey: "",
  capturePlaceholder: "",
  captureLead: "",
  captureType: "string",
  scopeChanged: false,
  guessFirst: true,
  entity: "",
  variableId: null,
}) {
  static getInvalidFields = (
    message: CaptureMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();

    if (!message.captureLead) {
      invalidFields.add("captureLead");
    }

    const isCaptureKeyValid =
      message.captureKey && message.captureKey.length <= 100;

    if (!isCaptureKeyValid) {
      invalidFields.add("captureKey");
    }

    return Immutable.List(invalidFields);
  };
}
