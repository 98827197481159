import { Agent } from "./Agent";
import { AnalyticsAreaGraphRecord } from "./AnalyticsAreaGraphRecord";
import { AnalyticsCount } from "./AnalyticsCount";
import { AnalyticsListItem } from "./AnalyticsListItem";
import { AnalyticsOverview } from "./AnalyticsOverview";
import { AnalyticsReport } from "./AnalyticsReport";
import { AnalyticsTimeSeriesRecord } from "./AnalyticsTimeSeriesRecord";
import { CSATSettings } from "./CSATSettings";
import { Channel } from "./Channel";
import { ChatterNew } from "./ChatterNew";
import { ClientLegacy } from "./Client";
import { Language } from "./Language";
import { MessageTemplate } from "./MessageTemplate";
import { ResponseSideBarItem } from "./ResponseSideBarItem";
import { StatsSatisfactionRecord } from "./StatsSatisfactionRecord";
import { User } from "./User";

export interface ResourceModelTypes {
  analyticsReport: AnalyticsReport;
  analyticsTimeSeriesRecord: AnalyticsTimeSeriesRecord;
  analyticsListItem: AnalyticsListItem;
  analyticsOverview: AnalyticsOverview;
  analyticsCount: AnalyticsCount;
  analyticsAreaGraphRecord: AnalyticsAreaGraphRecord;
  client: ClientLegacy;
  channel: Channel;
  csatSettings: CSATSettings;
  language: Language;
  responseSideBarItem: ResponseSideBarItem;
  user: User;
  agent: Agent;
}

export type ResourceType = keyof ResourceModelTypes;

type ResourceModel = ResourceModelTypes[ResourceType];

const allResourceModels = [
  AnalyticsListItem,
  AnalyticsOverview,
  AnalyticsReport,
  AnalyticsTimeSeriesRecord,
  AnalyticsCount,
  AnalyticsAreaGraphRecord,
  ChatterNew,
  ClientLegacy,
  Channel,
  CSATSettings,
  Language,
  MessageTemplate,
  ResponseSideBarItem,
  StatsSatisfactionRecord,
  User,
  Agent,
];

export function getModelByResourceType(
  resourceType: ResourceType,
): ResourceModel {
  const resourceModelsKeyedByType: Partial<ResourceModelTypes> =
    allResourceModels.reduce(
      (result, Model) => ({
        ...result,

        // Remove this when all models are converted to TS
        [Model.type]: Model,
      }),
      {},
    );

  const ResultModel = resourceModelsKeyedByType[resourceType];

  if (!ResultModel) {
    throw new Error(`A model with type "${resourceType}" not found`);
  }

  return ResultModel;
}

export {
  AnalyticsListItem,
  AnalyticsOverview,
  AnalyticsReport,
  AnalyticsTimeSeriesRecord,
  AnalyticsCount,
  AnalyticsAreaGraphRecord,
  ChatterNew,
  ClientLegacy,
  Channel,
  CSATSettings,
  Language,
  MessageTemplate,
  ResponseSideBarItem,
  StatsSatisfactionRecord,
  User,
  Agent,
};
