import styled from "styled-components";

import { colorPrimitives, colorTokens, v } from "constants/css";

export const DropdownContainer = styled.div<{
  minWidth?: number | null;
  triggerHeight: number;
  shouldOpenUp: boolean;
  listHeight: number;
}>`
  z-index: ${v.zIndexDropdown};
  background-color: ${colorPrimitives.white};
  border: 1px solid rgba(6, 6, 48, 0.04);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  border-radius: ${v.mainBorderRadius};
  position: absolute;
  width: 100%;

  ${({ minWidth }) =>
    minWidth &&
    `
     min-width: ${minWidth}px;
  `};

  display: flex;
  flex-direction: column;

  // Subtract 16px to account for spacing from trigger, and to allow spacing from edge of container
  max-height: ${({ listHeight }) => listHeight - 16}px;
  ${({ triggerHeight, shouldOpenUp }) =>
    shouldOpenUp
      ? `bottom: ${triggerHeight + 8}px`
      : `top: ${triggerHeight + 8}px`}
`;

export const HeaderButton = styled.button<{
  isActive: boolean;
  isInvalid: boolean;
}>`
  background-color: ${colorPrimitives.white};
  border-radius: ${v.smallBorderRadius};
  border: 1px solid ${colorTokens.borderButton};
  height: 36px;
  cursor: pointer;
  width: 100%;
  outline: inherit;
  display: flex;
  align-items: center;

  ${({ isActive, isInvalid }) => {
    if (isActive) {
      return `
        border: 1px solid ${colorPrimitives.black};
        outline: 3px solid ${colorTokens.actionPrimaryFocus};
      `;
    }

    if (isInvalid) {
      return `border: 1px solid ${colorTokens.statusNegative};`;
    }

    return null;
  }}
`;

export const HeaderTitle = styled.div`
  text-align: left;
  font-size: ${v.smallFontSize};
  width: 100%;
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
