import styled from "styled-components";

import { c, colorPrimitives, cssVariables, v } from "constants/css";

export const Body = styled.div`
  flex: 1;
  overflow: auto;
`;

export const InputSectionContainer = styled.div`
  padding: ${v.gUnit} ${v.gUnitDouble} 0 ${v.gUnitDouble};
`;

export const HandleLabelContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
`;

export const Divider = styled.div`
  height: ${v.gUnit};
  width: 100%;
  background-color: ${c.colorGrey1};
  margin: ${v.gUnitDouble} 0;
`;

export const Spacer = styled(Divider)`
  background: transparent;
`;

export const HalfSpacer = styled(Spacer)`
  height: ${v.gUnitHalf};
  margin: ${v.gUnit} 0;
`;

export const SubsectionHeading = styled.h2`
  font-weight: ${cssVariables.xHeavyFontWeight};
  font-size: ${cssVariables.mainFontSize};
  color: ${colorPrimitives.black};
  margin-bottom: ${cssVariables.space4};
`;

export const SidePadding = styled.div`
  padding: 0 ${v.gUnitDouble};
`;

export const ThinDivider = styled.hr`
  height: 1px;
  border-top: none;
  border-bottom: 1px solid ${c.colorGrey2};
  margin: ${v.gUnit} 0;
  transform: scaleY(0.5);
`;

export const ToggleDescription = styled.p`
  width: 460px;
  color: ${c.colorGrey4};
  font-size: ${v.xSmallFontSize};
  margin-bottom: ${v.gUnit};
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const AddTagButtonContainer = styled.div`
  position: absolute;
  right: 1px;
  top: 0;
`;

export const ResponseTagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ResponseTagContainer = styled.div`
  margin: 0 ${v.gUnit} ${v.gUnit} 0;
`;

export const AllTagsListOuterContainer = styled.div`
  margin-bottom: ${v.gUnitDouble};
`;

export const AllTagsListContainer = styled.div`
  border: 1px solid ${c.colorGrey2};
  border-radius: ${v.mainBorderRadius};
  margin-top: ${v.gUnit};
  max-height: 246px;
  overflow: auto;
`;

export const TagListGradient = styled.div<{ visible: boolean }>`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  pointer-events: none;
  transition: opacity 0.4s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const TagListItem = styled.div`
  padding: ${v.gUnit};
  border-bottom: 1px solid ${c.colorGrey2};
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`;

export const TopTagText = styled.p`
  float: right;
  margin-top: ${v.gUnitHalf};
  color: ${c.colorGrey4};
  font-size: 12px;
  margin-bottom: ${v.gUnit};
`;

export const WrappedTitle = styled.div`
  & .Modal__title {
    padding: 0 36px 0 16px;
    margin: 14px 0 0;
    display: block;
    text-align: center;
  }
`;
