import { type AsyncAction } from "actions/types";

import {
  type AlertArguments,
  type AlertButtons,
  type AlertTypeOption,
  DISMISS_ALERT,
  type DismissAlertAction,
  GENERATE_ALERT,
  type GenerateAlertAction,
} from "./types";

const ALERT_TIMEOUT = 5000;
let alertTimer: NodeJS.Timeout;

function generateAlert(
  message: string,
  buttons?: AlertButtons,
  alertType?: AlertTypeOption,
): GenerateAlertAction {
  return {
    type: GENERATE_ALERT,
    alertType,
    message,
    buttons,
  };
}

export function dismissAlert(): DismissAlertAction {
  return {
    type: DISMISS_ALERT,
  };
}

// Async actions

function setAlertTimer(): AsyncAction {
  return (dispatch) => {
    alertTimer = setTimeout(() => {
      dispatch(dismissAlert());
    }, ALERT_TIMEOUT);
  };
}

export function createAlert(args: AlertArguments): AsyncAction {
  const { response, buttons, alertType } = args;
  let { message } = args;

  message = message || response?.data.message || "";

  return (dispatch, getState) => {
    if (getState().alert.isVisible) {
      clearTimeout(alertTimer);
      dispatch(dismissAlert());

      if (!buttons) {
        dispatch(setAlertTimer());
      }

      dispatch(generateAlert(message, buttons, alertType));
    } else {
      dispatch(generateAlert(message, buttons, alertType));
      clearTimeout(alertTimer);
      dispatch(setAlertTimer());
    }
  };
}
