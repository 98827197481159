import {
  type LoginAction,
  LoginRecord,
} from "components/Declarative/Pages/Login/types";

export const login = (state = new LoginRecord(), action: LoginAction) => {
  switch (action.type) {
    // This action is used as a generic action for setting any part of the state
    // In particular it is used in the setState function in actions/index
    case "SET_LOGIN":
      return state.merge(action.payload);

    case "SET_LOGIN_ERROR":
      return state.merge({
        showError: true,
      });

    case "SET_CREDENTIALS": {
      const { email, password } = action.payload;

      return state.merge({
        email,
        password,
      });
    }

    case "SMS_VERIFY_SUCCESS":
      return state.set("tfa_token", action.response.data.token);

    case "CLEAR_CREDENTIALS":
      return state.merge({
        password: "",
        tfa_token: "",
      });

    case "RESET_NOTIFICATION_ERROR":
      return state.merge({
        showError: false,
      });
    case "SET_PASSWORD_EXPIRED":
      return state.merge({
        showError: true,
        lastLoginFailureReason: "password_expired",
      });
    case "LOGIN_FAILURE":
      return state.merge({
        lastLoginFailureReason: "default",
      });
    case "LOGIN_RATE_LIMIT":
      return state.merge({
        lastLoginFailureReason: "rate_limit",
      });
    case "RESET_PASSWORD_SUCCESS":
    case "RESET_PASSWORD_FAILURE":
      return state.set("isPasswordResetShown", false);
    case "RESET_EXPIRED_PASSWORD_SUCCESS":
      return state.set("showError", false);
    case "SET_CURRENT_STEP":
      return state.set("currentStep", state.get("currentStep") + 1);
    case "SET_PREVIOUS_STEP":
      return state.set("currentStep", state.get("currentStep") - 1);
    case "ENFORCE_MFA_ACTIVATE_TFA_FAILURE":
      if (action.response.status === 418) {
        return state.set("currentStep", 3);
      }

      return state.set("currentStep", 2);
    case "ENFORCE_MFA_ACTIVATE_TFA_SUCCESS":
    case "SMS_VERIFY_FAILURE":
      return state.set("currentStep", 3);
    case "ENFORCE_MFA_SMS_VERIFY_SUCCESS":
      return state.merge({
        currentStep: 4,
        tfa_token: action.response.data.token,
      });
    case "ENFORCE_MFA_SMS_VERIFY_FAILURE":
      return state.set("showError", true);
    default:
      return state;
  }
};
