import { type AnyAction } from "@reduxjs/toolkit";

import { createConversationSummariesDefaultState } from "./factory";
import { mapConversationSummaryPropertyNames } from "./objectMapper";
import { type ConversationSummaryResponse } from "./types";

export const conversationSummaries = (
  state = createConversationSummariesDefaultState(),
  action: AnyAction,
) => {
  switch (action.type) {
    case "FETCH_CONVERSATION_SUMMARIES_REQUEST": {
      return {
        ...state,
        loaded: false,
        loading: true,
      };
    }

    case "FETCH_CONVERSATION_SUMMARIES_SUCCESS": {
      let { summaries } = state;
      let { chatterSummaries } = state;

      const returnedSummaries = action.conversationSummaries.map(
        (summary: ConversationSummaryResponse) =>
          mapConversationSummaryPropertyNames(summary),
      );

      if (action.deepConversations) {
        chatterSummaries = returnedSummaries;
      } else if (action.clearShallow) {
        summaries = returnedSummaries;
      } else {
        summaries = [...state.summaries, ...returnedSummaries];
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        page: action.page,
        summaries,
        chatterSummaries,
      };
    }

    case "FETCH_CONVERSATION_SUMMARIES_FAILURE": {
      return { ...state, loading: false, loaded: false };
    }

    case "CLEAR_CONVERSATION_SUMMARIES": {
      return {
        ...state,
        loading: false,
        loaded: true,
        summaries: [],
        chatterSummaries: [],
      };
    }

    default:
      return state;
  }
};
