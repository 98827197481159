import { bindActionCreators } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";

import "./style.scss";

const BLOCK_NAME = "ModalEditTraining";

class ModalEditTrainingComponent extends React.Component {
  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const { expression } = this.props;

    this.state = {
      input: expression.get("expressionBody"),
    };

    this.editTrainingInputRef = React.createRef();
    this.handleUpdateExpressionInput =
      this.handleUpdateExpressionInput.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  /** */
  componentDidMount() {
    if (this.editTrainingInputRef) {
      // Brings focus to the textarea and moves the cursor to the end of text
      this.editTrainingInputRef.current.focus();
      this.editTrainingInputRef.current.setSelectionRange(
        this.editTrainingInputRef.current.value.length,
        this.editTrainingInputRef.current.value.length,
      );
    }
  }

  /**
   * Updates input
   * @param {Object} e
   */
  handleUpdateExpressionInput(e) {
    this.setState({
      input: e.target.value,
    });
  }

  /**
   * Closes modal when click on "Cancel" button
   */
  handleCancelButtonClick() {
    const { closeModal } = this.props;
    closeModal();
  }

  /**
   * Closes modal and triggers callback function when saving edited training
   */
  handleSaveButtonClick() {
    const { closeModal, getUpdatedTraining, expression } = this.props;
    const { input } = this.state;
    getUpdatedTraining(input, expression);

    closeModal();
  }

  render() {
    const { input } = this.state;

    return (
      <div className={`${BLOCK_NAME} Modal__modal--small`}>
        <h5 className="Modal__title">Edit Training Question</h5>
        <div className="Modal__content">
          <section className={`Modal__section ${BLOCK_NAME}__details`}>
            <div className={`${BLOCK_NAME}__details__user-info__inputs__input`}>
              <label
                className={`g-input__label ${BLOCK_NAME}__label`}
                htmlFor={`${BLOCK_NAME}__training`}
              >
                Training
              </label>
              <textarea
                id={`${BLOCK_NAME}__input__field`}
                className={`${BLOCK_NAME}__content__input-container__input g-input`}
                value={input}
                onChange={this.handleUpdateExpressionInput}
                ref={this.editTrainingInputRef}
              />
            </div>
          </section>
        </div>
        <div className={`Modal__bottom ${BLOCK_NAME}__bottom`}>
          <Button
            text="Cancel"
            title="Cancel"
            light
            customClassName={`${BLOCK_NAME}__bottom__button ${BLOCK_NAME}__bottom__button--left`}
            onClick={this.handleCancelButtonClick}
          />
          <Button
            text="Save"
            title="Save"
            disabled={!input}
            customClassName={`${BLOCK_NAME}__bottom__button`}
            onClick={this.handleSaveButtonClick}
          />
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line react/static-property-placement
ModalEditTrainingComponent.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getUpdatedTraining: PropTypes.func.isRequired,
  expression: ImmutablePropTypes.map.isRequired,
};

/**
 * @param {Function} dispatch
 * @returns {Object.<Function>}
 */
function mapDispatch(dispatch) {
  return {
    closeModal: bindActionCreators(closeModalAction, dispatch),
  };
}

export const ModalEditTraining = connect(
  undefined,
  mapDispatch,
)(ModalEditTrainingComponent);
