import qs from "qs";

import {
  INFERRED_VARIABLE_REFERENCE_REGEX,
  VARIABLE_REFERENCE_REGEX,
} from "components/Common/Slate/deserializers/constants";
import { adaAPI } from "services/api";
import { type JSONData } from "services/helpers";
import { type Variable } from "services/variables";
import { WebActionCharacterLimits } from "services/webActions/constants";

import { type InferredVariable, type WebAction } from "./api";

const getVariableIds = (str: string) =>
  str
    ? [...str.matchAll(VARIABLE_REFERENCE_REGEX)].map((match) => match[1])
    : [];

export const getReferencedVariables = (
  webAction: Omit<WebAction, "_id">,
  variables: Variable[],
): Variable[] => {
  const variableIds = [
    ...getVariableIds(webAction.url),
    ...getVariableIds(webAction.request_body),
    ...webAction.headers.flatMap((h) => getVariableIds(h.value)),
  ];

  return variableIds
    .map((id) => variables.find((v) => v._id === id))
    .filter(
      (variable, index, self) =>
        // Remove duplicates
        self.findIndex((v) => v?._id === variable?._id) === index,
    )
    .filter(Boolean) as Variable[];
};

const getInferredVariableIds = (str: string) =>
  str
    ? [...str.matchAll(INFERRED_VARIABLE_REFERENCE_REGEX)].map(
        (match) => match[1],
      )
    : [];

export const getReferencedInferredVariables = (
  webAction: Omit<WebAction, "_id">,
): InferredVariable[] => {
  const variableIds = [
    ...getInferredVariableIds(webAction.url),
    ...getInferredVariableIds(webAction.request_body),
    ...webAction.headers.flatMap((h) => getInferredVariableIds(h.value)),
  ];

  return webAction.inputs.filter((input) =>
    variableIds.includes(input.name),
  ) as InferredVariable[];
};

export const isIdentifier = (str: string) => {
  // Check if the string is not empty and starts with a letter, underscore, or dollar sign
  if (!str || !/^[a-zA-Z_$]/.test(str)) {
    return false;
  }

  // Check if the rest of the string contains only letters, digits, underscores, and dollar signs
  return /^[a-zA-Z0-9_$]*$/.test(str);
};

/** Validates input name and returns a validation message if invalid. */
export const getWebActionInputNameValidationMessage = (
  input: WebAction["inputs"][0],
): string | null => {
  if (!isIdentifier(input.name)) {
    return "Must be alphanumeric, no spaces, and start with a letter.";
  }

  if (input.name.length > WebActionCharacterLimits.inputName) {
    return "Name is too long.";
  }

  return null;
};

export const replaceVariablesWithValues = <
  T extends WebAction | Omit<WebAction, "_id">,
>(
  webAction: T,
  testValues: Record<string, string>,
): T => {
  const { url, headers, request_body: requestBody } = webAction;
  const variableRegex = VARIABLE_REFERENCE_REGEX;

  const replacer = (x: string, variableId: string) =>
    testValues[variableId] || x;

  return {
    ...webAction,
    url: url.replace(variableRegex, replacer),
    headers: headers.map((header) => ({
      ...header,
      value: header.value.replace(variableRegex, replacer),
    })),
    request_body: requestBody.replace(variableRegex, replacer),
  };
};

export const testWebActionApi = async (
  webAction: WebAction | Omit<WebAction, "_id">,
  testValues: {
    inferred_variable?: Record<string, string>;
    variable?: Record<string, string>;
  },
) => {
  const response = await adaAPI.request<{
    step_outcome:
      | {
          // Internal error
          status: "failed";
          return_values: null;
          error_message: string;
        }
      | {
          // API returned an error response
          status: "failed";
          status_code: number;
          return_values: null;
          error_message: string;
          full_response: JSONData;
        }
      | {
          // API returned a success response
          status: "succeeded";
          status_code: number;
          return_values: Record<string, string> | Record<string, string>[];
          full_response: JSONData;
        }
      | {
          // API returned a warning response
          status: "warning";
          status_code: number;
          return_values: Record<string, string> | Record<string, string>[];
          full_response: JSONData;
          error_message: string;
        };
  }>({
    method: "POST",
    url: "/generative_actions/web_actions/test",
    data: {
      web_action: replaceVariablesWithValues(
        webAction,
        testValues.variable || {},
      ),
      test_inputs: testValues.inferred_variable,
    },
  });

  return response.data;
};

export const getRedactedTokenValueForVariable = async (
  variableIds: string[],
) => {
  const response = await adaAPI.request<{
    redacted_values: {
      [token: string]: string;
    };
  }>({
    method: "GET",
    url: "/client_secrets/redacted",
    params: { variable_ids: variableIds },
    paramSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  });

  return response.data.redacted_values;
};

export const isInferredVariableReferenced = (
  webAction: Omit<WebAction, "_id">,
  inferredVariableName: string,
) =>
  webAction.url.includes(`{{${inferredVariableName}}}`) ||
  webAction.headers.some((header) =>
    header.value.includes(`{{${inferredVariableName}}}`),
  ) ||
  webAction.request_body.includes(`{{${inferredVariableName}}}`);
