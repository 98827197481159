import { parseScript } from "esprima";
import {
  type CallExpression,
  type Expression,
  type Identifier,
  type Literal,
  type SpreadElement,
  // eslint-disable-next-line import/no-unresolved
} from "estree";

import { RULE_DSL, type RuleDefinition } from "./dsl";
import { isRuleGroup } from "./helpers";

export * from "./dsl";
export * from "./helpers";
export * from "./validation";

const isArgumentTrueFalseIdentifier = (
  node: Expression | SpreadElement | CallExpression | Identifier | Literal,
): node is {
  type: "Identifier";
  name: "True" | "False";
} =>
  node.type === "Identifier" && (node.name === "True" || node.name === "False");

export const astNodeToRuleDefinition = (
  node: Expression | SpreadElement | CallExpression | Identifier | Literal,
): RuleDefinition => {
  if (node.type !== "CallExpression") {
    throw new Error("Expected call expression");
  }

  if (node.callee.type !== "Identifier") {
    throw new Error("Expected identifier");
  }

  if (node.callee.name === RULE_DSL.ALL || node.callee.name === RULE_DSL.ANY) {
    return {
      name: node.callee.name,
      args: node.arguments.map(astNodeToRuleDefinition),
    };
  }

  if (
    !(
      node.callee.name === RULE_DSL.IS ||
      node.callee.name === RULE_DSL.IS_NOT ||
      node.callee.name === RULE_DSL.IS_SET ||
      node.callee.name === RULE_DSL.IS_NOT_SET ||
      node.callee.name === RULE_DSL.GREATER_THAN ||
      node.callee.name === RULE_DSL.LESS_THAN ||
      node.callee.name === RULE_DSL.STARTS_WITH ||
      node.callee.name === RULE_DSL.ENDS_WITH ||
      node.callee.name === RULE_DSL.CONTAINS ||
      node.callee.name === RULE_DSL.NOT_CONTAINS ||
      node.callee.name === RULE_DSL.LENGTH_IS ||
      node.callee.name === RULE_DSL.LENGTH_GREATER_THAN ||
      node.callee.name === RULE_DSL.LENGTH_LESS_THAN
    )
  ) {
    throw new Error(
      `Expected valid function name. Received ${node.callee.name}.`,
    );
  }

  const [left, right, extraArgument] = node.arguments;

  if (left === undefined) {
    throw new Error("Expected left argument");
  }

  if (left.type !== "CallExpression") {
    throw new Error("Expected call expression");
  }

  if (left.callee.type !== "Identifier") {
    throw new Error("Expected identifier");
  }

  if (left.callee.name !== "v") {
    throw new Error("Expected v() call");
  }

  const variableIdArgument = left.arguments[0];

  if (variableIdArgument?.type !== "Literal") {
    throw new Error("Expected variable id argument");
  }

  if (typeof variableIdArgument.value !== "string") {
    throw new Error("Expected string literal");
  }

  // Unary operators
  if (
    node.callee.name === RULE_DSL.IS_SET ||
    node.callee.name === RULE_DSL.IS_NOT_SET
  ) {
    return {
      name: node.callee.name,
      args: [
        {
          type: "variable",
          id: variableIdArgument.value,
        },
      ],
    };
  }

  if (right === undefined) {
    throw new Error("Expected right argument");
  }

  let rightArgumentValue;

  if (right.type === "Literal") {
    rightArgumentValue = right.value;
  } else if (right.type === "UnaryExpression") {
    if (right.operator !== "-") {
      throw new Error('Expected "-" operator');
    }

    if (right.argument.type !== "Literal") {
      throw new Error("Expected literal");
    }

    if (typeof right.argument.value !== "number") {
      throw new Error("Expected number literal");
    }

    rightArgumentValue = -right.argument.value;
  } else if (isArgumentTrueFalseIdentifier(right)) {
    rightArgumentValue = right.name === "True";
  } else {
    throw new Error(`Expected literal or unary expression. Received ${right}`);
  }

  if (
    typeof rightArgumentValue !== "string" &&
    typeof rightArgumentValue !== "number" &&
    typeof rightArgumentValue !== "boolean"
  ) {
    throw new Error("Expected string or number literal");
  }

  // Numeric operators
  if (
    node.callee.name === RULE_DSL.LESS_THAN ||
    node.callee.name === RULE_DSL.GREATER_THAN ||
    node.callee.name === RULE_DSL.LENGTH_IS ||
    node.callee.name === RULE_DSL.LENGTH_GREATER_THAN ||
    node.callee.name === RULE_DSL.LENGTH_LESS_THAN
  ) {
    if (typeof rightArgumentValue !== "number") {
      throw new Error("Expected number literal");
    }

    return {
      name: node.callee.name,
      args: [
        { type: "variable", id: variableIdArgument.value },
        rightArgumentValue,
      ],
    };
  }

  // String operators
  if (
    node.callee.name === RULE_DSL.STARTS_WITH ||
    node.callee.name === RULE_DSL.ENDS_WITH ||
    node.callee.name === RULE_DSL.CONTAINS ||
    node.callee.name === RULE_DSL.NOT_CONTAINS
  ) {
    if (typeof rightArgumentValue !== "string") {
      throw new Error("Expected string literal");
    }

    if (extraArgument) {
      if (!isArgumentTrueFalseIdentifier(extraArgument)) {
        throw new Error("Expected boolean identifier");
      }

      return {
        name: node.callee.name,
        args: [
          { type: "variable", id: variableIdArgument.value },
          rightArgumentValue,
          extraArgument.name === "True",
        ],
      };
    }

    return {
      name: node.callee.name,
      args: [
        { type: "variable", id: variableIdArgument.value },
        rightArgumentValue,
      ],
    };
  }

  // Next: IS and IS_NOT operators

  if (typeof rightArgumentValue === "string") {
    if (extraArgument) {
      if (!isArgumentTrueFalseIdentifier(extraArgument)) {
        throw new Error("Expected boolean identifier");
      }

      return {
        name: node.callee.name,
        args: [
          { type: "variable", id: variableIdArgument.value },
          rightArgumentValue,
          extraArgument.name === "True",
        ],
      };
    }

    return {
      name: node.callee.name,
      args: [
        { type: "variable", id: variableIdArgument.value },
        rightArgumentValue,
      ],
    };
  }

  return {
    name: node.callee.name,
    args: [
      { type: "variable", id: variableIdArgument.value },
      rightArgumentValue,
    ],
  };
};

export const parseRuleQuery = (query: string): RuleDefinition | null => {
  let ast: ReturnType<typeof parseScript>;

  try {
    ast = parseScript(query);
  } catch (e) {
    console.error(e);

    return null;
  }

  const body = ast.body[0];

  if (!body) {
    return null;
  }

  if (body.type !== "ExpressionStatement") {
    return null;
  }

  if (body.expression.type !== "CallExpression") {
    return null;
  }

  try {
    return astNodeToRuleDefinition(body.expression);
  } catch (e) {
    console.error(e);

    return null;
  }
};

export const serializeRuleset = (rule: RuleDefinition): string => {
  if (rule.args.length === 0) {
    return "";
  }

  if (isRuleGroup(rule)) {
    return `${rule.name}(${rule.args.map(serializeRuleset).join(", ")})`;
  }

  return `${rule.name}(${rule.args
    .map((arg) => {
      if (typeof arg === "string" || typeof arg === "number") {
        return JSON.stringify(arg);
      }

      if (typeof arg === "boolean") {
        return arg ? "True" : "False";
      }

      return `v("${arg.id}")`;
    })
    .join(", ")})`;
};
