import { useCallback, useState } from "react";

export const useHoverDelay = (delayMs = 1000) => {
  const [isOpen, setIsOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState<number>();

  const handleMouseEnter = useCallback(() => {
    // explicitly use `window.setTimeout` otherwise
    // TS gets confused with Node's setTimeout
    setDelayHandler(
      window.setTimeout(() => {
        setIsOpen(true);
      }, delayMs),
    );
  }, [delayMs]);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(delayHandler);
    setIsOpen(false);
  }, [delayHandler]);

  return {
    isOpen,
    handleMouseEnter,
    handleMouseLeave,
  };
};
