export const DASHBOARD_PAGE_OPTIONS = [
  {
    label: "Campaigns",
    value: "/answers/campaigns",
    description: "Activity on the Campaigns page",
  },
  {
    label: "Variables",
    value: "/answers/variables",
    description: "Activity on the Variable Manager page",
  },
  {
    label: "Knowledge",
    value: "/answers/knowledge",
    description: "Activity on the Knowledge page",
  },
  {
    label: "Answers",
    value: "/answers",
    description: "Activity on the Answers page",
  },
  {
    label: "Training similarities",
    value: "/improvements/training-optimizer",
    description: "Activity on the Training Similarities page",
  },
  {
    label: "Not helpful",
    value: "/improvements/not-helpful",
    description: "Activity on the Not Helpful page",
  },
  {
    label: "Clarifications",
    value: "/improvements/clarifications",
    description: "Activity on the Clarifications page",
  },
  {
    label: "Improvements",
    value: "/improvements",
    description: "Activity on the Improvements page",
  },
  {
    label: "Reports",
    value: "/analytics/reports",
    description: "Activity on the Analytics Reports page",
  },
  {
    label: "Goals",
    value: "/analytics/goals",
    description: "Activity on the Goals page",
  },
  {
    label: "Events",
    value: "/analytics/events",
    description: "Activity on the Events page",
  },
  {
    label: "Analytics",
    value: "/analytics",
    description: "Activity on the Analytics page",
  },
  {
    label: "Conversations",
    value: "/conversations",
    description: "Activity on the Conversations page",
  },
  {
    label: "Team",
    value: "/settings/team",
    description: "Activity on the Team page",
  },
  {
    label: "Integrations",
    value: "/settings/integrations",
    description: "Activity on the Integrations page",
  },
  {
    label: "Filters",
    value: "/settings/filters",
    description: "Activity on the Filters and Redactions page",
  },
  {
    label: "Privacy",
    value: "/settings/privacy",
    description: "Activity on the Privacy page",
  },
  {
    label: "Settings answers",
    value: "/settings/answers",
    description: "Activity on the Answer Settings page",
  },
  {
    label: "SSO",
    value: "/settings/sso",
    description: "Activity on the Single Sign-On page",
  },
  {
    label: "Settings",
    value: "/settings",
    description: "Activity on the General Settings page",
  },
];
