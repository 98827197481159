import { Tooltip } from "@adasupport/byron";
import { bindActionCreators } from "@reduxjs/toolkit";
import classNames from "classnames";
import PropTypes from "prop-types";
import queryString from "query-string";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import {
  refreshClient,
  saveClientPlatform,
  saveClientPlatformSilent,
  updatePlatform,
} from "actions";
import { closeModal } from "actions/modal";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import ReadOnlyInput from "components/Common/ReadOnlyInput";
import SvgIcon from "components/Common/SvgIcon";
import { Title } from "components/Common/Title";
import { POPUP_WINDOW_PROPS } from "constants/popupWindow";

import { InputRow } from "./InputRow";
import "./styles.scss";

// Link to the Salesforce Guide
export const SALESFORCE_GUIDE_URL =
  "https://docs.ada.cx/ada-glass-for-salesforce";

const BLOCK_NAME = "SalesforceLAModal";

export class SalesforceLAModal extends React.Component {
  /**
   * {React.RefObject} Ref to the modal content, used by <InputGroup />
   */
  modalRef = React.createRef();

  static propTypes = {
    platform: ImmutablePropTypes.map.isRequired,
    closeModalAction: PropTypes.func.isRequired,
    saveClientPlatformAction: PropTypes.func.isRequired,
    saveClientPlatformSilentAction: PropTypes.func.isRequired,
    updatePlatformAction: PropTypes.func.isRequired,
    refreshClientAction: PropTypes.func.isRequired,
  };

  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    [
      "updatePlatform",
      "updateAuth",
      "handleConnectClick",
      "handleCancelClick",
      "handleDisconnectClick",
      "handleFinishClick",
      "handleStartClick",
      "handleErrorBannerClose",
      "handleSuccessBannerClose",
    ].forEach((methodName) => {
      this[methodName] = this[methodName].bind(this);
    });

    this.state = {
      /**
       * {Boolean} Starts false, switches to true once the user connects to display a banner
       */
      isConnected: false,

      /**
       * {Boolean} Whether or not to show the introduction screen
       */
      shouldShowIntro: !props.platform.get("isSaved"),

      /**
       * {Boolean} Starts false, if the form has errors switch this to true to display a banner
       */
      hasError: false,
    };
  }

  /**
   * Use componentDidUpdate to detect when a connection was made.
   * @param {Object} nextProps
   */
  componentDidUpdate(nextProps) {
    const { isConnected } = this.state;

    if (isConnected) {
      return;
    }

    const { platform } = this.props;
    const oldToken = nextProps.platform.getIn(["record", "accessToken"]);
    const newToken = platform.getIn(["record", "accessToken"]);

    if (newToken.length > 0 && oldToken === "") {
      this.setState({
        isConnected: true,
      });
    }
  }

  /**
   * Creates a new platform record with the updated fields, and then passes it to Redux
   *
   * @param {Object} payload
   *    Object containing the updated fields
   */
  updatePlatform(payload) {
    const { updatePlatformAction } = this.props;

    updatePlatformAction("salesforce_liveagent", payload);
  }

  /**
   * Helper function, wraps the updated auth fields in an object, so that we can re-use
   *    `this.updatePlatform`
   *
   * @param {Object} auth The updated auth fields
   */
  updateAuth(auth) {
    this.updatePlatform({ auth });
  }

  /**
   * Builds a Salesforce Oauth URL
   *
   * Checkout the 'OAuth 2.0 Web Server Authentication Flow' guide on Salesforce for a list of all
   *    possible things you can pass to the URL.
   * @see https://help.salesforce.com/articleView?id=remoteaccess_oauth_web_server_flow.htm
   *
   * @returns {String}
   */
  buildSalesforceOauthUrl() {
    const { platform } = this.props;
    const trailingSlash = /\/$/;
    const queryParams = queryString.stringify({
      response_type: "code",
      client_id: platform.getIn(["record", "auth", "clientId"]),
      client_secret: platform.getIn(["record", "auth", "clientSecret"]),
      redirect_uri: platform.getIn(["record", "auth", "redirectUri"]),
    });
    const instanceUrl = platform
      .getIn(["record", "auth", "instanceUrl"])
      .replace(trailingSlash, "");

    return `${instanceUrl}/services/oauth2/authorize?${queryParams}`;
  }

  /**
   * Handles what happens when the user clicks the connect button
   *
   * @param {React.MouseEvent<HTMLElement>} e
   *    The click event from the clicked element
   */
  handleConnectClick(e) {
    e.preventDefault();

    const { platform, saveClientPlatformSilentAction } = this.props;
    const salesforceUrl = this.buildSalesforceOauthUrl();

    saveClientPlatformSilentAction(platform);

    // Open the popup window
    const win = window.open(
      salesforceUrl,
      "Salesforce_Oauth",
      POPUP_WINDOW_PROPS,
    );

    // Since popup.onbeforeunload doesn't let us execute code anymore (for security), we'll need
    // to set an interval to listen for when the popup window closes
    const interval = setInterval(() => {
      if (win.closed) {
        clearInterval(interval);
        this.handlePopupWindowClose();
      }
    }, 1000);
  }

  /**
   * Handles what happens once we detect the user has closed the connection success popup window
   */
  handlePopupWindowClose() {
    const { refreshClientAction } = this.props;
    refreshClientAction();
  }

  /**
   * Handles what happens when the user clicks the `Cancel` button in the modal footer
   *
   * @param {React.MouseEvent<HTMLElement>} e
   *    The click event from the clicked element
   */
  handleCancelClick(e) {
    e.preventDefault();
    const { closeModalAction } = this.props;

    closeModalAction();
  }

  /**
   * Handles what happens when the user clicks the `Start` button on the first screen
   *
   * @param {React.MouseEvent<HTMLElement>} e
   *    The click event from the clicked element
   */
  handleStartClick(e) {
    e.preventDefault();

    this.setState({ shouldShowIntro: false });
  }

  /**
   * Handles what happens when the user clicks the `Finish` button on the second screen
   *
   * @param {React.MouseEvent<HTMLElement>} e
   *    The click event from the clicked element
   */
  handleFinishClick(e) {
    e.preventDefault();

    const { platform, saveClientPlatformAction } = this.props;
    const enabledPlatform = platform.setIn(["record", "enabled"], true);

    saveClientPlatformAction(enabledPlatform);
  }

  /**
   * Handles what happens when the user clicks the `x` icon on the error banner on the second screen
   *
   * @param {React.MouseEvent<HTMLElement>} e
   *    The click event from the clicked icon
   */
  handleErrorBannerClose(e) {
    e.preventDefault();

    this.setState({
      hasError: false,
    });
  }

  /**
   * Handles what happens when the user clicks the `x` icon on the success banner on the second
   * screen
   *
   * @param {React.MouseEvent<HTMLElement>} e
   *    The click event from the clicked icon
   */
  handleSuccessBannerClose(e) {
    e.preventDefault();

    this.setState({
      isConnected: false,
    });
  }

  /**
   * Handles what happens when the user clicks the disconnect button on the second screen.
   *
   * @param {React.MouseEvent<HTMLElement>} e
   *    The click event from the clicked button
   */
  handleDisconnectClick(e) {
    e.preventDefault();

    const { platform, saveClientPlatformSilentAction } = this.props;
    const updatedRecord = platform
      .setIn(["record", "accessToken"], "")
      .setIn(["record", "refreshToken"], "")
      .setIn(["record", "enabled"], false)
      .setIn(["record", "auth", "email"], "");

    saveClientPlatformSilentAction(updatedRecord);
  }

  /**
   * Returns the HTML for the intro screen with a link to the Salesforce guide
   *
   * @returns {React.ReactNode}
   */
  static renderIntro() {
    return (
      <div className={`${BLOCK_NAME}__intro`}>
        <h6 className={`${BLOCK_NAME}__intro-title`}>Before you start</h6>
        <p>
          We recommend you have our Salesforce Guide open as well as your
          Salesforce Dashboard while completing the setup.
        </p>
        <a
          href={SALESFORCE_GUIDE_URL}
          target="_sf-guide"
          rel="noopener noreferrer"
        >
          <SvgIcon icon="OpenLink" height={18} />
          Open Salesforce Guide
        </a>
      </div>
    );
  }

  /**
   * Returns the HTML for the main screen, which contains the form fields for the user
   *
   * @returns {React.ReactNode}
   */
  renderForm() {
    const { platform } = this.props;
    const { hasError, isConnected } = this.state;
    const isFormComplete = platform.get("isFormComplete");
    const hasAccessToken = platform.getIn(["record", "accessToken"]) !== "";

    return (
      <>
        <Banner icon="QuestionCircleFilled">
          Need help?{" "}
          <a
            href={SALESFORCE_GUIDE_URL}
            target="_sf-guide"
            rel="noopener noreferrer"
          >
            Open Salesforce Guide
          </a>{" "}
          to help you complete the setup.
        </Banner>

        <Banner
          icon="ErrorFilled"
          intent="negative"
          onClose={this.handleErrorBannerClose}
          isVisible={hasError}
        >
          We couldn’t link your account. Verify the highlighted fields and try
          again.
        </Banner>

        <Banner
          icon="CircleCheckmark"
          intent="positive"
          isVisible={isConnected}
          onClose={this.handleSuccessBannerClose}
        >
          We linked your account successfully.
        </Banner>

        <section className={`${BLOCK_NAME}__row`}>
          <InputRow
            label="Salesforce Host URL"
            tooltip={`The Host URL in Deployment Code, look under the script tag's
              liveagent.init parameters for the first parameter. Only enter the URL without /chat
              at the end.`}
            value={platform.getIn(["record", "salesforceHostnameUrl"])}
            placeholder="e.g https://example.salesforceliveagent.com"
            containerRef={this.modalRef.current}
            onChange={(e) =>
              this.updatePlatform({ salesforceHostnameUrl: e.target.value })
            }
          />
        </section>

        <section className={`${BLOCK_NAME}__row ${BLOCK_NAME}__row--split`}>
          <InputRow
            // N.B.: "split" rows get an extra class name:
            className={`${BLOCK_NAME}__cell ph-no-capture`}
            label="Organization ID"
            tooltip={`In Deployment Code, look under the script tag's liveagent.init
              parameters for the third parameter.`}
            value={platform.getIn(["record", "organizationId"])}
            placeholder="e.g 00A0v0099909949"
            containerRef={this.modalRef.current}
            onChange={(e) =>
              this.updatePlatform({ organizationId: e.target.value })
            }
          />

          <InputRow
            className={`${BLOCK_NAME}__cell ph-no-capture`}
            label="Deployment ID"
            tooltip={`In Deployment Code, look under the script tag's
              liveagent.init paramter for the second parameter.`}
            value={platform.getIn(["record", "deploymentId"])}
            placeholder="e.g 5720v0444000C9"
            containerRef={this.modalRef.current}
            onChange={(e) =>
              this.updatePlatform({ deploymentId: e.target.value })
            }
          />
        </section>

        <section className={`${BLOCK_NAME}__row`}>
          <InputRow
            label="Instance URL"
            tooltip="In Company Settings under My Domain, use your custom domain."
            value={platform.getIn(["record", "auth", "instanceUrl"])}
            placeholder="e.g https://example.my.salesforce.com"
            containerRef={this.modalRef.current}
            onChange={(e) => this.updateAuth({ instanceUrl: e.target.value })}
          />
        </section>

        <section className={`${BLOCK_NAME}__row ${BLOCK_NAME}__row--split`}>
          <InputRow
            className={`${BLOCK_NAME}__cell ph-no-capture`}
            label="Consumer Key"
            tooltip="The consumer ID found after you created your Connected App."
            value={platform.getIn(["record", "auth", "clientId"])}
            placeholder="e.g 1a2b3c4d5dghik"
            containerRef={this.modalRef.current}
            onChange={(e) => this.updateAuth({ clientId: e.target.value })}
          />

          <InputRow
            className={`${BLOCK_NAME}__cell ph-no-capture`}
            label="Consumer Secret"
            tooltip="The consumer secret found after you created your Connected App."
            value={platform.getIn(["record", "auth", "clientSecret"])}
            placeholder="e.g 123abc456defghikllmnop"
            containerRef={this.modalRef.current}
            onChange={(e) => this.updateAuth({ clientSecret: e.target.value })}
          />
        </section>

        <section className={`${BLOCK_NAME}__row`}>
          <InputRow
            label="Redirect URL"
            tooltip={`Use this to fill out the Callback URL for your connected app.
              We fill this in for you.`}
            className="ph-no-capture"
            value={platform.getIn(["record", "auth", "redirectUri"])}
            placeholder="e.g https://test.ada.support/oauth/salesforce_liveagent"
            containerRef={this.modalRef.current}
            onChange={(e) => this.updateAuth({ redirectUri: e.target.value })}
            {...(process.env.NODE_ENV === "production"
              ? { inputComponent: ReadOnlyInput }
              : {})}
          />
        </section>

        <hr className={`${BLOCK_NAME}__divider`} />

        <div className={`${BLOCK_NAME}__link-block`}>
          <Title headingLevel="h4">Link Salesforce Admin Account</Title>
          <p>
            Allow access to a Salesforce Admin Account before completing this
            integration.
          </p>
          {hasAccessToken ? (
            <div className={`${BLOCK_NAME}__connected-status`}>
              <SvgIcon icon="CircleCheckmark" height={24} />
              {platform.getIn(["record", "auth", "email"])}
              <button
                type="button"
                onClick={this.handleDisconnectClick}
                className={`${BLOCK_NAME}__disconnect`}
              >
                Disconnect
              </button>
            </div>
          ) : (
            <Tooltip
              text="Complete fields before connecting an account"
              isShown={!isFormComplete}
            >
              <Button
                text="Link Salesforce Account"
                title="Link Salesforce Account"
                icon="Link"
                disabled={!isFormComplete}
                onClick={this.handleConnectClick}
              />
            </Tooltip>
          )}
        </div>
      </>
    );
  }

  /**
   * Returns the content for the Salesforce settings modal
   *
   * @returns {React.ReactNode}
   */
  render() {
    const { platform } = this.props;
    const { shouldShowIntro } = this.state;
    const isSaveEnabled =
      platform.get("isValid") &&
      platform.getIn(["record", "accessToken"]) !== "" &&
      platform.getIn(["record", "refreshToken"]) !== "";
    const modalClass = classNames("Modal__modal", `${BLOCK_NAME}__modal`, {
      [`${BLOCK_NAME}__modal--intro`]: shouldShowIntro,
    });

    return (
      <div className={modalClass}>
        <h5 className="Modal__title">Salesforce Live Chat Settings</h5>

        <div className="Modal__content" ref={this.modalRef}>
          {shouldShowIntro
            ? SalesforceLAModal.renderIntro()
            : this.renderForm()}
        </div>

        <footer className="Modal__bottom">
          <div className={`${BLOCK_NAME}__actions`}>
            <Button
              onClick={this.handleCancelClick}
              text="Cancel"
              title="Cancel"
              customClassName={`${BLOCK_NAME}__cancel-button`}
              light
            />

            {shouldShowIntro ? (
              <Button
                onClick={this.handleStartClick}
                text="Start"
                title="Start"
              />
            ) : (
              <Tooltip
                text="Connect an account to finish"
                isShown={!isSaveEnabled}
              >
                <Button
                  onClick={this.handleFinishClick}
                  text="Finish"
                  title="Finish"
                  disabled={!isSaveEnabled}
                  isLoading={platform.get("isLoading")}
                />
              </Tooltip>
            )}
          </div>
        </footer>
      </div>
    );
  }
}

/**
 * @param {Object} state
 * @param {unknown} state.platforms
 * @returns {Object}
 */
const mapState = ({ platforms }) => ({
  platform: platforms.get("salesforce_liveagent"),
});

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      closeModalAction: closeModal,
      saveClientPlatformAction: saveClientPlatform,
      saveClientPlatformSilentAction: saveClientPlatformSilent,
      updatePlatformAction: updatePlatform,
      refreshClientAction: refreshClient,
    },
    dispatch,
  );

const Connector = connect(mapState, mapDispatch)(SalesforceLAModal);

export default Connector;
