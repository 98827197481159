import { Icon, Spinner } from "@adasupport/byron";
import { type TreeNodeProps } from "rc-tree/lib/TreeNode";
import React from "react";

import { cssVariables } from "constants/css";

import * as S from "./styles";

export const SwitcherIcon = (
  treeNodeProps: TreeNodeProps,
  isSearchMode?: boolean,
  isSidebar?: boolean,
) => {
  const { expanded, isLeaf, loading } = treeNodeProps;
  const iconHeight = isSidebar
    ? cssVariables.svgIconHeight.numHeight
    : cssVariables.mediumSvgIconHeight.numHeight;

  if (loading) {
    return (
      <S.SwitcherIcon>
        <Spinner size="sm" />
      </S.SwitcherIcon>
    );
  }

  const icon = expanded ? (
    <Icon.ChevronDown height={iconHeight} />
  ) : (
    <Icon.ChevronRight height={iconHeight} />
  );

  return <S.SwitcherIcon>{!isLeaf && !isSearchMode && icon}</S.SwitcherIcon>;
};
