import { createSelector } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";

import { VOICE_MODALITY } from "components/Shared/Pages/Responses/ResponsesEditor/constants";

import { selectActiveModality } from "./selectActiveModality";
import { selectActiveResponse } from "./selectActiveResponse";
/**
 * this selector returns all languages used within the active response.
 *
 * Because reselect memoization only works with reference equality and here we're
 * creating a new array everytime, we're using a custom equality function from lodash
 * to compare the results. This will prevent re-renders for two results with same array content.
 *
 * No problem doing it in this case because it's a simple array of strings, but be cautious about
 * using custom equality checks, since deep comparison for things like complex objects can be expensive.
 */
export const selectResponseMessagesLanguages = createSelector(
  selectActiveResponse,
  selectActiveModality,
  (activeResponse, modality) => {
    if (!activeResponse) return [];

    if (modality === VOICE_MODALITY) {
      return activeResponse.messagesVoice.keySeq().toArray();
    }
 
      return activeResponse.messages.keySeq().toArray();
    
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);
