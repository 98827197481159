// @ts-strict-ignore
import * as Immutable from "immutable";

import { FireableBusinessEvent } from "components/Common/EmbeddedBusinessEvent/types";
import { validateJSON, validateXML } from "services/payload-validators";
import { type TypedMap } from "types/global";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

function isTextValid(type: string, text: string): boolean {
  return type === "json" ? validateJSON(text) : validateXML(text);
}

export type HTTPVariablesData = Immutable.List<
  TypedMap<{
    key: string;
    variableId: string;
  }>
>;

export interface HttpMessageRecordAttributes
  extends BaseMessageRecordAttributes {
  type: "http_request_recipe";
  headers: unknown;
  headersList: Immutable.List<
    TypedMap<{
      key: string;
      value: string;
    }>
  >;
  errorResponseId: string;
  errorResponse: boolean;
  isHandoff: boolean;
  shouldPause: boolean;
  requestUrl: string;
  // requestPayload is a string for XML payloads and a list of maps for JSON payloads
  // requestPayload can also be a string when the HTTPMessageRecord is created in the reducer,
  // but before it is processed by `fromAPI`
  requestPayload: string | Immutable.List<Immutable.Map<string, unknown>>;
  requestPayloadType: string;
  requestType: string;
  variablesData: HTTPVariablesData;
  successBusinessEvent?: FireableBusinessEvent | null;
}

export const httpMessageRecordDefaultAttributes = {
  type: "http_request_recipe",
  headers: Immutable.OrderedMap({ "": "" }),
  headersList: Immutable.List(),
  errorResponseId: null as string,
  errorResponse: true,
  isHandoff: false,
  shouldPause: false,
  requestUrl: "",
  requestPayload: "",
  requestPayloadType: "json",
  requestType: "GET",
  variablesData: Immutable.List(),
  successBusinessEvent: new FireableBusinessEvent(),
} as const;

export function getInvalidHttpFields(
  message: HTTPMessageRecord,
): Immutable.List<string> {
  const invalidFields: Set<string> = new Set();

  const isXML = message.requestPayloadType === "data";

  message.variablesData
    .filter((data) => !data.get("variableId"))
    .forEach((data, i) => invalidFields.add(`invalidVariableId-${i}`));

  if (message.requestUrl.length <= 0) {
    invalidFields.add("requestUrl");
  }

  if (
    isXML &&
    ["PUT", "PATCH", "POST"].includes(message.requestType) &&
    !isTextValid("data", message.get("requestPayload") as string)
  ) {
    invalidFields.add("requestPayload");
  }

  if (message.errorResponse && !message.errorResponseId) {
    invalidFields.add("errorResponse");
  }

  return Immutable.List(invalidFields);
}

export class HTTPMessageRecord extends BaseMessageRecord<HttpMessageRecordAttributes>(
  httpMessageRecordDefaultAttributes,
) {
  static getInvalidFields = getInvalidHttpFields;
}
