import { type AnyAction } from "@reduxjs/toolkit";
import produce from "immer";

import {
  type BuilderABTest,
  type BuilderABTestDTO,
  type BuilderABTestsState,
} from "features/ABTesting/types";

export function serializeTest(test: Partial<BuilderABTest>) {
  return {
    _id: test.id,
    updated: test.updated,
    updated_by: test.updatedBy,
    name: test.name,
    archived: test.archived,
    variant_names: test.variantNames,
    variant_weights: test.variantWeights,
    status: test.status,
    response_id: test.responseId,
    business_event_key: test.businessEventKey,
    started_at: test.startedAt,
    completed_at: test.completedAt,
  };
}

function deserializeTest(test: BuilderABTestDTO) {
  return {
    id: test._id,
    updated: test.updated,
    updatedBy: test.updated_by,
    name: test.name,
    archived: test.archived,
    variantNames: test.variant_names,
    variantWeights: test.variant_weights,
    status: test.status,
    responseId: test.response_id,
    businessEventKey: test.business_event_key,
    startedAt: test.started_at,
    completedAt: test.completed_at,
  };
}

const DEFAULT_BUILDER_AB_TESTS_STATE: BuilderABTestsState = {
  loading: false,
  loaded: false,
  builderABTests: [],
  lastSavedBuilderABTests: [],
};

export const builderABTests = (
  state = DEFAULT_BUILDER_AB_TESTS_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case "FETCH_BUILDER_AB_TESTS_REQUEST":
      return produce(state, (draftState) => {
        draftState.loading = true;
      });

    case "FETCH_BUILDER_AB_TESTS_SUCCESS":
      return produce(state, (draftState) => {
        draftState.loaded = true;
        draftState.loading = false;
        draftState.builderABTests = action.builderABTests.map(
          (test: BuilderABTestDTO) => deserializeTest(test),
        );
        draftState.lastSavedBuilderABTests = action.builderABTests.map(
          (test: BuilderABTestDTO) => deserializeTest(test),
        );
      });

    case "FETCH_BUILDER_AB_TESTS_FAILURE":
      return produce(state, (draftState) => {
        draftState.loading = false;
      });

    case "SET_EMPTY_BUILDER_AB_TESTS":
      return produce(state, (draftState) => {
        draftState.loaded = true;
        draftState.loading = false;
        draftState.builderABTests = [];
      });

    case "UPDATE_BUILDER_AB_TEST":
      return produce(state, (draftState) => {
        draftState.builderABTests = draftState.builderABTests.map((test) =>
          test.id === action.testId ? action.updatedTest : test,
        );
      });

    case "DELETE_BUILDER_AB_TEST":
      return produce(state, (draftState) => {
        draftState.builderABTests = draftState.builderABTests.filter(
          (test) => test.id !== action.testId,
        );
      });

    case "UNDO_BUILDER_AB_TEST":
      return produce(state, (draftState) => {
        const savedTest = state.lastSavedBuilderABTests.find(
          (test) => test.id === action.testId,
        );
        draftState.builderABTests = draftState.builderABTests.map((test) =>
          test.id === action.testId ? savedTest : test,
        ) as BuilderABTest[];
      });

    default:
      return state;
  }
};
