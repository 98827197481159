import Immutable from "immutable";

import { ClientApiKeysRecord } from "reducers/clientApiKeys/helpers";

interface ClientApiKeysStateAttributes {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  apiKeyNew: string | null;
  clientApiKeys: Immutable.List<ClientApiKeysRecord>;
}

export class ClientApiKeysStateRecord extends Immutable.Record<ClientApiKeysStateAttributes>(
  {
    loading: false,
    loaded: false,
    error: false,
    apiKeyNew: null, // This should only be available once after a new key is generated
    clientApiKeys: Immutable.List<ClientApiKeysRecord>(),
  },
) {}

export const clientApiKeys = (
  state = new ClientApiKeysStateRecord(),
  action: {
    type: string;
    clientApiKeys: {
      date_created: number;
      is_revoked: boolean;
      expires_on: number | null;
      prefix: string | null;
    }[];
    apiKeyNew: string;
  },
) => {
  switch (action.type) {
    case "GENERATE_CLIENT_API_KEY_REQUEST":
      return state.merge({ loading: true });
    case "FETCH_CLIENT_API_KEYS_REQUEST":
      return state.merge({ loading: true });
    case "FETCH_CLIENT_API_KEYS_SUCCESS":
      return state.merge({
        loading: false,
        loaded: true,
        clientApiKeys: Immutable.List(
          action.clientApiKeys.map((clientApiKey) =>
            new ClientApiKeysRecord().merge({
              dateCreated: clientApiKey.date_created,
              isRevoked: clientApiKey.is_revoked,
              expiresOn: clientApiKey.expires_on,
              prefix: clientApiKey.prefix,
            }),
          ),
        ),
      });
    case "GENERATE_CLIENT_API_KEY_SUCCESS":
      return state.merge({
        loading: false,
        apiKeyNew: action.apiKeyNew,
      });
    case "GENERATE_CLIENT_API_KEY_FAILURE":
      return state.merge({
        loading: false,
        error: true,
      });
    case "FETCH_CLIENT_API_KEYS_FAILURE":
      return state.merge({
        loading: false,
        error: true,
      });
    default:
      return state;
  }
};
