import camelcase from "lodash.camelcase";

import { type Dispatch } from "actions/types";
import { type Client } from "services/client";
import { keys } from "services/helpers";
import {
  getTestBotCluster,
  getTestBotDomain,
  getTestBotHandle,
} from "services/test-bot";

import { type ResetParams, type WindowInfo } from "./types";

let chatterReadyResolve: ((chatterToken: string) => void) | undefined;

export const adaEmbed = Object.freeze({
  chatterReady: () =>
    new Promise((resolve) => {
      chatterReadyResolve = resolve;
    }),
  start: async (startOptions: Record<string, unknown>): Promise<void> => {
    /**
     * Start the Embed script
     */
    if (window.adaEmbed) {
      window.adaEmbed.start(startOptions);
    }
  },
  stop: async (): Promise<void> => window.adaEmbed && window.adaEmbed.stop(),
  toggle: async (): Promise<void> =>
    window.adaEmbed && window.adaEmbed.toggle(),
  setMetaFields: async (payload: Record<string, unknown>): Promise<void> =>
    window.adaEmbed && window.adaEmbed.setMetaFields(payload),
  getInfo: async (): Promise<WindowInfo> => {
    if (!window.adaEmbed) throw new Error("window.adaEmbed is not defined");

    return window.adaEmbed.getInfo();
  },
  reset: async (options: ResetParams): Promise<void> =>
    window.adaEmbed && window.adaEmbed.reset(options),
  subscribeEvent: window.adaEmbed && window.adaEmbed.subscribeEvent,
  unsubscribeEvent: window.adaEmbed && window.adaEmbed.unsubscribeEvent,
});

/**
 * Returns start options for test bot
 */
export const getTestBotStartOptions = (dispatch: Dispatch) => ({
  handle: getTestBotHandle(),
  domain: getTestBotDomain(),
  cluster: getTestBotCluster(),
  rolloutOverride: 1,
  hideMask: false,
  testMode: true,
  chatterTokenCallback: (chatterToken: string) => {
    if (chatterReadyResolve) {
      chatterReadyResolve(chatterToken);
    }

    dispatch({
      type: "SET_TEST_VARIABLES_MODAL_STATE",
      payload: { chatterToken },
    });
  },
  toggleCallback: (isChatOpen: boolean) => {
    dispatch({
      type: "SET_TEST_VARIABLES_MODAL_STATE",
      payload: { isTestBotOpen: isChatOpen },
    });
  },
});

/**
 * Returns a string containing all enabled features (camelCased)
 */
export const getEnabledFeatures = (features: Client["features"]): string =>
  keys(features)
    .filter((feature) => features[feature])
    // Camelcase for backwards compatibility
    .map((feature) => camelcase(feature))
    .join();
