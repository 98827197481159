import styled from "styled-components";

import { c, v } from "constants/css";

export const ModalContent = styled.div`
  padding: ${v.gUnitDouble};
  flex: 1;
`;

export const ModalSection = styled.section`
  padding: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled.h1`
  text-align: center;
  padding: 10px;
  font-weight: ${v.xHeavyFontWeight};
  font-size: ${v.xLargeFontSize};
`;

export const ModalDescription = styled.p`
  text-align: center;
  color: ${c.colorGrey4};
`;

export const InfoText = styled.p`
  font-size: ${v.xSmallFontSize};
  margin-bottom: ${v.gUnit};
`;

export const MarginTop8px = styled.div`
  margin-top: ${v.gUnit};
`;
