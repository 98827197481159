import { type BuilderABTest } from "features/ABTesting/types";

export const DEFAULT_BUILDER_AB_TEST: Partial<BuilderABTest> = {
  name: "",
  description: "",
  archived: false,
  variantNames: ["Control", "Variant 1"],
  variantWeights: [1, 1],
  status: "draft",
  responseId: "",
  businessEventKey: "",
};

export const BUILDER_AB_TEST_MAX_VARIANTS = 4;
