import styled from "styled-components";

import { c, v } from "constants/css";

export const LongDescription = styled.p`
  font-size: ${v.xSmallFontSize};
  height: ${v.smallLineHeight};
  color: ${c.colorGrey4};
  max-width: 640px;
  padding-left: 27px;
`;
