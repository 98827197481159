import { createAlert } from "actions/alerts";
import { type AsyncAction } from "actions/types";
import { type TrainedExpression } from "slices/training/types";

import { getBulkTrainingAddedMessage } from "./getBulkTrainingAddedMessage";

export function bulkTrainingAddedAlert(
  expressions: TrainedExpression[],
): AsyncAction {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(
      createAlert({
        message: getBulkTrainingAddedMessage({ state, expressions }),
        alertType: "success",
      }),
    );
  };
}
