import styled from "styled-components";

import { cssColors, cssVariables } from "constants/css";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalSection = styled.section`
  padding: 10px;
`;

export const ModalTitle = styled.h1`
  text-align: center;
  padding: 10px;
  font-weight: ${cssVariables.xHeavyFontWeight};
  font-size: ${cssVariables.xLargeFontSize};
`;

export const ModalDescription = styled.p`
  text-align: center;
  color: ${cssColors.colorGrey4};
  margin: 0 auto;
  width: 87%;
`;

export const InfoText = styled.p`
  font-size: ${cssVariables.xSmallFontSize};
  margin-bottom: ${cssVariables.gUnit};
`;

export const UploadButton = styled.div`
  display: flex;
  flex: 1;
  float: right;
  border-radius: ${cssVariables.mainBorderRadius};
  border: 1px solid ${cssColors.colorGrey2};
  padding: 8px;
  cursor: pointer;
  margin-bottom: ${cssVariables.gUnit};
  font-weight: ${cssVariables.heavyFontWeight};
`;

export const TextareaLabel = styled.div`
  display: flex;
  flex: 1;
`;

export const BoldTextLine = styled.div`
  position: relative;
  top: 7px;
  font-weight: ${cssVariables.heavyFontWeight};
`;

export const TextareaHeader = styled.div`
  display: flex;
`;

export const LanguageSelectLabel = styled.label`
  display: inline-block;
  cursor: text;
  color: ${cssColors.colorGrey4};
  margin-bottom: ${cssVariables.gUnit};
`;

export const BoldUnderlinedText = styled.span`
  font-weight: ${cssVariables.heavyFontWeight};
  text-decoration: underline;
`;

export const BoldText = styled.span`
  font-weight: ${cssVariables.heavyFontWeight};
`;
