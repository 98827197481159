import {
  type ConversationSummariesState,
  type ConversationSummary,
} from "./types";

const DEFAULT_CONVERSATION_SUMMARY: ConversationSummary = {
  id: "",
  conversationId: null,
  isAccurate: null,
  summary: null,
  created: 0,
  chatterName: "Anonymous",
  chatterId: null,
};

const DEFAULT_CONVERSATION_SUMMARIES_STATE: ConversationSummariesState = {
  loading: false,
  loaded: false,
  page: 1,
  summaries: [],
  chatterSummaries: [],
};

/**
 * Creates and return new conversation summary object containing
 * default values and/or the provided attributes.
 * @param attributes Attributes to be set on the object
 * @returns ConversationSummary
 */
export const createConversationSummary = (
  attributes?: Partial<ConversationSummary>,
) =>
  ({
    ...DEFAULT_CONVERSATION_SUMMARY,
    ...attributes,
  } as ConversationSummary);

/**
 * Creates and return new conversation summaries state object containing
 * default values and/or the provided attributes.
 * @param attributes Attributes to be set on the object
 * @returns ConversationSummariesState
 */
export const createConversationSummariesDefaultState = (
  attributes?: Partial<ConversationSummariesState>,
) =>
  ({
    ...DEFAULT_CONVERSATION_SUMMARIES_STATE,
    ...attributes,
  } as ConversationSummariesState);
