import { tokens } from "@adasupport/byron";
import styled from "styled-components";

import { c, colorTokens, v } from "constants/css";

export const Select = styled.div``;

export const SelectButton = styled.div`
  border: 1px solid ${tokens.colors.border.formInput};
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${tokens.colors.text.main};
  margin-bottom: 6px;
`;

export const Title = styled.li`
  width: 50%;
  font-size: ${v.xSmallFontSize};
  color: ${c.colorGrey3Active};
  padding: ${v.gUnit};
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListItemDescription = styled.div`
  margin-top: ${v.gUnitHalf};
  font-size: ${v.xSmallFontSize};
  color: ${colorTokens.textMuted};
`;
