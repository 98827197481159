import { type SetErrorPageAction } from "actions/error/types";
import { type ErrorState } from "reducers/error/types";

const DEFAULT_ERROR_STATE: ErrorState = {
  pageError: false,
  pageErrorStatus: "",
};

export const error = (
  state = DEFAULT_ERROR_STATE,
  action: SetErrorPageAction,
) => {
  switch (action.type) {
    case "SET_ERROR_PAGE":
      return {
        pageError: action.error,
        pageErrorStatus: action.status,
      };
    default:
      return state;
  }
};
