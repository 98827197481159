import moment from "moment-timezone";
import React, { useReducer } from "react";
import { useDispatch } from "react-redux";

import { createAlert as createAlertAction } from "actions/alerts";
import { Button } from "components/Common/Button";
import { InputText } from "components/Common/InputText";
import { Loading } from "components/Common/Loading";
import ReadOnlyInput from "components/Common/ReadOnlyInput";
import SvgIcon from "components/Common/SvgIcon";
import { c } from "constants/css";
import { useComponentDidMount } from "hooks/useComponentDidMount";
import { getAPIRoot } from "services/api-root";

import { fetchApiKeyInfo, generateApiKey } from "./actions";
import { initialState, reducer } from "./reducer";
import * as S from "./styles";

export function SettingsPlatformsEndUsersApiModal() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const globalDispatch = useDispatch();

  useComponentDidMount(() => {
    if (!state.loading) {
      fetchApiKeyInfo(dispatch);
    }
  });

  function generateApiKeyAndCloseConfirmation() {
    dispatch({ type: "CLOSE_CONFIRMATION_WARNING" });
    generateApiKey(dispatch);
  }

  function handleCopyClicked(textToCopy: string) {
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      globalDispatch(
        createAlertAction({
          message: "Copied to clipboard!",
          alertType: "success",
        }),
      );
    }
  }

  function showGenerateNewApiKeyWarningModal() {
    dispatch({ type: "SHOW_CONFIRMATION_WARNING" });
  }

  function cancelKeyGeneration() {
    dispatch({ type: "CLOSE_CONFIRMATION_WARNING" });
  }

  function renderConfirmationWarning() {
    return (
      <>
        <S.Title>Confirm API Key Generation</S.Title>
        <S.Description>
          Generating a new API Key will{" "}
          <S.BoldText>immediately invalidate</S.BoldText> your previous API key.
          Your API integrations will stop working until they&apos;re updated to
          use the new API key.
        </S.Description>
        <S.ButtonContainer>
          <Button light text="Cancel" onClick={() => cancelKeyGeneration()} />
          <Button
            light
            text="Generate a new API Key"
            onClick={() => generateApiKeyAndCloseConfirmation()}
          />
        </S.ButtonContainer>
      </>
    );
  }

  function renderLoadingState() {
    return (
      <S.Centered>
        <Loading />
      </S.Centered>
    );
  }

  function renderErrorState() {
    return (
      <S.WarningMessage>
        <SvgIcon icon="Error" height={20} fillColor={c.colorUiBad} />
        <S.WarningMessageText>Something went wrong.</S.WarningMessageText>
      </S.WarningMessage>
    );
  }

  function renderEmptyState() {
    return (
      <>
        <S.Title>API Key</S.Title>
        <S.Description>
          You haven&apos;t set up an End Users API key yet.
        </S.Description>
        <S.ButtonContainer>
          <Button
            light
            text="Generate a new API Key"
            onClick={() => generateApiKey(dispatch)}
          />
        </S.ButtonContainer>
      </>
    );
  }

  function renderWarningMessage() {
    return (
      <S.WarningMessage>
        <SvgIcon icon="Error" height={20} fillColor={c.colorUiBad} />
        <S.WarningMessageText>
          <S.BoldText>This API Key will be shown only once.</S.BoldText> You
          need to copy it before you close this modal.
        </S.WarningMessageText>
      </S.WarningMessage>
    );
  }

  const apiKeyUrl = `${getAPIRoot()}/end-users/v1`;

  function renderLatestApiKeyInfo() {
    return (
      <>
        {/* TODO(@davidson): Add docs link  */}
        {/* <S.Description>
          View the{" "}
          <a
            href=""
            target="_blank"
            rel="noopener noreferrer"
          >
            developer documentation
          </a>{" "}
          to learn more about the End User API.
        </S.Description> */}
        <S.Title>End Users API Base URL</S.Title>
        <ReadOnlyInput value={apiKeyUrl} />
        <S.Title>API Key</S.Title>
        <S.InputContainer>
          <InputText
            multiline
            value={state.apiKey || "••••••••••••••••••••••••••••••••••••••"}
            className="ph-no-capture"
            disabled
          />
          {state.apiKey && (
            <S.CopyButtonContainer>
              <Button
                light
                text="Copy"
                onClick={() => handleCopyClicked(state.apiKey as string)}
              />
            </S.CopyButtonContainer>
          )}
        </S.InputContainer>

        {state.apiKey ? (
          renderWarningMessage()
        ) : (
          <>
            <S.ExtraInfo>
              This API Key was generated at{" "}
              <S.BoldText>
                {state.metadata &&
                  moment(state.metadata.createdAt).format("LT")}
              </S.BoldText>{" "}
              on{" "}
              <S.BoldText>
                {state.metadata &&
                  moment(state.metadata.createdAt).format("MMM DD, YYYY")}
              </S.BoldText>
              .
            </S.ExtraInfo>
            <S.ButtonContainer>
              <Button
                light
                text="Generate a new API Key"
                onClick={() => showGenerateNewApiKeyWarningModal()}
              />
            </S.ButtonContainer>
          </>
        )}
      </>
    );
  }

  function renderContent() {
    if (state.confirmationWarning) {
      return renderConfirmationWarning();
    }

    if (state.loading) {
      return renderLoadingState();
    }

    if (state.error) {
      return renderErrorState();
    }

    if (!state.apiKey && !state.metadata) {
      return renderEmptyState();
    }

    return renderLatestApiKeyInfo();
  }

  return (
    <S.Root>
      <h5 className="Modal__title">End Users API</h5>
      <S.Container>{renderContent()}</S.Container>
    </S.Root>
  );
}
