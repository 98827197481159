import { createSelector } from "reselect";

import {
  MODALITY_DETAILS,
  type Modality,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type LanguageToMessagesMap } from "reducers/responsesLoaded/types";
import { type BlockConfigMap, selectBlockConfig } from "selectors/blocks";
import { getMessageTypes } from "services/responses";
import { selectActiveModality } from "services/responses/selectors/selectActiveModality";
import { selectActiveResponseId } from "services/responses/selectors/selectActiveResponseId";

export const hasIncompatibleBlocks = (
  messages: LanguageToMessagesMap,
  blockConfigs: BlockConfigMap,
  modality: Modality,
) => {
  const msgTypes = getMessageTypes(messages);

  return [...msgTypes].some((type) =>
    blockConfigs[type].unsupportedModalities.includes(modality),
  );
};

export const selectActiveModalityHasIncompatibleBlocks = createSelector(
  selectActiveModality,
  selectActiveResponseId,
  (state) => state.responsesLoaded,
  selectBlockConfig,

  (activeModality, responseId, responsesLoaded, blockConfigs) => {
    if (!responseId || !responsesLoaded.has(responseId)) {
      return false;
    }

    const response = responsesLoaded.get(responseId);

    if (!response) {
      return false;
    }

    const { messageField } = MODALITY_DETAILS[activeModality];

    const hasIncompatibles = hasIncompatibleBlocks(
      response.get(messageField) as LanguageToMessagesMap,
      blockConfigs,
      activeModality,
    );

    return hasIncompatibles;
  },
);
