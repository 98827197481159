import styled from "styled-components";

import { cssColors, cssVariables } from "constants/css";

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${cssColors.colorGrey1};
  border-radius: ${cssVariables.mainBorderRadius};
  margin-bottom: 10px;
  padding: 0 25px;
  height: 95px;
`;

export const IconWithText = styled.div`
  display: flex;
  align-items: center;

  & .SvgIcon + span {
    margin-left: 20px;
    font-weight: ${cssVariables.mediumFontWeight};
    font-size: ${cssVariables.smallFontSize};
  }
`;

export const CardAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .Button--dark {
    background-color: ${cssColors.colorBlack};
    color: ${cssColors.colorWhite};
    border: 1px solid ${cssColors.colorBlack};
  }
`;

export const Root = Card;
