import { type History } from "history";
import Immutable from "immutable";
import isEqual from "lodash.isequal";
import queryString from "query-string";

import {
  ConditionalMessageRecord,
  ConditionalRecord,
  ConditionalStatement,
  type MessageRecord,
  WeightedRandomIntegerMessageRecord,
} from "reducers/responses/messageRecords";
import { trackException } from "services/errorTracker";

import { type BuilderABTest, type BuilderABTestsState } from "./types";

export const VALID_STATUSES = ["Active", "Draft", "Complete"];

export function getBuilderABTestFrontendStatusHelper(status: string) {
  if (status === "draft") {
    return "Draft";
  }

  if (status === "active") {
    return "Active";
  }

  if (status === "complete") {
    return "Complete";
  }

  // Should never hit this, status is always "draft" | "active" | "complete"
  return "[error]";
}

export function getBuilderABTestFrontendStatus(builderABTest: BuilderABTest) {
  return getBuilderABTestFrontendStatusHelper(builderABTest.status);
}

export function generateABTestResponseMessages(
  testId: string,
  variableId: string, // ID of the variable indicating the chatter's assigned variant for this test
  weights: number[],
  variants: Immutable.List<Immutable.List<MessageRecord>>,
) {
  // The first block is a weighted random integer block. This block checks if the AB test
  // variable is set, and if it's not set already, sets a value for the variable.
  const firstMessage = new WeightedRandomIntegerMessageRecord({
    type: "weighted_random_integer",
    variableId,
    weights,
  });

  // A conditional needs at least two statements. The last one is treated as the else statement.
  // For our conditional, the else statement will be empty, so we use an empty statement.
  const emptyStatement = new ConditionalStatement({
    operator: "AND",
    conditions: Immutable.List([
      new ConditionalRecord({
        // TODO: investigate why this is getting validated even though it's the else statement
        leftOperand: variableId,
        comparisonOperator: "is null",
      }),
    ]),
    messages: Immutable.List([]),
  });

  // The second block is a conditional which checks the value of the AB test variable. This
  // determines which variant the chatter will see. Only messages of the branch matching the
  // variable value are shown.
  const secondMessage = new ConditionalMessageRecord({
    statements: variants
      .map(
        (variant, i) =>
          new ConditionalStatement({
            operator: "OR",
            conditions: Immutable.List([
              new ConditionalRecord({
                leftOperand: variableId, // If AB test variable
                comparisonOperator: "is", // is equal to
                rightOperand: i.toString(), // index i of this branch
                fieldType: "string",
              }),
              new ConditionalRecord({
                leftOperand: variableId, // If AB test variable
                comparisonOperator: "is null", // is not set
              }),
            ]),
            messages: variant, // then show the messages for variant with index i
          }),
      )
      .push(emptyStatement),
  });

  return Immutable.List([firstMessage, secondMessage]);
}

export function isResponseABTest(
  responseId: string | null | undefined,
  builderABTests: BuilderABTest[],
) {
  return builderABTests.some(
    (test) => test.responseId === responseId && test.status !== "complete",
  );
}

export function getResponseABTest(
  responseId: string,
  builderABTests: BuilderABTest[],
) {
  return builderABTests.find(
    (test) => test.responseId === responseId && test.status !== "complete",
  );
}

/**
 * Given the messages for one language of an AB test answer, returns a list of lists of messages.
 * List i contains the messages belonging to the variant with index i.
 */
export function getAllVariantMessages(messages: Immutable.List<MessageRecord>) {
  try {
    return messages
      .getIn([1, "statements"], Immutable.List())
      .map((statement: ConditionalStatement) => statement.get("messages"))
      .pop(); // pop because the last statement is the else statement, which is not a variant
  } catch (error) {
    const message = error instanceof Error ? error.message : "";

    throw Error(`[A/B_test_invalid_state]: ${message}`);
  }
}

export function hasWeightedRandomInteger(
  responseId: string,
  builderABTests: BuilderABTest[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages: Immutable.Map<string, any>,
): boolean {
  if (isResponseABTest(responseId, builderABTests)) {
    const languages = messages.keySeq().toArray();

    return languages.some(
      (language) =>
        messages.get(language).getIn([0, "type"]) === "weighted_random_integer",
    );
  }

  return false;
}

export function trackABTestInvalidState(
  responseId: string,
  builderABTests: BuilderABTest[],
  messages: Immutable.Map<string, unknown>,
) {
  const abTest = getResponseABTest(responseId, builderABTests);

  if (
    abTest &&
    !hasWeightedRandomInteger(responseId, builderABTests, messages)
  ) {
    trackException(
      new Error(
        `[A/B_test_invalid_state]:Failed to save new A/B test response structure with id ${responseId}: response not saved`,
      ),
    );
  }
}

export function generateVariantsOptions(variants: string[]) {
  return variants.map((str, index) => ({ label: str, value: index }));
}

export function hasResponseABTestChanged(
  responseId: string,
  builderABTestsState: BuilderABTestsState,
) {
  const builderABTest = getResponseABTest(
    responseId,
    builderABTestsState.builderABTests,
  );
  const lastSavedBuilderABTest =
    builderABTestsState.lastSavedBuilderABTests.find(
      (test) => test.id === builderABTest?.id,
    );

  return !isEqual(builderABTest, lastSavedBuilderABTest);
}

export function isResponseABTestValid(
  responseId: string,
  builderABTests: BuilderABTest[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages: Immutable.Map<string, any>,
) {
  if (isResponseABTest(responseId, builderABTests)) {
    const languages = messages.keySeq().toArray();

    return !languages.some((language) =>
      getAllVariantMessages(messages.get(language)).some(
        (languageVariantMessage: { size: number }) =>
          !languageVariantMessage.size,
      ),
    );
  }

  return true; // We are going to assume regular reponse is always valid.
}

export function setVariantIndexQueryParam(
  variantIndex: number,
  history: History,
) {
  history.replace({
    search: queryString.stringify({
      ...queryString.parse(window.location.search),
      variantIndex: variantIndex.toString(),
    }),
  });
}
