import { createSelector } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";

import { type BaseKeyPath, type KeyPath } from "services/responses";

import { getActiveMessages, getBaseKeyPath } from ".";

export const selectActiveRootMessagesIdKeyPathMap = createSelector(
  getActiveMessages,
  getBaseKeyPath,
  (activeMessages, baseKeyPath) =>
    activeMessages.reduce(
      (acc, message, index) =>
        acc.set(message.id as string, [...(baseKeyPath as BaseKeyPath), index]),
      new Map<string, KeyPath>(),
    ),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);
