import { Map, Record, is } from "immutable";

import {
  ALL_MODALITIES,
  MODALITY_DETAILS,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";

export const BaseQuickReplyInBlock = Record({
  target: "",
  label: "",
  button_type: "suggestion",
});

/**
 * @param {Immutable.List} messages
 * @param {String} messageId
 * @returns {Number}
 */
export function getMessageIndex(messages, messageId) {
  return messages.findIndex((m) => m.id === messageId);
}

export function validateResponseModality(messagesMap) {
  const allMessages = messagesMap.valueSeq().flatten();

  return allMessages.every((m) => !m.invalidFields.size);
}

/**
 *
 * @param {String} responseId
 * @param {Map} state
 */
export function validateResponse(responseId, state) {
  // This method should just be used to
  // validate the message data in the response
  // all other concerns around "savability" of a response
  // should go in the selector `selectResponseGoodToSave`
  const response = state.get(responseId);
  const languagesArray = response.messages.keySeq().toArray();

  if (!languagesArray.length) {
    return false;
  }

  if (!response.handle) {
    return false;
  }

  return ALL_MODALITIES.every((modality) =>
    validateResponseModality(
      response.get(MODALITY_DETAILS[modality].messageField),
    ),
  );
}

/**
 * @param {Record} responseRecord
 * @returns {Record}
 */
function removeStateFields(responseRecord) {
  let responseRecordToCompare = responseRecord;

  // Non-savable fields
  const stateFields = ["isDirty", "isValid", "messagesTranslationPreview"];
  stateFields.forEach((field) => {
    responseRecordToCompare = responseRecordToCompare.delete(field);
  });

  return responseRecordToCompare;
}

/**
 * Returns true if and only if the response has changed since the last save
 * @param {String} responseId
 * @param {Map} lastSavedResponsesState
 * @param {Map} currentResponsesState
 * @returns {Boolean}
 */
export function checkIfDirty(
  responseId,
  lastSavedResponsesState = Map(),
  currentResponsesState,
) {
  const lastSavedResponse = lastSavedResponsesState.get(responseId);
  const currentResponse = currentResponsesState.get(responseId);

  if (lastSavedResponse && currentResponse) {
    return !is(
      removeStateFields(lastSavedResponse),
      removeStateFields(currentResponse),
    );
  }

  return true;
}

/**
 * @param {String} responseId
 * @param {Map} lastSavedResponsesState
 * @param {Map} state
 * @returns {Map}
 */
export function stateWithMetaData(responseId, lastSavedResponsesState, state) {
  return state.mergeIn([responseId], {
    isDirty: checkIfDirty(responseId, lastSavedResponsesState, state),
    isValid: validateResponse(responseId, state),
  });
}
