import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

import { InputLabel } from "components/Common/InputLabel";
import { c, v } from "constants/css";

export const ModalInput = styled(InputLabel)`
  width: 100%;
`;

export const InputRow = styled.div`
  margin-bottom: ${primitives.spacing.space6};
`;

export const ModalTitle = styled.h5`
  font-size: ${primitives.fontSize.default};
  font-weight: ${primitives.fontWeight.semibold};
  line-height: ${primitives.lineHeight.small};
  color: ${tokens.colors.text.muted};
`;

export const ModalHeader = styled.section`
  height: 52px;
  display: flex;
  align-items: center;
  padding: ${primitives.spacing.space2} ${primitives.spacing.space4}
    ${primitives.spacing.space2} ${primitives.spacing.space6};
  border-bottom: 1px solid ${tokens.colors.border.default};
`;

export const ModalBody = styled.section`
  padding: ${primitives.spacing.space4} ${primitives.spacing.space6};
  font-size: ${v.smallFontSize};
  border-bottom: 1px solid ${c.colorGrey1};
`;

export const ModalSubtitle = styled.p`
  font-size: ${primitives.fontSize.small};
  font-weight: ${primitives.fontWeight.normal};
  line-height: ${primitives.lineHeight.default};
  color: ${tokens.colors.text.main};
  margin-bottom: ${primitives.spacing.space2};
`;

export const RadioLabel = styled.p`
  color: ${tokens.colors.text.main};
  font-size: ${v.smallFontSize};
  font-weight: 600;
  padding: 0 0 6px 0;
  display: block;
`;

export const RadioInputContainer = styled.div`
  padding-left: 27px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    gap: ${primitives.spacing.space2};
  }
  padding: ${primitives.spacing.space4};
`;
