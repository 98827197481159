// @ts-strict-ignore
import Immutable from "immutable";

import { Base64 } from "services/base64";

import {
  FilterItem,
  type FilterType,
  type OperatorOption,
  Operators,
  type SelectOption,
  supportedFiltersConfig,
} from "./constants";

export function replaceSpacesWithPlusSign(filterString: string): string {
  return filterString.replace(/ /g, "+");
}

export function serializeFilters(
  filters: Immutable.List<FilterItem> | FilterItem[],

  // TODO remove redundant parameter
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPlainJS = false,
): string {
  if ("toJS" in filters) {
    return Base64.encode(JSON.stringify(filters.toJS()));
  }

  return Base64.encode(JSON.stringify(filters));
}

export function deserializeFilters(
  filtersString: string,
): Immutable.List<FilterItem> {
  if (!filtersString) {
    return Immutable.List<FilterItem>();
  }

  const deserializedFiltersRaw = Immutable.fromJS(
    JSON.parse(Base64.decode(replaceSpacesWithPlusSign(filtersString))),
  );

  return deserializedFiltersRaw.map(
    (filterRaw: Immutable.Map<string, unknown>) =>
      new FilterItem({
        ...filterRaw.toJS(),
        ...{
          // FilterItem.value can be a string[] or a boolean value
          value:
            filterRaw.get("value") instanceof Immutable.List
              ? (filterRaw.get("value") as Immutable.List<string>).toJS()
              : (filterRaw.get("value") as boolean),
        },
      }),
  );
}

export function getFiltersDropdownOptions(
  allowedFilters: FilterType[],
): SelectOption[] {
  return allowedFilters
    .filter(
      // "variable" filter type requires custom sub menu, so don't include it here
      (filterType) =>
        !["variable", "conversationTopics"].includes(filterType) &&
        supportedFiltersConfig[filterType],
    )
    .map((filterKey) => ({
      label: supportedFiltersConfig[filterKey]?.label || "",
      value: filterKey,
      icon: supportedFiltersConfig[filterKey]?.icon,
      type: supportedFiltersConfig[filterKey]?.type,
    }));
}

export function getOperatorOption(operator: Operators): OperatorOption {
  switch (operator) {
    case Operators.IS:
      return { label: "Is", icon: "Equal", value: Operators.IS };
    case Operators.ISNOT:
      return { label: "Is Not", icon: "NotEqual", value: Operators.ISNOT };
    case Operators.BEGINSWITH:
      return {
        label: "Begins With",
        icon: "StartsWith",
        value: Operators.BEGINSWITH,
      };
    case Operators.ENDSWITH:
      return {
        label: "Ends With",
        icon: "EndsWith",
        value: Operators.ENDSWITH,
      };
    case Operators.CONTAINS:
      return { label: "Contains", icon: "Contains", value: Operators.CONTAINS };
    case Operators.ISSET:
      return { label: "Is Set", icon: "IsNotNull", value: Operators.ISSET };
    case Operators.ISNOTSET:
      return { label: "Is Not Set", icon: "IsNull", value: Operators.ISNOTSET };
    default:
      return { label: "Is", icon: "Equal", value: Operators.IS };
  }
}

export function getFiltersOperatorOptions(
  filterKey: FilterType | null,
): OperatorOption[] {
  return filterKey
    ? supportedFiltersConfig[filterKey].operators.map((operator) =>
        getOperatorOption(operator),
      )
    : [getOperatorOption(Operators.IS)];
}

export function getOptionLabelsFromValues(
  options: SelectOption[],
  values: string[] | null,
): string[] {
  if (!values) {
    return [];
  }

  const selectedValue = values.filter((value) =>
    options.find((option) => option.value === value),
  );

  return options
    .filter((option) => selectedValue.includes(option.value))
    .map((option) => option.label);
}

export function getFilterGroups(params: URLSearchParams): string[] {
  const filterGroups: string[] = [];

  const regex = /^f[0-9]*$/;
  params.forEach((val, key) => {
    if (regex.test(key)) {
      filterGroups.push(val);
    }
  });

  return filterGroups;
}
