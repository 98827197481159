import { push } from "connected-react-router";

import { type AsyncAction } from "actions/types";

export function setPageAction(page: string): AsyncAction {
  return (dispatch) => {
    dispatch(push(page));
  };
}

export const setPage = setPageAction;

export function goToPage(page: string): AsyncAction {
  return (dispatch) => {
    dispatch(push(page));
  };
}
