import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

import { c, v } from "constants/css";

export const Root = styled.div`
  margin-bottom: ${primitives.spacing.space4};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${primitives.spacing.space2};
  flex: 1;
`;

export const BreadCrumbs = styled.div`
  color: ${c.colorUiPrimary};
  margin-bottom: ${primitives.spacing.space6};
`;

export const ActionItems = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > * {
    margin-left: ${primitives.spacing.space2};
  }
`;

export const Description = styled.div<{ width?: number }>`
  font-size: ${primitives.fontSize.small};
  color: ${tokens.colors.text.muted};
  line-height: ${primitives.lineHeight.default};
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  p {
    font-size: ${v.smallFontSize};
  }
`;

export const Link = styled.a`
  text-decoration: none;
  white-space: nowrap;
  color: ${tokens.colors.action.primary};
  font-weight: ${primitives.fontWeight.semibold};
`;
