import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

import { v } from "constants/css";

export const Container = styled.div`
  padding: ${v.gUnitDouble};
`;

export const Title = styled.div`
  font-size: ${v.largeFontSize};
  font-weight: ${v.heavyFontWeight};
  display: flex;
  align-items: center;
  svg {
    margin-right: ${v.gUnit};
  }
`;

export const Body = styled.div<{ last?: boolean }>`
  margin: ${v.gUnitTriple} 0;
  font-size: ${v.smallFontSize};
  font-weight: ${v.heavyFontWeight};
  padding-left: ${({ last }) => (last ? v.gUnitQuadruple : 0)};
  line-height: ${v.mediumLineHeight};

  p {
    font-weight: ${v.lightFontWeight};
    font-size: ${v.smallFontSize};
  }
`;

export const InlineButton = styled.span`
  color: ${tokens.colors.action.primary};
  text-decoration: underline;
  cursor: pointer;
`;

export const TopicName = styled.div`
  margin: ${v.gUnit} 0;
`;

export const Summary = styled.div`
  margin-top: ${v.gUnit};
`;

export const Warning = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-row-gap: ${v.gUnit};
  grid-column-gap: ${v.gUnit};
  align-items: center;
  margin-top: ${v.gUnitDouble};
  background: ${primitives.palette.gold100};
  border: solid 1px ${primitives.palette.gold400};
  font-size: ${v.smallFontSize};
  padding: ${v.gUnitDouble};
  border-radius: ${v.mainBorderRadius};

  h6 {
    color: ${primitives.palette.orange800};
    font-weight: ${v.heavyFontWeight};
  }
`;

export const Header = styled.h6`
  font-size: ${v.smallFontSize};
  font-weight: ${v.lightFontWeight};
  color: ${tokens.colors.text.muted};
`;

export const SummaryNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${v.smallFontSize};

  button {
    margin: 0 ${v.gUnit};
  }
`;

export const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content min-content;
  grid-column-gap: ${v.gUnit};
`;
