export interface TestVariableInfo {
  id: string | null;
  value: string | null;
}

interface TestVariablesModalState {
  isTestBotOpen: boolean;
  isVariablePanelCollapsed: boolean;
  variablesInfo: TestVariableInfo[];
  chatterToken: string | null;
}

const INITIAL_STATE: TestVariablesModalState = {
  isTestBotOpen: false,
  isVariablePanelCollapsed: true,
  variablesInfo: [{ id: null, value: null }],
  chatterToken: null,
};

type Action =
  | {
      type: "SET_TEST_VARIABLES_MODAL_STATE";
      payload: Partial<TestVariablesModalState>;
    }
  | {
      type: "UPDATE_VARIABLE_INFO";
      index: number;
      variableInfo: TestVariableInfo;
    }
  | { type: "ADD_VARIABLE_INFO" }
  | { type: "DELETE_VARIABLE_INFO"; index: number }
  | { type: "RESET_VARIABLES_INFO" };

export const testVariablesModal = (
  state: TestVariablesModalState = INITIAL_STATE,
  action: Action,
): TestVariablesModalState => {
  switch (action.type) {
    case "SET_TEST_VARIABLES_MODAL_STATE":
      return {
        ...state,
        ...action.payload,
      };
    case "UPDATE_VARIABLE_INFO":
      return {
        ...state,
        variablesInfo: state.variablesInfo.map((info, index) =>
          index === action.index ? { ...info, ...action.variableInfo } : info,
        ),
      };
    case "ADD_VARIABLE_INFO":
      return {
        ...state,
        variablesInfo: [...state.variablesInfo, { id: null, value: null }],
      };
    case "DELETE_VARIABLE_INFO":
      return {
        ...state,
        variablesInfo: state.variablesInfo.filter(
          (info, index) => index !== action.index,
        ),
      };
    case "RESET_VARIABLES_INFO":
      return {
        ...state,
        variablesInfo: INITIAL_STATE.variablesInfo,
      };
    default:
      return state;
  }
};
