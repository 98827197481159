import React from "react";

import { parseRuleQuery, translateRuleToPlainText } from "services/rules";
import { useVariables } from "services/variables";

export const RuleQueryInlineView = ({
  ruleQuery,
}: {
  ruleQuery: string | null | undefined;
}) => {
  const { variables } = useVariables();

  if (!ruleQuery) return <>Everyone</>;

  const parsedRule = parseRuleQuery(ruleQuery);

  if (!parsedRule) return <>Unknown</>;

  const getVariableName = (id: string): string =>
    variables?.find((v) => v._id === id)?.name || "unknown_variable";

  return <>{translateRuleToPlainText(parsedRule, getVariableName)}</>;
};
