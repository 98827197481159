import { bindActionCreators } from "@reduxjs/toolkit";
import React, { type ReactElement } from "react";
import { connect } from "react-redux";

import { dismissAlert as dismissAlertAction } from "actions/alerts";
import { type Dispatch } from "actions/types";
import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";
import { ICON_MAP } from "constants/icons";
import { type AlertType } from "reducers/alerts/reducer";
import { type State } from "types";

import "./style.scss";

interface PropsFromState {
  alert: AlertType;
}

interface PropsFromDispatch {
  dismissAlert(): void;
}

export type AlertsProps = PropsFromState & PropsFromDispatch;

function AlertsComponent(props: AlertsProps): ReactElement {
  const { alert, dismissAlert } = props;

  return (
    <div
      data-testid="alert"
      className={`Alerts ${alert.isVisible ? "" : "Alerts--hidden"}`}
    >
      <div
        className={`
                    Alerts__box
                    ${alert.isVisible ? "" : "Alerts__box--hidden"}
                `}
      >
        <div className="Alerts__box__icon-container">
          {alert.type && (
            <SvgIcon
              icon={ICON_MAP[alert.type].icon}
              fillColor={ICON_MAP[alert.type].color}
              customClassName="Alerts__box__icon-container__icon"
            />
          )}
        </div>
        <div className="Alerts__box__text">
          <div className="Alerts__box__text__message">{alert.message}</div>
          <div className="Alerts__box__text__buttons">
            {alert.buttons.map((b) => (
              <Button
                customClassName="Alerts__box__text__buttons__button"
                onClick={b.action}
                text={b.label}
                title="Open Answer"
                size="small"
                light
                key={b.label}
              />
            ))}
            <Button
              customClassName="Alerts__box__text__buttons__button"
              onClick={dismissAlert}
              text="Dismiss"
              title="Dismiss"
              size="small"
              light
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state: State): PropsFromState {
  const { alert } = state;

  return { alert };
}

function mapDispatchToProps(dispatch: Dispatch): PropsFromDispatch {
  return {
    dismissAlert: bindActionCreators(dismissAlertAction, dispatch),
  };
}

export const Alerts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertsComponent);
