import Immutable from "immutable";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface CsatMessageRecordAttributes extends BaseMessageRecordAttributes {
  type: "csat_survey";
}

export class CsatMessageRecord extends BaseMessageRecord<CsatMessageRecordAttributes>(
  {
    type: "csat_survey",
  },
) {
  static getInvalidFields = (): Immutable.List<string> => Immutable.List();
}
