import React from "react";

import * as Modal from "components/Shared/Pages/Settings/Modals/styles";

import * as S from "./styles";

export function ZendeskAuthError() {
  return (
    <Modal.ModalHeader>
      <S.ErrorBanner icon="Exclamation" intent="negative">
        <S.ErrorMessage>
          Your knowledge base couldn&apos;t be connected because it&apos;s set
          to private. Please update your Zendesk Guide permissions and try
          again.&nbsp;
          <a
            href="https://docs.ada.cx/zendesk-subdomain"
            target="_blank"
            rel="noreferrer noopener"
          >
            Learn more
          </a>
        </S.ErrorMessage>
      </S.ErrorBanner>
    </Modal.ModalHeader>
  );
}
