import { type List } from "immutable";
import queryString from "query-string";
import { useSelector } from "react-redux";

import {
  type ConversationTopicsResponse,
  useGetConversationsTopicsQuery,
} from "slices/conversations/conversationsApi";
import {
  useGetArticleTagsQuery,
  useGetSourcesQuery,
} from "slices/knowledge/knowledgeApiSlice";

import { type FilterType, type SelectOption } from "./constants";
import { constructFilterOptionsFromStore } from "./constructFilterOptions";

const constructConversationTopicsFilterOption = (
  response: { data?: ConversationTopicsResponse },
  searchQuery?: string,
) =>
  response.data?.topics.map((topic: { _id: string; title: string }) => ({
    value: topic._id,
    label: topic.title,
    hidden: !topic.title
      .toLowerCase()
      .includes(searchQuery?.toLowerCase() as string),
  })) || [];

export const GetConversationTopics = (searchQuery?: string) =>
  constructConversationTopicsFilterOption(
    useGetConversationsTopicsQuery(),
    searchQuery,
  );

const useGetSourceOptions = () => {
  const { sources } = useGetSourcesQuery().data || { sources: [] };

  return sources.map((source) => ({
    label: source.name,
    value: source.id,
  }));
};

const useGetArticleTagsOptions = () => {
  const { article_tags: articleTags } = useGetArticleTagsQuery().data || {
    article_tags: [],
  };

  return articleTags.map((tag) => ({
    label: tag.name,
    value: tag._id,
  }));
};

function useFilterOptions(filterType: FilterType) {
  return useSelector((state) =>
    constructFilterOptionsFromStore(state, filterType),
  );
}

export function useFilterOptionsDict(): {
  [key: string]: SelectOption[] | List<SelectOption>;
} {
  // NOTE: All keys belong to type: FilterType
  return {
    answerId: useFilterOptions("answerId"),
    browser: useFilterOptions("browser"),
    channel: useFilterOptions("channel"),
    device: useFilterOptions("device"),
    interactionType: useFilterOptions("interactionType"),
    language: useFilterOptions("language"),
    platform: useFilterOptions("platform"),
    feedback: useFilterOptions("feedback"),
    agentIds: useFilterOptions("agentIds"),
    blockType: useFilterOptions("blockType"),
    user: useFilterOptions("user"),
    dashboardPage: useFilterOptions("dashboardPage"),
    engaged: useFilterOptions("engaged"),
    chatterId: useFilterOptions("chatterId"),
    csatscore: useFilterOptions("csatscore"),
    handoff: useFilterOptions("handoff"),
    conversationTopics: GetConversationTopics(),
    activeState: useFilterOptions("activeState"),
    source: useGetSourceOptions(),
    articleTag: useGetArticleTagsOptions(),
  };
}

export function useFiltersStringFromUrl(): string {
  const params = queryString.parse(window.location.search);

  return params.f as string;
}
