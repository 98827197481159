import { bindActionCreators } from "@reduxjs/toolkit";
import Immutable from "immutable";
import omit from "lodash.omit";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";

import { saveClientPlatform, updatePlatform } from "actions";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputClearable } from "components/Common/InputClearable";
import { InputSensitive } from "components/Common/InputSensitive";
import { NumberedSteps } from "components/Common/NumberedSteps";
import ReadOnlyInput from "components/Common/ReadOnlyInput";
import SvgIcon from "components/Common/SvgIcon";
import { ClientLegacy } from "resourceModels";
import colors from "stylesheets/utilities/colors.scss";
import "./style.scss";
import { clientSelector } from "selectors/client";

class SettingsPlatformsZendeskModal extends React.Component {
  /**
   * @param {String} clientId
   * @param {String} redirectUri
   * @param {String} subdomain
   * @returns {String}
   */
  static constructAuthUrl(clientId, redirectUri, subdomain) {
    let url =
      `https://${subdomain}.zendesk.com/oauth2/chat/authorizations/` +
      `new?response_type=code&client_id=${clientId}&` +
      "scope=read%20write%20chat&" +
      `redirect_uri=${encodeURIComponent(redirectUri)}`;

    if (subdomain) {
      url += `&subdomain=${encodeURIComponent(subdomain)}`;
    }

    return url;
  }

  /** @param {Object} props */
  constructor(props) {
    super(props);

    this.modalContentRef = React.createRef();

    this.saveFirstStep = this.saveFirstStep.bind(this);
    this.saveSecondStep = this.saveSecondStep.bind(this);
    this.removeAgentEmail = this.removeAgentEmail.bind(this);
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
    this.handleInputFieldClear = this.handleInputFieldClear.bind(this);
  }

  /**
   * @param {Event} e
   * @param {String} field
   */
  handleInputFieldChange(e, field) {
    let payload;

    if (field === "accountKey") {
      payload = {
        [field]: e.target.value,
        agentEmail: "",
      };
    } else {
      payload = {
        auth: { [field]: e.target.value },
        agentEmail: "",
      };
    }

    this.updatePlatform(payload);
  }

  /** @param {String} field */
  handleInputFieldClear(field) {
    let payload;

    if (field === "accountKey") {
      payload = {
        [field]: "",
        agentEmail: "",
      };
    } else {
      payload = {
        auth: { [field]: "" },
        agentEmail: "",
      };
    }

    this.updatePlatform(payload);
  }

  /**
   * @param {Object} payload
   */
  updatePlatform(payload) {
    const { updatePlatformAction } = this.props;
    updatePlatformAction("zendesk_liveagent", payload);
  }

  /** @param {Immutable.Record} platform */
  saveFirstStep(platform) {
    const { saveClientPlatformAction } = this.props;
    let newPlatform = platform.get("record").toJS();
    newPlatform.enabled = false;
    newPlatform = omit(newPlatform, ["agentEmail", "accessToken"]);
    newPlatform = Immutable.Map(newPlatform);

    const toSave = platform.set("record", newPlatform);

    saveClientPlatformAction(toSave, false);
  }

  /** @param {Immutable.Record} platform */
  saveSecondStep(platform) {
    const { saveClientPlatformAction } = this.props;
    const enabledPlatform = platform.setIn(["record", "enabled"], true);
    saveClientPlatformAction(enabledPlatform);
  }

  /** @param {Immutable.Record} platform */
  removeAgentEmail(platform) {
    /*
            Removing the agent email is actually equivalent to
            Saving on the first step:
                - remove agent email and access token
                - save first step data
     */
    this.saveFirstStep(platform);
  }

  /** @returns {ReactElement} */
  renderAPIClientBlock() {
    const {
      platform,
      /** @type ClientLegacy */
      client,
    } = this.props;
    const isSaveDisabled =
      !platform.get("isDirty") || platform.get("currentStep") !== 0;
    const isSaved = platform.get("isSaved");
    const isDirty = platform.get("isDirty");

    return (
      <section className="Modal__section">
        <section className="Modal__section__sub-section">
          <h1 className="SettingsPlatformsZendeskModal__section-heading">
            API Client
          </h1>
          <div className="SettingsPlatformsZendeskModal__section-row">
            <span
              className="Modal__section__sub-section__title g-input__label SettingsPlatformsZendeskModal__label-tooltip"
              htmlFor="SettingsPlatformsZendeskModal__account-sid"
            >
              <span>Redirect URL</span>
              <InfoTooltip
                blurb="Copy and paste this redirect URL when creating your API Client in Zendesk Chat"
                link={{
                  title: "Learn More",
                  href: "https://docs.ada.cx/ada-glass-for-zendesk",
                }}
                iconDefault="QuestionCircle"
                iconClicked="QuestionCircleFilled"
                absolute
                container={this.modalContentRef.current}
              />
            </span>
            {process.env.NODE_ENV === "development" ? (
              <InputClearable
                value={platform.getIn(["record", "auth", "redirectUri"])}
                placeholder="https://test-api.ngrok.io/oauth/zendesk"
                onChange={(e) => this.handleInputFieldChange(e, "redirectUri")}
                toggleDisabled
                invalid={!platform.getIn(["record", "auth", "redirectUri"])}
              />
            ) : (
              <ReadOnlyInput
                icon="Copy"
                value={platform.getIn(["record", "auth", "redirectUri"])}
                placeholder="Redirect URI"
              />
            )}
          </div>
          <div className="SettingsPlatformsZendeskModal__section-row SettingsPlatformsZendeskModal__section-row--flex">
            <div className="SettingsPlatformsZendeskModal__input-col">
              <span
                className="Modal__section__sub-section__title g-input__label SettingsPlatformsZendeskModal__label-tooltip"
                htmlFor="SettingsPlatformsZendeskModal__account-sid"
              >
                <span>Client ID</span>
                <InfoTooltip
                  blurb="Your Client ID is generated when you create a new API Client in Zendesk Chat"
                  link={{
                    title: "Learn More",
                    href: "https://docs.ada.cx/ada-glass-for-zendesk",
                  }}
                  iconDefault="QuestionCircle"
                  iconClicked="QuestionCircleFilled"
                  absolute
                  container={this.modalContentRef.current}
                />
              </span>
              <InputClearable
                value={platform.getIn(["record", "auth", "clientId"])}
                placeholder="gGRvsdA1ZzdfMH5Mgedhg"
                onChange={(e) => this.handleInputFieldChange(e, "clientId")}
                toggleDisabled
                invalid={!platform.getIn(["record", "auth", "clientId"])}
              />
            </div>
            <div className="SettingsPlatformsZendeskModal__input-col">
              <span
                className="Modal__section__sub-section__title g-input__label SettingsPlatformsZendeskModal__label-tooltip"
                htmlFor="SettingsPlatformsZendeskModal__account-sid"
              >
                <span>Client Secret</span>
                <InfoTooltip
                  blurb="Your Client Secret is generated once when you create a new API Client in Zendesk Chat"
                  link={{
                    title: "Learn More",
                    href: "https://docs.ada.cx/ada-glass-for-zendesk",
                  }}
                  iconDefault="QuestionCircle"
                  iconClicked="QuestionCircleFilled"
                  absolute
                  container={this.modalContentRef.current}
                />
              </span>
              <InputSensitive
                value={platform.getIn(["record", "auth", "clientSecret"])}
                placeholder=""
                onChange={(e) => this.handleInputFieldChange(e, "clientSecret")}
                toggleDisabled
                invalid={!platform.getIn(["record", "auth", "clientSecret"])}
              />
            </div>
          </div>
          <div className="SettingsPlatformsZendeskModal__section-row SettingsPlatformsZendeskModal__section-row--flex">
            <div className="SettingsPlatformsZendeskModal__input-col">
              <span
                className="Modal__section__sub-section__title g-input__label SettingsPlatformsZendeskModal__label-tooltip"
                htmlFor="SettingsPlatformsZendeskModal__account-sid"
              >
                <span>Support Subdomain</span>
                <InfoTooltip
                  blurb={
                    "Support Subdomain is a subdivision of your Zendesk domain. " +
                    "I.e. if your Zendesk environment URL is ada.zendesk.com, then ‘ada’ is your subdomain."
                  }
                  link={{
                    title: "Learn More",
                    href: "https://docs.ada.cx/ada-glass-for-zendesk",
                  }}
                  iconDefault="QuestionCircle"
                  iconClicked="QuestionCircleFilled"
                  absolute
                  container={this.modalContentRef.current}
                />
              </span>
              <InputClearable
                value={platform.getIn(["record", "auth", "subdomain"])}
                placeholder="acmecompany"
                onChange={(e) => this.handleInputFieldChange(e, "subdomain")}
                onClear={() => this.handleInputFieldClear("subdomain")}
              />
            </div>
            {client.features.get("adaGlassZendesk") ? (
              <div className="SettingsPlatformsZendeskModal__input-col">
                <span
                  className="Modal__section__sub-section__title g-input__label SettingsPlatformsZendeskModal__label-tooltip"
                  htmlFor="SettingsPlatformsZendeskModal__account-sid"
                >
                  <span>Account Key</span>
                  <InfoTooltip
                    blurb="Copy and paste your Account Key here from the Check connection section in your Zendesk Client"
                    link={{
                      title: "Learn More",
                      href: "https://docs.ada.cx/ada-glass-for-zendesk",
                    }}
                    iconDefault="QuestionCircle"
                    iconClicked="QuestionCircleFilled"
                    absolute
                    container={this.modalContentRef.current}
                  />
                </span>
                <InputClearable
                  value={platform.getIn(["record", "accountKey"])}
                  placeholder="g8389HHjk392389hadjkwa"
                  onChange={(e) => this.handleInputFieldChange(e, "accountKey")}
                  onClear={() => this.handleInputFieldClear("accountKey")}
                />
              </div>
            ) : (
              <div className="SettingsPlatformsZendeskModal__input-col" />
            )}
          </div>
          <div className="SettingsPlatformsZendeskModal__section-row">
            <Button
              onClick={() => this.saveFirstStep(platform)}
              text={isSaved && !isDirty ? "Saved" : "Save"}
              title={isSaved && !isDirty ? "Saved" : "Save"}
              disabled={isSaveDisabled}
              light
              isLoading={
                platform.get("isLoading") && platform.get("currentStep") === 0
              }
            />
          </div>
        </section>
      </section>
    );
  }

  /** @returns {ReactElement} */
  renderAgentBlock() {
    const { platform } = this.props;

    return (
      <section className="Modal__section">
        <section className="Modal__section__sub-section">
          <div className="SettingsPlatformsZendeskModal__section-row--flex">
            <div className="SettingsPlatformsZendeskModal__input-col">
              <h1 className=" SettingsPlatformsZendeskModal__section-heading-tooltip">
                <span className="SettingsPlatformsZendeskModal__section-heading">
                  Agent
                </span>
              </h1>
            </div>
            <div className="SettingsPlatformsZendeskModal__input-col" />
          </div>
          <div className="SettingsPlatformsZendeskModal__section-row SettingsPlatformsZendeskModal__section-row--flex">
            {platform.getIn(["record", "agentEmail"]) ? (
              <div className="SettingsPlatformsZendeskModal__input-col SettingsPlatformsZendeskModal__agent-email-col">
                <div className="SettingsPlatformsZendeskModal__agent-email-cell">
                  <span>{platform.getIn(["record", "agentEmail"])}</span>
                  <button
                    type="button"
                    className="SettingsPlatformsZendeskModal__remove-button"
                    onClick={() => this.removeAgentEmail(platform)}
                  >
                    <SvgIcon
                      icon="CircleRemove"
                      fillColor={colors.colorUIBad}
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div className="SettingsPlatformsZendeskModal__input-col">
                <Button
                  onClick={() =>
                    window.open(
                      SettingsPlatformsZendeskModal.constructAuthUrl(
                        platform.getIn(["record", "auth", "clientId"]),
                        platform.getIn(["record", "auth", "redirectUri"]),
                        platform.getIn(["record", "auth", "subdomain"]),
                      ),
                    )
                  }
                  text="Add Agent"
                  title="Add Agent"
                  icon="Zendesk"
                  isLoading={
                    platform.get("isLoading") &&
                    platform.get("currentStep") === 1
                  }
                  customClassName="SettingsPlatformsZendeskModal__add-agent"
                  light
                />
              </div>
            )}
            <div className="SettingsPlatformsZendeskModal__input-col" />
          </div>
        </section>
      </section>
    );
  }

  /** @returns {ReactElement} */
  render() {
    const { platform } = this.props;
    const isButtonEnabled = platform.get("isValid") && platform.get("isDirty");
    const chatType =
      platform.getIn(["record", "name"]) === "zendesk_liveagent"
        ? "Live Chat"
        : "Chat";

    return (
      <div className="SettingsPlatformsZendeskModal Modal__modal">
        <h5 className="Modal__title">{`Zendesk ${chatType} Settings`}</h5>
        <div className="Modal__content" ref={this.modalContentRef}>
          <Banner icon="QuestionCircleFilled">
            <a
              href="https://docs.ada.cx/ada-glass-for-zendesk"
              target="_blank"
              rel="noopener noreferrer"
              className="ModalNotification__link"
            >
              Start here! Click to read our guide on how to set up Ada for
              Zendesk Chat
            </a>
          </Banner>
          <NumberedSteps currentStep={platform.get("currentStep")}>
            {() => [this.renderAPIClientBlock(), this.renderAgentBlock()]}
          </NumberedSteps>
        </div>
        <div className="Modal__bottom SettingsPlatformsZendeskModal__bottom">
          <Button
            onClick={() => this.saveSecondStep(platform)}
            text="Finish"
            title="Finish"
            disabled={!isButtonEnabled}
            disabledStyleFlat
            isLoading={
              platform.get("isLoading") && platform.get("currentStep") === 2
            }
            customClassName="SettingsPlatformsZendeskModal__bottom__save"
          />
        </div>
      </div>
    );
  }
}

SettingsPlatformsZendeskModal.propTypes = {
  platform: ImmutablePropTypes.map.isRequired,
  updatePlatformAction: PropTypes.func.isRequired,
  saveClientPlatformAction: PropTypes.func.isRequired,

  client: PropTypes.instanceOf(ClientLegacy).isRequired,
};

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapState(state) {
  const { platforms } = state;

  return {
    client: clientSelector(state),
    platforms,
    platform: platforms.get("zendesk_liveagent"),
  };
}

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      saveClientPlatformAction: saveClientPlatform,
      updatePlatformAction: updatePlatform,
    },
    dispatch,
  );
}

const Connector = connect(mapState, mapDispatch)(SettingsPlatformsZendeskModal);

export default Connector;
