import { Button } from "@adasupport/byron";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeModalAction } from "actions/modal";
import { getAllClientVariables } from "actions/variables";
import { Banner } from "components/Common/Banner";
import { Checkbox } from "components/Common/Checkbox";
import { InputLabel } from "components/Common/InputLabel";
import { InputText } from "components/Common/InputText";
import { Loading } from "components/Common/Loading";
import { Title } from "components/Common/Title";
import VariableSearchSelect from "components/Common/VariableSearchSelect";
import { useSmartSearchIntegration } from "components/Shared/Pages/Settings/SettingsPlatforms/hooks/useSmartSearchIntegration";
import { useClientLegacy } from "services/clientLegacy";
import { getVariables } from "services/records/variables";
import { type PinterestKBIntegration } from "slices/legacyIntegrations/legacyIntegrationsApi";

import * as S from "./styles";

export function ConnectionGuide() {
  const dispatch = useDispatch();
  const { client } = useClientLegacy();
  const variables = useSelector((state) => state.variables);
  const variablesToShow = useMemo(
    () => getVariables(variables, { scopes: ["global", "meta"] }),
    [variables],
  );
  const {
    integrationData,
    integrationPosition,
    handleSave,
    handleDisconnect,
    isConnecting,
  } = useSmartSearchIntegration("KBIntegrationPinterest");

  const [formInput, setFormInput] = useState({
    helpCenter: "",
    languageList: "",
    authorizationHeader: "",
    includePrivate: false,
    selectedVariable: "",
  });
  const existingIntegration = integrationPosition > -1;

  useEffect(() => {
    dispatch(getAllClientVariables());
  }, [dispatch]);

  useEffect(() => {
    if (integrationData) {
      const pinterestIntegration = integrationData as PinterestKBIntegration;
      const includePrivate = Boolean(
        pinterestIntegration.categories_variable_id,
      );

      setFormInput({
        helpCenter: pinterestIntegration.base_url,
        languageList: pinterestIntegration.languages_url,
        authorizationHeader: pinterestIntegration.auth_code,
        selectedVariable: includePrivate
          ? pinterestIntegration.categories_variable_id
          : "",
        includePrivate,
      });
    }
  }, [integrationData]);

  const handleChange = (key: string) => (value: string) => {
    setFormInput({ ...formInput, [key]: value });
  };

  const handleSavePinterest = () => {
    const payload = {
      client_id: client.id,
      auth_code: formInput.authorizationHeader,
      base_url: formInput.helpCenter,
      languages_url: formInput.languageList,
    };

    handleSave(
      "pinterest",
      formInput.includePrivate
        ? { ...payload, categories_variable_id: formInput.selectedVariable }
        : payload,
    );
  };

  const isInputValid =
    formInput.authorizationHeader &&
    formInput.helpCenter &&
    formInput.languageList &&
    (!formInput.includePrivate || formInput.selectedVariable);

  return (
    <div>
      <h5 className="Modal__title">Connect Pinterest Help Center</h5>
      <S.Container>
        <Banner icon="Info">
          For setup help, view the&nbsp;
          <a
            href="https://docs.ada.cx/smart-search"
            target="_blank"
            rel="noreferrer noopener"
          >
            help doc.
          </a>
        </Banner>
        <S.InputWrapper>
          <InputLabel label="Help Center URL">
            <InputText
              value={formInput.helpCenter}
              onChange={handleChange("helpCenter")}
              disabled={existingIntegration}
            />
          </InputLabel>
        </S.InputWrapper>
        <S.InputWrapper>
          <InputLabel label="Language List URL">
            <InputText
              value={formInput.languageList}
              onChange={handleChange("languageList")}
              disabled={existingIntegration}
            />
          </InputLabel>
        </S.InputWrapper>
        <S.InputWrapper>
          <InputLabel label="Authorization Header">
            <InputText
              value={
                existingIntegration
                  ? "••••••••••••••••••••••••••••••••••••••"
                  : formInput.authorizationHeader
              }
              className="ph-no-capture"
              onChange={handleChange("authorizationHeader")}
              disabled={existingIntegration}
            />
          </InputLabel>
        </S.InputWrapper>

        <S.Divider />

        <Title headingLevel="h4">Article Filtering</Title>
        <S.Description>
          By default, all public articles will be accessible to the bot.
        </S.Description>
        <S.CheckboxWrapper>
          <Checkbox
            checked={formInput.includePrivate}
            disabled={existingIntegration}
            handleToggle={() =>
              setFormInput({
                ...formInput,
                includePrivate: !formInput.includePrivate,
              })
            }
          />
          <S.CheckboxText>
            <S.CheckboxLabel>Filter articles by user category</S.CheckboxLabel>
            <S.CheckboxDescription>
              Select the customer type variable to use for filtering articles by
              user category.
            </S.CheckboxDescription>
          </S.CheckboxText>
        </S.CheckboxWrapper>
        {formInput.includePrivate && (
          <S.VariableWrapper>
            {variablesToShow.isEmpty() ? (
              <Loading />
            ) : (
              <VariableSearchSelect
                value={formInput.selectedVariable}
                variables={variablesToShow}
                onVariableClick={handleChange("selectedVariable")}
                disabled={existingIntegration}
                noBackground
              />
            )}
          </S.VariableWrapper>
        )}

        <S.Footer>
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => dispatch(closeModalAction())}
          />
          {existingIntegration ? (
            <Button
              text="Disconnect"
              onClick={handleDisconnect}
              variant="secondary"
            />
          ) : (
            <Button
              text="Connect"
              isDisabled={isConnecting || !isInputValid}
              onClick={handleSavePinterest}
            />
          )}
        </S.Footer>
      </S.Container>
    </div>
  );
}
