import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction } from "actions/modal";
import { setPageAction } from "actions/router";
import ResponseStatus from "components/Common/ResponseStatus";

import * as S from "./styles";

interface Props {
  references: {
    id: string;
    handle: string;
    live: boolean;
  }[];
}

export function SimpleReferenceList({ references }: Props) {
  const dispatch = useDispatch();

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setIsScrolledToBottom(
      event.currentTarget.scrollTop >=
        event.currentTarget.scrollHeight - event.currentTarget.offsetHeight,
    );
  };

  return (
    <S.ReferenceListContainer>
      <S.ReferenceListContent onScroll={handleScroll}>
        {references.map((response) => (
          <S.ReferenceButton
            key={response.id}
            type="button"
            onClick={() => {
              dispatch(closeModalAction());
              dispatch(setPageAction(`/answers/${response.id}`));
            }}
          >
            <S.InfoGroup>
              <S.Title>{response.handle}</S.Title>
              <div>
                <ResponseStatus isSmall isOn={response.live} />
              </div>
            </S.InfoGroup>
          </S.ReferenceButton>
        ))}
      </S.ReferenceListContent>
      <S.ReferenceListGradient
        visible={references.length > 3 && !isScrolledToBottom}
      />
    </S.ReferenceListContainer>
  );
}
