import classNames from "classnames";
import type Immutable from "immutable";
import React, { useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";
import { useSelector } from "react-redux";

import { ResponseEditorLanguagesDialog } from "components/Declarative/Pages/Responses/ResponsesEditor/ResponseEditorLanguages/ResponseEditorLanguagesDialog";
import { type Language } from "resourceModels";
import { selectClientTranslatedLanguageCodes } from "services/clientLegacy/selectors/selectClientTranslatedLanguageCodes";
import { type LanguageCode } from "services/language";
import { selectLanguages } from "services/language/selectors/selectLanguages";
import { ControlledAccessButton as Button } from "source/components/Common/Button/ControlledAccessButton";

import * as S from "./styles";
import "./style.scss";

const BLOCK_NAME = "LanguageButton";

interface LanguageButtonProps {
  availableLanguages: Immutable.List<Language>;
  addLanguage: (languageCode: LanguageCode) => void;
  color?: "white" | null;
  plusButtonStyle?: boolean;
}

export function LanguageButton({
  availableLanguages,
  addLanguage,
  color,
  plusButtonStyle = false,
}: LanguageButtonProps) {
  const languages = useSelector(selectLanguages);
  const translatedLanguages = useSelector(selectClientTranslatedLanguageCodes);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialogRef = useOnClickOutside(() => {
    setIsDialogOpen(false);
  });

  function renderDialog() {
    return (
      <S.DialogContainer ref={dialogRef}>
        <h3 className="Dialog__title">Add Translation</h3>
        <Button
          icon="Close"
          title="Close Dialog"
          clear
          onClick={() => setIsDialogOpen(false)}
          size="x-small"
          customClassName="Dialog__close"
          showActiveBackground
        />
        <ResponseEditorLanguagesDialog
          closeDialog={() => setIsDialogOpen(false)}
          availableLanguages={availableLanguages}
          onLanguageClick={addLanguage}
        />
      </S.DialogContainer>
    );
  }

  function renderButton() {
    return (
      <Button
        text="Add"
        title="Add Language"
        size="small"
        icon="Globe"
        customClassName={classNames(`${BLOCK_NAME}__add`, {
          [`${BLOCK_NAME}__add--color-white`]: color === "white",
        })}
        onClick={() => setIsDialogOpen((oldVal) => !oldVal)}
      />
    );
  }

  function renderVoiceEnabledButton() {
    return (
      <S.AddButton
        title="Add Language"
        size="small"
        icon="Plus"
        onClick={() => setIsDialogOpen((oldVal) => !oldVal)}
      />
    );
  }

  return (
    <div
      className={classNames("LanguageButton", {
        "LanguageButton--hidden":
          availableLanguages.size === 0 &&
          languages.size - 1 === translatedLanguages.size,
      })}
    >
      {plusButtonStyle ? renderVoiceEnabledButton() : renderButton()}
      {isDialogOpen && renderDialog()}
    </div>
  );
}
