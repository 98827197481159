import {
  type ArticleRulesMap,
  type SerializedRuleset,
} from "slices/knowledge/types";

export function mapRulesToArticleIds(
  rulesets: SerializedRuleset[],
): ArticleRulesMap {
  return rulesets.reduce(
    (rulesMap, ruleset) =>
      ruleset.article_ids.reduce(
        (newMap, articleId) => ({
          ...newMap,
          [articleId]: ruleset.rules,
        }),
        rulesMap,
      ),
    {},
  );
}
