import React, { type ReactElement } from "react";

import { CodeBlock } from "components/Common/CodeBlock";
import { EmbedSnippetHtml } from "components/Common/CodeBlock/EmbedSnippetHtml";
import { SupportedLanguages } from "components/Common/CodeBlock/types";
import { getTestBotCluster, getTestBotHandle } from "services/test-bot";

class SettingsPlatformChatModalInstallation extends React.Component {
  cluster = getTestBotCluster();
  handle = getTestBotHandle();

  webSettings = (
    <section className="g-form__block">
      <h1 className="Modal__section__sub-section__title g-input__label">Web</h1>
      <p className="Modal__section__sub-section__description">
        Add the Ada Embed script to your page inside the
        &lt;head&gt;...&lt;/head&gt; block.
      </p>
      <EmbedSnippetHtml />
    </section>
  );

  get iosSettings(): ReactElement {
    return (
      <section className="g-form__block">
        <h1 className="Modal__section__sub-section__title g-input__label">
          iOS
        </h1>
        <p className="Modal__section__sub-section__description">
          Ada Chat can be embedded inside your iOS 10+ application using the Ada
          iOS SDK.
        </p>
        <CodeBlock
          language={SupportedLanguages.RUBY}
          code={`
            # Podfile
            platform :ios, '10.0'
            
            target 'MyApp' do
              use_frameworks!
            
              # Pods for MyApp
              # Be sure to get the latest version here https://cocoapods.org/pods/AdaEmbedFramework
              pod "AdaEmbedFramework", "~>1.2.1"
            
            end
          `}
        />
        <CodeBlock
          language={SupportedLanguages.SWIFT}
          code={`
            // Controller
            import AdaEmbedFramework

            var adaFramework = AdaWebHost(handle: "${this.handle}"${
            this.cluster ? `, cluster: "${this.cluster}"` : ""
          })
          `}
        />
      </section>
    );
  }

  get androidSettings(): ReactElement {
    return (
      <section className="g-form__block">
        <h1 className="Modal__section__sub-section__title g-input__label">
          Android
        </h1>
        <p className="Modal__section__sub-section__description">
          Ada Chat can be embedded inside your Android application using the Ada
          Android SDK.
        </p>
        <CodeBlock
          language={SupportedLanguages.GROOVY}
          code={`
            // build.gradle
            allprojects {
                repositories {
                    maven {
                        url "https://adasupport.bintray.com/Android-SDK"
                    }
                }
            }
          `}
        />
        <br />
        <p className="Modal__section__sub-section__description">
          Then add the following to your package dependencies. This is valid as
          long as your project has artifacts within the androidx namespace.
        </p>
        <CodeBlock
          language={SupportedLanguages.GROOVY}
          code={`
            // build.gradle
            implementation 'support.ada.embed:android-sdk-appcompat:1.1.2'
          `}
        />
        <br />
        <p className="Modal__section__sub-section__description">
          Otherwise, use the following if your project uses Android Support
          Library.
        </p>
        <CodeBlock
          language={SupportedLanguages.GROOVY}
          code={`
            // build.gradle
            implementation 'support.ada.embed:android-sdk-appcompat-legacy:1.1.2'
          `}
        />
        <br />
        <p className="Modal__section__sub-section__description">
          To programmatically create the chat frame, you need to create a view
          object and pass context to the constructor.
        </p>
        <CodeBlock
          language={SupportedLanguages.KOTLIN}
          code={`
            val adaView = AdaEmbedView(getContext())
          `}
        />
        <br />
        <p className="Modal__section__sub-section__description">
          After this, the view will be created, but will not be initialized. To
          do this call &quot;initialize(settings: AdaEmbedView.Settings)&quot;,
          passing the settings object as an argument.
        </p>
        <CodeBlock
          language={SupportedLanguages.KOTLIN}
          code={`
          val adaSettings = AdaEmbedView.Settings.Builder("${this.handle}")${
            this.cluster ? `\n.cluster(${this.cluster})` : ""
          }
            .build()

          adaView.initialize(adaSettings)
          `}
        />
      </section>
    );
  }

  render(): ReactElement {
    return (
      <div className="Modal__content">
        {this.webSettings}
        {this.iosSettings}
        {this.androidSettings}
        <section className="g-form__block">
          <h1 className="Modal__section__sub-section__title g-input__label">
            Advanced Setup
          </h1>
          <p className="Modal__section__sub-section__description">
            There are many ways you can further customize your bot experience.
            For advanced setup please refer to the docs{" "}
            <a
              href="https://developers.ada.cx/reference/add-chat-to-website"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
        </section>
      </div>
    );
  }
}

export default SettingsPlatformChatModalInstallation;
