/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Immutable from "immutable";

export interface DragAndDropAttributes {
  draggableId?: string;
  isDragging: boolean;
  droppableIdSource?: string;
}

export class DragAndDrop extends Immutable.Record<DragAndDropAttributes>({
  draggableId: undefined,
  isDragging: false,
  droppableIdSource: undefined,
}) {}

export const dragAndDropReducer = (
  state = new DragAndDrop(),
  action: Record<string, any>,
): DragAndDrop => {
  switch (action.type) {
    case "SET_DRAG_AND_DROP":
      return state.merge(action.payload);
    default:
      return state;
  }
};

export default dragAndDropReducer;
