import { SET_ERROR_PAGE } from "actions/error/types";
import { type AsyncAction } from "actions/types";

export function triggerErrorState(
  error: boolean,
  status: number,
): { type: string; error: boolean; status: number } {
  return {
    type: SET_ERROR_PAGE,
    error,
    status,
  };
}

export function setErrorPage(error: boolean, status: number): AsyncAction {
  return (dispatch) => dispatch(triggerErrorState(error, status));
}
