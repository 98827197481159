// @ts-strict-ignore
import { bindActionCreators } from "@reduxjs/toolkit";
import { type AxiosResponse } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { createAlert as createAlertAction } from "actions/alerts";
import { type AlertArguments } from "actions/alerts/types";
import { closeModalAction } from "actions/modal";
import { fetchOne } from "actions/resources";
import { type Dispatch } from "actions/types";
import { Banner } from "components/Common/Banner";
import { Button } from "components/Common/Button";
import { InputText } from "components/Common/InputText";
import { Loading } from "components/Common/Loading";
import SvgIcon from "components/Common/SvgIcon";
import * as Modal from "components/Shared/Pages/Settings/Modals/styles";
import { adaAPI } from "services/api";

import * as S from "./styles";

interface Credentials {
  [field: string]: string | null;
}

interface ConfigurationSchemaField {
  id: string;
  display_name: string;
  description?: string;
  placeholder?: string;
  is_visible: boolean;
}

interface ConfigurationSchema {
  view?: string;
  verification_url?: string;
  fields: ConfigurationSchemaField[];
  oauth_url?: string;
}

interface OwnProps {
  integrationName: string;
  displayName: string;
  configurationSchema: ConfigurationSchema;
  helpDocsUrl: string;
}

interface PropsFromDispatch {
  createAlert(options: AlertArguments): void;
  closeModal(): void;
  fetchClient(): void;
}

type Props = OwnProps & PropsFromDispatch;

const SettingsPlatformsHandoffsOauthModal = ({
  integrationName,
  displayName,
  configurationSchema,
  helpDocsUrl = "",
  fetchClient,
  closeModal,
  createAlert,
}: Props) => {
  const [credentialsLoaded, setCredentialsLoaded] = useState(false);
  const [oauthInitiated, setOauthInitiated] = useState(false);
  const [credentials, setCredentials] = useState({} as Credentials);

  const hasCredentials = Object.values(credentials).some(
    (credential) => !!credential,
  );

  const handleOauthConclusion = useCallback(
    (success: boolean) => {
      const message: AlertArguments = success
        ? {
            message: `You have successfully connected ${displayName} to your bot`,
            alertType: "success",
          }
        : {
            message: "Authentication failed. Please try again.",
            alertType: "error",
          };

      setOauthInitiated(false);

      createAlert(message);
      fetchClient();
      closeModal();
    },
    [createAlert, closeModal, displayName, fetchClient],
  );

  const fetchCurrentCredentials = async () => {
    try {
      const { data: credentialsData }: AxiosResponse = await adaAPI.request({
        method: "GET",
        url: `/integrations/${integrationName}/credentials`,
      });
      setCredentials(credentialsData.credentials as Credentials);
    } catch {
      setCredentials({});

      if (oauthInitiated) {
        handleOauthConclusion(false);
      }
    }

    setCredentialsLoaded(true);
  };

  useEffect(() => {
    fetchCurrentCredentials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (oauthInitiated && credentialsLoaded && hasCredentials) {
      handleOauthConclusion(true);
    }
  }, [
    handleOauthConclusion,
    credentialsLoaded,
    hasCredentials,
    oauthInitiated,
  ]);

  const renderLoadingState = () => (
    <>
      <h5 className="Modal__title">{displayName}</h5>
      <S.ModalBody>
        <S.Centered>
          <Loading />
        </S.Centered>
      </S.ModalBody>
    </>
  );

  const checkOauthStatus = () => {
    fetchCurrentCredentials();
  };

  const handleModalOpenButtonClick = async () => {
    setOauthInitiated(true);

    const oauthUrl = configurationSchema.oauth_url;

    const windowRef = window.open(oauthUrl);

    const timer = setInterval(() => {
      if (windowRef && windowRef.closed) {
        clearInterval(timer);
        checkOauthStatus();
      }
    }, 500);
  };

  const renderEmptyState = () => (
    <S.ModalBody>
      <S.ColumnCentered>
        <Modal.ModalHeader>
          <Modal.ModalTitle>Connect Ada to {displayName}</Modal.ModalTitle>
          <Modal.ModalDescription>
            To ensure you are successful we recommend you have our setup guide
            and your {displayName} dashboard open during this process.
          </Modal.ModalDescription>
        </Modal.ModalHeader>

        <S.PopoutLink
          href={helpDocsUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <S.PopoutIcon>
            <SvgIcon icon="PopOut" />
          </S.PopoutIcon>
          <S.PopoutLinkText>{`Open ${displayName}  Guide`}</S.PopoutLinkText>
        </S.PopoutLink>
      </S.ColumnCentered>
    </S.ModalBody>
  );

  const returnHiddenCredentialsText = (credentialsText: string | null) => {
    if (!credentialsText) {
      return "";
    }

    return credentialsText.replaceAll(/./g, "•");
  };

  const renderField = (field: ConfigurationSchemaField, index: number) => (
    <section className="input-row" key={`${field.display_name}__${index}`}>
      <S.Title>{field.display_name}</S.Title>
      <S.InputContainer>
        <InputText
          multiline
          value={returnHiddenCredentialsText(credentials[field.id])}
          disabled
        />
      </S.InputContainer>
    </section>
  );

  const renderCredentialFields = () => (
    <>
      <h5 className="Modal__title">{displayName}</h5>
      <S.ModalBody>
        {helpDocsUrl && (
          <Banner icon="QuestionCircleFilled">
            Need help? Try the following{" "}
            <a href={helpDocsUrl} target="_blank" rel="noopener noreferrer">
              Configuration Guide
            </a>
          </Banner>
        )}
        {configurationSchema.fields.map((field, index) =>
          renderField(field, index),
        )}
      </S.ModalBody>
    </>
  );

  const renderContent = () => {
    if (!credentialsLoaded) {
      return renderLoadingState();
    }

    if (!hasCredentials) {
      return renderEmptyState();
    }

    return renderCredentialFields();
  };

  return (
    <Modal.Modal>
      {renderContent()}
      <Modal.ModalFooter>
        <Button onClick={closeModal} text="Cancel" title="Cancel" light />
        <Button
          onClick={handleModalOpenButtonClick}
          text="Connect"
          title="Connect"
        />
      </Modal.ModalFooter>
    </Modal.Modal>
  );
};

function mapDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      closeModal: closeModalAction,
      createAlert: createAlertAction,
      fetchClient: () => dispatch(fetchOne("client")),
    },
    dispatch,
  );
}

export const connectSettingsPlatformsHandoffsOauthModal = connect(
  null,
  mapDispatch,
)(SettingsPlatformsHandoffsOauthModal);
