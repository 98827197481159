import { Button, Icon } from "@adasupport/byron";
import isEqual from "lodash.isequal";
import { type LegacyDataNode } from "rc-tree-select/lib/interface";
import React, { type Key, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createTag } from "actions";
import { createAlert } from "actions/alerts";
import { fetchOneFolderAction } from "actions/folders";
import { closeModalAction } from "actions/modal";
import { fetchMany } from "actions/resources";
import { updateResponse } from "actions/responses";
import { Button as CommonButton } from "components/Common/Button";
import { CheckboxPanel } from "components/Common/CheckboxPanel";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { InfoTooltip } from "components/Common/InfoTooltip";
import { InputSearch } from "components/Common/InputSearch";
import { LanguageButton } from "components/Common/LanguageButton";
import { Loading } from "components/Common/Loading";
import { ReadOnlyWrapper } from "components/Common/ReadOnlyWrapper";
import { Tag } from "components/Common/Tag";
import { type ResponseRecord } from "reducers/responses/types";
import { getResources } from "selectors/resources";
import { useClient } from "services/client";
import { createTreeStructure } from "services/folders";
import { selectResponseGoodToSave } from "services/responses/selectors/selectResponseGoodToSave";
import { findAndReorderTopTag, isTopTag } from "services/tags";
import colors from "stylesheets/utilities/colors.scss";

import { FolderSelect } from "../FolderSelect";

import { MultiLangAnswerLabels } from "./MultiLangAnswerLabels";
import * as S from "./styles";

interface Props {
  onSave: (updatedResponse: ResponseRecord) => void;
  response: ResponseRecord;
}

export function ModalResponseDetails({ onSave, response }: Props) {
  const dispatch = useDispatch();
  const { client } = useClient();
  const languages = useSelector((state) => getResources(state, "language"));
  const foldersState = useSelector((state) => state.foldersState);

  const { rootFolders, rootFolderId, foldersById } = foldersState;

  useEffect(() => {
    if (!languages) {
      dispatch(fetchMany("language"));
    }
  }, [dispatch, languages]);

  const tags = useSelector((state) => state.tags);

  const [handle, setHandle] = useState(response.handle);
  const [description, setDescription] = useState(response.description);
  const [parentId, setParentId] = useState<Key | undefined>(
    response.parentId || rootFolderId,
  );
  const [buttonLabels, setButtonLabels] = useState(
    response.buttonLabel.toJS() as Record<string, unknown>,
  );
  const [reviewable, setReviewable] = useState(response.reviewable);
  const [clarifications, setClarifications] = useState(response.clarifications);
  const [tagSearchQuery, setTagSearchQuery] = useState("");
  const [tagListScrolledToBottom, setTagListScrolledToBottom] = useState(false);
  const [responseTagIds, setResponseTagIds] = useState(response.tags);

  const treeSelectData = useMemo(() => {
    if (rootFolderId) {
      return createTreeStructure({
        rootFolders,
        rootFolderId,
        foldersById,
        isTreeSelect: true,
      });
    }

    return [];
  }, [rootFolderId, rootFolders, foldersById]);

  const isNew = !response.id;
  const updatedAttributes = {
    handle,
    description,
    parentId: parentId || rootFolderId,
    buttonLabel: buttonLabels,
    reviewable,
    clarifications,
    tags: responseTagIds,
    isDirty: true,
  };

  function hasChanges() {
    // Case for new answer
    if (response.new) {
      return true;
    }

    return (
      handle !== response.handle ||
      description !== response.description ||
      !isEqual(buttonLabels, response.buttonLabel.toJS()) ||
      reviewable !== response.reviewable ||
      clarifications !== response.clarifications ||
      !isEqual(responseTagIds.toJS().sort(), response.tags.toJS().sort()) ||
      parentId !== response.parentId
    );
  }

  const renderHandle = () => (
    <S.InputSectionContainer>
      <S.HandleLabelContainer>
        <label className="g-input__label" htmlFor="New_response_modal_handle">
          Title
        </label>
        {response.reserved && (
          <InfoTooltip
            blurb="Titles for locked answers cannot be changed"
            iconDefault="Lock"
            iconClicked="Lock"
            absolute
            inModal
          />
        )}
      </S.HandleLabelContainer>
      <DebouncedInput
        id="New_response_modal_handle"
        placeholder="Answer Title"
        value={handle}
        onChange={(newVal: string) => {
          setHandle(newVal);

          /* It sucks, but when this was built, it was made to persist changes even when the modal
           * is closed. To avoid confusion, we need to keep the legacy behaviour - so we need to
           * update the Redux state too if the response is not new, not just the component state.
           */
          if (!isNew) {
            dispatch(
              updateResponse(response.id, {
                handle: newVal,
              }),
            );
          }
        }}
        disabled={response.reserved}
        isInvalid={!handle.trim()}
        autoFocus
      />
    </S.InputSectionContainer>
  );

  const renderDescription = () => (
    <S.InputSectionContainer>
      <label
        className="g-input__label"
        htmlFor="New_response_modal_description"
      >
        Description
      </label>
      <DebouncedInput
        id="New_response_modal_description"
        placeholder="Something descriptive about this Answer to help your team find it in lists"
        value={description}
        onChange={(newVal: string) => {
          setDescription(newVal);

          if (!isNew) {
            dispatch(
              updateResponse(response.id, {
                description: newVal,
              }),
            );
          }
        }}
        isTextArea
      />
    </S.InputSectionContainer>
  );

  const renderAnswerLabels = () => {
    if (!response.hasButtonLabel) {
      return null;
    }

    if (!languages) {
      return <Loading />;
    }

    const sortedButtonLabels = Object.keys(buttonLabels)
      .sort((a, b) => {
        if (a === client.language) {
          return -1;
        }

        if (b === client.language) {
          return 1;
        }

        return 0;
      })
      .map(
        (languageCode) =>
          [languageCode, buttonLabels[languageCode] as string] as const,
      );

    const availableLanguages = languages
      .filter(
        (language) =>
          client.translatedLanguages.includes(language.code) &&
          !Object.keys(buttonLabels).includes(language.code),
      )
      .sortBy((language) => language.englishName);

    const languageButton = (
      <LanguageButton
        availableLanguages={availableLanguages}
        addLanguage={(languageCode: string) => {
          const newButtonLabels: { [key: string]: string | null } = {
            ...(buttonLabels as { [key: string]: string | null }),
            [languageCode]: null,
          };

          setButtonLabels(newButtonLabels);

          if (!isNew) {
            dispatch(
              updateResponse(response.id, {
                buttonLabel: newButtonLabels,
              }),
            );
          }
        }}
        color="white"
      />
    );

    return (
      <S.SidePadding>
        <MultiLangAnswerLabels
          sortedButtonLabels={sortedButtonLabels}
          response={response}
          languageButton={languageButton}
          handleUpdateButtonLabel={(
            buttonLabel: string,
            languageCode: string,
          ) => {
            const newButtonLabels = {
              ...buttonLabels,
              [languageCode]: buttonLabel,
            };

            setButtonLabels(newButtonLabels);

            if (!isNew) {
              dispatch(
                updateResponse(response.id, {
                  buttonLabel: newButtonLabels,
                }),
              );
            }
          }}
          handleDeleteButtonLabel={(languageCode: string) => {
            const clone = { ...buttonLabels };
            delete clone[languageCode];

            setButtonLabels(clone);

            if (!isNew) {
              dispatch(
                updateResponse(response.id, {
                  buttonLabel: clone,
                }),
              );
            }
          }}
        />
      </S.SidePadding>
    );
  };

  const renderLink = (address: string, text: string) => (
    <S.Link href={address} target="_blank" rel="noopener noreferrer">
      {text}
    </S.Link>
  );

  const renderToggles = () => {
    if (response.reserved) {
      return null;
    }

    const reviewableTooltipMessage =
      "Answer will appear with thumbs up and down buttons";

    const suggestionsTitle = client.features.predictive_suggestions
      ? "Include in Clarifications and Suggestions"
      : "Include in Clarifications";
    const suggestionCopy = client.features.predictive_suggestions
      ? "Allow the bot to serve this Answer automatically to clarify questions or suggest it while the customer types. Only answers with training will be served."
      : "Allow the bot to serve this Answer automatically to clarify questions. Only answers with training will be served.";

    return (
      <S.SidePadding>
        <CheckboxPanel
          label="Reviewable"
          checked={reviewable}
          handleToggle={(newVal: boolean) => {
            setReviewable(newVal);

            if (!isNew) {
              dispatch(
                updateResponse(response.id, {
                  reviewable: newVal,
                }),
              );
            }
          }}
          showTooltip
          tooltipMessage={reviewableTooltipMessage}
          tooltipImgPath="https://static.ada.support/tooltips/Reviewable-Answer-Tooltip-Rounded.gif"
          tooltipImgAltText={reviewableTooltipMessage}
          maxWidth={208}
        />
        <S.ToggleDescription>
          Allow the customer to review the usefulness of this Answer.
        </S.ToggleDescription>
        <S.ThinDivider />
        <CheckboxPanel
          label={suggestionsTitle}
          checked={clarifications}
          handleToggle={(newVal: boolean) => {
            setClarifications(newVal);

            if (!isNew) {
              dispatch(
                updateResponse(response.id, {
                  clarifications: newVal,
                }),
              );
            }
          }}
          tooltipMessage="Include this Answer as a clarification or prediction"
        />
        <S.ToggleDescription>{suggestionCopy}</S.ToggleDescription>
        <S.ToggleDescription>
          Learn more about{" "}
          {renderLink(
            "https://docs.ada.cx/not-understood-clarifications",
            "Clarifications",
          )}
          {client.features.predictive_suggestions && (
            <>
              {" "}
              and{" "}
              {renderLink(
                "https://docs.ada.cx/predictive-suggestions",
                "Suggestions",
              )}
            </>
          )}
        </S.ToggleDescription>
      </S.SidePadding>
    );
  };

  function renderResponseTags() {
    const responseTags = tags.filter((tag) => responseTagIds.includes(tag.id));
    const arrangedTags = findAndReorderTopTag(responseTags);

    return (
      <S.ResponseTagsList>
        {arrangedTags.map((tag) => (
          <S.ResponseTagContainer key={tag.id}>
            <Tag
              tag={tag}
              removeTag={(tagId: string) => {
                const newTagIds = responseTagIds.filter((id) => id !== tagId);
                setResponseTagIds(newTagIds);

                if (!isNew) {
                  dispatch(
                    updateResponse(response.id, {
                      tags: newTagIds,
                    }),
                  );
                }
              }}
              hasIcon={isTopTag(tag.name)}
            />
          </S.ResponseTagContainer>
        ))}
      </S.ResponseTagsList>
    );
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (
      e.currentTarget.scrollTop >=
      e.currentTarget.scrollHeight - e.currentTarget.offsetHeight
    ) {
      setTagListScrolledToBottom(true);
    } else {
      setTagListScrolledToBottom(false);
    }
  };

  function renderAllTags() {
    const filteredTags = tags.filter(
      (tag) =>
        tag.name.toLowerCase().indexOf(tagSearchQuery.toLowerCase()) >= 0 &&
        !responseTagIds.includes(tag.id),
    );
    const arrangedTags = findAndReorderTopTag(filteredTags);

    if (!arrangedTags.length) {
      return null;
    }

    return (
      <S.AllTagsListOuterContainer>
        <S.AllTagsListContainer onScroll={handleScroll}>
          {arrangedTags.map((tag) => (
            <S.TagListItem
              role="button"
              key={tag.id}
              onClick={() => {
                const newTagIds = responseTagIds.push(tag.id);
                setResponseTagIds(newTagIds);

                if (!isNew) {
                  dispatch(
                    updateResponse(response.id, {
                      tags: newTagIds,
                    }),
                  );
                }
              }}
            >
              <Tag tag={tag} hasIcon={isTopTag(tag.name)} />
              {isTopTag(tag.name) && (
                <S.TopTagText>
                  Select this ‘Top’ tag if this Answer is at the start of a
                  decision tree.
                </S.TopTagText>
              )}
            </S.TagListItem>
          ))}
        </S.AllTagsListContainer>
        <S.TagListGradient
          visible={filteredTags.length > 6 && !tagListScrolledToBottom}
        />
      </S.AllTagsListOuterContainer>
    );
  }

  function addTag(query: string) {
    if (!query) {
      return;
    }

    let tag = tags.find((t) => t.name.toLowerCase() === query);

    if (isTopTag(query)) {
      tag = tags.find((t) => t.name.toLowerCase() === query.toLowerCase());
    }

    if (tag) {
      if (responseTagIds.includes(tag.id)) {
        dispatch(
          createAlert({
            message: `"${response.handle}" already contains this tag.`,
          }),
        );
      } else {
        const newTagIds = responseTagIds.push(tag.id);
        setResponseTagIds(newTagIds);

        if (!isNew) {
          dispatch(
            updateResponse(response.id, {
              tags: newTagIds,
            }),
          );
        }
      }
    } else {
      dispatch(createTag(response, query));
    }
  }

  const renderTags = () => (
    <ReadOnlyWrapper hide>
      <S.SidePadding>
        {!client.features.experiment_voice && (
          <label
            className="g-input__label"
            htmlFor="New_response_modal_tag_search"
          >
            Tags
          </label>
        )}
        {renderResponseTags()}
        <div>
          <InputSearch
            idForLabel="New_response_modal_tag_search"
            value={tagSearchQuery}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTagSearchQuery(event.currentTarget.value);
            }}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) =>
              event.key === "Enter" && addTag(event.currentTarget.value)
            }
            placeholder="Search to add tags"
            hideChevron
          />
          <S.AddTagButtonContainer>
            <CommonButton
              onClick={() => addTag(tagSearchQuery)}
              title="Add a new Tag"
              icon="CircleAdd"
              fillColor={
                tagSearchQuery.length
                  ? colors.colorUIPrimary
                  : colors.colorGrey4
              }
              clear
            />
          </S.AddTagButtonContainer>
        </div>
        {renderAllTags()}
      </S.SidePadding>
    </ReadOnlyWrapper>
  );

  const onExpandFolder = async (dataNode: LegacyDataNode) => {
    const { key } = dataNode;

    if (key) {
      await dispatch(fetchOneFolderAction(key));
    }
  };

  const renderFolderSelect = () => (
    <S.SidePadding>
      <FolderSelect
        initialValue={parentId}
        onSelectFolder={setParentId}
        folders={treeSelectData}
        label="Add to Folder"
        onExpandFolder={onExpandFolder}
      />
    </S.SidePadding>
  );

  const canSaveResponse = useSelector((state) =>
    selectResponseGoodToSave(
      state,
      response.merge(updatedAttributes as Partial<ResponseRecord>),
    ),
  );

  const renderBody = () => (
    <>
      {renderHandle()}
      {renderDescription()}
      {renderFolderSelect()}
      {renderAnswerLabels()}
      <S.HalfSpacer />
      {renderToggles()}
      <S.Divider />
      {renderTags()}
    </>
  );

  return (
    <div className="Modal__modal">
      <S.WrappedTitle>
        <h5 className="Modal__title">{handle && `'${handle}'`} Settings</h5>
      </S.WrappedTitle>
      <S.Body>{renderBody()}</S.Body>
      <div className="Modal__bottom">
        <Button
          onClick={() => {
            onSave(
              response.merge(updatedAttributes as Partial<ResponseRecord>),
            );
            dispatch(closeModalAction());
          }}
          text="Save"
          dataTestId="save"
          icon={Icon.Cloud}
          isDisabled={!hasChanges() || !canSaveResponse}
        />
      </div>
    </div>
  );
}
