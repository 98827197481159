import * as React from "react";

import * as S from "./styles";

// Maps heading level strings to their respective styled components
const headingLevelMap = {
  h1: S.H1,
  h2: S.H2,
  h3: S.H3,
  h4: S.H4,
};

interface TitleProps
  extends Omit<React.HTMLProps<HTMLHeadingElement>, "size" | "className"> {
  /** Content rendered inside the Title */
  children?: React.ReactNode;
  /** The heading  to use */
  headingLevel: "h1" | "h2" | "h3" | "h4";
}

const Title: React.FunctionComponent<React.PropsWithChildren<TitleProps>> = ({
  children = "",
  headingLevel,
}: TitleProps) => {
  const HeadingComponent = headingLevelMap[headingLevel];

  return <HeadingComponent>{children}</HeadingComponent>;
};

export { Title, TitleProps };
