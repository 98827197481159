import Immutable from "immutable";

// This is all magic from webpack. require.context is a
// static replacement that is used to import all files from a folder.
// There is one unfortunate side effect of this though, which is that in
// tests, the import does not work without a lot of configuration.

// Since there is little value in testing this component
// (its used everywhere and will be quite obvious when it breaks)
// it is sufficient to simply ignore the import in testing.
// This can be updated in the future to import the svg icons

let cache = Immutable.Map();

function importAll(r) {
  // the elvis operator is used here in case r is undefined.
  r?.keys().forEach((key) => {
    cache = cache.set(key, r(key));
  });
}

if (typeof require.context === "undefined") {
  require.context = function context() {
    /* do nothing for tests */
  };
}

importAll(require.context("static/icons/", true));

export default Immutable.Map(cache);
