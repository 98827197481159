import { primitives } from "@adasupport/byron";
import styled from "styled-components";

import { cssVariables as c } from "constants/css";

export const PersistenceLabel = styled.div`
  & label {
    display: flex;
    align-items: center;
  }
`;

export const PersistenceHeader = styled.div`
  padding-bottom: ${c.space2};

  & h4 {
    font-weight: ${c.mediumFontWeight};
    font-size: ${c.smallFontSize};
    margin-bottom: ${c.space1};
  }

  & p {
    color: ${primitives.palette.slate500};
    font-size: ${c.smallFontSize};
    line-height: 21px;
  }
`;

export const PersistenceOptions = styled.div`
  display: flex;
`;

export const ExpiryTimeInput = styled.input<{ isInvalid: boolean }>`
  width: 72px !important;
  margin: auto ${c.space2};
  height: 36px;
  border-radius: 6px;
  padding: 6px;
  transition: 150ms ease;
  user-select: none;
  box-shadow: inset
    ${(p) =>
      p.isInvalid ? primitives.palette.red400 : primitives.palette.slate400}
    0 0 0 1px;
  border: none;
  display: block;
  cursor: text;
`;
