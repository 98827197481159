import { createAlert } from "actions/alerts";
import { bulkTrainingAddedAlert } from "actions/expressions/helpers/bulkTrainingAddedAlert";
import { closeModal } from "actions/modal";
import { keyConverter } from "services/key-converter";
import { deserializeTraining } from "slices/training/deserialization";

/**
 * If there are errors during the action, add them to the modalProps to be displayed
 * in the modal
 * @param {Object} args
 * @returns {Function}
 */
function handleBulkAddErrors(args) {
  const { errors } = args.response.data;

  return (dispatch, getState) => {
    const { modalProps } = getState().modal;
    dispatch({
      type: "SET_MODAL",
      payload: {
        isOpen: true,
        view: "MODAL_BULK_TRAINING",
        modalProps: { ...modalProps, errors: keyConverter(errors, "camel") },
      },
    });
  };
}

function handleBulkAddSuccess(args) {
  const expressions = args.response.data.created_expressions;

  return (dispatch) => {
    dispatch(bulkTrainingAddedAlert(deserializeTraining(expressions)));
  };
}

/**
 * @param {Immutable.Record} response
 * @param {Immutable.List} trainingData
 * @returns {Function}
 */
export function handleAddBulkTrainingAction(response, trainingData) {
  const operations = trainingData.map((body) => ({
    body,
    responseId: response.id,
  }));

  const payload = keyConverter(
    {
      operationType: "batch_create",
      operations,
    },
    "underscore",
  );

  return {
    // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
    CALL_API: {
      method: "post",
      endpoint: "/expressions/rpc/modify_expressions",
      payload,
      types: [
        "CREATE_EXPRESSIONS_REQUEST",
        "CREATE_EXPRESSIONS_SUCCESS",
        "CREATE_EXPRESSIONS_FAILURE",
      ],
      dispatchCallbacks: [
        {
          request: createAlert,
          fireOnStatus: 400,
          args: {
            message: "Sorry, your request could not be completed.",
            alertType: "error",
          },
        },
        {
          request: createAlert,
          fireOnStatus: 409,
          args: {
            message:
              "Sorry, one (or more) of those questions already exists on other answers.",
            alertType: "warning",
          },
        },
        {
          request: handleBulkAddErrors,
          fireOnStatus: 409,
        },
        {
          request: handleBulkAddSuccess,
          fireOnStatus: 200,
          args: {
            responseId: response.id,
          },
        },
        {
          request: closeModal,
          fireOnStatus: 200,
        },
        {
          request: createAlert,
          fireOnStatus: 207,
          args: {
            message:
              "Sorry, one (or more) of those questions could not be trained.",
            alertType: "warning",
          },
        },
        {
          request: handleBulkAddErrors,
          fireOnStatus: 207,
        },
      ],
    },
  };
}
