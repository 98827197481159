import { type AnyAction } from "@reduxjs/toolkit";
import Immutable from "immutable";

import { ExpressionRecord } from "reducers/expressions/types";
import { createRecordFromResponse } from "reducers/responses/reducer";
import { ResponseRecord } from "reducers/responses/types";
import { createVariableRecord } from "reducers/variables/helpers";
import { keyConverter } from "services/key-converter";
import {
  type VariableAttributes,
  type VariableRecord,
} from "services/variables";

export interface VersionAttributes {
  client_id: string;
  commit_author_id: string;
  commit_author_name: string;
  commit_parent: string;
  created: number;
  description: string;
  generated_description: string;
  title: string;
  updated: number;
  _id: string;
  snapshot: Immutable.Map<string, unknown>;
  user_path: string;
  deleted_responses: string[];
}

export class Version extends Immutable.Record({
  clientId: "",
  commitAuthorId: "",
  commitAuthorName: "",
  commitParent: "",
  created: 0,
  description: "",
  title: "",
  updated: 0,
  id: "",
  snapshot: Immutable.Map(),
}) {}

const initialState = Immutable.Map({
  shallow: Immutable.Map(),
  full: Immutable.Map(),
});

/**
 *
 * @param {Immutable.Map} state
 * @param {Object} action
 */
export const responseVersions = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case "FETCH_RESPONSE_VERSIONS_SHALLOW_SUCCESS": {
      const fetchedVersionData = Immutable.List<VersionAttributes>(
        action.data.versions.map((version: Immutable.Map<string, unknown>) =>
          keyConverter(version),
        ),
      );

      const fetchedVersions = fetchedVersionData.map((v) => new Version(v));

      if (action.replace) {
        return state.setIn(["shallow", action.responseId], fetchedVersions);
      }

      return state.updateIn(["shallow", action.responseId], (versions) =>
        versions ? versions.concat(fetchedVersions) : fetchedVersions,
      );
    }

    case "PATCH_VERSION_TITLE_DESCRIPTION_SUCCESS": {
      const updatedVersion = new Version(
        keyConverter(action.response.data.version as Record<string, unknown>),
      );

      return state.updateIn(["shallow", action.responseId], (versions) =>
        versions?.map((version: Version) => {
          if (version.id === action.versionId) {
            return updatedVersion;
          }

          return version;
        }),
      );
    }

    case "FETCH_RESPONSE_VERSION_SUCCESS": {
      let version = new Version(
        Immutable.fromJS(keyConverter(action.data.version)),
      );

      version = version.updateIn(["snapshot", "response"], (u) =>
        createRecordFromResponse(u, null),
      );
      version = version.updateIn(["snapshot", "variables"], (variables) => {
        let variablesMap = variables
          .map((variable: VariableAttributes) =>
            createVariableRecord(variable, true),
          )
          .groupBy((variable: VariableRecord) => variable.scope);

        if (variablesMap.has("local")) {
          variablesMap = variablesMap.update(
            "local",
            (localVariables: Immutable.List<VariableRecord>) =>
              localVariables.groupBy((variable) => variable.responseId),
          );
        }

        return variablesMap;
      });

      version = version.updateIn(["snapshot", "expressions"], (expressions) =>
        expressions.map(
          (e: Immutable.Map<string, unknown>) => new ExpressionRecord(e),
        ),
      );
      version = version.updateIn(
        ["snapshot", "shallowResponses"],
        (shallowResponses) =>
          shallowResponses.map(
            (data: Partial<ResponseRecord>) => new ResponseRecord(data),
          ),
      );

      return state.setIn(
        ["full", action.responseId, action.versionId],
        version,
      );
    }

    default:
      return state;
  }
};
