import { Select, primitives, tokens } from "@adasupport/byron";
import React from "react";
import styled from "styled-components";

import { RULE_DSL, type RULE_DSL_VALUE, type RuleGroup } from "services/rules";

const S = {
  RuleGroupOperator: styled.div`
    color: ${tokens.colors.text.muted};
    display: flex;
    align-items: center;
    font-size: ${primitives.fontSize.small};
  `,
};

const getOperatorLabel = (operator: string) => {
  switch (operator) {
    case RULE_DSL.ALL:
      return "and";
    case RULE_DSL.ANY:
      return "or";
    default:
      return null;
  }
};

const operatorOptions = [
  { value: RULE_DSL.ALL, label: "And" },
  { value: RULE_DSL.ANY, label: "Or" },
];

export const RuleGroupOperator = ({
  ruleGroup,
  index,
  onChange,
}: {
  ruleGroup: RuleGroup;
  index: number;
  onChange: (value: RULE_DSL_VALUE) => void;
}) => {
  const operator = ruleGroup.name;

  if (index === 0) {
    return <S.RuleGroupOperator>Where</S.RuleGroupOperator>;
  }

  if (index === 1) {
    return (
      <S.RuleGroupOperator>
        <Select
          variant="small"
          value={operator}
          options={operatorOptions}
          onChange={onChange}
        />
      </S.RuleGroupOperator>
    );
  }

  return (
    <S.RuleGroupOperator>{getOperatorLabel(operator)}</S.RuleGroupOperator>
  );
};
