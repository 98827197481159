import { createSelector } from "reselect";

import { versionedResponsesLoadedSelector } from "components/Shared/Pages/Responses/ResponseVersions/selectors";

import { selectActiveResponseId } from "./selectActiveResponseId";

export const selectActiveResponse = createSelector(
  selectActiveResponseId,
  versionedResponsesLoadedSelector,
  (responseId, responsesLoaded) => responsesLoaded.get(responseId ?? ""),
);
