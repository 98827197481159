// @ts-strict-ignore
import Immutable from "immutable";

import { CHARACTER_LIMIT } from "constants/messages";
import {
  canEvalToEmptyString,
  removeTemplateVariables,
} from "services/strings";

import BaseMessageRecord, {
  type BaseMessageRecordAttributes,
} from "../BaseMessageRecord";

interface TextMessageRecordAttributes extends BaseMessageRecordAttributes {
  type: "text";
  body: string;
}

export class TextMessageRecord extends BaseMessageRecord<TextMessageRecordAttributes>(
  {
    type: "text",
    body: "",
  },
) {
  static getInvalidFields = (
    message: TextMessageRecord,
  ): Immutable.List<string> => {
    const invalidFields: Set<string> = new Set();
    const bodyWithoutTemplateVars = removeTemplateVariables(message.body);

    if (
      message.body.trim().length <= 0 ||
      canEvalToEmptyString(message.body) ||
      bodyWithoutTemplateVars.trim().length > CHARACTER_LIMIT
    ) {
      invalidFields.add("body");
    }

    return Immutable.List(invalidFields);
  };
}
