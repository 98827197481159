import { type MessageBlock } from "reducers/messageBlocks/types";
import { keyConverter } from "services/key-converter";

export interface FetchMessageBlocksSuccessAction {
  type: "FETCH_MESSAGE_BLOCKS_SUCCESS";
  blocks: Record<string, unknown>[];
}

export function messageBlocks(
  state: MessageBlock[] | null = null,
  action: FetchMessageBlocksSuccessAction,
) {
  switch (action.type) {
    case "FETCH_MESSAGE_BLOCKS_SUCCESS":
      return keyConverter(action.blocks) as unknown as MessageBlock[];

    default:
      return state;
  }
}
