import Immutable from "immutable";

import { type ClientLegacy } from "resourceModels";
import { getAPIRoot } from "services/api-root";

class SalesforcePlatformRecord extends Immutable.Record({
  name: "salesforce_liveagent",
  fullName: "Salesforce Chat",
  enabled: false,
  salesforceHostnameUrl: "",
  organizationId: "",
  deploymentId: "",

  accessToken: "",
  refreshToken: "",

  auth: Immutable.Map({
    instanceUrl: "",
    redirectUri: `${
      process.env.NODE_ENV === "production"
        ? getAPIRoot()
        : "https://test.api.ngrok.ada.support"
    }/oauth/salesforce_liveagent`,
    clientId: "",
    clientSecret: "",
  }),

  isVisibleToClient(client: ClientLegacy): boolean {
    return client.features.get("adaGlassSalesforce") || false;
  },
}) {}

export { SalesforcePlatformRecord };
