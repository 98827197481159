import { Button, Icon, IconButton, primitives } from "@adasupport/byron";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { createAlert } from "actions/alerts";
import { closeModalAction } from "actions/modal";
import { InputText } from "components/Common";
import { RadioButton } from "components/Common/RadioButton";
import { useConnectTopicsMutation } from "slices/generatedTopics/generatedTopicsApi";

import * as S from "./styles";

interface Topic {
  name: string;
  summary: string;
}
interface Props {
  topics: { [k: string]: Topic };
  refetch: () => void;
}

export const ConnectGeneratedTopicsModal = ({ topics, refetch }: Props) => {
  const topicsArr = Object.entries(topics).sort((a, b) => {
    const keyA = a[1].name;
    const keyB = b[1].name;

    return keyA.localeCompare(keyB);
  });

  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [checkedName, setCheckedName] = useState("");
  const [newName, setNewName] = useState("");
  const [newSummary, setNewSummary] = useState(topicsArr[0]?.[1]?.summary);
  const [summaryNav, setSummaryNav] = useState(0);
  const [showAllNames, setShowAllNames] = useState(false);
  const [connectTopics] = useConnectTopicsMutation();

  const handleNextStep = async () => {
    if (step === 0 || step === 1) {
      if (newName === "") {
        dispatch(
          createAlert({ message: "Please select a name", alertType: "error" }),
        );
      } else {
        setStep(2);
      }
    } else if (step === 2 && newSummary === "") {
      dispatch(
        createAlert({ message: "Please enter a summary", alertType: "error" }),
      );
    } else if (step === 3) {
      try {
        await connectTopics({
          topic_ids: topicsArr.map((t) => t[0]),
          custom_topic_label: newName,
          custom_topic_summary: newSummary,
        }).unwrap();
        refetch();
        dispatch(
          createAlert({
            message: "Topics connected! Allow up to 1h to complete",
            alertType: "success",
          }),
        );
        dispatch(closeModalAction());
      } catch (err) {
        dispatch(
          createAlert({
            message: `Error connecting topics`,
            alertType: "error",
          }),
        );
      }
    } else if (step === 4) {
      dispatch(closeModalAction());
    } else {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    if (step === 2) {
      setStep(0);
    } else {
      setStep(step - 1);
    }
  };

  const handleCancel = () => {
    if (step === 4) {
      setStep(0);
    } else {
      setStep(4);
    }
  };

  const handleSetName = (id: string) => () => {
    const { name, summary } = topics[id] as Topic;
    setCheckedName(id);
    setNewName(name);
    setNewSummary(summary);
    setSummaryNav(topicsArr.findIndex((t) => t[1].summary === summary));
  };

  const handleSummaryNav = (change: -1 | 1) => () => {
    const newNav = summaryNav + change;
    setSummaryNav(newNav);
    setNewSummary(topicsArr[newNav]?.[1].summary);
  };

  const optionsToRender = (all: boolean) => {
    const uniqueNames = topicsArr.reduce(
      (obj, [id, { name }]) => ({ ...obj, [name]: { id, name } }),
      {} as { [k: string]: { id: string; name: string } },
    );

    return all
      ? Object.values(uniqueNames)
      : Object.values(uniqueNames).slice(0, 2);
  };

  const cancelConfirmStep = (
    <>
      <S.Title>
        <Icon.Exclamation
          height={24}
          width={24}
          color={primitives.palette.red500}
        />{" "}
        Unsaved changes
      </S.Title>
      <S.Body>
        <p>
          There are changes that are not saved and topics combine will not be
          performed. Please confirm that you want to leave.
        </p>
      </S.Body>
    </>
  );

  const selectNameStep = (
    <>
      <S.Title>Select topic&apos;s name</S.Title>
      {optionsToRender(showAllNames).map(({ id, name }) => (
        <S.Body key={id}>
          <RadioButton
            id={id}
            label={name}
            checked={checkedName === id}
            onClick={handleSetName(id)}
          />
        </S.Body>
      ))}
      <S.Body last>
        {optionsToRender(true).length > 2 && (
          <>
            <S.InlineButton onClick={() => setShowAllNames(!showAllNames)}>
              See {showAllNames ? "less" : "more"}
            </S.InlineButton>{" "}
          </>
        )}
        or{" "}
        <S.InlineButton
          onClick={() => {
            setNewName("");
            setStep(step + 1);
          }}
        >
          create a new name
        </S.InlineButton>
      </S.Body>
    </>
  );

  const createNameStep = (
    <>
      <S.Title>Create topic&apos;s name</S.Title>
      <S.Body>
        <InputText
          value={newName}
          onChange={(v) => setNewName(v)}
          placeholder="topic name"
        />
      </S.Body>
    </>
  );

  const summaryStep = (
    <>
      <S.Title>Choose or edit topic&apos;s summary</S.Title>
      <S.Body>
        {newName}
        <S.Summary>
          <InputText
            value={newSummary ?? ""}
            onChange={(v) => setNewSummary(v)}
            multiline
            minRows={3}
          />
        </S.Summary>
      </S.Body>
      <S.SummaryNavigation>
        <IconButton
          icon={Icon.ChevronLeft}
          size="small"
          aria-label="Previous summary"
          variant="tertiary"
          onClick={handleSummaryNav(-1)}
          isDisabled={summaryNav === 0}
        />
        {summaryNav + 1} of {topicsArr.length}
        <IconButton
          icon={Icon.ChevronRight}
          size="small"
          aria-label="Next summary"
          variant="tertiary"
          onClick={handleSummaryNav(1)}
          isDisabled={summaryNav === topicsArr.length - 1}
        />
      </S.SummaryNavigation>
    </>
  );

  const reviewStep = (
    <>
      <S.Title>Review</S.Title>
      <S.Warning>
        <Icon.Exclamation
          color={primitives.palette.orange600}
          height={24}
          width={24}
        />
        <h6>Combining of the topics cannot be undone </h6>
        <div />
        <div>
          Review carefully before finalizing the combination of the topics.
        </div>
      </S.Warning>
      <S.Body>
        <S.Header>Topics to connect</S.Header>
        {topicsArr.map((t) => (
          <S.TopicName key={t[0]}>{t[1].name}</S.TopicName>
        ))}
      </S.Body>

      <S.Body>
        <S.Header>New topic&apos;s name and summary</S.Header>
        <S.TopicName>{newName}</S.TopicName>
        <p>{newSummary}</p>
      </S.Body>
    </>
  );

  const steps = [
    selectNameStep,
    createNameStep,
    summaryStep,
    reviewStep,
    cancelConfirmStep,
  ];
  const stepButtonText = ["Select", "Continue", "Select", "Combine", "Confirm"];

  const shouldShowBackButton = step !== 4 && step >= 2;

  return (
    <S.Container>
      {steps[step]}
      <S.ActionButtons>
        {shouldShowBackButton ? (
          <Button text="Back" variant="tertiary" onClick={handlePrevStep} />
        ) : (
          <div />
        )}
        <div />
        <Button text="Cancel" variant="secondary" onClick={handleCancel} />
        <Button
          text={stepButtonText[step] as string}
          onClick={handleNextStep}
          variant={step !== 4 ? "primary" : "danger"}
        />
      </S.ActionButtons>
    </S.Container>
  );
};
