// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import md5 from "crypto-js/md5";
import { type Key } from "react";

import { type FoldersStateRecord } from "../reducer";

function createMD5Hash(dataToHash: unknown): string {
  return md5(JSON.stringify(dataToHash)).toString();
}

function createHashForFolder(id: Key, foldersState: FoldersStateRecord) {
  const { foldersById, rootFolderId, rootFolders } = foldersState;

  if (id === rootFolderId) {
    const dataToHash = {
      id,
      children: rootFolders.toJS(),
    };

    return createMD5Hash(dataToHash);
  }

  if (!foldersById.getIn([id, "didLoad"])) return undefined;

  const children = foldersById
    .getIn([id, "children"])
    .map((child: { id: string }) => child.id)
    .toJS();

  const dataToHash = {
    id,
    children,
  };

  return createMD5Hash(dataToHash);
}

export interface DragDropRecordProps {
  new_index: number;
  new_parent_folder_id: Key;
  children_to_move: {
    [current_folder_id: Key]: Set<Key>;
  };
  ordered_children_to_move: Set<{
    _id: Key;
    type: "folder" | "response";
  }>;
  hashes: {
    [folder_id: Key]: string;
  };
}

export class DragDropRecord implements DragDropRecordProps {
  new_index = 0;
  new_parent_folder_id: Key = "";
  children_to_move: {
    [current_folder_id: Key]: Set<Key>;
  } = {};

  ordered_children_to_move: Set<{
    _id: Key;
    type: "folder" | "response";
  }> = new Set();

  hashes: {
    [folder_id: Key]: string;
  } = {};

  constructor(props: DragDropRecordProps | null = null) {
    if (!props) return;

    const {
      new_index,
      new_parent_folder_id,
      children_to_move,
      ordered_children_to_move,
    } = props;

    this.new_index = new_index;
    this.new_parent_folder_id = new_parent_folder_id;
    this.children_to_move = children_to_move;
    this.ordered_children_to_move = ordered_children_to_move;
  }

  updateChildrenToMove(parentId: Key, childId: Key): void {
    if (parentId in this.children_to_move) {
      this.children_to_move[parentId].add(childId);
    } else {
      this.children_to_move[parentId] = new Set();
      this.children_to_move[parentId].add(childId);
    }
  }

  generateFolderHashes(foldersState: FoldersStateRecord): void {
    const parentIds = Object.keys(this.children_to_move).filter(
      (id) => id !== "",
    );
    parentIds.push(this.new_parent_folder_id as string);

    for (let i = 0; i < parentIds.length; i += 1) {
      const id = parentIds[i];
      const hash = createHashForFolder(id, foldersState);
      if (hash) this.hashes[id] = hash;
    }
  }

  generatePayloadObj(foldersState: FoldersStateRecord) {
    this.generateFolderHashes(foldersState);

    const childrenToMove = Object.fromEntries(
      Object.entries(this.children_to_move).map(([key, setValue]) => [
        key,
        Array.from(setValue),
      ]),
    );

    return {
      new_index: this.new_index,
      new_parent_folder_id: this.new_parent_folder_id,
      children_to_move: childrenToMove,
      ordered_children_to_move: [...this.ordered_children_to_move],
      hashes: this.hashes,
    };
  }
}
