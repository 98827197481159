import Immutable from "immutable";

import { type ExpressionActions } from "actions/expressions/types";
import {
  ExpressionRecord,
  type ExpressionShape,
  ExpressionsState,
} from "reducers/expressions/types";
import { keyConverter } from "services/key-converter";
import { recordMerger } from "services/record-merger";

export const expressions = (
  state = new ExpressionsState(),
  action: ExpressionActions,
) => {
  let newExpressions;
  let newExpression: ExpressionRecord;

  switch (action.type) {
    case "GET_EXPRESSIONS_REQUEST":
      return state.merge({
        loading: true,
        error: false,
        scrolling: action.scrolling,
        groupTitle: "",
        keywords: [],
      });

    case "GET_EXPRESSIONS_SUCCESS":
    // falls through

    case "GET_TRAINED_EXPRESSIONS_SUCCESS": {
      let loadedExpressions = state.get("loaded");

      const returnedResponses = action.response.data.expressions.map(
        (expression) =>
          new ExpressionRecord(
            recordMerger(expression, new ExpressionRecord()),
          ),
      );

      if (action.clear) {
        loadedExpressions = loadedExpressions.clear();
      }

      const expressionsArray = loadedExpressions.concat(returnedResponses);
      const lastExpression = expressionsArray.last();
      const queryCount = action.response.data.count;

      return state.merge({
        loaded: expressionsArray,
        loadedCount: expressionsArray.filter(
          (expression) => !expression.responseId,
        ).size,
        loading: false,
        lastExpressionId: lastExpression && lastExpression.id,
        error: false,
        scrolling: false,
        groupTitle: action.response.data.title || "",
        keywords: action.response.data.keywords,
        moreAvailable: action.response.data.more_available,
        lastSort: action.response.data.last_sort,
        ...(queryCount && { queryCount }),
      });
    }

    // Used by Conversations Topic
    // @see fetchConversationsTopicUnansweredQuestionsForExpressionsPage
    case "GET_CONVERSATIONS_TOPIC_EXPRESSIONS_SUCCESS": {
      const unansweredQuestions = action.unansweredQuestions.map(
        (question) =>
          new ExpressionRecord({
            id: question.expression_id,
            active: true,
            bestGuess: null,
            body: question.body,
            bodySimple: question.body,
            clientId: action.clientId,
            confidence: 0,
            chatterId: question.chatter_id,
            conversationId: question.conversation_id,
            created: question.updated,
            origin: "UNKNOWN",
            responseId: null,
            updated: question.updated,
            predictions: question.prediction_ids,
          }),
      );

      const expressionsArray = Immutable.List(unansweredQuestions);
      const lastExpression = expressionsArray.last();
      const queryCount = expressionsArray.size;

      return state.merge({
        loaded: expressionsArray,
        loadedCount: expressionsArray.filter((question) => !question.responseId)
          .size,
        loading: false,
        lastExpressionId: lastExpression && lastExpression.id,
        error: false,
        scrolling: false,
        queryCount,
      });
    }

    case "GET_EXPRESSIONS_FAILURE":
      return state.merge({
        loading: false,
        error: true,
      });

    case "GET_TOTAL_EXPRESSIONS_COUNT_SUCCESS":
      return state.set("queryCount", action.response.data.count);

    case "CREATE_EXPRESSION_SUCCESS": {
      const existingExpression = state.loaded.find(
        (expression) => expression.id === action.response.data.expression._id,
      );

      if (existingExpression) {
        return state;
      }

      newExpression = new ExpressionRecord(
        recordMerger(action.response.data.expression, new ExpressionRecord()),
      );

      return state.updateIn(["loaded"], (arr) => arr.unshift(newExpression));
    }

    case "TRAIN_EXPRESSION_SUCCESS":
      newExpressions = state.loaded.filter(
        (expression) => expression.id !== action.response.data.expression._id,
      );

      return state.merge({
        loaded: newExpressions,
        loadedCount: state.loadedCount - 1,
        queryCount: state.queryCount - 1,
      });

    case "MOVE_EXPRESSION_SUCCESS": {
      newExpressions = state.loaded.filter(
        (expression) => expression.id !== action.response.data.expression._id,
      );

      const newState = state.merge({
        loaded: newExpressions,
      });

      newExpression = new ExpressionRecord(
        recordMerger(action.response.data.expression, new ExpressionRecord()),
      );

      return newState.updateIn(["loaded"], (arr) => arr.unshift(newExpression));
    }

    case "PATCH_EXPRESSION_SUCCESS":
    // falls through

    case "PATCH_EXPRESSION_LANGUAGE_SUCCESS": {
      // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { updatedExpressions } = keyConverter(action.response.data) as any;
      let loadedExpressions = state.get("loaded");

      // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updatedExpressions.forEach((expression: any) => {
        const loadedExpressionIndex = loadedExpressions.findIndex(
          (loadedExpression) => loadedExpression.get("id") === expression.id,
        );

        loadedExpressions = loadedExpressions.set(
          loadedExpressionIndex,
          new ExpressionRecord(expression),
        );
      });

      return state.merge({
        loaded: loadedExpressions,
      });
    }

    case "DELETE_EXPRESSION_SUCCESS":
      newExpressions = state.loaded.filter(
        (expression) => expression.id !== action.response.data.expression._id,
      );

      return state.merge({
        loaded: newExpressions,
        loadedCount: state.loadedCount - 1,
        queryCount: state.queryCount - 1,
      });

    case "DELETE_UNTRAINED_EXPRESSIONS_SUCCESS":
    case "TRAIN_EXPRESSIONS_SUCCESS":
      newExpressions = state.loaded.filter(
        (expression) => !action.ids.includes(expression.id),
      );

      return state.merge({
        loaded: newExpressions,
        loadedCount: state.loadedCount - action.ids.length,
        queryCount: state.queryCount - action.ids.length,
      });

    case "MOVE_ALL_EXPRESSIONS_SUCCESS": {
      const errors = Immutable.fromJS(
        keyConverter(action.response.data.errors),
      );

      // filter out expressions that were successfully moved (i.e. not in the error list)
      newExpressions = state.get("loaded").filter((expression) =>
        errors.find(
          // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error: any) => error.get("expressionId") === expression.get("id"),
        ),
      );

      return state.merge({
        loaded: newExpressions,
        loadedCount: errors.size,
        queryCount: errors.size,
      });
    }

    case "DELETE_EXPRESSIONS_SUCCESS": {
      const results = Immutable.fromJS(keyConverter(action.response.data)).get(
        "deletedExpressions",
      );
      // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const movedExpressionIds = results.map((result: any) => result.get("id"));

      // filter out expressions that were moved away
      newExpressions = state
        .get("loaded")
        .filter(
          (expression) => !movedExpressionIds.includes(expression.get("id")),
        );

      return state.merge({
        loaded: newExpressions,
        loadedCount: state.loadedCount - results.size,
        queryCount: state.queryCount - results.size,
      });
    }

    case "MOVE_EXPRESSIONS_SUCCESS": {
      const results = Immutable.fromJS(keyConverter(action.response.data)).get(
        "updatedExpressions",
      );
      // TODO: replace `any` with proper types - intentionally left by TS conversion initiative
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const movedExpressionIds = results.map((result: any) => result.get("id"));

      // filter out expressions that were moved away
      newExpressions = state
        .get("loaded")
        .filter(
          (expression) => !movedExpressionIds.includes(expression.get("id")),
        );

      return state.merge({
        loaded: newExpressions,
        loadedCount: state.loadedCount - results.size,
        queryCount: state.queryCount - results.size,
      });
    }

    case "REPLACE_TRAINING": {
      const loaded = Immutable.fromJS(keyConverter(action.expressions)).map(
        (expression: ExpressionShape) => new ExpressionRecord(expression),
      );

      const loadedCount = loaded.size;

      return state.merge({
        loaded,
        loadedCount,
      });
    }

    default:
      return state;
  }
};
