import { bindActionCreators } from "@reduxjs/toolkit";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { setState as setStateAction } from "actions";
import { Button } from "components/Common/Button";
import SvgIcon from "components/Common/SvgIcon";
import { introsDeleteLanguage } from "components/Shared/Pages/Responses/ResponsesActionBar/actions";
import { ClientLegacy, Language } from "resourceModels";
import { clientSelector } from "selectors/client";
import { getResources } from "selectors/resources";
import colors from "stylesheets/utilities/colors.scss";
import "./style.scss";

const blockName = "MultilingualInputLanguagesDialog";

class MultilingualInputLanguagesDialogComponent extends React.Component {
  /**
   * @param {string} code
   * @param {string} code
   * @returns {boolean}
   */
  isPrimaryLanguage(code) {
    const {
      /** @type {ClientLegacy} */
      client,
    } = this.props;

    return client.language === code;
  }

  /**
   * @param {string} code
   * @returns {boolean}
   */
  hasInvalidField(code) {
    const { invalidFields } = this.props;

    return invalidFields.includes(code);
  }

  render() {
    const {
      availableLanguages,
      /** @type {ClientLegacy} */
      client,
      closeDialog,
      deleteLanguage,
      enabledLanguages,
      languages,
      onClick,
      onLanguageClick,
      activeLanguage,
      invalidLanguageCodes,
      setState,
    } = this.props;

    return (
      <div className={blockName}>
        <div className={`${blockName}__languages`}>
          {enabledLanguages && (
            <div className={`${blockName}__languages__enabled-languages`}>
              <div className={`${blockName}__languages__title`}>
                Custom Translations
              </div>
              {enabledLanguages.map((language) => (
                // Not going to have a keyboard handler
                <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                  role="button"
                  className={classnames(
                    `${blockName}__languages__language-button--container`,
                    {
                      [`${blockName}__languages__language-button--selected`]:
                        activeLanguage === language.code,
                    },
                  )}
                  key={language.code}
                  onClick={() => {
                    if (this.isPrimaryLanguage(language.code)) {
                      onClick(language);
                      closeDialog();
                    }
                  }}
                >
                  <Button
                    text={language.englishName}
                    title={language.englishName}
                    customClassName={classnames(
                      `${blockName}__languages__language-button`,
                      {
                        [`${blockName}__languages__language-button--selected`]:
                          activeLanguage === language.code,
                        [`${blockName}__languages__language-button--available`]:
                          client.language === language.code &&
                          activeLanguage !== language.code,
                        [`${blockName}__languages__language-button--invalid`]:
                          this.hasInvalidField(language.code),
                      },
                    )}
                    icon={
                      invalidLanguageCodes.has(language.code)
                        ? "WarningYellow"
                        : ""
                    }
                    key={language.code}
                    secondIcon={(() => {
                      if (this.hasInvalidField(language.code)) {
                        return "Warning";
                      }

                      return this.isPrimaryLanguage(language.code)
                        ? "Lock"
                        : "";
                    })()}
                    fillColor={(() => {
                      if (this.hasInvalidField(language.code)) {
                        return colors.colorUIWarn;
                      }

                      return activeLanguage === language.code
                        ? colors.colorUIPrimary
                        : "";
                    })()}
                    onClick={() => {
                      if (!this.isPrimaryLanguage(language.code)) {
                        onClick(language);
                        closeDialog();
                      }
                    }}
                    size="small"
                    clear
                    leftAlignContent
                  />
                  {!this.isPrimaryLanguage(language.code) && (
                    <Button
                      customClassName={`${blockName}__languages__language-button__delete`}
                      key={`delete-${language.code}`}
                      title="Delete Language"
                      icon="CircleRemove"
                      fillColor={colors.colorUIBad}
                      onClick={() => deleteLanguage(language.code)}
                      clear
                    />
                  )}
                  {activeLanguage === language.code && (
                    <SvgIcon
                      key={`active-${language.code}`}
                      customClassName={`${blockName}__languages__language-button__active`}
                      icon="Checkmark"
                      fillColor={colors.colorUIPrimary}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className={`${blockName}__languages__available-languages`}>
            <div className={`${blockName}__languages__title`}>
              Available languages
            </div>
            {availableLanguages.map((language) => (
              // This needs to be changed to Button component
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                role="button"
                tabIndex={0}
                className={`${blockName}__languages__language-button--container`}
                key={language.code}
                onClick={() => {
                  if (onLanguageClick) {
                    onLanguageClick(language.code);
                  }

                  onClick(language);
                  closeDialog();
                }}
              >
                <Button
                  text={language.englishName}
                  title={language.englishName}
                  customClassName={classnames(
                    `${blockName}__languages__language-button`,
                    `${blockName}__languages__language-button--available`,
                  )}
                  key={`available-${language.code}`}
                  left
                  size="small"
                  clear
                />
                <SvgIcon
                  key={`add-${language.code}`}
                  customClassName={`${blockName}__languages__language-button__add`}
                  icon="CircleAdd"
                  fillColor={colors.colorUIGood}
                />
              </div>
            ))}
          </div>
        </div>
        {/*
          We subtract 1 here because languages includes the primary language,
          which does not need to be translated
        */}
        {languages.size - 1 !== client.translatedLanguages.size && (
          <p
            className={classnames(
              `${blockName}__link-block`,
              "g-form__block__description",
              `${blockName}__description`,
            )}
          >
            Add more languages in&nbsp;
            <Link
              to="/settings/languages"
              className={classnames(
                `${blockName}__link-block__link`,
                "g-form__block__description",
              )}
              onClick={() =>
                setState("MODAL", {
                  isOpen: false,
                  view: null,
                  size: null,
                })
              }
            >
              Settings → Multilingual
            </Link>
          </p>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { responses } = state;

  return {
    client: clientSelector(state),
    languages: getResources(state, "language"),
    responses,
  };
}

function mapDispatch(dispatch) {
  return bindActionCreators(
    {
      introsDeleteLanguage,
      setState: setStateAction,
    },
    dispatch,
  );
}

// eslint-disable-next-line react/static-property-placement
MultilingualInputLanguagesDialogComponent.propTypes = {
  activeLanguage: PropTypes.string,
  availableLanguages: ImmutablePropTypes.list,
  closeDialog: PropTypes.func,
  enabledLanguages: ImmutablePropTypes.list,
  onClick: PropTypes.func,
  onLanguageClick: PropTypes.func,
  deleteLanguage: PropTypes.func.isRequired,

  client: PropTypes.instanceOf(ClientLegacy).isRequired,
  languages: ImmutablePropTypes.listOf(PropTypes.instanceOf(Language))
    .isRequired,
  invalidFields: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  invalidLanguageCodes: PropTypes.instanceOf(Set).isRequired,
  setState: PropTypes.func.isRequired,
};

export const MultilingualInputLanguagesDialog = connect(
  mapState,
  mapDispatch,
)(MultilingualInputLanguagesDialogComponent);

MultilingualInputLanguagesDialog.propTypes = {
  deleteLanguage: PropTypes.func.isRequired,
};
