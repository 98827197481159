import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "slices";

export const ResponsesApi = createApi({
  reducerPath: "responsesExpressionLanguages",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Languages"],
  endpoints: (build) => ({
    getExpressionLanguages: build.query({
      query: (responseId) => ({
        method: "GET",
        url: `/responses/${responseId}/expressions/languages`,
      }),
      providesTags: ["Languages"],
    }),
  }),
});

export const { useGetExpressionLanguagesQuery } = ResponsesApi;
