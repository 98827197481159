import Immutable from "immutable";
import { createSelector, createStructuredSelector } from "reselect";

import { versionedResponsesLoadedSelector } from "components/Shared/Pages/Responses/ResponseVersions/selectors";
import {
  MESSAGING_MODALITY,
  MODALITY_DETAILS,
  type Modality,
  VOICE_MODALITY,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { isResponseABTest } from "features/ABTesting/services";
import { type BuilderABTest } from "features/ABTesting/types";
import { type State } from "reducers";
import { type MessageRecord } from "reducers/responses/messageRecords";
import { type BlockConfig } from "reducers/responses/messageRecords/types";
import { type ResponseRecord } from "reducers/responses/types";
import { selectBlockConfig } from "selectors/blocks";
import { selectClient } from "services/client/selectors";
import { type LanguageCode } from "services/language";
import { selectLanguages } from "services/language/selectors/selectLanguages";
import { selectActiveResponse } from "services/responses/selectors/selectActiveResponse";
import { type BaseKeyPath } from "services/responses/types";

import { selectActiveModality } from "./selectActiveModality";
import { selectActiveVariant } from "./selectActiveVariant";

export * from "./selectActiveModality";
export * from "./selectActiveResponse";
export * from "./selectActiveResponseById";
export * from "./selectResponseMessagesLanguages";
export * from "./selectResponsePageParams";

export const getResponseEditorActiveLanguage = createSelector(
  (state: State) => state.responsesPage,
  (responsesPage) => responsesPage.activeLanguage,
);

export const getActiveLanguageEnglishName = createSelector(
  getResponseEditorActiveLanguage,
  selectLanguages,
  (activeLanguage, allLanguages) =>
    allLanguages.find(({ code }) => code === activeLanguage)?.englishName,
);

const structuredSelector = createStructuredSelector<
  State,
  {
    activeModality: Modality;
    activeLanguage: LanguageCode;
    builderABTests: BuilderABTest[];
    variantIndex: number;
    activeResponse: ResponseRecord | undefined;
    client: ReturnType<typeof selectClient>;
  }
>({
  activeModality: selectActiveModality,
  activeLanguage: getResponseEditorActiveLanguage,
  builderABTests: (state) => state.builderABTestsState.builderABTests,
  variantIndex: selectActiveVariant,
  activeResponse: selectActiveResponse,
  client: selectClient,
});

/**
 * This function returns the key path to the active messages (i.e. the messages currently shown
 * in the response editor). This is distinct from the relative key path, which is the path within
 * the active messages to the specific (potentially nested) message in question.
 */
export const getBaseKeyPath = createSelector(
  structuredSelector,
  ({
    activeModality,
    activeLanguage,
    builderABTests,
    variantIndex,
    activeResponse,
  }): BaseKeyPath | null => {
    if (!activeResponse) {
      return null;
    }

    const responseId = activeResponse.id;

    if (
      activeModality === VOICE_MODALITY &&
      !activeResponse.get("messagesVoice").has(activeLanguage)
    ) {
      return [
        responseId,
        "messagesVoiceTranslationPreview",
        activeLanguage,
      ] as const;
    }
    // TODO: handle voice modality: SET MODALITY HERE
    // If active modality is messaging,
    // and the active language is not in the response,
    // then we are in the translation preview mode.

    if (
      activeModality === MESSAGING_MODALITY &&
      !activeResponse.get("messages").keySeq().includes(activeLanguage)
    ) {
      return [
        responseId,
        "messagesTranslationPreview",
        activeLanguage,
      ] as const;
    }

    if (
      isResponseABTest(responseId, builderABTests) &&
      activeModality === MESSAGING_MODALITY
    ) {
      /* 1 (between activeLanguage and "statements") is because we want the second message in the
       * response messages. The first message is a weighted random integer block which sets the AB
       * test variable if it hasn't already been set (i.e. rolling the dice to assign the chatter to
       * a variant). The second block is a conditional containing the actual variant content in its
       * branches, which is what we're interested in here.
       */

      return [
        responseId,
        "messages" as "messages" | "messagesVoice",
        activeLanguage,
        1,
        "statements",
        variantIndex,
        "messages",
      ] as const;
    }

    return [
      responseId,
      MODALITY_DETAILS[activeModality].messageField,
      activeLanguage,
    ] as const;
  },
);

/**
 * This function returns the messages that are currently active in the response editor. Usually,
 * this consists of all the messages for the active language. However, for AB test responses,
 * only a subset of those are shown (specifically, the active messages are the messages
 * corresponding to one variant, which live in one branch of a conditional block).
 */

export const getActiveMessages = createSelector(
  versionedResponsesLoadedSelector,
  getBaseKeyPath,
  (responsesLoaded, baseKeyPath): Immutable.List<MessageRecord> =>
    (baseKeyPath && responsesLoaded.getIn(baseKeyPath)) || Immutable.List(),
);

/**
 * Returns the number of unsupported messages for the active modality
 */
export const countUnsupportedActiveMessages = createSelector(
  [selectBlockConfig, selectActiveModality, getActiveMessages],
  (blockConfig, activeModality, messages) => {
    const count = messages.reduce((acc, message) => {
      const currentBlockConfig = blockConfig[
        message.type as keyof typeof blockConfig
      ] as BlockConfig | undefined;
      const isUnsupported =
        currentBlockConfig?.unsupportedModalities.includes(activeModality) ??
        true;

      return isUnsupported ? acc + 1 : acc;
    }, 0);

    return count;
  },
);

export function getCurrentlySortingKeyPath(state: State, containerId: string) {
  return state.responsesPage.currentlySortingKeyPath[containerId];
}

export function isCurrentlySorting(state: State) {
  const { currentlySortingKeyPath } = state.responsesPage;

  return Object.values(currentlySortingKeyPath).some((value) => value !== null);
}
