import Immutable from "immutable";
import { type DataNode } from "rc-tree/lib/interface";

import { type Breadcrumbs } from "./breadCrumbs";

interface FoldersSearchHighlightsItem {
  highlights: {
    start: number;
    end: number;
  }[];
}

export interface FoldersSearchStringMatchItem
  extends FoldersSearchHighlightsItem {
  body: string;
}

export interface FoldersSearchTagMatchItem extends FoldersSearchHighlightsItem {
  tag_id: string;
}

export interface FoldersSearchMatches {
  blocks?: Record<string, number[]>;
  content?: FoldersSearchStringMatchItem[];
  description?: FoldersSearchStringMatchItem;
  handle?: FoldersSearchStringMatchItem;
  tags?: FoldersSearchTagMatchItem[];
  title?: FoldersSearchStringMatchItem;
}

export interface FoldersSearchResultItem extends FoldersSearchMatches {
  type: string;
  parent_id: string;
  _id: string;
  breadcrumbs: [] | undefined;
  _source: {
    handle: string;
    description: string;
    descriptive_string: string;
    reserved: boolean;
    tags: string[];
    _id: string;
    created: number;
    updated: number;
    title: string;
    live: boolean;
    live_voice: boolean;
    // To account for the rest of the props that we don't use in folder search results
    [x: string]: unknown;
  };
}

export interface FolderRecordProps {
  id: string;
  parentId: string;
  // TODO - update type when Answer editor changes are in
  breadcrumbs: Breadcrumbs;
  title: string;
  created: string;
  createdBy: string;
  updated: string;
  updatedBy: string;
  description?: string;
  isLeaf: boolean;
  didLoad: boolean;
  isPendingLoad: boolean;
  key: string;
  // the name `value` (though not intuitive to read) is required for TreeSelect Dropdown.
  value: string;
  live: boolean;
  liveVoice: boolean;
  tags: string[];
  reserved: boolean;
  isDirty: boolean;
  isInvalid: boolean;
  searchMatches: FoldersSearchMatches;
}

export type CustomNode = DataNode & FolderRecordProps;

export class FolderRecord extends Immutable.Record<CustomNode>({
  id: "",
  key: "",
  value: "",
  parentId: "",
  breadcrumbs: [],
  title: "",
  created: "",
  createdBy: "",
  updated: "",
  updatedBy: "",
  children: undefined,
  description: "",
  isLeaf: false,
  didLoad: false,
  isPendingLoad: false,
  live: false,
  liveVoice: false,
  tags: [],
  reserved: false,
  isDirty: false,
  isInvalid: false,
  searchMatches: {},
}) {}
