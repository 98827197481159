import { bindActionCreators } from "@reduxjs/toolkit";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  saveMessageTemplateAction,
  updateMessageTemplateAction,
} from "actions/messageTemplates";
import { closeModalAction } from "actions/modal";
import { Button } from "components/Common/Button";
import { ButtonGroup } from "components/Common/ButtonGroup";
import { ReadOnlyCheckbox as Checkbox } from "components/Common/Checkbox";
import { DebouncedInput } from "components/Common/DebouncedInput";
import { InputRange } from "components/Common/InputRange";
import { MultilingualInput } from "components/Common/MultilingualInput";
import { ClientLegacy } from "resourceModels";
import { MessageTemplate } from "resourceModels/MessageTemplate";
import {
  getPatchOneMessageTemplatesRequest,
  getSurveyMessageTemplate,
} from "selectors/messageTemplates";
import { RequestState } from "services/requestState";

import SettingsSatisfactionModalPreview from "./SettingsSatisfactionModalPreview";
import {
  openConfirmationModalAction,
  showSettingsModalAction,
} from "./actions";

import "./style.scss";
import { clientSelector } from "selectors/client";

const BLOCK_NAME = "SettingsSatisfactionModal";
const TYPE_SCALE = "SCALE";
const TYPE_BINARY = "BINARY";

class SettingsSatisfactionModal extends React.Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    const { client, messageTemplate } = this.props;

    this.state = {
      activeLanguage: client.language,
      // Set scale type to Binary if there are 2 items; otherwise use Scale
      activeScaleType:
        messageTemplate.scaleItems.length <= 2 ? TYPE_BINARY : TYPE_SCALE,
    };

    this.handleLikertLabelChange = this.handleLikertLabelChange.bind(this);
    this.handleScaleTypeChange = this.handleScaleTypeChange.bind(this);
    this.handleScaleRangeChange = this.handleScaleRangeChange.bind(this);
    this.handleStartScaleCheckbox = this.handleStartScaleCheckbox.bind(this);
    this.handleScaleLabelLowChange = this.handleScaleLabelLowChange.bind(this);
    this.handleScaleLabelHighChange =
      this.handleScaleLabelHighChange.bind(this);
    this.handleQuestionLabelChange = this.handleQuestionLabelChange.bind(this);
    this.handleQuestionSectionToggle =
      this.handleQuestionSectionToggle.bind(this);
    this.handleCommentSectionToggle =
      this.handleCommentSectionToggle.bind(this);
    this.handleCommentQuestionLabelChange =
      this.handleCommentQuestionLabelChange.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  /**
   * Properties
   */

  /**
   * The warning modal should be shown only if the following properties change
   *
   * @returns {Boolean}
   */
  get shouldShowWarning() {
    const {
      /** @type MessageTemplate */
      messageTemplate,
    } = this.props;

    return [
      "scale",
      "startScaleAtOne",
      "hasQuestionSection",
      "hasCommentSection",
    ].some((attributeName) =>
      messageTemplate.didAttributeChange(attributeName),
    );
  }

  /**
   * Indicates if Rating Type is set to Binary (rather than Scale)
   * @returns {Boolean}
   */
  get binaryTypeSelected() {
    const { activeScaleType } = this.state;

    return activeScaleType === TYPE_BINARY;
  }

  /**
   * Hooks
   */

  /**
   * @param {String} value
   */
  handleLikertLabelChange(value) {
    this.updateMessageTemplate(["likertScaleQuestionLabel"], value);
  }

  /**
   * @param {Number} value
   */
  handleScaleTypeChange(value) {
    this.setState({ activeScaleType: value });

    switch (value) {
      case TYPE_BINARY:
        this.updateMessageTemplateAttributes({
          scale: 2,
          startScaleAtOne: true,
        });
        break;
      default:
        this.updateMessageTemplate(["startScaleAtOne"], false);
    }
  }

  /**
   * @param {Event} event
   */
  handleScaleRangeChange(event) {
    this.updateMessageTemplate(["scale"], +event.target.value);
  }

  /**
   */
  handleStartScaleCheckbox() {
    const { messageTemplate } = this.props;
    this.updateMessageTemplate(
      ["startScaleAtOne"],
      !messageTemplate.startScaleAtOne,
    );
  }

  /**
   * @param {String} value
   */
  handleScaleLabelLowChange(value) {
    const { activeLanguage } = this.state;
    const { messageTemplate } = this.props;
    const labels = messageTemplate.likertScaleLabelLow.set(
      activeLanguage,
      value,
    );
    this.updateMessageTemplate(["likertScaleLabelLow"], labels);
  }

  /**
   * @param {String} value
   */
  handleScaleLabelHighChange(value) {
    const { activeLanguage } = this.state;
    const { messageTemplate } = this.props;
    const labels = messageTemplate.likertScaleLabelHigh.set(
      activeLanguage,
      value,
    );
    this.updateMessageTemplate(["likertScaleLabelHigh"], labels);
  }

  /**
   * @param {String} value
   */
  handleQuestionLabelChange(value) {
    this.updateMessageTemplate(["yesNoQuestionLabel"], value);
  }

  /**
   */
  handleQuestionSectionToggle() {
    const { messageTemplate } = this.props;
    this.updateMessageTemplate(
      ["hasQuestionSection"],
      !messageTemplate.hasQuestionSection,
    );
  }

  /**
   */
  handleCommentSectionToggle() {
    const { messageTemplate } = this.props;
    this.updateMessageTemplate(
      ["hasCommentSection"],
      !messageTemplate.hasCommentSection,
    );
  }

  /**
   * @param {String} value
   */
  handleCommentQuestionLabelChange(value) {
    this.updateMessageTemplate(["additionalCommentsLabel"], value);
  }

  /**
   */
  handleSubmitClick() {
    const { openConfirmationModal, showSettingsModal, closeModal } = this.props;

    if (!this.shouldShowWarning) {
      this.saveMessageTemplate().then(closeModal);

      return;
    }

    openConfirmationModal({
      title: "Warning",
      subTitle: "Are you sure you want to change settings?",
      message:
        "Please note: By changing these settings the survey results will no longer be tracked within your Ada platform. The results can still be integrated within your own data warehouse.",
      shouldCloseOnMaskClick: false,
      actions: [
        {
          title: "Update settings anyway",
          buttonTint: "alert",
          onClick: () => {
            this.saveMessageTemplate();
            closeModal();
          },
        },
        {
          title: "Cancel",
          onClick() {
            showSettingsModal();
          },
        },
      ],
      onClose() {
        showSettingsModal();
      },
    });
  }

  /**
   * @param {String} languageCode
   */
  handleLanguageChange(languageCode) {
    this.setState({
      activeLanguage: languageCode,
    });
  }

  /**
   * Methods
   */

  /**
   * @param {Array.<String>} path
   * @param {*} value
   */
  updateMessageTemplate(path, value) {
    const {
      /** @type MessageTemplate */
      messageTemplate,
      updateMessageTemplate,
    } = this.props;
    const newMessageTemplate = messageTemplate.updateAttribute(path, value);

    updateMessageTemplate(newMessageTemplate.id, newMessageTemplate);
  }

  /**
   * @param {Object} newAttributeValues
   */
  updateMessageTemplateAttributes(newAttributeValues) {
    const { messageTemplate, updateMessageTemplate } = this.props;
    const newMessageTemplate =
      messageTemplate.updateAttributes(newAttributeValues);

    updateMessageTemplate(newMessageTemplate.id, newMessageTemplate);
  }

  /**
   * @returns {Promise}
   */
  saveMessageTemplate() {
    const { saveMessageTemplate, messageTemplate } = this.props;

    return saveMessageTemplate(messageTemplate.id, { shouldShowAlerts: true });
  }

  /**
   * @returns {ReactElement}
   */
  render() {
    const {
      /** @type MessageTemplate */
      messageTemplate,
      /** @type RequestState */
      request,
      client,
    } = this.props;

    const { activeLanguage, activeScaleType } = this.state;

    return (
      <div className={`${BLOCK_NAME} Modal__modal`}>
        <h5 className="Modal__title">Satisfaction Survey Settings</h5>
        <div className={`${BLOCK_NAME}__content`}>
          <div className={`${BLOCK_NAME}__settings`}>
            <section className={`${BLOCK_NAME}__settings-section`}>
              <h1 className={`${BLOCK_NAME}__settings-section-title`}>
                Rating
              </h1>

              <div className={`${BLOCK_NAME}__settings-text-input`}>
                <MultilingualInput
                  id={`${BLOCK_NAME}__likert-scale-question`}
                  client={client}
                  label="Rating Question"
                  activeLanguage={activeLanguage}
                  value={messageTemplate.likertScaleQuestionLabel}
                  placeholder="Enter text"
                  characterCounter={MessageTemplate.labelMaxLength}
                  isInvalid={messageTemplate.invalidFields.includes(
                    "likertScaleQuestionLabel",
                  )}
                  debounceTime={50}
                  onChange={this.handleLikertLabelChange}
                  onLanguageChange={this.handleLanguageChange}
                />
              </div>

              <section
                className={classnames(
                  `${BLOCK_NAME}__settings-section`,
                  `${BLOCK_NAME}__settings-section--sub`,
                  `${BLOCK_NAME}__scale`,
                )}
              >
                <h1
                  className={classnames(
                    `${BLOCK_NAME}__settings-section-title`,
                    `${BLOCK_NAME}__settings-section-title--small`,
                  )}
                >
                  Rating Type
                </h1>

                <ButtonGroup
                  className={`${BLOCK_NAME}__scale-type-picker`}
                  data={[
                    {
                      value: TYPE_SCALE,
                      label: "Scale",
                    },
                    {
                      value: TYPE_BINARY,
                      label: "Binary",
                    },
                  ]}
                  onChange={this.handleScaleTypeChange}
                  value={activeScaleType}
                />

                <div className={`${BLOCK_NAME}__scale-range-picker`}>
                  <InputRange
                    className={`${BLOCK_NAME}__scale-range-picker-input`}
                    min={2}
                    max={10}
                    step={1}
                    data-value={messageTemplate.scale}
                    value={messageTemplate.scale}
                    onChange={this.handleScaleRangeChange}
                    disabled={this.binaryTypeSelected}
                  />
                  <div
                    className={classnames(
                      `${BLOCK_NAME}__scale-range-picker-value`,
                      {
                        [`${BLOCK_NAME}__scale-range-picker-value--disabled`]:
                          this.binaryTypeSelected,
                      },
                    )}
                  >
                    {messageTemplate.scale}
                  </div>
                </div>

                <Checkbox
                  className={`${BLOCK_NAME}__scale-start-at-one-checkbox`}
                  label="Start scale at 1"
                  labelSide="right"
                  checked={messageTemplate.startScaleAtOne}
                  handleToggle={this.handleStartScaleCheckbox}
                  disabled={this.binaryTypeSelected}
                />
              </section>

              <section
                className={classnames(
                  `${BLOCK_NAME}__settings-section`,
                  `${BLOCK_NAME}__settings-section--sub`,
                )}
              >
                <h1
                  className={classnames(
                    `${BLOCK_NAME}__settings-section-title`,
                    `${BLOCK_NAME}__settings-section-title--small`,
                  )}
                >
                  Labels
                </h1>
                <div className={`${BLOCK_NAME}__settings-section-input-block`}>
                  <DebouncedInput
                    id={`${BLOCK_NAME}__scale-label-low`}
                    type="text"
                    value={messageTemplate.getLabel(
                      "likertScaleLabelLow",
                      activeLanguage,
                    )}
                    onChange={this.handleScaleLabelLowChange}
                    placeholder="Poor"
                    smallFont
                    debounceTime={50}
                    isInvalid={messageTemplate.invalidFields.includes(
                      "likertScaleLabelLow",
                    )}
                  />
                </div>
                <div className={`${BLOCK_NAME}__settings-section-input-block`}>
                  <DebouncedInput
                    id={`${BLOCK_NAME}__scale-label-high`}
                    type="text"
                    value={messageTemplate.getLabel(
                      "likertScaleLabelHigh",
                      activeLanguage,
                    )}
                    onChange={this.handleScaleLabelHighChange}
                    placeholder="Excellent"
                    smallFont
                    debounceTime={50}
                    isInvalid={messageTemplate.invalidFields.includes(
                      "likertScaleLabelHigh",
                    )}
                  />
                </div>
              </section>
            </section>

            <section className={`${BLOCK_NAME}__settings-section`}>
              <div className={`${BLOCK_NAME}__settings-section-checkbox`}>
                <Checkbox
                  className={`${BLOCK_NAME}__question-section-checkbox`}
                  checked={messageTemplate.hasQuestionSection}
                  handleToggle={this.handleQuestionSectionToggle}
                  isSwitch
                />
              </div>
              <h1 className={`${BLOCK_NAME}__settings-section-title`}>
                Resolution
              </h1>

              {messageTemplate.hasQuestionSection && (
                <div className={`${BLOCK_NAME}__settings-section-content`}>
                  <div className={`${BLOCK_NAME}__settings-text-input`}>
                    <MultilingualInput
                      id={`${BLOCK_NAME}__yes-no-question`}
                      client={client}
                      label="Resolution Question"
                      activeLanguage={activeLanguage}
                      value={messageTemplate.yesNoQuestionLabel}
                      placeholder="Enter text"
                      characterCounter={MessageTemplate.labelMaxLength}
                      isInvalid={messageTemplate.invalidFields.includes(
                        "yesNoQuestionLabel",
                      )}
                      debounceTime={50}
                      onChange={this.handleQuestionLabelChange}
                      onLanguageChange={this.handleLanguageChange}
                    />
                  </div>
                </div>
              )}
            </section>

            <section className={`${BLOCK_NAME}__settings-section`}>
              <div className={`${BLOCK_NAME}__settings-section-checkbox`}>
                <Checkbox
                  className={`${BLOCK_NAME}__comment-section-checkbox`}
                  checked={messageTemplate.hasCommentSection}
                  handleToggle={this.handleCommentSectionToggle}
                  isSwitch
                />
              </div>
              <h1 className={`${BLOCK_NAME}__settings-section-title`}>
                Comments
              </h1>

              {messageTemplate.hasCommentSection && (
                <div className={`${BLOCK_NAME}__settings-section-content`}>
                  <div className={`${BLOCK_NAME}__settings-text-input`}>
                    <MultilingualInput
                      id={`${BLOCK_NAME}__comment-question`}
                      client={client}
                      label="Message"
                      activeLanguage={activeLanguage}
                      value={messageTemplate.additionalCommentsLabel}
                      placeholder="Enter text"
                      characterCounter={MessageTemplate.labelMaxLength}
                      isInvalid={messageTemplate.invalidFields.includes(
                        "additionalCommentsLabel",
                      )}
                      debounceTime={50}
                      onChange={this.handleCommentQuestionLabelChange}
                      onLanguageChange={this.handleLanguageChange}
                    />
                  </div>
                </div>
              )}
            </section>
          </div>
          <div className={`${BLOCK_NAME}__preview`}>
            <SettingsSatisfactionModalPreview
              messageTemplate={messageTemplate}
              activeLanguageCode={activeLanguage}
            />
          </div>
        </div>
        <div className={`${BLOCK_NAME}__controls Modal__bottom`}>
          <div className={`${BLOCK_NAME}__controls-button`}>
            <Button
              onClick={this.handleSubmitClick}
              text="Confirm"
              title="Confirm changes"
              disabled={!messageTemplate.isDirty || !messageTemplate.isValid}
              isLoading={request ? request.isPending : false}
              stretched
            />
          </div>
        </div>
      </div>
    );
  }
}

SettingsSatisfactionModal.isLarge = true;

SettingsSatisfactionModal.propTypes = {
  client: PropTypes.instanceOf(ClientLegacy).isRequired,
  messageTemplate: PropTypes.instanceOf(MessageTemplate).isRequired,
  request: PropTypes.instanceOf(RequestState),
  closeModal: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  showSettingsModal: PropTypes.func.isRequired,
  updateMessageTemplate: PropTypes.func.isRequired,
  saveMessageTemplate: PropTypes.func.isRequired,
};

SettingsSatisfactionModal.defaultProps = {
  request: null,
};

/**
 * @param {Object} state
 * @returns {Object}
 */
function mapStateToProps(state) {
  return {
    client: clientSelector(state),
    messageTemplate: getSurveyMessageTemplate(state),
    request: getPatchOneMessageTemplatesRequest(state),
  };
}

/**
 * @param {Function} dispatch
 * @returns {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    closeModal: bindActionCreators(closeModalAction, dispatch),
    updateMessageTemplate: bindActionCreators(
      updateMessageTemplateAction,
      dispatch,
    ),
    saveMessageTemplate: bindActionCreators(
      saveMessageTemplateAction,
      dispatch,
    ),
    openConfirmationModal: bindActionCreators(
      openConfirmationModalAction,
      dispatch,
    ),
    showSettingsModal: bindActionCreators(showSettingsModalAction, dispatch),
  };
}

const Connector = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsSatisfactionModal);

export default Connector;
