import { adaAPI } from "services/api";

export async function getVariableValues(
  variableId: string,
  prefix: string,
  abortSignal: AbortSignal,
) {
  try {
    const res = await adaAPI.request<{ values: string[] }>({
      method: "POST",
      url: "/variables/values",
      data: {
        variable_id: variableId,
        prefix,
      },
      abortSignal,
    });

    return res.data.values;
  } catch (e) {
    return [];
  }
}
