import styled from "styled-components";

import SvgIcon from "components/Common/SvgIcon";
import { colorPrimitives, colorTokens, cssVariables, v } from "constants/css";

export const Wrapper = styled.div`
  color: ${colorPrimitives.black};
`;

export const Icon = styled(SvgIcon)`
  height: ${cssVariables.mediumSvgIconHeight.stringHeight};
  vertical-align: middle;
  margin-right: ${cssVariables.gUnit};
`;

export const ErrorList = styled.div`
  margin: 10px 0 26px;
  border: 1px solid ${colorPrimitives.slate300};
  border-radius: ${cssVariables.gUnit};
`;

export const Expression = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colorTokens.borderDefault};
  height: ${v.inputHeight};

  // this forces the browser to use the GPU for expression rendering. This fixes Safari hover issue
  transform: translateZ(0);

  &:last-child {
    border-bottom: none;
  }
`;

export const ExpressionText = styled.div`
  text-overflow: ellipsis;
  margin-left: ${v.gUnit};
  white-space: nowrap;
  overflow: hidden;
`;
