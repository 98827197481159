import styled from "styled-components";

import { colorPrimitives, v } from "constants/css";

export const Container = styled.div`
  padding: ${v.gUnitDouble};
`;

export const TitleWrapper = styled.div`
  margin-top: ${v.gUnit};
`;

export const Description = styled.p`
  font-size: ${v.smallFontSize};
  color: ${colorPrimitives.slate600};
  margin-bottom: ${v.gUnit};
  a {
    color: ${colorPrimitives.slate600};
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-right: ${v.gUnit};
  }
`;

export const InputGroupContainer = styled.div`
  display: flex;
  margin-bottom: ${v.gUnitDouble};
`;

export const Divider = styled.hr`
  height: ${v.gUnit};
  margin: ${v.gUnitTriple} -${v.gUnitDouble};
  background: ${colorPrimitives.slate200};
  border: none;
`;

export const RadioContainer = styled.div`
  margin: ${v.gUnit} 0;
`;

export const Footer = styled.div`
  margin: ${v.gUnitDouble} -${v.gUnitDouble} 0;
  padding: ${v.gUnitDouble};
  padding-bottom: 0;
  border-top: 1px solid ${colorPrimitives.slate200};
  display: flex;
  justify-content: space-between;
`;
