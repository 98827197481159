import { Link } from "react-router-dom";
import styled from "styled-components";

import { c, colorTokens, v } from "constants/css";

export const EmptyState = styled.div`
  padding: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${c.colorGrey4};
  flex-flow: column;
`;

export const EmptyHeader = styled.div`
  font-weight: ${v.heavyFontWeight};
  font-size: ${v.smallFontSize};
  margin-top: 40px;
  line-height: 20px;
  color: ${c.colorBlack};
`;

export const EmptyText = styled.div`
  font-weight: ${v.lightFontWeight};
  font-size: ${v.xSmallFontSize};
  margin-top: ${v.gUnit};
  line-height: 16px;
  color: ${c.colorBlack};
  width: 286px;
  text-align: center;
`;

export const SavedEvents = styled.p`
  width: 50%;
  font-size: ${v.xSmallFontSize};
  color: ${c.colorGrey3};
  padding: ${v.gUnit};
`;

export const SeeAllEvents = styled(Link)`
  text-decoration: none;
  width: 50%;
  text-align: end;
  font-size: ${v.xSmallFontSize};
  padding: ${v.gUnit};
`;

export const CreateNewEvent = styled.div`
  width: 100%;
  padding: ${v.gUnit};
  border-bottom: 1px solid ${c.colorGrey2};
  /* remove padding from the button */
  > :first-child {
    padding: 0;
  }
`;

export const DropDownHeaderRoot = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const EmptyLink = styled.a`
  font-size: ${v.smallFontSize};
  margin-top: ${v.gUnitDouble};
  color: ${colorTokens.actionPrimaryText};
  text-decoration: none;
`;
