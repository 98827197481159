export const CONFLUENCE_INTEGRATION = "confluence";
export const CALLBACK_URL = `${window.location.protocol}//${window.location.host}/api/knowledgebase/confluence/oauth/authorize`;

export const CONFLUENCE_PERMISSIONS = [
  "offline_access",
  "read:confluence-space.summary",
  "read:confluence-props read:confluence-content.all",
  "read:confluence-content.summary",
  "read:confluence-content.permission",
  "read:confluence-user",
  "read:confluence-groups",
];

export const SPACES_OPTIONS = [
  {
    label: "All Spaces",
    value: "all",
  },
  {
    label: "Only Specific Spaces",
    value: "allow",
  },
  {
    label: "All Spaces Except",
    value: "block",
  },
];

export const BASE_OAUTH_URL = `https://auth.atlassian.com/authorize?`;
