import { type List, type Map } from "immutable";
import React, { type ReactNode } from "react";

import { escapeRegexp, replaceVariableIDJSX } from "services/strings";

interface HighlightStringFromNeedleProps {
  highlight: string;
  description: string;
}

/**
 * Renders a highlighted string based on a needle
 */
const HighlightStringFromNeedle = (
  props: HighlightStringFromNeedleProps,
): React.ReactElement => {
  const { highlight, description } = props;
  // Split on highlight term and include term into parts, ignore case
  const parts = description.split(
    new RegExp(`(${escapeRegexp(highlight)})`, "gi"),
  );

  // map through the parts. If we find the needle, return a highlight
  // JSX element that's wrapping the needle
  return (
    <span>
      {" "}
      {parts.map((part, i) =>
        part === highlight ? (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i} className="g-highlight">
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </span>
  );
};

interface HighlightStringFromLocationsProps {
  string: string;
  locations: List<Map<string, number>>;
  variables: Map<string, unknown>;
}

/**
 * Highlight the indexes in string from locations
 * {String} string the complete string we are highlighting from
 * {List<Map>} locations [{start: number, end: number}, ...]
 *
 * What are Start and End?
 * `start` and `end` are indices in the string that indicate the beginning
 * and end of a highlighted section of the string
 *
 * {Map} variables
 * @param {HighlightStringFromLocationsProps} props
 */
const HighlightStringFromLocations = (
  props: HighlightStringFromLocationsProps,
): React.ReactElement => {
  const { string, locations, variables } = props;

  // if there's no locations, return the string
  if (locations.size < 1) {
    return <span>{string}</span>;
  }

  let res: Array<ReactNode> = [];

  // Array representation of our string
  const arrayString = string.split("");

  // add the first non-highlighted piece of text to our result array.
  // but first, replave variable templates
  res = res.concat(
    replaceVariableIDJSX(
      arrayString.slice(0, locations.getIn([0, "start"])).join(""),
      variables,
    ),
  );

  // Loop through our locations and highlight each "start" to "end"
  // by wrapping the text in the highlight span tag
  locations.forEach((startEnd, i) => {
    res = res.concat(
      <span className="g-highlight">
        {arrayString
          .slice(startEnd.get("start"), (startEnd.get("end") as number) + 1)
          .join("")}
      </span>,
    );

    // this is where we account for the strings between highlights
    // and after the last highlight.
    res = res.concat(
      replaceVariableIDJSX(
        arrayString
          .slice(
            (startEnd.get("end") as number) + 1,
            // on the last iteration, the line below will be undefined and
            // the slice will go to the end of the string
            locations.getIn([i + 1, "start"]),
          )
          .join(""),
        variables,
      ),
    );
  });

  return <span data-testid="title">{res}</span>;
};

export { HighlightStringFromNeedle, HighlightStringFromLocations };
