import { type BaseMessageRecordAttributes } from "reducers/responses/messageRecords/BaseMessageRecord";
import { type MESSAGE_RECORD_FACTORIES } from "reducers/responses/reducer";

const basAttrs: BaseMessageRecordAttributes = {};

export const createBaseMessageRecord = <
  T extends keyof typeof MESSAGE_RECORD_FACTORIES,
>(
  type: T,
) =>
  ({
    ...basAttrs,
    type,
    _type: type,
  } as const);
