// @ts-strict-ignore
import RecordClassCreator, { type ManyResourcePayload } from "services/record";

interface AgentAttributes {
  name: string;
  email: string;
  platformName: string;
}

export class Agent extends RecordClassCreator<AgentAttributes>({
  type: "agent",
  name: null,
  email: null,
  platformName: null,
}) {
  static resolveFetchManyResult(payload: {
    agents: { _id: string; _type: string; [key: string]: unknown }[];
  }): ManyResourcePayload {
    return {
      ...payload,
      data: payload.agents,
    };
  }
}
