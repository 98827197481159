import { createSelector } from "reselect";

import {
  MESSAGING_MODALITY,
  type Modality,
} from "components/Shared/Pages/Responses/ResponsesEditor/constants";
import { type State } from "types";

import { selectModality } from "./selectModality";

type SelectorResponse = (state: State) => Modality;

export const selectActiveModality: SelectorResponse = createSelector(
  selectModality,
  (modality) => modality || MESSAGING_MODALITY,
);
