import { primitives, tokens } from "@adasupport/byron";
import styled, { css } from "styled-components";

import { colorTokens, v } from "constants/css";

const InputText = styled.span`
  position: relative;
  display: block;
  width: 100%;
`;
export const Root = InputText;

export const characterCounterStyle = css`
  position: absolute;
  font-size: 10px;
  color: ${tokens.colors.text.main};
  line-height: 1;
`;

export const CharacterCounter = styled.span<{ placement: "left" | "right" }>`
  ${characterCounterStyle};

  ${(p) => (p.placement === "left" ? "left: 8px" : "right: 8px")};
  bottom: 4px;
  visibility: hidden;

  textarea:focus + & {
    visibility: visible;
  }
`;

export const TextAreaWrapper = styled.div`
  position: relative;
`;

export const SearchIcon = styled.span`
  position: absolute;
  top: 6px;
  left: 8px;
  z-index: 1;
`;

export const inputTextStyle = css`
  box-shadow: none;
  padding: 6px 8px;
  line-height: 22px;
  border: 1px solid ${tokens.colors.border.formInput};
  transition: 150ms ease;
  color: ${tokens.colors.text.main};
  font-size: 14px;
  background-color: ${tokens.colors.background.main};
  border-radius: ${primitives.borderRadius.small};
`;

export const inputTextStyleFocused = css`
  border-color: ${tokens.colors.border.formInputFocused};
  box-shadow: 0 0 0 3px ${tokens.colors.border.formInputFocusedShadow};
`;

export const inputTextStyleInvalid = css`
  border-color: ${tokens.colors.border.formInputError};
`;

export const Textarea = styled.textarea<{
  isInvalid: boolean;
  textAlign: "left" | "center" | "right";
  multiline: boolean;
  textDirection: "rtl" | "ltr";
  hideScrollbars: boolean;
  disabled: boolean;
  search?: boolean;
}>`
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: ${v.smallFontSize};
  line-height: 18px;
  padding: 6px;
  transition: 250ms ease;
  border: none;
  border-radius: 6px;
  background-color: ${colorTokens.background};
  font-family: inherit;
  resize: none;
  direction: ${(p) => p.textDirection};
  
  ${inputTextStyle};

  &:focus {
    ${inputTextStyleFocused};
  }

  ${(p) =>
    css`
      ${p.isInvalid &&
      css`
        &,
        &:focus {
          ${inputTextStyleInvalid};
        }
      `}

      ${p.disabled &&
      css`
        color: ${tokens.colors.text.muted};
        border-color: ${tokens.colors.border.default};
      `}
    `}
  
  ${(p) => css`
    text-align: ${p.textAlign};
  `}

  ${(p) => p.disabled && "cursor: not-allowed"};
  ${(p) => !p.multiline && "white-space: nowrap"};
  
  ${(p) =>
    p.hideScrollbars &&
    css`
      &::-webkit-scrollbar {
        display: none;
      }
    `}}

  &::placeholder {
    font-style: italic;
  }
  
  ${(p) =>
    p.search &&
    css`
      padding-left: 40px;
    `}
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${tokens.colors.text.main};
  margin-bottom: 6px;
`;

export const PredicitonText = styled.div`
  position: absolute;
  font-size: ${v.smallFontSize};
  color: ${tokens.colors.text.muted};
  line-height: 18px;
  top: ${v.gUnit};
  left: ${v.gUnit};
`;
