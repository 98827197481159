import { createApi } from "@reduxjs/toolkit/query/react";

import { adaAPI } from "services/api";

export interface CSATRes {
  id: string;
  survey_is_active: boolean;
  survey_type: "bot" | "agent";
  satisfaction_range: number;
  satisfaction_style: "NUMERIC" | "EMOJI" | "THUMBS";
  follow_up_feedback_is_enabled: boolean;
  resolution_is_enabled: boolean;
  open_feedback_is_enabled: boolean;
  respond_to_negative_feedback: boolean;
  respond_to_positive_feedback: boolean;
  anytime_survey_is_enabled: boolean;
  post_chat_survey_is_enabled: boolean;
  order: string[];
  required: string[];
  satisfaction_label: {
    [key: string]: string;
  } | null;
  follow_up_feedback_positive_label: {
    [key: string]: string;
  } | null;
  follow_up_feedback_negative_label: {
    [key: string]: string;
  } | null;
  resolution_label: {
    [key: string]: string;
  } | null;
  open_feedback_label: {
    [key: string]: string;
  } | null;
  email_template: {
    [key: string]: string;
  } | null;
  [key: string]: unknown;
}

export const CSATApi = createApi({
  reducerPath: "csatSettings",
  baseQuery: (props) => adaAPI.request({ ...props }),
  tagTypes: ["CSAT"],
  endpoints: (builder) => ({
    getCSAT: builder.query({
      query: (suveryType: string) => ({
        url: `csat_settings?survey_type=${suveryType}`,
      }),
      transformResponse: (response: { data: CSATRes }) => response.data,
      providesTags: ["CSAT"],
    }),
    getCSATDefaultLabels: builder.query<
      { [key: string]: { [key: string]: string } },
      void
    >({
      query: () => ({
        url: "csat_settings/default_labels",
      }),
      transformResponse: (response: {
        data: { [key: string]: { [key: string]: string } };
      }) => response.data,
      providesTags: ["CSAT"],
    }),
    updateCSAT: builder.mutation<CSATRes, Partial<CSATRes>>({
      query: (body) => ({
        url: `csat_settings?survey_type=${body.surveyType}`,
        method: "PATCH",
        data: body.data,
      }),
      invalidatesTags: ["CSAT"],
    }),
  }),
});

export const {
  useGetCSATQuery,
  useGetCSATDefaultLabelsQuery,
  useUpdateCSATMutation,
} = CSATApi;
