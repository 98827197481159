import { primitives, tokens } from "@adasupport/byron";
import styled from "styled-components";

import { colorPrimitives, cssColors, v } from "constants/css";

export { SectionTitle } from "../styles";

export const RadioButtonsBlock = styled.div`
  margin-top: ${v.gUnit};
`;

export const CheckboxesBlock = styled.div`
  margin-top: ${v.gUnitHalf};
  margin-left: ${v.gUnitDouble};
`;

export const RadioButtonRow = styled.div`
  margin-bottom: ${v.gUnit};
`;

export const CheckboxRow = styled.div`
  align-items: center;
  margin-top: ${primitives.spacing.space1};
  display: flex;
`;

export const CheckboxLabel = styled.span<{ isDisabled: boolean }>`
  margin-left: ${primitives.spacing.space2};
  color: ${({ isDisabled }) =>
    isDisabled ? tokens.colors.text.muted : tokens.colors.text.main};
`;

export const Divider = styled.hr`
  height: ${v.gUnit};
  background: ${cssColors.colorGrey1};
  margin: ${v.gUnitTriple} (-${v.gUnitDouble});
  border: none;
`;

export const OptionalLabelContainer = styled.div`
  span {
    margin-left: ${v.space1};
    color: ${colorPrimitives.slate600};
  }
`;
