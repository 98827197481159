import { type RuleDefinition } from "./dsl";
import { isRuleConditionUnary, isRuleGroup } from "./helpers";

export const ruleHasInvalidFields = (rule: RuleDefinition) => {
  if (isRuleGroup(rule)) {
    return rule.args.some(ruleHasInvalidFields);
  }

  const [variable, value] = rule.args;

  return (
    !variable.id ||
    (!isRuleConditionUnary(rule) && (value === undefined || value === ""))
  );
};
