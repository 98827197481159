import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { closeModalAction, openModalAction } from "actions/modal";
import { type Cls } from "reducers/smartsearch/types";
import {
  type ConfluenceKBIntegration,
  type PinterestKBIntegration,
  type SalesforceKBIntegration,
  type ZendeskKbIntegration,
  useConnectLegacyIntegrationMutation,
  useDisconnectLegacyIntegrationMutation,
  useGetLegacyIntegrationsQuery,
  useLazySyncLegacyIntegrationsQuery,
} from "slices/legacyIntegrations/legacyIntegrationsApi";

const CLS_NAME: {
  [key: string]: string;
} = {
  KBIntegrationZendesk: "Zendesk",
  KBIntegrationSalesforce: "Salesforce",
  KBIntegrationConfluence: "Confluence",
  KBIntegrationPinterest: "Pinterest",
};

interface ErrorType {
  data: {
    message: string;
  };
}

export const useSmartSearchIntegration = (integrationCls: Cls) => {
  const dispatch = useDispatch();
  const [integrationData, setIntegrationData] = useState<
    | ZendeskKbIntegration
    | SalesforceKBIntegration
    | ConfluenceKBIntegration
    | PinterestKBIntegration
    | undefined
  >(undefined);
  const [integrationPosition, setIntegrationPosition] = useState(-1);
  const [integrationError, setIntegrationError] = useState("");
  const { data: legacyIntegrations, error: fetchError } =
    useGetLegacyIntegrationsQuery({});
  const [triggerSyncLegacyIntegrations] = useLazySyncLegacyIntegrationsQuery();
  const [
    connectLegacyIntegration,
    { error: connectionError, isLoading: isConnecting },
  ] = useConnectLegacyIntegrationMutation();
  const [disconnectLegacyIntegration] =
    useDisconnectLegacyIntegrationMutation();

  useEffect(() => {
    if (fetchError) {
      setIntegrationError(
        (fetchError as ErrorType).data.message ||
          "An unexpected error occurred.",
      );
    }

    if (connectionError) {
      setIntegrationError(
        (connectionError as ErrorType).data.message ||
          "An unexpected error occurred.",
      );
    }

    if (legacyIntegrations && legacyIntegrations.integrations.length > 0) {
      const { integrations } = legacyIntegrations;
      const integrationIndex = integrations.findIndex(
        // eslint-disable-next-line no-underscore-dangle
        (val) => val._cls === integrationCls,
      );

      if (integrationIndex > -1) {
        setIntegrationPosition(integrationIndex);
        const data = integrations[integrationIndex];
        setIntegrationData(data);
      }
    }
  }, [connectionError, fetchError, integrationCls, legacyIntegrations]);

  const handleSave = async (
    integration: "zendesk" | "salesforce" | "confluence" | "pinterest",
    payload: Record<string, unknown>,
  ) => {
    await connectLegacyIntegration({
      integration,
      payload,
    })
      .unwrap()
      .then(() => {
        triggerSyncLegacyIntegrations({ force: true });
        dispatch(closeModalAction());
        dispatch(openModalAction("LEGACY_INTEGRATION_SUCCESS", {}));
      });
  };

  const handleDisconnect = () => {
    const message =
      "Are you sure? Once disconnected, all articles will be removed from the Knowledge page and your bot will no longer be able to answer inquiries using this content.";
    dispatch(
      openModalAction("MODAL_WARNING", {
        title: `Disconnect ${CLS_NAME[integrationCls]}`,
        message,
        actions: [
          {
            title: "Disconnect",
            buttonTint: "alert",
            onClick: () => {
              disconnectLegacyIntegration({
                integrationIndex: integrationPosition,
              });
              dispatch(closeModalAction());
            },
          },
          {
            title: "Cancel",
            onClick: () => dispatch(closeModalAction()),
          },
        ],
      }),
    );
  };

  return {
    integrationData,
    integrationPosition,
    handleSave,
    handleDisconnect,
    integrationError,
    isConnecting,
  };
};
