import { primitives } from "@adasupport/byron";
import memoize from "lodash.memoize";

import { uuid } from "services/generate-uuid";
import { type WebAction } from "services/webActions";
import { getWebActionInputNames } from "services/webActionsV2";

import { createBaseMessageRecord } from "./base";

export type WebActionMessageRecord = ReturnType<
  typeof createWebActionMessageRecord
>;

export const createWebActionMessageRecord = ({
  id = uuid(),
  actionId = "",
  inputs = [],
  outputs = [],
}: {
  actionId?: string;
  id?: string;
  inputs?: Array<{
    inputName: string;
    value: string;
  }>;
  outputs?: Array<{
    outputName: string;
    variableId: string | null;
  }>;
}) =>
  ({
    ...createBaseMessageRecord("web_action_block"),
    id,
    actionId,
    inputs,
    outputs,

    invalidFields: ((fields) => {
      // Inputs must have a value
      for (const input of inputs) {
        if (!input.value) fields.add(`inputs.${input.inputName}.value`);
      }

      return fields;
    })(new Set<string>()),

    merge(attributes: Record<string, unknown>) {
      return createWebActionMessageRecord({
        ...this,
        ...attributes,
      });
    },
  } as const);

export const createWebActionMessageRecordFromWebAction = memoize(
  (webAction: WebAction) =>
    createWebActionMessageRecord({
      actionId: webAction._id,
      inputs: getWebActionInputNames(webAction).map((inputName) => ({
        inputName,
        value: "",
      })),
      outputs: webAction.outputs.map((output) => ({
        outputName: output.name || output.key,
        variableId: null,
      })),
    }),
);

export const getWebActionMessageRecordBlockConfig = ({
  webAction,
}: {
  webAction: WebAction;
}) =>
  ({
    blockTitle: webAction.name,
    designSystemIconName: "Lightning",
    iconColor: primitives.palette.gold600,
    iconBackgroundColor: primitives.palette.gold100,
  } as const);
