/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import React from "react";

import { InfoTooltip } from "components/Common/InfoTooltip";
import "./style.scss";

// TODO: replace any with proper types - intentionally left by TS conversion initiative
interface ReadOnlyInputProps {
  customClassName?: string;
  className?: string;
  idForLabel?: string;
  value?: string;
  placeholder?: string;
}

interface ReadOnlyInputState {
  tooltipOpen: boolean;
}

class ReadOnlyInput extends React.Component<
  ReadOnlyInputProps,
  ReadOnlyInputState
> {
  input: any;
  constructor(props: any) {
    super(props);
    this.copyValue = this.copyValue.bind(this);
    this.onClickCopyButton = this.onClickCopyButton.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  onClickCopyButton() {
    this.copyValue();
    this.setState(
      {
        tooltipOpen: true,
      },
      () => setTimeout(() => this.setState({ tooltipOpen: false }), 1000),
    );
  }

  /**
   */
  copyValue() {
    this.input.select();
    document.execCommand("copy");
  }

  render() {
    const {
      customClassName = "",
      className = "",
      idForLabel,
      value,
      placeholder,
    } = this.props;

    const { tooltipOpen } = this.state;

    return (
      <div
        className={classNames("ReadOnlyInput", {
          [customClassName]: customClassName,
        })}
      >
        <input
          className={classNames("g-input ReadOnlyInput__input", {
            [className]: className,
          })}
          id={idForLabel}
          value={value}
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          spellCheck={false}
          onClick={() => this.input.select()}
          ref={(input) => {
            this.input = input;
          }}
          readOnly
        />
        {/* Not going to have a keyboard handler */}
        <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
          role="button"
          className="ReadOnlyInput__search-icon-container"
          onClick={this.onClickCopyButton}
        >
          <InfoTooltip
            blurb="Copied"
            iconDefault="Copy"
            iconClicked="Copy"
            absolute
            opened={tooltipOpen}
            customClassName="Tooltip--copied"
          />
        </div>
      </div>
    );
  }
}

export default ReadOnlyInput;
