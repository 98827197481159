import styled, { css } from "styled-components";

import { c, colorPrimitives, v } from "constants/css";

export const ContentFrame = styled.div`
  padding: ${v.gUnitTriple};
`;

export const Title = styled.label`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${v.gUnit};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${c.colorGrey3Active};
  border-bottom: 1px solid ${c.colorGrey2};
  height: ${v.gUnitQuadruple};
  box-sizing: content-box;
  margin: 0;
`;

export const ButtonArea = styled.div`
  border-top: 1px solid ${c.colorGrey2};
  padding: ${v.gUnitDouble};
`;

interface FullWidthButtonProps {
  textColor?: string;
  fontWeight?: number;
  onClick?(): void;
  children?: React.ReactNode;
  last?: boolean;
  disabled?: boolean;
}

const roundedBottom = css`
  border-bottom-right-radius: ${v.gUnit};
  border-bottom-left-radius: ${v.gUnit};
`;

const disabledStyle = css`
  cursor: default;
  color: ${colorPrimitives.slate300};
`;

export const FullWidthButton = styled.button<FullWidthButtonProps>`
  width: 100%;
  padding: ${v.gUnit};
  text-align: center;
  color: ${(props) => props.textColor || "black"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  background-color: white;
  border: none;
  box-shadow: inset 0px 1px 0px ${c.colorGrey2};
  cursor: pointer;
  font-size: ${v.gUnitDouble};
  height: 47px;
  ${(props) => props.last && roundedBottom}
  ${(props) => props.disabled && disabledStyle}
`;
