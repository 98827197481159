import Immutable from "immutable";

import RecordClassCreator from "services/record";

import { type AnalyticsListItemValue } from "./helpers";

export interface AnalyticsListItemAttributes {
  dataType: string | null;
  valueType: string;
  value?: unknown;
  meta?: Immutable.Map<string, unknown>;
  values: Immutable.List<AnalyticsListItemValue>;
}

export class AnalyticsListItem extends RecordClassCreator<AnalyticsListItemAttributes>(
  {
    type: "analyticsListItem",
    dataType: null,
    value: null,
    valueType: "number",
    meta: Immutable.Map(),
    values: Immutable.List(),
  },
) {}
