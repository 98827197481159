import { type LanguageCode } from "services/language";

/** Defines which keys and what type of values we store in local storage */
type StorageValueTypeByKey = Partial<{
  // example_key: string;
  // another_example_key: number;

  web_action_api_test_values: {
    [key: string]: {
      variable?: Record<string, string>;
      inferred_variable?: Record<string, string>;
    };
  };
  tabular_chart: {
    [key: string]: {
      pageSize: number;
      page: number;
    };
  };
  last_viewed_whats_new: string;
  onboarding_quick_questions: { text: string }[] | undefined;
  did_onboard: boolean;
  gen_topics_v2_preview: boolean;
  onboarding_meet_agent_viewed: boolean;
  email_footer_language: LanguageCode;
  csrf: string;
}>;

export const browserStorage = {
  store: <K extends keyof StorageValueTypeByKey>(
    key: K,
    value: StorageValueTypeByKey[K],
  ) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(error);
    }
  },

  retrieve: <K extends keyof StorageValueTypeByKey>(
    key: K,
  ): StorageValueTypeByKey[K] => {
    try {
      const rawValue = localStorage.getItem(key);

      return rawValue ? JSON.parse(rawValue) : undefined;
    } catch (error) {
      console.error(error);

      return undefined;
    }
  },

  remove: <K extends keyof StorageValueTypeByKey>(key: K) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  },
};
