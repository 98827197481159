/**
 * Inspired by getScrollParent in the InfoTooltip component
 */
function getBoundingParent(element: HTMLElement | null): HTMLElement | null {
  if (!(element instanceof HTMLElement)) {
    return null;
  }

  if (window.getComputedStyle(element).overflowY !== "visible") {
    return element;
  }

  return getBoundingParent(element.parentElement) || document.body;
}

const MAX_LIST_HEIGHT = 400;

/**
 * The absolute-positioned dropdown will be clipped by the element found by getBoundingParent
 * (roughly, the first element with overflow-y set to something other than visible).
 *
 * Rather than trying to find a way to escape (from online reading, this seems like a very
 * difficult problem), we instead work with the space we have and use the largest amount of
 * space available (above or below).
 *
 * It is a limitation of this component that if the bounding parent has insufficient clearance
 * both above and below, then the dropdown will get clipped. This is generally unlikely to happen
 * since the bounding element is usually more or less the whole page (which scrolls with overflow).
 */
export function shouldOpenUp(trigger: HTMLElement | null): boolean {
  if (!trigger) {
    return false;
  }

  const boundingParent = getBoundingParent(trigger);

  if (!boundingParent) {
    return false; // arbitrary default
  }

  const boundingParentBounds = boundingParent.getBoundingClientRect();
  const triggerBounds = trigger.getBoundingClientRect();

  // Max and min are to handle when bounding parent is partially scrolled off screen
  const topClearance =
    triggerBounds.top - Math.max(boundingParentBounds.top, 0);
  const bottomClearance =
    Math.min(boundingParentBounds.bottom, document.body.clientHeight) -
    triggerBounds.bottom;

  // If there's enough space to fit the whole dropdown going down, then do that
  if (bottomClearance >= MAX_LIST_HEIGHT) {
    return false;
  }

  // Otherwise, go up or down depending on which direction has more space
  return topClearance > bottomClearance;
}

export function getListHeight(trigger: HTMLElement | null): number {
  if (!trigger) {
    return MAX_LIST_HEIGHT; // if we don't know what to do, just default to max
  }

  const boundingParent = getBoundingParent(trigger);

  if (!boundingParent) {
    return MAX_LIST_HEIGHT;
  }

  const boundingParentBounds = boundingParent.getBoundingClientRect();
  const triggerBounds = trigger.getBoundingClientRect();

  const topClearance = triggerBounds.top - boundingParentBounds.top;
  const bottomClearance = boundingParentBounds.bottom - triggerBounds.bottom;

  const clearance = Math.max(topClearance, bottomClearance);

  return Math.min(clearance, MAX_LIST_HEIGHT);
}

export function getHeight(node: HTMLElement | null) {
  if (!node) {
    return 0;
  }

  return node.getBoundingClientRect().height;
}

/**
 * This function is useful for determining if there's enough space for a dropdown that's
 * right-aligned with its trigger.
 */
export function getLeftClearance(trigger: HTMLElement | null): number {
  if (!trigger) {
    return 0; // if we don't know what to do, arbitrarily default to 0
  }

  const boundingParent = getBoundingParent(trigger);

  if (!boundingParent) {
    return 0;
  }

  const boundingParentBounds = boundingParent.getBoundingClientRect();
  const triggerBounds = trigger.getBoundingClientRect();

  return triggerBounds.right - boundingParentBounds.left;
}
