import { type WebAction } from "services/webActions";

const getInputNamesFromString = (str: string) =>
  str
    ? [...str.matchAll(/\{\{(\w+)}}/g)].map((match) => match[1] as string)
    : [];

export const getWebActionInputNames = (webAction: WebAction) => [
  ...getInputNamesFromString(webAction.url),
  ...getInputNamesFromString(webAction.request_body),
  ...webAction.headers.flatMap((h) => getInputNamesFromString(h.value)),
];
