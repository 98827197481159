import { type LDFlagSet } from "launchdarkly-js-client-sdk";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { type State } from "types";

export const setFlagsAction = (flags: LDFlagSet) => ({
  type: "SET_FLAGS",
  payload: flags,
});

export const flagsReducer = (
  state: LDFlagSet = {},
  action: ReturnType<typeof setFlagsAction>,
) => {
  switch (action.type) {
    case "SET_FLAGS":
      return action.payload;
    default:
      return state;
  }
};

export const selectFlags = (state: State) => state.flags;

/** A simple component to be used within LDProvider (in RootContainer) to update flags in redux state. */
export const FlagsUpdater = (): null => {
  const flags = useFlags();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFlagsAction(flags));
  }, [dispatch, flags]);

  return null;
};
