import styled from "styled-components";

import { colorPrimitives, colorTokens, v } from "constants/css";

export const Container = styled.div`
  padding: ${v.gUnitDouble};
`;

export const Description = styled.p`
  font-size: ${v.smallFontSize};
  color: ${colorPrimitives.slate600};
  margin-bottom: ${v.gUnitDouble};
`;

export const InputWrapper = styled.div`
  padding-bottom: ${v.gUnitDouble};
`;

export const Divider = styled.hr`
  height: ${v.gUnit};
  margin: ${v.gUnitTriple} -${v.gUnitDouble};
  background: ${colorPrimitives.slate200};
  border: none;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${v.gUnitDouble};
`;

export const CheckboxText = styled.div`
  margin-left: ${v.gUnit};
`;

export const CheckboxLabel = styled.div`
  font-weight: ${v.heavyFontWeight};
  font-size: ${v.smallFontSize};
`;

export const CheckboxDescription = styled.p`
  font-size: ${v.xSmallFontSize};
  color: ${colorTokens.textMuted};
`;

export const VariableWrapper = styled.div`
  width: 50%;
  margin-left: ${v.gUnitQuadruple};
  button span {
    padding-left: ${v.gUnit};
  }
  button div {
    background: transparent;
  }
`;

export const Footer = styled.div`
  margin: ${v.gUnitDouble} -${v.gUnitDouble} 0;
  padding: ${v.gUnitDouble};
  padding-bottom: 0;
  border-top: 1px solid ${colorPrimitives.slate200};
  display: flex;
  justify-content: space-between;
`;
