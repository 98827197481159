import styled from "styled-components";

import { Banner } from "components/Common/Banner";
import { InputLabel } from "components/Common/InputLabel";
import { colorPrimitives, cssColors } from "constants/css";

export const ModalInput = styled(InputLabel)`
  width: 100%;
`;

export const ErrorBanner = styled(Banner)`
  svg {
    margin-bottom: auto;
    color: #b91c1c;
  }
`;

export const ErrorMessage = styled.div`
  color: ${cssColors.colorTextGrey};
  a {
    color: ${colorPrimitives.blue300};
    text-decoration: underline;
    font-weight: bold;
  }
`;
