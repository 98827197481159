import Immutable from "immutable";

import {
  BUILDER_AB_TESTS_VARIABLE_SCOPE,
  GLOBAL_VARIABLE_SCOPE,
  LOCAL_VARIABLE_SCOPE,
  OAUTH_VARIABLE_SCOPE,
  SCOPES_AVAILABLE_IN_ALL_ANSWERS,
  SENSITIVE_VARIABLE_SCOPE,
} from "constants/variables";
import { trackException } from "services/errorTracker";
import { keyConverter } from "services/key-converter";
import { mongoObjectId } from "services/objectid";
import { VariableRecord } from "services/variables";

export { VariableRecord } from "services/variables";

export const ReferenceCounts = Immutable.Record({
  saved: 0,
  unsaved: 0,
});

/**
 * @param {VariableRecord} variable
 * @param {string} responseId
 * @param {Number} additionalReferences
 * @returns {object}
 */
export function addResponseId(variable, responseId, additionalReferences = 1) {
  if (!variable.get("responseReferenceCounts").has(responseId)) {
    return variable.setIn(
      ["responseReferenceCounts", responseId],
      new ReferenceCounts({
        unsaved: additionalReferences,
      }),
    );
  }

  return variable.updateIn(
    ["responseReferenceCounts", responseId, "unsaved"],
    (count) => count + additionalReferences,
  );
}

/**
 * @param {VariableRecord} variable
 * @param {string} responseId
 * @returns {object}
 */
export function deleteResponseId(variable, responseId) {
  if (!variable.get("responseReferenceCounts").has(responseId)) {
    return variable;
  }

  return variable.updateIn(
    ["responseReferenceCounts", responseId, "unsaved"],
    (count) => count - 1,
  );
}

/**
 * @param {VariableRecord} variable
 * @param {string} responseId
 * @returns {boolean}
 */
export function isDirty(variable, responseId) {
  if (!variable.responseReferenceCounts.has(responseId)) {
    return false;
  }

  return (
    variable.getIn(["responseReferenceCounts", responseId, "unsaved"]) !== 0 ||
    variable.get("modified")
  );
}

/**
 * @param {unknown} variable
 * @returns {boolean}
 */
export function isSaved(variable) {
  if (
    ![
      LOCAL_VARIABLE_SCOPE,
      GLOBAL_VARIABLE_SCOPE,
      OAUTH_VARIABLE_SCOPE,
      SENSITIVE_VARIABLE_SCOPE,
      BUILDER_AB_TESTS_VARIABLE_SCOPE,
    ].includes(variable.scope)
  ) {
    return true;
  }

  return variable.responseReferenceCounts
    .map((counts) => counts.saved)
    .some((v) => v !== 0);
}

/**
 * Checks if this variable is valid
 * @param {VariableRecord} variable
 * @returns {Boolean}
 */
export const isValid = (variable) =>
  ["name", "scope"].map((property) => variable.get(property)).every(Boolean);

export const hasInvalidVariables = (variables, responseId) => {
  const variablesToCheck = variables
    .map((value, key) => {
      if (key === LOCAL_VARIABLE_SCOPE && value.has(responseId)) {
        return value.get(responseId);
      }

      return value;
    })
    .valueSeq()
    .flatten();

  return variablesToCheck.some((variable) => !variable.get("isValid"));
};

/**
 * @param {object} variable
 * @param {string} responseId
 * @returns {Immutable.List}
 */
export function constructResponseReferences(variable, responseId) {
  try {
    return variable.responseReferenceCounts.reduce(
      (reduction, counts, respId) => {
        let count = counts.saved;

        if (respId === responseId) {
          count += counts.unsaved;
        }

        return reduction.concat(Array(count).fill(respId));
      },
      Immutable.List(),
    );
  } catch (e) {
    trackException(e);

    return variable.responseReferences;
  }
}

/**
 * @param {unknown} stateVariable
 * @param {unknown} variableData
 * @param {String} responseId
 * @returns {unknown}
 */
export function updateFromServer(stateVariable, variableData, responseId) {
  /*
    This is called on successful create,update, or delete.
    Updates the id and counts
  */
  let variable = stateVariable;
  const savedCounts = variableData
    ? Immutable.List(variableData.response_references).countBy((v) => v)
    : null;

  variable = variable
    .merge({
      id: variableData ? variableData._id : variable.id,
      modified: false,
    })
    .mergeIn(["responseReferenceCounts", responseId], {
      saved: savedCounts ? savedCounts.get(responseId) : 0,
      unsaved: 0,
    });

  return variable;
}

export const createVariableRecord = (variable, saved) => {
  /* variable: variable object from server (saved = true) OR created by user */
  const keyConvertedVariable = keyConverter(variable);
  // isActive: Active on the backend
  const responseReferences = Immutable.List(
    keyConvertedVariable.responseReferences || [],
  );

  let newVariableRecord = new VariableRecord(keyConvertedVariable).merge({
    responseReferences,
    modified: !saved,
  });

  if (saved) {
    const savedCounts = responseReferences.countBy((v) => v);
    newVariableRecord = newVariableRecord.merge({
      responseReferenceCounts: savedCounts.map(
        (savedCount) => new ReferenceCounts({ saved: savedCount }),
      ),
    });
  } else if (newVariableRecord.responseId) {
    /* this requires globals to have response_id set,
      which seems odd since we remove it later
    */
    newVariableRecord = addResponseId(
      newVariableRecord,
      newVariableRecord.responseId,
    );
  }

  if (SCOPES_AVAILABLE_IN_ALL_ANSWERS.includes(newVariableRecord.scope)) {
    newVariableRecord = newVariableRecord.set("responseId", null);
  }

  if (newVariableRecord.get("id") === null) {
    newVariableRecord = newVariableRecord.merge({ id: mongoObjectId() });
  }

  return newVariableRecord;
};

export const mapValidationToType = {
  string: "string",
  phone_number: "string",
  email: "string",
  pnr: "string",
  number: "long",
  boolean: "bool",
};

export const mapTypeToValidation = {
  string: "string",
  long: "number",
  bool: "boolean",
};

/**
 * Check if there are unsaved variables related to this response
 * @param {Immutable.Map} variables
 * @param {Object} response
 * @returns {boolean}
 */
export function hasUnsavedVariables(variables, response) {
  const globals = variables.get(GLOBAL_VARIABLE_SCOPE);

  if (
    globals.size &&
    globals.find((variable) => isDirty(variable, response.id))
  ) {
    return true;
  }

  const locals = variables.getIn([LOCAL_VARIABLE_SCOPE, response.id]);

  if (locals && locals.find((variable) => variable.get("modified"))) {
    return true;
  }

  const sensitives = variables.get(SENSITIVE_VARIABLE_SCOPE);

  if (
    sensitives &&
    sensitives.find((variable) => isDirty(variable, response.id))
  ) {
    return true;
  }

  return false;
}
