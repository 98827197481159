import classnames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logoutUser } from "actions";
import { ModalActionButton } from "components/Common/ModalActionButton";

import "./style.scss";

// This modal is a bit different from other modals - it is displayed as a component directly in
// Root.jsx, rather than being a view that is set for the <Modal /> component. This allows it to
// be displayed on top of another modal.

const BLOCK_NAME = "SessionTimeoutModal";

export const SessionTimeoutModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    state.modalSessionTimeout.get("isOpen"),
  );

  useEffect(() => {
    // Unfocus selected element (e.g. input field)
    if (isOpen) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [isOpen]);

  return (
    <div
      id="modal"
      className={classnames("Modal", "Modal--small", {
        "Modal--active": isOpen,
      })}
    >
      <div className="Modal__mask" />
      <div
        className={classnames("Modal__container", "Modal__container--small")}
      >
        <div className="Modal__inner-container">
          <div className={`${BLOCK_NAME} Modal_modal`}>
            <h5 className="Modal__title Modal__title--small">
              Session Expired
            </h5>
            <div className="Modal__content Modal__content--no-top-padding">
              <section className="Modal__section SessionTimeoutModal__details">
                <p>
                  Looks like you&apos;ve been gone for more than 24 hours. To
                  keep your bot safe, we&apos;ve logged you out.
                </p>
              </section>
            </div>

            <ModalActionButton
              onClick={() => dispatch(logoutUser())}
              text="Log in"
              intent="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
