import { type CSATSatisfactionStyle } from "services/csat";
import { type VariableLegacy as Variable } from "services/variables";

export enum PredicateValueType {
  ALL = "all",
  ANY = "any",
}
export interface FilterResponse {
  name: string;
  operator: string;
  value: string;
  id?: string;
  type: string;
}

export interface Filter {
  type: string;
  operator: string;
  value: string;
  variable?: Partial<Variable>;
  placeholder?: string;
}

export interface ConversationsTopicResponse {
  _id: string;
  title: string;
  description: string;
  keywords: string[];
  exclude_keywords: string[];
  created: number;
  updated: number;
  last_changed_by_user: string;
  client_id: string;
  filters: FilterResponse[];
  filters_if_any_of?: boolean;
}

export interface ConversationsTopic {
  id: string;
  name: string;
  description: string;
  filters: Filter[];
  logicalConnective: PredicateValueType;
  keywords: string[];
  excludeKeywords?: string[];
  lastUpdatedBy: string;
  lastUpdated: Date;
  created: Date;
}

export interface ConversationsTopicCsatCommentResponse {
  chatter_id: string;
  chatter_name: string;
  csat_comment: string;
  csat_score: number;
  csat_style: CSATSatisfactionStyle;
  conversation_id: string;
  created: number;
}

export interface ConversationsTopicCsatComment {
  chatterId: string;
  chatterName: string;
  comment: string;
  score: number;
  style: CSATSatisfactionStyle;
  conversationId: string;
  date: number;
}

export interface ConversationsTopicUnansweredQuestionResponse {
  expression_id: string;
  body: string;
  conversation_id: string;
  chatter_id: string;
  updated: number;
  prediction_ids: string[];
}

export interface ConversationsTopicUnansweredQuestion {
  id: string;
  question: string;
  conversationId: string;
  chatterId: string;
  date: number;
  predictionIds: string[];
}

export interface LoadableDataAttribute<T> {
  loading: boolean;
  loaded: boolean;
  page: number;
  data: T[];
}

export interface ConversationsTopicsState {
  loading: boolean;
  loaded: boolean;
  conversationsTopics: ConversationsTopic[];
  draftConversationsTopic: Partial<ConversationsTopic>;
  isLoadingConversationsTopic: boolean;
  activeConversationsTopic: ConversationsTopic | null;
  conversationsTopicCsatComments: LoadableDataAttribute<ConversationsTopicCsatComment>;
  conversationsTopicUnansweredQuestions: LoadableDataAttribute<ConversationsTopicUnansweredQuestion>;
}
