import { createAlert } from "actions/alerts";
import { type ThunkAction } from "actions/types";
import { type MessageTemplate } from "resourceModels/MessageTemplate";

export function updateMessageTemplateAction(
  id: string,
  newMessageTemplate: MessageTemplate,
) {
  return {
    type: "UPDATE_MESSAGE_TEMPLATE_ATTRIBUTES",
    id,
    newResourceRecord: newMessageTemplate,
  };
}

interface SaveMessageTemplateActionOptions {
  shouldShowAlerts?: boolean;
}

export function saveMessageTemplateAction(
  id: string,
  options: SaveMessageTemplateActionOptions = {},
): ThunkAction {
  return (dispatch, getState) => {
    const messageTemplateRecord = getState().resources.messageTemplate.getIn([
      "byId",
      id,
    ]);

    return new Promise<void>((resolve, reject) =>
      // eslint-disable-next-line no-promise-executor-return
      dispatch({
        // TODO BUIL-690: deprecate CALL_API (use adaAPI directly instead)
        CALL_API: {
          method: "patch",
          endpoint: `/message_templates/${id}`,
          payload: messageTemplateRecord.serialize(),
          types: [
            "SAVE_ONE_MESSAGE_TEMPLATE_REQUEST",
            "SAVE_ONE_MESSAGE_TEMPLATE_SUCCESS",
            "SAVE_ONE_MESSAGE_TEMPLATE_FAILURE",
          ],
          dispatchCallbacks: [
            {
              request() {
                if (options.shouldShowAlerts) {
                  dispatch(
                    createAlert({
                      message:
                        "Satisfaction survey settings have been updated.",
                      alertType: "success",
                    }),
                  );
                }

                resolve();
              },
              fireOnStatus: "success",
            },
            {
              request() {
                if (options.shouldShowAlerts) {
                  dispatch(
                    createAlert({
                      message: "Something went wrong",
                      alertType: "error",
                    }),
                  );
                }

                reject();
              },
              fireOnStatus: "error",
            },
          ],
        },
      }),
    );
  };
}
