import React, { type ReactNode } from "react";

import { Title } from "components/Common/Title";

import { VSpace1 } from "../Space";

import * as S from "./style";

export interface PageHeaderProps {
  title: ReactNode;
  breadCrumbs?: ReactNode;
  description?: ReactNode;
  descriptionWidth?: number;
  learnMoreLink?: string;
  actionItems?: ReactNode;
  children?: ReactNode;
}

export const PageHeader = (props: PageHeaderProps) => {
  const {
    title = null,
    description = null,
    descriptionWidth,
    actionItems = null,
    learnMoreLink = "",
    children,
    breadCrumbs = null,
  } = props;

  return (
    <S.Root>
      <VSpace1 />
      {breadCrumbs && <S.BreadCrumbs>{breadCrumbs}</S.BreadCrumbs>}
      <S.HeaderContainer>
        <S.Header>
          <div>
            <VSpace1 />
            <Title headingLevel="h1">{title}</Title>
          </div>
          <S.Description width={descriptionWidth}>
            {description}{" "}
            {learnMoreLink && (
              <S.Link href={learnMoreLink} target="_blank" rel="noreferrer">
                Learn more
              </S.Link>
            )}
          </S.Description>
        </S.Header>
        <S.ActionItems>{actionItems}</S.ActionItems>
      </S.HeaderContainer>
      {children}
    </S.Root>
  );
};
