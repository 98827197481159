import { createSelector } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";

import { adaAPI } from "services/api";

export interface GeneratedTopic {
  _id: string;
  topic_label: string;
  created: number;
  updated: number;
  custom_topic_label?: string;
  title?: string;
}

interface GeneratedTopicOperations {
  _id: string;
  operation: "merge" | "rename";
  sources: string[];
  target: string;
  status: "pending" | "completed" | "failed" | "conflict";
  created_by: string;
  created: number;
  updated: number;
}

export const GeneratedTopicsApi = createApi({
  reducerPath: "generatedTopics",
  baseQuery: (props) => adaAPI.request({ ...props }),
  tagTypes: ["GeneratedTopics"],
  endpoints: (builder) => ({
    getGeneratedTopic: builder.query({
      query: (topicId: string) => ({
        url: `generated_topics/${topicId}`,
      }),
      transformResponse: (response: { generated_topic: GeneratedTopic }) =>
        response.generated_topic,
    }),
    getGeneratedTopics: builder.query({
      query: () => ({
        url: "generated_topics",
      }),
      transformResponse: (response: { generated_topics: GeneratedTopic[] }) =>
        response.generated_topics,
      providesTags: ["GeneratedTopics"],
    }),
    connectTopics: builder.mutation({
      query: (body) => ({
        method: "POST",
        url: "generated_topics/merge",
        data: body,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    getPendingConnections: builder.query({
      query: () => ({
        url: "generated_topics/merge",
      }),
      transformResponse: (response: {
        generated_topic_operations: GeneratedTopicOperations[];
      }) => response.generated_topic_operations,
      providesTags: ["GeneratedTopics"],
    }),
    renameTopic: builder.mutation({
      query: (body) => ({
        method: "PATCH",
        url: "generated_topics/rename",
        data: body,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
    hideTopic: builder.mutation({
      query: (body) => ({
        method: "PATCH",
        url: "generated_topics/hide",
        data: body,
      }),
      invalidatesTags: ["GeneratedTopics"],
    }),
  }),
});

export const SelectAllGeneratedTopics = createSelector(
  GeneratedTopicsApi.endpoints.getGeneratedTopics.select({}),
  (res) => res.data ?? [],
);

export const {
  useGetGeneratedTopicQuery,
  useGetGeneratedTopicsQuery,
  useGetPendingConnectionsQuery,
  useConnectTopicsMutation,
  useHideTopicMutation,
  useRenameTopicMutation,
} = GeneratedTopicsApi;
