// @ts-strict-ignore
import Immutable from "immutable";

export type AnalyticsTimeSeriesValueType =
  | "number"
  | "percentage"
  | "seconds"
  | "currency";
export type AnalyticsTimeSeriesUnit = "month" | "week" | "day" | "hour";

interface AnalyticsTimeSeriesRecordValueProps {
  type: AnalyticsTimeSeriesValueType;
  label: string;
  increaseIsDesirable: boolean;
  value: number;
  previousPeriodValue: number;
}

export class AnalyticsTimeSeriesRecordValue extends Immutable.Record<AnalyticsTimeSeriesRecordValueProps>(
  {
    type: null,
    label: null,
    increaseIsDesirable: false,
    value: null,
    previousPeriodValue: null,
  },
) {}
