// @ts-strict-ignore
import { type LanguageCode } from "services/language";
import RecordClassCreator, { type ManyResourcePayload } from "services/record";

interface LanguageAttributes {
  name: string;
  code: LanguageCode;
  englishName: string;
  validBotLanguage: boolean;
}

export class Language extends RecordClassCreator<LanguageAttributes>({
  type: "language",
  name: undefined,
  code: undefined,
  englishName: undefined,
  validBotLanguage: false,
}) {
  static resolveFetchManyResult(payload: {
    [key: string]: unknown;
    languages: { [key: string]: unknown; code: string; _type: string }[];
  }): ManyResourcePayload {
    return {
      ...payload,
      data: payload.languages.map((item) => ({
        ...item,
        _id: item.code,
      })),
    };
  }
}
