import { Icon, IconButton, Select } from "@adasupport/byron";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Flex, HSpace2, InputText, VariablePicker } from "components/Common";
import { InputWithVariableAutocompleteDropdown } from "components/Common/InputWithVariableAutocompleteDropdown";
import { type Variable } from "slices/variables/types";

import * as S from "./styles";

interface Props {
  index: number;
  variableId: string | null;
  value: string | null;
  variables: Variable[];
}

export function VariableConfigRow({
  index,
  variableId,
  value,
  variables,
}: Props) {
  const dispatch = useDispatch();

  const updateValue = useCallback(
    (newVal: string | number | boolean | null) => {
      dispatch({
        type: "UPDATE_VARIABLE_INFO",
        index,
        variableInfo: {
          value: newVal,
        },
      });
    },
    [dispatch, index],
  );

  const variableType =
    variables.find((variable) => variable._id === variableId)?._type ||
    "string";

  return (
    <S.VariableConfigRow>
      <VariablePicker
        value={variableId}
        onChange={(newId) => {
          dispatch({
            type: "UPDATE_VARIABLE_INFO",
            index,
            variableInfo: {
              id: newId,
            },
          });
        }}
        variables={variables}
      />
      <HSpace2 />
      {variableType === "string" && (
        <InputWithVariableAutocompleteDropdown
          value={value || ""}
          onChange={updateValue}
          variableId={variableId || ""}
        />
      )}
      {variableType === "long" && (
        <InputText
          value={value || ""}
          onChange={updateValue}
          invalid={Boolean(value) && Number.isNaN(Number(value))}
          invalidMessage="Value must be a number"
        />
      )}
      {variableType === "bool" && (
        <Flex stretch>
          <Select
            options={[
              {
                label: "True",
                value: "True",
              },
              {
                label: "False",
                value: "False",
              },
            ]}
            value={value}
            onChange={updateValue}
          />
        </Flex>
      )}
      <HSpace2 />
      <IconButton
        icon={Icon.Trash}
        aria-label="Delete variable configuration"
        variant="tertiary danger"
        onClick={() => {
          dispatch({
            type: "DELETE_VARIABLE_INFO",
            index,
          });
        }}
      />
    </S.VariableConfigRow>
  );
}
